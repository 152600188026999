import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import ChildrenEducationBenefit from './ChildrenEducationBenefit';
import SportsBenefits from './SportsBenefits';
import EmployeeLoans from './EmployeeLoans';
import CompanyCar from './CompanyCar';
import Awards from './Awards';
import OthersBenefits from './OthersBenefits';

import { setOtherBenefitsData } from '../../../../../../redux/onboard/benefits/other_benefits';
import { toast } from 'react-toastify';


export default function OtherBenefits(props) {
    const other_benefits = useSelector(state => state.other_benefits)
    const { data } = other_benefits
    const dispatch = useDispatch()

    const { register, handleSubmit, watch, reset, control,
        formState: { errors, isDirty },
    } = useForm();

    useEffect(() => {
        reset(data)
    }, [])
    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])

    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    
    }




    const onSubmit = (form_data) => {
        if (isDirty){
            dispatch(setOtherBenefitsData(form_data))
            props.onSaveClick("benefits", "other_benefits", form_data)
        }
        props.changeTab(5)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ChildrenEducationBenefit id={'education'} control={control} watch={watch} register={register} errors={errors} />
            <SportsBenefits id={'sports_gym'} control={control} watch={watch} register={register} errors={errors} />
            <EmployeeLoans id={`loans`} control={control} watch={watch} register={register} errors={errors} />
            <CompanyCar id={"company_car"} control={control} watch={watch} register={register} errors={errors} />
            <Awards id={"long_term_awards"} control={control} watch={watch} register={register} errors={errors} />
            <OthersBenefits id={"other"} control={control} watch={watch} register={register} errors={errors} />
            <div className="flex justify-end mt-5 px-2 py-2">
                <button type='submit' className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}