export default function BCard({ children, title, subtitle, footer, note }) {
  return (
    <div className=" border border-gray-300 rounded-md shadow-md overflow-hidden ">
      <div className="px-4 py-2  bg-gray-50 ">
        <h5 className="text-sm ">
          <span className="font-bold">{title}</span>
        </h5>
        <h4 className="text-sm ">{subtitle}</h4>
      </div>
      {(title || subtitle) && <hr />}
      <div className="p-2">{children}</div>
      {(footer || note) && <hr />}
      <div className="px-4 py-2 text-xs  ">
        {footer && footer}
        <br />
        {note && (
          <>
            <span className="font-bold">Note: </span>
            <span className="">{note}</span>
          </>
        )}
      </div>
    </div>
  );
  // return (
  //   <div
  //     className="bg-white shadow-md rounded-lg overflow-hidden"
  //     style={{ border: "1px solid rgb(235, 235, 235)" }}
  //   >
  //     <div className="px-4 py-2">
  //       <h4 className="text-sm">
  //         <span className="font-bold">{title}</span> -{" "}
  //         <span className="">{subtitle}</span>
  //       </h4>
  //     </div>
  //     <div className="p-2">{children}</div>
  //     <div className="px-4 py-2 text-sm">
  //       {footer && footer}
  //       <br />
  //       {note && <span>Note: {note}</span>}
  //     </div>
  //   </div>
  // );
}
