import DeiProgram from "./DeiProgram";
import EvpProgram from "./EvpProgram";
import Initiative from "./Initiative";
import InitiativeOffering from "./InitiativeOffering";
import WellBeingStrategy from "./WellBeingStrategy";
import WellBeingStrategyOwners from "./WellBeingStrategyOwners";

export default function BenefitWellBeingDEI(props) {
  console.log(props.data);
  const {
    dei_program,
    evp_program,
    initiatives,
    offering,
    wellbeing_strategy,
    wellbeing_strategy_owns,
  } = props.data;
  return (
    <div>
      <EvpProgram data={evp_program} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <WellBeingStrategy data={wellbeing_strategy} />
        <WellBeingStrategyOwners data={wellbeing_strategy_owns} />
        <InitiativeOffering data={offering} />
        <DeiProgram data={dei_program} />
      </div>
      <Initiative data={initiatives} />
    </div>
  );
}
