import AnnualCompensationAdjustments from "./AnnualCompensationAdjustments";
import CompensationStructure from "./CompensationStructure";

export default function BenefitCompensationPractices(props) {
  const { compensation_structure, annual_compensation } = props.data;
  return (
    <div>
      <CompensationStructure data={compensation_structure} />
      <AnnualCompensationAdjustments data={annual_compensation} />
    </div>
  );
}
