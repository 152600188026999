export function isUserLoggedIn() {
    return getAccessToken() !== null
}

export const setAccessToken = (token) => {
    document.cookie = `workRateToken=${token}; expires=Thu, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const getAccessToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('workRateToken=')) {
            return cookie.substring('workRateToken='.length, cookie.length);
        }
    }
    return null;
};

export const deleteAccessToken = () => {
    document.cookie = 'workRateToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};


export const setRefreshToken = (token) => {
    document.cookie = `workRateRefreshToken=${token}; expires=Thu, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const getRefreshToken = () => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('workRateRefreshToken=')) {
            return cookie.substring('workRateRefreshToken='.length, cookie.length);
        }
    }
    return null;
};

export const deleteRefreshToken = () => {
    document.cookie = 'workRateRefreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
};