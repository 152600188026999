import { createSlice } from '@reduxjs/toolkit'


export const compensationSubDataSlice = createSlice({
    name: 'compensation_submission',
    initialState: {
        data: null,
        xlsx_url: null
    },
    reducers: {
        setCompensationSubmissionData: (state, action) => {
            state.xlsx_url = action.payload.xlsx_url
            state.rows = action.payload.rows
            state.cols_names = action.payload.cols_names
            state.meta = action.payload.meta
        },
        
        setCompensationSubissionDataWKey: (state, action) => {
            state[action.payload.key] = action.payload.value
        }
    }
})

export const { setCompensationSubmissionData, setCompensationSubissionDataWKey } = compensationSubDataSlice.actions

export default compensationSubDataSlice.reducer