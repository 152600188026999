import { createSlice } from '@reduxjs/toolkit'


export const wellBeingDEISlice = createSlice({
  name: 'well_being',
  initialState: {
    data: {
      "wb_data": {

      },
      "initiates": {
      }


    }
  },
  reducers: {
    setWellBeingData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setWellBeingData } = wellBeingDEISlice.actions

export default wellBeingDEISlice.reducer


/*
wb_data: {

      },
      initiates: {

      }
    */