import React from "react";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function CompensationManagement(props) {
  const { categories, data, total } = props.data;
  return (
    <BCard
      title="Expatriate Compensation Management Location"
      footer={getFooter(total)}
      note={getNote()}
    >
      <PieChart categories={categories} series={data} height="200px" />
    </BCard>
  );
}
