import { useForm, Controller } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";


export function CheckboxInput(props) {
  const { value, errors, control, ...restProps } = props;
  
  const isReadOnly = useSelector(onBoardingReadOnly);
  return (
    <Controller
      name={restProps.name}
      control={control}
      defaultValue={false}
      render={({ field }) => (
        <Checkbox
          {...field}
          
          disabled={isReadOnly}
          size="small"
          checked={field.value}
          onChange={(e) => field.onChange(e.target.checked)}
          color="primary"
        />
      )}
    />
  );
}