import { getNestedError } from "./utils";

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";

export default function TextInput(props) {
    const { register, errors, watch, rules = {}, ...restProps } = props;
    
    const isError = !!getNestedError(errors, restProps.name);
    const baseClasses = `mt-1 p-1.5 w-full border rounded-sm text-gray-500 ${restProps.className}`;
    const errorClasses = isError ? 'border-red-500 bg-red-100' : '';

    // Combine the default rule with any additional rules passed in.
    const combinedRules = { required: true, ...rules };
    
    const isReadOnly = useSelector(onBoardingReadOnly);

    return <input {...register(restProps.name, combinedRules)} {...restProps} 
        disabled={isReadOnly}
        className={`${baseClasses} ${errorClasses}`} />;
}
