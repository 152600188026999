import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../auth/useJwt';


function sortArrayByNumber(strings) {
  // Ignore any value which is null or undefined
  strings = strings.filter(Boolean);
  return strings.sort((a, b) => {
      // Extract the numeric part from each string
      const numberA = parseInt(a.match(/\d+/), 10);
      const numberB = parseInt(b.match(/\d+/), 10);

      // Compare the numeric parts
      return numberA - numberB;
  });
}

// 1. Rename the action
export const fetchTalentData = createAsyncThunk(
  'talent_data/fetchTalentData',
  async (period, { rejectWithValue }) => {
    try {
      // 2. Adjust the API endpoint
      const response = await instance.get("/talent?period=" + period);
      return response.data;
    } catch (err) {
      const errorMsg = err?.response?.data ? err?.response?.data : "Error fetching data";
      return rejectWithValue(errorMsg);
    }
  }
);

// 3. Update the state shape and reducers
export const talentDataSlice = createSlice({
  name: 'talent_data',
  initialState: {
    data: [],
    allData: [],
    company_info: {},
    position_salaries: [],
    gender_pay_equity: null,
    loading: true,
    error: null,
    filters: {
      period : "Annual"
    },
    uniqueGrades: [],
    uniqueRoles: [],
    uniqueLocations: [],
    uniqueJobFamilies: [],
  },
  reducers: {
    setTalentFilters: (state, action) => {
      state.filters = action.payload;

      const { role, grade, location, jobFamily} = state.filters;
      console.log(role, grade)
      // Filter based on role and grade
      if (role || grade || location || jobFamily) {
        state.data = state.allData.filter(item => {
          let roleMatch = true;
          let gradeMatch = true;
          let locationMatch = true;
          let jobFamilyMatch = true;

          if (role) {
            roleMatch = item.role === role;
          }

          if (grade) {
            gradeMatch = item.grade === grade;
          }

          if (location) {
            locationMatch = item.location === location;
          }

          if (jobFamily) {
            jobFamilyMatch = item.job_family === jobFamily;
          }


          return roleMatch && gradeMatch && locationMatch && jobFamilyMatch;
        });
      } else {
        // If both filters are null, reset data to allData
        state.data = state.allData;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTalentData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTalentData.fulfilled, (state, action) => {
        state.data = action.payload.table_data;
        state.allData = action.payload.table_data;
        state.position_salaries = action.payload.position_salaries;
        state.gender_pay_equity = action.payload.gender_pay_equity;

        const roles = action.payload.table_data.map(item => item.role);
        state.uniqueRoles = [...new Set(roles)];

        // Extract unique grades
        const grades = action.payload.table_data.map(item => item.grade);
        state.uniqueGrades = sortArrayByNumber([...new Set(grades)])
        
        // Extract unique `location`
        const locations = action.payload.table_data.map(item => item.location);
        state.uniqueLocations = [...new Set(locations)];

        // Extract unique `job_family`
        const job_families = action.payload.table_data.map(item => item.job_family);
        state.uniqueJobFamilies = [...new Set(job_families)];

        state.company_info = {
          name: action.payload.company_name,
        }

        state.loading = false;
      })
      .addCase(fetchTalentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const { setTalentFilters } = talentDataSlice.actions;

export default talentDataSlice.reducer;
