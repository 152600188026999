import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'

import { COUNTRIES, COUNTRY_CITY_DISTRICT } from "../../../../configs/data/location";
import Label from "../../../../components/forms/InputLabel";
import SelectInput from "../../../../components/forms/SelectInput";
import TextInput from "../../../../components/forms/TextInput";
import BlockHeading from "../../../../components/forms/BlockHeading";
import { setCompanyInfoData, setCompanyInfoError, setOnCompanyInfoSubmitDone } from "../../../../redux/onboard/company_info";


export default function OrganizationInformation(props) {
    const { watch, errors, control, register } = props
    const id = "organization_information"
    console.log(COUNTRY_CITY_DISTRICT[watch(`${id}.country`)]?.cities[watch(`${id}.county`)])
    return (
        <div>

            <BlockHeading text="1. Organisation Information" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-2">
                <div>
                    <Label htmlFor="name" text="Name" />
                    <TextInput watch={watch} type="text" id="name" name={`${id}.name`} register={register} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="physical_address" text="Physical Address" />
                    <TextInput watch={watch} type="text" id="physical_address" name={`${id}.physical_address`} register={register} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="country" text="Country" />
                    <SelectInput id="country" name={`${id}.country`} register={register} options={COUNTRIES} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="county" text="Province/County" />
                    <SelectInput id="county" name={`${id}.county`} register={register} options={COUNTRY_CITY_DISTRICT[watch(`${id}.country`)]?.districts} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="city" text={`City`} />
                    <SelectInput id="city" name={`${id}.city`} register={register} options={COUNTRY_CITY_DISTRICT[watch(`${id}.country`)]?.cities[watch(`${id}.county`)]} errors={errors} />
                </div>

            </div>
        </div>
    );
}
