import {
  createBrowserRouter,
  BrowserRouter as Router,
  Route, Routes, Navigate,
  Link
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";


import Login from "../pages/authentication/login";
import Register from "../pages/authentication/register";
import DashboardHome from "../pages/dashboard";
import ForgotPassword from "../pages/authentication/forgotPassword";
import EmailVerification from "../pages/authentication/verifyEmail";
import ResetPassword from "../pages/authentication/resetPassword";
import MainDashboard from "../pages/dashboard/MainDashboard";
import MarketData from "../pages/MarketData";
import Talent from "../pages/Talent";
import FAQ from "../pages/faq";
import Benefits from "../pages/Benefits";
import AdminHome from "../pages/admin/home";
import AdminRoute from "./AdminRoute";
import AdminUsers from "../pages/admin/users";
import AdminCompanies from "../pages/admin/companies";
import Profile from "../pages/Profile";
import AdminCompanyDetails from "../pages/admin/companies/CompanyDetails";
import WR_LEVELS from "../pages/data/wr_levels";

function RouterComp() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<DashboardHome />} />
          <Route path="/past-survey-responses" element={<DashboardHome />} />
          <Route path="/dashboard" element={<MainDashboard />} />
          <Route path="/market-data" element={<MarketData />} />
          <Route path="/talent" element={<Talent />} />
          <Route path="/benefits" element={<Benefits />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/data/wr-levels" element={<WR_LEVELS />} />
          <Route path="/faq" element={<FAQ />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/companies" element={<AdminCompanies />} />
          <Route path="/admin/companies/:id" element={<AdminCompanyDetails />} />
          <Route path="/admin/companies/view" element={<DashboardHome />} />
        </Route>
      </Routes>
    </Router>
  )
}


export default RouterComp