import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../components/layouts/Dashboard";
import instance from "../../../auth/useJwt";
import { Link, useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import CompanyDetailsCard from "./components/CompanyDetailsCard";
import CompanyApproveDialog from "./components/CompanyApproveDialog";
import CompanyDisApproveDialog from "./components/CompanyDisapproveDialog";
import StatusHistoryCard from "./components/StatusHistoryCard";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function AdminCompanyDetails() {
    const { id } = useParams()
    const [companyDetails, setCompanyDetails] = useState({})
    const [loading, setLoading] = useState(true)

    const get_company_details = () => {
        setLoading(true)
        instance.get(`/admin/companies?id=${id}`).then(res => {
            setCompanyDetails(res.data)
            setLoading(false)
        }
        ).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    const onSuccess = () => {
        console.log('success')
        get_company_details()
    }

    useEffect(() => {
        get_company_details()
    }, [])

    return (
        <DashboardLayout title="Company Details - Admin">
            <div className="px-4 py-1">
                {
                    loading ? <Box sx={{ height: '60vh', flexDirection: 'column', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                        :
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', pb: 2 }}>
                                <Box sx={{ display: 'flex' }}>
                                <Typography variant="h6">{companyDetails?.data?.company_info?.organization_information?.name}</Typography>
                                
                                <Tooltip title={companyDetails.approved ? 'Approved' : 'Not Approved'} arrow>
                                <CheckCircleOutlineIcon sx={{ color: companyDetails.approved ? 'green' : 'red', ml: 1, mt: 0.7 }} />
                                </Tooltip>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                {
                                    !companyDetails.approved &&
                                    // <Button variant="contained"
                                    // color="success"
                                    // size="small" sx={{ textTransform: 'none' }}>Approve</Button>
                                    <CompanyApproveDialog onSuccess={onSuccess} />
                                }

                                    {/* <Button variant="contained"
                                    color="error"
                                    size="small" sx={{ textTransform: 'none' }}>Disapprove</Button>
                                 */}
                                 <CompanyDisApproveDialog
                                 onSuccess={onSuccess}
                                 contact_name={companyDetails?.data?.company_info?.contact_information?.name}
                                 />


                                <Button variant="contained"
                                    size="small"
                                    sx={{ textTransform: 'none' }}
                                    to={`/admin/companies/view?id=${id}`} component={Link}>View Company Details</Button>
                                    </Box>
                            </Box>

                            <Box>
                                <CompanyDetailsCard data={companyDetails.data}
                                can_edit={companyDetails.can_edit}
                                approved={companyDetails.approved}
                                
                                />
                            </Box>
                            <Box>
                                <StatusHistoryCard data={companyDetails.history || []} />
                            </Box>
                        </Box>
                }
            </div>
        </DashboardLayout>
    )
}