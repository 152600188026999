import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import { num_generator } from "../../utils/generators"
import MUITextInput from '../../../../../../components/forms/MUITextInput';
import { WR_COLORS } from "../../../../../../configs/colors"
import HeadCell from "../../../../../../components/forms/general"

const ORGANIZATION_PP = [
    'All employees eligible for the same benefit',
    ...ORGANIZATION_PEOPLE
]

// const SUBSIDIES = [
//     { label: 'Select a value', value: null },
//     { label: "No", value: "No" },
//     { label: "Yes - 100% subsidised", value: "Yes - 100% subsidised" },
//     { label: "Yes - 51-90% subsidised", value: "Yes - 51-90% subsidised" },
//     { label: "Yes - 50% subsidised", value: "Yes - 50% subsidised" },
//     { label: "Yes - 25% subsidised", value: "Yes - 25% subsidised" },
//     { label: "Other", value: "Other" },
// ]

export default function ChildrenEducationBenefit(props) {
    const id = props.id
    const { watch, errors, control, register } = props

    const allEmployeesValues = watch(`${id}.data.All employees eligible for the same benefit`);
    const isAllEmployeesRowFilled = Object.values(allEmployeesValues || {}).some(val => Boolean(val));

    const otherRowsValues = ORGANIZATION_PP.filter(item => item !== "All employees eligible for the same benefit")
        .map(item => watch(`${id}.data.${item}`));
    const isAnyOtherRowFilled = otherRowsValues.some(row => Object.values(row || {}).some(val => Boolean(val)));


    return (
        <div>

            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer child education benefit or personal education education benefit?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    {/* <Label className="mb-2"
                        text="Who is eligible?" /> */}

                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head_main }}>
                                <HeadCell>Who is eligible</HeadCell>
                                <HeadCell colSpan={3}>Children Education benefit</HeadCell>
                                <HeadCell>Personal Education benefit</HeadCell>
                            </tr>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                {["", 'Max. amount per child yearly', "Max. No. of children", "Max Age", "Education for self yearly (amount)"].map(smm => (
                                    <HeadCell key={`i40n-${smm}`}>{smm}</HeadCell>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PP.map((item, index) => {
                                    const isCurrentRowAllEmployees = item === "All employees eligible for the same benefit";

                                    if (isAnyOtherRowFilled && isCurrentRowAllEmployees || isAllEmployeesRowFilled && !isCurrentRowAllEmployees) {
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 py-2 border-b border-r">{item}</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                            </tr>
                                        );
                                    }
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        <td className="border-b border-r">
                                            {/* <input type="number" className="border-none outline-none focus:outline-none w-full h-full" placeholder="Write here" /> */}
                                            <MUITextInput type="number" watch={watch} placeholder="Write amount here"
                                                border={false} id="organization_name"
                                                name={`${id}.data.${item}.max_amount_per_child_yearly`} control={control} errors={errors} />
                                        </td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle id="city"
                                                name={`${id}.data.${item}.max_num_children`} control={control} border={false} register={register} className="border-none outline-none focus:outline-none w-full h-full" 
                                                options={[
                                                    {label: "Uncapped", value: "Uncapped"},
                                                    ...num_generator(4, true)
                                                ]} errors={errors} />
                                        </td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle id="city"
                                                name={`${id}.data.${item}.max_age`} control={control} border={false} register={register} className="border-none outline-none focus:outline-none w-full h-full" 
                                                options={[
                                                    // ignore 1st value
                                                    ...num_generator(21, false).slice(1)
                                                ]} errors={errors} />
                                        </td>
                                        <td className="border-b border-r">
                                            {/* <input type="number" className="border-none outline-none focus:outline-none w-full h-full" placeholder="Write here" /> */}
                                            <MUITextInput type="number" watch={watch} border={false} placeholder="Write amount here"
                                                control={control} id="organization_name" name={`${id}.data.${item}.self_education_yearly_amount`} register={register} errors={errors} />
                                        </td>

                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )
}