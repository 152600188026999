import React from 'react';
import Chart from 'react-apexcharts'
import { styled } from "@mui/material";
import { WR_GRAPH_COLORS } from '../../../configs/colors';
import PageLoader from '../../../components/Loaders/PageLoader';
import { useSelector } from 'react-redux';

const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)",
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))

export default function SGIComparisonPosition(props) {
    const { data, loading } = props;
    const { company_info } = useSelector(state => state.talent)
    if (loading) {
        return <PageLoader />
    }

    console.log(data)

    // Truncate x-axis labels to a maximum of 15 characters
    const truncatedPosition = data['Position'].map(label => label.slice(0, 22) + "...");

    const state = {
        series: [
            { name: 'Market 25th', type: 'column', data: data['Market 25th'] },
            { name: 'Market 50th', type: 'column', data: data['Market 50th'] },
            { name: 'Market 75th', type: 'column', data: data['Market 75th'] },
            { name: `Grade Avg. ${company_info.name}`, type: 'line', data: data['Grade Average SGI'] }
        ],
        options: {
            chart: {
                type: 'line', // This default type is needed but will be overridden by the types set in the series
                height: 350,
                stacked: true,
                toolbar: { show: false }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '70%',
                    endingShape: 'rounded'
                },
            },
            grid: { show: false },
            xaxis: {
                categories: truncatedPosition, // Use the truncated labels on the x-axis
                labels: {
                    show: true,
                },
                axisBorder: { show: true },
                title: {
                    text: "Job Grade"
                },
            },
            colors: WR_GRAPH_COLORS, //['#77B6EA', '#545454', "#F00", "#09F"],
            stroke: {
                width: [0, 0, 0, 2], // setting the width of line stroke for Grade Avg. SGI and 0 for the rest
            },
            dataLabels: { enabled: false },
            yaxis: {
                title: {
                    text: "Salaries"
                },
                labels: {
                    show: true,
                    formatter: function (value) {
                        // Convert the value to a string with commas
                        return value.toLocaleString();
                    },
                    axisBorder: { show: true }
                },
                axisBorder: { show: true }
            },
            fill: { opacity: 1 },
            legend: { show: true },
            // tooltip: {
            //     custom: function({ series, seriesIndex, dataPointIndex, w }) {
            //         const fullLabel = data['Position'][dataPointIndex];
            //         return `<div class="tooltip">${fullLabel}: ${series[seriesIndex]}</div>`;
            //     }
            // }
        }
    }

    return (
        <SingleCard>
            <h2 className='text-xl font-semibold text-gray-600'>Comparison of "{company_info.name}" Salaries with Market Data</h2>
            <p className='text-sm mt-2 text-gray-500'>Per Position</p>
            <Chart series={state.series} options={state.options} height={400} />
        </SingleCard>
    );
}
