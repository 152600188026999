import React, { useState, useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import Cards from "../components/Cards";
import DepartmentCompensation from "../components/DepartmentCompensation";
import Currencies from "../components/Currencies";
import HeadCount from "../components/HeadCount";
import EmploymentType from "../components/EmploymentType";
import instance from "../../../../auth/useJwt";
import PageLoader from "../../../../components/Loaders/PageLoader";

export default function Dashboard() {
    const [data, setData] = useState(null)
    const [apiReq, setApiReq] = useState({ loading: true, error: null })

    const get_data = () => {
        instance.get("/dashboard").then(res => {
            setData(res.data)
            console.log(res)
            setApiReq({ loading: false, error: null })
        }).catch(err => {
            console.log(err)
            setApiReq({ loading: false, error: "Error Fetching data" })
        })
    }

    useEffect(() => {
        get_data()
    }, [])




    return (
        <div>
            {apiReq.loading ?

               <PageLoader />
                :
                <div className="px-4 mt-6">
                    <Cards data={data?.basic} />
                    <div className="my-6">
                        <DepartmentCompensation data={data?.basic?.job_family_compensation} />
                    </div>
                    <Grid container spacing={2}>
                        {/* <Grid item md={3}>
                            <Currencies />
                        </Grid>
                        <Grid item md={4}>
                            <HeadCount />
                        </Grid> */}
                        <Grid item md={12}>
                            <EmploymentType data={data?.basic.wr_data} />
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}