import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/layouts/Dashboard";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SGIComparisonPosition from "./components/SGIComparisonPosition";
import instance from "../../auth/useJwt";
import PageLoader from "../../components/Loaders/PageLoader";
import OrgLevel from "./OrgLevel";
import GenderPayEquityChart from "./components/GenderPayEquity";
import { useDispatch, useSelector } from "react-redux";
import { fetchTalentData } from "../../redux/talent";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};


export default function Talent(props) {
  const [value, setValue] = useState('graph-view');

  const handleChange = (event, newValue) => {
    setValue(newValue)
  };
  const dispatch = useDispatch()

  const { data, loading, error, gender_pay_equity, position_salaries, filters } = useSelector(state => state.talent) //useState(null)

  useEffect(() => {
    dispatch(fetchTalentData(filters.period))
  }, [filters.period])

  const tabs_data = [
    { id: 'graph-view', label: 'Graph View' },
    { id: 'org-level', label: 'Org. Level' },
    { id: 'pay-split', label: 'Gender Split' }
  ]

  return (
    <DashboardLayout>
      
      <Box sx={{ width: '100%' }} className="px-4 mt-2">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {tabs_data.map((item, index) => (
              <Tab sx={{ fontSize: '14px', textTransform: 'capitalize' }} key={`tb-${index}`} label={item.label} value={item.id} />
            ))
            }
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={`graph-view`}>

          <SGIComparisonPosition data={position_salaries} loading={loading} />

        </CustomTabPanel>
        <CustomTabPanel value={value} index={'org-level'}>
          <OrgLevel data={data} loading={loading} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={'pay-split'}>
          <GenderPayEquityChart data={gender_pay_equity} loading={loading} />
        </CustomTabPanel>
        {error ?
          <div className="text-center text-red-500 text-xl mt-5">{error}</div>
          : null}
      </Box>

      
    </DashboardLayout>
  );
}

