export const WR_LEVELS_DESCRIPTION = {
    "WR 15": "Jobs at the top of the organisation involved in making long-term policy decisions within wide limits of discretion at board or commission level",
    "WR 14": "Jobs providing strategic leadership for international organisations involved in decisions on long-term programmes, plans or budgets across different countries. Corresponds to jobs in Paterson F1/F2, gradar 22-24, Mercer E4, Hay 24+, WTW 18/19, Birches Group 14, Peromnes 1, Task 25, EQUATE 16",
    "WR 13": "Jobs providing strategic leadership for an organisation at a national level involved in decisions on long-term programmes, plans or budgets for a major function/group of functions. Corresponds to jobs in Paterson E4/E5, gradar 20/21, Mercer E3, Hay 23/24, WTW 17, Birches Group 13, Peromnes 2, Task 22/24, EQUATE 15",
    "WR 12": "Heads of major functions or divisions which have several departments or experts with a national level authority in their specific field. Corresponds to jobs in Paterson E3, gradar 18/19, Mercer E2/M5/P6, Hay 21/22, WTW 16, Birches Group 12/13, Peromnes 3, Task 20/21, EQUATE 15",
    "WR 11": "Heads of functions/divisions/directorates and Experts with Organisation Distinct Authority. Corresponds to jobs in Paterson E1/E2, gradar 17, Mercer E1, Hay 20, WTW 15, Birches Group 12, Peromnes 4, Task 18/19, EQUATE 14",
    "WR 10": "Heads of departments, senior managers and Senior Expert / Technical Specialist / Specialist Professional. Corresponds to jobs in Paterson D4/D5, gradar 16, Mercer M4/P5, Hay 19, WTW 15, Birches Group 12, Peromnes 5, Task 16/17, EQUATE 14",
    "WR 09": "Departmental Managers and Experienced Expert / Technical Specialist / Specialist Professional. Corresponds to jobs in Paterson D3, gradar 15, Mercer M3/P4, Hay 18, WTW 14, Birches Group 11, Peromnes 6, Task 15, EQUATE 13",
    "WR 08": "Assistant/Deputy Managers, Managers of small departments, Team Leader for professional teams, Expert / Technical Specialist / Specialist Professional. Corresponds to jobs in Paterson D1/D2, gradar 13/14, Mercer M2, Hay 16/17, WTW 13/14, Birches Group 10, Peromnes 7, Task 13/14, EQUATE 12",
    "WR 07": "Certified Specialist / Practitioners / Analysts and Team Leaders /Supervisors / Superintendents of technical teams. Corresponds to jobs in Paterson C4/C5, gradar 12, Mercer P3/M1, Hay 15, WTW 12, Birches Group 10, Peromnes 8, Task 12, EQUATE 10/11",
    "WR 06": "Senior Analyst / Senior Professional and Supervisor/Foreman at the Operational Level. Corresponds to jobs in Paterson C3, gradar 11, Mercer P3/M1, Hay 14, WTW 11, Birches Group 09, Peromnes 9, Task 11, EQUATE 9",
    "WR 05": "Skilled, Technical and Academically Qualified Employees/Officers and Intermediate Analyst / Professional / Associate. Corresponds to jobs in Paterson C1/C2, gradar 9/10, Mercer P2, Hay 12/13, WTW 9/10, Birches Group 08, Peromnes 10/11, Task 8-10, EQUATE 7/8",
    "WR 04": "Experienced (Senior) Operational Skilled Worker & Entry Level Analyst / Professional. Corresponds to jobs in Paterson B4/B5, gradar 7/8, Mercer S4/P1, Hay 10/11, WTW 7/8, Birches Group 06/08, Peromnes 12, Task 7, EQUATE 6",
    "WR 03": "Mid Level Operational Skilled Worker.Corresponds to jobs in Paterson B3, gradar 6, Mercer S3, Hay 9, WTW 6, Birches Group 05, Peromnes 13, Task 6, EQUATE 5",
    "WR 02": "Basic Operational Skilled / Semi Skilled Worker / Mechanical Operators. Corresponds to jobs in Paterson B1/B2, gradar 4/5, Mercer S2, Hay 7/8, WTW 4/5, Birches Group 03/04, Peromnes 14/15, Task 4/5, EQUATE 4",
    "WR 01": "Primary Skilled Worker / Physical Operators. Corresponds to jobs in Paterson A, gradar 1-3, Mercer S1, Hay 4/5/6, WTW 2/3, Birches Group 01/02, Peromnes 16-19, Task 1-3, EQUATE 1-3"
}