export default function OnBoardDone() {
    return (
        <div className="px-20 py-10">
            <h3 className="text-2xl font-bold">Congratulations! Submission Successful - Await Approval for Dashboard Access.</h3>
            <p className="py-2 mt-6">
                Your company data has been successfully submitted. We appreciate your commitment to this process. Please await
                the completion of the data validation and approval process. Once approved, you will gain access to the dashboard
                for valuable insights.
            </p>
            <p className="py-2">
                Our team is diligently working on the validation process. You can anticipate an update on the approval status within
                5 days.
            </p>
            <p className="py-2">
                We appreciate your patience and look forward to providing you with access to the insightful dashboard upon
                approval.
            </p>
        </div>
    )
}