import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import HeadCell from "../../../../../../components/forms/general"
import { WR_COLORS } from "../../../../../../configs/colors"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"

import { num_generator } from "../../utils/generators"
const ORGANIZATION_PP = [
    'All employees eligible for the same benefit',
    ...ORGANIZATION_PEOPLE
]

const SUBSIDIES = [
    { label: 'Select a value', value: null },
    { label: "No", value: "No" },
    { label: "Yes - 100% subsidised", value: "Yes - 100% subsidised" },
    { label: "Yes - 51-90% subsidised", value: "Yes - 51-90% subsidised" },
    { label: "Yes - 50% subsidised", value: "Yes - 50% subsidised" },
    { label: "Yes - 25% subsidised", value: "Yes - 25% subsidised" },
    { label: "Other", value: "Other" },
]

export default function SportsBenefits(props) {
    const id = props.id
    const { watch, errors, control, register } = props
    const allEmployeesValues = watch(`${id}.data.All employees eligible for the same benefit`);
    const isAllEmployeesRowFilled = Object.values(allEmployeesValues || {}).some(val => Boolean(val));

    const otherRowsValues = ORGANIZATION_PP.filter(item => item !== "All employees eligible for the same benefit")
        .map(item => watch(`${id}.data.${item}`));
    const isAnyOtherRowFilled = otherRowsValues.some(row => Object.values(row || {}).some(val => Boolean(val)));

    return (
        <div>



            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer sports club and/or gym membership?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    <Label className="mb-2"
                        text="Who is eligible?" />

                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head_main }}>
                                <HeadCell></HeadCell>
                                <HeadCell colSpan={3}>Sports Club membership</HeadCell>
                                <HeadCell>Gym membership</HeadCell>
                            </tr>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                {["", 'No. of Clubs', "Registration fee", "Annual Subscription amount", "Annual subscription"].map(smm => (
                                    <HeadCell key={`sports-heading-${smm}`}>{smm}</HeadCell>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PP.map((item, index) => {
                                    const isCurrentRowAllEmployees = item === "All employees eligible for the same benefit";

                                    if (isAnyOtherRowFilled && isCurrentRowAllEmployees || isAllEmployeesRowFilled && !isCurrentRowAllEmployees) {
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 py-2 border-b border-r">{item}</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                            </tr>
                                        );
                                    }
                                    return (<tr key={`sports-benefits-row-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        <td className="px-2 border-b border-r">
                                            <SelectAutocompleteSingle border={false} id={`sports#${item}#sports_no_clubs`} name={`${id}.data.${item}.no_sports_clubs`} control={control} options={num_generator(3, true)} errors={errors} />
                                        </td>
                                        <td className="px-2 border-b border-r">
                                            <SelectAutocompleteSingle border={false} id={`sports#${item}#sports_registration_fee`} name={`${id}.data.${item}.sports_registration_fee`} control={control} options={SUBSIDIES} errors={errors} />
                                        </td>
                                        <td className="px-2 border-b border-r">
                                            <SelectAutocompleteSingle border={false} id={`sports#${item}#sports_annual_subscription_amount`} name={`${id}.data.${item}.ports_annual_subscription_amount`} control={control} options={SUBSIDIES} errors={errors} />
                                        </td>
                                        <td className="px-2 border-b border-r">
                                            <SelectAutocompleteSingle border={false} id={`sports#${item}#gym_annual_subscription`} name={`${id}.data.${item}.gym_annual_subscription`} control={control} options={SUBSIDIES} errors={errors} />
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }


        </div>
    )
}