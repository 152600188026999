import { createSlice } from '@reduxjs/toolkit'


export const hrPoliciesSlice = createSlice({
    name: 'hr_policies',
    initialState: {
        data: {
            employee_demography: {
                
            },
            training_development_policies: {
                
            },
            attraction_retention_practices: {
                
            },
            overtime: {

            },
            work_environment: {
               
            },
            leave: {
                
            }
            
        }
    },
    reducers: {
        setHrPoliciesData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setHrPoliciesData, setHrPoliciesSubmit, setOnHrPoliciesSubmitDone, setHrPoliciesError, setHRPoliciesInitState } = hrPoliciesSlice.actions

export default hrPoliciesSlice.reducer


/*
employee_demography : {
        local_contract: null, expatriate_contract: null, local_plus_contract: null, employment_terms: []
    },
    training_development_policies: {
        formal_training: null, training_needs_identification: null, training_kinds: null, training_budget: null
    },
    attraction_retention_practices: {
        hiring_retention_practices: null, top_three_difficul_attraction_jobs: null, hot_job_hire_stratigies: null,
    },
    work_environment: {
        work_days: null, work_hours: null, work_regime: null
    },
    leave: {
                annual: null, study: null, sick: null, compassionate: null, maternity: null, 
                paternity: null, sabbatical: null, unpaid: null,
                carried_forward: null, sell_annual: null
            }
    initState: true,
    */



/*
employee_demography : {
    "local_contract": {
        "label": "4%",
        "value": 4
    },
    "expatriate_contract": {
        "label": "4%",
        "value": 4
    },
    "local_plus_contract": {
        "label": "2%",
        "value": 2
    },
    "employment_terms": [
        {
            "label": "Fixed-term Contract",
            "value": "Fixed-term Contract"
        },
        {
            "label": "Permanent Contract",
            "value": "Permanent Contract"
        }
    ]
},
training_development_policies: {
    formal_training: null, training_needs_identification: [], training_kinds: [], training_budget: null
},
attraction_retention_practices: {
    hiring_retention_practices: null, top_three_difficul_attraction_jobs: null, hot_job_hire_stratigies: null,
},
work_environment: {
    "work_days": {
        "label": "Other",
        "value": "Other"
    },
    "work_hours": {
        "label": "8am-6pm",
        "value": "8am-6pm"
    },
    "work_regime": {
        "label": "Work from home (WFH)",
        "value": "Work from home (WFH)"
    }
},
leave: {
    "annual": {
        "label": "1",
        "value": 1
    },
    "study": {
        "label": "2",
        "value": 2
    },
    "sick": {
        "label": "3",
        "value": 3
    },
    "compassionate": {
        "label": "4",
        "value": 4
    },
    "maternity": {
        "label": "5",
        "value": 5
    },
    "paternity": {
        "label": "6",
        "value": 6
    },
    "sabbatical": {
        "label": "4 months",
        "value": "4 months"
    },
    "unpaid": {
        "label": "7",
        "value": 7
    },
    "carried_forward": {
        "label": "8",
        "value": 8
    },
    "sell_annual": {
        "label": "Yes - All vacation leave days",
        "value": "Yes - All vacation leave days"
    }
},
*/