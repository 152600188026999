import React from 'react';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts'
import { styled } from "@mui/material";

const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)",
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))


export default function Currencies() {

    const state = {
        series: [65, 35],
        options: {
            labels: ['USD', 'KES'],
            colors: ['#6f52ed', '#20c997'],
            chart: {
                type: 'donut',
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '65%',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: 30,
                                color: "#09F",
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                offsetY: -10,
                                formatter: function (val) {
                                    return '100%';
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#333',
                                offsetY: 10,
                                value: "Total current users",
                                formatter: function (val) {
                                    return 'Total Current User';
                                }
                            },
                            total: {
                                show: true,
                                showAlways: false,
                                label: 'Total',
                                fontSize: '22px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                color: '#373d3f',
                                formatter: function (w) {
                                    return "Total Current User"

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return (

        <SingleCard>
            <h2 className='text-xl font-semibold text-gray-600'>Currencies</h2>
            <div className='flex justify-center mt-5'>
                <Chart options={state.options}
                    series={state.series}
                    type="donut"
                    width="380" />
            </div>
        </SingleCard>
    );
}



