import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../../../../../redux/app_data";


export default function SelectAutocompleteSingle(props) {
    const { options, required, fontSize, name, index, value, onChange, ...restProps } = props;


    // Determine the variant and border style
    const variant = props.variant ? props.variant : 'outlined';
    var borderNone = {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: "none",
                    padding: 0,
                },
            }
    }

    // Determine the background color when disabled
    const backgroundColorWhenDisabled = props.disabled ? { backgroundColor: '#eee' } : {};
    const stt = fontSize ? { fontSize: fontSize ? '12px' : undefined, padding: 0 } : {}
    
    const isReadOnly = useSelector(onBoardingReadOnly);
    return (
       
                <Autocomplete
                    isOptionEqualToValue={(option, value) => option.value === value}
                    options={options || []}
                    disabled={props.disabled || isReadOnly}
                    value={value}
                    onChange={(_, newValue) => onChange(newValue ? newValue.value : null, name, index)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant={variant}
                            fullWidth
                            // error={required && !props.disabled ? isError : false}
                            multiline={props.isMultiline}
                            sx={{ ...borderNone, ...backgroundColorWhenDisabled }}
                            inputProps={{
                                ...params.inputProps,
                                style: stt,
                            }}
                        />
                    )}
                />
    );
}
