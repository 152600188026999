import BlockHeading from "../../../../../../components/forms/BlockHeading";
import Label from "../../../../../../components/forms/InputLabel";
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle";
import { num_generator } from "../../utils/generators";

const LEAVE_ANNUAL = [
    { label: 'None', value: 'none' },
    { label: 'No Policy (Management Discretion)', value: 'no policy' },
    ...num_generator(90)
]
const LEAVE_30 = [
    { label: 'None', value: 'none' },
    { label: 'No Policy (Management Discretion)', value: 'no policy' },
    ...num_generator(30)
]
const LEAVE_180 = [
    { label: 'None', value: 'none' },
    { label: 'No Policy (Management Discretion)', value: 'no policy' },
    ...num_generator(365),
    { label: '365+', value: '365+' },
]
const LEAVE_100 = [
    { label: 'None', value: 'none' },
    { label: 'No Policy (Management Discretion)', value: 'no policy' },
    ...num_generator(100)
]
const LEAVE_SABBATICAL = [
    { label: 'None', value: 'none' },
    { label: 'No Policy (Management Discretion)', value: 'no policy' },
    { label: '3 months', value: '3 months' },
    { label: '4 months', value: '4 months' },
    { label: '5 months', value: '5 months' },
    { label: '6 months', value: '6 months' },
    { label: '7 months', value: '7 months' },
    { label: '8 months', value: '8 months' },
    { label: '9 months', value: '9 months' },
    { label: '10 months', value: '10 months' },
    { label: '11 months', value: '11 months' },
    { label: '12 months', value: '12 months' },
    { label: '12+ months', value: '12+ months' },
]




export default function Leave(props) {
    const id = 'leave'
    const { watch, errors, control } = props



    return (
        <div>
            <BlockHeading text="6. LEAVE" />

            <div className="my-2">

                <p>
                    All leave entitlement below should be reported as business days, unless stated differently.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-2">

                    {[
                        { label: "Annual (vacation) leave", name: "annual", options: LEAVE_ANNUAL },
                        { label: "Study leave", name: "study", options: LEAVE_30 },
                        { label: "Compassionate leave", name: "compassionate", options: LEAVE_30 },
                        { label: "Sick leave (Full Pay)", name: "sick_full_pay", options: LEAVE_100 },
                        { label: "Sick leave (Half Pay)", name: "sick_half_pay", options: LEAVE_100 },
                        { label: "Sick leave (Unpaid)", name: "sick_unpaid", options: LEAVE_100 },
                        { label: "Maternity leave (in calendar days)", name: "maternity", options: LEAVE_180 },
                        { label: "Paternity leave  (in calendar days)", name: "paternity", options: LEAVE_180 },
                        { label: "Long term leave/sabbatical", name: "sabbatical", options: LEAVE_SABBATICAL },
                        { label: "Unpaid leave", name: "unpaid", options: LEAVE_30 },
                        { label: "Number of annual leave days carried forward?", name: "carried_forward", options: LEAVE_30 },

                    ].map((item) => {
                        return (
                            <div key={`inp-${item.name}`}>
                                <Label className="mb-2" htmlFor={`${id}.${item.name}`}
                                    text={item.label} />
                                <SelectAutocompleteSingle
                                    required
                                    name={`${id}.${item.name}`}
                                    errors={errors} watch={watch}
                                    options={item.options} control={control} />
                            </div>
                        )
                    })
                    }


                </div>
                <div>
                    <Label className="mb-2" htmlFor={`sell_annual`}
                        text={`Are employees allowed to sell their annual (vacation) leave days?`} />
                    <SelectAutocompleteSingle
                        required
                        name={`${id}.sell_annual`}
                        errors={errors} watch={watch}
                        options={[
                            { label: 'Yes - All vacation leave days', value: 'Yes - All vacation leave days' },
                            { label: 'Yes - Some vacation leave days', value: 'Yes - Some vacation leave days' },
                            { label: 'No', value: 'No' },
                        ]} control={control} />
                </div>
            </div>

        </div>
    )
}