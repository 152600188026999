import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import generator from "../../utils/generators"
import { WR_COLORS } from "../../../../../../configs/colors";
import SelectAutocomplete from "../../../../../../components/forms/SelectAutocomplete";



const MONTHS = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" }
];

const ADJUST_SALARY_RANGES = [
    { label: "Every 2 years", value: "Every 2 years" },
    { label: "Every 3 years", value: "Every 3 years" },
    { label: "Annual", value: "Annual" },
    { label: "Semi Annual", value: "Semi Annual" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "This does not happen", value: "This does not happen" },
]

const ELEMENTS_ANNUAL_SALARY_INCREASES = [
    { label: "Individual performance", value: "Individual performance" },
    { label: "Team performance", value: "Team performance" },
    { label: "Organisation performance", value: "Organisation performance" },
    { label: "Length of service", value: "Length of service" },
    { label: "Inflation", value: "Inflation" },
    { label: "Position in salary range", value: "Position in salary range" },
    { label: "External market competitiveness", value: "External market competitiveness" },
    { label: "No policy", value: "No policy" },
]


export default function AnnualCompensationAdjustments(props) {
    const id = 'annual_compensation'
    const { watch, errors, control, register } = props
    return (
        <div>
                <BlockHeading text="2. ANNUAL COMPENSATION ADJUSTMENTS" />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                    <div>
                        <Label className="mb-2" htmlFor="annual_salary_increases"
                            text="When do annual salary increases take place?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.annual_salary_increases`}
                            errors={errors} watch={watch}
                            options={MONTHS} control={control} />
                    </div>
                    {/* <div>
                        <Label className="mb-2"
                            htmlFor="annual_stm_incentives"
                            text="When do annual short term incentives (bonus) payments take place?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.annual_stm_incentives`}
                            errors={errors} watch={watch}
                            options={[...MONTHS, { label: "We do not pay short term incentives", label: "We do not pay short term incentives" }]} control={control} />
                    </div> */}
                    <div>
                        <Label className="mb-2" htmlFor="adjustment-salary_increment"
                            text="What is the promotional salary increment % this current year?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.salary_increment`}
                            errors={errors} watch={watch}
                            options={[{label: "Not Applicable", value: "Not Applicable"}, ...generator(100)]} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2"
                            htmlFor="salary_adj_frequency"
                            text="How frequently do you adjust your salary ranges? " />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.salary_adj_frequency`}
                            errors={errors} watch={watch}
                            options={ADJUST_SALARY_RANGES} control={control} />
                    </div>
                </div>
                <div className="mt-2">
                    {/* <Label text="Projected increments for the next year: Merit, COLA and Bonus" /> */}
                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                <td className="p-2 border-b border-r">Projected increments for the next year: Merit, COLA and Bonus</td>
                                <td className="p-2 border-b border-r">Select from dropdown</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ["Merit", "COLA – Cost of Living Allowance", "Bonus"].map((item, index) => {
                                    return (<tr key={`organization_target_market_pay-${index}`}>
                                        <td className="px-2 py-1 border-b border-r">{item}</td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle
                                                required
                                                name={`${id}.projected_increments.${item}`}
                                                errors={errors} watch={watch}
                                                options={[{ "label": "Not Applicable", value: "Not Applicable" }, { "label": "0%", value: "0%" }, ...generator(100)]} border={false} control={control} />
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="mt-2">
                    <Label className="mb-2"
                        htmlFor="salary_increase_determination"
                        text="What elements does your organisation use to determine annual salary increases? (multiple selection)" />
                    {/* <SelectAutocompleteSingle
                        required
                        name={`${id}.salary_increase_determination`}
                        errors={errors} watch={watch}
                        options={ELEMENTS_ANNUAL_SALARY_INCREASES} control={control} /> */}
                        <SelectAutocomplete control={control} register={register} watch={watch}
                                options={ELEMENTS_ANNUAL_SALARY_INCREASES} name={`${id}.salary_increase_determination`} />
                </div>
        </div>
    )
}