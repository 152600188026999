export const COUNTRIES = [
    { label: "Mauritius", value: "mauritius" },
    { label: "Mozambique", value: "mozambique" },
    { label: "Kenya", value: "kenya" },
    { label: "Ethiopia", value: "ethiopia" },
    { label: "Uganda", value: "uganda" },
    { label: "Rwanda", value: "rwanda" },
    { label: "Tanzania", value: "tanzania" },
    { label: "Somalia", value: "somalia" },
]

export const COUNTRY_CITY_DISTRICT = {
    "mauritius": {
        "cities": {
            "Port Louis": [
                {
                    "label": "Port Louis ",
                    "value": "Port Louis "
                }
            ],
            "Plaines Wilhems": [
                {
                    "label": "Curepipe",
                    "value": "Curepipe"
                },
                {
                    "label": "Beau Bassin-Rose Hill",
                    "value": "Beau Bassin-Rose Hill"
                },
                {
                    "label": "Quatre Bornes",
                    "value": "Quatre Bornes"
                },
                {
                    "label": "Vacoas-Phoenix",
                    "value": "Vacoas-Phoenix"
                },
                {
                    "label": "Ebene",
                    "value": "Ebene"
                }
            ],
            "Grand Port": [
                {
                    "label": "Mahebourg",
                    "value": "Mahebourg"
                },
                {
                    "label": "Rose Belle",
                    "value": "Rose Belle"
                },
                {
                    "label": "Plaine Magnien",
                    "value": "Plaine Magnien"
                }
            ],
            "Rivi\u00e8re du Rempart": [
                {
                    "label": "Grand Baie",
                    "value": "Grand Baie"
                },
                {
                    "label": "Goodlands",
                    "value": "Goodlands"
                },
                {
                    "label": "Rivi\u00e8re du Rempart",
                    "value": "Rivi\u00e8re du Rempart"
                }
            ],
            "Rivi\u00e8re Noire": [
                {
                    "label": "Flic en Flac",
                    "value": "Flic en Flac"
                },
                {
                    "label": "Tamarin",
                    "value": "Tamarin"
                },
                {
                    "label": "Black River",
                    "value": "Black River"
                },
                {
                    "label": "Bambous",
                    "value": "Bambous"
                }
            ],
            "Pamplemousses": [
                {
                    "label": "Triolet",
                    "value": "Triolet"
                },
                {
                    "label": "Pamplemousses",
                    "value": "Pamplemousses"
                }
            ],
            "Rodrigues": [
                {
                    "label": "Port Mathurin",
                    "value": "Port Mathurin"
                }
            ],
            "Moka": [
                {
                    "label": "Moka",
                    "value": "Moka"
                }
            ],
            "Flacq": [
                {
                    "label": "Bel Air Rivi\u00e8re S\u00e8che",
                    "value": "Bel Air Rivi\u00e8re S\u00e8che"
                },
                {
                    "label": "Centre de Flacq",
                    "value": "Centre de Flacq"
                }
            ],
            "Savanne": [
                {
                    "label": "Chemin Grenier",
                    "value": "Chemin Grenier"
                },
                {
                    "label": "Surinam",
                    "value": "Surinam"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Port Louis",
                "value": "Port Louis"
            },
            {
                "label": "Plaines Wilhems",
                "value": "Plaines Wilhems"
            },
            {
                "label": "Grand Port",
                "value": "Grand Port"
            },
            {
                "label": "Rivi\u00e8re du Rempart",
                "value": "Rivi\u00e8re du Rempart"
            },
            {
                "label": "Rivi\u00e8re Noire",
                "value": "Rivi\u00e8re Noire"
            },
            {
                "label": "Pamplemousses",
                "value": "Pamplemousses"
            },
            {
                "label": "Rodrigues",
                "value": "Rodrigues"
            },
            {
                "label": "Moka",
                "value": "Moka"
            },
            {
                "label": "Flacq",
                "value": "Flacq"
            },
            {
                "label": "Savanne",
                "value": "Savanne"
            }
        ]
    },
    "mozambique": {
        "cities": {
            "Maputo City": [
                {
                    "label": "Maputo",
                    "value": "Maputo"
                }
            ],
            "Maputo": [
                {
                    "label": "Matola",
                    "value": "Matola"
                },
                {
                    "label": "Ressano Garcia",
                    "value": "Ressano Garcia"
                },
                {
                    "label": "Marracuene",
                    "value": "Marracuene"
                }
            ],
            "Nampula": [
                {
                    "label": "Nampula",
                    "value": "Nampula"
                },
                {
                    "label": "Nacala",
                    "value": "Nacala"
                },
                {
                    "label": "Angoche",
                    "value": "Angoche"
                },
                {
                    "label": "Ilha de Mo\u00e7ambique",
                    "value": "Ilha de Mo\u00e7ambique"
                },
                {
                    "label": "Mutuali",
                    "value": "Mutuali"
                }
            ],
            "Sofala": [
                {
                    "label": "Beira",
                    "value": "Beira"
                },
                {
                    "label": "Dondo",
                    "value": "Dondo"
                },
                {
                    "label": "Nhamatanda",
                    "value": "Nhamatanda"
                }
            ],
            "Manica": [
                {
                    "label": "Chimoio",
                    "value": "Chimoio"
                },
                {
                    "label": "Manica",
                    "value": "Manica"
                },
                {
                    "label": "Gondola",
                    "value": "Gondola"
                }
            ],
            "Zambezia": [
                {
                    "label": "Quelimane",
                    "value": "Quelimane"
                },
                {
                    "label": "Mocuba",
                    "value": "Mocuba"
                },
                {
                    "label": "Gur\u00fa\u00e8",
                    "value": "Gur\u00fa\u00e8"
                },
                {
                    "label": "Chinde",
                    "value": "Chinde"
                },
                {
                    "label": "Alto Molocue",
                    "value": "Alto Molocue"
                }
            ],
            "Tete": [
                {
                    "label": "Tete",
                    "value": "Tete"
                }
            ],
            "Niassa": [
                {
                    "label": "Lichinga",
                    "value": "Lichinga"
                },
                {
                    "label": "Cuamba",
                    "value": "Cuamba"
                }
            ],
            "Cabo Delgado": [
                {
                    "label": "Pemba",
                    "value": "Pemba"
                },
                {
                    "label": "Mocimboa da Praia",
                    "value": "Mocimboa da Praia"
                },
                {
                    "label": "Montepuez",
                    "value": "Montepuez"
                }
            ],
            "Gaza": [
                {
                    "label": "Xai-Xai",
                    "value": "Xai-Xai"
                },
                {
                    "label": "Macia",
                    "value": "Macia"
                }
            ],
            "Inhambane": [
                {
                    "label": "Maxixe",
                    "value": "Maxixe"
                },
                {
                    "label": "Inhambane",
                    "value": "Inhambane"
                },
                {
                    "label": "Vilankulo",
                    "value": "Vilankulo"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Maputo City",
                "value": "Maputo City"
            },
            {
                "label": "Maputo",
                "value": "Maputo"
            },
            {
                "label": "Nampula",
                "value": "Nampula"
            },
            {
                "label": "Sofala",
                "value": "Sofala"
            },
            {
                "label": "Manica",
                "value": "Manica"
            },
            {
                "label": "Zambezia",
                "value": "Zambezia"
            },
            {
                "label": "Tete",
                "value": "Tete"
            },
            {
                "label": "Niassa",
                "value": "Niassa"
            },
            {
                "label": "Cabo Delgado",
                "value": "Cabo Delgado"
            },
            {
                "label": "Gaza",
                "value": "Gaza"
            },
            {
                "label": "Inhambane",
                "value": "Inhambane"
            }
        ]
    },
    "kenya": {
        "cities": {
            "Mombasa": [
                {
                    "label": "Mombasa City",
                    "value": "Mombasa City"
                }
            ],
            "Kwale": [
                {
                    "label": "Kwale",
                    "value": "Kwale"
                }
            ],
            "Kilifi": [
                {
                    "label": "Kilifi",
                    "value": "Kilifi"
                }
            ],
            "Tana River": [
                {
                    "label": "Hola",
                    "value": "Hola"
                }
            ],
            "Lamu": [
                {
                    "label": "Lamu",
                    "value": "Lamu"
                }
            ],
            "Taita\u2013Taveta": [
                {
                    "label": "Mwatate",
                    "value": "Mwatate"
                }
            ],
            "Garissa": [
                {
                    "label": "Garissa",
                    "value": "Garissa"
                }
            ],
            "Wajir": [
                {
                    "label": "Wajir",
                    "value": "Wajir"
                }
            ],
            "Mandera": [
                {
                    "label": "Mandera",
                    "value": "Mandera"
                }
            ],
            "Marsabit": [
                {
                    "label": "Marsabit",
                    "value": "Marsabit"
                }
            ],
            "Isiolo": [
                {
                    "label": "Isiolo",
                    "value": "Isiolo"
                }
            ],
            "Meru": [
                {
                    "label": "Meru",
                    "value": "Meru"
                }
            ],
            "Tharaka-Nithi": [
                {
                    "label": "Kathwana",
                    "value": "Kathwana"
                }
            ],
            "Embu": [
                {
                    "label": "Embu",
                    "value": "Embu"
                }
            ],
            "Kitui": [
                {
                    "label": "Kitui",
                    "value": "Kitui"
                }
            ],
            "Machakos": [
                {
                    "label": "Machakos",
                    "value": "Machakos"
                }
            ],
            "Makueni": [
                {
                    "label": "Wote",
                    "value": "Wote"
                }
            ],
            "Nyandarua": [
                {
                    "label": "Ol Kalou",
                    "value": "Ol Kalou"
                }
            ],
            "Nyeri": [
                {
                    "label": "Nyeri",
                    "value": "Nyeri"
                }
            ],
            "Kirinyaga": [
                {
                    "label": "Kerugoya\u00a0",
                    "value": "Kerugoya\u00a0"
                }
            ],
            "Murang\u2019a": [
                {
                    "label": "Murang\u2019a",
                    "value": "Murang\u2019a"
                }
            ],
            "Kiambu": [
                {
                    "label": "Kiambu",
                    "value": "Kiambu"
                }
            ],
            "Turkana": [
                {
                    "label": "Lodwar",
                    "value": "Lodwar"
                }
            ],
            "West Pokot": [
                {
                    "label": "Kapenguria",
                    "value": "Kapenguria"
                }
            ],
            "Samburu": [
                {
                    "label": "Maralal",
                    "value": "Maralal"
                }
            ],
            "Trans-Nzoia": [
                {
                    "label": "Kitale",
                    "value": "Kitale"
                }
            ],
            "Uasin Gishu": [
                {
                    "label": "Eldoret",
                    "value": "Eldoret"
                }
            ],
            "Elgeyo-Marakwet": [
                {
                    "label": "Iten",
                    "value": "Iten"
                }
            ],
            "Nandi": [
                {
                    "label": "Kapsabet",
                    "value": "Kapsabet"
                }
            ],
            "Baringo": [
                {
                    "label": "Kabarnet",
                    "value": "Kabarnet"
                }
            ],
            "Laikipia": [
                {
                    "label": "Nanyuki",
                    "value": "Nanyuki"
                }
            ],
            "Nakuru": [
                {
                    "label": "Nakuru City",
                    "value": "Nakuru City"
                }
            ],
            "Narok": [
                {
                    "label": "Narok",
                    "value": "Narok"
                }
            ],
            "Kajiado": [
                {
                    "label": "Kajiado",
                    "value": "Kajiado"
                }
            ],
            "Kericho": [
                {
                    "label": "Kericho",
                    "value": "Kericho"
                }
            ],
            "Bomet": [
                {
                    "label": "Bomet",
                    "value": "Bomet"
                }
            ],
            "Kakamega": [
                {
                    "label": "Kakamega",
                    "value": "Kakamega"
                }
            ],
            "Vihiga": [
                {
                    "label": "Mbale",
                    "value": "Mbale"
                }
            ],
            "Bungoma": [
                {
                    "label": "Bungoma",
                    "value": "Bungoma"
                }
            ],
            "Busia": [
                {
                    "label": "Busia",
                    "value": "Busia"
                }
            ],
            "Siaya": [
                {
                    "label": "Siaya",
                    "value": "Siaya"
                }
            ],
            "Kisumu": [
                {
                    "label": "Kisumu City",
                    "value": "Kisumu City"
                }
            ],
            "Homa Bay": [
                {
                    "label": "Homa Bay",
                    "value": "Homa Bay"
                }
            ],
            "Migori": [
                {
                    "label": "Migori",
                    "value": "Migori"
                }
            ],
            "Kisii": [
                {
                    "label": "Kisii",
                    "value": "Kisii"
                }
            ],
            "Nyamira": [
                {
                    "label": "Nyamira",
                    "value": "Nyamira"
                }
            ],
            "Nairobi\u00a0": [
                {
                    "label": "Nairobi City",
                    "value": "Nairobi City"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Mombasa",
                "value": "Mombasa"
            },
            {
                "label": "Kwale",
                "value": "Kwale"
            },
            {
                "label": "Kilifi",
                "value": "Kilifi"
            },
            {
                "label": "Tana River",
                "value": "Tana River"
            },
            {
                "label": "Lamu",
                "value": "Lamu"
            },
            {
                "label": "Taita\u2013Taveta",
                "value": "Taita\u2013Taveta"
            },
            {
                "label": "Garissa",
                "value": "Garissa"
            },
            {
                "label": "Wajir",
                "value": "Wajir"
            },
            {
                "label": "Mandera",
                "value": "Mandera"
            },
            {
                "label": "Marsabit",
                "value": "Marsabit"
            },
            {
                "label": "Isiolo",
                "value": "Isiolo"
            },
            {
                "label": "Meru",
                "value": "Meru"
            },
            {
                "label": "Tharaka-Nithi",
                "value": "Tharaka-Nithi"
            },
            {
                "label": "Embu",
                "value": "Embu"
            },
            {
                "label": "Kitui",
                "value": "Kitui"
            },
            {
                "label": "Machakos",
                "value": "Machakos"
            },
            {
                "label": "Makueni",
                "value": "Makueni"
            },
            {
                "label": "Nyandarua",
                "value": "Nyandarua"
            },
            {
                "label": "Nyeri",
                "value": "Nyeri"
            },
            {
                "label": "Kirinyaga",
                "value": "Kirinyaga"
            },
            {
                "label": "Murang\u2019a",
                "value": "Murang\u2019a"
            },
            {
                "label": "Kiambu",
                "value": "Kiambu"
            },
            {
                "label": "Turkana",
                "value": "Turkana"
            },
            {
                "label": "West Pokot",
                "value": "West Pokot"
            },
            {
                "label": "Samburu",
                "value": "Samburu"
            },
            {
                "label": "Trans-Nzoia",
                "value": "Trans-Nzoia"
            },
            {
                "label": "Uasin Gishu",
                "value": "Uasin Gishu"
            },
            {
                "label": "Elgeyo-Marakwet",
                "value": "Elgeyo-Marakwet"
            },
            {
                "label": "Nandi",
                "value": "Nandi"
            },
            {
                "label": "Baringo",
                "value": "Baringo"
            },
            {
                "label": "Laikipia",
                "value": "Laikipia"
            },
            {
                "label": "Nakuru",
                "value": "Nakuru"
            },
            {
                "label": "Narok",
                "value": "Narok"
            },
            {
                "label": "Kajiado",
                "value": "Kajiado"
            },
            {
                "label": "Kericho",
                "value": "Kericho"
            },
            {
                "label": "Bomet",
                "value": "Bomet"
            },
            {
                "label": "Kakamega",
                "value": "Kakamega"
            },
            {
                "label": "Vihiga",
                "value": "Vihiga"
            },
            {
                "label": "Bungoma",
                "value": "Bungoma"
            },
            {
                "label": "Busia",
                "value": "Busia"
            },
            {
                "label": "Siaya",
                "value": "Siaya"
            },
            {
                "label": "Kisumu",
                "value": "Kisumu"
            },
            {
                "label": "Homa Bay",
                "value": "Homa Bay"
            },
            {
                "label": "Migori",
                "value": "Migori"
            },
            {
                "label": "Kisii",
                "value": "Kisii"
            },
            {
                "label": "Nyamira",
                "value": "Nyamira"
            },
            {
                "label": "Nairobi\u00a0",
                "value": "Nairobi\u00a0"
            }
        ]
    },
    "ethiopia": {
        "cities": {
            "Addis Ababa": [
                {
                    "label": "Addis Ababa",
                    "value": "Addis Ababa"
                }
            ],
            "Afar": [
                {
                    "label": "Asayita",
                    "value": "Asayita"
                }
            ],
            "Amhara": [
                {
                    "label": "Bahir Dar",
                    "value": "Bahir Dar"
                }
            ],
            "Benishangul-Gumuz": [
                {
                    "label": "Asosa",
                    "value": "Asosa"
                }
            ],
            "Dire Dawa": [
                {
                    "label": "Dire Dawa",
                    "value": "Dire Dawa"
                }
            ],
            "Gambela": [
                {
                    "label": "Gambela",
                    "value": "Gambela"
                }
            ],
            "Harari": [
                {
                    "label": "Harar",
                    "value": "Harar"
                }
            ],
            "Oromia": [
                {
                    "label": "Addis Ababa",
                    "value": "Addis Ababa"
                }
            ],
            "Somali": [
                {
                    "label": "Jijiga",
                    "value": "Jijiga"
                }
            ],
            "Tigray": [
                {
                    "label": "Mekele",
                    "value": "Mekele"
                }
            ],
            "Southern Nations Nationalities and People (SNNPR)": [
                {
                    "label": "Hawassa",
                    "value": "Hawassa"
                }
            ],
            "Sidama": [
                {
                    "label": "Hawassa",
                    "value": "Hawassa"
                }
            ],
            "South West Ethiopia People Region (SWEPR)": [
                {
                    "label": "Bonga",
                    "value": "Bonga"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Addis Ababa",
                "value": "Addis Ababa"
            },
            {
                "label": "Afar",
                "value": "Afar"
            },
            {
                "label": "Amhara",
                "value": "Amhara"
            },
            {
                "label": "Benishangul-Gumuz",
                "value": "Benishangul-Gumuz"
            },
            {
                "label": "Dire Dawa",
                "value": "Dire Dawa"
            },
            {
                "label": "Gambela",
                "value": "Gambela"
            },
            {
                "label": "Harari",
                "value": "Harari"
            },
            {
                "label": "Oromia",
                "value": "Oromia"
            },
            {
                "label": "Somali",
                "value": "Somali"
            },
            {
                "label": "Tigray",
                "value": "Tigray"
            },
            {
                "label": "Southern Nations Nationalities and People (SNNPR)",
                "value": "Southern Nations Nationalities and People (SNNPR)"
            },
            {
                "label": "Sidama",
                "value": "Sidama"
            },
            {
                "label": "South West Ethiopia People Region (SWEPR)",
                "value": "South West Ethiopia People Region (SWEPR)"
            }
        ]
    },
    "uganda": {
        "cities": {
            "Northern Region": [
                {
                    "label": "Gulu",
                    "value": "Gulu"
                }
            ],
            "Central Region": [
                {
                    "label": "Kampala",
                    "value": "Kampala"
                }
            ],
            "Eastern Region": [
                {
                    "label": "Mbale",
                    "value": "Mbale"
                }
            ],
            "Western Region": [
                {
                    "label": "Mbarara",
                    "value": "Mbarara"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Northern Region",
                "value": "Northern Region"
            },
            {
                "label": "Central Region",
                "value": "Central Region"
            },
            {
                "label": "Eastern Region",
                "value": "Eastern Region"
            },
            {
                "label": "Western Region",
                "value": "Western Region"
            }
        ]
    },
    "rwanda": {
        "cities": {
            "Kigali": [
                {
                    "label": "Kigali",
                    "value": "Kigali"
                }
            ],
            "Southern (Amajyepfo)": [
                {
                    "label": "Nyanza",
                    "value": "Nyanza"
                }
            ],
            "Western (Iburengerazuba)": [
                {
                    "label": "Kibuye",
                    "value": "Kibuye"
                }
            ],
            "Northern (Amajyaruguru)": [
                {
                    "label": "Byumba",
                    "value": "Byumba"
                }
            ],
            "Eastern (Iburasirazuba)": [
                {
                    "label": "Rwamagana",
                    "value": "Rwamagana"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Kigali",
                "value": "Kigali"
            },
            {
                "label": "Southern (Amajyepfo)",
                "value": "Southern (Amajyepfo)"
            },
            {
                "label": "Western (Iburengerazuba)",
                "value": "Western (Iburengerazuba)"
            },
            {
                "label": "Northern (Amajyaruguru)",
                "value": "Northern (Amajyaruguru)"
            },
            {
                "label": "Eastern (Iburasirazuba)",
                "value": "Eastern (Iburasirazuba)"
            }
        ]
    },
    "tanzania": {
        "cities": {
            "Arusha Region": [
                {
                    "label": "Arusha",
                    "value": "Arusha"
                }
            ],
            "Dar es Salaam Region": [
                {
                    "label": "Dar es Salaam",
                    "value": "Dar es Salaam"
                }
            ],
            "Dodoma Region": [
                {
                    "label": "Dodoma",
                    "value": "Dodoma"
                }
            ],
            "Geita Region": [
                {
                    "label": "Geita",
                    "value": "Geita"
                }
            ],
            "Iringa Region": [
                {
                    "label": "Iringa",
                    "value": "Iringa"
                }
            ],
            "Kagera Region": [
                {
                    "label": "Bukoba",
                    "value": "Bukoba"
                }
            ],
            "Katavi Region": [
                {
                    "label": "Mpanda",
                    "value": "Mpanda"
                }
            ],
            "Kigoma Region": [
                {
                    "label": "Kigoma",
                    "value": "Kigoma"
                }
            ],
            "Kilimanjaro Region": [
                {
                    "label": "Moshi",
                    "value": "Moshi"
                }
            ],
            "Lindi Region": [
                {
                    "label": "Lindi",
                    "value": "Lindi"
                }
            ],
            "Manyara Region": [
                {
                    "label": "Babati",
                    "value": "Babati"
                }
            ],
            "Mara Region": [
                {
                    "label": "Musoma",
                    "value": "Musoma"
                }
            ],
            "Mbeya Region": [
                {
                    "label": "Mbeya",
                    "value": "Mbeya"
                }
            ],
            "Mjini Magharibi Region (Zanzibar Urban West)": [
                {
                    "label": "Zanzibar City",
                    "value": "Zanzibar City"
                }
            ],
            "Morogoro Region": [
                {
                    "label": "Morogoro",
                    "value": "Morogoro"
                }
            ],
            "Mtwara Region": [
                {
                    "label": "Mtwara",
                    "value": "Mtwara"
                }
            ],
            "Mwanza Region": [
                {
                    "label": "Mwanza",
                    "value": "Mwanza"
                }
            ],
            "Njombe Region": [
                {
                    "label": "Njombe",
                    "value": "Njombe"
                }
            ],
            "Pemba North Region": [
                {
                    "label": "Wete",
                    "value": "Wete"
                }
            ],
            "Pemba South Region": [
                {
                    "label": "Chake-Chake",
                    "value": "Chake-Chake"
                }
            ],
            "Pwani Region": [
                {
                    "label": "Kibaha",
                    "value": "Kibaha"
                }
            ],
            "Rukwa Region": [
                {
                    "label": "Sumbawanga",
                    "value": "Sumbawanga"
                }
            ],
            "Ruvuma Region": [
                {
                    "label": "Songea",
                    "value": "Songea"
                }
            ],
            "Shinyanga Region": [
                {
                    "label": "Shinyanga",
                    "value": "Shinyanga"
                }
            ],
            "Simiyu Region": [
                {
                    "label": "Bariadi",
                    "value": "Bariadi"
                }
            ],
            "Singida Region": [
                {
                    "label": "Singida",
                    "value": "Singida"
                }
            ],
            "Songwe Region": [
                {
                    "label": "Vwawa",
                    "value": "Vwawa"
                }
            ],
            "Tabora Region": [
                {
                    "label": "Tabora",
                    "value": "Tabora"
                }
            ],
            "Tanga Region": [
                {
                    "label": "Tanga",
                    "value": "Tanga"
                }
            ],
            "Unguja North Region (Zanzibar North))": [
                {
                    "label": "Mkokotoni",
                    "value": "Mkokotoni"
                }
            ],
            "Unguja South Region (Zanzibar South)": [
                {
                    "label": "Koani",
                    "value": "Koani"
                }
            ]
        },
        "districts": [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Arusha Region",
                "value": "Arusha Region"
            },
            {
                "label": "Dar es Salaam Region",
                "value": "Dar es Salaam Region"
            },
            {
                "label": "Dodoma Region",
                "value": "Dodoma Region"
            },
            {
                "label": "Geita Region",
                "value": "Geita Region"
            },
            {
                "label": "Iringa Region",
                "value": "Iringa Region"
            },
            {
                "label": "Kagera Region",
                "value": "Kagera Region"
            },
            {
                "label": "Katavi Region",
                "value": "Katavi Region"
            },
            {
                "label": "Kigoma Region",
                "value": "Kigoma Region"
            },
            {
                "label": "Kilimanjaro Region",
                "value": "Kilimanjaro Region"
            },
            {
                "label": "Lindi Region",
                "value": "Lindi Region"
            },
            {
                "label": "Manyara Region",
                "value": "Manyara Region"
            },
            {
                "label": "Mara Region",
                "value": "Mara Region"
            },
            {
                "label": "Mbeya Region",
                "value": "Mbeya Region"
            },
            {
                "label": "Mjini Magharibi Region (Zanzibar Urban West)",
                "value": "Mjini Magharibi Region (Zanzibar Urban West)"
            },
            {
                "label": "Morogoro Region",
                "value": "Morogoro Region"
            },
            {
                "label": "Mtwara Region",
                "value": "Mtwara Region"
            },
            {
                "label": "Mwanza Region",
                "value": "Mwanza Region"
            },
            {
                "label": "Njombe Region",
                "value": "Njombe Region"
            },
            {
                "label": "Pemba North Region",
                "value": "Pemba North Region"
            },
            {
                "label": "Pemba South Region",
                "value": "Pemba South Region"
            },
            {
                "label": "Pwani Region",
                "value": "Pwani Region"
            },
            {
                "label": "Rukwa Region",
                "value": "Rukwa Region"
            },
            {
                "label": "Ruvuma Region",
                "value": "Ruvuma Region"
            },
            {
                "label": "Shinyanga Region",
                "value": "Shinyanga Region"
            },
            {
                "label": "Simiyu Region",
                "value": "Simiyu Region"
            },
            {
                "label": "Singida Region",
                "value": "Singida Region"
            },
            {
                "label": "Songwe Region",
                "value": "Songwe Region"
            },
            {
                "label": "Tabora Region",
                "value": "Tabora Region"
            },
            {
                "label": "Tanga Region",
                "value": "Tanga Region"
            },
            {
                "label": "Unguja North Region (Zanzibar North))",
                "value": "Unguja North Region (Zanzibar North))"
            },
            {
                "label": "Unguja South Region (Zanzibar South)",
                "value": "Unguja South Region (Zanzibar South)"
            }
        ]
    },
    "somalia": {
        "cities": {
            "Galmudug": [
                { "label": "Dhusamareb", "value": "Dhusamareb" }
            ],
            "Hirshabelle": [
                { "label": "Jowhar", "value": "Jowhar" }
            ],
            "Jubaland": [
                { "label": "Kismayo", "value": "Kismayo" }
            ],
            "Khatumo": [
                { "label": "Las Anod", "value": "Las Anod" }
            ],
            "Puntland": [
                { "label": "Garoowe", "value": "Garoowe" }
            ],
            "Somaliland": [
                { "label": "Hargeisa", "value": "Hargeisa" }
            ],
            "South West": [
                { "label": "Barawa", "value": "Barawa" }
            ],
            "Banaadir": [
                { "label": "Mogadishu", "value": "Mogadishu" }
            ]
        },
        "districts" : [
            {
                "label": "Select a state",
                "value": ""
            },
            {
                "label": "Galmudug",
                "value": "Galmudug"
            },
            {
                "label": "Hirshabelle",
                "value": "Hirshabelle"
            },
            {
                "label": "Jubaland",
                "value": "Jubaland"
            },
            {
                "label": "Khatumo",
                "value": "Khatumo"
            },
            {
                "label": "Puntland",
                "value": "Puntland"
            },
            {
                "label": "Somaliland",
                "value": "Somaliland"
            },
            {
                "label": "South West",
                "value": "South West"
            },
            {
                "label": "Banaadir",
                "value": "Banaadir"
            }
        ]
    }
}

// export const COUNTRY_CITY_DISTRICT = {
//     "mauritius": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Port Louis (capital)",
//                 "value": "Port Louis (capital)"
//             },
//             {
//                 "label": "Curepipe",
//                 "value": "Curepipe"
//             },
//             {
//                 "label": "Beau Bassin-Rose Hill",
//                 "value": "Beau Bassin-Rose Hill"
//             },
//             {
//                 "label": "Quatre Bornes",
//                 "value": "Quatre Bornes"
//             },
//             {
//                 "label": "Vacoas-Phoenix",
//                 "value": "Vacoas-Phoenix"
//             },
//             {
//                 "label": "Mahebourg",
//                 "value": "Mahebourg"
//             },
//             {
//                 "label": "Grand Baie",
//                 "value": "Grand Baie"
//             },
//             {
//                 "label": "Flic en Flac",
//                 "value": "Flic en Flac"
//             },
//             {
//                 "label": "Tamarin",
//                 "value": "Tamarin"
//             },
//             {
//                 "label": "Goodlands",
//                 "value": "Goodlands"
//             },
//             {
//                 "label": "Triolet",
//                 "value": "Triolet"
//             },
//             {
//                 "label": "Rivi\u00e8re du Rempart",
//                 "value": "Rivi\u00e8re du Rempart"
//             },
//             {
//                 "label": "Pamplemousses",
//                 "value": "Pamplemousses"
//             },
//             {
//                 "label": "Plaines Wilhems",
//                 "value": "Plaines Wilhems"
//             },
//             {
//                 "label": "Savanne",
//                 "value": "Savanne"
//             },
//             {
//                 "label": "Black River",
//                 "value": "Black River"
//             },
//             {
//                 "label": "Rodrigues",
//                 "value": "Rodrigues"
//             },
//             {
//                 "label": "Agalega",
//                 "value": "Agalega"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Flacq",
//                 "value": "Flacq"
//             },
//             {
//                 "label": "Grand Port",
//                 "value": "Grand Port"
//             },
//             {
//                 "label": "Moka",
//                 "value": "Moka"
//             },
//             {
//                 "label": "Pamplemousses",
//                 "value": "Pamplemousses"
//             },
//             {
//                 "label": "Plaines Wilhems",
//                 "value": "Plaines Wilhems"
//             },
//             {
//                 "label": "Port Louis",
//                 "value": "Port Louis"
//             },
//             {
//                 "label": "Rivi\u00e8re du Rempart",
//                 "value": "Rivi\u00e8re du Rempart"
//             },
//             {
//                 "label": "Rivi\u00e8re Noire",
//                 "value": "Rivi\u00e8re Noire"
//             },
//             {
//                 "label": "Savanne",
//                 "value": "Savanne"
//             }
//         ]
//     },
//     "mozambique": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Maputo",
//                 "value": "Maputo"
//             },
//             {
//                 "label": "Matola",
//                 "value": "Matola"
//             },
//             {
//                 "label": "Nampula",
//                 "value": "Nampula"
//             },
//             {
//                 "label": "Beira",
//                 "value": "Beira"
//             },
//             {
//                 "label": "Chimoio",
//                 "value": "Chimoio"
//             },
//             {
//                 "label": "Quelimane",
//                 "value": "Quelimane"
//             },
//             {
//                 "label": "Tete",
//                 "value": "Tete"
//             },
//             {
//                 "label": "Nacala",
//                 "value": "Nacala"
//             },
//             {
//                 "label": "Lichinga",
//                 "value": "Lichinga"
//             },
//             {
//                 "label": "Pemba",
//                 "value": "Pemba"
//             },
//             {
//                 "label": "Mocuba",
//                 "value": "Mocuba"
//             },
//             {
//                 "label": "Gur\u00fa\u00e8",
//                 "value": "Gur\u00fa\u00e8"
//             },
//             {
//                 "label": "Xai-Xai",
//                 "value": "Xai-Xai"
//             },
//             {
//                 "label": "Maxixe",
//                 "value": "Maxixe"
//             },
//             {
//                 "label": "Angoche",
//                 "value": "Angoche"
//             },
//             {
//                 "label": "Inhambane",
//                 "value": "Inhambane"
//             },
//             {
//                 "label": "Cuamba",
//                 "value": "Cuamba"
//             },
//             {
//                 "label": "Ressano Garcia",
//                 "value": "Ressano Garcia"
//             },
//             {
//                 "label": "Dondo",
//                 "value": "Dondo"
//             },
//             {
//                 "label": "Ilha de Mo\u00e7ambique",
//                 "value": "Ilha de Mo\u00e7ambique"
//             },
//             {
//                 "label": "Mutuali",
//                 "value": "Mutuali"
//             },
//             {
//                 "label": "Mocimboa da Praia",
//                 "value": "Mocimboa da Praia"
//             },
//             {
//                 "label": "Macia",
//                 "value": "Macia"
//             },
//             {
//                 "label": "Chinde",
//                 "value": "Chinde"
//             },
//             {
//                 "label": "Alto Molocue",
//                 "value": "Alto Molocue"
//             },
//             {
//                 "label": "Montepuez",
//                 "value": "Montepuez"
//             },
//             {
//                 "label": "Nhamatanda",
//                 "value": "Nhamatanda"
//             },
//             {
//                 "label": "Manica",
//                 "value": "Manica"
//             },
//             {
//                 "label": "Gondola",
//                 "value": "Gondola"
//             },
//             {
//                 "label": "Vilankulo",
//                 "value": "Vilankulo"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Cabo Delgado",
//                 "value": "Cabo Delgado"
//             },
//             {
//                 "label": "Gaza",
//                 "value": "Gaza"
//             },
//             {
//                 "label": "Inhambane",
//                 "value": "Inhambane"
//             },
//             {
//                 "label": "Manica",
//                 "value": "Manica"
//             },
//             {
//                 "label": "Maputo",
//                 "value": "Maputo"
//             },
//             {
//                 "label": "Nampula",
//                 "value": "Nampula"
//             },
//             {
//                 "label": "Niassa",
//                 "value": "Niassa"
//             },
//             {
//                 "label": "Sofala",
//                 "value": "Sofala"
//             },
//             {
//                 "label": "Tete",
//                 "value": "Tete"
//             },
//             {
//                 "label": "Zambezia",
//                 "value": "Zambezia"
//             }
//         ]
//     },
//     "kenya": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Mombasa City",
//                 "value": "Mombasa City"
//             },
//             {
//                 "label": "Kwale",
//                 "value": "Kwale"
//             },
//             {
//                 "label": "Kilifi",
//                 "value": "Kilifi"
//             },
//             {
//                 "label": "Hola",
//                 "value": "Hola"
//             },
//             {
//                 "label": "Lamu",
//                 "value": "Lamu"
//             },
//             {
//                 "label": "Mwatate",
//                 "value": "Mwatate"
//             },
//             {
//                 "label": "Garissa",
//                 "value": "Garissa"
//             },
//             {
//                 "label": "Wajir",
//                 "value": "Wajir"
//             },
//             {
//                 "label": "Mandera",
//                 "value": "Mandera"
//             },
//             {
//                 "label": "Marsabit",
//                 "value": "Marsabit"
//             },
//             {
//                 "label": "Isiolo",
//                 "value": "Isiolo"
//             },
//             {
//                 "label": "Meru",
//                 "value": "Meru"
//             },
//             {
//                 "label": "Kathwana",
//                 "value": "Kathwana"
//             },
//             {
//                 "label": "Embu",
//                 "value": "Embu"
//             },
//             {
//                 "label": "Kitui",
//                 "value": "Kitui"
//             },
//             {
//                 "label": "Machakos",
//                 "value": "Machakos"
//             },
//             {
//                 "label": "Wote",
//                 "value": "Wote"
//             },
//             {
//                 "label": "Ol Kalou",
//                 "value": "Ol Kalou"
//             },
//             {
//                 "label": "Nyeri",
//                 "value": "Nyeri"
//             },
//             {
//                 "label": "Kerugoya\u00a0",
//                 "value": "Kerugoya\u00a0"
//             },
//             {
//                 "label": "Murang\u2019a",
//                 "value": "Murang\u2019a"
//             },
//             {
//                 "label": "Kiambu",
//                 "value": "Kiambu"
//             },
//             {
//                 "label": "Lodwar",
//                 "value": "Lodwar"
//             },
//             {
//                 "label": "Kapenguria",
//                 "value": "Kapenguria"
//             },
//             {
//                 "label": "Maralal",
//                 "value": "Maralal"
//             },
//             {
//                 "label": "Kitale",
//                 "value": "Kitale"
//             },
//             {
//                 "label": "Eldoret",
//                 "value": "Eldoret"
//             },
//             {
//                 "label": "Iten",
//                 "value": "Iten"
//             },
//             {
//                 "label": "Kapsabet",
//                 "value": "Kapsabet"
//             },
//             {
//                 "label": "Kabarnet",
//                 "value": "Kabarnet"
//             },
//             {
//                 "label": "Nanyuki",
//                 "value": "Nanyuki"
//             },
//             {
//                 "label": "Nakuru City",
//                 "value": "Nakuru City"
//             },
//             {
//                 "label": "Narok",
//                 "value": "Narok"
//             },
//             {
//                 "label": "Kajiado",
//                 "value": "Kajiado"
//             },
//             {
//                 "label": "Kericho",
//                 "value": "Kericho"
//             },
//             {
//                 "label": "Bomet",
//                 "value": "Bomet"
//             },
//             {
//                 "label": "Kakamega",
//                 "value": "Kakamega"
//             },
//             {
//                 "label": "Mbale",
//                 "value": "Mbale"
//             },
//             {
//                 "label": "Bungoma",
//                 "value": "Bungoma"
//             },
//             {
//                 "label": "Busia",
//                 "value": "Busia"
//             },
//             {
//                 "label": "Siaya",
//                 "value": "Siaya"
//             },
//             {
//                 "label": "Kisumu City",
//                 "value": "Kisumu City"
//             },
//             {
//                 "label": "Homa Bay",
//                 "value": "Homa Bay"
//             },
//             {
//                 "label": "Migori",
//                 "value": "Migori"
//             },
//             {
//                 "label": "Kisii",
//                 "value": "Kisii"
//             },
//             {
//                 "label": "Nyamira",
//                 "value": "Nyamira"
//             },
//             {
//                 "label": "Nairobi City",
//                 "value": "Nairobi City"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Mombasa",
//                 "value": "Mombasa"
//             },
//             {
//                 "label": "Kwale",
//                 "value": "Kwale"
//             },
//             {
//                 "label": "Kilifi",
//                 "value": "Kilifi"
//             },
//             {
//                 "label": "Tana River",
//                 "value": "Tana River"
//             },
//             {
//                 "label": "Lamu",
//                 "value": "Lamu"
//             },
//             {
//                 "label": "Taita\u2013Taveta",
//                 "value": "Taita\u2013Taveta"
//             },
//             {
//                 "label": "Garissa",
//                 "value": "Garissa"
//             },
//             {
//                 "label": "Wajir",
//                 "value": "Wajir"
//             },
//             {
//                 "label": "Mandera",
//                 "value": "Mandera"
//             },
//             {
//                 "label": "Marsabit",
//                 "value": "Marsabit"
//             },
//             {
//                 "label": "Isiolo",
//                 "value": "Isiolo"
//             },
//             {
//                 "label": "Meru",
//                 "value": "Meru"
//             },
//             {
//                 "label": "Tharaka-Nithi",
//                 "value": "Tharaka-Nithi"
//             },
//             {
//                 "label": "Embu",
//                 "value": "Embu"
//             },
//             {
//                 "label": "Kitui",
//                 "value": "Kitui"
//             },
//             {
//                 "label": "Machakos",
//                 "value": "Machakos"
//             },
//             {
//                 "label": "Makueni",
//                 "value": "Makueni"
//             },
//             {
//                 "label": "Nyandarua",
//                 "value": "Nyandarua"
//             },
//             {
//                 "label": "Nyeri",
//                 "value": "Nyeri"
//             },
//             {
//                 "label": "Kirinyaga",
//                 "value": "Kirinyaga"
//             },
//             {
//                 "label": "Murang\u2019a",
//                 "value": "Murang\u2019a"
//             },
//             {
//                 "label": "Kiambu",
//                 "value": "Kiambu"
//             },
//             {
//                 "label": "Turkana",
//                 "value": "Turkana"
//             },
//             {
//                 "label": "West Pokot",
//                 "value": "West Pokot"
//             },
//             {
//                 "label": "Samburu",
//                 "value": "Samburu"
//             },
//             {
//                 "label": "Trans-Nzoia",
//                 "value": "Trans-Nzoia"
//             },
//             {
//                 "label": "Uasin Gishu",
//                 "value": "Uasin Gishu"
//             },
//             {
//                 "label": "Elgeyo-Marakwet",
//                 "value": "Elgeyo-Marakwet"
//             },
//             {
//                 "label": "Nandi",
//                 "value": "Nandi"
//             },
//             {
//                 "label": "Baringo",
//                 "value": "Baringo"
//             },
//             {
//                 "label": "Laikipia",
//                 "value": "Laikipia"
//             },
//             {
//                 "label": "Nakuru",
//                 "value": "Nakuru"
//             },
//             {
//                 "label": "Narok",
//                 "value": "Narok"
//             },
//             {
//                 "label": "Kajiado",
//                 "value": "Kajiado"
//             },
//             {
//                 "label": "Kericho",
//                 "value": "Kericho"
//             },
//             {
//                 "label": "Bomet",
//                 "value": "Bomet"
//             },
//             {
//                 "label": "Kakamega",
//                 "value": "Kakamega"
//             },
//             {
//                 "label": "Vihiga",
//                 "value": "Vihiga"
//             },
//             {
//                 "label": "Bungoma",
//                 "value": "Bungoma"
//             },
//             {
//                 "label": "Busia",
//                 "value": "Busia"
//             },
//             {
//                 "label": "Siaya",
//                 "value": "Siaya"
//             },
//             {
//                 "label": "Kisumu",
//                 "value": "Kisumu"
//             },
//             {
//                 "label": "Homa Bay",
//                 "value": "Homa Bay"
//             },
//             {
//                 "label": "Migori",
//                 "value": "Migori"
//             },
//             {
//                 "label": "Kisii",
//                 "value": "Kisii"
//             },
//             {
//                 "label": "Nyamira",
//                 "value": "Nyamira"
//             },
//             {
//                 "label": "Nairobi\u00a0",
//                 "value": "Nairobi\u00a0"
//             }
//         ]
//     },
//     "ethiopia": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Addis Ababa",
//                 "value": "Addis Ababa"
//             },
//             {
//                 "label": "Asayita",
//                 "value": "Asayita"
//             },
//             {
//                 "label": "Bahir Dar",
//                 "value": "Bahir Dar"
//             },
//             {
//                 "label": "Asosa",
//                 "value": "Asosa"
//             },
//             {
//                 "label": "Dire Dawa",
//                 "value": "Dire Dawa"
//             },
//             {
//                 "label": "Gambela",
//                 "value": "Gambela"
//             },
//             {
//                 "label": "Harar",
//                 "value": "Harar"
//             },
//             {
//                 "label": "Jijiga",
//                 "value": "Jijiga"
//             },
//             {
//                 "label": "Mekele",
//                 "value": "Mekele"
//             },
//             {
//                 "label": "Awasa",
//                 "value": "Awasa"
//             },
//             {
//                 "label": "Bonga",
//                 "value": "Bonga"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Addis Ababa",
//                 "value": "Addis Ababa"
//             },
//             {
//                 "label": "Afar",
//                 "value": "Afar"
//             },
//             {
//                 "label": "Amhara",
//                 "value": "Amhara"
//             },
//             {
//                 "label": "Benishangul-Gumuz",
//                 "value": "Benishangul-Gumuz"
//             },
//             {
//                 "label": "Dire Dawa",
//                 "value": "Dire Dawa"
//             },
//             {
//                 "label": "Gambela",
//                 "value": "Gambela"
//             },
//             {
//                 "label": "Harari",
//                 "value": "Harari"
//             },
//             {
//                 "label": "Oromia",
//                 "value": "Oromia"
//             },
//             {
//                 "label": "Somali",
//                 "value": "Somali"
//             },
//             {
//                 "label": "Tigray",
//                 "value": "Tigray"
//             },
//             {
//                 "label": "Southern Nations Nationalities and People (SNNPR)",
//                 "value": "Southern Nations Nationalities and People (SNNPR)"
//             },
//             {
//                 "label": "Sidama",
//                 "value": "Sidama"
//             },
//             {
//                 "label": "South West Ethiopia People Region (SWEPR)",
//                 "value": "South West Ethiopia People Region (SWEPR)"
//             }
//         ]
//     },
//     "uganda": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Gulu",
//                 "value": "Gulu"
//             },
//             {
//                 "label": "Kampala",
//                 "value": "Kampala"
//             },
//             {
//                 "label": "Mbale",
//                 "value": "Mbale"
//             },
//             {
//                 "label": "Mbarara",
//                 "value": "Mbarara"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Northern Region",
//                 "value": "Northern Region"
//             },
//             {
//                 "label": "Central Region",
//                 "value": "Central Region"
//             },
//             {
//                 "label": "Eastern Region",
//                 "value": "Eastern Region"
//             },
//             {
//                 "label": "Western Region",
//                 "value": "Western Region"
//             }
//         ]
//     },
//     "rwanda": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Kigali",
//                 "value": "Kigali"
//             },
//             {
//                 "label": "Nyanza",
//                 "value": "Nyanza"
//             },
//             {
//                 "label": "Kibuye",
//                 "value": "Kibuye"
//             },
//             {
//                 "label": "Byumba",
//                 "value": "Byumba"
//             },
//             {
//                 "label": "Rwamagana",
//                 "value": "Rwamagana"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Kigali",
//                 "value": "Kigali"
//             },
//             {
//                 "label": "Southern (Amajyepfo)",
//                 "value": "Southern (Amajyepfo)"
//             },
//             {
//                 "label": "Western (Iburengerazuba)",
//                 "value": "Western (Iburengerazuba)"
//             },
//             {
//                 "label": "Northern (Amajyaruguru)",
//                 "value": "Northern (Amajyaruguru)"
//             },
//             {
//                 "label": "Eastern (Iburasirazuba)",
//                 "value": "Eastern (Iburasirazuba)"
//             }
//         ]
//     },
//     "tanzania": {
//         "cities": [
//             {
//                 "label": "Select a city",
//                 "value": ""
//             },
//             {
//                 "label": "Arusha",
//                 "value": "Arusha"
//             },
//             {
//                 "label": "Dar es Salaam",
//                 "value": "Dar es Salaam"
//             },
//             {
//                 "label": "Dodoma",
//                 "value": "Dodoma"
//             },
//             {
//                 "label": "Geita",
//                 "value": "Geita"
//             },
//             {
//                 "label": "Iringa",
//                 "value": "Iringa"
//             },
//             {
//                 "label": "Bukoba",
//                 "value": "Bukoba"
//             },
//             {
//                 "label": "Mpanda",
//                 "value": "Mpanda"
//             },
//             {
//                 "label": "Kigoma",
//                 "value": "Kigoma"
//             },
//             {
//                 "label": "Moshi",
//                 "value": "Moshi"
//             },
//             {
//                 "label": "Lindi",
//                 "value": "Lindi"
//             },
//             {
//                 "label": "Babati",
//                 "value": "Babati"
//             },
//             {
//                 "label": "Musoma",
//                 "value": "Musoma"
//             },
//             {
//                 "label": "Mbeya",
//                 "value": "Mbeya"
//             },
//             {
//                 "label": "Zanzibar City",
//                 "value": "Zanzibar City"
//             },
//             {
//                 "label": "Morogoro",
//                 "value": "Morogoro"
//             },
//             {
//                 "label": "Mtwara",
//                 "value": "Mtwara"
//             },
//             {
//                 "label": "Mwanza",
//                 "value": "Mwanza"
//             },
//             {
//                 "label": "Njombe",
//                 "value": "Njombe"
//             },
//             {
//                 "label": "Wete",
//                 "value": "Wete"
//             },
//             {
//                 "label": "Chake-Chake",
//                 "value": "Chake-Chake"
//             },
//             {
//                 "label": "Kibaha",
//                 "value": "Kibaha"
//             },
//             {
//                 "label": "Sumbawanga",
//                 "value": "Sumbawanga"
//             },
//             {
//                 "label": "Songea",
//                 "value": "Songea"
//             },
//             {
//                 "label": "Shinyanga",
//                 "value": "Shinyanga"
//             },
//             {
//                 "label": "Bariadi",
//                 "value": "Bariadi"
//             },
//             {
//                 "label": "Singida",
//                 "value": "Singida"
//             },
//             {
//                 "label": "Vwawa",
//                 "value": "Vwawa"
//             },
//             {
//                 "label": "Tabora",
//                 "value": "Tabora"
//             },
//             {
//                 "label": "Tanga",
//                 "value": "Tanga"
//             },
//             {
//                 "label": "Mkokotoni",
//                 "value": "Mkokotoni"
//             },
//             {
//                 "label": "Koani",
//                 "value": "Koani"
//             }
//         ],
//         "districts": [
//             {
//                 "label": "Select a state",
//                 "value": ""
//             },
//             {
//                 "label": "Arusha Region",
//                 "value": "Arusha Region"
//             },
//             {
//                 "label": "Dar es Salaam Region",
//                 "value": "Dar es Salaam Region"
//             },
//             {
//                 "label": "Dodoma Region",
//                 "value": "Dodoma Region"
//             },
//             {
//                 "label": "Geita Region",
//                 "value": "Geita Region"
//             },
//             {
//                 "label": "Iringa Region",
//                 "value": "Iringa Region"
//             },
//             {
//                 "label": "Kagera Region",
//                 "value": "Kagera Region"
//             },
//             {
//                 "label": "Katavi Region",
//                 "value": "Katavi Region"
//             },
//             {
//                 "label": "Kigoma Region",
//                 "value": "Kigoma Region"
//             },
//             {
//                 "label": "Kilimanjaro Region",
//                 "value": "Kilimanjaro Region"
//             },
//             {
//                 "label": "Lindi Region",
//                 "value": "Lindi Region"
//             },
//             {
//                 "label": "Manyara Region",
//                 "value": "Manyara Region"
//             },
//             {
//                 "label": "Mara Region",
//                 "value": "Mara Region"
//             },
//             {
//                 "label": "Mbeya Region",
//                 "value": "Mbeya Region"
//             },
//             {
//                 "label": "Mjini Magharibi Region (Zanzibar Urban West)",
//                 "value": "Mjini Magharibi Region (Zanzibar Urban West)"
//             },
//             {
//                 "label": "Morogoro Region",
//                 "value": "Morogoro Region"
//             },
//             {
//                 "label": "Mtwara Region",
//                 "value": "Mtwara Region"
//             },
//             {
//                 "label": "Mwanza Region",
//                 "value": "Mwanza Region"
//             },
//             {
//                 "label": "Njombe Region",
//                 "value": "Njombe Region"
//             },
//             {
//                 "label": "Pemba North Region",
//                 "value": "Pemba North Region"
//             },
//             {
//                 "label": "Pemba South Region",
//                 "value": "Pemba South Region"
//             },
//             {
//                 "label": "Pwani Region",
//                 "value": "Pwani Region"
//             },
//             {
//                 "label": "Rukwa Region",
//                 "value": "Rukwa Region"
//             },
//             {
//                 "label": "Ruvuma Region",
//                 "value": "Ruvuma Region"
//             },
//             {
//                 "label": "Shinyanga Region",
//                 "value": "Shinyanga Region"
//             },
//             {
//                 "label": "Simiyu Region",
//                 "value": "Simiyu Region"
//             },
//             {
//                 "label": "Singida Region",
//                 "value": "Singida Region"
//             },
//             {
//                 "label": "Songwe Region",
//                 "value": "Songwe Region"
//             },
//             {
//                 "label": "Tabora Region",
//                 "value": "Tabora Region"
//             },
//             {
//                 "label": "Tanga Region",
//                 "value": "Tanga Region"
//             },
//             {
//                 "label": "Unguja North Region (Zanzibar North))",
//                 "value": "Unguja North Region (Zanzibar North))"
//             },
//             {
//                 "label": "Unguja South Region (Zanzibar South)",
//                 "value": "Unguja South Region (Zanzibar South)"
//             }
//         ]
//     }
// }