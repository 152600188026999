import CompanyCars from "./CompanyCars";
import EducationBenefit from "./EducationBenefit";
import Loans from "./Loans";
import LongServiceAwards from "./LongServiceAwards";
import Others from "./Others";
import SportsClub from "./SportsClub";

export default function BenefitOtherBenefits(props) {
  const { data } = props;
  return (
    <div>
      <EducationBenefit data={data?.education} />
      <SportsClub data={data?.sports_gym} />
      <Loans data={data?.loans} />
      <CompanyCars data={data?.company_car} />
      <LongServiceAwards data={data?.long_term_awards} />
      <Others data={data?.other} />
    </div>

  
  );
}
