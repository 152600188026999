import BlockHeading from "../../../../../../components/forms/BlockHeading";
import Label from "../../../../../../components/forms/InputLabel";
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle";
import { num_generator } from "../../utils/generators";
const WORK_DAYS = [
    { label: "Monday, Tuesday, Wednesday, Thursday, Friday", value: "Monday, Tuesday, Wednesday, Thursday, Friday" },
    { label: "Tuesday, Wednesday, Thursday, Friday, Saturday", value: "Tuesday, Wednesday, Thursday, Friday, Saturday" },
    { label: "Sunday, Monday, Tuesday, Wednesday, Thursday", value: "Sunday, Monday, Tuesday, Wednesday, Thursday" },
    { label: "Monday, Tuesday, Wednesday, Thursday, Friday, Saturday", value: "Monday, Tuesday, Wednesday, Thursday, Friday, Saturday" },
    // { label: "Other", value: "Other" },
]

const WORK_HOURS = [
    { label: "7am-4pm", value: "7am-4pm" },
    { label: "8am-5pm", value: "8am-5pm" },
    { label: "8am-6pm", value: "8am-6pm" },
    { label: "9am-5pm", value: "9am-5pm" },
    { label: "9am-6pm", value: "9am-6pm" },
    { label: "Flexible hours between 8am-6pm", value: "Flexible hours between 8am-6pm" },
]

const WORK_REGIME = [
    { label: "Work from office (WFO)", value: "Work from office (WFO)" },
    { label: "Work from home (WFH)", value: "Work from home (WFH)" },
    { label: "Hybrid (WFH and WFO)", value: "Hybrid (WFH and WFO)" },
]




export default function WorkEnvironment(props) {
    const id = 'work_environment'
    const { watch, errors, control, register } = props


    return (
        <div>
            <BlockHeading text="5. WORK ENVIRONMENT" />

            <div className="my-2">

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                    <div>
                        <Label className="mb-2" htmlFor="work_days"
                            text="Official working days for majority of employees (>75%)" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.work_days`}
                            errors={errors} watch={watch}
                            options={WORK_DAYS} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2" htmlFor="work_hours"
                            text="Official working hours for majority of employees (>75%) &nbsp;" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.work_hours`}
                            errors={errors} watch={watch}
                            options={WORK_HOURS} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2" htmlFor="work_regime"
                            text="Work regime for majority of employees (>75%) &nbsp; &nbsp; &nbsp; &nbsp;" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.work_regime`}
                            errors={errors} watch={watch}
                            options={WORK_REGIME} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2" htmlFor="work_hours"
                            text="Total working hours per week &nbsp; &nbsp; &nbsp; &nbsp;" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.work_hours_week`}
                            errors={errors} watch={watch}
                            options={num_generator(60, false)} control={control} />
                    </div>
                </div>
            </div>

        </div>
    )
}