export const PublicSector = {
    "Not for Profit": {
        "Programs | Program Management": {
            "job_family_descriptor": "The Program Management job family in Not-for-Profit organizations is responsible for the strategic planning, implementation, and oversight of a portfolio of development or humanitarian programs through different projects. These programs aim to address social, environmental, or humanitarian challenges and advance the organization's mission.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Program Officer",
                    "position_descriptor": " The Chief Program Officer is a high-level executive responsible for overseeing the organization's entire program portfolio. They develop and execute program strategies, allocate resources, and ensure that programs align with the organization's mission and objectives. The CPO plays a crucial role in setting program priorities, monitoring program impact, and overseeing a team of Program and Project Managers.",
                    "related_job_titles": " Chief Program Officer, Head of Programs, Program Executive Director, Senior Program Strategist, Chief Development and Program Officer."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Program Management",
                    "position_descriptor": " Directors of Program Management lead the program management team, overseeing the planning, execution, and evaluation of all programs. They are responsible for program design, budget management, and ensuring program quality and impact. Directors collaborate with other departments to achieve program goals and may manage Program and Project Managers.",
                    "related_job_titles": " Director of Programs, Program Management Director, Director of Development, Senior Program Director, Program Planning and Strategy Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Program Manager",
                    "position_descriptor": "Senior Program Managers are seasoned professionals responsible for overseeing and leading a portfolio of programs within Not-for-Profit organizations in the Development or Humanitarian sector. They collaborate closely with Directors of Programs and Chief Program Officers to develop program strategies, allocate resources, ensure program quality and impact, and manage program teams. Senior Program Managers play a pivotal role in shaping program objectives and ensuring alignment with the organization's mission.",
                    "related_job_titles": "Senior Program Manager, Senior Program Management Specialist, Program Manager II, Senior Program Implementation Manager, Senior Program Planning and Strategy Specialist, Senior Program Development Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Program Manager",
                    "position_descriptor": " Program Managers are responsible for the management and implementation of specific programs. They develop program plans, set objectives, allocate resources, and monitor progress. Program Managers also assess program effectiveness, manage budgets, and oversee a team of Project Managers responsible for program projects.",
                    "related_job_titles": " Program Manager, Program Development Manager, Project and Program Manager, Program Implementation Manager, Program Planning Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Program Coordinator",
                    "position_descriptor": " Senior Program Coordinators assist Program Managers in coordinating program activities. They provide support in program planning, data collection, and reporting. Senior Program Coordinators often supervise Program and Project Coordinators, who manage specific aspects of program projects.",
                    "related_job_titles": " Senior Program Coordinator, Senior Program Officer, Program Coordinator II, Senior Program Implementation Specialist, Program Management Coordinator."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Program Coordinator",
                    "position_descriptor": " Program Coordinators play a key role in program planning and execution. They assist in program design, logistics coordination, and communication with stakeholders. Program Coordinators may also manage Project Managers responsible for project execution.",
                    "related_job_titles": " Program Coordinator, Program Officer, Program Implementation Coordinator, Program Support Specialist"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Program Officer",
                    "position_descriptor": " Program Officers are responsible for managing specific aspects of programs. They work on program implementation, monitor program activities, and report on progress. Program Officers often supervise Project Officers who manage project-level activities.",
                    "related_job_titles": " Program Officer, Program Development Officer, Project Officer, Program Implementation Officer, Program Support Officer."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Program Associate",
                    "position_descriptor": " Program Associates provide administrative and logistical support to the program management team. They assist in scheduling meetings, maintaining program records, and coordinating program materials. Program Associates also support Project Associates who assist with project-level tasks.",
                    "related_job_titles": " Program Associate, Program Assistant, Program Support Associate, Program Administrative Coordinator, Program Operations Associate."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Program Assistant",
                    "position_descriptor": " Program Assistants provide entry-level support to program activities. They assist with administrative tasks, data entry, and program documentation. Program Assistants may also support Project Assistants who help with project-related activities.",
                    "related_job_titles": " Program Assistant, Program Administrative Assistant, Program Support Assistant, Program Coordinator Assistant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field-Level Operational Staff",
                    "position_descriptor": "Field staff who work directly in the communities, where work involves direct engagement with beneficiaries, local communities, and stakeholders",
                    "related_job_titles": "Community Outreach Assistant, Field Staff, Grassroots Organizer, Community Mobilizer"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Field-Level Operational Support Staff",
                    "position_descriptor": "Field support staff who support in community engagement activities at the basic level",
                    "related_job_titles": "Community Outreach Intern, Outreach Support Intern Field Assistant, Field Staff, "
                }
            ]
        },
        "Programs | Project Management": {
            "job_family_descriptor": "The Project Management job family within Not-for-Profit organizations focuses on the planning, execution, and completion of individual projects that contribute to the achievement of broader program and organizational goals.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Project Officer",
                    "position_descriptor": " The Chief Project Officer (CPO) is a high-level executive responsible for overseeing the entire project portfolio. They develop project strategies, allocate resources, and ensure that projects align with the organization's mission and objectives. The CPO plays a crucial role in setting project priorities, monitoring project impact, and overseeing a team of Project Directors and Managers.",
                    "related_job_titles": "Head of Projects, Project Executive Director, Senior Project Strategist, Chief Development and Project Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Projects",
                    "position_descriptor": " Directors of Projects lead the project management team, overseeing the planning, execution, and evaluation of all projects within a program. They are responsible for project design, budget management, and ensuring project quality and impact. Directors collaborate with other departments to achieve project goals and may manage Project Managers.",
                    "related_job_titles": "Director of Projects, Project Director, Director of Development, Senior Project Director, Project Planning and Strategy Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Project Manager",
                    "position_descriptor": " Senior Project Managers are experienced professionals responsible for the planning, execution, and successful completion of complex projects within Not-for-Profit organizations in the Development or Humanitarian sector. They work closely with Directors of Projects and Program Managers to ensure that projects align with the organization's goals and mission, manage project teams, allocate resources, and oversee all project phases, including budget management and quality assurance",
                    "related_job_titles": " Senior Project Manager, Senior Project Management Specialist, Senior Project Lead, Project Manager II, Senior Project Implementation Manager, Senior Project Planning and Strategy Specialist"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Project Manager",
                    "position_descriptor": " Project Managers are responsible for managing individual projects within programs. They develop project plans, set goals, allocate project resources, and oversee project teams. Project Managers ensure that projects are completed on time, within budget, and meet project objectives.",
                    "related_job_titles": " Project Manager, Project Development Manager,  Program Project Manager, Project Implementation Manager, Project Planning Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Project Coordinator",
                    "position_descriptor": " Senior Project Coordinators assist Project Managers in coordinating project activities. They provide support in project planning, data collection, and reporting. Senior Project Coordinators often supervise Project Coordinators, who manage specific aspects of projects.",
                    "related_job_titles": " Senior Project Coordinator, Senior Project Officer, Project Coordinator II, Senior Project Implementation Specialist, Project Management Coordinator."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Project Coordinator",
                    "position_descriptor": " Project Coordinators play a key role in project planning and execution. They assist in project design, logistics coordination, and communication with stakeholders. Project Coordinators may also manage Project Assistants who help with project-level tasks.",
                    "related_job_titles": " Project Coordinator, Project Officer, Project Implementation Coordinator, Project Support Specialist"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Project Officer",
                    "position_descriptor": " Project Officers are responsible for managing specific aspects of projects. They work on project implementation, monitor project activities, and report on progress. Project Officers often supervise Project Assistants who assist with project-level activities.",
                    "related_job_titles": " Project Officer, Project Development Officer, Program Officer, Project Implementation Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Project Associate",
                    "position_descriptor": " Project Associates provide administrative and logistical support to the project management team. They assist in scheduling meetings, maintaining project records, and coordinating project materials. Project Associates play a vital role in ensuring the smooth operation of projects.",
                    "related_job_titles": " Project Associate, Project Support Officer, Project Support Associate, Project Administrative Coordinator, Project Operations Associate."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Project Assistant",
                    "position_descriptor": " Project Assistants provide entry-level support to project activities. They assist with administrative tasks, data entry, and project documentation. Project Assistants may also support Project Interns who gain practical experience.",
                    "related_job_titles": " Project Assistant, Project Administrative Assistant, Project Support Assistant, Project Coordinator Assistant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field-Level Operational Staff",
                    "position_descriptor": "Field staff who work directly in the communities, where work involves direct engagement with beneficiaries, local communities, and stakeholders",
                    "related_job_titles": "Community Outreach Assistant, Field Staff, Grassroots Organizer, Community Mobilizer"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Field-Level Operational Support Staff",
                    "position_descriptor": "Field support staff who support in community engagement activities at the basic level",
                    "related_job_titles": "Community Outreach Intern, Outreach Support Intern Field Assistant, Field Staff, "
                }
            ]
        },
        "Humanitarian Assistance and Disaster Response": {
            "job_family_descriptor": "This job family is dedicated to providing immediate and long-term relief to communities affected by disasters, conflicts, and crises. This family encompasses roles that span the full spectrum of emergency response, from preparedness and relief to recovery and resilience-building efforts.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Humanitarian Officer",
                    "position_descriptor": " The Chief  is the highest-level executive responsible for leading the organization's HADR efforts. They provide strategic direction, oversee disaster response operations, and coordinate with partner agencies and governments.",
                    "related_job_titles": " Chief Humanitarian Officer, Head of Humanitarian Assistance, Chief Disaster Response Officer, Director of Emergency Response."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Emergency Response",
                    "position_descriptor": " Directors of Emergency Response lead the organization's emergency response teams. They design response strategies, mobilize resources, and provide oversight for field operations during crises.",
                    "related_job_titles": " Director of Emergency Response, Emergency Response Director, Disaster Relief Director, Crisis Response Program Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Humanitarian Program Manager",
                    "position_descriptor": "Senior Program Managers are highly experienced professionals responsible for overseeing complex emergency response programs. They provide strategic leadership, coordinate multiple program areas, manage large response teams, and ensure the effective delivery of aid during crises",
                    "related_job_titles": "Senior Humanitarian Program Manager, Senior Disaster Response Program Manager, Senior Emergency Relief Manager, Senior Crisis Recovery Program Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Humanitarian Program Manager",
                    "position_descriptor": "Program Managers are responsible for managing emergency response programs. They plan and execute relief efforts, coordinate logistics, and collaborate with partners to ensure the timely delivery of aid.",
                    "related_job_titles": " Humanitarian Program Manager, Disaster Response Program Manager, Emergency Relief Manager, Crisis Recovery Program Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Specialist -Humanitarian",
                    "position_descriptor": " Senior Specialists are experienced professionals who lead specialized aspects of disaster response. They may focus on areas like logistics, shelter, health, or water and sanitation, ensuring the effectiveness of response operations.",
                    "related_job_titles": " Senior Humanitarian Specialist, Disaster Logistics Specialist, Shelter Coordinator, Health Response Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist - Humanitarian",
                    "position_descriptor": "Specialists provide expertise in specific areas of disaster response. They contribute to response planning, manage resources, and work on the ground to address urgent needs.",
                    "related_job_titles": "Humanitarian Specialist, Disaster Response Specialist, Emergency Logistics Coordinator, Crisis Health Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Humanitarian Coordinator",
                    "position_descriptor": " Coordinators play a critical role in coordinating response efforts. They work closely with program managers, agencies, and local authorities to ensure efficient and effective aid delivery.",
                    "related_job_titles": "Humanitarian Coordinator, Disaster Response Coordinator, Emergency Relief Coordinator, Crisis Response Liaison."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Humanitarian Officer",
                    "position_descriptor": "Officers are responsible for executing response plans and providing operational support during crises. They work in the field to distribute aid, manage shelters, and assist affected communities.",
                    "related_job_titles": "Humanitarian Officer, Disaster Response Officer, Emergency Relief Officer, Crisis Recovery Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Humanitarian Assistant",
                    "position_descriptor": "Assistants provide support to response teams by assisting in logistics coordination, data entry, and basic relief efforts. They help with the day-to-day operations of emergency response.",
                    "related_job_titles": "Humanitarian Assistant, Disaster Response Assistant, Emergency Relief Assistant, Crisis Recovery Assistant."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field-Level Operational Staff",
                    "position_descriptor": "Field staff who work directly in the communities, where work involves direct engagement with beneficiaries, local communities, and stakeholders",
                    "related_job_titles": "Community Outreach Assistant, Field Staff, Grassroots Organizer, Community Mobilizer"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Field-Level Operational Support Staff",
                    "position_descriptor": "Field support staff who support in community engagement activities at the basic level",
                    "related_job_titles": "Community Outreach Intern, Outreach Support Intern Field Assistant, Field Staff, "
                }
            ]
        },
        "Monitoring, Evaluation, Research and Learning (MERL)": {
            "job_family_descriptor": "The MERL job family plays a crucial role in ensuring the effectiveness, impact, and accountability of programs and projects in the Development or Humanitarian sector. MERL is responsible for designing and implementing systems to monitor progress, evaluate outcomes, conduct research, and facilitate organizational learning",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief MERL Officer",
                    "position_descriptor": "As the highest-level MERL executive, the Chief MERL Officer provides strategic leadership for the organization's MERL efforts. They design and oversee the implementation of comprehensive MERL strategies, ensuring alignment with the organization's mission and goals. The CMO leads a team of MERL Directors and Managers.",
                    "related_job_titles": " Chief MERL Officer, Head of MERL, MERL Executive Director, Chief Learning and Impact Officer, Chief M&E Officer"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of MERL",
                    "position_descriptor": "Director leads the MERL department, overseeing the development and execution of MERL plans for programs and projects. They collaborate with program leaders to design robust monitoring and evaluation frameworks, manage research initiatives, and promote organizational learning.",
                    "related_job_titles": " Director of MERL, MERL Director, M&E Director, Director of Impact Assessment, Research and Learning Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "MERL Manager",
                    "position_descriptor": "Managers are responsible for managing the day-to-day MERL activities of programs and projects. They design and implement data collection systems, conduct evaluations, analyze data, and prepare reports to inform programmatic decisions.",
                    "related_job_titles": " MERL Manager, M&E Manager, Impact Assessment Manager, Evaluation Manager, Research and Learning Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior MERL Specialist",
                    "position_descriptor": "Senior MERL Specialists provide expert guidance on MERL methodologies, data analysis, and research design. They lead complex evaluations, manage research teams, and contribute to organizational learning and innovation.",
                    "related_job_titles": " Senior MERL Specialist, Senior M&E Specialist, Senior Impact Assessment Specialist, Senior Research and Learning Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "MERL Specialist",
                    "position_descriptor": "Specialists focus on the implementation of MERL plans. They design data collection tools, gather and analyze data, and contribute to evaluation and research efforts.",
                    "related_job_titles": " MERL Specialist, M&E Specialist, Impact Assessment Specialist, Evaluation Specialist, Research and Learning Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "MERL Coordinator",
                    "position_descriptor": "Coordinators support MERL Specialists in data collection, analysis, and reporting. They assist in maintaining MERL databases, organizing surveys, and ensuring data quality.",
                    "related_job_titles": " MERL Coordinator, M&E Coordinator, Impact Assessment Coordinator, Evaluation Coordinator, Research and Learning Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "MERL Officer",
                    "position_descriptor": "Officers provide administrative support to MERL teams. They assist in data entry, document management, and basic analysis tasks.",
                    "related_job_titles": " MERL Officer, M&E Officer, Impact Assessment Officer, Evaluation Officer, Research and Learning Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "MERL Assistant",
                    "position_descriptor": "Assistants provide entry-level support to MERL teams in administrative tasks, data entry, document management, and basic research activities. They assist in organizing and maintaining MERL databases, as well as supporting MERL Officers and Coordinators.",
                    "related_job_titles": "MERL Assistant, M&E Assistant, Impact Assessment Assistant, Evaluation Assistant, Research and Learning Assistant."
                }
            ]
        },
        "Fundraising and Resource Mobilization": {
            "job_family_descriptor": "The Fundraising and Resource Mobilization job family is responsible for developing and executing strategies to secure financial resources, donations, grants, and partnerships to support the organization's programs and mission in the Development or Humanitarian sector.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Fundraising / Business Development Officer",
                    "position_descriptor": " The Chief is the highest-level executive responsible for overseeing the organization's entire fundraising and resource mobilization efforts. They develop and execute comprehensive fundraising strategies, establish partnerships, and provide strategic leadership for the fundraising team.",
                    "related_job_titles": " Chief Fundraising Officer, Head of Fundraising, Chief Business Development Officer, Chief Resource Mobilization Officer."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Fundraising / Business Development",
                    "position_descriptor": "The Director of Fundraising Strategy lead the strategic planning and development of fundraising initiatives. They work closely with the CFO, set fundraising goals, and oversee the implementation of fundraising strategies.",
                    "related_job_titles": " Director of Fundraising Strategy, Fundraising Strategy Director, Director of Business Development, Resource Mobilization Strategy Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Fundraising / Business Development Manager",
                    "position_descriptor": " Managers manage fundraising teams and activities. They develop fundraising campaigns, identify potential donors, and implement strategies to secure financial support.",
                    "related_job_titles": " Fundraising Manager, Business Development Manager, Resource Mobilization Manager, Grants Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Fundraising / Business Development Specialist",
                    "position_descriptor": " Senior Specialists are experienced professionals responsible for executing and managing complex fundraising projects. They build donor relationships, secure major gifts, and contribute to fundraising strategy.",
                    "related_job_titles": " Senior Fundraising Specialist, Major Gifts Specialist, Senior Business Development Officer, Resource Mobilization Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Fundraising / Business Development Specialist",
                    "position_descriptor": "Specialists focus on executing fundraising campaigns and initiatives. They engage donors, manage fundraising events, and coordinate donor stewardship efforts.",
                    "related_job_titles": " Fundraising Specialist, Business Development Specialist, Resource Mobilization Coordinator, Donor Engagement Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Fundraising / Business Development Coordinator",
                    "position_descriptor": "Coordinators provide support to fundraising activities, including donor communications, data management, and event logistics. They assist in organizing fundraising campaigns.",
                    "related_job_titles": " Fundraising Coordinator, Business Development Coordinator, Resource Mobilization Assistant, Donor Relations Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Fundraising / Business Development Officer",
                    "position_descriptor": "Officers are responsible for actively engaging donors, managing donor relationships, and securing financial support for the organization's initiatives. They execute fundraising campaigns, coordinate donor stewardship efforts, and contribute to achieving fundraising targets.",
                    "related_job_titles": "Fundraising Officer, Business Development Officer, Resource Mobilization Officer, Donor Relations Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Fundraising / Business Development Assistant",
                    "position_descriptor": "Assistants provide entry-level support to fundraising teams. They assist in administrative tasks, donor database management, and donor communications.",
                    "related_job_titles": " Fundraising Assistant,Business Development Assistant, Resource Mobilization Assistant, Donor Support Assistant."
                }
            ]
        },
        "Policy and Advocacy": {
            "job_family_descriptor": "The Policy and Advocacy job family is dedicated to shaping policies, influencing decision-makers, and advocating for change in support of the organization's mission and the communities it serves. Job holders work to engage policymakers and the public on issues that affect the Not for Profit organisation, and work to advance the organisation agenda through policy analysis, coalition building, and public education.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Policy and Advocacy Officer",
                    "position_descriptor": "The Chief is the highest-level executive responsible for setting the organization's policy and advocacy agenda. They provide strategic leadership, oversee advocacy initiatives, and engage with policymakers and stakeholders at the highest levels.",
                    "related_job_titles": " Chief Policy and Advocacy Officer, Head of Advocacy, Chief Government Relations Officer, Director of Policy Engagement."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of  Policy and Advocacy",
                    "position_descriptor": "Directors of Advocacy Strategy lead the strategic planning and development of advocacy initiatives. They set advocacy goals, develop campaigns, and collaborate with internal and external partners to advance the organization's policy agenda.",
                    "related_job_titles": " Director of Advocacy Strategy, Advocacy Strategy Director, Director of Policy Advocacy, Government Affairs Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager, Policy and Advocacy",
                    "position_descriptor": "Managers manage advocacy teams and activities. They oversee advocacy campaigns, mobilize supporters, and engage with policymakers to drive change on key issues.",
                    "related_job_titles": " Advocacy Manager, Policy Advocacy Manager, Government Relations Manager, Campaigns Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Analyst, Policy and Advocacy",
                    "position_descriptor": "Senior Analysts are subject matter experts who research and analyze policy issues. They provide strategic guidance on policy advocacy, create position papers, and engage in thought leadership.",
                    "related_job_titles": " Senior Policy Analyst, Advocacy Analyst, Government Relations Specialist, Policy Research Manager."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Policy and Advocacy Analyst",
                    "position_descriptor": "Analysts research, analyze, and monitor policy developments. They support the development of advocacy materials, engage in policy briefings, and contribute to advocacy strategies.",
                    "related_job_titles": " Policy Analyst, Advocacy Analyst, Government Affairs Specialist, Policy Researcher."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Policy and Advocacy Coordinator",
                    "position_descriptor": "Coordinators play a crucial role in coordinating advocacy activities. They support campaign logistics, engage with supporters, and help organize advocacy events.",
                    "related_job_titles": " Advocacy Coordinator, Policy Advocacy Coordinator, Government Relations Coordinator, Campaign Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Policy and Advocacy Officer",
                    "position_descriptor": "Officers execute advocacy plans and activities. They engage with stakeholders, draft advocacy materials, and contribute to grassroots mobilization efforts.",
                    "related_job_titles": " Advocacy Officer, Policy Advocacy Officer, Government Relations Officer, Campaign Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Policy and Advocacy Assistant",
                    "position_descriptor": "These are entry-Level Advocacy Assistants provide support to advocacy efforts by assisting with administrative tasks, data entry, and basic advocacy activities. They help organize and maintain advocacy materials.",
                    "related_job_titles": " Advocacy Assistant, Policy Advocacy Assistant, Government Relations Assistant, Campaign Assistant."
                }
            ]
        },
        "Partnership and Stakeholder Relations Management": {
            "job_family_descriptor": "This job family is focused on building and maintaining collaborative relationships with external partners, stakeholders, and communities. This family plays a critical role in facilitating partnerships, managing donor relationships, and ensuring effective engagement with stakeholders.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Partnership and Stakeholder Relations Officer",
                    "position_descriptor": " The Chief is the highest-level executive responsible for leading the organization's partnership and stakeholder engagement efforts. They provide strategic leadership, oversee partnership development, and build strong relationships with key stakeholders.",
                    "related_job_titles": " Chief Partnership and Stakeholder Relations Officer, Head of Partnerships, Chief External Relations Officer, Director of Stakeholder Engagement."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Strategic Partnerships",
                    "position_descriptor": " Directors lead the development of strategic partnership initiatives. They identify potential partners, negotiate agreements, and ensure alignment with the organization's mission and goals.",
                    "related_job_titles": " Director of Strategic Partnerships, Partnership Strategy Director, Director of External Relations, Stakeholder Engagement Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Partnership Manager",
                    "position_descriptor": " Partnership Managers manage partnerships and collaborations. They work closely with external partners, oversee partnership agreements, and ensure effective communication and collaboration.",
                    "related_job_titles": " Partnership Manager, Collaboration Manager, External Relations Manager, Stakeholder Relations Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Stakeholder Relations Specialist",
                    "position_descriptor": " Senior Stakeholder Relations Specialists are experienced professionals who focus on managing key stakeholder relationships. They engage with donors, government agencies, and other stakeholders to build and maintain partnerships.",
                    "related_job_titles": " Senior Stakeholder Relations Specialist, Donor Relations Specialist, Government Relations Manager, External Affairs Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Stakeholder Relations Specialist",
                    "position_descriptor": " Stakeholder Relations Specialists play a critical role in building and maintaining stakeholder relationships. They support partnership activities, conduct stakeholder outreach, and ensure effective communication.",
                    "related_job_titles": " Stakeholder Relations Specialist, Partnership Specialist, External Affairs Coordinator, Donor Engagement Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Partnership Coordinator",
                    "position_descriptor": " Coordinators assist in coordinating partnership activities. They work with teams to implement partnership agreements, track progress, and support the fulfillment of partnership commitments.",
                    "related_job_titles": " Partnership Coordinator, Collaboration Coordinator, External Relations Assistant, Stakeholder Engagement Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Partnership and Stakeholder Relations Officer",
                    "position_descriptor": " Officers are responsible for managing and nurturing partnerships and stakeholder relationships. They facilitate communication between the organization and its partners, monitor partnership activities, and contribute to the development of partnership strategies.",
                    "related_job_titles": "Partnership and Stakeholder Relations Officer, External Relations Officer, Donor Engagement Officer, Stakeholder Liaison Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Stakeholder Engagement Assistant:",
                    "position_descriptor": " These are entry-Level Stakeholder Engagement Assistants provide support in stakeholder engagement efforts. They assist with administrative tasks, stakeholder communications, and data management.",
                    "related_job_titles": " Stakeholder Engagement Assistant, Partnership Assistant, External Relations Assistant, Donor Relations Assistant."
                }
            ]
        },
        "Volunteer Services & Community Outreach": {
            "job_family_descriptor": "This job family is focused on engaging and mobilizing volunteers, fostering community relationships, and ensuring effective outreach and support for the organization's mission and programs.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Volunteer Services & Community Outreach Officer",
                    "position_descriptor": "The Chief is the highest-level executive responsible for leading the organization's volunteer services and community outreach efforts. They provide strategic leadership, oversee volunteer engagement, and build strong community relationships.",
                    "related_job_titles": " Chief Volunteer Services Officer, Head of Community Outreach, Chief Engagement Officer, Director of Volunteer Programs."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Volunteer Engagement",
                    "position_descriptor": "Director lead the development of volunteer engagement strategies. They recruit, train, and manage volunteers, ensuring alignment with the organization's mission.",
                    "related_job_titles": " Director of Volunteer Engagement, Volunteer Engagement Director, Director of Community Outreach, Volunteer Programs Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Volunteer Services Manager",
                    "position_descriptor": "Managers manage volunteer programs and services. They oversee volunteer recruitment, onboarding, and coordination, ensuring a positive volunteer experience.",
                    "related_job_titles": " Volunteer Services Manager, Community Outreach Manager, Volunteer Engagement Manager, Volunteer Programs Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Community Outreach Specialist",
                    "position_descriptor": "Specialists focus on building relationships with the community. They organize outreach events, engage with local stakeholders, and promote the organization's initiatives.",
                    "related_job_titles": " Community Outreach Specialist, Volunteer Engagement Specialist, Outreach Coordinator, Community Relations Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Senior Volunteer Coordinator",
                    "position_descriptor": "Senior Coordinators manage volunteer coordination efforts. They work closely with volunteers, assign tasks, and ensure that volunteers are effectively contributing to the organization's programs.",
                    "related_job_titles": " Senior Volunteer Coordinator, Volunteer Services Coordinator, Volunteer Engagement Coordinator, Senior Outreach Coordinator."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Volunteer Coordinator",
                    "position_descriptor": "Coordinators focus on recruiting and coordinating volunteers. They match volunteers with appropriate roles, oversee scheduling, and provide support to ensure volunteer success.",
                    "related_job_titles": " Volunteer Coordinator, Community Outreach Coordinator, Volunteer Engagement Assistant, Volunteer Programs Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Volunteer Services & Community Outreach Officer",
                    "position_descriptor": "Officers play a key role in managing volunteer programs and fostering community relationships. They coordinate volunteer activities, engage with community stakeholders, and contribute to the development of outreach strategies.",
                    "related_job_titles": " Volunteer Services & Community Outreach Officer, Outreach and Engagement Officer, Volunteer Programs Officer, Community Relations Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Community Outreach Assistant",
                    "position_descriptor": "Community Outreach Assistants provide support in community outreach efforts. They assist in organizing events, engage with community members, and contribute to outreach campaigns.",
                    "related_job_titles": " Community Outreach Assistant, Volunteer Engagement Assistant, Community Relations Assistant."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field-Level Operational Staff",
                    "position_descriptor": "Field staff who work directly in the communities, where work involves direct engagement with beneficiaries, local communities, and stakeholders",
                    "related_job_titles": "Community Outreach Assistant, Volunteer Engagement Assistant, Field Staff, Grassroots Organizer, Community Mobilizer"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Field-Level Operational Support Staff",
                    "position_descriptor": "Field support staff who support in community engagement activities at the basic level",
                    "related_job_titles": "Community Outreach Intern, Volunteer Engagement Intern, Outreach Support Intern Field Assistant, Field Staff, "
                }
            ]
        }
    }
}