import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import instance from '../../../../auth/useJwt';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function CompanyApproveDialog({ onSuccess }) {
    const [open, setOpen] = React.useState(false);
    const { id } = useParams()
    const [apiReq, setApiReq] = React.useState({
        loading: false,
        error: null,
        success: false,
    });

    const onApproveCompanyData = () => {
        setApiReq({
            loading: true,
            error: null,
            success: false,
        });
        instance.post('/admin/companies/update-approval', { 
            id : id,
            status: "approved"
         }).then(res => {
            onSuccess();
            setApiReq({
                loading: false,
                error: null,
                success: true,
            });
            toast.success('Company approved successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setOpen(false)
        }).catch(err => {
            setOpen(false)
            setApiReq({
                loading: false,
                error: err,
                success: false,
            });
            console.log(err);
        });
    };

    const title = 'Approve Company Data';
    const message = 'Are you sure you want to approve this company data? If you approve, the company will be able to access the platform.';

    const handleClose = (answer) => {
        if (answer === 'yes') {
            onApproveCompanyData();
        } else {
            setOpen(false)
        }
    };

    const send_toast = () => {
        

    }

    return (
        <> <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
    />

            <Button variant="contained"
                onClick={
                    () => setOpen(true)
                }
                color="success"
                size="small" sx={{ textTransform: 'none' }}>Approve</Button>
            <Dialog
                open={open}
                //onClose={() => handleClose('no')}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={apiReq.loading}
                        onClick={() => handleClose('no')} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={apiReq.loading}
                        onClick={() => handleClose('yes')} color="primary" autoFocus>
                        {apiReq.loading ? 'Processing...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CompanyApproveDialog