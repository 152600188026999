import { useEffect } from "react";
import BlockHeading from "../../../../../../components/forms/BlockHeading";
import Label from "../../../../../../components/forms/InputLabel";
import SelectAutocomplete from "../../../../../../components/forms/SelectAutocomplete";
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle";
import generator from "../../utils/generators";

const EMP_TERMS = [
    { label: "Permanent Contract", value: "Permanent Contract" },
    { label: "Fixed-term Contract", value: "Fixed-term Contract" },
    { label: "Internship Contract", value: "Internship Contract" },
    { label: "Seasonal Contract", value: "Seasonal Contract" },
]

const GEN_0_100 = [
    {label: "0%", value: "0%"}, ...generator(100)
]


export default function EmployeeDemography(props) {
    const id = 'employee_demography'
    const { watch, errors, control, register, setValue } = props

    const localContract = parseInt((watch(`${id}.local_contract`) || '0').replace('%', ''), 10);
    const expatriateContract = parseInt((watch(`${id}.expatriate_contract`) || '0').replace('%', ''), 10);
    const localPlusContract = parseInt((watch(`${id}.local_plus_contract`) || '0').replace('%', ''), 10);

    const isSumEqual100 = localContract + expatriateContract + localPlusContract === 100;

    // If local_contract is selected 100%, then set other two to 0%
    useEffect(() => {
        if (localContract === 100) {
            setValue(`${id}.expatriate_contract`, '0%');
            setValue(`${id}.local_plus_contract`, '0%');
        }
    }, [localContract])    

    return (
        <div>
            <BlockHeading text="1. Employee Demography" />

            <div className="my-2">
                <Label text="Employee population split" />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-2">
                    <div>
                        <Label className="mb-2" htmlFor="local_contract"
                            text="Local Contract" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.local_contract`}
                            errors={errors} watch={watch}
                            options={GEN_0_100} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2"
                            htmlFor="expatriate_contract"
                            text="Expatriate Contract" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.expatriate_contract`}
                            errors={errors} watch={watch}
                            options={GEN_0_100} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2" htmlFor="local_plus_contract"
                            text="Local Plus Contract" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.local_plus_contract`}
                            errors={errors} watch={watch}
                            options={GEN_0_100} control={control} />
                    </div>


                </div>
                {watch(`${id}.local_contract`) &&
                    <div>
                        {!isSumEqual100 &&
                            <div className="text-red-500 font-semibld">
                                Split should be equal to 100%
                            </div>}
                    </div>}
                <div className="mt-2">
                    <Label text="What employment terms do you provide to local employees? (multiple selection)" className="mb-2" />
                    <SelectAutocomplete control={control} register={register} watch={watch} options={EMP_TERMS}
                        name={`${id}.employment_terms`} />
                </div>
            </div>

        </div>
    )
}