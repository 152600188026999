import { useEffect, useState } from "react"
import { useForm, useFieldArray } from 'react-hook-form';
import SelectAutocompleteSingle from "./components/forms/SelectAutocompleteSingle";
import MUITextInput from "./components/forms/MUITextInput";
import Tooltip from "./components/Tooltip";
import { Box, Tooltip as MUITooltip, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow as TR } from "@mui/material";
import { WR_LEVELS_DESCRIPTION } from "../../../../configs/data/CompensationFieldsData";
import { COLS, COLS_MP, CONTRACTING_OPTIONS } from "./data/base";
import AddRowPopup from "./components/AddRowPopup";
import { useSelector } from "react-redux";
import { onBoardingReadOnly } from "../../../../redux/app_data";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

const TableRow = styled(TR)(({ theme }) => ({
    // No padding
    '& > *': {
        padding: "0px 5px",
    },
    padding: '0px'
    
}));


export default function InputTableC(props) {
    const { fieldsR, setFieldsR } = props


    const headingColor = "#9651FA"

    const [colsSort, setColsSort] = useState(COLS.reduce((acc, value) => {
        acc[value] = { name: value, sort: null };
        return acc;
    }, {}));
    
    const get_description = (row, val, col) => {
        var given = ""
        fieldsR[row].points.levels.map((item) => {
            if (item.survey_position === val) {
                given = item[col]
            }
        })
        return given
    }

    const get_wr_job_level = (row, val, col) => {
        var given = ""
        row.points.levels.map((item) => {
            if (item.survey_position === val) {
                given = item[col]
            }
        })
        return given
    }

    const addNewRow = (e, item, index) => {
        e.preventDefault();

        // Deep copy the item object
        const itemCopy = JSON.parse(JSON.stringify(item));

        // Modify the copy
        itemCopy.survey_position = null;
        itemCopy.related_job_title = "";
        itemCopy.sb_index = fieldsR.filter(employee => employee.job_family === itemCopy.job_family).length;

        const newFields = [...fieldsR];
        newFields.splice(index + 1, 0, itemCopy);

        setFieldsR(newFields);
    }

    const deleteRow = (e, index) => {
        e.preventDefault();

        const newFields = [...fieldsR];
        newFields.splice(index, 1);
        setFieldsR(newFields);
    }

    const onAddJF = (jf) => {
        const tempFields = [...fieldsR]
        tempFields.push(jf)
        setFieldsR(tempFields)
    }

    const onSelectChange = (value, name, index) => {
        // Create a shallow copy of the array
        const tempFields = [...fieldsR];
    
        // Ensure that the object at the given index is not immutable
        tempFields[index] = { ...tempFields[index], [name]: value };
    
        setFieldsR(tempFields);
    };
    

    useEffect(() => {
        const newFields = [...fieldsR];
        // Only apply on sort by "Job Level" as wr_job_level is the only column that has a sort order
        newFields.sort((a, b) => {
            const old_job_level = parseInt(get_wr_job_level(a, a.survey_position, "wr_job_level").split(" ")[1])
            const new_job_level = parseInt(get_wr_job_level(b, b.survey_position, "wr_job_level").split(" ")[1])
            if (colsSort["Job Level"].sort === 'asc') {
                return old_job_level - new_job_level;
            } else if (colsSort["Job Level"].sort === 'desc') {
                return new_job_level - old_job_level;
            } else {
                return 0;
            }
            
        })
        setFieldsR(newFields);

    }, [colsSort])


    const isReadOnly = useSelector(onBoardingReadOnly)
    return (
        <div>
            {/* <div className="overflow-x-auto relative"> */}
                {/* <table className="min-w-xxl w-full border" style={{ minWidth: '1500px' }}> */}
                <TableContainer  sx={{ maxHeight: 500 }}>
                    <Table size="small" stickyHeader className="min-w-xxl w-full border" style={{ minWidth: '1500px', height: '70vh' }}>
                    <TableHead>

                        <TableRow sx={{ backgroundColor: headingColor, '.MuiTableCell-stickyHeader': { backgroundColor: headingColor } }}>
                
                            <TableCell className="px-1 border-r text-white text-sm w-5"></TableCell>
                            {COLS.map(smm => (
                                <TableCell 
                                onClick={() => {
                                    if (smm !== "Job Level") return;
                                    const newSort = { ...colsSort };
                                    if (newSort[smm].sort === null) {
                                        newSort[smm].sort = 'asc';
                                    } else if (newSort[smm].sort === 'asc') {
                                        newSort[smm].sort = 'desc';
                                    } else if (newSort[smm].sort === 'desc') {
                                        newSort[smm].sort = null;
                                    }
                                    setColsSort(newSort);
                                }}
                                key={`th-${smm}`} style={{ fontWeight: 500, bacgroundColor: headingColor }}
                                className="px-1 py-4 border-r text-white text-sm w-10">
                                    <Box sx={{ display: 'flex' }}>
                                    <MUITooltip title={COLS_MP[smm]}>
                                        {smm}
                                    </MUITooltip>

                                    {
                                        colsSort[smm] && colsSort[smm].sort === 'asc' && smm === "Job Level" &&
                                        <NorthIcon className="ml-1 text-xs text-white" />
                                    }
                                    {
                                        colsSort[smm] && colsSort[smm].sort === 'desc' && smm === "Job Level" &&
                                        <SouthIcon className="ml-1 text-xs text-white" />
                                    }
                                    </Box>
                                </TableCell>
                            ))
                            }
                        </TableRow>

                    </TableHead>

                    <TableBody>
                        {
                            fieldsR.map((item, index) => {
                                return (
                                    <TableRow key={`job-${index}`} style={{ fontSize: '12px' }} sx={{ padding: '0px' }}>
                                        <TableCell className="px-2 border-b border-r text-center" style={{ minWidth: '70px' }}>
                                            {index + 1}

                                            {!isReadOnly &&
                                                <>
                                                    <button className="text-blue-500 ml-1" onClick={(e) => addNewRow(e, item, index)}>
                                                        Add
                                                    </button>
                                                    <button className="text-red-500 ml-1" onClick={(e) => deleteRow(e, index)}>Del</button>
                                                </>
                                            }
                                        </TableCell>
                                        <TableCell className="px-1 border-b border-r">{item.job_family}</TableCell>
                                        <TableCell className="border-b border-r" style={{ minWidth: '250px' }}>
                                            <SelectAutocompleteSingle required
                                                name={`survey_position`}
                                                border={false}
                                                isMultiline
                                                fontSize={12}
                                                index={index}
                                                value={item.survey_position}
                                                onChange={onSelectChange}
                                                options={
                                                    item.points.levels?.map((sb_item) => ({ label: sb_item.survey_position, value: sb_item.survey_position }))
                                                } />
                                        </TableCell>
                                        <TableCell className="border-b border-r text-center" style={{ maxWidth: '50px' }}>
                                            {get_description(index, item.survey_position, "wr_job_level")}
                                        </TableCell>
                                        <TableCell className="border-b border-r text-xs py-0 px-2 text-center" style={{ maxWidth: '70px' }}>
                                            {/* {get_description(index, watch(`${get_id(item.job_family)}.survey_position`), 'position_descriptor')} */}
                                            {item.survey_position &&
                                                <Tooltip
                                                    position_overview={get_description(index, item.survey_position, 'position_descriptor')}
                                                    related_jobs={get_description(index, item.survey_position, 'related_job_titles')}
                                                    wr_description={WR_LEVELS_DESCRIPTION[get_description(index, item.survey_position, 'wr_job_level')]} />
                                            }
                                        </TableCell>
                                        <TableCell className="border-b border-r">
                                            <MUITextInput
                                                border={"false"}
                                                required
                                                placeholder="Related job title"
                                                multiline
                                                name={`related_job_title`}
                                                value={item.related_job_title}
                                                onChange={(e) => onSelectChange(e.target.value, e.target.name, index)}
                                            />
                                        </TableCell>
                                        <TableCell className="border-b border-r">
                                            <SelectAutocompleteSingle required
                                                name={`contracting`}
                                                border={false}
                                                isMultiline
                                                fontSize={12}
                                                options={
                                                    CONTRACTING_OPTIONS
                                                }
                                                value={item.contracting}
                                                onChange={onSelectChange}
                                                index={index}
                                                />
                                        </TableCell>
                                        <TableCell className="border-b border-r">
                                            <MUITextInput border={"false"} required placeholder="Reporting Structure"
                                                name={`reporting_structure`}
                                                value={item.reporting_structure}
                                                onChange={(e) => onSelectChange(e.target.value, e.target.name, index)}
                                                />
                                        </TableCell>

                                        <TableCell className="border-b border-r">
                                            <MUITextInput type="number" required border={"false"} placeholder="Number of staff"
                                                name={`staff_number`}
                                                value={item.staff_number}
                                                onChange={(e) => onSelectChange(e.target.value, e.target.name, index)}
                                                />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {!isReadOnly &&
                <AddRowPopup availableJF={props.availableJF} onAdd={onAddJF} />
            }
        </div>
    )
}