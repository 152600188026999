



export default function FinishSubmission(props) {
    const onClick = () => {
        props.onSaveClick("finish", null, true)
    }

    if (props.finishData) {
        return (
            <div>
                <h3 className="text-xl font-bold mt-8 mb-2">Congratulations!</h3>
                <h3 className="text-xl mt-2">You have finished the onboarding process</h3>

                
                <h3 className="text-xl font-bold mt-8 mb-3">We will review the data submission. This process will take 5-10 business days.</h3>
                <h3 className="text-xl mt-3">Once this is completed, you will receive an email confirmation that access to <b>TheWorkrate</b> app is now available.</h3>
            </div>
        )
    }


    return (
        <div>
            <h1 className="text-2xl font-bold text-center mt-5 mb-5">Done</h1>
            <p className="text-center">Congratulations, you have submitted all data required. Please click on finish to finalize submissions</p>
            <div className="flex justify-center mt-5 px-2 py-2">
                <button onClick={onClick} disabled={props.apiReq.loading} className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">
                    {props.apiReq.loading ? 'Finalizing Submission' : 'Finish Submission'}
                </button>
            </div>
        </div>
    )
}