import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function CommissionSchemes(props) {
  const { offering } = props.data;
  const {
    eligibility, payout_frequency
 } = props.data.data
  return (
    <div>
      <BlockHeading text="2. Commission Schemes" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Commission Schemes Offered"}
          footer={getFooter(offering?.total)}
          note={getNote()}
          total={offering?.total}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Eligibility"}
          footer={getFooter(offering?.total)}
          note={getNote()}
          total={offering?.total}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={eligibility?.categories}
            series={eligibility?.data}
            height="auto"
          />
        </BCard>
      </div>
      <div className="my-4">
        <BCard
          title={"Frequency Of Payout"}
          footer={getFooter(offering?.total)}
          note={getNote()}
          total={offering?.total}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={payout_frequency?.categories}
            series={payout_frequency?.data}
            height="auto"
          />
        </BCard>
      </div>
    </div>
  );
}
