import { createSlice } from '@reduxjs/toolkit'


export const compensationPracticesSlice = createSlice({
  name: 'compensation_practices',
  initialState: {
    data : {
    compensation_structure : {
        
    },
    annual_compensation: {
        
    }
}

  },
  reducers: {
    setCompPracticeData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { setCompPracticeData } = compensationPracticesSlice.actions

export default compensationPracticesSlice.reducer


/*
compensation_structure : {
        job_evaluation: null, salary_structure: null, num_salary_structure: null, use_speradsheet: null, 
        salary_adjustments: null
    },
    */