export default function HeadCell({ children, ...restProps }) {
  return (
    <th
      className="px-2 py-2 border-b border-r text-sm font-semibold"
      {...restProps}
    >
      {children}
    </th>
  );
}
