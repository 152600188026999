import React from 'react';
import { Grid } from '@mui/material';
import { styled } from "@mui/material";
import LocalCafe from '@mui/icons-material/LocalCafe';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)",
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))


const CardItem = styled("div")(({ theme }) => ({
    border: '1px solid rgb(230, 230, 230)', borderRadius: '5px', display: 'flex', gap: 15
}))

const AdminIcon = (props) => {
    return <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
  </svg>
  
}

export default function HeadCount() {

    const iconSize = 34
    const data = [
        { "title" : "Sales", value: "550", color: "#E9EFFF", icon: <BusinessCenterIcon style={{ color: "#5076ff", fontSize: 30 }} /> },
        { "title" : "Support", value: "250", color: "#E7FAFC", icon: <WysiwygIcon style={{ color: "#3BE9E0", fontSize: 30 }} /> },
        { "title" : "Admin", value: "470", color: "#E9EFFF", icon: <AdminIcon style={{ color: "#8124EF", fontSize: iconSize }} /> },
    ]

    return (

            <SingleCard>
                <h2 className='text-xl font-semibold text-gray-600'>Headcount per Department</h2>
                <div className='flex justify-center flex-col mt-5'>
                    {
                        data.map((item, index) => (
                            <CardItem className='p-3 mb-3'>
                                <div style={{ backgroundColor: item.color, width: '55px', height: '55px', borderRadius: '6px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                            flexDirection: 'column' }}>
                                    {item.icon}
                                </div>
                                <div>
                                    <h3 className='text-xl font-semibold text-gray-600'>{ item.title } - {item.value} employees</h3>
                                    <p className='text-gray-500 mt-1'>Avg Benefit Ratio</p>
                                </div>
                            </CardItem>
                        ))
                    }
                </div>
            </SingleCard>
    );
}



