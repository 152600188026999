import React, { useEffect, useState } from "react";
import { COMPANY, SECTORS, INDUSTRIES, OFFICES, CURRENCY, YES_NO } from '../../../../configs/data/onboarding'
import Label from "../../../../components/forms/InputLabel";
import SelectInput from "../../../../components/forms/SelectInput";
import BlockHeading from "../../../../components/forms/BlockHeading";
import Tooltip from "../../../../components/Tooltip";

const STARTUP_OPTIONS = [
    { label: "Please select a value", value: "" },
    { label: "Pre-Seed/Seed", value: "Pre-Seed/Seed" },
    { label: "Series A", value: "Series A" },
    { label: "Series B", value: "Series B" },
    { label: "Series C", value: "Series C" },
    { label: "Series D", value: "Series D" },
    { label: "Series E+", value: "Series E+" },
    { label: "Post IPO", value: "Post IPO" },
    { label: "Other", value: "Other" },
]

const ORGANIZATION_SIZE = [
    { label: "1-10", value: "1-10" },
    { label: "11-100", value: "11-100" },
    { label: "101-500", value: "101-500" },
    { label: "501-1000", value: "501-1000" },
    { label: "1,001-3,000", value: "1001-3000" },
    { label: "3,001-5,000", value: "3001-5000" },
    { label: "5,001-10,000", value: "5001-10000" },
    { label: "10,001-50,000", value: "10001-50000" },
    { label: "50,001-100,000", value: "50001-100000" },
    { label: ">100,000", value: ">100000" },
]

const get_nums = () => {
    var start = 1800
    var end = new Date().getFullYear()
    const nums = []
    // start from 1800 to current year
    for (let i = start; i <= end; i++) {
        nums.push({
            label: i,
            value: i
        })
    }
    return nums
}

export default function Demography(props) {
    const { watch, errors, control, register } = props
    const id = "demography"
    return (
        <div>

            <BlockHeading text="3. Demography" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-2">
                <div>
                    <Label htmlFor="type" text="Company Type" />
                    <SelectInput id="demography_type" name={`${id}.type`} register={register} options={[
                        { label: 'Select a value', value: '' },
                        { label: 'Privately held', value: 'Privately held' },
                        { label: 'Public Company', value: 'Public Company' },
                        { label: 'Government Agency', value: 'Government Agency' },
                        { label: 'Non Profit', value: 'Non Profit' },
                    ]} errors={errors} />
                </div>
                {/* <div>
                    <Label htmlFor="profile" text="Profile" />
                    <SelectInput id="profile" name={`${id}.profile`} register={register} options={[
                        { label: 'Select a value', value: '' },
                        { label: 'For Profit (Revenue Generating)', value: 'For Profit (Revenue Generating)' },
                        { label: 'Non-For Profit', value: 'Non-For Profit' },
                    ]} errors={errors} />
                </div> */}
                <div>
                    <Label htmlFor="industry" text="Industry" />
                    <SelectInput id="demography_industry" name={`${id}.industry`} register={register} options={INDUSTRIES} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="sector" text="Sector" />
                    <SelectInput id="demography_sector" name={`${id}.sector`} register={register} options={SECTORS[watch(`${id}.industry`, 'Consumer')]} errors={errors} />
                </div>


                <div>
                    <Label htmlFor="company" text="Company" />
                    <SelectInput id="demography_company" name={`${id}.company`} register={register} options={COMPANY} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="in_country_offices" text="Number of offices in Country" />
                    <SelectInput id="demography_offices_in_country" name={`${id}.in_country_offices`} register={register} options={OFFICES} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="abroad_offices" text="Number of offices abroad" />
                    <SelectInput id="demography_offices_abroad" name={`${id}.abroad_offices`} register={register} options={OFFICES} errors={errors} />
                </div>

                
                <div>
                    <Label htmlFor="organisation_size" text="Number of employees" />
                    <SelectInput id="organisation_size" name={`${id}.organisation_size`} register={register} options={ORGANIZATION_SIZE} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="incorporation_year" text="Year of incorporation" />
                    <SelectInput id="incorporation_year" name={`${id}.incorporation_year`} register={register} options={get_nums()} errors={errors} />
                </div>

                <div>
                    <div className="flex">
                        <Label htmlFor="is_startup" className="mb-0" text="Is your company a startup?" />
                        <Tooltip text="Startups are companies or ventures that are focused on a single product or service that the founders want to bring to market." faq={true} />
                    </div>
                    <SelectInput id="is_startup" name={`${id}.is_startup`} register={register} options={YES_NO} errors={errors} />
                </div>

                {/* If yes */}
                {watch(`${id}.is_startup`) === 'Yes' &&
                    <div>
                        <Label htmlFor="funding_stage" text="What is the funding stage?" />
                        <SelectInput id="funding_stage" name={`${id}.funding_stage`} register={register} options={STARTUP_OPTIONS} errors={errors} />
                    </div>
                }

                
            </div>
        </div>
    );
}
