import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { WR_GRAPH_COLORS } from '../../../../configs/colors';
import './styles.css'
const GenderPayEquityChart = (props) => {
    const { data } = props
    const GENDER_NAME = {
      0: "Male",
      1: "Female",
      2: "Non Binary"
    }
  const [chartOptions, setChartOptions] = React.useState({
    chart: {
        type: 'bar',
        height: 440,
        stacked: true
      },
      colors: WR_GRAPH_COLORS.slice(1, 4),
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
          //borderRadius: 10
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          // This ensures that even zero values are displayed
          return val === 0 ? '0%' : Math.abs(val) + '%';
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        max: data?.meta?.max_val + 5 || 50,
        min: 0,
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          console.log(seriesIndex, dataPointIndex, w)
            const category = w.globals.labels[dataPointIndex];
            const value = series[seriesIndex][dataPointIndex];
            const gender = GENDER_NAME[seriesIndex];
            const gender_lower = GENDER_NAME[seriesIndex]?.toLowerCase()?.replace(' ', '_');
            return (
                `<div class="custom-tooltip">
                    <div class="custom-tooltip-header">${category} - ${gender}</div>
                    <div class="custom-tooltip-value">Val: ${Math.abs(value)}%</div>
                    <div class="custom-tooltip-value">Records: ${data?.counts[dataPointIndex].data[gender_lower]}</div>
                </div>`
            );
        },
        // ... other tooltip settings ...
    },
      title: {
        text: 'Gender Split'
      },
      xaxis: {
        categories: data?.categories,
        title: {
          text: 'Gender Split'
        },
        labels: {
          formatter: function (val) {
            return Math.abs(Math.round(val)) + "%";
          }
        }
      }
  });

  const series = [
    {
      name: `Male`, // Sum of all values
      data: data?.male
    },
    {
      name: `Female`,
      data: data?.female
    },
    {
      name: `Non Binary`,
      data: data?.non_binary
    }
  ];

  return (
    <div className='py-5'>
      <ReactApexChart options={chartOptions} series={series} type="bar" height={450} />
    </div>
  );
}

export default GenderPayEquityChart;
