import { createSlice } from '@reduxjs/toolkit'


export const insuranceSlice = createSlice({
    name: 'insurance',
    initialState: {
        data: {

            "medical_cover": {

            },
            "provident_fund": {

            },
            "life_insurance": {

            }

        }
    },
    reducers: {
        setInsuranceData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setInsuranceData } = insuranceSlice.actions

export default insuranceSlice.reducer


/*
education: {
        offering: null
    },
    sports_gym: {
        offering: null
    },
    loans: {
        offering: null
    },
    company_car: {
        offering: null
    },
    long_term_awards: {
        offering: null
    },
    other: {
        offering: null
    },
    */