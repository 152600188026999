export default function BlankRowCell(props) {
    const defaultStyle = {
        width: '10%', 
        height: '40px', 
        backgroundColor: "rgb(235, 235, 235)", 
        border: "1px solid #fff"
    };

    const combinedStyles = { ...defaultStyle, ...props.style };

    // Ensure that the combined style is applied last
    return (
        <td className="border-b border-r" 
            {...props}
            style={combinedStyles}
        >
            {/* Content of the cell */}
        </td>
    )
}
