import { useState } from 'react';
// import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'; // Import icons for toggling visibility
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const { register, errors } = props;
    // ...rest of your component logic

    return (
        <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700">Password</label>
            <div className="flex items-center border rounded-sm">
                <input 
                    type={showPassword ? "text" : "password"} 
                    id="password" 
                    name="password"
                    {...register('password', {
                        required: 'Password is required',
                        minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters long',
                        },
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message:
                                'Password must contain at least one lowercase letter, one uppercase letter, one special character, and a number',
                        },
                    })} 
                    className="w-full px-4 py-2" 
                />
                <button 
                    type="button" 
                    onClick={() => setShowPassword(!showPassword)}
                    className="p-2"
                >
                    {showPassword ? <VisibilityOffIcon className="h-5 w-5 text-gray-500" /> : <VisibilityIcon className="h-5 w-5 text-gray-500" />}
                </button>
            </div>
            {errors.password && <span className="text-red-600 text-sm">{errors.password.message}</span>}
        </div>
    );
};

export default PasswordInput;
