import { createSlice } from '@reduxjs/toolkit'


export const otherBenefitsSlice = createSlice({
    name: 'other_benefits',
    initialState: {
        data: {
            "education": {

            },
            "sports_gym": {

            },
            "loans": {

            },
            "company_car": {

            },
            "long_term_awards": {

            },
            "other": {

            }
        },


    },
    reducers: {
        setOtherBenefitsData: (state, action) => {
            state.data = action.payload
        },

    }
})

export const { setOtherBenefitsData } = otherBenefitsSlice.actions

export default otherBenefitsSlice.reducer


/*
education: {
        offering: null
    },
    sports_gym: {
        offering: null
    },
    loans: {
        offering: null
    },
    company_car: {
        offering: null
    },
    long_term_awards: {
        offering: null
    },
    other: {
        offering: null
    },
    */