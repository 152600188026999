import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function Overtime(props) {
  console.log(props.data);
  const { levels_org, timeof_in_lieu_org } = props.data;
  return (
    <div>
      <BlockHeading text="4. Overtime" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title="Levels Eligible for Overtime"
          footer={getFooter(levels_org?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={levels_org?.categories}
            series={[
              {
                name: "Levels Eligible for Overtime",
                data: levels_org?.data,
              },
            ]}
            height={"auto"}
          />
        </BCard>
        <BCard
          title="Time Off In Lieu"
          footer={getFooter(timeof_in_lieu_org?.total)}
          note={getNote()}
        >
          <PieChart
            categories={timeof_in_lieu_org?.categories}
            series={timeof_in_lieu_org?.data}
            height={"200px"}
          />
        </BCard>
      </div>
    </div>
  );
}
