import AuthLayout from "../../../components/layouts/Auth";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../configs/endpoints";
import { LoaderSVG } from "../../../components/icons/LoaderSvg";


export default function EmailVerification() {

    const [apiReq, setApiReq] = useState({
        loading: true, error: null, success: false
    })

    const [searchParams] = useSearchParams();


    const onSubmit = (verification_id) => {
        setApiReq({ loading: true, error: null, success: false })
        axios.get(`${API_URL}/api/v1/verify-email?id=${verification_id}`).then(res => {
            setApiReq({ loading: false, error: null, success: true })
            
        }).catch(err => {
            if (err?.response?.status) {
                setApiReq({ loading: false, error: err.response.data.message, success: false })
            } else {
                setApiReq({ loading: false, error: 'Error processing request', success: false })
            }
        })
    }

    useEffect(() => {
        onSubmit(searchParams.get("verification_key"))
    }, [])

    return (
        <AuthLayout title="Forgot Password">

            <div className="text-center mb-8">
                <h2 className="text-2xl font-semibold mt-4">Email Verification</h2>
            </div>
            <div className="flex-grow mt-10">
                {
                    apiReq.loading &&
                    <div>
                        <div className="text-blue-600 text-md text-center">Your email is being verified</div>
                        <div className="flex justify-center pt-10">
                            <LoaderSVG />
                        </div>
                    </div>
                }

                {
                    apiReq.success &&
                    <div>
                        <div className="text-green-700 text-md text-center">Your email has been verified successfully. You can now log in to your account.</div>

                        <a href="/login" style={{ backgroundColor: '#8025EE' }} className="w-full flex justify-center bg-blue-600 text-white py-2 my-5 rounded-lg hover:bg-blue-700">
                            Log In
                        </a>
                    </div>
                }

                <div className="text-red-600 text-md text-center">{apiReq.error && apiReq.error}</div>

            </div>
        </AuthLayout>
    )
}
