import { createSlice } from '@reduxjs/toolkit'


export const incentivesSlice = createSlice({
  name: 'incentives',
  initialState: {
    data: {
      short_term_incentives: {
  
      },
      commission_schemes: {
        
      },
      long_term_incentives: {
        
      },
      profit_sharing: {
        
      }
    }

  },
  reducers: {
    setIncentivesData: (state, action) => {
      state.data = action.payload
    },

  }
})

export const { setIncentivesData } = incentivesSlice.actions

export default incentivesSlice.reducer


/*
short_term_incentives: {

    },
    commission_schemes: {

    },
    long_term_incentives: {

    },
    profit_sharing: {

    }
    */