import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import STMVariableIncentives from './STMVariableIncentives';
import CommissionSchemes from './CommissionSchemes';
import LongTermIncentives from './LongTermIncentives';
import ProfitSharing from './ProfitSharing';
import { setIncentivesData } from '../../../../../../redux/onboard/benefits/incentives';
import { toast } from 'react-toastify';

export default function Incentives(props){
    const incentives = useSelector(state => state.incentives)
    const { data } = incentives
    const dispatch = useDispatch()

    const { register, handleSubmit, watch, reset, control,
        formState: { errors, isDirty },
    } = useForm();

    useEffect(() => {
        reset(data)
    }, [])

    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])

    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    
    }





    const onSubmit = (form_data) => {
        if (isDirty){
            dispatch(setIncentivesData(form_data))
            props.onSaveClick("benefits", "incentives", form_data)
        }
        props.changeTab(3)
    };
    
    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <STMVariableIncentives control={control} watch={watch} register={register} errors={errors} />
            <CommissionSchemes control={control} watch={watch} register={register} errors={errors} />
            <LongTermIncentives control={control} watch={watch} register={register} errors={errors} />
            <ProfitSharing control={control} watch={watch} register={register} errors={errors} /> 

            <div className="flex justify-end mt-5 px-2 py-2">
                <button type='submit' className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}