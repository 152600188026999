import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function ProfitSharing(props) {
  if (!props.data) return null;
  const { offering, data } = props.data;

  
  return (
    <div>
      <BlockHeading text="4. Profit Sharing" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Profit Sharing Offered"}
          footer={getFooter(offering?.total)}
          note={getNote()}
          total={offering?.total}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Profit Sharing Aount As % Of Basic Salary"}
          footer={getFooter(data?.data?.total)}
          note={getNote()}
          total={data?.data?.total}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={data?.data?.categories}
            series={data?.data?.data}
            height="auto"
          />
        </BCard>
      </div>
    </div>
  );
}
