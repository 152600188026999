export const Agriculture = {
    "Agriculture": {
        "Crop Production": {
            "job_family_descriptor": "In this job family professionals work to ensure the successful cultivation of crops and maximize agricultural yields.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Crop Production",
                    "position_descriptor": "The Director oversees all aspects of crop management for an organization or large-scale farming operation. They develop strategies, set goals, and lead teams to ensure optimal crop yields and quality.",
                    "related_job_titles": " Director of Crop Production, Crop Production Director, Head of Crop Management, Chief Agronomist."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Agronomist / Crop Management Specialist",
                    "position_descriptor": "Senior Agronomists or Crop Management Specialists are highly experienced professionals responsible for designing and implementing crop management plans. They provide expertise in crop selection, planting, and maintenance.",
                    "related_job_titles": " Senior Agronomist, Crop Management Specialist, Senior Crop Scientist, Principal Agronomist."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Crop Scientist / Agronomist / Farm Manager",
                    "position_descriptor": "Crop Scientists or Agronomists conduct research on crop varieties, cultivation techniques, and pest control. They work to improve crop productivity and sustainability.",
                    "related_job_titles": " Crop Scientist, Agronomist, Crop Researcher, Field Crop Specialist."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Crop Consultant / Agriculture Advisor / Farm supervisor",
                    "position_descriptor": "Crop Consultants or Agriculture Advisors provide guidance to farmers on crop management. They assess crop conditions, recommend practices, and offer solutions to improve yields.",
                    "related_job_titles": " Crop Consultant, Agriculture Advisor, Crop Management Consultant, Farming Advisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Crop Foreman / Farm Supervisor",
                    "position_descriptor": "Crop Managers or Farm Managers oversee day-to-day crop operations on a farm. They plan planting schedules, manage labor, and ensure that crops are cultivated effectively.",
                    "related_job_titles": " Crop Manager, Farm Manager, Crop Operations Manager, Farming Supervisor."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Crop Technician / Agricultural Technician",
                    "position_descriptor": "Crop Technicians or Agricultural Technicians assist with crop-related tasks, including planting, irrigation, and pest control. They provide hands-on support to crop management activities.",
                    "related_job_titles": " Crop Technician, Agricultural Technician, Crop Operations Technician, Farming Assistant."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Crop Specialist / Field Agronomist",
                    "position_descriptor": "Crop Specialists or Field Agronomists focus on specific crop types or agricultural regions. They conduct research, offer expert advice, and address crop-related challenges.",
                    "related_job_titles": " Crop Specialist, Field Agronomist, Crop Research Specialist, Regional Crop Advisor."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Crop Analyst / Agriculture Analyst",
                    "position_descriptor": "Crop Analysts or Agriculture Analysts collect and analyze data related to crop performance. They provide insights and reports to support decision-making in crop management.",
                    "related_job_titles": " Crop Analyst, Agriculture Analyst, Crop Data Analyst, Agricultural Data Specialist."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Crop Intern / Junior Agronomy Assistant",
                    "position_descriptor": "Crop Interns or Junior Agronomy Assistants are entry-level positions in crop production. They gain hands-on experience by assisting with various crop-related tasks.",
                    "related_job_titles": " Crop Intern, Junior Agronomy Assistant, Farming Intern, Crop Research Trainee."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Farm Laborer / Crop Worker",
                    "position_descriptor": "Farm Laborers or Crop Workers perform manual labor tasks on farms. They are involved in planting, harvesting, and maintaining crops under the supervision of higher-level professionals.",
                    "related_job_titles": " Farm Laborer, Crop Worker, Field Hand, Agriculture Laborer."
                }
            ]
        },
        "Livestock and Animal Husbandry": {
            "job_family_descriptor": "In this job family professionals are responsible for the care, breeding, and management of livestock and animals",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Livestock Officer / Director of Animal Husbandry",
                    "position_descriptor": " The Chief Livestock Officer or Director of Animal Husbandry is the highest-ranking position in livestock and animal husbandry. They oversee all aspects of animal care, breeding, and management, ensuring the highest standards of animal health and welfare.",
                    "related_job_titles": " Chief Livestock Officer, Director of Animal Husbandry, Livestock Director, Head of Animal Care."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Animal Scientist / Livestock Management Specialist",
                    "position_descriptor": " Senior Animal Scientists or Livestock Management Specialists are experts in animal husbandry. They design and implement livestock management programs, optimize breeding strategies, and ensure animal well-being.",
                    "related_job_titles": " Senior Animal Scientist, Livestock Management Specialist, Senior Livestock Manager, Principal Animal Husbandry Advisor."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Animal Husbandry Specialist / Livestock Manager",
                    "position_descriptor": " Animal Husbandry Specialists or Livestock Managers manage and oversee day-to-day operations of livestock facilities. They are responsible for breeding programs, nutrition, and health management.",
                    "related_job_titles": " Animal Husbandry Specialist, Livestock Manager, Herd Manager, Livestock Operations Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Livestock Nutritionist / Animal Health Technician",
                    "position_descriptor": " Livestock Nutritionists or Animal Health Technicians focus on the dietary and health needs of animals. They create nutrition plans, manage feed quality, and support animal health care.",
                    "related_job_titles": " Livestock Nutritionist, Animal Health Technician, Livestock Dietician, Animal Health Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Livestock Technician / Farm Supervisor",
                    "position_descriptor": " Livestock Technicians or Farm Supervisors provide hands-on care for livestock. They oversee daily feeding, cleanliness, and animal health under the direction of higher-level professionals.",
                    "related_job_titles": " Livestock Technician, Farm Supervisor, Animal Caretaker, Livestock Farm Assistant."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Animal Breeder / Livestock Specialist",
                    "position_descriptor": " Animal Breeders or Livestock Specialists focus on animal breeding programs. They select breeding pairs, oversee mating, and manage genetic diversity in livestock herds.",
                    "related_job_titles": " Animal Breeder, Livestock Specialist, Livestock Breeding Technician, Animal Genetics Specialist."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Animal Health Officer / Livestock Officer",
                    "position_descriptor": " Animal Health Officer or Livestock Officer are responsible for monitoring and ensuring the health, safety, and disease management of animals.",
                    "related_job_titles": " Animal Health Officer, Livestock Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Animal Health Assistant / Livestock Caretaker",
                    "position_descriptor": " Animal Health Assistants or Livestock Caretakers provide support to higher-level professionals in animal care. They assist with feeding, cleaning, and basic health checks.",
                    "related_job_titles": " Animal Health Assistant, Livestock Caretaker, Animal Farm Assistant, Livestock Farmhand."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Livestock Intern / Animal Husbandry Trainee",
                    "position_descriptor": " Livestock Interns or Animal Husbandry Trainees are entry-level positions in animal husbandry. They gain practical experience by assisting with various animal care tasks.",
                    "related_job_titles": " Livestock Intern, Animal Husbandry Trainee, Livestock Farming Intern, Animal Care Trainee."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Animal Farm Laborer / Livestock Worker",
                    "position_descriptor": " Animal Farm Laborers or Livestock Workers perform manual labor tasks on farms and in livestock facilities. They are responsible for feeding, cleaning, and assisting in daily animal care.",
                    "related_job_titles": " Animal Farm Laborer, Livestock Worker, Farm Hand, Animal Care Laborer."
                }
            ]
        },
        "Farm Management and Operations": {
            "job_family_descriptor": "This job family involves roles related to the management and operation of farms, including farm owners, farm managers, and farm laborers",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Farm Director / Agricultural Operations Director",
                    "position_descriptor": " The Farm Director or Agricultural Operations Director oversees all aspects of a farm's operations. They set strategic goals, manage resources, and ensure the overall success and profitability of the farm.",
                    "related_job_titles": " Farm Director, Agricultural Operations Director, Farm Manager, Farm Administrator."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Farm Manager / Ranch Manager",
                    "position_descriptor": " Senior Farm Managers or Ranch Managers are experienced professionals who lead and manage day-to-day farming operations. They make strategic decisions, supervise staff, and maintain the farm's efficiency and productivity.",
                    "related_job_titles": " Senior Farm Manager, Ranch Manager, Farm Operations Manager, Senior Agricultural Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Farm Manager / Agricultural Operations Manager",
                    "position_descriptor": " Farm Managers or Agricultural Operations Managers oversee and coordinate farming activities. They manage budgets, plan crop production, and ensure that farming operations run smoothly.",
                    "related_job_titles": " Farm Manager, Agricultural Operations Manager, Crop Manager, Farming Operations Supervisor."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Farm Supervisor / Operations Supervisor",
                    "position_descriptor": " Farm Supervisors or Operations Supervisors are responsible for supervising farm workers and ensuring that tasks are completed efficiently. They may oversee specific farm sections or activities.",
                    "related_job_titles": " Farm Supervisor, Operations Supervisor, Farm Section Supervisor, Agriculture Operations Coordinator."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Farm Technician / Agriculture Technician",
                    "position_descriptor": " Farm Technicians or Agriculture Technicians provide hands-on support in farming operations. They may be involved in planting, harvesting, irrigation, or livestock care, depending on the farm's needs.",
                    "related_job_titles": " Farm Technician, Agriculture Technician, Farm Analyst"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Farm Operations Officer/Coordinator",
                    "position_descriptor": " Farm Operations Officers or Coordinators play a vital role in planning and organizing day-to-day farm activities. They ensure tasks are allocated, resources are managed efficiently, and the farm operates smoothly.",
                    "related_job_titles": " Farm Operations Officer, Farm Coordinator, Operations Manager, Farming Operations Coordinator."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Farm Operations Assistant",
                    "position_descriptor": " Farm Operations Assistants provide support to higher-level professionals in various farming tasks. They assist in coordinating activities, ensuring equipment readiness, and maintaining a well-organized farm environment.",
                    "related_job_titles": " Farm Operations Assistant, Operations Assistant, Farm Assistant, Agricultural Support."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Farm Intern / Agriculture Trainee",
                    "position_descriptor": " Farm Interns or Agriculture Trainees are entry-level positions designed to provide individuals with hands-on experience in farm operations. They assist in various farming activities.",
                    "related_job_titles": " Farm Intern, Agriculture Trainee, Farming Intern, Agriculture Assistant."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Farm Laborer / Worker",
                    "position_descriptor": " Farm Laborers or Agricultural Workers perform manual labor tasks on the farm. They participate in activities like planting, harvesting, and maintenance as directed by higher-level supervisors.",
                    "related_job_titles": " Farm Laborer, Agricultural Worker, Farm Hand, Agriculture Laborer."
                }
            ]
        },
        "Food Processing and Production": {
            "job_family_descriptor": "This job family covers roles involved in food processing, food safety, and production of value-added agricultural products, including food technologists and production supervisors.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Food Processing",
                    "position_descriptor": "Director of Food Processing is the highest-ranking position responsible for overseeing all aspects of food production. They set strategic goals, manage resources, and ensure the overall success and efficiency of food processing operations.",
                    "related_job_titles": " Chief Food Production Officer, Director of Food Processing, Food Production Director, Food Manufacturing Executive."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Food Production Manager",
                    "position_descriptor": " Senior Food Production Managers or Production Operations Managers are experienced professionals who lead and manage day-to-day food production operations. They make strategic decisions, supervise staff, and maintain efficient food processing practices.",
                    "related_job_titles": " Senior Food Production Manager, Production Operations Manager, Senior Food Operations Supervisor, Food Production Specialist."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Food Production Manager",
                    "position_descriptor": " Food Production Managers or Processing Operations Coordinators oversee and coordinate food processing activities. They manage budgets, plan production schedules, and ensure product quality and safety.",
                    "related_job_titles": " Food Production Manager, Processing Operations Coordinator, Food Operations Manager, Production Planning Supervisor."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Production Supervisor / Operations Supervisor",
                    "position_descriptor": " Production Supervisors or Operations Supervisors are responsible for supervising production workers and ensuring that tasks are completed efficiently. They may oversee specific production lines or activities.",
                    "related_job_titles": " Production Supervisor, Operations Supervisor, Production Line Supervisor, Manufacturing Operations Coordinator."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Food Quality Assurance Specialist / Food Safety Technician",
                    "position_descriptor": " Food Quality Assurance Specialists or Food Safety Technicians focus on maintaining product quality and safety. They monitor production processes, conduct inspections, and ensure compliance with food safety standards.",
                    "related_job_titles": " Food Quality Assurance Specialist, Food Safety Technician, Quality Control Coordinator, Food Production Auditor."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Production Technician / Process Operator",
                    "position_descriptor": " Production Technicians or Process Operators work directly in the manufacturing process. They operate machinery, monitor production lines, and perform routine maintenance tasks to ensure efficient production.",
                    "related_job_titles": " Production Technician, Process Operator, Manufacturing Operator, Production Line Operator."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Food Production Assistant / Processing Assistant",
                    "position_descriptor": " Food Production Assistants or Processing Assistants provide support in various food production tasks. They may assist in preparation, packaging, and maintaining production areas.",
                    "related_job_titles": " Food Production Assistant, Processing Assistant, Food Manufacturing Assistant, Production Support."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Food Production Trainee / Process Trainee",
                    "position_descriptor": " Food Production Trainees or Process Trainees are entry-level positions designed to provide individuals with hands-on experience in food processing. They assist in various production activities under supervision.",
                    "related_job_titles": " Food Production Trainee, Process Trainee, Food Manufacturing Trainee, Production Assistant."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Production Laborer / Manufacturing Worker",
                    "position_descriptor": " Production Laborers or Manufacturing Workers perform manual labor tasks on the production floor. They participate in activities like assembly, packaging, and maintaining production equipment.",
                    "related_job_titles": " Production Laborer, Manufacturing Worker, Production Operator, Manufacturing Laborer."
                }
            ]
        },
        "Forestry": {
            "job_family_descriptor": "Within the Forestry job family, professionals are responsible for the sustainable management and conservation of forests",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Forester / Director of Forestry",
                    "position_descriptor": " The Chief Forester or Director of Forestry is the highest-ranking position responsible for overseeing all aspects of forest management. They set strategic goals, manage resources, and ensure sustainable forestry practices.",
                    "related_job_titles": " Chief Forester, Director of Forestry, Forestry Director, Forest Management Executive."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Forest Manager",
                    "position_descriptor": " Senior Forest Managers or Forestry Operations Managers are experienced professionals who lead and manage day-to-day forest operations. They make strategic decisions, supervise staff, and maintain sustainable forest management practices.",
                    "related_job_titles": " Senior Forest Manager, Forestry Operations Manager, Senior Forest Operations Supervisor, Forest Management Specialist."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Forest Manager",
                    "position_descriptor": " Forest Managers or Forestry Operations Coordinators oversee and coordinate forest management activities. They manage budgets, plan logging and reforestation, and ensure compliance with environmental regulations.",
                    "related_job_titles": " Forest Manager, Forestry Operations Coordinator, Forest Operations Manager, Reforestation Supervisor."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Forest Supervisor / Operations Supervisor",
                    "position_descriptor": " Forest Supervisors or Operations Supervisors are responsible for supervising forestry workers and ensuring that tasks are completed efficiently. They may oversee specific forest sections or activities.",
                    "related_job_titles": " Forest Supervisor, Operations Supervisor, Forest Section Supervisor, Timber Operations Coordinator."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Reforestation Specialist / Forest Technician",
                    "position_descriptor": " Reforestation Specialists or Forest Technicians play a crucial role in replanting and restoring forests. They manage seedlings, conduct planting operations, and monitor reforestation progress.",
                    "related_job_titles": " Reforestation Specialist, Forest Technician, Reforestation Coordinator, Forest Restoration Specialist."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Forestry Officer",
                    "position_descriptor": "Officer ensure the sustainable management of forest resources, including planning and executing reforestation, harvesting, and conservation efforts and play a critical role in maintaining healthy forests, monitoring timber production, and complying with environmental regulations",
                    "related_job_titles": "Forestry Officer, Forest Management Officer, Conservation Forester, Forest Operations Supervisor."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Logging Technician / Harvesting Coordinator",
                    "position_descriptor": " Logging Technicians or Harvesting Coordinators are responsible for timber harvesting operations. They oversee logging crews, equipment, and safety protocols to ensure efficient and responsible harvesting.",
                    "related_job_titles": " Logging Technician, Harvesting Coordinator, Timber Operations Supervisor, Logging Crew Leader."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Forestry Assistant Officer",
                    "position_descriptor": " Forestry Assistants or Forest Workers provide hands-on support in various forestry tasks. They may be involved in planting, maintenance, reforestation, or logging activities.",
                    "related_job_titles": " Forestry Assistant, Reforestation Assistant"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Forest Worker / Timber Worker",
                    "position_descriptor": " Forest Conservation Technicians or Timber Workers focus on maintaining the health and biodiversity of forests. They support conservation efforts, wildlife monitoring, and habitat protection.",
                    "related_job_titles": "Timber Worker, Lumberjack"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Timber Assistant",
                    "position_descriptor": " Forest Apprentices or Timber Assistants are at the early stages of their forestry careers. They assist in various forest-related tasks, gaining practical experience and knowledge.",
                    "related_job_titles": "Timber Assistant, Logging Assistant."
                }
            ]
        },
        "Fisheries": {
            "job_family_descriptor": "In this job family professionals are responsible for the sustainable management and operation of fishing activities",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Fisheries",
                    "position_descriptor": "Director of Fisheries is the highest-ranking position responsible for overseeing all aspects of fisheries management. They set strategic goals, manage resources, and ensure sustainable fishing practices.",
                    "related_job_titles": " Chief Fisheries Officer, Director of Fisheries, Fisheries Director, Fisheries Management Executive."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Fisheries Manager",
                    "position_descriptor": " Senior Fisheries Managers are experienced professionals who lead and manage day-to-day fishing operations. They make strategic decisions, supervise staff, and maintain sustainable fishing practices.",
                    "related_job_titles": " Senior Fisheries Manager, Fisheries Operations Manager, Senior Fisheries Operations Supervisor, Fisheries Management Specialist."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Fisheries Manager",
                    "position_descriptor": " Fisheries Managers oversee and coordinate fishing activities. They manage budgets, plan fishing schedules, and ensure compliance with fisheries regulations.",
                    "related_job_titles": " Fisheries Manager, Fishing Operations Coordinator, Fisheries Operations Manager, Fishing Operations Planner."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Fishery Scientist / Fisheries Researcher",
                    "position_descriptor": " Fishery Scientists or Fisheries Researchers focus on the scientific aspects of fisheries management. They conduct research, collect data, and analyze the health and sustainability of fish populations.",
                    "related_job_titles": " Fishery Scientist, Fisheries Researcher, Marine Biologist, Fishery Data Analyst."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Fishing / Fisheries Operations Supervisor",
                    "position_descriptor": " Fishing Operations Supervisors or Fisheries Operations Supervisors are responsible for supervising fishing crews and ensuring that tasks are completed efficiently. They may oversee specific fishing vessels or activities.",
                    "related_job_titles": " Fishing Operations Supervisor, Fisheries Operations Supervisor, Fishing Crew Supervisor, Fishing Operations Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Fisheries Technician/ Officer",
                    "position_descriptor": "Fisheries Technicians work on fishing vessels and assist in fishing activities. They may handle gear, maintain equipment, and participate in catch processing.",
                    "related_job_titles": " Fisheries Technician, Fisheries Officer"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Fisheries Assistant Officer",
                    "position_descriptor": " Fisheries Assistants or Fishing Support Workers provide support in various fishing-related tasks. They may assist in preparation, gear maintenance, and maintaining fishing vessels.",
                    "related_job_titles": " Fisheries Assistant, Fishing Support Worker, Fishing Assistant,Deckhand"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Fisheries / Fishing Laborer",
                    "position_descriptor": " Fisheries Laborers or Fishing Laborers perform manual labor tasks related to fishing and fisheries. They may handle catch processing, gear maintenance, and equipment cleaning.",
                    "related_job_titles": " Fisheries Laborer, Fishing Laborer, Fisheries Worker, Fishing Helper."
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Fishing Mate",
                    "position_descriptor": "Fishing Mate are entry-level positions designed to provide individuals with hands-on experience in fishing and fisheries. They assist in various fishing taks under supervision.",
                    "related_job_titles": " Fisheries Trainee, Fishing Assistant, Boat Mate"
                }
            ]
        }
    }
}