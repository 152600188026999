// ** React Imports
import { Navigate, Outlet } from "react-router-dom"
import { isUserLoggedIn } from "../auth/utils"
import { useSelector } from "react-redux"

const PrivateRoute = () => {
  const user = useSelector(state => state.authentication)
  const userLoggedIn = isUserLoggedIn()

  return (
    userLoggedIn ? 
    user.role === "admin" ? <Navigate to='/admin' /> : <Outlet />
    : <Navigate to='/login' />
  )
}
export default PrivateRoute