import BenefitsTabs from "./BenefitsTabs";



export default function Benefits(props) {


    return (
        <div>
            {/* <h1 className="pb-3 text-lg">Benefits and Practices</h1> */}
            <div className="mt-1">
                
            <BenefitsTabs onSaveClick={props.onSaveClick} onNext={props.onNext} tab={props.tab} />
               
            </div>
        </div>
    )
}