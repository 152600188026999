import LifeInsurance from "./LifeInsurance";
import MedicalCover from "./MedicalCover";
import PensionProvident from "./PensionProvident";

export default function BenefitInsurance(props) {
  console.log(props.data);
  const { medical_cover, pension_provident_fund, life_insurance } = props.data;
  return (
    <div>
      <MedicalCover data={medical_cover} />
      <PensionProvident data={pension_provident_fund} />
      <LifeInsurance data={life_insurance} />
    </div>
  );
}
