import { JOB_FAMILIES_LIST } from "./job_families"

const COMPANY = [
    { label: 'Select a value', value: '' },
    { label: 'Parent Company (HQ)', value: 'Parent Company (HQ)' },
    { label: 'Regional Office', value: 'Regional Office' },
    { label: 'Country Office', value: 'Country Office' },
]

const INDUSTRIES = [
    { label: 'Select a value', value: '' },
    // { label: 'Consumer', value: 'Consumer' },
    // { label: 'Energy, Resources & Industrials', value: 'Energy, Resources & Industrials' },
    { label: 'Financial Services', value: 'Financial Services' },
    { label: 'Public Sector', value: 'Public Sector' },
    // { label: 'Government & Public Services', value: 'Government & Public Services' },
    // { label: 'Life Sciences & Health Care', value: 'Life Sciences & Health Care' },
    { label: 'Technology, Media & Telecommunications', value: 'Technology, Media & Telecommunications' },
    { label: 'Professional Services', value: 'Professional Services' },
    { label: 'Agriculture', value: 'Agriculture' },
    { label: 'Industrial Products & Construction', value: 'Industrial Products & Construction' },
    { label: "Energy", value: "Energy" },
    { label: "Tourism & Hospitality", value: "Tourism & Hospitality" },
]

const SECTORS = {
    'Consumer': [
        { label: 'Select a value', value: '' },
        { label: 'Automotive', value: 'Automotive' },
        { label: 'Consumer Product', value: 'Consumer Product' },
        { label: 'Retail, Wholesale & Distribution', value: 'Retail, Wholesale & Distribution' },
        { label: 'Transportation, Hospitality & Services', value: 'Transportation, Hospitality & Services' },
    ],
    'Energy, Resources & Industrials': [
        { label: 'Select a value', value: '' },
        { label: 'Industrial Products & Construction', value: 'Industrial Products & Construction' },
        { label: 'Mining & Metals', value: 'Mining & Metals' },
        { label: 'Oil, Gas & Chemicals', value: 'Oil, Gas & Chemicals' },
        { label: 'Power & Utilities', value: 'Power & Utilities' },
    ],
    'Financial Services': [
        { label: 'Select a value', value: '' },
        { label: 'Banking', value: 'Banking' },
        { label: 'Insurance', value: 'Insurance' },
        // { label: 'Financial Services Management', value: 'Financial Services Management' },
        { label: 'Real Estate', value: 'Real Estate' },
    ],
    'Government & Public Services': [
        { label: 'Select a value', value: '' },
        { label: 'Government & Public Services Government', value: 'Government & Public Services Government' },
        { label: 'Defense, Security & Justice', value: 'Defense, Security & Justice' },
        { label: 'Federal Health', value: 'Federal Health' },
        { label: 'International Donor Organizations', value: 'International Donor Organizations' },
        { label: 'State, Local & Education', value: 'State, Local & Education' }
    ],
    'Life Sciences & Health Care': [
        { label: 'Select a value', value: '' },
        { label: 'Health Care', value: 'Health Care' },
        { label: 'Life Sciences & Health Care Science', value: 'Life Sciences & Health Care Science' },
    ],
    'Technology, Media & Telecommunications': [
        { label: 'Select a value', value: '' },
        { label: 'Technology', value: 'Technology' },
        // { label: 'Telecommunications, Media & Entertainment', value: 'Telecommunications, Media & Entertainment' },
    ],
    "Professional Services": [
        { label: 'Select a value', value: '' },
        { "label": "Consulting", "value": "Consulting" },
        { "label": "Research", "value": "Research" },
        { "label": "Research and Academia", "value": "Research and Academia" },
        // { "label": "General Management", "value": "General Management" },
        // { "label": "Human Resource", "value": "Human Resource" },
        // { "label": "Finance", "value": "Finance" },
        // { "label": "Customer Service and Support", "value": "Customer Service and Support" },
        // { "label": "Operations and Administration", "value": "Operations and Administration" },
        // { "label": "Supply Chain", "value": "Supply Chain" },
        // { "label": "Legal and Compliance", "value": "Legal and Compliance" },
        // { "label": "General Sales", "value": "General Sales" },
        // { "label": "General Marketing", "value": "General Marketing" }
    ],
    "Agriculture": [
        { label: 'Select a value', value: '' },
        { label: "Agriculture", value: "Agriculture" }
    ],
    "Public Sector": [
        { label: 'Select a value', value: '' },
        { label: "Not for Profit", value: "Not for Profit" }
    ],
    "Industrial Products & Construction": [
        { label: 'Select a value', value: '' },
        { label: "Construction", value: "Construction" },
        { label: "Metals and Mining", value: "Metals and Mining" },
        { label: "Manufacturing", value: "Manufacturing" },
        { label: "Safety Health and Environmental Services", value: "Safety Health and Environmental Services" }
    ],
    "Energy": [
        { label: 'Select a value', value: '' },
        { label: "Oil and Gas", value: "Oil and Gas" },
        { label: "Electricity", value: "Electricity" }
    ],
    "Tourism & Hospitality": [
        { label: 'Select a value', value: '' },
        { label: "Food and Beverages", value: "Food and Beverages" },
        { label: "Travel and Tourism", value: "Travel and Tourism" },
        { label: "Lodging and Accommodation", value: "Lodging and Accommodation" },
        { label: "Meetings, Conferences and Exhibitions", value: "Meetings, Conferences and Exhibitions" }
    ]
}


const OFFICES = [
    { label: 'Select a value', value: '' },
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: 'More than 12', value: '12+' },
]


const CURRENCY = [
    { label: 'Select a value', value: '' },
    { label: "Algerian Dinar (DZD)", value: "Algerian Dinar (DZD)" },
    { label: "Angolan Kwanza (AOA)", value: "Angolan Kwanza (AOA)" },
    { label: "Australian Dollar (AUD)", value: "Australian Dollar (AUD)" },
    { label: "Botswana Pula (BWP)", value: "Botswana Pula (BWP)" },
    { label: "British Pound Sterling (GBP)", value: "British Pound Sterling (GBP)" },
    { label: "Burundian Franc (BIF)", value: "Burundian Franc (BIF)" },
    { label: "Canadian Dollar (CAD)", value: "Canadian Dollar (CAD)" },
    { label: "Chinese Yuan (CNY)", value: "Chinese Yuan (CNY)" },
    { label: "Congolese Franc (CDF)", value: "Congolese Franc (CDF)" },
    { label: "Egyptian Pound (EGP)", value: "Egyptian Pound (EGP)" },
    { label: "Ethiopian Birr (ETB)", value: "Ethiopian Birr (ETB)" },
    { label: "Euro (EUR)", value: "Euro (EUR)" },
    { label: "Ghanaian Cedi (GHS)", value: "Ghanaian Cedi (GHS)" },
    { label: "Indian Rupee (INR)", value: "Indian Rupee (INR)" },
    { label: "Japanese Yen (JPY)", value: "Japanese Yen (JPY)" },
    { label: "Kenyan Shilling (KES)", value: "Kenyan Shilling (KES)" },
    { label: "Libyan Dinar (LYD)", value: "Libyan Dinar (LYD)" },
    { label: "Malawian Kwacha (MWK)", value: "Malawian Kwacha (MWK)" },
    { label: "Mauritian Rupee (MUR)", value: "Mauritian Rupee (MUR)" },
    { label: "Moroccan Dirham (MAD)", value: "Moroccan Dirham (MAD)" },
    { label: "Mozambican Metical (MZN)", value: "Mozambican Metical (MZN)" },
    { label: "Namibian Dollar (NAD)", value: "Namibian Dollar (NAD)" },
    { label: "Nigerian Naira (NGN)", value: "Nigerian Naira (NGN)" },
    { label: "Rwandan Franc (RWF)", value: "Rwandan Franc (RWF)" },
    { label: "Senegalese Franc (XOF)", value: "Senegalese Franc (XOF)" },
    { label: "Seychellois Rupee (SCR)", value: "Seychellois Rupee (SCR)" },
    { label: "Somali Shilling (SOS)", value: "Somali Shilling (SOS)" },
    { label: "South African Rand (ZAR)", value: "South African Rand (ZAR)" },
    { label: "Sudanese Pound (SDG)", value: "Sudanese Pound (SDG)" },
    { label: "Swiss Franc (CHF)", value: "Swiss Franc (CHF)" },
    { label: "Tanzanian Shilling (TZS)", value: "Tanzanian Shilling (TZS)" },
    { label: "Tunisian Dinar (TND)", value: "Tunisian Dinar (TND)" },
    { label: "Ugandan Shilling (UGX)", value: "Ugandan Shilling (UGX)" },
    { label: "United States Dollar (USD)", value: "United States Dollar (USD)" },
    { label: "West African Franc (CFA)", value: "West African Franc (CFA)" },
    { label: "Zambian Kwacha (ZMW)", value: "Zambian Kwacha (ZMW)" },
    { label: "Zimbabwean Dollar (ZWL)", value: "Zimbabwean Dollar (ZWL)" },
    { label: "Multi-Currency - Local & USD", value: "Multi-Currency - Local & USD" },
    { label: "Multi-Currency - Local & Euro", value: "Multi-Currency - Local & Euro" },
    { label: "Multi-Currency - Local & Other", value: "Multi-Currency - Local & Other" }
]

const CURRENCY_SYMBOLS = {
    "Algerian Dinar (DZD)": "د.ج",  // Approximation: Arabic symbol for Dinar
    "Angolan Kwanza (AOA)": "Kz",
    "Australian Dollar (AUD)": "$",
    "Botswana Pula (BWP)": "P",
    "British Pound Sterling (GBP)": "£",
    "Burundian Franc (BIF)": "₣",  // Approximation: Generic Franc symbol
    "Canadian Dollar (CAD)": "$",
    "Chinese Yuan (CNY)": "¥",
    "Congolese Franc (CDF)": "₣",  // Approximation: Generic Franc symbol
    "Egyptian Pound (EGP)": "£",   // Could also be E£
    "Ethiopian Birr (ETB)": "Br",
    "Euro (EUR)": "€",
    "Ghanaian Cedi (GHS)": "₵",
    "Indian Rupee (INR)": "₹",
    "Japanese Yen (JPY)": "¥",
    "Kenyan Shilling (KES)": "KSh",
    "Libyan Dinar (LYD)": "ل.د",  // Approximation: Arabic symbol for Dinar
    "Malawian Kwacha (MWK)": "MK",
    "Mauritian Rupee (MUR)": "₨",  // Approximation: Generic Rupee symbol
    "Moroccan Dirham (MAD)": "د.م.", // Approximation: Arabic symbol for Dirham
    "Mozambican Metical (MZN)": "MT",
    "Namibian Dollar (NAD)": "$",
    "Nigerian Naira (NGN)": "₦",
    "Rwandan Franc (RWF)": "₣",  // Approximation: Generic Franc symbol
    "Senegalese Franc (XOF)": "₣",  // Approximation: Generic Franc symbol
    "Seychellois Rupee (SCR)": "₨",  // Approximation: Generic Rupee symbol
    "Somali Shilling (SOS)": "Sh",  // Approximation
    "South African Rand (ZAR)": "R",
    "Sudanese Pound (SDG)": "£",   // Could also be SD£
    "Swiss Franc (CHF)": "CHF",    // No single symbol, often written as "CHF"
    "Tanzanian Shilling (TZS)": "TSh",
    "Tunisian Dinar (TND)": "د.ت",  // Approximation: Arabic symbol for Dinar
    "Ugandan Shilling (UGX)": "USh",
    "United States Dollar (USD)": "$",
    "West African Franc (CFA)": "₣",  // Approximation: Generic Franc symbol
    "Zambian Kwacha (ZMW)": "ZK",
    "Zimbabwean Dollar (ZWL)": "$",
    "Multi-Currency - Local & USD": "MULTI-USD",  // Placeholder
    "Multi-Currency - Local & Euro": "MULTI-EUR", // Placeholder
    "Multi-Currency - Local & Other": "MULTI-OTHER"  // Placeholder
};

const CURRENCY_CODE_SYMBOLS =  {
    "DZD": "د.ج",  // Algerian Dinar
    "AOA": "Kz",   // Angolan Kwanza
    "AUD": "$",    // Australian Dollar
    "BWP": "P",    // Botswana Pula
    "GBP": "£",    // British Pound Sterling
    "BIF": "₣",    // Burundian Franc
    "CAD": "$",    // Canadian Dollar
    "CNY": "¥",    // Chinese Yuan
    "CDF": "₣",    // Congolese Franc
    "EGP": "£",    // Egyptian Pound
    "ETB": "Br",   // Ethiopian Birr
    "EUR": "€",    // Euro
    "GHS": "₵",    // Ghanaian Cedi
    "INR": "₹",    // Indian Rupee
    "JPY": "¥",    // Japanese Yen
    "KES": "KSh",  // Kenyan Shilling
    "LYD": "ل.د",  // Libyan Dinar
    "MWK": "MK",   // Malawian Kwacha
    "MUR": "₨",    // Mauritian Rupee
    "MAD": "د.م.", // Moroccan Dirham
    "MZN": "MT",   // Mozambican Metical
    "NAD": "$",    // Namibian Dollar
    "NGN": "₦",    // Nigerian Naira
    "RWF": "₣",    // Rwandan Franc
    "XOF": "₣",    // Senegalese Franc
    "SCR": "₨",    // Seychellois Rupee
    "SOS": "Sh",   // Somali Shilling
    "ZAR": "R",    // South African Rand
    "SDG": "£",    // Sudanese Pound
    "CHF": "CHF",  // Swiss Franc
    "TZS": "TSh",  // Tanzanian Shilling
    "TND": "د.ت",  // Tunisian Dinar
    "UGX": "USh",  // Ugandan Shilling
    "USD": "$",    // United States Dollar
    "CFA": "₣",    // West African Franc
    "ZMW": "ZK",   // Zambian Kwacha
    "ZWL": "$",    // Zimbabwean Dollar
    "MULTI-USD": "MULTI-USD",    // Multi-Currency - Local & USD
    "MULTI-EUR": "MULTI-EUR",    // Multi-Currency - Local & Euro
    "MULTI-OTHER": "MULTI-OTHER" // Multi-Currency - Local & Other
};

// Benefits

const ORGANIZATION_PEOPLE = [
    "Head of Organisation", "Top Management/Executives", "Management", "Team Leaders", "Professionals",
    "Technicians/Artisans", "Operative Support Staff"
]

const ORGANIZATION_PEOPLE_OBJ = [
    { label: "All Levels", value: "All Levels" },
    { label: "Head of Organisation", value: "Head of Organisation" },
    { label: "Top Management/Executives", value: "Top Management/Executives" },
    { label: "Management", value: "Management" },
    { label: "Team Leaders", value: "Team Leaders" },
    { label: "Professionals", value: "Professionals" },
    { label: "Technicians/Artisans", value: "Technicians/Artisans" },
    { label: "Operative Support Staff", value: "Operative Support Staff" },
    { label: "None", value: "None" },
]

const YES_NO = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
]

const JOB_FAMILIES = JOB_FAMILIES_LIST


export { COMPANY, SECTORS, INDUSTRIES, OFFICES, CURRENCY, ORGANIZATION_PEOPLE, ORGANIZATION_PEOPLE_OBJ, JOB_FAMILIES, YES_NO, CURRENCY_SYMBOLS, CURRENCY_CODE_SYMBOLS }