import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { YES_NO } from "../../../../../../configs/data/onboarding"


const EVP = [
    { label: "Yes, all of them can", value: "Yes, all of them can" },
    { label: "Yes, at least 75% or more articulate it well", value: "Yes, at least 75% or more articulate it well" },
    { label: "Yes, at least 50% or more articulate it well", value: "Yes, at least 50% or more articulate it well" },
    { label: "Yes, less than 50% articluate it well", value: "Yes, less than 50% articluate it well" },
    { label: "Yes, less than 35% articulate it well.", value: "Yes, less than 35% articulate it well." },
    { label: "No", value: "No" },
]

const EVP_OFFERING = [
    { label: "Yes, implemented less than two years ago", value: "Yes, implemented less than two years ago" },
    { label: "Yes, implemented less than 3-5 years ago", value: "Yes, implemented less than 3-5 years ago" },
    { label: "Yes, implemented more than 5 years ago", value: "Yes, implemented more than 5 years ago" },,
    { label: "No", value: "No" },
]

const YES_NO_SURE = [
    ...YES_NO,
    { label: "Not Sure", value: "Not Sure" }
]

const ORG_WELL_BEING = [
    { label: "Human Resources", value: "Human Resources" },
    { label: "Health, Safety & Environment", value: "Health, Safety & Environment" },
    { label: "Occupational Health", value: "Occupational Health" },
    { label: "Well-being is independent/standalone unit", value: "Well-being is independent/standalone unit" },
    { label: "Other", value: "Other" },
]

export default function Questions(props) {
    const id = 'wb_data'
    const { watch, errors, control, register } = props
    return (
        <div>



            <div className="my-2">
                    <div>
                        <Label className="mb-2" htmlFor="evp_program"
                            text="Does your organisation have a formal employee value proposition program (EVP)?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.evp_program`}
                            errors={errors} watch={watch}
                            options={EVP_OFFERING} control={control} />
                    </div>
                    <div className='my-2 mt-3'>
                        { !["No", undefined, null].includes(watch(`${id}.evp_program`)) && // === "Yes" &&
                            <>
                                <Label className="mb-2" htmlFor="evp_articulate"
                                    text="If yes, can people managers articulate the EVP?" />
                                <SelectAutocompleteSingle
                                    required
                                    name={`${id}.evp_articulate`}
                                    errors={errors} watch={watch}
                                    options={EVP} control={control} />
                            </>
                        }
                    </div>
                    
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                    <div>
                        <Label className="mb-2" htmlFor="dei_program"
                            text="Does your organisation have a formal DEI (diversity, equity and inclusion) strategy/program?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.dei_program`}
                            errors={errors} watch={watch}
                            options={YES_NO_SURE} control={control} />
                    </div>
                    <div>
                        <Label className="mb-2" htmlFor="wellbeing_strategy"
                            text="Does your organisation have a formal employee workplace well-being strategy?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.wellbeing_strategy`}
                            errors={errors} watch={watch}
                            options={YES_NO_SURE} control={control} />
                    </div>
                    {watch(`${id}.wellbeing_strategy`) === "Yes" &&
                        <div>
                            <Label className="mb-2" htmlFor="wellbeing_strategy_owns"
                                text="If yes, who owns well-being in your organisation?" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.wellbeing_strategy_owns`}
                                errors={errors} watch={watch}
                                options={ORG_WELL_BEING} control={control} />
                        </div>
                    }
                    {/* <div>
                        <Label className="mb-2" htmlFor="wellbeing_inititiates"
                            text="Does your organisation offer well-being inititiates?" />
                        <SelectAutocompleteSingle
                            required
                            name="wellbeing_inititiates"
                            errors={errors} watch={watch}
                            options={YES_NO} control={control} />
                    </div> */}
                </div>
            </div>



        </div>
    )
}