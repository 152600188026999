import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const appDataSlice = createSlice({
    name: 'app_data',
    initialState: {
        sidebar_open: true,
        onboarding : {
            readonly: false
        }
    },
    reducers: {
        setAppData: (state, action) => {
            state[action.payload.key] = action.payload.value;
        },
        setOnBoardingReadOnly: (state, action) => {
            state.onboarding.readonly = action.payload;
        }
    },
  
});

export const onBoardingReadOnly = (state) => state.app_data.onboarding.readonly;

export const { setAppData, setOnBoardingReadOnly } = appDataSlice.actions;

export default appDataSlice.reducer;
