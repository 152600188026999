import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, TextField } from '@mui/material';

export default function AddRowPopup(props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const { availableJF } = props
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddClose = () => {
        // iterate over availableJF and find the one with the same job_family
        var selected = {}
        availableJF.map((item) => {
            if (item.job_family === value) {
                selected = item
            }
        })
        props.onAdd(selected)
        setOpen(false);
    }
    return (
        <React.Fragment>
            <button
    className='flex items-center justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700 mt-3'
    onClick={(e) => {
        e.preventDefault()
        handleClickOpen()
    }}
>
    {/* SVG Plus Icon */}
    <svg 
        className='mr-2' 
        width='16' 
        height='16' 
        viewBox='0 0 16 16' 
        fill='none' 
        xmlns='http://www.w3.org/2000/svg'
    >
        <path 
            fillRule='evenodd' 
            clipRule='evenodd' 
            d='M8 1C8.55228 1 9 1.44772 9 2V7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H9V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V9H2C1.44772 9 1 8.55228 1 8C1 7.44772 1.44772 7 2 7H7V2C7 1.44772 7.44772 1 8 1Z' 
            fill='currentColor'
        />
    </svg>
    Add Row
</button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add new row"}
                </DialogTitle>
                <DialogContent sx={{ minWidth: '500px', py:5 }}>
                    <DialogContentText id="alert-dialog-description" sx={{ mt: 2, mb: 0.5 }}>
                        Please select a value
                    </DialogContentText>
                    <Autocomplete
                    fullWidth
                        id="combo-box-demo"
                        options={availableJF.map((option) => option.job_family)}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} size="small" />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddClose} autoFocus>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
