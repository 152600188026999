export const JOB_FAMILIES_LIST = [
    {
        "label": "Accounting and Auditing",
        "value": "Accounting and Auditing"
    },
    {
        "label": "Actuarial",
        "value": "Actuarial"
    },
    {
        "label": "Architecture",
        "value": "Architecture"
    },
    {
        "label": "Banking Strategy and Operations",
        "value": "Banking Strategy and Operations"
    },
    {
        "label": "Beverage Production and Management",
        "value": "Beverage Production and Management"
    },
    {
        "label": "Building and Construction Services",
        "value": "Building and Construction Services"
    },
    {
        "label": "Business Consulting",
        "value": "Business Consulting"
    },
    {
        "label": "Capital Markets",
        "value": "Capital Markets"
    },
    {
        "label": "Claims",
        "value": "Claims"
    },
    {
        "label": "Claims Investigation and Fraud Detection",
        "value": "Claims Investigation and Fraud Detection"
    },
    {
        "label": "Commercial",
        "value": "Commercial"
    },
    {
        "label": "Compliance and Regulatory Affairs",
        "value": "Compliance and Regulatory Affairs"
    },
    {
        "label": "Construction",
        "value": "Construction"
    },
    {
        "label": "Corporate Banking",
        "value": "Corporate Banking"
    },
    {
        "label": "Crop Production",
        "value": "Crop Production"
    },
    {
        "label": "Culinary Arts and Kitchen Management",
        "value": "Culinary Arts and Kitchen Management"
    },
    {
        "label": "Customer Service and Support (General)",
        "value": "Customer Service and Support (General)"
    },
    {
        "label": "Customer Service | Call Center Operations",
        "value": "Customer Service | Call Center Operations"
    },
    {
        "label": "Customer Service | Field Services Customer Support",
        "value": "Customer Service | Field Services Customer Support"
    },
    {
        "label": "Customer Service | Technical Customer Support",
        "value": "Customer Service | Technical Customer Support"
    },
    {
        "label": "Data Science and Analytics",
        "value": "Data Science and Analytics"
    },
    {
        "label": "Data Science and Analytics",
        "value": "Data Science and Analytics"
    },
    {
        "label": "Distribution and Marketing",
        "value": "Distribution and Marketing"
    },
    {
        "label": "Electricity and Power Generation",
        "value": "Electricity and Power Generation"
    },
    {
        "label": "Engineering",
        "value": "Engineering"
    },
    {
        "label": "Environmental and Climate",
        "value": "Environmental and Climate"
    },
    {
        "label": "Event Planning and Management",
        "value": "Event Planning and Management"
    },
    {
        "label": "Exploration and Production (Upstream)",
        "value": "Exploration and Production (Upstream)"
    },
    {
        "label": "Facilities & Plant Management",
        "value": "Facilities & Plant Management"
    },
    {
        "label": "Farm Management and Operations",
        "value": "Farm Management and Operations"
    },
    {
        "label": "Finance",
        "value": "Finance"
    },
    {
        "label": "Finance | Accounting",
        "value": "Finance | Accounting"
    },
    {
        "label": "Finance | Financial Analysis",
        "value": "Finance | Financial Analysis"
    },
    {
        "label": "Finance | Financial Planning and Analysis",
        "value": "Finance | Financial Planning and Analysis"
    },
    {
        "label": "Finance | Financial Technology (FinTech)",
        "value": "Finance | Financial Technology (FinTech)"
    },
    {
        "label": "Finance | General Internal Audit",
        "value": "Finance | General Internal Audit"
    },
    {
        "label": "Finance | Risk Management",
        "value": "Finance | Risk Management"
    },
    {
        "label": "Finance | Tax Accounting",
        "value": "Finance | Tax Accounting"
    },
    {
        "label": "Finance | Treasury Management",
        "value": "Finance | Treasury Management"
    },
    {
        "label": "Financial Consulting",
        "value": "Financial Consulting"
    },
    {
        "label": "Fisheries",
        "value": "Fisheries"
    },
    {
        "label": "Food Processing and Production",
        "value": "Food Processing and Production"
    },
    {
        "label": "Food and Beverage Service",
        "value": "Food and Beverage Service"
    },
    {
        "label": "Forestry",
        "value": "Forestry"
    },
    {
        "label": "Fundraising and Resource Mobilization",
        "value": "Fundraising and Resource Mobilization"
    },
    {
        "label": "General Management",
        "value": "General Management"
    },
    {
        "label": "General Marketing",
        "value": "General Marketing"
    },
    {
        "label": "General Real Estate Management",
        "value": "General Real Estate Management"
    },
    {
        "label": "General Sales",
        "value": "General Sales"
    },
    {
        "label": "Geographic Information System (GIS)",
        "value": "Geographic Information System (GIS)"
    },
    {
        "label": "Guest Relations and Concierge",
        "value": "Guest Relations and Concierge"
    },
    {
        "label": "Hospitality and Catering Management",
        "value": "Hospitality and Catering Management"
    },
    {
        "label": "Hotel Management",
        "value": "Hotel Management"
    },
    {
        "label": "Housekeeping and Cleaning",
        "value": "Housekeeping and Cleaning"
    },
    {
        "label": "Human Resource",
        "value": "Human Resource"
    },
    {
        "label": "Human Resource | Compensation & Benefits",
        "value": "Human Resource | Compensation & Benefits"
    },
    {
        "label": "Human Resource | Diversity & Inclusion",
        "value": "Human Resource | Diversity & Inclusion"
    },
    {
        "label": "Human Resource | Employee Relations & Engagement",
        "value": "Human Resource | Employee Relations & Engagement"
    },
    {
        "label": "Human Resource | HR Analytics & Data Management",
        "value": "Human Resource | HR Analytics & Data Management"
    },
    {
        "label": "Human Resource | HR Business Partnering",
        "value": "Human Resource | HR Business Partnering"
    },
    {
        "label": "Human Resource | HR Information Systems (HRIS)",
        "value": "Human Resource | HR Information Systems (HRIS)"
    },
    {
        "label": "Human Resource | Labor Relations",
        "value": "Human Resource | Labor Relations"
    },
    {
        "label": "Human Resource | Organizational Development",
        "value": "Human Resource | Organizational Development"
    },
    {
        "label": "Human Resource | Performance Management",
        "value": "Human Resource | Performance Management"
    },
    {
        "label": "Human Resource | Recruitment & Talent Acquisition",
        "value": "Human Resource | Recruitment & Talent Acquisition"
    },
    {
        "label": "Human Resource | Training & Development",
        "value": "Human Resource | Training & Development"
    },
    {
        "label": "Humanitarian Assistance and Disaster Response",
        "value": "Humanitarian Assistance and Disaster Response"
    },
    {
        "label": "ICT Operations",
        "value": "ICT Operations"
    },
    {
        "label": "IT Infrastructure and Operations",
        "value": "IT Infrastructure and Operations"
    },
    {
        "label": "Insurance Product Development",
        "value": "Insurance Product Development"
    },
    {
        "label": "Insurance Strategy and Operations",
        "value": "Insurance Strategy and Operations"
    },
    {
        "label": "Investment Banking",
        "value": "Investment Banking"
    },
    {
        "label": "Legal and Compliance (General)",
        "value": "Legal and Compliance (General)"
    },
    {
        "label": "Legal and Compliance | Compliance",
        "value": "Legal and Compliance | Compliance"
    },
    {
        "label": "Legal and Compliance | Legal",
        "value": "Legal and Compliance | Legal"
    },
    {
        "label": "Livestock and Animal Husbandry",
        "value": "Livestock and Animal Husbandry"
    },
    {
        "label": "Market Research",
        "value": "Market Research"
    },
    {
        "label": "Mining",
        "value": "Mining"
    },
    {
        "label": "Monitoring, Evaluation, Research and Learning (MERL)",
        "value": "Monitoring, Evaluation, Research and Learning (MERL)"
    },
    {
        "label": "Oil and Gas General",
        "value": "Oil and Gas General"
    },
    {
        "label": "Operations and Administration | Admin Support",
        "value": "Operations and Administration | Admin Support"
    },
    {
        "label": "Operations and Administration | Data Entry and Documentation",
        "value": "Operations and Administration | Data Entry and Documentation"
    },
    {
        "label": "Operations and Administration | Executive Assistance",
        "value": "Operations and Administration | Executive Assistance"
    },
    {
        "label": "Operations and Administration | Facilities and Space Management",
        "value": "Operations and Administration | Facilities and Space Management"
    },
    {
        "label": "Operations and Administration | Front Desk and Reception",
        "value": "Operations and Administration | Front Desk and Reception"
    },
    {
        "label": "Operations and Administration | Office Management",
        "value": "Operations and Administration | Office Management"
    },
    {
        "label": "Packaging and Containers",
        "value": "Packaging and Containers"
    },
    {
        "label": "Partnership and Stakeholder Relations Management",
        "value": "Partnership and Stakeholder Relations Management"
    },
    {
        "label": "Payment Operations",
        "value": "Payment Operations"
    },
    {
        "label": "Policy and Advocacy",
        "value": "Policy and Advocacy"
    },
    {
        "label": "Printing",
        "value": "Printing"
    },
    {
        "label": "Product Development",
        "value": "Product Development"
    },
    {
        "label": "Product Management",
        "value": "Product Management"
    },
    {
        "label": "Production Management",
        "value": "Production Management"
    },
    {
        "label": "Programs | Program Management",
        "value": "Programs | Program Management"
    },
    {
        "label": "Programs | Project Management",
        "value": "Programs | Project Management"
    },
    {
        "label": "Property Management",
        "value": "Property Management"
    },
    {
        "label": "Public Relations",
        "value": "Public Relations"
    },
    {
        "label": "Quality Assurance",
        "value": "Quality Assurance"
    },
    {
        "label": "Real Estate Procurement, Strategy & Development",
        "value": "Real Estate Procurement, Strategy & Development"
    },
    {
        "label": "Refining and Processing (Downstream)",
        "value": "Refining and Processing (Downstream)"
    },
    {
        "label": "Research & Development",
        "value": "Research & Development"
    },
    {
        "label": "Research and Academia",
        "value": "Research and Academia"
    },
    {
        "label": "Retail Banking",
        "value": "Retail Banking"
    },
    {
        "label": "Risk Management",
        "value": "Risk Management"
    },
    {
        "label": "Risk Management",
        "value": "Risk Management"
    },
    {
        "label": "Safety Health and Environmental Services",
        "value": "Safety Health and Environmental Services"
    },
    {
        "label": "Safety and Security",
        "value": "Safety and Security"
    },
    {
        "label": "Scientific Research",
        "value": "Scientific Research"
    },
    {
        "label": "Software Development",
        "value": "Software Development"
    },
    {
        "label": "Supply Chain",
        "value": "Supply Chain"
    },
    {
        "label": "Supply Chain | Logistics",
        "value": "Supply Chain | Logistics"
    },
    {
        "label": "Supply Chain | Materials Management",
        "value": "Supply Chain | Materials Management"
    },
    {
        "label": "Supply Chain | Procurement and Purchasing",
        "value": "Supply Chain | Procurement and Purchasing"
    },
    {
        "label": "Supply Chain | Warehousing",
        "value": "Supply Chain | Warehousing"
    },
    {
        "label": "Travel Agencies and Tour Operations",
        "value": "Travel Agencies and Tour Operations"
    },
    {
        "label": "Treasury",
        "value": "Treasury"
    },
    {
        "label": "Underwriting",
        "value": "Underwriting"
    },
    {
        "label": "Venue Management",
        "value": "Venue Management"
    },
    {
        "label": "Volunteer Services & Community Outreach",
        "value": "Volunteer Services & Community Outreach"
    },
    {
        "label": "Wealth Management",
        "value": "Wealth Management"
    }
]