import { ORGANIZATION_PEOPLE } from "../../../../../../configs/data/onboarding"
import { CheckboxInput } from "../../../../../../components/forms/CheckboxInput"
import { WR_COLORS } from "../../../../../../configs/colors"
import HeadCell from "../../../../../../components/forms/general"


export default function ExpTable(props) {
    const id = "contract"
    const { errors, control } = props
    const COLS = ['Expatriate on home country/international contract', "Expatriate on host country/local contract"]


    return (
        <div className="my-2">
            <table className="w-full border">
                <thead>

                    <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                        <HeadCell></HeadCell>
                        {COLS.map(smm => (
                            <HeadCell key={`th-${smm}`}>{smm}</HeadCell>
                        ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        ORGANIZATION_PEOPLE.map((item, index) => {
                            return (<tr key={`medical_cover-eligibility-${index}`}>
                                <td className="px-2 border-b border-r">{item}</td>
                                {COLS.map(smm => (
                                    <td key={`radio-cmse-${index}-${smm}`} className="px-2 border-b border-r">
                                        <CheckboxInput
                                            name={`${id}.${item}.${smm}`}
                                            value={smm} control={control} errors={errors} />
                                    </td>
                                ))
                                }


                            </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}