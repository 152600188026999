import React from "react";
import Chart from "react-apexcharts";
import { WR_PIE_GRAPH_COLORS } from "../../../../configs/colors";

const CountriesStats = (props) => {
  // Define the chart options and series data
  const options = {
    chart: {
      type: "pie",
      height: 300
    },
    labels: props.categories || ["Retail & Wholesale", "Insurance/Reinsurance"],
    legend: {
      position: "bottom",
      offsetY: 0,
    },
    colors: WR_PIE_GRAPH_COLORS,
    
  };

  const series = props.series;

  // Render the chart component
  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type="pie"
        height={400}
      />
    </div>
  );
};

export default CountriesStats;
