import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import BarChart from "../../Components/BarChart";
import { getFooter, getNote } from "../utils";

export default function PensionProvident(props) {
  const { offering, early_retirement_option_plan } = props.data;
  return (
    <div>
      <BlockHeading text="2. Pension/ Provident Fund" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Pension/Provident Fund Offering"}
          subtitle={"Does your organisation provide a pension plan"}
          footer={getFooter(offering?.total)}
          note={getNote()}
        >
          <PieChart
            categories={offering.categories}
            series={offering.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Early Retirement Option Plan"}
          subtitle={
            "Does organisation offer an early retirement option plan for employees when compared to statutory retirement guidelines"
          }
          footer={getFooter(early_retirement_option_plan?.total)}
          note={getNote()}
        >
          <PieChart
            categories={early_retirement_option_plan.categories}
            series={early_retirement_option_plan.data}
            height="250"
          />
        </BCard>
      </div>
      <div className="my-2">
        <TableChart data={props.data?.eligibility} />
      </div>
    </div>
  );
}


function TableChart(props) {
  if (!props.data) {
    return null;
  }


  const { employee_contribution, employer_contribution, gratuity } = props.data;

  return (
    <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
      <BCard
        subtitle={
          "Does organisation offer an early retirement option plan for employees when compared to statutory retirement guidelines"
        }
        footer={getFooter(3)}
        note={getNote()}
      >
        <BarChart categories={employee_contribution?.categories} series={employee_contribution?.data} height="auto" stacked={true} />
      </BCard>
      <BCard
      title={"Employer Contribution"}
        subtitle={"Does your organisation provide a pension plan"}
        footer={getFooter(3)}
        note={getNote()}
      >
        <BarChart categories={employer_contribution?.categories} series={employer_contribution?.data} height="auto" stacked={true} />
      </BCard>
        

    </div>
    <BCard
    title={"Gratuity"}
        subtitle={
          "Does organisation offer an early retirement option plan for employees when compared to statutory retirement guidelines"
        }
        footer={getFooter(3)}
        note={getNote()}
      >
        <BarChart categories={gratuity?.categories} series={gratuity?.data} height="auto" stacked={true} percentage={true} />
      </BCard>
    </div>
  )


}