import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import BarChart from "../../Components/BarChart";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function MedicalCover(props) {
  const { offering, funded, insurance_emergency_evacuation, insurance_payer, region } = props.data;
  return (
    <div>
      <BlockHeading text="1. Medical Cover" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Medical cover offered"}
          subtitle={"Does your organisation offer medical cover?"}
          footer={getFooter(offering?.total)}
          note={getNote()}
        >
          <PieChart
            categories={offering.categories}
            series={offering.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Medical cover administered"}
          subtitle={"How is medical cover administered"}
          footer={getFooter(insurance_payer?.total)}
          note={getNote()}
        >
          <BarChart
            categories={insurance_payer?.categories}
            series={[{ name: "Data", data: insurance_payer?.data }]}
            height="80"
            percentage={true}
          />
        </BCard>
        <BCard
          title={"Medical cover funded"}
          subtitle={"How is medical cover funded"}
          footer={getFooter(funded?.total)}
          note={getNote()}
        >
          <BarChart
            categories={funded?.categories}
            series={[{ name: "Fund type", data: funded?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={"Region of Cover"}
          subtitle={"What region does the medical cover cover?"}
          footer={getFooter(region?.total)}
          note={getNote()}
        >
          <BarChart
            categories={region?.categories}
            series={[{ name: "Fund type", data: region?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={"Emergency evacuation"}
          subtitle={"Does your medical insurance/medical cover include emergency evacuation"}
          footer={getFooter(insurance_emergency_evacuation?.total)}
          note={getNote()}
        >
          <BarChart
            categories={insurance_emergency_evacuation?.categories}
            series={[{ name: "Fund type", data: insurance_emergency_evacuation?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
      </div>
    </div>
  );
}
