import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import TableView from "../../Components/TableView";
import { getFooter, getNote } from "../utils";

export default function AttractionAndRetentionPractices(props) {
  console.log(props.data);
  const { difficulty_hiring, difficult_to_hire, difficult_to_retain } =
    props.data;
  return (
    <div>
      <BlockHeading text="2. Attraction And Retention Practices" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          // title="Does your organisation have difficulty hiring or retaining employees in certain roles?"
          title="Difficulty In Hiring or Retaining Employees"
          footer={getFooter(difficulty_hiring?.total)}
          note={getNote()}
        >
          <PieChart
            categories={difficulty_hiring?.categories}
            series={difficulty_hiring?.data}
            height="200px"
          />
        </BCard>
        <BCard
          title="Most Difficult Roles To Fill In Organisation"
          // title="From which job family are the roles most difficult to fill in your organisation"
          footer={getFooter(difficult_to_hire?.total)}
          note="Only the most prevalent choices are shown"
        >
          <TableView
            columns={difficult_to_hire?.columns}
            rows={difficult_to_hire?.rows}
          />
        </BCard>
      </div>
      <BCard
        title="Most Difficult Roles To Retain In Organisation"
        // title="From which job family are the roles most difficult to retain in your organisation"
        footer={getFooter(difficult_to_retain?.total)}
        note="Only the most prevalent choices are shown"
      >
        <TableView
          columns={difficult_to_retain?.columns}
          rows={difficult_to_retain?.rows}
        />
      </BCard>
    </div>
  );
}
