import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import OrganizationInformation from "./CompanyInfo/OrganizationInformation";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyInfoData } from "../../../redux/onboard/company_info";
import ContactInformation from "./CompanyInfo/ContactInformation";
import Demography from "./CompanyInfo/Demography";
import PayInformation from "./CompanyInfo/PayInformation";


function CompanyDemographic(props) {

    const company_info = useSelector(state => state.company_info)
    const { data } = company_info
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm({
        defaultValues: data
    });
    const dispatch = useDispatch()

    useEffect(() => {
        reset(data)
    }, [])

    const onSubmit = (form_data) => {

        dispatch(setCompanyInfoData(form_data))
        props.onNext("benefits")
        props.onSaveClick("company_info", null, form_data)
    }

    const onError = (error_data) => {
        console.log(error_data)
    }


    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>

            <OrganizationInformation control={control} watch={watch} register={register} errors={errors} />
            <ContactInformation control={control} watch={watch} register={register} errors={errors} setValue={setValue} />
            <Demography control={control} watch={watch} register={register} errors={errors} />
            <PayInformation control={control} watch={watch} register={register} errors={errors} />

            <div className="flex justify-end mt-5 px-2 py-2">
                <button type="submit" className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    );
}

export default CompanyDemographic;
