import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import TableView from "../../Components/TableView";
import { getFooter, getNote } from "../utils";

export default function CompensationStructure(props) {
  console.log(props.data);
  const {
    job_evaluation,
    organization_target_market_pay,
    pay_currency,
    salary_adjustments,
    salary_structure,
    use_spreadsheet,
  } = props.data;
  return (
    <div>
      <BlockHeading text="1. Compensation Structure" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Job evaluation conducted"}
          footer={getFooter(job_evaluation?.total)}
          note={getNote()}
        >
          <PieChart
            categories={job_evaluation.categories}
            series={job_evaluation.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Salary structure in place"}
          footer={getFooter(salary_structure?.total)}
          note={getNote()}
        >
          <PieChart
            categories={salary_structure.categories}
            series={salary_structure.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Job evaluation conducted"}
          footer={getFooter(job_evaluation?.total)}
          note={getNote()}
        >
          <PieChart
            categories={job_evaluation.categories}
            series={job_evaluation.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Use of spreadsheet"}
          footer={getFooter(use_spreadsheet?.total)}
          note={getNote()}
        >
          <PieChart
            categories={use_spreadsheet.categories}
            series={use_spreadsheet.data}
            height="250"
          />
        </BCard>
      </div>
      <div className="my-4">
        <BCard
          title="Which figure does your organisation use to benchmark remuneration?"
          footer={getFooter(organization_target_market_pay?.total)}
          note={`The total does not equal 100% due to rounding.`}
        >
          <TableView
            columns={organization_target_market_pay.columns}
            rows={organization_target_market_pay.rows}
          />
        </BCard>
      </div>
      <div className="my-4">
        <BCard
          title="Pay currency"
          footer={getFooter(pay_currency?.total)}
          note={`The total does not equal 100% due to rounding.`}
        >
          <TableView columns={pay_currency.columns} rows={pay_currency.rows} />
        </BCard>
      </div>
    </div>
  );
}
