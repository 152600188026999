import BlockHeading from "../../../../../../components/forms/BlockHeading";
import Label from "../../../../../../components/forms/InputLabel";
import { ORGANIZATION_PEOPLE_OBJ } from "../../../../../../configs/data/onboarding";
import MUITextInput from "../../../../../../components/forms/MUITextInput";
import SelectAutocomplete from "../../../../../../components/forms/SelectAutocomplete";


export default function Overtime(props) {
    const id = 'overtime'

    const { watch, errors, control } = props


    return (
        <div>
            <BlockHeading text="4. OVERTIME" />

            <div className="my-2">
                
                <div className="my-2">
                    <Label className="mb-2" htmlFor="benefits-1_attraction_retention-top3"
                        text="Which levels are entitled to overtime?" />
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2"> */}

                        <div>
                            <SelectAutocomplete
                                required
                                name={`${id}.levels_org`}
                                errors={errors} watch={watch}
                                options={ORGANIZATION_PEOPLE_OBJ} control={control} />
                        </div>
                        {/* <div>
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.levels_jobs_family`}
                                errors={errors} watch={watch}
                                options={JOB_FAMILIES} control={control} />
                        </div> */}
                    {/* </div> */}
                </div>
                <div className="my-2">
                    <Label className="mb-2" htmlFor="benefits-1_attraction_retention-top3"
                        text="Which Levels are entitled to 'time off in Lieu' for working beyond official work hours/day?" />
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2"> */}

                        <div>
                            <SelectAutocomplete
                                required
                                name={`${id}.timeof_in_lieu_org`}
                                errors={errors} watch={watch}
                                options={ORGANIZATION_PEOPLE_OBJ} control={control} />
                        </div>
                        {/* <div>
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.timeof_in_lieu_jobs_family`}
                                errors={errors} watch={watch}
                                options={JOB_FAMILIES} control={control} />
                        </div>
                    </div> */}
                </div>
                {/* if timeof_in_lieu_org is None, hide it */}
                { watch(`${id}.timeof_in_lieu_org`) !== "None" &&
                <div className="my-2">
                    <Label className="mb-2" htmlFor="htf_skills_strategies"
                        text={`Up to how many days of 'time off in lieu' are provided?`} />
                    <MUITextInput control={control} type="number" errors={errors}
                     name={`${id}.lieu_days`} required />
                </div>
}
            </div>

        </div>
    )
}