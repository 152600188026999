import Tooltip from "../../../../../../components/Tooltip"
import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { WR_COLORS } from "../../../../../../configs/colors"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import generator from "../../utils/generators"

const PERCENTAGE_100 = [
    { label: "Not Applicable", value: "Not Applicable" },
    ...generator(100),
    { label: "100%+", value: '100%+' }
]
const MONTHS = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" }
];

export default function STMVariableIncentives(props) {
    const id = 'short_term_incentives'
    const { watch, errors, control, register } = props

    return (
        <div>
            <BlockHeading text="1. Short-term variable incentives" />
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2"> */}
            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer short-term incentives (bonus)? " />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div>
                    <div>
                        <Label className="mb-2"
                            htmlFor="annual_stm_incentives"
                            text="When do annual short term incentives (bonus) payments take place?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.annual_stm_incentives`}
                            errors={errors} watch={watch}
                            options={[...MONTHS, { label: "We do not pay short term incentives", label: "We do not pay short term incentives" }]} control={control} />
                    </div>

                    <div className="my-2">
                        <Label className="mb-2" htmlFor="payment_differentiation_bonus"
                            text="Is there bonus payment differentiation (based on grade, individual performance or team performance?)" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.payment_differentiation_bonus`}
                            errors={errors} watch={watch}
                            options={YES_NO} control={control} />
                    </div>
                    {/* </div> */}
                    <div><table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                <td className="px-2 py-2 border-b border-r">Eligible levels</td>
                                <td className="px-2 border-b border-r">Target variable bonus (% of annual basic salary)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PEOPLE.map((item, index) => {
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>


                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle
                                                required
                                                name={`${id}.payment_differentiation_bonus_offering.${item}`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                border={false}
                                                options={PERCENTAGE_100} control={control} /></td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table></div>
                </div>
            }
            <div className="my-2">
                <div className="flex">
                    <Label className="mb-2" htmlFor="division_of_profits_bonus"
                        text="Does your organisation offer 'division of profits' bonus plan?" />
                    <Tooltip text="Division of profits bonus plan is an incentive program that rewards employees based on the financial performance of specific divisions or units within an organization" faq={true} />

                </div>
                <SelectAutocompleteSingle
                    required
                    name={`${id}.division_of_profits_bonus`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {watch(`${id}.division_of_profits_bonus`) == "Yes" &&
                <div><table className="w-full border">
                    <thead>
                        <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                            <td className="px-2 py-2 border-b border-r">Eligible levels</td>
                            <td className="px-2 border-b border-r">Target variable bonus (% of annual basic salary)</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ORGANIZATION_PEOPLE.map((item, index) => {
                                return (<tr key={`medical_cover-eligibility-${index}`}>
                                    <td className="px-2 border-b border-r">{item}</td>


                                    <td className="border-b border-r">
                                        <SelectAutocompleteSingle
                                            name={`${id}.division_of_profits_bonus_offering.${item}`}
                                            required
                                            register={register}
                                            errors={errors} watch={watch}
                                            border={false}
                                            options={[
                                                { label: "Not Applicable", value: "Not Applicable" },
                                                ...PERCENTAGE_100
                                            ]} control={control} /></td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table></div>
            }
        </div>
    )
}