import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import { num_generator } from "../../utils/generators"
import { WR_COLORS } from "../../../../../../configs/colors"

const FUNERAL_COVER_OFFERING = [
    { label: "Yes, via existing organisation insurance cover", value: "Yes, via existing organisation insurance cover" },
    { label: "Yes, self-funded by organisation", value: "Yes, self-funded by organisation" },
    { label: "Yes, employee led contribution only", value: "Yes, employee led contribution only" },
    { label: "No", value: "No" }
]

const FUNERAL_COVER_ELIGIBILITY = [
    {label: "Employee only", value: "Employee only"},
    {label: "Employee and spouse", value: "Employee and spouse"},
    {label: "Employee, spouse and children", value: "Employee, spouse and children"},
]

export default function LifeInsurance(props) {
    const id = "life_insurance"
    const { watch, errors, control, register } = props
    return (
        <div>
            <BlockHeading text="3. LIFE INSURANCE" />
            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer life insurance cover?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    {/* <Label className="mb-2"
                        text="Who is eligible?" /> */}

                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head_main }}>
                                <td className="px-2 py-2 border-b border-r">Eligible Levels</td>
                                <td className="px-2 border-b border-r">Life Insurance</td>
                                <td className="px-2 border-b border-r">Group Personal Accident</td>
                            </tr>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                <td className="px-2 border-b border-r"></td>
                                <td className="px-2 py-2 border-b border-r">Value of cover (no. of years x annual basic salary)</td>
                                <td className="px-2 border-b border-r">(no. of yrs x annual basic salary)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PEOPLE.map((item, index) => {
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        {["Value of cover", "No of yrs basic sallary"].map((check_item, check_index) => {
                                            return (
                                                <td className="border-b border-r">
                                                    <SelectAutocompleteSingle
                                                        required
                                                        border={false}
                                                        name={`${id}.eligibility.${item}.${check_item}`}
                                                        errors={errors} watch={watch}
                                                        options={num_generator(10, true)} control={control} />
                                                </td>

                                            )
                                        })

                                        }
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
            <div className="my-2">
                <Label className="mb-2" htmlFor="medical_cover-funeral-cover"
                    text="Does your organisation offer a funeral cover?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.funeral_cover`}
                    errors={errors} watch={watch}
                    options={FUNERAL_COVER_OFFERING} control={control} />
            </div>
            { !["No", undefined, null].includes(watch(`${id}.funeral_cover`)) &&
            <div className="my-2">
                <Label className="mb-2" htmlFor="medical_cover-funeral-cover-eligibility"
                    text="Who is eligible for funeral cover?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.funeral_cover_eligibility`}
                    errors={errors} watch={watch}
                    options={FUNERAL_COVER_ELIGIBILITY} control={control} />
            </div>
            }
        </div>
    )
}