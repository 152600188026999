import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';

const faqs = [
  {
    id: 'panel1',
    question: "How do I update my company's compensation data in Workrate?",
    answer: <Box>
      There are two ways to update your organisation's compensation data: <br />
      <ol>
        <li>
          <p>
          1. During onboarding before final submission, you can update and edit any previous inputs. Navigate the various onboarding subsections (Company Info, Benefits and Compensation Data). From there, you can upload a new dataset or manually edit existing entries. Ensure your data is in the supported format and make the required changes before final submission.
          </p>
          <img src='https://storage.googleapis.com/cdn-theworkrate-com/images/faq/Stepper.png' alt='Onboarding initial' />
        </li>
        <li>
        <p>2. At the beginning of every quarter during a one-week window, there will be an opportunity to update your compensation and benefits inputs. Select ‘Past Survey Responses’ from the navigation menu. From here, all your previous onboarding responses will be prepopulated so you will not be required to re-submit all the data from scratch. Simply update the relevant section as required and submit. 
        </p>
        <img src='https://storage.googleapis.com/cdn-theworkrate-com/images/faq/CompanyInfo.png' alt='Onboarding update' />
        </li>
      </ol>
    </Box>
  },
  {
    id: 'panel2',
    question: "What should I do if I encounter an error while uploading data?",
    answer: <Box>
      <p>If you encounter an error during data upload, first check the format of your file against our guidelines. After uploading the compensation data file, the system will autogenerate the inputs and highlight any errors in red font. Any fields with red font should be corrected and the whole file should be re-uploaded. Make sure there are no blank spaces in mandatory fields as this can generate errors too.
      </p>
      <img src='https://storage.googleapis.com/cdn-theworkrate-com/images/faq/UploadError.png' alt='Upload error' />
      <p>
      If the issue persists, please contact our support team (<a href='mailto:clients@theworkrate.com'>clients@theworkrate.com</a>) with a detailed description of the problem and a screenshot of any error messages you received.
      </p>
    </Box>
  },
  {
    id: 'panel3',
    question: "Can you explain what 'market percentile' means?",
    answer: "Market percentile refers to the position of a compensation value within the market data distribution. For example, a salary in the 75th percentile means it is higher than 75% of the comparison set, indicating it is above average (median) within the market. Note that since percentiles are a statistical measure, percentiles are relative to the data pool being measured."
  },
  {
    id: 'panel4',
    question: "I'm new to benchmarking. How do I start with Workrate?",
    answer: ": Begin with our benchmarking tutorial available in the 'Help' section. It guides you through selecting job titles, matching them to our database, and comparing your compensation structures against market data. For further assistance, don't hesitate to contact our customer success team."
  },
  // {
  //   id: 'panel5',
  //   question: "How do I interpret the salary structure floating bar graph?",
  //   answer: "The salary structure floating bar graph visualizes the range of salaries for a particular role, from the minimum to the maximum, with markers for the median and quartiles. This helps you understand how your organization's pay for a role compares to the broader market."
  // },
  {
    id: 'panel6',
    question: "What if the job titles in my organization don't match the ones in Workrate?",
    answer: "Job matching is based on job content and not on job titles. Workrate job levels and their corresponding job titles will be the baseline job architecture for all organisations using the platform. However, each organisation will be able to input their own job title into the system allowing them to see their job title as well as the equivalent Workrate job title."
  },
  {
    id: 'panel7',
    question: "How can I share reports with my team?",
    answer: <Box>
      <p>Under "Talent" in the navigation menu, you will be able to export your organisation's data. 
The export button can be found under the "Org. Level" tab as shown below.
</p>
<img src='https://storage.googleapis.com/cdn-theworkrate-com/images/faq/Filters.png' alt='Export' />
    </Box>
  },
  {
    id: 'panel8',
    question: "Why does the compensation data vary between updates?",
    answer: ":Each organisation will be able to update their data on a quarterly basis. Additionally, there may be changes in the organisations that collectively make up the market data. These movements may result in changes to the compensation data. Our goal is to provide the most current and accurate data to reflect these changes."
  },
  {
    id: 'panel9',
    question: "How do I add or remove users from our Workrate account?",
    answer: "At this time, there is only one user profile available. This means there will only be one user per organisation with access to the Workrate. Removing the user will be automatic if the subscription is cancelled."
  },
  {
    id: 'panel10',
    question: "How do I cancel my Workrate subscription?",
    answer: "Towards the end of your annual subscription, you will be sent a reminder notification. Should you elect not to renew, the subscription will end without further action from you."
  }

];

export default function FaqAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {faqs.map((faq) => (
        <Accordion key={faq.id} expanded={expanded === faq.id} onChange={handleChange(faq.id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${faq.id}-content`}
            id={`${faq.id}-header`}
          >
            <Typography sx={{ width: '66%', flexShrink: 0 }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
