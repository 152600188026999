import LinePattern from '../../../assets/images/authentication/LinePattern.png';
import Mockup from '../../../assets/images/authentication/Mockup-plain.png';
import Logomark from '../../../assets/images/main/Logomark.png';

import WorkRateLogo from '../../../assets/main/workrate_white_bluedot.png';
export default function AuthLayout({ children }) {
    const FullYear = new Date().getFullYear();
    return (
        <div className="flex h-screen">
            {/* Left Side - Login Form */}
            <div className="w-1/2 bg-white py-10 lg:px-40 md:px-20 flex flex-col justify-between">

                <div className="my-5">
                    <img src={Logomark} alt="Logo" className="w-8 h-8" />
                </div>


                {children}

                <div className="flex justify-between">
                    <div>&copy; {FullYear}</div>
                    <div>
                    Question? Contact: <a href="mailto:clients@theworkrate.com" className='text-blue-500 text-md'>clients@theworkrate.com</a>
                    </div>
                </div>

            </div>
            {/* Right Side - Slider */}
            <div className="w-1/2" style={{ backgroundColor: '#8025EE' }}>
                {/* Place your slider content here */}
                <img src={LinePattern} style={{ height: '40vh', position: 'absolute', right: 0 }} alt="Lines" />
                <img src={LinePattern} style={{ height: '30vh', position: 'absolute', bottom: 0 }} alt="Lines" />
                <div className="flex flex-col justify-center h-screen">
                    <img src={Mockup} className="mx-auto" alt="Charts" style={{ width: '450px', zIndex: 9999 }} />
                    <div className="text-center text-white text-xl mt-5">
                        {/* Welcome to your new dashboard */}
                        <img src={WorkRateLogo} alt="Logo" className="w-80 mx-auto" />
                    </div>
                    {/* <div className="text-center text-white text-sm pt-2 mb-20">
                        Sign In to explore changes we made
                    </div> */}
                </div>

            </div>
        </div>
    )
}
