import React, { useState, useEffect } from "react";
import IconButton from "../../../utilities/IconButton";
import NotificationIcon from '../../../../assets/icons/notification.svg'
import SettingIcon from '../../../../assets/icons/setting.svg'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import Avatar from "../../../utilities/Avatar";
import ReactCountryFlag from "react-country-flag"
import { Link } from "react-router-dom";
import { CURRENCY_CODE_SYMBOLS } from "../../../../configs/data/onboarding";
import { Tooltip } from "@mui/material";

const Navbar = (props) => {
  const user = useSelector(state => state.authentication)

  const { i18n, t } = useTranslation();

  const { sidebarOpen, setSidebarOpen } = props

  const [currentLang, setCurrentLang] = useState('en')
  const onChangeLanguage = (e) => {
    localStorage.setItem("lang", e.target.value)
    setCurrentLang(e.target.value)
    i18n.changeLanguage(e.target.value);

  }

  useEffect(() => {
    if (localStorage.getItem("lang")) {
      setCurrentLang(localStorage.getItem("lang"))
      i18n.changeLanguage(localStorage.getItem("lang"))
    }
  }, [])
  return (
    <nav
      className="bg-white fixed top-0 p-4 flex justify-between z-50"

      style={{ width: sidebarOpen ? 'calc(100% - 300px)' : '100%', borderBottom: '1px solid #eee', transition: 'all 0.3s ease-in-out' }}
    >
      <div className="flex justify-start">
        {/* Div with h4 on the left */}
        <div className="py-1 flex">
          <button className="mr-3" onClick={() => setSidebarOpen(!sidebarOpen)}>
            {sidebarOpen ?

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>


            }
          </button>
          <h4 className="text-2xl font-semibold">{t("hello")} <span className="text-blue-500">{user.name}</span></h4>
        </div>
      </div>

      <div className="flex justify-end">
        {/* Country icon with name */}
        { user.currency && user.role !== "admin" &&
          <div className="text-md mr-4 pt-1 flex">
            <Tooltip title={CURRENCY_CODE_SYMBOLS[user.currency]} arrow>
            <span className="rounded border cursor-pointer px-1 pt-1">{user.currency}</span>
            </Tooltip>
          </div>
        }
        {user.country && user.role !== "admin" &&
          <div className="text-md mr-4 pt-1 flex">
            <ReactCountryFlag
            className="mt-2 rounded"
              countryCode={user.country?.code}
              svg
              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
              cdnSuffix="svg"
              title="US"
            /> 
            <div className="mt-1 ml-2">{user.country?.name}</div>
          </div>
        }

        {/* Dropdown for selection */}
        <select className="border rounded px-4 py-2 pr-4" value={currentLang} onChange={onChangeLanguage}>
          <option value="en">English</option>
          <option value="fr">French</option>
          <option value="pt">Portuguese</option>
        </select>

        {/* Notifications icon */}
        <div className="ml-4">
          <IconButton icon={NotificationIcon} onClick={() => console.log("click")} />

        </div>

        {/* Settings icon */}
        <div className="text-white mx-2">
          <IconButton icon={SettingIcon} onClick={() => console.log("click")} />
        </div>

        {/* User profile pic */}
        <div className="ml-2">
          {/* Add user profile picture here */}
          {/* <img
            src="https://placekitten.com/40/40"
            alt="User Profile"
            className="rounded-full w-10 h-10"
          /> */}
          <Link to="/profile">
          <Avatar name={user.name} size="w-10 h-10" />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
