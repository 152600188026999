import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";

export default function LifeInsurance(props) {
  const {
    offering,
    funeral_cover_offering,
    funeral_cover_eligibility,
    eligibility,
  } = props.data;
  const { value_of_cover, no_of_years_basic_salary } = eligibility;
  console.log("data in life insurance", props.data);
  return (
    <div>
      <BlockHeading text="3. Life Insurance" />
      <div className="my-2">
        <BCard
          title={"Life insurance offered"}
          subtitle={"Does your organisation offer life insurance?"}
          footer={`Based on responses from ${offering.total} organisations`}
          note="Some organisations indicated more than one option therefore the total exceeds 100%"
        >
          <PieChart
            categories={offering.categories}
            series={offering.data}
            height="250"
          />
        </BCard>
      </div>
      <div className="my-2">
        <BCard
          title={"Life Insurance"}
          subtitle={"Value of cover (number of years basic salary)"}
          footer={`Based on responses from ${value_of_cover?.total} organisations`}
          note="Some organisations indicated more than one option therefore the total exceeds 100%"
        >
          <BarChart
            stacked={true}
            categories={value_of_cover?.categories}
            series={value_of_cover?.data}
            height="250"
          />
        </BCard>
      </div>
      <div className="my-2">
        <BCard
          title={"Group personal accident"}
          subtitle={"Value of cover (number of years basic salary)"}
          footer={`Based on responses from ${no_of_years_basic_salary?.total} organisations`}
          note="Some organisations indicated more than one option therefore the total exceeds 100%"
        >
          <BarChart
            stacked={true}
            categories={no_of_years_basic_salary?.categories}
            series={no_of_years_basic_salary?.data}
            height="250"
          />
        </BCard>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Funeral cover offered"}
          subtitle={"Does your organisation offer funeral cover?"}
          footer={`Based on responses from ${funeral_cover_offering?.total} organisations`}
          note="Some organisations indicated more than one option therefore the total exceeds 100%"
        >
          <PieChart
            categories={funeral_cover_offering?.categories}
            series={funeral_cover_offering?.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Funeral cover eligibility"}
          subtitle={"What is the eligibility criteria for funeral cover?"}
          footer={`Based on responses from ${funeral_cover_eligibility?.total} organisations`}
          note="Some organisations indicated more than one option therefore the total exceeds 100%"
        >
          <PieChart
            categories={funeral_cover_eligibility?.categories}
            series={funeral_cover_eligibility?.data}
            height="250"
          />
        </BCard>
      </div>
    </div>
  );
}
