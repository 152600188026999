import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import EmployeeDemography from "./EmployeeDemography";
import { setHrPoliciesData } from "../../../../../../redux/onboard/benefits/hr_policies";
import TrainingDevelopmentPolicies from "./TrainingDevelopmentPolicies";
import AttractionRetentionPractices from "./AttractionRetentionPractices";
import WorkEnvironment from "./WorkEnvironment";
import Leave from "./Leave";
import Overtime from "./Overtime";
import { ToastContainer, toast } from 'react-toastify';



export default function HRPolicies(props) {
    const hr_policies = useSelector(state => state.hr_policies)
    const { data } = hr_policies
    const { register, handleSubmit, watch, reset, control, setValue,
        formState: { errors, isDirty },
    } = useForm();
    const dispatch = useDispatch()

    useEffect(() => {
        reset(data)
    }, [])

    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])

    const onSubmit = (form_data) => {
        if (isDirty){
            dispatch(setHrPoliciesData(form_data))
            props.onSaveClick("benefits", "hr_policies", form_data)
        }
        props.changeTab(1)
    };

    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ToastContainer />
            <EmployeeDemography control={control} watch={watch} register={register} errors={errors} setValue={setValue} />
            <TrainingDevelopmentPolicies control={control} watch={watch} register={register} errors={errors} />
            <AttractionRetentionPractices control={control} watch={watch} register={register} errors={errors} />
            <Overtime control={control} watch={watch} register={register} errors={errors} />
            <WorkEnvironment control={control} watch={watch} register={register} errors={errors} />
            <Leave control={control} watch={watch} register={register} errors={errors} />
            {/* 

            

            
             */}
            <div className="flex justify-end mt-5 px-2 py-2">
                <button type="submit" className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}