import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import { num_generator } from "../../utils/generators"
import { WR_COLORS } from "../../../../../../configs/colors"
import BlankRowCell from "../../../../../../components/forms/BlankRowCell"


const MEDICAL_FUND_DROPDOWN = [
    { label: "Yes, we offer an optional private pension scheme", value: "Yes, we offer an optional private pension scheme" },
    { label: "Yes, we offer a mandatory private pension scheme", value: "Yes, we offer a mandatory private pension scheme" },
    { label: "No, we only offer the statutory social security or pension scheme", value: "No, we only offer the statutory social security or pension scheme" }
]


export default function PensionProvidentFund(props) {
    const id = "provident_fund"
    const { watch, errors, control, register } = props

    // if any of these values '"employee_contribution", "employer_contribution", "gratuity"' for "All Employees" is selected, then don't render SelectAutocompleteSingle for other rows
    const All_EMPLOYEES = "All Employees"
    const TABLE_INPUT_COLS = ["employee_contribution", "employer_contribution", "gratuity"]

    const checkDisable = (item) => {
        const ccid = All_EMPLOYEES
        if (![undefined, null].includes(watch(`${id}.eligibility.${ccid}.employee_contribution`)) &&
            `${id}.eligibility.${ccid}.employee_contribution` !== `${id}.eligibility.${item}.employee_contribution`) {
            return true
        } else if (
            ![undefined, null].includes(watch(`${id}.eligibility.Head of Organisation.employee_contribution`)) &&
            `${id}.organization_target_market_pay.${ccid}` === `${id}.organization_target_market_pay.${item}`
        ) {
            return true
        }
    }
    return (
        <div>
            <BlockHeading text="2. PENSION/ PROVIDENT FUND" />
            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation provide a pension plan?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={MEDICAL_FUND_DROPDOWN} control={control} />
            </div>

            { ['Yes, we offer an optional private pension scheme', 'Yes, we offer a mandatory private pension scheme'].includes(watch(`${id}.offering`)) &&
                <div className="my-2">
                    {/* <Label className="mb-2"
                        text="Who is eligible?" /> */}

                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                <td className="px-2 border-b border-r">Eligible Levels</td>
                                <td className="px-2 py-2 border-b border-r">% Employee contribution p.m.</td>
                                <td className="px-2 border-b border-r">% Employer contribution p.m.</td>
                                <td className="px-2 border-b border-r">Gratuity (% salary)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                [
                                    "All Employees",
                                    ...ORGANIZATION_PEOPLE
                                ].map((item, index) => {
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        {["employee_contribution", "employer_contribution", "gratuity"].map((check_item, check_index) => {
                                            return (
                                                checkDisable(item) ?
                                                <BlankRowCell key={`medical_cover-eligibility-${index}-${check_index}`} style={{ width: '20%' }} />
                                                :
                                                <td className="border-b border-r">
                                                    
                                                        <SelectAutocompleteSingle
                                                            required={!checkDisable(item)}
                                                            border={false}
                                                            name={`${id}.eligibility.${item}.${check_item}`}
                                                            errors={errors} watch={watch}
                                                            options={[
                                                                {label: "Not Applicable", value: "Not Applicable"},
                                                                ...num_generator(100, false)
                                                            ]} control={control} />
                                                        
                                                </td>

                                            )
                                        })

                                        }
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer an early retirement option plan for employees when compared to statutory retirement guidelines?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.early_retirement_option_plan`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2"> */}


            {/* </div> */}

        </div>
    )
}