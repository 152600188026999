import React, { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import './styles.css';
import { useSelector, useDispatch } from "react-redux";
import { setAppData } from "../../../redux/app_data";
import CompanySubmitted from '../../../assets/images/main/switch-connect.png'

function DashboardLayout({ children, background }) {
    //const [sidebarOpen, setSidebarOpen] = useState(true);

    const appData = useSelector(state => state.app_data)
    const user = useSelector(state => state.authentication)

    const APPROVAL_REQUIRED_ROUTES = [
        "/talent", "/market-data", "/benefits", "/dashboard"
    ]
    const ONLY_SUBMISSION_REQUIRED_ROUTES = [
        "/past-survey-responses"
    ]

    const CURRENT_ROUTE = window.location.pathname

    const sidebarOpen = appData.sidebar_open
    const dispatch = useDispatch()

    const setSidebarOpen = (value) => {
        dispatch(setAppData({ key: 'sidebar_open', value: !sidebarOpen }))
    }

    const mainStyle = {
        width: sidebarOpen ? 'calc(100% - 300px)' : '100%',
        marginLeft: sidebarOpen ? '300px' : '0px',
        transition: 'all 0.3s ease-in-out' // This ensures a smooth transition for the main content area.
    };

    const bg_class = background ? 'p-0' : 'p-2'
    const styles = {
        backgroundColor: background ? background : 'none'
    }

    return (
        <div>
            <div className={`Csidebar ${sidebarOpen ? 'open' : 'closed'}`}>
                <Sidebar />
            </div>

            <div style={mainStyle}>
                <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className={`mt-20 p-2`} style={styles}>
                    {user?.access?.approved === false && APPROVAL_REQUIRED_ROUTES.includes(CURRENT_ROUTE) ? 
                    <div className="alert text-blue-500 text-center py-10" style={{ color: "#8025EE", fontSize: '20px' }}>
                        <img src={CompanySubmitted} alt="Company Submitted" className="mx-auto" style={{ width: '50%', marginBottom: '100px', marginTop: '50px' }} />
                        {
                            user?.access?.finished === false ?
                            <div>
                                Please complete your onboarding process and submit for approval to access this page. You will be notified once your data is approved.
                            </div>
                            :
                            <div>
                                Your company data has been submitted. Please await approval for dashboard access.
                            </div>
                        }
                    </div>
                        :
                         user.access?.finished === false && ONLY_SUBMISSION_REQUIRED_ROUTES.includes(CURRENT_ROUTE) ?
                            <div className="alert text-center py-10">
                                Please complete your onboarding process to access this page.
                            </div>
                            :
                        children
                    }
                </div>
            </div>
        </div>
    );
}

export default DashboardLayout;
