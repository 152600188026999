import AuthLayout from "../../../components/layouts/Auth";
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { API_URL } from "../../../configs/endpoints";
import { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { LoaderSVG } from "../../../components/icons/LoaderSvg";
import PasswordInput from "../forms/PasswordInput";

export default function Register() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const navigate = useNavigate()


    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null })
        axios.post(`${API_URL}/api/v1/register`, data).then(res => {

            setApiReq({ loading: false, error: null })
            navigate("/login?registration=success")
        }).catch(err => {
            if (err?.response?.status) {
                setApiReq({ loading: false, error: err.response.data.message })
            } else {
                setApiReq({ loading: false, error: 'Error processing request' })
            }
        })
    }


    return (
        <AuthLayout title="Register">

            <div className="text-center mb-8">
                <h2 className="text-2xl font-semibold mt-4">Sign Up</h2>
                <p className="text-gray-600 mt-2">Welcome! Please enter your details.</p>
            </div>
            <form className="flex-grow" onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700">Name</label>
                    <input type="name" id="name" name="name" placeholder="Enter your name" {...register("name", { required: true, maxLength: 40 })} className="w-full px-4 py-2 border rounded-sm" />
                    {errors.name && <span className="text-red-600 text-sm">This field is required</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700">Email</label>
                    <input type="email" id="email" name="email" placeholder="Enter your email" {...register('email', { required: true })} className="w-full px-4 py-2 border rounded-sm" />
                    {errors.email && <span className="text-red-600 text-sm">Correct email is required</span>}
                </div>
                {/* <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700">Password</label>
                    <input type="password" id="password" name="password" {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be at least 8 characters long',
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message:
                                    'Password must contain at least one lowercase letter, one uppercase letter, one special character, and a number',
                            },
                        })} className="w-full px-4 py-2 border rounded-sm" />
                    {errors.password && <span className="text-red-600 text-sm">{ errors.password.message }</span>}
                </div> */}
                <PasswordInput register={register} errors={errors} />

                <div className="text-red-600 text-md">{apiReq.error && apiReq.error}</div>
                

                <button type="submit" style={{ backgroundColor: '#8025EE' }} disabled={apiReq.loading} className="w-full flex justify-center bg-blue-600 text-white py-2 mt-5 rounded-lg hover:bg-blue-700">
                    {apiReq.loading ? <LoaderSVG /> : 'Get Started'}
                </button>
                <div className="text-center mt-5">Already have an account? <a href="/login" className="font-medium" style={{ color: '#8025EE' }}>Log In</a></div>
            </form>
        </AuthLayout>
    )
}
