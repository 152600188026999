import { createSlice } from '@reduxjs/toolkit'


export const compnayInfoSlice = createSlice({
    name: 'company_info',
    initialState: {
        data: {
            organization_information: {
                
            },
            contact_information: {
                
            },
            demography: {
               
            },
            pay_information: {
                
            }
        },
    },
    reducers: {
        setCompanyInfoData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setCompanyInfoData } = compnayInfoSlice.actions

export default compnayInfoSlice.reducer


/*
organization_information : {
        name: null, physical_address: null, country: null, city: null, county: null
    },
    contact_information: {
        name: null, title: null, phone: null, email: null
    },
    demography: {
        type: null, //Public or Private
        profile: null, sector: null, industry: null, company: null, in_country_offices: null, abroad_offices: null
    },
    pay_information: {
        salary_data_period: null, pay_currency: null, pay_financial_year: null
    },
    */