import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { WR_COLORS } from "../../../../../../configs/colors"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"


const PAYOUT_FREQUENCY = [
    {"label": "Semi annually", "value": "Semi annually"},
    {"label": "Annually", "value": "Annually"},
    {"label": "Every two years", "value": "Every two (2) years"},
    {"label": "Every three years", "value": "Every three (3) years"},
    {"label": "Every four years", "value": "Every four (4) years"},
    {"label": "Every five years", "value": "Every five (5) years"},
    {"label": "Over five years", "value": "Over five years"}
]


export default function LongTermIncentives(props) {

    const id = 'long_term_incentives'
    const { watch, errors, control, register } = props
    return (
        <div>
            <BlockHeading text="3. LONG TERM INCENTIVES" />
            <div>
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer long term incentive plans?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                {["Eligible levels", "Stock options eligibility", "Frequency of payout", "Direct purchase plans eligibility",
                                    "Service-based restricted shares eligibility", "Other"].map((item, index) => (
                                        <td key={`long_term_inc-${index}`} className="px-2 py-2 border-b border-r">{item}</td>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PEOPLE.map((item, index) => {
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>


                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false}
                                                name={`${id}.data.${item}.stock_options_eligibility`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={YES_NO} control={control} /></td>

                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false}
                                                name={`${id}.data.${item}.payout_frequency`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={PAYOUT_FREQUENCY} control={control} /></td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false}
                                                name={`${id}.data.${item}.direct_purchase_plans_eligibility`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={YES_NO} control={control} /></td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false}
                                                name={`${id}.data.${item}.service_based_restricted_shares_eligibility`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={YES_NO} control={control} /></td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false}
                                                name={`${id}.data.${item}.other`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={YES_NO} control={control} /></td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )
}