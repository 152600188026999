import BlockHeading from "../../../../../../components/forms/BlockHeading";
import Label from "../../../../../../components/forms/InputLabel";
import SelectAutocomplete from "../../../../../../components/forms/SelectAutocomplete";
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle";
import { JOB_FAMILIES, ORGANIZATION_PEOPLE_OBJ } from "../../../../../../configs/data/onboarding";

const HIRE_STRATEGIES = [
    { label: "Pay market premium salaries", value: "Pay market premium salaries" },
    { label: "Provide additional perks (benefits)", value: "Provide additional perks (benefits)" },
    { label: "Refine your Employee Value Proposition (EVP)", value: "Refine your Employee Value Proposition (EVP)" },
    { label: "Implement referral programs", value: "Implement referral programs" },
    { label: "Offer hybrid or remote work", value: "Offer hybrid or remote work" },
    { label: "Additional compensation (e.g. bonuses)", value: "Additional compensation (e.g. bonuses)" },
    { label: "Other", value: "Other" },
]

const HTF_SKILL_STRATEGIES = [
    { label: "Pay market premium salaries", value: "Pay market premium salaries" },
    { label: "Provide additional perks (benefits)", value: "Provide additional perks (benefits)" },
    { label: "Refine your Employee Value Proposition (EVP)", value: "Refine your Employee Value Proposition (EVP)" },
    { label: "Implement referral programs", value: "Implement referral programs" },
    { label: "Offer hybrid or remote work", value: "Offer hybrid or remote work" },
    { label: "Additional compensation (e.g. bonuses)", value: "Additional compensation (e.g. bonuses)" },
    { label: "Flexible work schedules", value: "Flexible work schedules" },
    { label: "Mentoring and leadership programmes", value: "Mentoring and leadership programmes" },
    { label: "Retention programmes", value: "Retention programmes" },
    { label: "Other", value: "Other" },
]


export default function AttractionRetentionPractices(props) {
    const id = 'attraction_retention_practices'

    const { watch, errors, control, register } = props


    return (
        <div>
            <BlockHeading text="3. Attraction and retention practices" />

            <div className="my-2">
                <Label className="mb-2"
                    text="Does your organisation have difficulty hiring or retaining employees in certain roles?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.difficulty_hiring`}
                    errors={errors} watch={watch}
                    options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                    ]} control={control} />
                {watch(`${id}.difficulty_hiring`) == "Yes" &&
                    <div>
                        <div className="my-2">
                            <Label className="mb-2" htmlFor="benefits-1_attraction_retention-top3"
                                text="a) What are the top three (3) job types that your organisation finds difficult to attract." />
                            { // array of 3 (0, 3)
                                [0, 1, 2].map((i) => (
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2" key={`difficult_to_attract_jobs_org-${i}`}>

                                        <div>
                                            <SelectAutocompleteSingle
                                                required
                                                name={`${id}.difficult_to_attract_jobs_org.${i}`}
                                                errors={errors} watch={watch}
                                                options={ORGANIZATION_PEOPLE_OBJ} control={control} />
                                        </div>
                                        <div>
                                            {watch(`${id}.difficult_to_attract_jobs_org.${i}`) !== "None" &&
                                                <SelectAutocompleteSingle
                                                    required
                                                    disabled={watch(`${id}.difficult_to_attract_jobs_org.${i}`) === "None"}
                                                    name={`${id}.difficult_to_attract_jobs_family.${i}`}
                                                    errors={errors} watch={watch}
                                                    options={JOB_FAMILIES} control={control} />
                                            }
                                        </div>
                                    </div>
                                )
                                )}
                        </div>
                        <div>
                            <Label className="mb-2" htmlFor="benefits-1_attraction_retention-strategies"
                                text={`b) What strategies do you apply to hire for "hot jobs" or scarce talent?`} />
                            <SelectAutocomplete control={control} register={register} watch={watch}
                                options={HIRE_STRATEGIES} name={`${id}.hot_jobs_hire_strategies`} />
                        </div>
                        <div className="my-2">
                            <Label className="mb-2" htmlFor="benefits-1_attraction_retention-top3"
                                text="c) What are the three job types that your organisation finds difficult to retain? (multiple selection)" />
                            {[0, 1, 2].map((i) => (
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2" key={`difficult_to_retain_jobs_org-${i}`}>

                                    <div>
                                        <SelectAutocompleteSingle
                                            required
                                            name={`${id}.difficult_to_retain_org.${i}`}
                                            errors={errors} watch={watch}
                                            options={ORGANIZATION_PEOPLE_OBJ} control={control} />
                                    </div>
                                    <div>
                                        {watch(`${id}.difficult_to_retain_org.${i}`) !== "None" &&
                                            <SelectAutocompleteSingle
                                                required
                                                disabled={watch(`${id}.difficult_to_retain_org.${i}`) === "None"}
                                                name={`${id}.difficult_to_retain_jobs_family.${i}`}
                                                errors={errors} watch={watch}
                                                options={JOB_FAMILIES} control={control} />
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="my-2">
                            <Label className="mb-2" htmlFor="htf_skills_strategies"
                                text={`What strategies do you apply to retain "hard to find" skills or rare skills? (multiple selection)`} />
                            <SelectAutocomplete control={control} register={register} watch={watch}
                                options={HTF_SKILL_STRATEGIES} name={`${id}.htf_skills_strategies`} />
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}