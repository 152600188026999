export const FinancialServices = {
    "Banking": {
        "Retail Banking": {
            "job_family_descriptor": "Retail Banking encompasses roles related to providing financial services and products to individual customers. This job family focuses on managing customer relationships, offering banking solutions, and ensuring a positive customer experience within branches and digital platforms.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Retail Banking Officer oversees the retail banking division, responsible for driving retail banking strategies, enhancing customer experiences, and optimizing branch operations to achieve business growth and profitability.",
                    "related_job_titles": "Chief Retail Banking Officer (CRBO): Chief Retail Banking Officer, Head of Retail Banking, Retail Banking Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Retail Banking leads the retail banking strategy for the organization. They focus on customer acquisition and retention, develop retail banking products, and ensure compliance with regulatory requirements.",
                    "related_job_titles": "Senior Director - Retail Banking: Retail Banking Strategy Director, Senior Retail Banking Director, Director of Retail Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Retail Banking manages retail banking operations and customer service. They collaborate with branch managers, develop sales strategies, and implement initiatives to improve customer satisfaction and financial performance.",
                    "related_job_titles": "Director - Retail Banking: Retail Operations Director, Retail Solutions Director, Chief Retail Officer (CRO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Retail Banking oversees branch operations and customer engagement. They drive sales targets, manage staff, and ensure efficient delivery of retail banking services while maintaining a customer-centric approach.",
                    "related_job_titles": "Senior Manager - Retail Banking: Senior Retail Manager, Retail Solutions Manager, Senior Retail Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Retail Banking Manager leads a branch team, ensuring exceptional customer service and sales performance. They manage branch operations, implement business development strategies, and foster a positive customer experience.",
                    "related_job_titles": "Manager - Retail Banking: Retail Operations Manager, Retail Implementation Manager, Retail Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Retail Banking supervises branch professionals. They provide leadership, support sales and service activities, and contribute to the overall success of the branch and customer satisfaction.",
                    "related_job_titles": "Team Leader (Professionals) - Retail Banking: Retail Team Lead, Retail Department Supervisor, Senior Retail Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Retail Banking Specialist provides expertise in retail banking products and services. They assist customers with complex banking needs, offer financial advice, and identify opportunities for upselling and cross-selling.",
                    "related_job_titles": "Specialist Professional - Retail Banking: Retail Expert, Retail Specialist, Customer Operations Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Retail Banking supports branch operations and customer relationships. They handle customer inquiries, assist in account management, and provide financial guidance to clients.",
                    "related_job_titles": "Senior Professional - Retail Banking: Senior Retail Analyst, Lead Customer Specialist, Principal Retail Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Retail Banking contributes to customer engagement and operational efficiency. They process transactions, promote banking services, and provide support in achieving branch goals.",
                    "related_job_titles": "Experienced Professional - Retail Banking: Retail Solutions Specialist, Customer Project Coordinator, Senior Retail Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Retail Banking Professional assists customers with their banking needs. They handle routine transactions, provide account information, and offer guidance on banking products and services.",
                    "related_job_titles": "Professional - Retail Banking: Customer Analyst, Retail Associate, Customer Operations Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Retail Banking gains exposure to customer service and branch operations. They assist in routine banking tasks, support customer interactions, and contribute to maintaining branch efficiency.",
                    "related_job_titles": "Entry Professional - Retail Banking: Junior Customer Analyst, Trainee Retail Associate, Retail Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Retail Banking provides administrative support to branch operations. They manage documentation, assist in coordinating customer communications, and ensure smooth branch functioning.",
                    "related_job_titles": "Support Associate - Retail Banking: Retail Operations Associate, Customer Support Assistant, Retail Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Retail Banking offers administrative support to the retail banking division. They manage office tasks, coordinate meetings, and provide assistance to ensure efficient branch operations",
                    "related_job_titles": "Administrative Assistant - Retail Banking: Retail Office Assistant, Customer Admin Support, Retail Coordinator"
                }
            ]
        },
        "Corporate Banking": {
            "job_family_descriptor": "Corporate Banking involves roles that cater to the financial needs of corporate clients and businesses. Professionals in this job family work closely with corporate customers to provide tailored financial solutions, including lending, cash management, trade finance, and other banking services to support their operational and growth requirements.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Corporate Banking Officer oversees the strategic direction and operations of the corporate banking division. They lead the development and execution of corporate banking strategies, focusing on client relationships, business growth, and revenue generation.",
                    "related_job_titles": "Chief Corporate Banking Officer (CCBO): Chief Corporate Banking Officer, Head of Corporate Banking, Corporate Banking Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Corporate Banking plays a pivotal role in guiding the corporate banking strategy. They lead a team in managing client relationships, assessing credit risks, and driving revenue growth through tailored financial solutions.",
                    "related_job_titles": "Senior Director - Corporate Banking: Corporate Banking Strategy Director, Senior Corporate Banking Director, Director of Corporate Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Corporate Banking leads a team of relationship managers and specialists. They collaborate with clients to understand their financial needs, recommend banking solutions, and ensure delivery of top-tier corporate banking services.",
                    "related_job_titles": "Director - Corporate Banking: Corporate Operations Director, Corporate Solutions Director, Chief Corporate Officer (CCO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Corporate Banking oversees a portfolio of corporate clients. They manage complex financial transactions, analyze credit risk, and work closely with teams to provide comprehensive banking solutions.",
                    "related_job_titles": "Senior Manager - Corporate Banking: Senior Corporate Manager, Corporate Solutions Manager, Senior Corporate Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Corporate Banking Manager handles relationships with corporate clients. They assess financial needs, propose banking products, and ensure seamless delivery of banking services to meet client objectives.",
                    "related_job_titles": "Manager - Corporate Banking: Corporate Operations Manager, Corporate Implementation Manager, Corporate Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Corporate Banking supervises a team of professionals. They provide support to relationship managers, analyze financial data, and contribute to strategic client engagement.",
                    "related_job_titles": "Team Leader (Professionals) - Corporate Banking: Corporate Team Lead, Corporate Department Supervisor, Senior Corporate Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Corporate Banking Specialist is an expert in financial solutions for corporate clients. They analyze financial statements, assess credit risk, and recommend tailored banking products to meet client needs.",
                    "related_job_titles": "Specialist Professional - Corporate Banking: Corporate Expert, Corporate Specialist, Business Operations Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Corporate Banking supports client relationships and business growth. They analyze financial data, provide strategic insights, and collaborate with teams to deliver value-added banking solutions.",
                    "related_job_titles": "Senior Professional - Corporate Banking: Senior Corporate Analyst, Lead Business Specialist, Principal Corporate Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Corporate Banking contributes to client engagements and financial analyses. They support credit assessments, participate in deal structuring, and assist in the execution of financial transactions.",
                    "related_job_titles": "Experienced Professional - Corporate Banking: Corporate Solutions Specialist, Business Project Coordinator, Senior Corporate Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Corporate Banking Professional assists in client relationship management. They analyze financial information, prepare credit proposals, and contribute to the development of banking strategies.",
                    "related_job_titles": "Professional - Corporate Banking: Business Analyst, Corporate Associate, Business Operations Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Corporate Banking gains exposure to client interactions. They support client meetings, analyze financial data, and assist in preparing banking solutions.",
                    "related_job_titles": "Entry Professional - Corporate Banking: Junior Business Analyst, Trainee Corporate Associate, Corporate Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Corporate Banking provides administrative assistance to relationship managers. They manage documentation, coordinate client communications, and support the delivery of banking services.",
                    "related_job_titles": "Support Associate - Corporate Banking: Corporate Operations Associate, Business Support Assistant, Corporate Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Corporate Banking offers administrative support to the corporate banking team. They manage office tasks, coordinate meetings, and ensure smooth communication within the division.",
                    "related_job_titles": "Administrative Assistant - Corporate Banking: Corporate Office Assistant, Business Admin Support, Corporate Coordinator"
                }
            ]
        },
        "Banking Strategy and Operations": {
            "job_family_descriptor": "The Banking Strategy and Operations job family includes roles responsible for defining and executing strategic initiatives to enhance the efficiency, effectiveness, and competitiveness of the bank. These professionals collaborate with various departments to streamline processes, optimize operations, and align the bank's activities with its overarching goals.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Banking Strategy Officer responsible for defining and driving the overall strategic direction of the bank. They lead the development and execution of strategic initiatives, working closely with cross-functional teams to optimize operations, enhance customer experience, and achieve business objectives.",
                    "related_job_titles": "Chief Strategy Officer (CSO), Head of Strategy and Operations, Strategic Planning Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Banking Strategy playing a pivotal role in shaping the bank's long-term vision. They oversee the formulation and implementation of strategic plans, collaborate with senior leadership, and analyze market trends to identify growth opportunities and operational improvements.",
                    "related_job_titles": "Senior Strategy Director, Director of Strategic Planning, Strategy Implementation Lead"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Banking Strategy leading strategic initiatives that align with the bank's goals. They analyze competitive landscapes, assess market dynamics, and develop actionable strategies to drive business growth, expand market share, and enhance operational efficiency.",
                    "related_job_titles": "Strategic Planning Director, Business Strategy Director, Director of Strategic Initiatives"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager of Banking Strategy oversees the execution of strategic projects, ensuring alignment with the bank's objectives. They collaborate with various teams to drive operational improvements, optimize processes, and implement innovative solutions for enhanced customer value.",
                    "related_job_titles": "Senior Strategy Manager, Operations Strategy Manager, Senior Business Development Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Strategy Manager plays a key role in implementing the bank's strategic plans. They monitor project execution, coordinate cross-functional efforts, and drive process enhancements to maximize operational effectiveness and deliver on strategic objectives.",
                    "related_job_titles": "Strategy Implementation Manager, Operations Manager, Business Strategy Implementation Lead"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "As the Team Leader in Banking Strategy, this role supervises a team of analysts. They conduct data-driven analyses, generate strategic insights, and support decision-making processes that contribute to the bank's overall strategic direction.",
                    "related_job_titles": "Strategic Team Leader, Senior Analyst of Strategic Planning, Operations Team Supervisor"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Specialist in Banking Strategy provides expertise in strategic analysis and execution. They conduct comprehensive market research, identify growth opportunities, and develop data-driven recommendations to support the bank's strategic initiatives.",
                    "related_job_titles": "Strategy Specialist, Operations Specialist, Business Insights Expert"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Banking Strategy analyzes industry trends, competitive landscapes, and market dynamics to formulate strategic recommendations. They collaborate with cross-functional teams to implement initiatives that drive business growth and operational excellence.",
                    "related_job_titles": "Senior Strategy Analyst, Lead Business Strategist, Principal Operations Planner"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Banking Strategy contributes to strategic projects by conducting market research, analyzing data, and supporting the development of actionable recommendations. They assist in the execution of initiatives aimed at optimizing operations and enhancing customer value.",
                    "related_job_titles": "Strategy Solutions Specialist, Operations Project Coordinator, Senior Business Analyst"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Professional in Banking Strategy supports the strategic planning process by conducting data analysis, generating insights, and contributing to the implementation of operational improvements. They play a role in aligning business activities with the bank's overarching strategy.",
                    "related_job_titles": "Strategy Analyst, Operations Associate, Business Strategy Support"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Banking Strategy gains exposure to strategic initiatives through data analysis, research, and supporting project work. They assist senior team members in developing insights and contribute to the bank's strategic objectives.",
                    "related_job_titles": "Junior Strategy Analyst, Trainee Operations Associate, Entry-level Business Strategist"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Banking Strategy provides administrative and coordination support to strategic projects. They compile reports, manage documentation, and assist in organizing activities that contribute to the successful execution of the bank's strategic initiatives.",
                    "related_job_titles": "Operations Support Associate, Business Support Assistant, Strategy Coordination Assistant"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Banking Strategy offers administrative support to strategy teams. They manage office tasks, coordinate meetings, and assist in maintaining efficient communication and documentation related to the bank's strategic activities.",
                    "related_job_titles": "Strategy Office Assistant, Operations Admin Support"
                }
            ]
        },
        "Investment Banking": {
            "job_family_descriptor": "Investment Banking encompasses roles focused on advising clients, typically corporations and institutions, on financial strategies related to mergers and acquisitions, capital raising, and other complex financial transactions. Investment bankers provide advisory and execution services to help clients achieve their financial objectives.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Investment Banking Officer is responsible for leading the investment banking division. They oversee strategic direction, business development, and execution of investment banking services, including mergers and acquisitions, capital raising, and financial advisory.",
                    "related_job_titles": "Chief Investment Banking Officer (CIBO): Chief Investment Banking Officer, Head of Investment Banking, Investment Banking Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Investment Banking leads high-level engagements with clients. They manage deal teams, execute complex financial transactions, and provide expert advice on capital markets, valuation, and financial strategies.",
                    "related_job_titles": "Senior Director - Investment Banking: Investment Banking Strategy Director, Senior Investment Banking Director, Director of Investment Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Investment Banking manages client relationships and deals. They collaborate with corporate clients on mergers, acquisitions, and capital raising initiatives, while ensuring adherence to regulatory and market standards.",
                    "related_job_titles": "Director - Investment Banking: Investment Operations Director, Investment Solutions Director, Chief Investment Officer (CIO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Investment Banking coordinates and manages investment banking projects. They analyze market trends, conduct financial modeling, and support deal execution, contributing to successful client outcomes.",
                    "related_job_titles": "Senior Manager - Investment Banking: Senior Investment Manager, Investment Solutions Manager, Senior Investment Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Investment Banking Manager supports deal execution and client interactions. They conduct financial analysis, prepare presentations, and assist in structuring and negotiating transactions.",
                    "related_job_titles": "Manager - Investment Banking: Investment Operations Manager, Investment Implementation Manager, Investment Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Investment Banking supervises professionals working on financial transactions. They coordinate deal processes, provide guidance on financial modeling, and ensure timely execution of client projects.",
                    "related_job_titles": "Team Leader (Professionals) - Investment Banking: Investment Team Lead, Investment Department Supervisor, Senior Investment Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Investment Banking Specialist provides subject matter expertise in financial analysis and deal structuring. They assess industry trends, evaluate valuation methods, and contribute to comprehensive financial solutions.",
                    "related_job_titles": "Specialist Professional - Investment Banking: Investment Expert, Investment Specialist, Financial Operations Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Investment Banking contributes to client engagements and deal execution. They analyze financial data, assist in due diligence, and collaborate with cross-functional teams to support successful transactions.",
                    "related_job_titles": "Senior Professional - Investment Banking: Senior Investment Analyst, Lead Financial Specialist, Principal Investment Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Investment Banking supports deal teams in executing financial transactions. They conduct research, perform financial modeling, and assist in preparing materials for client presentations.",
                    "related_job_titles": "Experienced Professional - Investment Banking: Investment Solutions Specialist, Financial Project Coordinator, Senior Investment Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Investment Banking Professional assists in financial analysis and transaction execution. They contribute to client deliverables, support due diligence efforts, and collaborate with teams to ensure accurate and timely project completion.",
                    "related_job_titles": "Professional - Investment Banking: Financial Analyst, Investment Associate, Financial Operations Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Investment Banking gains exposure to financial transactions. They assist in data analysis, conduct research, and support team members in preparing client presentations and financial models.",
                    "related_job_titles": "Entry Professional - Investment Banking: Junior Financial Analyst, Trainee Investment Associate, Investment Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Investment Banking provides administrative assistance to deal teams. They manage documentation, assist in organizing client communications, and ensure smooth project coordination.",
                    "related_job_titles": "Support Associate - Investment Banking: Investment Operations Associate, Financial Support Assistant, Investment Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Investment Banking offers administrative support to the investment banking division. They manage office tasks, coordinate meetings, and provide assistance to ensure efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Investment Banking: Investment Office Assistant, Financial Admin Support"
                }
            ]
        },
        "Risk Management": {
            "job_family_descriptor": "Risk Management roles are dedicated to identifying, assessing, and mitigating risks that could impact the bank's operations, financial stability, and reputation. Professionals in this job family develop risk management frameworks, policies, and procedures to ensure compliance with regulatory requirements and to safeguard the bank's assets.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Risk Officer responsible for overseeing the bank's risk management framework. They develop and implement risk strategies, policies, and processes to identify, assess, and mitigate risks across the organization.",
                    "related_job_titles": "Chief Risk Officer (CRO): Chief Risk Officer, Head of Risk Management, Risk Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Risk Management leads risk management efforts. They develop risk assessment methodologies, oversee risk reporting, and provide guidance on risk-related matters to senior leadership.",
                    "related_job_titles": "Senior Director - Risk Management: Risk Strategy Director, Senior Risk Management Director, Director of Risk Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Risk Management manages risk assessment and control functions. They collaborate with business units, analyze risk exposure, and recommend measures to ensure compliance with regulatory requirements.",
                    "related_job_titles": "Director - Risk Management: Risk Operations Director, Risk Solutions Director, Chief Risk Officer (CRO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Risk Management oversees risk mitigation activities. They develop risk management plans, assess the effectiveness of controls, and implement risk reduction strategies.",
                    "related_job_titles": "Senior Manager - Risk Management: Senior Risk Manager, Risk Solutions Manager, Senior Risk Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Risk Management Manager supports risk assessment and control processes. They work closely with business units, identify potential risks, and help design and implement risk management solutions.",
                    "related_job_titles": "Manager - Risk Management: Risk Operations Manager, Risk Implementation Manager, Risk Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Risk Management supervises risk analysts. They coordinate risk assessment activities, provide guidance on risk identification, and contribute to the development of risk mitigation strategies.",
                    "related_job_titles": "Team Leader (Professionals) - Risk Management: Risk Team Lead, Risk Department Supervisor, Senior Risk Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Risk Management Specialist provides expertise in identifying and analyzing risks. They develop risk models, conduct stress tests, and contribute to the enhancement of risk management frameworks.",
                    "related_job_titles": "Specialist Professional - Risk Management: Risk Expert, Risk Specialist, Compliance and Risk Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Risk Management supports risk assessment efforts. They analyze risk data, assist in developing risk management strategies, and collaborate with cross-functional teams.",
                    "related_job_titles": "Senior Professional - Risk Management: Senior Risk Analyst, Lead Compliance Specialist, Principal Risk Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Risk Management contributes to risk identification and analysis. They assist in assessing risk exposure, monitor risk metrics, and support the implementation of risk mitigation measures.",
                    "related_job_titles": "Experienced Professional - Risk Management: Risk Solutions Specialist, Compliance Project Coordinator, Senior Risk Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Risk Management Professional supports risk assessment activities. They assist in data collection, contribute to risk analysis, and help maintain risk management documentation.",
                    "related_job_titles": "Professional - Risk Management: Compliance Analyst, Risk Associate, Compliance and Risk Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Risk Management gains exposure to risk assessment processes. They assist in data entry, support risk analysis, and contribute to the development of risk reports.",
                    "related_job_titles": "Entry Professional - Risk Management: Junior Compliance Analyst, Trainee Risk Associate, Risk Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Risk Management provides administrative assistance to risk management teams. They manage documentation, assist in coordinating risk assessments, and support risk reporting.",
                    "related_job_titles": "Support Associate - Risk Management: Risk Operations Associate, Compliance Support Assistant, Risk Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Risk Management offers administrative support to the risk management division. They manage office tasks, coordinate meetings, and provide assistance to ensure efficient risk management operations.",
                    "related_job_titles": "Administrative Assistant - Risk Management: Risk Office Assistant, Compliance Admin Support, Risk Coordinator"
                }
            ]
        },
        "Wealth Management": {
            "job_family_descriptor": "Wealth Management involves roles dedicated to managing and advising high-net-worth individuals and families on their financial assets and investments. Professionals in this job family offer personalized financial planning, investment advice, estate planning, and other wealth preservation strategies.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Wealth Management Officer leads the wealth management division. They develop and execute strategies to provide high-net-worth clients with comprehensive financial planning, investment, and advisory services.",
                    "related_job_titles": "Chief Wealth Management Officer (CWMO): Chief Wealth Management Officer, Head of Relationship Management, Relationship Management Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Wealth Management oversees the wealth management strategy. They lead teams of wealth advisors, ensure client-centric services, and provide expertise in financial planning and investment solutions.",
                    "related_job_titles": "Senior Director - Wealth Management: Relationship Management Strategy Director, Senior Relationship Management Director, Director of Wealth Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Wealth Management manages client relationships and wealth advisory services. They collaborate with high-net-worth individuals, assess financial goals, and offer tailored wealth management solutions.",
                    "related_job_titles": "Director - Wealth Management: Wealth Operations Director, Wealth Solutions Director, Chief Relationship Officer (CRO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Wealth Management oversees client portfolios. They analyze financial data, develop customized wealth strategies, and provide guidance to clients on investment decisions.",
                    "related_job_titles": "Senior Manager - Wealth Management: Senior Relationship Manager, Wealth Solutions Manager, Senior Wealth Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Manager supports clients in achieving their financial goals. They assist in financial planning, provide investment recommendations, and ensure exceptional client experiences.",
                    "related_job_titles": "Manager - Wealth Management: Wealth Operations Manager, Wealth Implementation Manager, Wealth Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Wealth Management supervises wealth advisors. They guide team members in providing financial planning, estate planning, and investment advisory services to clients.",
                    "related_job_titles": "Team Leader (Professionals) - Wealth Management: Relationship Team Lead, Relationship Department Supervisor, Senior Relationship Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Specialist offers expertise in financial advisory and investment solutions. They conduct market research, analyze investment opportunities, and provide tailored advice to clients.",
                    "related_job_titles": "Specialist Professional - Wealth Management: Relationship Expert, Relationship Specialist, Wealth Advisory Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Wealth Management supports wealth advisory services. They analyze client financial situations, assist in developing financial plans, and provide guidance on investment strategies.",
                    "related_job_titles": "Senior Professional - Wealth Management: Senior Relationship Analyst, Lead Wealth Advisor, Principal Relationship Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Wealth Management contributes to client engagement and financial planning efforts. They assist in investment analysis, prepare financial reports, and provide support to clients.",
                    "related_job_titles": "Experienced Professional - Wealth Management: Relationship Solutions Specialist, Wealth Planning Coordinator, Senior Relationship Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Wealth Management Professional assists clients with financial planning and investment needs. They analyze investment options, provide recommendations, and contribute to client portfolio management.",
                    "related_job_titles": "Professional - Wealth Management: Wealth Advisor, Relationship Associate, Wealth Advisory Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Wealth Management gains exposure to client interactions and financial planning processes. They support wealth advisors, analyze investment data, and assist in client communications.",
                    "related_job_titles": "Entry Professional - Wealth Management: Junior Wealth Advisor, Trainee Relationship Associate, Wealth Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Wealth Management provides administrative assistance to wealth advisory teams. They manage documentation, assist in coordinating client communications, and support operational tasks.",
                    "related_job_titles": "Support Associate - Wealth Management: Wealth Operations Associate, Relationship Support Assistant, Wealth Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Wealth Management offers administrative support to the wealth management division. They manage office tasks, coordinate meetings, and assist in maintaining efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Wealth Management: Wealth Office Assistant, Relationship Admin Support,"
                }
            ]
        },
        "Treasury": {
            "job_family_descriptor": "The Treasury job family focuses on managing the bank's liquidity, cash flow, and financial assets. Professionals in this field oversee funding, cash management, capital allocation, and risk management to ensure the bank's financial stability and optimal use of resources.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Treasury Officer leads the treasury management division. They oversee cash management, liquidity, and risk management strategies to optimize the organization's financial position and ensure compliance with financial regulations.",
                    "related_job_titles": "Chief Treasury Officer (CTO): Chief Treasury Officer, Head of Treasury, Treasury Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director in Treasury leads treasury operations and strategies. They manage cash flows, analyze financial data, and develop plans to optimize liquidity, funding, and financial risk management.",
                    "related_job_titles": "Senior Director - Treasury: Treasury Strategy Director, Senior Treasury Director, Director of Treasury Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director in Treasury manages treasury functions. They oversee cash forecasting, analyze funding needs, and implement strategies to manage financial risk and maximize investment returns.",
                    "related_job_titles": "Director - Treasury: Treasury Operations Director, Treasury Solutions Director, Chief Treasury Officer (CFO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Treasury oversees cash management activities. They execute cash flow forecasting, monitor banking relationships, and ensure efficient fund utilization to meet financial obligations.",
                    "related_job_titles": "Senior Manager - Treasury: Senior Treasury Manager, Treasury Solutions Manager, Senior Treasury Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Treasury Manager supports cash operations. They manage cash transactions, monitor account balances, and assist in optimizing cash positions and liquidity.",
                    "related_job_titles": "Manager - Treasury: Treasury Operations Manager, Treasury Implementation Manager, Treasury Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Treasury supervises treasury analysts. They coordinate cash management activities, analyze financial data, and contribute to treasury strategy execution.",
                    "related_job_titles": "Team Leader (Professionals) - Treasury: Treasury Team Lead, Treasury Department Supervisor, Senior Treasury Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Treasury Specialist provides expertise in cash management and financial risk. They analyze market trends, execute cash management strategies, and support risk mitigation efforts.",
                    "related_job_titles": "Specialist Professional - Treasury: Treasury Expert, Treasury Specialist, Financial Operations Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Treasury supports treasury operations. They manage cash flows, assist in liquidity management, and contribute to financial risk assessment and mitigation.",
                    "related_job_titles": "Senior Professional - Treasury: Senior Treasury Analyst, Lead Financial Specialist, Principal Treasury Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Treasury assists in cash operations and financial analysis. They execute cash forecasts, support investment decisions, and contribute to treasury reporting.",
                    "related_job_titles": "Experienced Professional - Treasury: Treasury Solutions Specialist, Financial Project Coordinator, Senior Treasury Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Professional assists in cash management activities. They perform cash transactions, monitor liquidity positions, and support financial risk management efforts.",
                    "related_job_titles": "Professional - Treasury: Financial Analyst, Treasury Associate, Financial Operations Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Treasury gains exposure to cash operations. They assist in cash forecasting, support treasury projects, and contribute to financial reporting.",
                    "related_job_titles": "Entry Professional - Treasury: Junior Financial Analyst, Trainee Treasury Associate, Treasury Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Treasury provides administrative assistance to treasury teams. They manage documentation, assist in coordinating treasury activities, and support operational tasks.",
                    "related_job_titles": "Support Associate - Treasury: Treasury Operations Associate, Financial Support Assistant, Treasury Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Treasury offers administrative support to the treasury management division. They manage office tasks, coordinate meetings, and assist in maintaining efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Treasury: Treasury Office Assistant, Financial Admin Support"
                }
            ]
        },
        "Compliance and Regulatory Affairs": {
            "job_family_descriptor": "Compliance and Regulatory Affairs roles are responsible for ensuring the bank's adherence to legal and regulatory requirements. Professionals in this job family develop and implement compliance programs, monitor regulatory changes, conduct internal audits, and ensure the bank's operations are conducted within legal and ethical boundaries.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief leads the compliance and regulatory affairs function. They are responsible for developing and implementing comprehensive compliance strategies, ensuring alignment with regulatory requirements, overseeing risk assessments, and providing strategic guidance to senior leadership.",
                    "related_job_titles": "Chief Compliance Officer (CCO): Chief Compliance Officer, Head of Compliance, Compliance Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director plays a critical role in shaping the organization's compliance framework. They lead the design and execution of compliance programs, manage relationships with regulatory authorities, and collaborate across departments to ensure consistent adherence to regulatory standards.",
                    "related_job_titles": "Senior Director - Compliance: Compliance Strategy Director, Senior Compliance Director, Director of Compliance Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director manages and coordinates compliance efforts. They evaluate regulatory impacts on business operations, develop compliance policies and procedures, and lead initiatives to educate employees on regulatory matters.",
                    "related_job_titles": "Director - Compliance: Compliance Operations Director, Compliance Solutions Director, Chief Compliance Officer (CCO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Compliance and Regulatory Affairs oversees compliance operations. They lead compliance audits, assess regulatory risks, provide training to employees, and contribute to the enhancement of compliance controls.",
                    "related_job_titles": "Senior Manager - Compliance: Senior Compliance Manager, Compliance Solutions Manager, Senior Compliance Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Manager focuses on ensuring adherence to relevant regulations. They review and update compliance policies, monitor regulatory changes, collaborate with internal stakeholders, and assist in driving a culture of compliance.",
                    "related_job_titles": "Manager - Compliance: Compliance Operations Manager, Compliance Implementation Manager, Compliance Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader supervises a team of compliance professionals. They guide compliance activities, conduct risk assessments, contribute to compliance strategies, and support the execution of compliance initiatives.",
                    "related_job_titles": "Team Leader (Professionals) - Compliance: Compliance Team Lead, Compliance Department Supervisor, Senior Compliance Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Specialist brings expertise in regulatory compliance. They analyze evolving regulations, assist in the development of compliance programs, conduct internal assessments, and provide subject matter support.",
                    "related_job_titles": "Specialist Professional - Compliance: Compliance Expert, Compliance Specialist, Regulatory Compliance Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Compliance and Regulatory Affairs supports compliance operations. They review compliance documentation, assist in compliance assessments, and contribute to the development of compliance training materials.",
                    "related_job_titles": "Senior Professional - Compliance: Senior Compliance Analyst, Lead Regulatory Specialist, Principal Compliance Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in assists in compliance activities. They contribute to regulatory research, support compliance assessments, and collaborate with cross-functional teams to ensure compliance with regulatory obligations.",
                    "related_job_titles": "Experienced Professional - Compliance: Compliance Solutions Specialist, Regulatory Project Coordinator, Senior Compliance Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Professional is involved in day-to-day compliance efforts. They review compliance documentation, support compliance assessments, and contribute to regulatory reporting.",
                    "related_job_titles": "Professional - Compliance: Regulatory Analyst, Compliance Associate, Regulatory Compliance Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional gains exposure to compliance processes. They assist in compliance reviews, contribute to regulatory research, and support compliance documentation.",
                    "related_job_titles": "Entry Professional - Compliance: Junior Regulatory Analyst, Trainee Compliance Associate, Compliance Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate provides administrative assistance to compliance teams. They manage documentation, assist in coordinating compliance activities, and support compliance reporting.",
                    "related_job_titles": "Support Associate - Compliance: Compliance Operations Associate, Regulatory Support Assistant, Compliance Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the compliance and regulatory affairs division. They manage office tasks, coordinate meetings, and assist in maintaining efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Compliance: Compliance Office Assistant, Regulatory Admin Support, Compliance Coordinator"
                }
            ]
        },
        "Capital Markets": {
            "job_family_descriptor": "Capital Markets encompasses roles related to financial markets, including trading, investment, and securities issuance. Professionals in this job family engage in trading activities, manage investment portfolios, facilitate securities offerings, and provide market insights to clients and internal stakeholders.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief leads the capital markets division. They are responsible for formulating and executing strategies related to trading, investment banking, and capital raising activities to optimize the organization's capital structure and financial performance.",
                    "related_job_titles": "Chief Capital Markets Officer (CCMO): Chief Capital Markets Officer, Head of Capital Markets, Capital Markets Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director oversees capital markets operations. They lead trading activities, manage investment portfolios, and collaborate with investment bankers to execute capital raising initiatives.",
                    "related_job_titles": "Senior Director - Capital Markets: Capital Markets Strategy Director, Senior Capital Markets Director, Director of Capital Markets Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director manages trading and investment activities. They analyze market trends, execute trades, and assist in structuring and executing capital market transactions.",
                    "related_job_titles": "Director - Capital Markets: Capital Markets Operations Director, Capital Markets Solutions Director, Chief Capital Markets Officer (CCMO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager oversees trading and investment strategies. They manage trading teams, analyze market data, and provide insights to enhance investment decision-making.",
                    "related_job_titles": "Senior Manager - Capital Markets: Senior Capital Markets Manager, Capital Markets Solutions Manager, Senior Capital Markets Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Manager supports trading and investment functions. They execute trades, monitor market performance, and contribute to the development of trading strategies.",
                    "related_job_titles": "Manager - Capital Markets: Capital Markets Operations Manager, Capital Markets Implementation Manager, Capital Markets Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader supervises trading professionals. They coordinate trading activities, analyze market data, and contribute to the development of investment strategies.",
                    "related_job_titles": "Team Leader (Professionals) - Capital Markets: Capital Markets Team Lead, Capital Markets Department Supervisor, Senior Capital Markets Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Specialist provides expertise in trading and investment activities. They analyze market trends, execute trades, and support the implementation of capital market transactions.",
                    "related_job_titles": "Specialist Professional - Capital Markets: Capital Markets Expert, Capital Markets Specialist, Investment Banking Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional supports trading and investment operations. They monitor market performance, assist in trade execution, and contribute to investment analysis.",
                    "related_job_titles": "Senior Professional - Capital Markets: Senior Capital Markets Analyst, Lead Investment Specialist, Principal Capital Markets Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional contributes to trading and investment efforts. They execute trades, monitor market trends, and support investment decision-making.",
                    "related_job_titles": "Experienced Professional - Capital Markets: Capital Markets Solutions Specialist, Investment Project Coordinator, Senior Capital Markets Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Professional supports trading and investment functions. They execute trades, analyze market data, and contribute to investment analysis.",
                    "related_job_titles": "Professional - Capital Markets: Investment Analyst, Capital Markets Associate, Investment Banking Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional gains exposure to trading and investment activities. They support trade execution, monitor market data, and assist in investment analysis.",
                    "related_job_titles": "Entry Professional - Capital Markets: Junior Investment Analyst, Trainee Capital Markets Associate, Capital Markets Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate provides administrative assistance to trading teams. They manage documentation, assist in coordinating trading activities, and support operational tasks.",
                    "related_job_titles": "Support Associate - Capital Markets: Capital Markets Operations Associate, Investment Support Assistant, Capital Markets Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the capital markets division. They manage office tasks, coordinate meetings, and assist in maintaining efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Capital Markets: Capital Markets Office Assistant, Investment Admin Support, Capital Markets Coordinator"
                }
            ]
        }
    },
    "Insurance": {
        "Underwriting": {
            "job_family_descriptor": "Job family focussing on roles in underwriting which involve assessing risks and determining insurance coverage for individuals and businesses. Underwriters evaluate applications, analyze data, and set premium rates.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Underwriting Officer",
                    "position_descriptor": "The Chief leads the underwriting division and sets the strategic direction for risk assessment and pricing. They ensure underwriting policies align with business goals, oversee underwriting operations, and collaborate with other departments to optimize risk management.",
                    "related_job_titles": "Chief Underwriting Officer (CUO), Chief Underwriter, Head of Underwriting, Chief Risk Officer, Senior Underwriting Executive, Underwriting Leader"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director \u2013 Underwriting",
                    "position_descriptor": "The Senior Director provides leadership to the underwriting team. They guide risk evaluation, pricing strategies, and underwriting processes. The Senior Director monitors portfolio performance, develops underwriting guidelines, and contributes to product development.",
                    "related_job_titles": "Senior Director \u2013 Underwriting, Senior Underwriting Director, Director of Risk Assessment, Chief Underwriting Strategist, Head of Underwriting Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director \u2013 Underwriting",
                    "position_descriptor": "The Director manages underwriting activities and portfolio performance. They oversee risk assessment, collaborate with underwriters, and ensure adherence to underwriting guidelines. The Director analyzes market trends and contributes to the development of underwriting strategies.",
                    "related_job_titles": "Director \u2013 Underwriting, Underwriting Director, Risk Assessment Director, Director of Underwriting Services, Director of Underwriting Operations, Director of Risk Management"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager \u2013 Underwriting",
                    "position_descriptor": "The Senior Manager leads a team of underwriters. They review complex risks, make underwriting decisions, and provide technical guidance to underwriting staff. The Senior Manager ensures consistent and accurate risk evaluation.",
                    "related_job_titles": "Senior Manager \u2013 Underwriting, Senior Underwriting Manager, Senior Risk Evaluation Manager, Senior Manager of Underwriting Services, Senior Risk Assessment Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager \u2013 Underwriting",
                    "position_descriptor": "The Manager oversees underwriting operations. They review insurance applications, assess risk factors, and make underwriting decisions within established guidelines. The Manager collaborates with agents, brokers, and internal teams to ensure quality underwriting services.",
                    "related_job_titles": "Manager \u2013 Underwriting, Underwriting Manager, Risk Manager, Manager of Risk Assessment, Manager of Underwriting Operations, Portfolio Underwriting Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader supervises a team of underwriters. They guide risk assessment, provide technical mentorship, and ensure accurate and timely underwriting decisions. The Team Leader contributes to underwriting process enhancements.",
                    "related_job_titles": "Team Leader \u2013 Underwriting, Underwriting Team Leader, Senior Underwriting Specialist, Lead Underwriter, Team Lead - Risk Assessment, Underwriting Process Leader"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional \u2013 Underwriting",
                    "position_descriptor": "The Specialist assesses complex risks and provides expertise in specific insurance lines. They evaluate risk factors, analyze underwriting data, and recommend appropriate coverage terms. The Specialist collaborates with underwriters and contributes to risk evaluation.",
                    "related_job_titles": "Specialist Professional \u2013 Underwriting, Underwriting Specialist, Risk Assessment Specialist, Senior Underwriter, Underwriting Analyst, Expert Underwriter"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional \u2013 Underwriting",
                    "position_descriptor": "The Senior Professional reviews insurance applications, assesses risk profiles, and makes underwriting decisions. They ensure compliance with underwriting guidelines, provide customer support, and contribute to risk analysis.",
                    "related_job_titles": "Senior Underwriter, Experienced Underwriting Professional, Senior Risk Analyst, Senior Policy Underwriter, Underwriting Consultant, Senior Underwriting Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional \u2013 Underwriting",
                    "position_descriptor": "The Experienced Professional evaluates insurance applications, gathers risk information, and supports underwriting processes. They assist in risk assessment, collaborate with underwriters, and ensure accurate data entry.",
                    "related_job_titles": "Experienced Professional \u2013 Underwriting, Experienced Underwriter, Underwriting Analyst, Policy Underwriting Specialist, Risk Assessment Associate, Senior Underwriting Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional \u2013 Underwriting",
                    "position_descriptor": "The Underwriting Professional reviews standard insurance applications, assesses risk characteristics, and contributes to underwriting decisions. They collaborate with underwriting teams, process policy documents, and ensure accurate recordkeeping.",
                    "related_job_titles": "Professional \u2013 Underwriting, Underwriter, Associate Underwriter, Policy Underwriting Associate, Underwriting Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional \u2013 Underwriting",
                    "position_descriptor": "The Entry-level Professional in Underwriting gains exposure to underwriting procedures. They assist in data entry, support underwriting activities, and learn about risk assessment and insurance guidelines.",
                    "related_job_titles": "Entry Professional \u2013 Underwriting, Underwriting Assistant, Junior Underwriting Associate, Trainee Underwriter, Junior Underwriter, Entry-level Underwriting Specialist"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate \u2013 Underwriting",
                    "position_descriptor": "The Support Associate provides administrative assistance to underwriting teams. They manage documentation, assist in coordinating underwriting activities, and support operational tasks.",
                    "related_job_titles": "Support Associate \u2013 Underwriting, Underwriting Support Associate, Senior Underwriting Administrative Clerk, Underwriting Documentation Associate, Underwriting Support Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Underwriting",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the underwriting division. They manage office tasks, coordinate meetings, and assist in maintaining efficient underwriting operations",
                    "related_job_titles": "Administrative Assistant \u2013 Underwriting, Underwriting Administrative Assistant, Underwriting Operations Assistant, Administrative Support \u2013 Underwriting, Underwriting Clerk"
                }
            ]
        },
        "Claims": {
            "job_family_descriptor": "The claims job family involves handling and processing insurance claims from policyholders. Claims professionals investigate and verify claims, determine coverage, and facilitate settlements",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Claims Officer",
                    "position_descriptor": "The Chief leads the claims division, overseeing the strategic direction of claims processing and management. They ensure efficient claims handling, collaborate with other departments, and implement strategies to optimize claims settlement and customer satisfaction.",
                    "related_job_titles": "Chief Claims Officer (CCO), Chief Claims Officer, Head of Claims, Chief Claims Executive, Claims Division Leader, Head of Claims Operations"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Claims",
                    "position_descriptor": "The Senior Director provides leadership to the claims team. They guide claims processing operations, implement best practices, and ensure timely and accurate claims assessment. The Senior Director monitors claims metrics, collaborates with underwriting and legal teams, and contributes to the development of claims strategies.",
                    "related_job_titles": "Senior Director \u2013 Claims, Senior Claims Director, Director of Claims Services, Claims Strategy Senior Director, Head of Claims Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Claims",
                    "position_descriptor": "The Director manages claims processing and resolution. They oversee claims investigations, review policy terms, and ensure compliance with claims guidelines. The Director collaborates with adjusters, legal teams, and customer service to ensure efficient claims resolution.",
                    "related_job_titles": "Director \u2013 Claims, Claims Director, Director of Claims Resolution, Claims Processing Director, Director of Claims Management, Claims Operations Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Claims",
                    "position_descriptor": "The Senior Manager leads a team of claims adjusters. They review complex claims cases, provide technical guidance, and ensure accurate claims assessment. The Senior Manager fosters a customer-centric approach and collaborates with other departments to improve claims processes.",
                    "related_job_titles": "Senior Manager \u2013 Claims, Senior Claims Manager, Claims Operations Senior Manager, Senior Claims Adjuster. Senior Manager of Claims Assessment, Claims Resolution Senior Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Claims",
                    "position_descriptor": "The Claims Manager oversees claims processing operations. They review claims documentation, assess coverage, and make informed claims decisions. The Manager collaborates with adjusters, coordinates claims investigations, and ensures efficient claims resolution.",
                    "related_job_titles": "Manager \u2013 Claims, Claims Manager, Claims Processing Manager, Manager of Claims Resolution, Claims Assessment Manager, Claims Operations Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Claims",
                    "position_descriptor": "The Team Leader supervises a team of claims professionals. They guide claims processing, provide technical mentorship, and ensure accurate claims assessment. The Team Leader fosters a positive team environment and maintains effective communication.",
                    "related_job_titles": "Team Leader (Professionals) \u2013 Claims, Claims Team Leader,  Senior Claims Specialist, Lead Claims Adjuster, Team Lead - Claims Processing, Claims Processing Supervisor, Claims Supervisor"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Claims",
                    "position_descriptor": "The Claims Specialist handles complex claims cases. They analyze policy terms, assess claims, and collaborate with legal and underwriting teams to ensure accurate claims processing. The Specialist provides expertise in claims assessment and resolution.",
                    "related_job_titles": "Specialist Professional \u2013 Claims, Claims Specialist, Claims Resolution Specialist, Senior Claims Adjuster, Claims Analyst, Expert Claims Adjuster"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Claims",
                    "position_descriptor": "The Senior Professional assesses and processes insurance claims. They review documentation, determine coverage, and facilitate claims settlement. The Senior Professional ensures compliance with claims guidelines and supports effective customer communication.",
                    "related_job_titles": "Senior Professional \u2013 Claims, Senior Claims Professional, Senior Claims Analyst, Senior Claims Assessor, Senior Claims Examiner, Claims Consultant, Senior Claims Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Claims",
                    "position_descriptor": "The Experienced Professional handles insurance claims and provides customer support. They review claims documentation, assist in claims assessment, and collaborate with adjusters to facilitate claims resolution.",
                    "related_job_titles": "Experienced Professional \u2013 Claims, Experienced Claims Professional, Claims Analyst, Claims Processing Specialist, Claims Resolution Associate, Senior Claims Support"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Claims",
                    "position_descriptor": "The Claims Professional assesses insurance claims and supports claims processing. They review documentation, liaise with adjusters, and ensure accurate claims assessment to facilitate timely claims settlement.",
                    "related_job_titles": "Professional \u2013 Claims, Claims Professional, Junior Claims Analyst, Claims Associate, Claims Processing Associate, Junior Claims Adjuster, Claims Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Claims",
                    "position_descriptor": "The Entry-level Professional gains exposure to claims processing procedures. They assist in claims documentation, support adjusters, and learn about claims assessment and resolution.",
                    "related_job_titles": "Entry Professional \u2013 Claims, Claims Assistant, Junior Claims Associate, Entry-level Claims Specialist"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Claims",
                    "position_descriptor": "The Support Associate provides administrative assistance to claims teams. They manage documentation, assist in coordinating claims activities, and support operational tasks.",
                    "related_job_titles": "Support Associate \u2013 Claims, Claims Support Associate, Claims Administrative Associate, Claims Documentation Associate, Claims Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Claims",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the claims division/department. They manage office tasks, coordinate meetings, and assist in maintaining efficient claims processing operations",
                    "related_job_titles": "Administrative Assistant - Claims: Claims Administrative Assistant, Claims Clerk, Claims Operations Assistant, Claims Office Administrator, Claims Data Entry Clerk"
                }
            ]
        },
        "Actuarial": {
            "job_family_descriptor": "Actuarial job family is primarily focused on analyzing statistical data to assess and manage financial risks. Actuaries use mathematical models to calculate insurance premiums, reserves, and other financial aspects.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Actuarial Officer",
                    "position_descriptor": "The Chief leads the actuarial division, overseeing the strategic direction of risk assessment and financial modeling. They ensure accurate actuarial analyses, collaborate with other departments, and implement strategies to optimize financial stability and compliance.",
                    "related_job_titles": "Chief Actuarial Officer (CAO), Chief Actuary, Head of Actuarial Division, Chief Risk Analyst, Actuarial Executive, Actuarial Strategy Leader."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Actuarial",
                    "position_descriptor": "The Senior Director provides leadership to the actuarial team. They guide risk assessment and financial modeling operations, implement best practices, and ensure accurate financial projections. The Senior Director monitors actuarial metrics, collaborates with underwriting and finance teams, and contributes to the development of actuarial strategies.",
                    "related_job_titles": "Senior Director \u2013 Actuarial, Senior Actuarial Director, Director of Risk Modeling, Chief Actuarial Strategist, Head of Actuarial Operations, Senior Risk Analyst."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Actuarial",
                    "position_descriptor": "The Director manages actuarial analyses and projections. They oversee financial modeling, review assumptions, and ensure compliance with actuarial guidelines. The Director collaborates with underwriters, product managers, and finance teams to ensure accurate financial forecasting.",
                    "related_job_titles": "Director \u2013 Actuarial, Actuarial Director, Director of Financial Projections, Director of Risk Assessment, Chief Actuarial Analyst, Director of Actuarial Services."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Actuarial",
                    "position_descriptor": "The Senior Manager leads a team of actuaries. They review complex financial models, provide technical guidance, and ensure accurate risk assessment. The Senior Manager fosters a data-driven approach and collaborates with other departments to improve financial analyses.",
                    "related_job_titles": "Senior Manager \u2013 Actuarial, Senior Actuarial Manager, Senior Manager of Financial Modeling, Senior Risk Assessor, Actuarial Analytics Senior Manager, Senior Manager - Risk Evaluation."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Actuarial",
                    "position_descriptor": "The Actuarial Manager oversees financial modeling operations. They review assumptions, assess financial risks, and contribute to informed decision-making. The Manager collaborates with actuaries, supports financial projections, and ensures accurate risk assessment.",
                    "related_job_titles": "Manager \u2013 Actuarial, Actuarial Manager, Manager of Risk Projections, Manager of Risk Assessment, Financial Modeling Manager, Manager of Actuarial Operations."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Actuarial",
                    "position_descriptor": "The Team Leader supervises a team of actuarial professionals. They guide financial modeling, provide technical mentorship, and ensure accurate risk assessment. The Team Leader fosters a collaborative environment and maintains effective communication.",
                    "related_job_titles": "Team Leader \u2013 Actuarial, Actuarial Team Leader, Lead Actuarial Analyst, Team Lead - Financial Projections, Actuarial Process Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Actuarial",
                    "position_descriptor": "The Actuarial Specialist handles complex financial modeling and projections. They analyze data, assess risk factors, and collaborate with underwriting and finance teams to ensure accurate financial analyses. The Specialist provides expertise in financial modeling and risk assessment.",
                    "related_job_titles": "Specialist Professional \u2013 Actuarial, Actuarial Specialist, Risk Modeling Specialist, Senior Actuarial Analyst, Financial Modeling Expert, Advanced Risk Assessor."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Actuarial",
                    "position_descriptor": "The Senior Professional performs financial modeling and risk assessment. They review data, assess risk profiles, and contribute to accurate financial projections. The Senior Professional ensures compliance with actuarial guidelines and supports informed decision-making.",
                    "related_job_titles": "Senior Professional \u2013 Actuarial, Senior Actuary, Experienced Risk Analyst, Senior Actuarial Analyst, Senior Financial Modeler, Actuarial Consultant, Senior Actuarial Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Actuarial",
                    "position_descriptor": "The Experienced Professional performs financial analyses and supports risk assessment. They review data, assist in financial modeling, and collaborate with actuaries to facilitate accurate financial projections.",
                    "related_job_titles": "Experienced Professional - Actuarial: Experienced Actuary, Actuarial Analyst"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Actuarial",
                    "position_descriptor": "The Actuarial performs financial modeling and contributes to risk assessment. They review data, collaborate with actuaries, and ensure accurate financial analyses to facilitate informed decision-making.",
                    "related_job_titles": "Professional \u2013 Actuarial, Actuary, Actuarial Associate, Actuarial Analyst, Actuarial Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Actuarial",
                    "position_descriptor": "The Entry-level Professional gains exposure to financial modeling and risk assessment. They assist in data analysis, support actuarial processes, and learn about financial projections.",
                    "related_job_titles": "Entry Professional \u2013 Actuarial, Actuarial Assistant, Junior Actuarial Associate, Trainee Actuary, Actuarial Support, Entry-level Risk Analyst."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Actuarial",
                    "position_descriptor": "The Support Associate provides administrative assistance to actuarial teams. They manage documentation, assist in coordinating actuarial activities, and support operational tasks.",
                    "related_job_titles": "Support Associate \u2013 Actuarial, Actuarial Support Associate, Actuarial Administrative Assistant, Actuarial Documentation Associate, Actuarial Coordinator, Administrative Support - Actuarial."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Actuarial",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the actuarial division. They manage office tasks, coordinate meetings, and assist in maintaining efficient actuarial operations.",
                    "related_job_titles": "Administrative Assistant \u2013 Actuarial, Actuarial Administrative Assistant, Actuarial Office Coordinator, Actuarial Operations Assistant, Administrative Support - Actuarial, Actuarial Office Administrator."
                }
            ]
        },
        "Claims Investigation and Fraud Detection": {
            "job_family_descriptor": "A specialized job families focused on investigating potentially fraudulent claims. These professionals identify suspicious activities and ensure that claims are legitimate.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Head of Fraud Detection",
                    "position_descriptor": "The Head of Fraud Detection is a senior executive responsible for overseeing the entire fraud detection function within the organization. This role involves setting the strategic direction for fraud prevention initiatives, developing and implementing policies and procedures, and leading a team of managers and analysts. The VP collaborates with other senior leaders to ensure fraud detection efforts align with business goals and regulatory requirements. They may represent the organization in industry forums and contribute to the development of industry-wide fraud prevention standards.",
                    "related_job_titles": "Head of Fraud Detection, Vice President of Fraud Detection, Chief Fraud Detection Officer, Executive Director of Fraud Prevention"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Fraud Detection Director",
                    "position_descriptor": "The Fraud Detection Director leads and manages a team of fraud detection specialists and analysts. They develop and execute strategies to prevent and detect fraudulent activities across the organization. This role involves analyzing data, identifying patterns of fraud, and implementing measures to mitigate risks. The director collaborates with cross-functional teams, reports findings to senior management, and plays a key role in shaping the organization's fraud prevention policies.",
                    "related_job_titles": "Fraud Detection Director, Director of Claims Investigation and Fraud Prevention,Fraud Prevention and Detection Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Fraud Detection Manager or Team Lead",
                    "position_descriptor": "The Fraud Detection Manager oversees a team of investigators and analysts focused on detecting and preventing fraudulent claims. They provide leadership, mentorship, and guidance to team members, ensuring the effective and efficient execution of fraud prevention strategies. The manager collaborates with other departments, stays updated on industry trends, and contributes to the development of fraud prevention protocols.",
                    "related_job_titles": "Fraud Detection Manager, Manager of Fraud Investigation,  Fraud Prevention Team Lead"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Fraud Detection Specialist",
                    "position_descriptor": "Fraud Detection Specialists are responsible for investigating and analyzing potentially fraudulent claims and activities. They use data analysis, research, and investigative techniques to identify suspicious patterns and behaviors. Specialists collaborate with internal and external stakeholders to gather information, compile evidence, and recommend appropriate actions to prevent and mitigate fraud.",
                    "related_job_titles": "Fraud Detection Specialist, Fraud Investigator, Claims Fraud Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Fraud Detection Analyst",
                    "position_descriptor": "Fraud Detection Analysts focus on analyzing data to detect patterns and anomalies indicative of fraudulent activities. They use specialized software and tools to review claims data, transaction records, and other relevant information. Analysts produce reports, identify potential red flags, and work closely with investigators to support fraud prevention efforts.",
                    "related_job_titles": "Fraud Detection Analyst, Fraud Analyst, Anti-Fraud Data Analyst"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Claims Investigator",
                    "position_descriptor": "Senior Claims Investigators take a leadership role in conducting in-depth investigations into potentially fraudulent claims. They gather evidence, interview parties involved, and collaborate with law enforcement and legal teams when necessary. Seniors often mentor and train junior investigators, contribute to process improvement initiatives, and assist in shaping fraud detection strategies.",
                    "related_job_titles": "Senior Claims Investigator, Lead Investigator, Senior Fraud Examiner"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Claims Investigator",
                    "position_descriptor": "Claims Investigators are responsible for conducting thorough investigations into claims that raise suspicion of fraud. They gather information, analyze data, interview claimants and witnesses, and compile evidence to make informed determinations about the validity of claims. Investigators work within established protocols and contribute to the overall fraud detection efforts of the organization.",
                    "related_job_titles": "Claims Investigator, Fraud Investigator, Investigative Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry-level or Junior Claims Investigator",
                    "position_descriptor": "Entry-level or Junior Claims Investigators assist more experienced team members in conducting investigations. They learn to use investigative tools and techniques, gather preliminary information, and support senior investigators. They may also assist in data analysis and contribute to the documentation of investigation findings.",
                    "related_job_titles": "Junior Fraud Analyst, Claims Fraud Trainee"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Fraud",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the claims Investigation and Fraud division/department. They manage office tasks, coordinate meetings, and assist in maintaining efficient documentation processing operations",
                    "related_job_titles": "Administrative Assistant - Claims: Claims Administrative Assistant,Fraud Clerk, Claims Operations Assistant, Claims Office Administrator, Claims Data Entry Clerk"
                }
            ]
        },
        "Insurance Product Development": {
            "job_family_descriptor": "This job family specialises on creating new insurance products or improving existing ones. They analyze market trends, assess customer needs, and design insurance offerings.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Product Development Officer",
                    "position_descriptor": "The Chief leads the product development division, overseeing the strategic creation and enhancement of insurance products. They ensure market analysis, collaborate with underwriting and marketing teams, and implement strategies to optimize product offerings and competitiveness.",
                    "related_job_titles": "Chief Product Officer, Head of Product Innovation, Chief Product Strategist, Product Development Executive, Product Strategy Leader, Chief Innovation Officer."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Product Development",
                    "position_descriptor": "The Senior Director provides leadership to the product development team. They guide the creation of insurance products, implement best practices, and ensure alignment with customer needs. The Senior Director monitors market trends, collaborates with underwriting and sales teams, and contributes to the development of product strategies.",
                    "related_job_titles": "Senior Product Development Director, Director of Product Innovation, Chief Product Strategist, Head of Product Design, Senior Product Management Director, Senior Director of Product Strategy."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Product Development",
                    "position_descriptor": "The Director manages the development and enhancement of insurance products. They oversee market research, review product specifications, and ensure compliance with regulatory requirements. The Director collaborates with underwriters, actuaries, and marketing teams to ensure successful product launches.",
                    "related_job_titles": "Product Development Director, Director of Product Enhancement, Director of Product Strategy, Chief Product Architect, Director of Product Management, Product Design Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Product Development",
                    "position_descriptor": "The Senior Manager leads a team of product developers. They review product concepts, provide technical guidance, and ensure effective product implementation. The Senior Manager fosters innovation and collaborates with cross-functional teams to enhance product offerings.",
                    "related_job_titles": "Senior Product Development Manager, Manager of Product Innovation, Senior Product Implementation Manager, Product Innovation Senior Manager, Senior Product Enhancement Manager, Senior Manager of Product Design."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Product Development",
                    "position_descriptor": "The Product Development Manager oversees product development operations. They review product proposals, assess market demand, and contribute to the design of insurance products. The Manager collaborates with product analysts, underwriters, and marketing teams to bring products to market.",
                    "related_job_titles": "Product Development Manager, Manager of Product Design, Manager of Product Enhancement, Product Design and Innovation Manager, Manager of Product Strategy, Product Innovation Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Product Development",
                    "position_descriptor": "The Team Leader supervises a team of product development professionals. They guide product creation, provide technical mentorship, and ensure alignment with customer preferences. The Team Leader fosters a collaborative environment and maintains effective communication.",
                    "related_job_titles": "Product Development Team Leader, Senior Product Specialist, Lead Product Developer, Team Lead - Product Innovation, Product Creation Supervisor, Product Design Team Leader."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Product Development",
                    "position_descriptor": "The Product Development Specialist handles specialized aspects of insurance product creation. They analyze market trends, assess customer needs, and collaborate with underwriters and marketing teams to develop competitive product offerings. The Specialist provides expertise in product innovation.",
                    "related_job_titles": "Product Development Specialist, Product Enhancement Specialist, Senior Product Analyst, Product Innovation Expert, Advanced Product Developer, Product Design Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Product Development",
                    "position_descriptor": "The Senior Professional in Product Development contributes to insurance product creation and enhancement. They review market research, assess customer feedback, and support the design of new product features. The Senior Professional ensures compliance with regulatory guidelines and contributes to product strategy.",
                    "related_job_titles": "Senior Product Developer, Experienced Product Analyst, Senior Product Designer, Senior Product Innovator, Product Strategy Consultant, Senior Product Design Analyst."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Product Development",
                    "position_descriptor": "The Experienced Professional in Product Development supports insurance product creation and refinement. They review market data, assist in product design, and collaborate with cross-functional teams to bring products to market.",
                    "related_job_titles": "Experienced Product Developer, Product Analyst, Product Design Specialist, Product Enhancement Associate, Senior Product Support, Experienced Product Design Analyst."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Product Development",
                    "position_descriptor": "The Product Development Professional assists in insurance product creation and enhancement. They review market analysis, collaborate with underwriters and marketing teams, and contribute to the design of competitive product offerings.",
                    "related_job_titles": "Product Developer, Junior Product Analyst, Product Design Associate, Product Enhancement Professional, Entry-level Product Innovator, Junior Product Designer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Product Development",
                    "position_descriptor": "The Entry-level Professional gains exposure to insurance product creation processes. They assist in market research, support product development tasks, and learn about product design and customer preferences.",
                    "related_job_titles": "Product Development Assistant, Junior Product Associate, Trainee Product Developer, Product Support, Entry-level Product Designer, Entry-level Product Analyst."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Product Development",
                    "position_descriptor": "The Support Associate provides administrative assistance to product development teams. They manage documentation, assist in coordinating product development activities, and support operational tasks.",
                    "related_job_titles": "Product Development Support Associate, Product Administrative Assistant, Product Documentation Associate, Product Coordinator, Administrative Support - Product Development, Product Support Associate."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Product Development",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the product development division. They manage office tasks, coordinate meetings, and assist in maintaining efficient product development operations.",
                    "related_job_titles": "Product Development Administrative Assistant, Product Office Clerk, Product Operations Admin Assistant, Administrative Support - Product Development, Product Office Administrator, Administrative Assistant - Product Innovation."
                }
            ]
        },
        "Insurance Strategy and Operations": {
            "job_family_descriptor": "This job family includes roles responsible for defining and executing strategic initiatives to enhance the efficiency, effectiveness, and competitiveness of the Insurance company. These professionals collaborate with various departments to streamline processes, optimize operations, and align the insurance's activities with its overarching goals.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Strategy and Operations Officer",
                    "position_descriptor": "The Chief Strategy and Operations Officer leads the strategic planning and operational management of the insurance company. They drive growth initiatives, optimize operational efficiency, and ensure alignment with corporate goals.",
                    "related_job_titles": "Chief Strategy Officer, Head of Strategy and Operations, Chief Operations Strategist, Strategy and Operations Executive, Chief Growth Officer."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Strategy and Operations",
                    "position_descriptor": "The Senior Director oversees strategic initiatives and operational processes. They develop and execute business strategies, optimize operational performance, and collaborate with cross-functional teams to achieve organizational objectives.",
                    "related_job_titles": "Senior Strategy and Operations Director, Director of Strategic Planning, Chief Operations Strategist, Senior Director of Business Strategy, Senior Operations Management Director."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Strategy and Operations",
                    "position_descriptor": "The Director manages strategic planning and operational execution. They analyze market trends, develop growth strategies, and ensure streamlined operations across the company's functions.",
                    "related_job_titles": "Strategy and Operations Director, Director of Corporate Strategy, Director of Operations Excellence, Chief Strategy Implementation Officer, Director of Operational Planning."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Strategy and Operations",
                    "position_descriptor": "The Senior Manager leads cross-functional teams to implement strategic initiatives. They optimize operational processes, drive performance improvement, and ensure effective alignment with corporate goals.",
                    "related_job_titles": "Senior Strategy and Operations Manager, Manager of Strategic Initiatives, Senior Operations Performance Manager, Operations Improvement Senior Manager, Senior Strategy Implementation Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Strategy and Operations",
                    "position_descriptor": "The Manager oversees operational planning and execution. They collaborate with department heads, analyze business data, and implement initiatives to enhance operational efficiency.",
                    "related_job_titles": "Strategy and Operations Manager, Manager of Business Strategy, Manager of Operations Optimization, Strategy and Operations Optimization Manager, Manager of Operational Efficiency."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Strategy and Operations",
                    "position_descriptor": "The Team Leader supervises teams focused on strategic planning and operational excellence. They provide leadership, guide business strategy execution, and foster collaboration across departments.",
                    "related_job_titles": "Strategy and Operations Team Leader, Senior Strategic Planning Specialist, Lead Operations Analyst, Team Lead - Business Strategy, Operations Excellence Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Strategy and Operations",
                    "position_descriptor": "The Strategy and Operations Specialist focuses on strategic analysis and operational optimization. They analyze market data, identify growth opportunities, and collaborate with teams to implement process improvements.",
                    "related_job_titles": "Strategy and Operations Specialist, Strategic Analysis Specialist, Senior Operations Optimizer, Business Strategy Analyst, Advanced Operations Planner."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Strategy and Operations",
                    "position_descriptor": "The Senior Professional contributes to strategic planning and operational enhancements. They analyze business trends, develop growth strategies, and support the implementation of operational initiatives.",
                    "related_job_titles": "Senior Strategy and Operations Professional, Experienced Strategic Planner, Senior Operations Analyst, Senior Business Strategy Consultant, Operations Improvement Expert."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Strategy and Operations",
                    "position_descriptor": "The Experienced Professional in Strategy and Operations supports strategic projects and operational improvement efforts. They collaborate with teams to drive business growth and enhance operational effectiveness.",
                    "related_job_titles": "Experienced Strategy and Operations Professional, Strategic Initiatives Analyst, Operations Enhancement Specialist, Experienced Business Strategy Associate, Senior Operations Support officer."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Strategy and Operations",
                    "position_descriptor": "The Strategy and Operations Professional participates in strategic planning and operational initiatives. They analyze data, support process enhancements, and contribute to achieving business objectives.",
                    "related_job_titles": "Strategy and Operations Professional, Junior Strategic Planner, Operations Optimization Associate, Business Strategy Associate, Operations Analyst/officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Strategy and Operations",
                    "position_descriptor": "The Entry-level Professional learns about strategic planning and operational processes. They assist in data analysis, support project implementation, and gain exposure to business strategy.",
                    "related_job_titles": "Strategy and Operations Assistant, Junior Strategy Analyst/officer, Trainee Operations Planner, Strategy and Operations Support, Entry-level Business Strategist."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Strategy and Operations",
                    "position_descriptor": "The Support Associate provides administrative assistance to strategic and operational teams. They manage documentation, assist in coordinating projects, and support daily operations.",
                    "related_job_titles": "Strategy and Operations Support Associate, Strategy and Operations Administrative Assistant, Operations Documentation Associate, Strategy and Operations Coordinator, Administrative Support - Strategy and Operations."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Strategy and Operations",
                    "position_descriptor": "The Administrative Assistant in offers administrative support to strategic planning and operational teams. They manage office tasks, coordinate meetings, and assist in maintaining efficient operations.",
                    "related_job_titles": "Strategy and Operations Administrative Assistant, Strategy and Operations Office Coordinator, Operations Support Clerk, Admin Support Clerk, Strategy Office Administrator"
                }
            ]
        }
    },
    "Real Estate": {
        "General Real Estate Management": {
            "job_family_descriptor": " This job family entails overseeing, integrating, and strategizing the organization's comprehensive real estate requirements encompassing structures, assets, and facilities. Responsibilities encompass implementing real estate solutions, procuring locations, cultivating vendor relationships, lease negotiations, and divesting surplus properties.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Real Estate Officer",
                    "position_descriptor": "The Chief provides strategic leadership for the real estate division, overseeing property management, investment strategies, and operational efficiency to drive growth and value.",
                    "related_job_titles": "Chief Real Estate Officer, Chief Real Estate Strategist, Head of Real Estate Management, Chief Property Portfolio Officer, Chief Real Estate Strategist, Real Estate Executive Officer."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Real Estate Management",
                    "position_descriptor": "The Senior Director leads the strategic direction of real estate operations, overseeing property portfolios, investment analysis, and compliance with regulations.",
                    "related_job_titles": "Senior Director - Real Estate Management, Senior Real Estate Director, Director of Property Operations, Senior Property Management Director, Senior Real Estate Strategist, Senior Real Estate Operations Director."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Real Estate Management",
                    "position_descriptor": "The Director manages property operations, ensures optimal property performance, and collaborates with investment teams to execute real estate strategies.",
                    "related_job_titles": "Director - Real Estate Management, Real Estate Management Director, Director of Property Performance, Director of Property Operations, Director of Real Estate Strategy, Property Portfolio Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Real Estate Management:",
                    "position_descriptor": "The Senior Manager supervises property managers, oversees property performance, and works with teams to develop and implement real estate plans.",
                    "related_job_titles": "Senior Manager - Real Estate Management, Senior Real Estate Manager, Manager of Property Operations, Senior Property Portfolio Manager, Senior Real Estate Operations Manager, Senior Property Performance Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Real Estate Management",
                    "position_descriptor": "The Real Estate Manager manages property operations, coordinates maintenance and tenant relationships, and contributes to strategic decisions for property value enhancement.",
                    "related_job_titles": "Manager - Real Estate Management, Real Estate Manager, Manager of Property Performance, Property Operations Manager, Real Estate Operations Manager, Property Value Enhancement Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Real Estate Management",
                    "position_descriptor": "The Team Leader leads a team of professionals responsible for property management, tenant relations, and ensuring property compliance.",
                    "related_job_titles": "Team Leader, Real Estate Management Team Leader, Senior Property Specialist, Lead Property Manager, Team Lead - Property Operations, Property Compliance Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Real Estate Management",
                    "position_descriptor": "The Real Estate Management Specialist focuses on property performance, tenant relations, and compliance, optimizing operational efficiency for real estate portfolios.",
                    "related_job_titles": "Real Estate Management Specialist, Property Performance Specialist, Senior Property Analyst, Real Estate Operations Expert, Advanced Property Manager."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Real Estate Management",
                    "position_descriptor": "The Senior Professional contributes to property operations, tenant satisfaction, and supports property value growth through effective management.",
                    "related_job_titles": "Senior Real Estate Professional, Experienced Property Manager, Senior Property Analyst, Senior Real Estate Operations Analyst, Property Value Growth Consultant."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Real Estate Management",
                    "position_descriptor": "The Experienced Professional supports property operations, collaborates with property managers, and contributes to strategic property planning.",
                    "related_job_titles": "Experienced Real Estate Professional, Property Operations Analyst, Property Enhancement Specialist, Experienced Property Associate, Senior Property Support."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Real Estate Management",
                    "position_descriptor": "The Professional assists in property operations, tenant communications, and contributes to maintaining property performance and value.",
                    "related_job_titles": "Real Estate Management Professional, Junior Property Analyst, Property Operations Associate, Real Estate Operations Associate, Entry-level Property Manager."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Real Estate Management",
                    "position_descriptor": "The Entry-level Professional learns about property management, assists in tenant relations, and supports property operations.",
                    "related_job_titles": "Real Estate Management Assistant, Junior Property Associate, Trainee Property Manager, Property Support, Entry-level Real Estate Analyst."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Real Estate Management",
                    "position_descriptor": "The Support associate provides administrative assistance to property management teams, assists with documentation, and supports daily operations.",
                    "related_job_titles": "Real Estate Management Support Associate, Real Estate Administrative Assistant, Property Documentation Associate, Real Estate Coordinator, Administrative Support - Property Management."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Real Estate Management",
                    "position_descriptor": "The Administrative Assistant offers administrative support to property management operations, assists in coordination, and helps maintain efficient property management processes.",
                    "related_job_titles": "Administrative Assistant, Real Estate Management Administrative Assistant, Real Estate Office Coordinator, Property Management Assistant, Administrative Clerk - Property Management, Real Estate Office Administrator, Real Estate Administration Clerk"
                }
            ]
        },
        "Property Management": {
            "job_family_descriptor": "This job family involves supervising commercial, industrial, and/or residential real estate. Duties encompass lease management, marketing, acting as an intermediary between landlord and tenant, rent collection, addressing maintenance concerns, contract administration, and overseeing property enhancement initiatives.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Property Officer (CPO)",
                    "position_descriptor": " The Chief Property Officer oversees the management and operations of the organization's real estate portfolio, ensuring optimal tenant relations, maintenance, and value enhancement.",
                    "related_job_titles": " Chief Property Strategist, Head of Property Operations, Chief Tenant Relations Officer, Chief Property Portfolio Manager, Property Management Executive."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Property Management",
                    "position_descriptor": " The Senior Director of Property Management leads the strategic direction of property operations, tenant relations, and maintenance to ensure the overall success of the real estate portfolio.",
                    "related_job_titles": " Senior Property Director, Director of Tenant Relations, Senior Property Operations Director, Senior Property Maintenance Manager, Senior Portfolio Manager."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Property Management",
                    "position_descriptor": " The Director of Property Management manages property operations, tenant satisfaction, and collaborates with teams to implement effective maintenance and leasing strategies.",
                    "related_job_titles": " Property Management Director, Director of Tenant Services, Director of Property Operations, Director of Leasing and Maintenance, Property Portfolio Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Property Management",
                    "position_descriptor": " The Senior Manager in Property Management supervises property managers, oversees tenant relationships, and ensures maintenance and compliance with regulations.",
                    "related_job_titles": " Senior Property Manager, Manager of Tenant Relations, Senior Property Operations Manager, Senior Maintenance Manager, Senior Leasing Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Property Management",
                    "position_descriptor": " The Property Manager oversees day-to-day property operations, manages tenant relations, and coordinates maintenance and repairs for a portfolio of properties.",
                    "related_job_titles": " Property Manager, Manager of Tenant Services, Property Operations Manager, Maintenance Coordinator, Leasing Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Property Management",
                    "position_descriptor": " The Team Leader in Property Management leads a team of professionals focused on tenant relations, maintenance coordination, and property compliance.",
                    "related_job_titles": " Property Management Team Leader, Senior Tenant Relations Specialist, Lead Property Coordinator, Team Lead - Tenant Services, Property Compliance Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Property Management",
                    "position_descriptor": " The Property Management Specialist specializes in tenant relations, maintenance coordination, and property compliance to ensure operational efficiency and tenant satisfaction.",
                    "related_job_titles": " Property Management Specialist, Tenant Relations Specialist, Senior Property Analyst, Maintenance Coordinator, Advanced Property Coordinator."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Property Management",
                    "position_descriptor": " The Senior Professional in Property Management contributes to tenant satisfaction, property maintenance, and supports value enhancement through effective management.",
                    "related_job_titles": " Senior Property Professional, Experienced Tenant Relations Specialist, Senior Property Analyst, Senior Property Coordinator, Portfolio Management Consultant."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Property Management",
                    "position_descriptor": " The Experienced Professional in Property Management supports property operations, assists property managers, and contributes to maintaining positive tenant relationships.",
                    "related_job_titles": " Experienced Property Professional, Tenant Relations Specialist, Property Analyst, Property Coordinator, Senior Property Support."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Property Management",
                    "position_descriptor": " The Property Management Professional assists in tenant communication, coordinates maintenance tasks, and supports property managers in ensuring smooth operations.",
                    "related_job_titles": " Property Management Professional, Junior Tenant Relations Specialist, Property Analyst, Property Coordinator, Entry-level Property Manager."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Property Management",
                    "position_descriptor": " The Entry-level Professional in Property Management learns about property operations, assists in tenant interactions, and supports property management tasks.",
                    "related_job_titles": " Property Management Assistant, Junior Tenant Coordinator, Trainee Property Manager, Property Support, Entry-level Property Coordinator."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Property Management",
                    "position_descriptor": " The Support Associate in Property Management provides administrative assistance to property management teams, coordinates maintenance requests, and supports property operations.",
                    "related_job_titles": " Property Management Support Associate, Property Administrative Assistant, Tenant Services Associate, Maintenance Coordinator, Administrative Support - Property Management."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Property Management",
                    "position_descriptor": " The Administrative Assistant in Property Management offers administrative support to property management operations, assists in tenant communications, and helps streamline property-related processes.",
                    "related_job_titles": " Property Management Administrative Assistant, Property Office Coordinator, Tenant Relations Assistant, Administrative Support - Property Management, Property Office Administrator, Admin Clerk"
                }
            ]
        },
        "Real Estate Procurement, Strategy & Development": {
            "job_family_descriptor": "This job family is accountable for the real estate growth, enhancement, and modernization endeavors. Tasks encompass formulating acquisition strategies, evaluating project costs, directing project progression, identifying investment and financial objective-aligned acquisition and divestiture prospects, and potentially specializing in sectors like housing, retail expansion, or university property planning and development.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Real Estate Strategy Officer",
                    "position_descriptor": " The Chief Real Estate Strategy Officer leads the strategic planning and development of the organization's real estate portfolio, overseeing property acquisitions, development initiatives, and investment strategies.",
                    "related_job_titles": " Chief Property Growth Executive, Head of Real Estate Expansion, Chief Acquisitions Strategist, Chief Real Estate Development Visionary, Real Estate Growth Leader."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Real Estate Strategy & Development",
                    "position_descriptor": " The Senior Director of Real Estate Strategy & Development guides the organization's real estate growth by overseeing property acquisitions, development projects, and implementing strategic plans.",
                    "related_job_titles": " Senior Real Estate Expansion Director, Director of Property Acquisitions, Senior Development Strategist, Senior Real Estate Visionary, Senior Property Growth Director."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Real Estate Strategy & Development",
                    "position_descriptor": " The Director of Real Estate Strategy & Development manages property acquisitions, development opportunities, and strategic planning for the organization's real estate expansion.",
                    "related_job_titles": " Development Director, Acquisitions Director, Real Estate Visionary, Real Estate Growth Director, Property Expansion Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Real Estate Strategy & Development",
                    "position_descriptor": " The Senior Manager in Real Estate Strategy & Development leads teams in identifying and evaluating real estate opportunities, coordinating acquisitions, and developing growth strategies.",
                    "related_job_titles": " Senior Development Manager, Senior Acquisitions Manager, Senior Growth Manager, Senior Real Estate Strategy Manager, Senior Property Expansion Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Real Estate Strategy & Development",
                    "position_descriptor": " The Real Estate Strategy & Development Manager identifies property acquisition opportunities, assesses market trends, and collaborates on the organization's real estate growth strategy.",
                    "related_job_titles": " Development Manager, Acquisitions Manager, Growth Manager, Real Estate Strategy Manager, Property Expansion Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Real Estate Strategy & Development",
                    "position_descriptor": " The Team Leader in Real Estate Strategy & Development leads professionals responsible for identifying property opportunities, coordinating acquisitions, and executing development projects.",
                    "related_job_titles": " Expansion Team Leader, Senior Property Specialist, Lead Development Coordinator, Team Lead - Acquisitions, Property Growth Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Real Estate Strategy & Development",
                    "position_descriptor": " The Real Estate Strategy & Development Specialist focuses on market analysis, property sourcing, and development project coordination to support strategic real estate growth.",
                    "related_job_titles": " Growth Specialist, Property Visionary, Senior Property Analyst, Development Project Coordinator, Advanced Real Estate Strategist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Real Estate Strategy & Development",
                    "position_descriptor": " The Senior Professional in Real Estate Strategy & Development contributes to property sourcing, market research, and supports the execution of real estate development plans.",
                    "related_job_titles": " Senior Growth Professional, Experienced Acquisitions Specialist, Senior Property Analyst, Senior Development Coordinator, Real Estate Visionary."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Real Estate Strategy & Development",
                    "position_descriptor": " The Experienced Professional in Real Estate Strategy & Development supports real estate growth initiatives, conducts property research, and assists in development project coordination.",
                    "related_job_titles": " Experienced Growth Professional, Acquisitions Specialist, Real Estate Analyst, Development Coordinator, Senior Property Expansion Associate."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Real Estate Strategy & Development",
                    "position_descriptor": " The Real Estate Strategy & Development Professional assists in property sourcing, market analysis, and contributes to the execution of real estate development strategies.",
                    "related_job_titles": " Growth Professional, Junior Acquisitions Specialist, Property Analyst, Development Coordinator, Entry-level Property Strategist."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Real Estate Strategy & Development",
                    "position_descriptor": " The Entry-level Professional in Real Estate Strategy & Development learns about property acquisition processes, assists in market research, and supports development project coordination.",
                    "related_job_titles": " Growth Assistant, Junior Property Analyst, Trainee Development Coordinator, Property Expansion Support, Entry-level Acquisitions Coordinator."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Real Estate Strategy & Development",
                    "position_descriptor": " The Support Associate in Real Estate Strategy & Development provides administrative assistance to property acquisition teams, supports documentation, and contributes to project coordination.",
                    "related_job_titles": " Expansion Support Associate, Administrative Assistant - Real Estate, Property Acquisitions Associate, Development Project Support, Administrative Support - Property Strategy."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Real Estate Strategy & Development",
                    "position_descriptor": " The Administrative Assistant in Real Estate Strategy & Development offers administrative support to property acquisition and development teams, assists in coordination, and helps streamline processes.",
                    "related_job_titles": " Real Estate Administrative Assistant, Property Support Assistant, Property Growth Assistant, Administrative Support - Real Estate, Property Clerk, Administrative Clerk "
                }
            ]
        }
    },
    "Financial Technology (FinTech)": {
        "Data Science and Analytics": {
            "job_family_descriptor": "This job family focuses on roles related to data analysis, data interpretation, and deriving insights from data. It includes positions that involve data modeling, statistical analysis, machine learning, and data visualization within FinTech",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Data Officer  / Head of Data Science",
                    "position_descriptor": " The top executive overseeing all data-related functions within the Fintech firm and sets the data strategy, manages data teams, and ensures data-driven decision-making.",
                    "related_job_titles": "Chief Data Officer (CDO): Chief Analytics Officer, Chief Data Strategist, Data Science Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director - Data Science and Analytics",
                    "position_descriptor": "The Senior Director guides data science efforts. They lead data teams, manage data projects, and collaborate with stakeholders to deliver actionable insights and data-driven solutions.",
                    "related_job_titles": " Data Operations Director, Analytics Solutions Director, Lead Data Scientist"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Data Science / Analytics Director",
                    "position_descriptor": " Directors provide strategic leadership for data science and analytics. They manage teams, define analytics strategies, and oversee complex data projects.",
                    "related_job_titles": "Senior Data Science Manager, Analytics Solutions Manager, Senior Data Analyst Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Data Science and Analytics",
                    "position_descriptor": "The Manager supports data analysis initiatives. They manage data projects, collaborate with cross-functional teams, and ensure the successful delivery of actionable insights.",
                    "related_job_titles": "Manager - Data Science, Data Operations Manager, Analytics Implementation Manager, Data Insights Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Data Scientist / Senior Analyst",
                    "position_descriptor": " Senior Data Scientists are experienced professionals who lead advanced analytics projects and develop predictive models, provide insights, and mentor junior analysts.",
                    "related_job_titles": "Data Science Team Lead, Analytics Team Supervisor, Data Insights Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Data Scientist / Data Analyst",
                    "position_descriptor": " Data Scientists work on data analysis, modelling, and reporting and also design experiments, mine data, and create data-driven solutions.",
                    "related_job_titles": " Data Science Expert, Analytics Specialist, Data Analytics Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Data Engineer / Data Analytics Engineer",
                    "position_descriptor": " Data Engineers focus on data infrastructure and architecture building data pipelines, design databases, and ensure data accessibility.",
                    "related_job_titles": "Senior Data Scientist, Lead Data Analyst, Principal Data Engineer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Data Analyst / Junior Data Scientist",
                    "position_descriptor": " Data Analysts conduct data investigations, generate reports, and contribute to analytics projects and support in data analysis and reporting.",
                    "related_job_titles": "Data Analytics Project Coordinator, Senior Data Analyst"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Data Specialist / Data Associate",
                    "position_descriptor": " Data Specialists focus on specific data-related tasks, such as data cleaning and processing and assist in data collection and maintain data quality.",
                    "related_job_titles": "Data Analyst, Analytics Engineer, Technical Data Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Data Technician / Data Support Specialist",
                    "position_descriptor": " Data Technicians manage data entry and data support tasks, data processing and provide technical support.",
                    "related_job_titles": "Junior Data Scientist, Trainee Data Analyst, Data Science Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Data Support Associate/ Junior Data Technician",
                    "position_descriptor": " Data Suppport Associates are typically entry-level positions for those gaining experience in data-related roles and assist with basic data tasks, data entry, and data processing.",
                    "related_job_titles": "Data Operations Associate, Technical Analytics Assistant, Data Helpdesk"
                }
            ]
        },
        "Product Management": {
            "job_family_descriptor": "Job family responsible for managing innovative financial products and services that cater to the evolving needs of consumers, businesses, and the financial industry.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Product Officer / Head of Product Management",
                    "position_descriptor": " The top executive responsible for the entire product strategy and vision, leading the development of innovative Fintech products and managing product teams.",
                    "related_job_titles": "Chief Product Officer, Head of Product Management, Chief Product Strategist, Lead Product Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Vice President of Product / Director of Product Management",
                    "position_descriptor": " Senior leaders responsible for defining and executing product strategies, managing product portfolios, and overseeing product managers.",
                    "related_job_titles": "VP of Product, Director of Product Management, Product Director, Senior Product Manager, Product Portfolio Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Product Manager / Product Strategist",
                    "position_descriptor": " Experienced product professionals who lead the development of major Fintech products, set product roadmaps, and manage cross-functional teams.",
                    "related_job_titles": "Senior Product Manager, Product Strategist, Senior Product Owner, Lead Product Manager, Principal Product Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Product Manager",
                    "position_descriptor": " Product Managers are responsible for the planning, development, and delivery of Fintech products, ensuring alignment with business goals and user needs.",
                    "related_job_titles": "Product Manager, Product Owner, Associate Product Manager, Product Lead"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Associate Product Manager",
                    "position_descriptor": " Junior professionals who assist Product Managers in various product-related tasks, including market research, feature development, and project management.",
                    "related_job_titles": "Associate Product Manager, Junior Product Owner, Product Analyst, Product Coordinator, Assistant Product Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Product Analyst / Market Research Analyst",
                    "position_descriptor": " Analysts specializing in market research, user behavior analysis, and product data to provide insights for product development decisions.",
                    "related_job_titles": "Product Analyst, Market Research Analyst, Product Data Analyst, User Insights Analyst, Product Research Specialist"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Product Coordinator",
                    "position_descriptor": " Coordinators who support the product team in various administrative and coordination tasks, ensuring smooth product development processes.",
                    "related_job_titles": "Product Coordinator, Product Support Coordinator, Product Planning Coordinator"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Product Quality Assurance (QA)",
                    "position_descriptor": " Professionals responsible for testing and quality assurance to ensure Fintech products meet quality standards and are free from defects.",
                    "related_job_titles": "Product QA Tester, Testing Assistant, Quality Assurance Analyst, Product Tester, QA Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Product Assistant",
                    "position_descriptor": " Entry-level positions typically for individuals gaining practical experience in the product management field, assisting with basic product-related tasks.",
                    "related_job_titles": "Product Development Assistant, Junior Product Assistant, Entry-Level Product Specialist"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Product Support Specialist / Customer Support Analyst",
                    "position_descriptor": " Specialists who provide customer support for Fintech products, addressing inquiries and issues, and collecting user feedback.",
                    "related_job_titles": "Product Support Specialist, Customer Support Analyst, Product Customer Care Specialist, Product Support Analyst, User Support Specialist"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Product Documentation Assistant",
                    "position_descriptor": "Admin Assistants who create product-related content, including user guides, documentation, and marketing materials to support product launches.",
                    "related_job_titles": "Product Content Writer, Product Documentation Specialist, Product Writer, Technical Writer, Documentation Specialist"
                }
            ]
        },
        "Product Development": {
            "job_family_descriptor": "This job family specialises on creating new Fintech products or improving existing ones. They analyze market trends, assess customer needs, and design firm offerings.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Product Development Officer",
                    "position_descriptor": "The Chief leads the product development division, overseeing the strategic creation and enhancement of Fintech products. They ensure market analysis, collaborate with underwriting and marketing teams, and implement strategies to optimize product offerings and competitiveness.",
                    "related_job_titles": "Chief Product Officer, Head of Product Innovation, Chief Product Strategist, Product Development Executive, Product Strategy Leader, Chief Innovation Officer."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Product Development",
                    "position_descriptor": "The Senior Director provides leadership to the product development team. They guide the creation of Fintech products, implement best practices, and ensure alignment with customer needs. The Senior Director monitors market trends, collaborates with underwriting and sales teams, and contributes to the development of product strategies.",
                    "related_job_titles": "Senior Product Development Director, Director of Product Innovation, Chief Product Strategist, Head of Product Design, Senior Product Management Director, Senior Director of Product Strategy."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Product Development",
                    "position_descriptor": "The Director manages the development and enhancement of Fintech products. They oversee market research, review product specifications, and ensure compliance with regulatory requirements. The Director collaborates with underwriters, actuaries, and marketing teams to ensure successful product launches.",
                    "related_job_titles": "Product Development Director, Director of Product Enhancement, Director of Product Strategy, Chief Product Architect, Director of Product Management, Product Design Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Product Development",
                    "position_descriptor": "The Senior Manager leads a team of product developers. They review product concepts, provide technical guidance, and ensure effective product implementation. The Senior Manager fosters innovation and collaborates with cross-functional teams to enhance product offerings.",
                    "related_job_titles": "Senior Product Development Manager, Manager of Product Innovation, Senior Product Implementation Manager, Product Innovation Senior Manager, Senior Product Enhancement Manager, Senior Manager of Product Design."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Product Development",
                    "position_descriptor": "The Product Development Manager oversees product development operations. They review product proposals, assess market demand, and contribute to the design of Fintech products. The Manager collaborates with product analysts, underwriters, and marketing teams to bring products to market.",
                    "related_job_titles": "Product Development Manager, Manager of Product Design, Manager of Product Enhancement, Product Design and Innovation Manager, Manager of Product Strategy, Product Innovation Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Product Development",
                    "position_descriptor": "The Team Leader supervises a team of product development professionals. They guide product creation, provide technical mentorship, and ensure alignment with customer preferences. The Team Leader fosters a collaborative environment and maintains effective communication.",
                    "related_job_titles": "Product Development Team Leader, Senior Product Specialist, Lead Product Developer, Team Lead - Product Innovation, Product Creation Supervisor, Product Design Team Leader."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Product Development",
                    "position_descriptor": "The Product Development Specialist handles specialized aspects of Fintech product creation. They analyze market trends, assess customer needs, and collaborate with underwriters and marketing teams to develop competitive product offerings. The Specialist provides expertise in product innovation.",
                    "related_job_titles": "Product Development Specialist, Product Enhancement Specialist, Senior Product Analyst, Product Innovation Expert, Advanced Product Developer, Product Design Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Product Development",
                    "position_descriptor": "The Senior Professional in Product Development contributes to Fintech product creation and enhancement. They review market research, assess customer feedback, and support the design of new product features. The Senior Professional ensures compliance with regulatory guidelines and contributes to product strategy.",
                    "related_job_titles": "Senior Product Developer, Experienced Product Analyst, Senior Product Designer, Senior Product Innovator, Product Strategy Consultant, Senior Product Design Analyst."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Product Development",
                    "position_descriptor": "The Experienced Professional in Product Development supports Fintech product creation and refinement. They review market data, assist in product design, and collaborate with cross-functional teams to bring products to market.",
                    "related_job_titles": "Experienced Product Developer, Product Analyst, Product Design Specialist, Product Enhancement Associate, Senior Product Support, Experienced Product Design Analyst."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Product Development",
                    "position_descriptor": "The Product Development Professional assists in Fintech product creation and enhancement. They review market analysis, collaborate with underwriters and marketing teams, and contribute to the design of competitive product offerings.",
                    "related_job_titles": "Product Developer, Junior Product Analyst, Product Design Associate, Product Enhancement Professional, Entry-level Product Innovator, Junior Product Designer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Product Development",
                    "position_descriptor": "The Entry-level Professional gains exposure to Fintech product creation processes. They assist in market research, support product development tasks, and learn about product design and customer preferences.",
                    "related_job_titles": "Product Development Assistant, Junior Product Associate, Trainee Product Developer, Product Support, Entry-level Product Designer, Entry-level Product Analyst."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Product Development",
                    "position_descriptor": "The Support Associate provides administrative assistance to product development teams. They manage documentation, assist in coordinating product development activities, and support operational tasks.",
                    "related_job_titles": "Product Development Support Associate, Product Administrative Assistant, Product Documentation Associate, Product Coordinator, Administrative Support - Product Development, Product Support Associate."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Product Development",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the product development division. They manage office tasks, coordinate meetings, and assist in maintaining efficient product development operations.",
                    "related_job_titles": "Product Development Administrative Assistant, Product Office Clerk, Product Operations Admin Assistant, Administrative Support - Product Development, Product Office Administrator, Administrative Assistant - Product Innovation."
                }
            ]
        },
        "Commercial": {
            "job_family_descriptor": "Job Family that focusses on revenue generation, business development, partnerships, and commercial strategies aimed at contributing to the financial growth and sustainability of fintech companies by driving sales, forming partnerships, and managing revenue-related functions.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Commercial Officer / Head of Commercial Operations",
                    "position_descriptor": " The highest-ranking executive responsible for the overall commercial strategy, revenue generation, and business growth within the Fintech company.",
                    "related_job_titles": "Chief Commercial Officer (CCO), Head of Commercial Operations, Chief Revenue Officer (CRO), Senior Vice President of Commercial, Commercial Strategy Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Vice President of Commercial / Commercial Director",
                    "position_descriptor": " Senior leaders who oversee and direct the commercial functions of the organization, including sales, partnerships, and business development.",
                    "related_job_titles": "VP of Commercial, Commercial Director, Business Development Director, Head of Sales, Senior Business Development Manager"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Commercial Manager / Commercial Strategy Director",
                    "position_descriptor": " Experienced professionals who lead the development and execution of commercial strategies, drive revenue growth, and manage cross-functional teams.",
                    "related_job_titles": "Senior Commercial Manager, Commercial Strategy Director, Senior Business Development Director, Commercial Strategy Manager, Commercial Growth Lead"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Commercial Manager / Business Development Manager",
                    "position_descriptor": " Professionals responsible for sales, partnerships, and revenue generation within the Fintech sector, ensuring alignment with business goals.",
                    "related_job_titles": "Commercial Manager, Business Development Manager, Sales Manager, Commercial Partnerships Manager, Revenue Generation Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Associate Commercial Manager / Junior Business Development Manager",
                    "position_descriptor": " Junior-level contributors who support commercial managers in sales, partnerships, and business development activities.",
                    "related_job_titles": "Associate Commercial Manager, Junior Business Development Manager, Junior Sales Manager, Business Development Associate, Sales Coordinator"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Commercial Analyst / Sales Analyst",
                    "position_descriptor": " Analysts specializing in market research, sales analysis, and business data to provide insights for commercial decision-making.",
                    "related_job_titles": "Commercial Analyst, Sales Analyst, Market Research Analyst, Business Data Analyst, Sales Insights Analyst"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Commercial Coordinator / Sales Coordinator",
                    "position_descriptor": " Coordinators who assist the commercial team with various administrative and coordination tasks, ensuring smooth commercial processes.",
                    "related_job_titles": "Commercial Coordinator, Sales Coordinator, Business Development Coordinator, Commercial Support Coordinator, Sales Operations Coordinator, Commercial Officer, Sales Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Commercial Quality Assurance (QA)",
                    "position_descriptor": " Professionals responsible for testing and quality assurance to ensure commercial processes and systems meet quality standards.",
                    "related_job_titles": "Commercial QA Officer, Quality Assurance Analyst, QA Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Commercial / Sales Assistant",
                    "position_descriptor": " Entry-level positions typically for individuals gaining practical experience in commercial roles, assisting with basic commercial tasks.",
                    "related_job_titles": "Sales Assistant, Commercial Assistant, Business Development Assistant, Sales Representatives"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Commercial Support Specialist / Customer Support Analyst",
                    "position_descriptor": " Specialists who provide customer support for commercial activities, addressing inquiries and issues related to sales and partnerships.",
                    "related_job_titles": "Commercial Support Specialist, Customer Support Analyst, Sales Customer Care Specialist, Commercial Support Analyst, Business Support Specialist"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Commercial Documentation Assistant",
                    "position_descriptor": " Admin Assistants who create commercial-related content, including sales collateral, documentation, and marketing materials to support commercial efforts.",
                    "related_job_titles": "Sales Administrators, Business Development Admin Assistant"
                }
            ]
        },
        "Payment Operations": {
            "job_family_descriptor": "Job Family encompasses roles and functions related to the management, processing, and security of financial transactions and payment methods to ensuree smooth, secure, and efficient functioning of payment systems, which are central to the fintech industry",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Head of Payment Operations",
                    "position_descriptor": " The highest-ranking executive responsible for overseeing all aspects of payment operations, ensuring seamless transactions and financial security within the Fintech company.",
                    "related_job_titles": "Chief Payment Operations Officer, Head of Payment Operations, Chief Payments Executive, Vice President of Payments"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Payment Operations Director",
                    "position_descriptor": " Senior leaders who manage and direct payment operations, including payment processing, settlements, and fraud prevention, while overseeing payment teams and strategies.",
                    "related_job_titles": "Payment Operations Director, Director of Payment Processing, Senior Payments Manager, Payment Operations Strategist"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Payment Operations Manager / Operations Strategy Director",
                    "position_descriptor": " Experienced professionals who lead payment operations, define operational strategies, manage cross-functional teams, and implement fraud prevention measures.",
                    "related_job_titles": "Senior Payment Operations Manager, Operations Strategy Director, Senior Payments Director, Payment Strategy Manager, Payment Operations Lead"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Payment Operations Manager / Payment Processing Manager",
                    "position_descriptor": " Professionals responsible for overseeing payment processing, settlements, and operational efficiency to ensure accurate and timely financial transactions.",
                    "related_job_titles": "Payment Operations Manager, Payment Processing Manager, Operations Manager, Settlements Manager, Payment Operations Specialist"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Associate Payment Operations Manager / Junior Payment Processing Manager",
                    "position_descriptor": " Junior-level contributors who assist Payment Operations Managers in tasks related to payment processing, settlements, and operational coordination.",
                    "related_job_titles": "Team Lead Payment Operations, Associate Payment Operations Manager, Junior Payment Processing Manager, Junior Operations Manager, Payment Processing Associate, Payment Coordinator"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Payment Operations Analyst / Payment Processing Analyst",
                    "position_descriptor": " Analysts specializing in payment data analysis, transaction monitoring, and fraud detection to ensure the integrity of payment operations.",
                    "related_job_titles": "Payment Operations Analyst, Payment Processing Analyst, Operations Analyst, Payment Data Analyst, Payment Fraud Analyst"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Payment Operations Coordinator / Operations Support Coordinator",
                    "position_descriptor": " Coordinators who provide administrative and coordination support to payment operations teams, ensuring smooth transaction processes.",
                    "related_job_titles": "Payment Operations Coordinator, Operations Support Coordinator, Operations Coordinator, Payment Support Coordinator"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Payment Quality Assurance (QA) Officer",
                    "position_descriptor": " Professionals responsible for testing and quality assurance to ensure the accuracy and security of payment transactions.",
                    "related_job_titles": "Payment QA Officer, Payments Processing Officer, Payment QA Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Payment Operations / Processing Assistant",
                    "position_descriptor": " Entry-level operational positions for individuals gaining practical experience in payment operations, assisting with basic operational tasks and support.",
                    "related_job_titles": "Payment Operations Assistant, Junior Payment Processing Assistant, Payment Processing Assistant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Payment Support Specialist / Customer Support Analyst",
                    "position_descriptor": " Specialists who provide customer support for payment-related inquiries and issues, assisting users with payment concerns.",
                    "related_job_titles": "Payment Support Specialist, Customer Support Analyst, Payment Customer Care Specialist, Support Analyst, Payment User Support Specialist"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Payment Documentation Admin Assistant",
                    "position_descriptor": " Admin assistant who create payment-related content, including documentation, guidelines, and user materials to support payment operations.",
                    "related_job_titles": "Payment Documentation Assistant, Admin Assistant"
                }
            ]
        },
        "Risk Management": {
            "job_family_descriptor": "Risk Management roles are dedicated to identifying, assessing, and mitigating risks that could impact the bank's operations, financial stability, and reputation. Professionals in this job family develop risk management frameworks, policies, and procedures to ensure compliance with regulatory requirements and to safeguard the bank's assets.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Chief Risk Officer responsible for overseeing the Fintech's risk management framework. They develop and implement risk strategies, policies, and processes to identify, assess, and mitigate risks across the organization.",
                    "related_job_titles": "Chief Risk Officer (CRO): Chief Risk Officer, Head of Risk Management, Risk Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director",
                    "position_descriptor": "The Senior Director of Risk Management leads risk management efforts. They develop risk assessment methodologies, oversee risk reporting, and provide guidance on risk-related matters to senior leadership.",
                    "related_job_titles": "Senior Director - Risk Management: Risk Strategy Director, Senior Risk Management Director, Director of Risk Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director",
                    "position_descriptor": "The Director of Risk Management manages risk assessment and control functions. They collaborate with business units, analyze risk exposure, and recommend measures to ensure compliance with regulatory requirements.",
                    "related_job_titles": "Director - Risk Management: Risk Operations Director, Risk Solutions Director, Chief Risk Officer (CRO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "The Senior Manager in Risk Management oversees risk mitigation activities. They develop risk management plans, assess the effectiveness of controls, and implement risk reduction strategies.",
                    "related_job_titles": "Senior Manager - Risk Management: Senior Risk Manager, Risk Solutions Manager, Senior Risk Operations Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": "The Risk Management Manager supports risk assessment and control processes. They work closely with business units, identify potential risks, and help design and implement risk management solutions.",
                    "related_job_titles": "Manager - Risk Management: Risk Operations Manager, Risk Implementation Manager, Risk Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals)",
                    "position_descriptor": "The Team Leader in Risk Management supervises risk analysts. They coordinate risk assessment activities, provide guidance on risk identification, and contribute to the development of risk mitigation strategies.",
                    "related_job_titles": "Team Leader (Professionals) - Risk Management: Risk Team Lead, Risk Department Supervisor, Senior Risk Analyst"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional",
                    "position_descriptor": "The Risk Management Specialist provides expertise in identifying and analyzing risks. They develop risk models, conduct stress tests, and contribute to the enhancement of risk management frameworks.",
                    "related_job_titles": "Specialist Professional - Risk Management: Risk Expert, Risk Specialist, Compliance and Risk Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional",
                    "position_descriptor": "The Senior Professional in Risk Management supports risk assessment efforts. They analyze risk data, assist in developing risk management strategies, and collaborate with cross-functional teams.",
                    "related_job_titles": "Senior Professional - Risk Management: Senior Risk Analyst, Lead Compliance Specialist, Principal Risk Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional",
                    "position_descriptor": "The Experienced Professional in Risk Management contributes to risk identification and analysis. They assist in assessing risk exposure, monitor risk metrics, and support the implementation of risk mitigation measures.",
                    "related_job_titles": "Experienced Professional - Risk Management: Risk Solutions Specialist, Compliance Project Coordinator, Senior Risk Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional",
                    "position_descriptor": "The Risk Management Professional supports risk assessment activities. They assist in data collection, contribute to risk analysis, and help maintain risk management documentation.",
                    "related_job_titles": "Professional - Risk Management: Compliance Analyst, Risk Associate, Compliance and Risk Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional",
                    "position_descriptor": "The Entry-level Professional in Risk Management gains exposure to risk assessment processes. They assist in data entry, support risk analysis, and contribute to the development of risk reports.",
                    "related_job_titles": "Entry Professional - Risk Management: Junior Compliance Analyst, Trainee Risk Associate, Risk Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate",
                    "position_descriptor": "The Support Associate in Risk Management provides administrative assistance to risk management teams. They manage documentation, assist in coordinating risk assessments, and support risk reporting.",
                    "related_job_titles": "Support Associate - Risk Management: Risk Operations Associate, Compliance Support Assistant, Risk Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": "The Administrative Assistant in Risk Management offers administrative support to the risk management division. They manage office tasks, coordinate meetings, and provide assistance to ensure efficient risk management operations.",
                    "related_job_titles": "Administrative Assistant - Risk Management: Risk Office Assistant, Compliance Admin Support, Risk Coordinator"
                }
            ]
        }
    }
}