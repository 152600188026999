import React from 'react';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts'
import { styled } from "@mui/material";
import { WR_GRAPH_COLORS } from '../../../configs/colors';
import PageLoader from '../../../components/Loaders/PageLoader';
import { useSelector } from 'react-redux';

const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)",
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))

export default function DepartmentCompensation(props) {
    //const { data } = props
    const { grade_salaries, loading, error, apiFilters, company_name } = useSelector(state => state.market_data)
    const data = grade_salaries;

    if (loading) return <PageLoader />


    const state = {
        series: [
            { name: 'Market 25th', data: data['Market 25th'] },
            { name: 'Market 50th', data: data['Market 50th'] },
            { name: 'Market 75th', data: data['Market 75th'] },
            { name: `Grade Avg. ${company_name}`, data: data['Grade Average SGI'] }
        ],
        options: {
            chart: {
                type: 'line',
                height: 350,
                toolbar: { show: false }
            },
            grid: { show: false },
            xaxis: {
                categories: data['Grade'],
                labels: {
                    show: true,
                    rotate: -45,
                },
                axisBorder: { show: true },
                title: {
                    text: "Job Grade"
                },
            },
            colors: WR_GRAPH_COLORS,
            stroke: {
                width: 2, // Adjust the line width as needed
                curve: "smooth", // Use smooth curve
            },
            dataLabels: { enabled: false },
            yaxis: {
                title: {
                    text: "Salaries"
                },
                labels: {
                    show: true,
                    formatter: function (value) {
                        // Convert the value to a string with commas
                        return value.toLocaleString();
                    },
                    axisBorder: { show: true }
                },
                axisBorder: { show: true }
            },
            fill: { opacity: 1 },
            legend: { show: true }
        }
    }

    return (
        <SingleCard>
            <h2 className='text-xl font-semibold text-gray-600'>Comparison of {company_name} Salaries with Market Data</h2>
            <p className='text-sm mt-2 text-gray-500'>per Job Grade (level)</p>
            <Chart series={state.series} options={state.options} type="line" height={400} />
        </SingleCard>
    );
}
