import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setApiFilters } from '../../../../redux/market_data';

export default function HFilters() {
    const dispatch = useDispatch()
    const { apiFilters } = useSelector(state => state.market_data)

    const onChangeDt = (e) => {
        dispatch(setApiFilters({ ...apiFilters, [e.target.name]: e.target.value }))
    }


    return (
        <Card className="p-4 flex items-center space-x-4" sx={{ border: '1px solid #eee' }}>

            <div>
                <select
                    onChange={(e) => onChangeDt(e)}
                    name="compensation_type"
                    value={apiFilters.compensation_type}
                    className="p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option defaultValue value={"annual_basic_salary"}>Compensation : Base Salary</option>
                    <option value={"total_guaranteed_cash"}>Compensation : Total Guaranteed Cash</option>
                </select>
            </div>

            <div>
                <select
                    onChange={(e) => onChangeDt(e)}
                    name="frequency"
                    value={apiFilters.frequency}
                    className="p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option defaultValue value={"annual"}>Annual</option>
                    <option value={"monthly"}>Monthly</option>
                </select>
            </div>

        </Card>
    );
}
