import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function ShortTermIncentives(props) {
  const { offering,
    division_of_profits_bonus, division_of_profits_bonus_offering,
    payment_differentiation_bonus, payment_differentiation_bonus_offering, annual_stm_incentives } = props.data;
  console.log(division_of_profits_bonus_offering)
  console.log("data in short term incentives", props?.data);
  return (
    <div>
      <BlockHeading text="1. Short-Term Variable Incentives" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Short-Term Incentives"}
          footer={getFooter(offering?.total)}
          note={getNote()}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="250"
          />
        </BCard>

        <BCard
          title={"Bonus Differentiation"}
          footer={getFooter(payment_differentiation_bonus?.total)}
          note={getNote()}
        >
          <PieChart
            categories={payment_differentiation_bonus?.categories}
            series={payment_differentiation_bonus?.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Target Variable Bonus (% of annual basic salary)"}
          footer={getFooter(division_of_profits_bonus?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={payment_differentiation_bonus_offering?.data?.categories}
            series={payment_differentiation_bonus_offering?.data?.data}
            height="auto"
          />
        </BCard>
        <BCard
          title={"When do annual short term incentives (bonus) payments take place"}
          footer={getFooter(annual_stm_incentives?.total)}
          note={getNote()}
        >
          <PieChart
            categories={annual_stm_incentives?.categories}
            series={annual_stm_incentives?.data}
            height="250"
          />
        </BCard>



      </div>


      <div>
        <BCard
          title={"Division of Profits"}
          footer={getFooter(division_of_profits_bonus?.total)}
          note={getNote()}
        >
          <PieChart
            categories={division_of_profits_bonus?.categories}
            series={division_of_profits_bonus?.data}
            height="250"
          />
        </BCard>

        <BCard
          title={"Division of profits data"}
          footer={3}
          note={getNote()}

        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={division_of_profits_bonus_offering?.data?.categories}
            series={division_of_profits_bonus_offering?.data?.data}
            height="auto"
          />
        </BCard>

      </div>
    </div>
  );
}
