import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Questions from './Questions';

import { setWellBeingData } from '../../../../../../redux/onboard/benefits/well_being';
import WBInititiates from './Inititiates';
import { toast } from 'react-toastify';


export default function WellBeingDEI(props) {
    const well_being = useSelector(state => state.well_being)
    const { data } = well_being
    const dispatch = useDispatch()


    const { register, handleSubmit, watch, reset, control,
        formState: { errors, isDirty },
    } = useForm();

    useEffect(() => {
        reset(data)
    }, [])

    // check if any change made in form (user made any selection, input check etc) from react-hook-form


    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }

    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])


    const onSubmit = (form_data) => {
        if (isDirty) {
            dispatch(setWellBeingData(form_data))
            props.onSaveClick("benefits", "well_being", form_data)
        }
        props.changeTab(6)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Questions control={control} watch={watch} register={register} errors={errors} />
            <WBInititiates control={control} watch={watch} register={register} errors={errors} />
            <div className="flex justify-end mt-5 px-2 py-2">
                <button type='submit' className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}