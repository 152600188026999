import React from "react";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";
import BarChart from "../../Components/BarChart";

export default function ExpatriatesContract(props) {
  //   console.log(props);
  const { data } = props;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
      <BCard
        title="Expatriate on home country/international contract"
        footer={getFooter(3)}
        note={getNote()}
      >
        <BarChart 
        
        stacked={true}
        categories={data['Expatriate on home country/international contract'].categories} series={data['Expatriate on home country/international contract'].data} height="auto" />
      </BCard>
      <BCard
        title="Expatriate on host country/local contract"
        footer={getFooter(3)}
        note={getNote()}
      >
        <BarChart 
        stacked={true}
        categories={data['Expatriate on host country/local contract'].categories} 
        series={data['Expatriate on host country/local contract'].data} height="auto" />
      </BCard>
    </div>
  );
}
