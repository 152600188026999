import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material';
const headingColor = "#9651FA"

const StyledDataGrid = styled(DataGrid)({
    // set width to 100%
    '& .MuiDataGrid-root': {
      width: '100%',
    },
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: headingColor, // Set table heading row background to #eee
      color: "white"
    },
  });
export default function SubmissionTable({ data }) {
    const parseNumber = (number) => {
        try {
            return parseInt(number).toLocaleString();
        } catch (err) {
            return number;
        }
    };

    const columns = [
        { field: 'id', headerName: '',lock: true, width: 15, renderCell: (params) => (
            <div style={{textAlign: 'center', width: '100%', backgroundColor: "#fff", color: headingColor}}>{params.row.id+1}</div>
        ) },
        { field: 'sector_survey_position', headerName: 'Sector Survey Position', width: 220 },
        { field: 'organisation_job_title', headerName: 'Organisation Job Title', width: 150 },
        { field: 'staff_id', headerName: 'Staff ID', width: 90 },
        { field: 'gender', headerName: 'Gender', width: 120 },
        { field: 'location', headerName: 'Location', width: 130 },
        { field: 'date_of_birth', headerName: 'Date of Birth', width: 150 },
        { field: 'date_of_joining', headerName: 'Date of Joining', width: 150 },
        { field: 'salary_grade_minimum', headerName: 'Salary Grade Minimum', width: 150, valueGetter: (params) => parseNumber(params.row.salary_grade_minimum) },
        { field: 'salary_grade_midpoint', headerName: 'Salary Grade Midpoint', width: 150, valueGetter: (params) => parseNumber(params.row.salary_grade_midpoint) },
        { field: 'salary_grade_maximum', headerName: 'Salary Grade Maximum', width: 150, valueGetter: (params) => parseNumber(params.row.salary_grade_maximum) },
        { field: 'annual_basic_salary', headerName: 'Annual Basic Salary', width: 150, valueGetter: (params) => parseNumber(params.row.annual_basic_salary) },
        { field: 'house_allowance', headerName: 'House Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.house_allowance) },
        { field: 'car_allowance', headerName: 'Car Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.car_allowance) },
        { field: 'entertainment_allowance', headerName: 'Entertainment Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.entertainment_allowance) },
        { field: 'telephone_allowance', headerName: 'Telephone Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.telephone_allowance) },
        { field: 'utilities', headerName: 'Utilities', width: 150, valueGetter: (params) => parseNumber(params.row.utilities) },
        { field: 'annual_leave_allowance', headerName: 'Annual Leave Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.annual_leave_allowance) },
        { field: '13th_cheque', headerName: '13th Cheque', width: 150, valueGetter: (params) => parseNumber(params.row["13th_cheque"]) },
        { field: 'transport_allowance', headerName: 'Transport Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.transport_allowance) },
        { field: 'meals_allowance', headerName: 'Meals Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.meals_allowance) },
        { field: 'other_allowance', headerName: 'Other Allowance', width: 150, valueGetter: (params) => parseNumber(params.row.other_allowance) },
        { field: 'consolidated_salary', headerName: 'Consolidated Salary', width: 150, valueGetter: (params) => parseNumber(params.row.consolidated_salary) }
    ];

    const rows = data?.rows.map((row, index) => ({
        id: index,
        ...row,
        date_of_birth: row.date_of_birth?.slice(0, 16),
        date_of_joining: row.date_of_joining?.slice(0, 16)
    }));

    return (
        <div style={{ height: 650, width: '100%' }}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5, 10, 20]}
                checkboxSelection={false}
                disableSelectionOnClick
                sx={{
                    '.MuiDataGrid-virtualScroller': {
                      height: '650px !important',
                      overflowY: 'auto',
                    },
                    '& .MuiDataGrid-cell:first-child': {
                      position: 'sticky',
                      top: '0',
                      left: '0',
                      paddingLeft: '1.5rem',
                      zIndex: 999,
                      backgroundColor: "#fff",
                    },
                    '& .MuiDataGrid-columnHeader:first-child': {
                      position: 'sticky',
                      top: '0',
                      left: '0',
                      paddingLeft: '1.5rem',
                      border: 'none',
                      zIndex: 999,
                    },
                  }}
                  columnBuffer={columns.length}
            />
        </div>
    );
}    