import { getNestedError } from "./utils";

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";

export default function SelectInput(props) {
    const { register, options, errors, ...restProps } = props;
    const isError = !!getNestedError(errors, restProps.name);//errors && errors[restProps.name];
    const baseClasses = `mt-1 p-1.5 w-full border rounded-sm text-gray-500 ${restProps.className}`;
    const errorClasses = isError ? 'border-red-500 bg-red-100' : '';

    
    const isReadOnly = useSelector(onBoardingReadOnly);
    return (
        <select {...register(restProps.name, { required: true })} {...restProps} 
        className={`${baseClasses} ${errorClasses}`}
        disabled={isReadOnly}
        >
            {options?.map((item, index) => (
                <option key={`${restProps.name}-${item.label}`} value={item.value}>
                    {item.label}
                </option>
            ))}
        </select>
    );
}