import React from 'react';

function ErrorTable({ data }) {
  return (
    <div>
      <h4 className='text-red-400'>Please fix errors below by filling missing fields</h4>
    <div className="overflow-x-auto">
      <table className="min-w-full">
        <thead>
          <tr>
            {data[0].map((heading, index) => (
              <th key={index} className="px-4 py-2 bg-blue-500 text-white">
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                // Render first two cells content as is
                if(cellIndex === 0 || cellIndex === 1) {
                  return <td key={cellIndex} className="px-4 py-2 border text-xs" style={{ minWidth: '250px' }}>{cell}</td>;
                }
                
                // For other cells, show checkbox or error based on boolean value
                return (
                  <td key={cellIndex} className={`px-4 py-1 border ${cell ? 'bg-red-200' : `bg-green-200`}`}>
                    <div className="overflow-x-auto white-space-nowrap">
                      {cell ? (
                          <div className="text-center text-xl">&times;</div>
                      ) : (
                          <div className="text-center">{'\u2713'}</div>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
}

export default ErrorTable;
