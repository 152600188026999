import React from "react";
import BCard from "../../Components/BCard";
import TableView from "../../Components/TableView";
import { getFooter } from "../utils";

export default function ExpariatesPackages(props) {
  const { rows, columns, total } = props.data;
  return (
    <div className="my-4">
      <BCard
        title="Expatriate Packages"
        footer={getFooter(total)}
        note={`The total does not equal 100% due to rounding.`}
      >
        <TableView rows={rows} columns={columns} />
      </BCard>
    </div>
  );
}
