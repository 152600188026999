import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function TrainingAndDevelopment(props) {
  console.log(props.data);
  const { offering, training_budget } = props.data;
  return (
    <div>
      <BlockHeading text="3. Training And Development" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title="Offering Of Training And Development"
          footer={getFooter(offering?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={offering?.categories}
            series={[
              {
                name: "Offering Of Training And Development",
                data: offering?.data,
              },
            ]}
            height={"auto"}
          />
        </BCard>

        <BCard
          title="Traning Budget"
          footer={getFooter(training_budget?.total)}
          note={getNote()}
        >
          <PieChart
            categories={training_budget?.categories}
            series={training_budget?.data}
            height={"200px"}
          />
        </BCard>
      </div>
    </div>
  );
}
