import BlankRowCell from "../../../../../../components/forms/BlankRowCell"
import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { WR_COLORS } from "../../../../../../configs/colors"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"


const PAYOUT_FREQUENCY = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Annually", value: "Annually" },
]

export default function CommissionSchemes(props) {
    const id = 'commission_schemes'
    const { watch, errors, control, register } = props



    return (
        <div>
            <BlockHeading text="2. COMMISSION SCHEMES" />
            <div>
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer commission schemes?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2"><table className="w-full border">
                    <thead>
                        <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                            <td className="px-2 py-2 border-b border-r">Eligible levels</td>
                            <td className="px-2 border-b border-r">Eligibility</td>
                            <td className="px-2 border-b border-r">Frequency of payout</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ORGANIZATION_PEOPLE.map((item, index) => {
                                return (<tr key={`medical_cover-eligibility-${index}`}>
                                    <td className="px-2 border-b border-r">{item}</td>


                                    <td className="px-2 border-b border-r">
                                        <SelectAutocompleteSingle
                                            border={false}
                                            required={true}
                                            name={`${id}.data.${item}.eligibility`}
                                            register={register}
                                            errors={errors} watch={watch}
                                            options={YES_NO} control={control} /></td>

                                    {watch(`${id}.data.${item}.eligibility`) === "Yes" ?
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle
                                                border={false}
                                                required={true}
                                                name={`${id}.data.${item}.payout_frequency`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={PAYOUT_FREQUENCY} control={control} /></td>
                                        :
                                        <BlankRowCell style={{ width: '25%' }} />
                                    }
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table></div>
            }

        </div>
    )
}