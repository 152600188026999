import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './i18n/en.json';
import ptTranslation from './i18n/pt.json';
import frTranslation from './i18n/fr.json';

const resources = {
  en: { translation: enTranslation },
  pt: { translation: ptTranslation },
  fr: { translation: frTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language here.
  fallbackLng: 'en', // Fallback language if a translation key is missing.
  interpolation: {
    escapeValue: false, // React already escapes the values, so no need for this.
  },
});

export default i18n;
