import React from "react";
import Chart from "react-apexcharts";
import { WR_GRAPH_COLORS, WR_PIE_GRAPH_COLORS } from "../../../configs/colors";
import NoData from "./NoData";

const PieChart = (props) => {
  // Define the chart options and series data
  const options = {
    chart: {
      type: "pie",
      height: props.height ? props.height : 300,
    },
    labels: props.categories || ["Retail & Wholesale", "Insurance/Reinsurance"],
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -23,
        },
      },
    },
    colors: WR_PIE_GRAPH_COLORS,
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = props.series;

  // check if all values are zero
  const allZero = series ? series?.every((item) => item === 0) : true;
  if (allZero) {
    return <NoData />;
  }

  // Render the chart component
  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type="pie"
        height={props.height || 300}
      />
    </div>
  );
};

export default PieChart;
