import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import BarChart from "../../Components/BarChart";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function AnnualCompensationAdjustments(props) {
  const {
    annual_salary_increases,
    salary_adj_frequency,
    salary_increase_determination,
    salary_increment,
    projected_increments
  } = props.data;
  return (
    <div>
      <BlockHeading text="2. Annual Compensation Structure" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title={"Annual salary increases"}
          subtitle={"When do annual salary increases take place"}
          footer={getFooter(annual_salary_increases?.total)}
          note={getNote()}
        >
          <PieChart
            categories={annual_salary_increases.categories}
            series={annual_salary_increases.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Salary increment %"}
          subtitle={
            "What is the promotional salary increment % this current year"
          }
          footer={getFooter(salary_increment?.total)}
          note={getNote()}
        >
          <PieChart
            categories={salary_increment.categories}
            series={salary_increment.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Salary adjustment frequency"}
          subtitle={"How frequently do you adjust your salary ranges"}
          footer={getFooter(salary_adj_frequency?.total)}
          note={getNote()}
        >
          <PieChart
            categories={salary_adj_frequency.categories}
            series={salary_adj_frequency.data}
            height="250"
          />
        </BCard>
        <BCard
          title={"Salary increase determination"}
          subtitle={
            "What elements does your organisation use to determine annual salary increases"
          }
          footer={getFooter(salary_increase_determination?.total)}
          note={getNote()}
        >
          <PieChart
            categories={salary_increase_determination.categories}
            series={salary_increase_determination.data}
            height="250"
          />
        </BCard>
      </div>
     { projected_increments && <TableCharts data={projected_increments} intitial_val="Projected Increment % for " />}
    </div>
  );
}


function TableCharts(props) {
  const { data } = props;
  if (!data) return null;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
      { Object.entries(data).map(([key, value]) => {
        return (
        <BCard
          title={`${props.intitial_val}${key}`}
          note={getNote()}
          footer={getFooter(value?.total)}
        >
          <BarChart
            percentage={true}
            categories={value?.categories}
            series={[{ name: key, data: value?.data }]} // [{ name: "Incorporation Year", data: incorporation_year.data }
            height="auto"
          />
        </BCard>
        )
      })}
      </div>
  )
}
