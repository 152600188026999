import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTalentFilters } from '../../../../redux/talent';
import instance from '../../../../auth/useJwt';
import { fetchTalentData } from '../../../../redux/talent';

function SelectFilter(props) {
    const { title, name, options } = props
    return (
            <select id="role" name={name} 
            onChange={props.onChange}
            style={{minWidth: '120px'}}
            className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                { title !== "Period" && <option value={""}>{title}</option> }
                {/* You can list your options here */}
                {options.map((option, index) => {
                    return <option key={index} value={option}>{option}</option>
                })}
            </select>
    )
}

export default function HFilters() {
    const { uniqueRoles, uniqueGrades, filters,
        uniqueJobFamilies, uniqueLocations
    } = useSelector(state => state.talent)
    const vFilters = filters
    const dispatch = useDispatch()
  
    
    const onChane = (e) => {
        dispatch(setTalentFilters({
            ...vFilters,
            [e.target.name]: e.target.value
        }))
    }

    const [apiReq, setApiReq] = React.useState({loading: false, error: null})

    const exportData = () => {
        setApiReq({loading: true, error: null})
        instance.get('/talent/export').then((res) => {
            // res.data is a url
            window.open(res.data, '_blank')
            setApiReq({loading: false, error: null})
        }).catch((err) => {
            console.log(err)
            setApiReq({loading: false, error: err})
        })
    }



 


    return (
        <Card className="p-4 flex items-center space-x-4" sx={{ border: '1px solid #eee' }}>
            
            {/* <div>
                <select className="p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option defaultValue value={"Total Cash"}>Compensation : Base Salary</option>
                    <option defaultValue value={"Guaranteed Cash"}>Compensation : Total Guaranteed Cash</option>
                </select>
            </div> */}

            <div>
            <SelectFilter name={"role"} title="Role" options={uniqueRoles} onChange={onChane} />
            </div>
            <div>
                <SelectFilter name={"grade"} title="Grade" options={uniqueGrades} onChange={onChane} />
            </div>
            <div>
                <SelectFilter name={"jobFamily"} title="Job Family" options={uniqueJobFamilies} onChange={onChane} />
            </div>
            <div>
                <SelectFilter name={"location"} title="Location" options={uniqueLocations} onChange={onChane} />
            </div>
            <div>
                <SelectFilter name={"period"} title="Period" options={[
                    'Annual', 'Monthly'
                ]} onChange={onChane} />
            </div>


            <div>
                <button 
                onClick={() => exportData()}
                disabled={apiReq.loading}
                className="p-2 px-4 text-white border bg-blue-500 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    { apiReq.loading ? 'Exporting...' : 'Export' }
                </button>
            </div>

        </Card>
    );
}
