import { Grid } from "@mui/material";
import SingleCard from "./SingleCard";
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
export default function Stats(props) {
    const basic_dt = props.data.basic

    const IconSize = 50

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <SingleCard icon={
                    <PersonIcon sx={{
                        fontSize: IconSize,
                        color: '#06f'
                    }} />
                } title="Total Users" stats={basic_dt.general_users} />
            </Grid>
            <Grid item xs={12} md={3}>
                <SingleCard icon={
                    <ManageAccountsIcon sx={{
                        fontSize: IconSize,
                        color: '#03F'
                    }} />
                } title="Admin" stats={basic_dt.admin_users} />
            </Grid>
            <Grid item xs={12} md={3}>
                <SingleCard icon={
                    <BusinessIcon sx={{
                        fontSize: IconSize,
                        color: '#00BBFF'
                    }} />
                } title="Companies" stats={basic_dt.companies} />
            </Grid>
            <Grid item xs={12} md={3}>
                <SingleCard icon={
                    <StoreIcon sx={{
                        fontSize: IconSize,
                        color: '#9752FD'
                    }} />
                } title="Companies Submitted" stats={basic_dt.companies_finished} />
            </Grid>
        </Grid>
    )
}