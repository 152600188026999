import React from 'react';

import SearchIcon from '../../../../assets/icons/search.svg'
import { useTranslation } from 'react-i18next';

const SearchBox = () => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center border border-gray-400 rounded p-2 w-full">
      <img src={SearchIcon} alt='Search Icon' />
      <input
        type="text"
        className="focus:outline-none flex-1 bg-transparent text-white pl-2"
        placeholder={t("search")}
      />
    </div>
  );
};

export default SearchBox;
