import React from "react";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function HiringExpatriates(props) {
  const { categories, data, total } = props.data;
  return (
    <div className="my-4">
      <BCard
        title="Hiring of Expatriates"
        footer={getFooter(total)}
        note={getNote()}
      >
        <PieChart categories={categories} series={data} height="200px" />
      </BCard>
    </div>
  );
}
