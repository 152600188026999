import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { handleLogout } from "../../redux/authentication"
import DashboardLayout from "../../components/layouts/Dashboard"
import OnBoarding from "./OnBoarding"

export default function DashboardHome() {
    // const user = useSelector(state => state.authentication)
    // const dispatch = useDispatch()
    // const navigate = useNavigate()

    return (
        <DashboardLayout>
            <OnBoarding />
        </DashboardLayout>
    )
}