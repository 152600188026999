import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import BarChart from "../../Components/BarChart";
import { getFooter, getNote } from "../utils";

export default function LongServiceAwards(props) {
  const { data } = props;
  if (!data) return null;
  console.log("data in long service awards = ", data);

  const { offering } = data;
  return (
    <div>
      <BlockHeading text="Long Service Awards or Work Anniversary Recognition" />
      <div className="my-4">
        <BCard
          title="Long term service awards"
          subtitle="Does your organization offer long term service awards or work anniversary recognition to any employees?"
          footer={getFooter(offering?.total)}
          total={offering?.total}
          note={getNote()}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="200px"
          />
        </BCard>
      </div>
      {
        data.data && <TableCharts data={data?.data} intitial_val={""} />
      }
    </div>
  );
}


function TableCharts(props) {
  const { data } = props;
  if (!data) return null;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
      { Object.entries(data).map(([key, value]) => {
        return (
        <BCard
          title={`${props.intitial_val}${key}`}
          note={getNote()}
          total={value?.total}
          footer={getFooter(value?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={value.categories}
            series={value.data}
            height="auto"
          />
        </BCard>
        )
      })}
      </div>
  )
}
