export const ENERGY = {
    "Oil and Gas": {
        "Oil and Gas General": {
            "job_family_descriptor": "The Oil and Gas sector is  focused on exploration, data acquisition, development, drilling, production, gathering, refining, distribution and transportation of hydrocarbons.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Executive",
                    "position_descriptor": "The Chief Executive holds the highest executive position, providing overall leadership, strategic direction, and decision-making for the entire company.",
                    "related_job_titles": "Chief Executive, Managing Director"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Executive",
                    "position_descriptor": "The Senior Executive is responsible for overseeing the entire exploration function, setting strategic goals, and ensuring the success of exploration /refining projects as the head of the division.",
                    "related_job_titles": "Chief Exploration Officer, "
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Executive Director",
                    "position_descriptor": "The Executive Director of upstream or downstream operations oversees exploration, drilling, and production activities, ensuring efficient and effective extraction of oil and gas resources.",
                    "related_job_titles": "Director - Exploration and Production"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Engineering Manager / Project Director",
                    "position_descriptor": "Senior Engineers play a key role in evaluating and optimizing reservoir performance to maximize oil and gas recovery.",
                    "related_job_titles": "Senior Reservoir Engineer, Reservoir Engineering Manager, Reservoir Engineering Specialist"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Operations Manager",
                    "position_descriptor": "Operations Managers oversee day-to-day activities in oil and gas operations, managing production processes, safety protocols, and compliance.",
                    "related_job_titles": "Operations Manager, Operations Director, Field Operations Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Engineer",
                    "position_descriptor": "Engineers plan and execute drilling operations, optimizing well designs and ensuring safe and efficient extraction.",
                    "related_job_titles": "Drilling Engineer, Drilling Manager, Drilling Operations Engineer"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Health, Safety, and Environment (HSE) Specialist",
                    "position_descriptor": "HSE  Specialist focus on implementing and maintaining health, safety, and environmental standards within oil and gas operations.",
                    "related_job_titles": "HSE Manager, Health and Safety Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Project Manager",
                    "position_descriptor": "Project Managers lead specific projects, ensuring timely and cost-effective execution while meeting safety and regulatory standards.",
                    "related_job_titles": "Project Manager - Oil and Gas, Project Lead"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Production Supervisor",
                    "position_descriptor": "Supervisors oversee  crews, ensuring that drilling operations are conducted safely and in accordance with plans.",
                    "related_job_titles": "Drilling Supervisor, Production Supervisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Analyst/ Production officer",
                    "position_descriptor": "Oil and Gas Analysts analyze market trends, pricing, and industry data to provide insights for business strategies and investment decisions.",
                    "related_job_titles": "Oil and Gas Analyst, Market Analyst, Energy Analyst,Reservoir Analyst, Production Officer"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field Technician",
                    "position_descriptor": "Field Technicians provide on-site support, performing tasks such as equipment maintenance, monitoring, and data collection in oil and gas fields.",
                    "related_job_titles": "Field Technician, Field Operator, Field Service Technician"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Skilled Trade Workers",
                    "position_descriptor": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within a  site.",
                    "related_job_titles": "Skilled Trades Workers, Electrician, Plumber, Carpenter, Mason, HVAC Technician, Welder, Painter, Pipefitter, Roofing Specialist, Concrete Finisher, Ironworker, Glazier, Tile Setter, Drywall Installer, Millwright"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Laborers / General Laborers",
                    "position_descriptor": "General Laborers provide manual labor support on sites, assisting skilled trades workers and following supervisors' instructions.",
                    "related_job_titles": "General Laborers, Construction Worker, Laborer, Site Helper, Mate"
                }
            ]
        },
        "Exploration and Production (Upstream)": {
            "job_family_descriptor": "This job family consists of positions that are responsible for planning, directing, and coordinating the operations of drilling rigs and well services used in the exploration, development, and production of oil and gas. This includes positions that are responsible for the safety and efficient operation of the rigs and well services, as well as the maintenance and repair of the equipment. Positions in this job family are found in a variety of settings, including oil and gas fields, drilling rig operations, and well services companies.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Executive - Exploration",
                    "position_descriptor": "The Senior Executive is responsible for overseeing the entire exploration function, setting strategic goals, and ensuring the success of exploration projects.",
                    "related_job_titles": "Chief Exploration Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Production Officer",
                    "position_descriptor": "The CPO is in charge of the production operations, including drilling, extraction, and overall production processes. They play a key role in optimizing production efficiency.",
                    "related_job_titles": "Chief Production Officer"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Engineering Manager / Project Director",
                    "position_descriptor": "This is the Chief Reservoir Engineer who leads reservoir engineering efforts, managing the estimation of oil and gas reserves and implementing strategies to maximize resource recovery.",
                    "related_job_titles": "Chief Reservoir Engineer"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Exploration and Production Director",
                    "position_descriptor": "This director oversees both exploration and production activities, coordinating efforts to achieve organizational goals and contributing to strategic decision-making.",
                    "related_job_titles": "Exploration and Production Director"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Production/ Engineering Manager",
                    "position_descriptor": "The Reservoir Engineering Managers lead a team of engineers focused on reservoir analysis, simulation, and management to optimize oil and gas recovery. This level includes; Senior Drilling Managers are responsible for planning and executing drilling operations, ensuring safety, efficiency, and adherence to environmental regulations; and  Production Operations Managers supervise day-to-day production activities, ensuring smooth operations, and implementing strategies to enhance productivity.",
                    "related_job_titles": "Reservoir Engineering Manager, Production Operations Manager. Senior Drilling Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Reservoir / Drilling Engineer",
                    "position_descriptor": "Reservoir Engineers analyze subsurface reservoirs to estimate reserves and implement strategies to maximize production. Includes Drilling Engineers are involved in the planning and execution of drilling projects, emphasizing safety, cost-effectiveness, and technical efficiency.",
                    "related_job_titles": "Reservoir Engineer, Drilling Engineer"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Production Technologist",
                    "position_descriptor": "Production Technologists work on optimizing production processes, implementing technologies, and ensuring efficient oil and gas extraction.",
                    "related_job_titles": "Production Technologist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Drilling Supervisor",
                    "position_descriptor": "Drilling Supervisors oversee drilling crews, ensuring that drilling operations are conducted safely and in accordance with plans.",
                    "related_job_titles": "Drilling Supervisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Reservoir Analyst / Production Officer",
                    "position_descriptor": "Reservoir Analysts support reservoir engineering efforts by analyzing data, conducting simulations, and contributing to reservoir management strategies.",
                    "related_job_titles": "Reservoir Analyst, Production Officer"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field Technician",
                    "position_descriptor": "Field Technicians, at the entry level, assist in various field activities, gaining hands-on experience in exploration and production operations.",
                    "related_job_titles": "Field Technician"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Skilled Trade Workers",
                    "position_descriptor": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within a  site.",
                    "related_job_titles": "Skilled Trades Workers, Electrician, Plumber, Carpenter, Mason, HVAC Technician, Welder, Painter, Pipefitter, Roofing Specialist, Concrete Finisher, Ironworker, Glazier, Tile Setter, Drywall Installer, Millwright"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Laborers / General Laborers",
                    "position_descriptor": "General Laborers provide manual labor support on sites, assisting skilled trades workers and following supervisors' instructions.",
                    "related_job_titles": "General Laborers, Construction Worker, Laborer, Site Helper, Mate"
                }
            ]
        },
        "Refining and Processing (Downstream)": {
            "job_family_descriptor": "The Downstream job family is involved in the refining and processing of crude oil into various end-products such as fuels, lubricants, and petrochemicals. Downstream operations also encompass petrochemical production, where raw materials derived from oil and gas are used to manufacture a wide range of chemical products.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Executive - Refining",
                    "position_descriptor": "The Chief Refining Officer is the highest-ranking executive overseeing all aspects of refining and processing operations. They are responsible for setting strategic goals, ensuring operational efficiency, and leading the overall direction of the downstream business.",
                    "related_job_titles": "Chief Refining Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Operations Officer",
                    "position_descriptor": "The Chief Operations Officer for Downstream Operations holds a senior leadership role, responsible for the overall operational performance of downstream facilities. They collaborate with other executives to align operations with organizational goals.",
                    "related_job_titles": "Chief Operations Officer - Downstream"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Chief Technology Officer- Refining",
                    "position_descriptor": "The Chief Technology Officer for Refining focuses on technological innovation and development within the refining sector. They lead research and development initiatives, implementing cutting-edge technologies to enhance refining processes.",
                    "related_job_titles": "Chief Technology Officer - Refining"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Refining and Processing Director",
                    "position_descriptor": "Directors in Refining and Processing oversee departmental activities and contribute to strategic decision-making. They work closely with executive leadership to ensure efficient and safe operations.",
                    "related_job_titles": "Refining and Processing Director"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Engineering / Operations / Refinery Manager",
                    "position_descriptor": "Engineering Managers are experienced professionals responsible for designing, optimizing, and troubleshooting refining processes. They provide technical expertise and contribute to continuous improvement efforts. Includes Operations / Refinery Managers overseeing the entire refinery operation, manage day-to-day activities, ensuring safe and efficient production. They manage refining processes, oversee teams, coordinate workflows, and implement operational plans to achieve production targets while maintaining safety standards.",
                    "related_job_titles": "Senior Process Engineer,Refinery Manager, Operations Manager - Refinery"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Process Engineer",
                    "position_descriptor": "Process Engineers are responsible for designing and implementing refining processes. They focus on optimizing efficiency, reducing costs, and ensuring compliance with industry standards.",
                    "related_job_titles": "Process Engineer"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Process Optimization Specialist",
                    "position_descriptor": "Optimization Specialists focus on improving refining processes to enhance efficiency and reduce environmental impact. They analyze data, implement improvements, and contribute to sustainability initiatives.",
                    "related_job_titles": "Process Optimization Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Refinery Operations Supervisor",
                    "position_descriptor": "Operations Supervisors oversee day-to-day activities in specific refinery areas. They lead operational teams, ensure compliance with safety protocols, and contribute to process optimization.",
                    "related_job_titles": "Refinery Operations Supervisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Refinery Operations Officer / Plant Operator",
                    "position_descriptor": "Experienced operations officers who operate and monitor equipment in the refinery. They ensure the safe and efficient functioning of machinery, follow operational procedures, and respond to emergencies.",
                    "related_job_titles": "Plant Operator, Production Officer, Operations Officer, Analyst"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Refinery Technician",
                    "position_descriptor": "Entry-Level Refinery Technicians support various tasks in the refinery, gaining hands-on experience in refining processes, equipment maintenance, and safety protocols.",
                    "related_job_titles": "Refinery Technician"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Skilled Trade Workers",
                    "position_descriptor": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within a  site.",
                    "related_job_titles": "Skilled Trades Workers, Electrician, Plumber, Carpenter, Mason, HVAC Technician, Welder, Painter, Pipefitter, Roofing Specialist, Concrete Finisher, Ironworker, Glazier, Tile Setter, Drywall Installer, Millwright"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Laborers / General Laborers",
                    "position_descriptor": "General Laborers provide manual labor support on sites, assisting skilled trades workers and following supervisors' instructions.",
                    "related_job_titles": "General Laborers, Construction Worker, Laborer, Site Helper, Mate"
                }
            ]
        },
        "Distribution and Marketing": {
            "job_family_descriptor": "This job family is involved in the transportation, storage, and commercialization of refined petroleum products. It also involves managing logistics, storage facilities, and transportation networks to ensure a reliable supply chain.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Marketing and Distribution Officer",
                    "position_descriptor": "The Chief Marketing and Distribution Officer is a top executive responsible for leading and overseeing all aspects of marketing and distribution strategies. They set strategic goals, manage partnerships, and ensure effective product distribution.",
                    "related_job_titles": "Chief Marketing and Distribution Officer, Marketing and Distribution Executive, Chief Commercial and Marketing Strategist"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director/Chief Commercial Officer",
                    "position_descriptor": "The Chief Commercial Officer focuses on the commercial aspects of the oil and gas business, including marketing, sales, and distribution. They develop and execute commercial strategies to maximize profitability.",
                    "related_job_titles": "Senior Commercial Director, Chief Commercial Officer, Chief Marketing and Sales Officer"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Distribution and Marketing",
                    "position_descriptor": "Directors in Distribution and Marketing play a crucial role in shaping marketing and distribution strategies. They provide leadership, oversee departmental activities, and contribute to organizational goals.",
                    "related_job_titles": "Director of Marketing and Distribution, Director of Commercial Operations"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Marketing/ Distribution Manager",
                    "position_descriptor": "Senior Marketing Managers lead marketing teams, develop marketing plans, and implement strategies to promote oil and gas products. They analyze market trends and ensure effective brand positioning.",
                    "related_job_titles": "Senior Marketing Manager, Senior Marketing Strategist"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Commercial Operations / Distribution and Logistics Manager",
                    "position_descriptor": "Commercial Operations Managers oversee various commercial activities, including sales, distribution, and customer relationships. They work to enhance operational efficiency and drive revenue growth. Includes Distribution and Logistics Managers who focus on optimizing the supply chain and distribution processes. They manage logistics, distribution networks, and collaborate with suppliers to ensure efficient product delivery.",
                    "related_job_titles": "Distribution and Logistics Manager, Distribution Manager, Logistics and Supply Chain Manager, Commercial Operations Manager, Manager of Commercial Excellence"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Marketing Strategist / Analyst",
                    "position_descriptor": "Marketing Strategists develop and implement marketing strategies aligned with business objectives. They conduct market research, analyze consumer behavior, and contribute to branding efforts. Level includes senior Marketing Analysts who specialize in analyzing market data, consumer trends, and competitive landscapes. They provide insights to support marketing decisions and contribute to data-driven strategies.",
                    "related_job_titles": "Marketing Strategist, Strategic Marketing Specialist, Senior Marketing Analyst, Senior Market Insights Analyst"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Sales and Distribution Supervisor",
                    "position_descriptor": "Sales and Distribution Supervisors manage teams involved in sales and product distribution. They ensure sales targets are met, oversee distribution channels, and monitor customer satisfaction.",
                    "related_job_titles": "Sales and Distribution Supervisor, Sales Supervisor, Supervisor of Distribution Operations"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Marketing Coordinator",
                    "position_descriptor": "Marketing Coordinators support marketing activities, coordinate campaigns, and assist in executing marketing plans. They work closely with marketing teams to implement strategies.",
                    "related_job_titles": "Marketing Coordinator, Marketing Campaign Coordinator, Senior Marketing Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Distribution Specialist / Marketing Assistants",
                    "position_descriptor": "Distribution Specialists focus on optimizing product distribution processes. They coordinate with logistics partners, monitor inventory levels, and contribute to efficient supply chain management. Level includes Entry-Level Marketing Assistants are new to the field and support marketing activities by performing tasks such as data entry, research, and assisting with marketing campaigns. They gain foundational experience in marketing processes and may work under the guidance of more experienced team members.",
                    "related_job_titles": "Distribution Specialist, Supply Chain and Distribution Officer"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Marketing Admin Assistant",
                    "position_descriptor": "Offers administrative support to the department, manage office tasks, coordinate meetings, and provide assistance to ensure the smooth functioning of operations. Level includes Entry-level professionals in Marketing undergoing training programs to develop foundational skills in marketing and who support various marketing tasks and gain hands-on experience.",
                    "related_job_titles": "Marketing Admin Assistant, Marketing clerk, Marketing Trainee, Entry-Level Marketing Associate"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Sales Representative",
                    "position_descriptor": "Sales Representatives promote and sell oil and gas products. They build relationships with clients, understand customer needs, and contribute to achieving sales targets.",
                    "related_job_titles": "Sales Representative, Field Sales Specialist"
                }
            ]
        },
        "Construction": {
            "job_family_descriptor": "The Construction job family consist of positions that construct and maintain facilities and infrastructure in the Mining, Oil and Gas industry . This includes the construction of new mines, the expansion and repair of existing mines, the construction of oil and gas wells, pipelines and other transportation infrastructure, and the construction of processing facilities",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Executive",
                    "position_descriptor": " The Senior Executive holds the highest leadership position in a construction division of a company, overseeing all operations and strategic direction.",
                    "related_job_titles": "Chief Construction Officer,Senior Director of Construction, Senior Executive, Construction  Leader."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Construction",
                    "position_descriptor": " The Director of Construction manages the construction division or department, setting goals, strategy, and ensuring project success.",
                    "related_job_titles": "Director of Construction, Director of Operations, Director of Projects, Construction Division Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Project Manager / Project Director",
                    "position_descriptor": " Senior Project Managers or Project Directors lead major construction projects, managing teams and coordinating complex initiatives.",
                    "related_job_titles": "Senior Project Manager, Project Director, Senior Construction Project Manager, or Senior Project Leader."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Project Manager",
                    "position_descriptor": " Project Managers are responsible for planning, executing, and closing construction projects, ensuring quality, budget, and schedule goals are met.",
                    "related_job_titles": "Project Manager, Construction Project Manager, Project Coordinator, or Project Team Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Construction Manager",
                    "position_descriptor": " Construction Managers oversee on-site construction activities, ensuring safety, quality, and efficiency.",
                    "related_job_titles": "Construction Manager, Site Manager, Field Operations Manager, Construction Site Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Construction Engineer",
                    "position_descriptor": " Construction Engineers focus on design, quality control, and project management within the construction field.",
                    "related_job_titles": "Construction Engineer, Design Engineer, Project Engineer, or Construction Engineering Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Construction Supervisor",
                    "position_descriptor": " Construction Supervisors lead teams of construction workers, ensuring adherence to safety guidelines and efficient task completion.",
                    "related_job_titles": "Construction Supervisor, Site Supervisor, Foreman, or On-Site Construction Supervisor."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Construction Foreman",
                    "position_descriptor": " Construction Foremen supervise small groups of construction workers, directing their work and ensuring tasks are completed as planned.",
                    "related_job_titles": "Construction Foreman, Crew Leader, Foreman Supervisor, or Site Foreman"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Construction Technicians",
                    "position_descriptor": " Construction Technicians provide technical support, conduct tests, and assist engineers and managers with various tasks.",
                    "related_job_titles": "Construction Technicians, Quality Control Technician, Materials Testing Technician, or Field Technician."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Construction Assistants",
                    "position_descriptor": " Construction Assistants support project teams by performing administrative and operational tasks.",
                    "related_job_titles": "Construction Assistants, Assistant Project Manager, Construction Support Assistant, or Project Coordinator Assistant."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Skilled Trades Workers",
                    "position_descriptor": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within construction.",
                    "related_job_titles": "Skilled Trades Workers, Electrician, Plumber, Carpenter, Mason, HVAC Technician, Welder, Painter, Pipefitter, Roofing Specialist, Concrete Finisher, Ironworker, Glazier, Tile Setter, Drywall Installer, Millwright"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Construction Laborers / General Laborers",
                    "position_descriptor": " Construction Laborers or General Laborers provide manual labor support on construction sites, assisting skilled trades workers and following supervisors' instructions.",
                    "related_job_titles": "Construction Laborers, General Laborers, Construction Worker, Laborer, Site Helper, Mate"
                }
            ]
        }
    },
    "Electricity": {
        "Electricity and Power Generation": {
            "job_family_descriptor": "The Electricity Sector encompasses a broad range of activities related to the generation, transmission, distribution, and utilization of electrical energy. Professionals in the sector are involved in designing, implementing, and maintaining electrical systems, ensuring the reliable and efficient supply of electricity.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Executive Officer",
                    "position_descriptor": "The CEO holds the highest executive position, providing strategic leadership, making key decisions, and overseeing the overall operations of the electricity company at a national level",
                    "related_job_titles": "CEO, Chief Executive, Executive Director"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Operations Officer (COO) - Power Generation",
                    "position_descriptor": "The COO focuses on power generation operations, ensuring the efficient and reliable production of electricity, managing power plants, and implementing operational strategies.",
                    "related_job_titles": "COO - Power Generation, Operations Director, VP of Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Technology Officer (CTO) - Energy Technology",
                    "position_descriptor": "The CTO specializes in energy technology, leading research and development efforts, implementing innovative technologies, and driving technological advancements in the electricity sector.",
                    "related_job_titles": "CTO - Energy Technology, Technology Director, VP of Technology"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Grid Operations",
                    "position_descriptor": "Directors of Grid Operations oversee the electricity grid, ensuring stability, reliability, and efficiency, and managing the overall transmission and distribution network.",
                    "related_job_titles": "Director of Grid Operations, Grid Operations Manager, Operations Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Power Systems Engineer",
                    "position_descriptor": "Senior Power Systems Engineers provide expertise in designing, analyzing, and optimizing power systems, including generation, transmission, and distribution components.",
                    "related_job_titles": "Senior Power Systems Engineer, Power Systems Manager, Senior Engineer"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Transmission and Distribution Manager",
                    "position_descriptor": "Transmission and Distribution Managers are responsible for managing the transmission and distribution infrastructure, ensuring reliable electricity delivery to end-users. This level also include Renewable Energy Project Managers who lead projects focused on renewable energy sources, overseeing planning, development, and implementation of wind, solar, or other renewable projects.",
                    "related_job_titles": "Transmission and Distribution Manager, T&D Operations Manager, Manager - Grid Operations, Renewable Energy Project Manager, Project Manager - Renewable Energy"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Control Systems Engineer",
                    "position_descriptor": "Control Systems Engineers specialize in designing, implementing, and maintaining control systems for power plants and grid operations.",
                    "related_job_titles": "Control Systems Engineer, Systems Engineer - Electricity, Automation Engineer"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Electrical Design Engineer",
                    "position_descriptor": "Electrical Design Engineers focus on designing electrical systems, components, and infrastructure, ensuring compliance with safety and regulatory standards.",
                    "related_job_titles": "Electrical Design Engineer, Design Engineer - Electricity, Senior Electrical Engineer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Energy Analyst",
                    "position_descriptor": "Energy Analysts analyze data and trends in the electricity sector, providing insights into energy consumption, efficiency, and recommending strategies for improvement.",
                    "related_job_titles": "Energy Analyst, Renewable Energy Analyst, Power Systems Analyst"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Field Service Technician",
                    "position_descriptor": "Field Service Technicians perform on-site maintenance, repairs, and installations, ensuring the proper functioning of electrical equipment and infrastructure.",
                    "related_job_titles": "Field Service Technician, Electrical Technician, Service Engineer"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Entry-Level Electrical Technician",
                    "position_descriptor": "Entry-Level Electrical Technicians support more experienced technicians, assisting with maintenance, troubleshooting, and routine tasks within the electricity sector.",
                    "related_job_titles": "Entry-Level Electrical Technician, Junior Electrical Technician, Technician Trainee"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Laborers / General Laborers",
                    "position_descriptor": "General Laborers provide manual labor support on sites, assisting skilled trades workers and following supervisors' instructions.",
                    "related_job_titles": "General Laborers, Construction Worker, Laborer, Site Helper, Mate"
                }
            ]
        }
    }
}