import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../components/layouts/Dashboard";
import instance from "../../../auth/useJwt";
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardContent, CardHeader, Checkbox, LinearProgress } from "@mui/material";

import { styled } from '@mui/material';
import OnBoardStatusChange from "./components/OnboardStatusChange";


const StyledDataGrid = styled(DataGrid)({
    // set width to 100%
    '& .MuiDataGrid-root': {
        width: '100%',
    },
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#F9FAFB', // Set table heading row background to #eee
    },
});
export default function AdminUsers() {

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    const get_users = () => {
        setLoading(true)
        instance.get('/admin/users').then(res => {

            // add field "index" to users
            const temp_users = res.data.map((user, index) => {
                return {
                    ...user,
                    index: index + 1
                }
            })
            setUsers(temp_users)
            setLoading(false)
        }
        ).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }


    const columns = [
        { field: 'index', headerName: '', width: 50 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'username', headerName: 'Username', width: 150 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'created_at', headerName: 'Created At', flex: 1 },
        { field: 'role', headerName: 'Role', flex: 1 },
        {
            field: 'onboarding_approved', headerName: 'Onboard Approved', flex: 1,
            renderCell: (params) => (
                // MUI checkbox
                // <Checkbox checked={params.value} onChange={() => onChangeOnboardApproval(params.row.id, !params.value)} />
                params.row.role === "user" ?
                <OnBoardStatusChange row={params.row} onSuccess={get_users} />
                : null
            )
        },
    ]

    useEffect(() => {
        get_users()
    }, [])



    return (
        <DashboardLayout title="Users - Admin">
            <div className="px-4 py-1">
                <Card sx={{ border: '1px solid #eee' }}>
                    <CardHeader title={`Users (${users.length})`} />
                    <CardContent sx={{ borderTop: '1px solid #eee', px: 0 }}>
                        <StyledDataGrid
                            rows={users}
                            columns={columns}
                            loading={loading}
                            disableRowSelectionOnClick={true}
                            autoHeight
                            sx={{ height: 500, width: '100%', border: 'none' }}
                            slots={{ loadingOverlay: LinearProgress, }}
                            density="compact"
                            pagination
                            pageSize={15}
                            pageSizeOptions={[15, 25]}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 15 } },
                            }}
                        />
                    </CardContent>
                </Card>
            </div>
        </DashboardLayout>
    )
}