import { Box, Card, CardContent, Paper, Typography } from "@mui/material";

export default function SingleCard(props) {
    return (
        <Card sx={{ border: '1px solid #ddd' }}>
            <CardContent>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {props.icon}
                    </Box>
                    <Box>
                        <Typography variant="h6">{props.stats}</Typography>
                        <p>{props.title}</p>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}