import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import instance from '../../../auth/useJwt';
import GenerateXLSX from './Submission/GenerateXLSX';
import ErrorTable from './Submission/ErrorTable';
import { useDispatch, useSelector } from 'react-redux';
import { setCompensationSubmissionData } from '../../../redux/onboard/compensation_submission';
import SubmissionTable from './Submission/SubmissionTable';
import { onBoardingReadOnly } from '../../../redux/app_data';

export default function FileSubmission(props) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [responseErrors, setResponseErrors] = useState(null);

  const dispatch = useDispatch()
  const compensation_submission_data = useSelector(state => state.compensation_submission)
  console.log(compensation_submission_data)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Optional: Only allow Excel files
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFile(acceptedFiles[0]);
    }
  });

  const onSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    setResponseErrors(null)
    setResponse(null)
    instance
      .post('/on-boarding/submit', formData)
      .then(res => {
        console.log(res)
        setLoading(false);

        dispatch(setCompensationSubmissionData(res.data))
        setFile(null)
        setResponse("Data processed successfully");
        //props.onNext('finish')
      })
      .catch(err => {
        console.log(err)
        setResponseErrors(err.response.data)
        setLoading(false);
        setResponse(`Error: ${err}`);
      });
  };

  const onDeleteFile = (e) => {
    e.preventDefault()
    setFile(null);
  };

  const isReadOnly = useSelector(onBoardingReadOnly)
  return (
    <div>
      {!isReadOnly &&
        <div className="py-5 border-2 rounded-md border-dashed text-center">
          <GenerateXLSX />
        </div>
      }

      {!isReadOnly &&
        <div>
          <h1 className="pb-3 text-lg mt-5">Upload Excel File</h1>

          <div {...getRootProps()} className="p-10 w-full h-56 flex justify-center cursor-pointer border-2 border-dashed rounded-md">
            <input {...getInputProps()} />
            {file ?
              <div className="flex flex-row items-center justify-between w-full border-2 border-solid py-3 px-3 rounded-md" style={{ height: '60px' }}>
                <div>{file.name}</div>
                <button onClick={onDeleteFile} className="ml-2 px-2 py-2 bg-gray-200 rounded-full hover:bg-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>

                </button>
              </div> :
              <>
                <div>{isDragActive ? <p>Drop the file here ...</p> : <p>Drag 'n' drop a file here, or click to select a file</p>}
                  <p className='text-center mt-4 text-red-400'>Only <b>xlsx</b> file is accepted</p>
                </div>
              </>
            }
          </div>
        </div>
      }

      { !isReadOnly &&
      <div className="flex justify-end mt-5 px-2 py-2">
        {!compensation_submission_data?.rows || file !== null ?

          <button
            onClick={onSubmit}
            disabled={loading || !file}
            className="ml-3 flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700"
          >
            {loading ? 'Processing...' : 'Submit'}
          </button>
          :
          <button
            onClick={() => props.onNext('finish')}
            disabled={loading}
            className="ml-3 flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700"
          >
            Next
          </button>
        }
      </div>
      }

      {/* {response && (
        <div className="mt-5 text-green-600">
          {response}
        </div>
      )} */}

      {
        responseErrors &&
        <ErrorTable data={responseErrors} />
      }

      {
        compensation_submission_data?.rows &&
        <SubmissionTable data={compensation_submission_data} />
      }
    </div>
  );
}
