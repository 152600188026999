// ** Reducers Imports

import authentication from "./authentication"

import company_info from "./onboard/company_info"
import hr_policies from "./onboard/benefits/hr_policies"
import compensation_practices from "./onboard/benefits/compensation_practices"
import incentives from "./onboard/benefits/incentives"
import well_being from "./onboard/benefits/well_being"
import other_benefits from "./onboard/benefits/other_benefits"
import insurance from "./onboard/benefits/insurance"
import expatriate_compensation from "./onboard/benefits/expatriate_compensation"
import compensation_info from "./onboard/compensation_info"
import compensation_submission from "./onboard/compensation_submission"
import market_data from "./market_data"
import talent from "./talent"
import app_data from "./app_data"

const rootReducer = { 
    authentication,
    // Onboarding
    company_info,
    hr_policies, compensation_practices, incentives, insurance, other_benefits, well_being, expatriate_compensation,
    compensation_info, compensation_submission,
    market_data, talent,
    app_data
}

export default rootReducer