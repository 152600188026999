import React from "react";
import { CURRENCY } from '../../../../configs/data/onboarding'
import Label from "../../../../components/forms/InputLabel";
import SelectInput from "../../../../components/forms/SelectInput";
import BlockHeading from "../../../../components/forms/BlockHeading";


export default function PayInformation(props) {
    const { errors, register } = props
    const id = 'pay_information'
    return (
        <div>
            <BlockHeading text="4. Pay Information" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-2">
                <div>
                    <Label htmlFor="salary_period" text="Pay reference period" />
                    <SelectInput id="salary_period" name={`${id}.salary_period`} register={register} options={[
                        { label: 'Select a value', value: '' },
                        { label: 'Annual', value: 'Annual' },
                        { label: 'Monthly', value: 'Monthly' },
                    ]} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="pay_currency" text="Currency used to pay employees" />
                    <SelectInput id="pay_currency" name={`${id}.pay_currency`} register={register} options={CURRENCY} errors={errors} />
                </div>
                <div>
                    <Label htmlFor="pay_financial_year" text="Financial Year Period" />
                    <SelectInput id="pay_financial_year" name={`${id}.pay_financial_year`} register={register} options={[
                        { label: 'Select a value', value: '' },
                        { label: 'January-December', value: 'January' },
                        { label: 'July-June', value: 'July' },
                    ]} errors={errors} />
                </div>


            </div>
        </div>
    );
}
