import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { getNestedError } from './utils';

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";

export function RadioTableInput(props) {
  const { register, value, errors, control, ...restProps } = props;
  const isError = !!getNestedError(errors, restProps.name);
  const errorStyle = isError ? { borderColor: 'red', borderWidth: '2px', borderStyle: 'solid' } : {};
  
  const isReadOnly = useSelector(onBoardingReadOnly);
  return (
    <Controller
      name={restProps.name}
      control={control}
      defaultValue={null}
      rules={{ required: true }} // This indicates the radio button is required.
      render={({ field }) => (
        <RadioGroup {...field} >
          <FormControlLabel 
            value={value} 
            control={<Radio disabled={isReadOnly} sx={{
              color: isError ? "#c62828" : "default"}} />} 
          />
        </RadioGroup>
      )}
    />
  );
}
