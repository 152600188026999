import React, { useState } from 'react'
import instance from "../../../../auth/useJwt"
import { ToastContainer, toast } from 'react-toastify';
export default function GenerateXLSX(props) {
    const [apiReq, setApiReq] = useState({
        loading: false, error: null, url: null
    })

    const fetchPreviousData = () => {
        setApiReq({ loading: true, error: null, url: null })
        instance.get("/on-boarding?submission=true").then(res => {
            console.log(res)
            window.open(res.data, '_blank');

            setApiReq({ loading: false, error: null, url: res.data })


        }).catch(err => {
            console.log(err)
            setApiReq({ loading: false, error: "Error generating xlsx file for compensation data", url: null })
            toast.error('Error generating xlsx file for compensation data', {
                position: "top-center",
                type: 'error',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }
    return (
        <div>
            <ToastContainer />
            <button onClick={fetchPreviousData}>{ apiReq.loading ? 'Generating...' : 'Generate XLSX' }</button>
        </div>
    )
}