import React from 'react';
import TextField from "@mui/material/TextField";

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../../../../../redux/app_data";

function MUITextInput(props) {
    const { value, onChange, name, index, required, ...restProps } = props;

    const isReadOnly = useSelector(onBoardingReadOnly);

    const variant = props.variant ? props.variant : 'outlined';
    var borderNone = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: "none"
            },
        }
    }

    return (

        <TextField
            value={value}
            onChange={onChange}
            size="small"
            name={name}
            disabled={isReadOnly}
            variant={variant}
            fullWidth
            // error={required ? isError : false}
            sx={borderNone}
            {...restProps}
        />

    );
}

export default MUITextInput;
