import { Grid } from '@mui/material';
import React from 'react';
import DataTable from './components/DataTable';
import HFilters from './components/HFilters';
import VFilters from './components/VFilters';
import PageLoader from '../../../components/Loaders/PageLoader';
import { useSelector } from 'react-redux';

export default function SGIComparisonTable(props) {

  const { data, loading, apiFilters } = useSelector(state => state.market_data)


  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item md={2}>
        <VFilters />
      </Grid>
      <Grid item md={10}>
        <div className='pb-5'>
          <HFilters />
        </div>
        {loading ?
          <PageLoader />
          :
          <DataTable data={data} />
        }
      </Grid>
    </Grid>
  );
}
