import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";

export default function SelectAutocomplete(props) {
    const { register, control, options, errors, ...restProps } = props;
    const isError = errors && errors[restProps.name];
    const baseClasses = `mt-1 p-1.5 w-full border rounded-sm text-gray-500 ${restProps.className}`;
    const errorClasses = isError ? 'border-red-500 bg-red-100' : '';
    
    const isReadOnly = useSelector(onBoardingReadOnly);

    return (
        <Controller
            name={restProps.name}
            control={control}
            defaultValue={[]} 
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    multiple
                    options={options || []}
                    disabled={isReadOnly}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                size="small"
                            />
                        ))
                    }
                    getOptionLabel={(option) => option.label}
                    value={typeof value === 'string' ? [] : value}
                    onChange={(_, newValue) => onChange(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} size="small" variant="outlined" fullWidth />
                    )}
                />
            )}
        />
    );
}