import { useState } from "react"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import { CheckboxInput } from "../../../../../../components/forms/CheckboxInput"
import MUITextInput from '../../../../../../components/forms/MUITextInput';
import { WR_COLORS } from "../../../../../../configs/colors"
import HeadCell from "../../../../../../components/forms/general"
import { useEffect } from "react"
import BlankRowCell from "../../../../../../components/forms/BlankRowCell"
const ORGANIZATION_PP = [
    'All employees eligible for the same benefit',
    ...ORGANIZATION_PEOPLE
]

const SUBSIDIES = [
    { label: 'Select a value', value: null },
    { label: "No", value: "No" },
    { label: "Yes - 100% subsidised", value: "Yes - 100% subsidised" },
    { label: "Yes - 51-90% subsidised", value: "Yes - 51-90% subsidised" },
    { label: "Yes - 50% subsidised", value: "Yes - 50% subsidised" },
    { label: "Yes - 25% subsidised", value: "Yes - 25% subsidised" },
    { label: "Other", value: "Other" },
]

const LOAN_NATURE = [
    { label: 'Select a value', value: null },
    { label: "Bank Facilitated", value: "Bank Facilitated" },
    { label: "Company Provided", value: "Company Provided" }
]

export default function EmployeeLoans(props) {
    const id = props.id
    const { watch, errors, control, register } = props

    const [CAR_HOUSE_LOAN_PP, setCAR_HOUSE_LOAN_PP] = useState([]);
    const [showCarHouseLoanTable, setShowCarHouseLoanTable] = useState(false);
    const id_map = {
        'House Loan': "house", 'Car Loan': "car"
    }
    const allEmployeesGeneralValue = watch(`${id}.general.All employees eligible for the same benefit`, {});
    const isAllEmployeesGeneralChecked = Object.values(allEmployeesGeneralValue).some(val => val);

    const otherRowsValues = ORGANIZATION_PP.filter(item => item !== "All employees eligible for the same benefit")
        .map(item => watch(`${id}.general.${item}`));
    const isAnyOtherRowFilled = otherRowsValues.some(row => Object.values(row || {}).some(val => Boolean(val)));




    const allEmployeesLoanValue = {};
    ['House Loan', 'Car Loan'].forEach(loanType => {
        allEmployeesLoanValue[loanType] = watch(`${id}.${id_map[loanType]}.All employees eligible for the same benefit`, {});
    });
    const isAllEmployeesLoanChecked = Object.values(allEmployeesLoanValue).some(loan => Object.values(loan).some(val => val));
    const otherRowsCheckedValues = ORGANIZATION_PP.filter(item => item !== 'All employees eligible for the same benefit').map(item => ({
        house: watch(`${id}.${id_map["House Loan"]}.${item}.Nature`, ""),
        car: watch(`${id}.${id_map["Car Loan"]}.${item}.Nature`, ""),
    }));

    const isAnyOtherRowChecked = otherRowsCheckedValues.some(row => row.house || row.car);

    // Any of car loan row select from any row
    // const ANY_CAR_LOAN = ORGANIZATION_PP.map(item => watch(`${id}.general.${item}.Car Loan`));
    // const ANY_HOUSE_LOAN = ORGANIZATION_PP.map(item => watch(`${id}.general.${item}.House Loan`));
    const [ac, setAC] = useState(0)
    const onClickA = () => {
        setAC(ac + 1)

    }


    useEffect(() => {

        const ANY_CAR_LOAN = ORGANIZATION_PP.map(item => watch(`${id}.general.${item}.Car Loan`));
        const ANY_HOUSE_LOAN = ORGANIZATION_PP.map(item => watch(`${id}.general.${item}.House Loan`));
        const NEW_LIST = []
        var SHOW_TABLE = false
        ORGANIZATION_PP.map((item, index) => {
            if (ANY_CAR_LOAN[index] || ANY_HOUSE_LOAN[index]) {
                SHOW_TABLE = true
            }

            NEW_LIST.push({
                title: item,
                house_loan: ANY_HOUSE_LOAN[index],
                car_loan: ANY_CAR_LOAN[index],
            })
        }
        );
        setShowCarHouseLoanTable(SHOW_TABLE)
        console.log("new_CAR_HOUSE_LOAN_PP", NEW_LIST);
        setCAR_HOUSE_LOAN_PP(NEW_LIST);
    }, [ac, watch(`${id}.offering`)])


    return (
        <div>

            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer loans to employees?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    {/* <Label className="mb-2"
                    text="Who is eligible?" /> */}

                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                {["Who is eligible", 'None', "Car Loan", "House/Land Ownership Loan", "Education Loan", "Personal Loan", "Medical Loan", "Salary Advance", "Other Loans"].map(smm => (
                                    <HeadCell style={{
                                        width: smm !== "Who is eligible" ? "100px" : "auto",
                                    }} key={`ghead-${smm}`}>{smm}</HeadCell>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PP.map((item, index) => {
                                    const isCurrentRowAllEmployees = item === "All employees eligible for the same benefit";

                                    if (isAnyOtherRowFilled && isCurrentRowAllEmployees || isAllEmployeesGeneralChecked && !isCurrentRowAllEmployees) {
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 py-2 border-b border-r">{item}</td>
                                                { // range of 8 columns
                                                    [...Array(8)].map((_, index) => (
                                                    <BlankRowCell style={{ width: 'auto' }} key={`blank-row-cell-${index}`} />
                                                ))}
                                            </tr>
                                        );
                                    }
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        {['None', "Car Loan", "House Loan", "Education Loan", "Personal Loan", "Medical Loan", "Salary Advance", "Other Loans"].map(smm_dw => (
                                            <td onClick={() => onClickA()} key={`loands_general-${item}.${smm_dw}`} className="px-2 border-b border-r">
                                                {/* if None is selected, dont render rest of the columns */}
                                                <CheckboxInput control={control} errors={errors} name={`${id}.general.${item}.${smm_dw}`} />
                                            </td>
                                        ))}

                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {CAR_HOUSE_LOAN_PP.length > 0 &&
                        <table className="w-full border mt-3">
                            <thead>
                                <tr style={{ backgroundColor: WR_COLORS.t_head_main }}>
                                    <HeadCell></HeadCell>
                                    <HeadCell colSpan={4}>House Loan</HeadCell>
                                    <HeadCell colSpan={4}>Car Loan</HeadCell>
                                </tr>
                                <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                    {["", 'Nature of House Loan', "Max. value amount", "Interest rate (%)", "Repayment Period (Years)",
                                        "Nature of Car Loan", "Max. value amount", "Interest rate (%)", "Repayment period (years)"].map((smm, smm_index) => (
                                            <HeadCell key={`car-home-${smm}-${smm_index}`} >{smm}</HeadCell>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    CAR_HOUSE_LOAN_PP.map((item, index) => {
                                        return (<tr key={`car-house-loading-row-${index}`}>
                                            <td className="px-2 py-2 border-b border-r">{item.title}</td>
                                            {['House Loan', 'Car Loan'].map((sb_item, sb_index) => {
                                                if (sb_item === 'House Loan' && !item.house_loan || sb_item === 'Car Loan' && !item.car_loan) return (
                                                    <>
                                                    { // range of 4 columns
                                                        [...Array(4)].map((_, index) => (
                                                        <BlankRowCell key={`blank-row-cell-${index}`} />
                                                    ))}
                                                    </>
                                                )

                                                return (
                                                    <>
                                                        {isAllEmployeesLoanChecked && item.title !== 'All employees eligible for the same benefit' || isAnyOtherRowChecked && item.title === 'All employees eligible for the same benefit' ? (
                                                            <>
                                                                { // range of 4 columns
                                                        [...Array(4)].map((_, index) => (
                                                        <BlankRowCell key={`blank-row-cell-${index}`} />
                                                    ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td className="px-2 border-b border-r" style={{ minWidth: '150px' }}>
                                                                    <SelectAutocompleteSingle border={"false"} id={`${id}.${id_map[sb_item]}.${item.title}.Nature`} name={`${id}.${id_map[sb_item]}.${item.title}.Nature`} control={control} options={LOAN_NATURE} errors={errors} />
                                                                </td>
                                                                <td className="px-2 border-b border-r">
                                                                    {/* <input type="number" className="border-none outline-none focus:outline-none w-full h-full" placeholder="Write here" /> */}
                                                                    <MUITextInput type="number" placeholder="Write here"
                                                                        border={"false"} id={`hc#${item.title}#${sb_item}#Amount`} name={`${id}.${id_map[sb_item]}.${item.title}.Amount`} control={control} errors={errors} />
                                                                </td>
                                                                <td className="px-2 border-b border-r">
                                                                    <MUITextInput type="number" placeholder="Write here"
                                                                        border={"false"} id={`hc#${item.title}#${sb_item}#InterestRate`} name={`${id}.${id_map[sb_item]}.${item.title}.InterestRate`} control={control} errors={errors} />
                                                                </td>
                                                                <td className="px-2 border-b border-r">
                                                                    <MUITextInput type="number" placeholder="Write here"
                                                                        border={"false"} id={`hc#${item.title}#${sb_item}#RepaymentPeriod`} name={`${id}.${id_map[sb_item]}.${item.title}.RepaymentPeriod`} control={control} errors={errors} />
                                                                </td>
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            }
                                            )}
                                        </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>
            }

        </div>
    )
}