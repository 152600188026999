export const WR_COLORS = {
    primary: "#8025EE",
    t_head: "#bd91ff",
    t_head_main: "#3BE9DF"
}

export const WR_GRAPH_COLORS = [
    "#F72585", "#7209B7", "#3A0CA3", "#4CC9F0", "#B5179E"
]

export const WR_PIE_GRAPH_COLORS = [
    "#7209B7", "#3A0CA3", "#4CC9F0", "#B5179E", "#F72585"
]