import React from 'react';

const IconButton = ({ icon, onClick }) => {
    return (
        <button
            className="inline-flex items-center justify-center p-2 border border-transparent rounded-full shadow-sm text-white bg-gray-100 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
            onClick={onClick}
        >
            <img src={icon} alt='Icon' />
        </button>
    );
};

export default IconButton;
