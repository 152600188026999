import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import { getNestedError } from './utils';

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";

function MUITextInput(props) {
    const { control, errors, required, ...restProps } = props;
    const isError = !!getNestedError(errors, restProps.name);
    
    const isReadOnly = useSelector(onBoardingReadOnly);

    const variant = props.variant ? props.variant : 'outlined';
    var borderNone = {};
    if (props.border === false || props.border === "false") {
        borderNone = isError ? {} : {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: "none"
                },
            }
        }
    }

    return (
        <Controller
            name={restProps.name}
            control={control}
            defaultValue=""
            rules={required ? { required: 'Value is required!' }: {}}
            render={({ field: { onChange, value } }) => (
                <TextField 
                    value={value}
                    onChange={onChange}
                    size="small" 
                    
                    disabled={isReadOnly}
                    variant={variant} 
                    fullWidth
                    error={required ? isError : false} 
                    sx={borderNone}
                    {...restProps}
                />
            )}
        />
    );
}

export default MUITextInput;
