import AuthLayout from "../../../components/layouts/Auth";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../configs/endpoints";
import { LoaderSVG } from "../../../components/icons/LoaderSvg";

export default function ResetPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm();

    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()


    const onSubmit = (data) => {

        setApiReq({ loading: true, error: null })
        axios.post(`${API_URL}/api/v1/password-reset`, {
            password: data.password, reset_id: searchParams.get("key")
        }).then(res => {

            setApiReq({ loading: false, error: null })
            navigate("/login")
        }).catch(err => {
            if (err?.response?.status) {
                setApiReq({ loading: false, error: err.response.data.message })
            } else {
                setApiReq({ loading: false, error: 'Error processing request' })
            }
        })
    }

    useEffect(() => {
        if (!searchParams.get("key")) {
            navigate("/login")
        }
    }, [])

    const validatePasswordMatch = (value) => {
        const password = getValues('password');
        if (value !== password) {
            return 'Passwords do not match';
        }
        return true;
    };

    return (
        <AuthLayout title="Reset Password">

            <div className="text-center mb-8">
                <h2 className="text-2xl font-semibold mt-4">Reset Password</h2>
                <p className="text-gray-600 mt-2">Please enter your new password.</p>
            </div>
            <form className="flex-grow" onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700">Password</label>
                    <input type="password" id="password" name="password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 8,
                                message: 'Password must be at least 8 characters long',
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                message:
                                    'Password must contain at least one lowercase letter, one uppercase letter, one special character, and a number',
                            },
                        })}
                        className="w-full px-4 py-2 border rounded-sm" />
                    {errors.password && <span className="text-red-600 text-sm">{errors.password.message}</span>}
                </div>
                <div className="mb-4">
                    <label htmlFor="confirmPassword" className="block text-gray-700">Confirm Password</label>
                    <input type="password" id="confirmPassword" name="confirmPassword"
                        {...register('confirmPassword', {
                            required: 'Please confirm your password',
                            validate: validatePasswordMatch, // Using custom validation function
                        })}
                        className="w-full px-4 py-2 border rounded-sm" />
                    {errors.confirmPassword && <span className="text-red-600 text-sm">{errors.confirmPassword.message}</span>}
                </div>



                <div className="text-red-600 text-md">{apiReq.error && apiReq.error}</div>

                <button type="submit" style={{ backgroundColor: '#8025EE' }} className="w-full flex justify-center bg-blue-600 text-white py-2 my-5 rounded-lg hover:bg-blue-700">
                    {apiReq.loading ?
                        <LoaderSVG />
                        :
                        `Change Password`
                    }
                </button>

                <div className="text-center mt-5">Don't have an account? <a href="/register" className="font-medium" style={{ color: '#8025EE' }}>Sign Up</a></div>
            </form>
        </AuthLayout>
    )
}
