import { Agriculture } from "./Agriculture";
import { COMMON_JF } from "./CommonJF";
import { COMMON_JFAll } from "./CommonJFAll";
import { ENERGY } from "./Energy";
import { FinancialServices } from "./FinancialServices";
import { IndustrialProducts } from "./IndustrialProducts";
import { ProfessionalServices } from "./ProfessionalServices";
import { PublicSector } from "./PublicSector";
import { TECHNOLOGY_MEDIA } from "./TechnologyCom";
import { TOURISM_HOSPITALITY } from "./TourismHospitality";
import { WR_LEVELS_DESCRIPTION } from "./WRLevelsDescription";

const CompensationFieldsData = {
    "Financial Services": FinancialServices,
    "COMMON_JF": COMMON_JF,
    "COMMON_JF_ALL" : COMMON_JFAll,
    "Technology, Media & Telecommunications" : TECHNOLOGY_MEDIA,
    "Professional Services" : ProfessionalServices,
    "Agriculture" : Agriculture,
    "Industrial Products & Construction" : IndustrialProducts,
    "Public Sector" : PublicSector,
    "Energy" : ENERGY,
    "Tourism & Hospitality" : TOURISM_HOSPITALITY
}

export { WR_LEVELS_DESCRIPTION };
export default CompensationFieldsData;