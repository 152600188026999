import { createSlice } from '@reduxjs/toolkit'


export const expatriateCompensationSlice = createSlice({
    name: 'expatriate_compensation',
    initialState: {
        data: {
            "contract": {
                
            },
            "data": {

            }
        }
    },
    reducers: {
        setExpatriateCompensationData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setExpatriateCompensationData } = expatriateCompensationSlice.actions

export default expatriateCompensationSlice.reducer