import React from "react";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function DeiProgram(props) {
  const { categories, total, data } = props?.data;
  return (
    <BCard
      title="Formal DEI Program/Strategy Presence"
      footer={getFooter(total)}
      note={getNote()}
    >
      <PieChart categories={categories} series={data} height="200px" />
    </BCard>
  );
}
