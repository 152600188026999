export const ProfessionalServices = {
    "Research": {
        "Market Research": {
            "job_family_descriptor": "Job family essential in helping businesses and organizations understand their target markets, consumer preferences, and market dynamics. Their insights play a critical role in shaping marketing strategies, product development, and overall business decision-making in the professional services industry",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Market Research Officer",
                    "position_descriptor": "Top executive responsible for the entire market research function within an organization. They provide strategic leadership, oversee research teams, and ensure that research efforts align with business objectives.",
                    "related_job_titles": " Chief Market Research Officer, Chief Research Officer, Head of Market Research, Market Research Executive."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Market Research Director",
                    "position_descriptor": "Experienced and senior leaders in the field who drive and oversee research initiatives, manage research teams, and provide strategic guidance to the organization.",
                    "related_job_titles": " Market Research Director, Director of Research, Senior Research Manager, Market Insights Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Market Research Manager",
                    "position_descriptor": " Senior Managers lead and manage research projects, coordinate research teams, and analyze complex data to provide actionable insights to clients or stakeholders.",
                    "related_job_titles": " Senior Market Research Manager, Senior Research Analyst, Research Project Manager, Market Analysis Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Market Research Manager",
                    "position_descriptor": " Market Research Managers are responsible for planning, coordinating, and executing market research projects. They oversee research teams, analyze data, and communicate findings.",
                    "related_job_titles": " Market Research Manager, Research Manager, Research Program Manager, Market Analysis Supervisor."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Market Research Analyst",
                    "position_descriptor": " Market Research Analysts collect, analyze, and interpret data related to market trends, consumer behavior, and competitive landscapes. They generate reports and recommendations.",
                    "related_job_titles": " Market Research Analyst, Research Analyst, Data Analyst, Marketing Research Specialist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Market Research Specialist",
                    "position_descriptor": " Market Research Specialists focus on specific areas of research, such as consumer insights or competitive intelligence. They conduct research activities and contribute to reports.",
                    "related_job_titles": " Market Research Specialist, Research Specialist, Consumer Insights Specialist, Competitive Intelligence Analyst."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Market Research Coordinator",
                    "position_descriptor": " Market Research Coordinators provide administrative support for research projects. They assist in data collection, scheduling, and communication with research teams.",
                    "related_job_titles": " Market Research Coordinator, Research Coordinator, Project Coordinator"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Market Research Officer",
                    "position_descriptor": " Market Research Officers play a key role in assisting with research activities, data collection, and report generation. They contribute to research projects and help coordinate research tasks. provides an intermediate step between entry-level assistants and coordinators, focusing on supporting research efforts and data collection in the Market Research field.",
                    "related_job_titles": " Market Research Officer, Research Officer, Research Associate, Junior Market Analyst."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Market Research Assistant",
                    "position_descriptor": " Entry-Level Market Research Assistants provide foundational support for research activities. They assist with data entry, basic research tasks, and administrative duties.",
                    "related_job_titles": " Market Research Assistant, Research Assistant, Junior Research Analyst, Data Entry Clerk."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Market Research Intern",
                    "position_descriptor": " Market Research Interns gain practical experience by supporting research teams. They assist with data collection, analysis, and research-related tasks.",
                    "related_job_titles": " Market Research Intern, Research Intern, Research Support Intern, Data Analysis Intern."
                }
            ]
        },
        "Environmental and Climate": {
            "job_family_descriptor": "This is a job family covering a range of expertise and responsibilities in addressing environmental sustainability and climate change challenges.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Environmental Officer / Environmental Director",
                    "position_descriptor": " The Chief Environmental Officer or Environmental Director is the highest-ranking executive responsible for an organization's environmental and climate strategies. They provide visionary leadership, oversee environmental policies and initiatives, and ensure compliance with regulations.",
                    "related_job_titles": " Chief Environmental Officer, Environmental Director, Director of Environmental Sustainability, Head of Climate Action."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Environmental Manager / Environmental Manager",
                    "position_descriptor": " Senior Environmental Managers or Environmental Managers lead environmental programs and teams within organizations. They develop and implement sustainability strategies, manage environmental compliance, and oversee environmental projects.",
                    "related_job_titles": " Senior Environmental Manager, Environmental Manager, Sustainability Manager, Climate Change Program Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Environmental Specialist / Senior Climate Analyst",
                    "position_descriptor": " Environmental Specialists or Senior Climate Analysts are experts in environmental science and climate analysis. They conduct research, analyze data, and provide recommendations for sustainable practices.",
                    "related_job_titles": " Environmental Specialist, Senior Climate Analyst, Climate Change Specialist, Environmental Policy Analyst."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Climate Scientist / Climate Researcher",
                    "position_descriptor": " Climate Scientists or Climate Researchers focus on studying climate patterns and impacts. They conduct climate research, model climate scenarios, and contribute to climate change understanding.",
                    "related_job_titles": " Climate Scientist, Climate Researcher, Climate Analyst, Climate Data Scientist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Environmental Analyst / Climate Data Analyst",
                    "position_descriptor": " Environmental Analysts or Climate Data Analysts work with data related to environmental and climate issues. They collect, process, and analyze data to inform decision-making and policy development.",
                    "related_job_titles": " Environmental Analyst, Climate Data Analyst, Environmental Data Scientist, Climate Research Analyst."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Environmental Analyst / Climate Data Analyst",
                    "position_descriptor": " Environmental Analysts or Climate Data Analysts work with data related to environmental and climate issues. They collect, process, and analyze data to inform decision-making and policy development.",
                    "related_job_titles": " Environmental Analyst, Climate Data Analyst, Environmental Data Scientist, Climate Research Analyst."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Environmental Technician / Climate Research Assistant",
                    "position_descriptor": " Environmental Technicians or Climate Research Assistants provide support to environmental and climate research projects. They assist with data collection, fieldwork, and research tasks.",
                    "related_job_titles": " Environmental Technician, Climate Research Assistant, Environmental Field Technician, Climate Research Associate."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Environmental Assistant",
                    "position_descriptor": " Entry-Level Environmental assistants gain hands-on experience by assisting environmental teams. They contribute to research, data entry, and basic environmental support.",
                    "related_job_titles": " Environmental Intern, Sustainability Intern, Climate Research Intern, Junior Environmental Analyst."
                }
            ]
        },
        "Scientific Research": {
            "job_family_descriptor": "The Scientific Research job family encompasses various scientific disciplines and research activities. Professionals in this field are engaged in advancing knowledge, conducting experiments, and contributing to scientific discoveries across industries and academia.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Research Director",
                    "position_descriptor": " The Chief Research Scientist or Research Director is the highest-ranking position in scientific research within the organisation. They lead research programs, set strategic research goals, secure funding, and oversee multidisciplinary research teams.",
                    "related_job_titles": " Chief Research Scientist, Research Director, Director of Research and Development, Head of Research."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Research Scientist / Principal Investigator",
                    "position_descriptor": "Experienced researchers who lead and conduct scientific investigations. They design experiments, collect data, analyze results, and publish findings.",
                    "related_job_titles": " Senior Research Scientist, Principal Investigator, Lead Scientist, Research Project Leader."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Research Scientist / Project Scientist",
                    "position_descriptor": " Research Scientists or Project Scientists work on research projects under the guidance of senior researchers. They perform experiments, data analysis, literature reviews, and contribute to research publications.",
                    "related_job_titles": " Research Scientist, Project Scientist, Associate Scientist, Research Analyst."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Research Scientist / Project Scientist",
                    "position_descriptor": " Research Scientists or Project Scientists work on research projects under the guidance of senior researchers. They perform experiments, data analysis, literature reviews, and contribute to research publications.",
                    "related_job_titles": " Research Scientist, Project Scientist, Associate Scientist, Research Analyst."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Research Associate / Junior Scientist",
                    "position_descriptor": " Research Associates or Junior Scientists assist in research activities by conducting experiments, collecting data, and providing support to senior researchers. They are often in the early stages of their research careers.",
                    "related_job_titles": " Research Associate, Junior Scientist, Research Assistant, Laboratory Technician."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Research Associate / Junior Scientist",
                    "position_descriptor": " Research Associates or Junior Scientists assist in research activities by conducting experiments, collecting data, and providing support to senior researchers. They are often in the early stages of their research careers.",
                    "related_job_titles": " Research Associate, Junior Scientist, Research Assistant, Laboratory Technician."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Laboratory Technician / Research Technician",
                    "position_descriptor": " Laboratory Technicians or Research Technicians are responsible for maintaining laboratory equipment, preparing samples, and assisting in experiments. They ensure the laboratory's smooth operation.",
                    "related_job_titles": " Laboratory Technician, Research Technician, Lab Assistant, Scientific Technician."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Research Intern / Junior Research Assistant",
                    "position_descriptor": " Research Interns or Junior Research Assistants are entry-level positions in scientific research. They gain hands-on experience by assisting in experiments, data entry, and basic research tasks.",
                    "related_job_titles": " Research Intern, Junior Research Assistant, Research Trainee, Laboratory Intern."
                }
            ]
        }
    },
    "Consulting": {
        "Business Consulting": {
            "job_family_descriptor": "The Business Consulting job family provides expert advice and recommendations to businesses in order to help them improve their operations and bottom line. They typically have a wide range of skills and knowledge in areas such as business strategy, marketing, finance, and operations.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Director",
                    "position_descriptor": " Directors in consulting are seasoned professionals who typically have a deep understanding of industry trends and client needs. They provide strategic leadership, oversee multiple consulting projects, and are responsible for the growth and profitability of their practice area.",
                    "related_job_titles": " Director of Consulting, Consulting Director, Practice Leader, Managing Director."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Principal / Associate Director",
                    "position_descriptor": " Principals or Associate Directors are senior-level consultants who often lead specific practice areas or industry groups. They are responsible for client relationships, project oversight, and strategic direction.",
                    "related_job_titles": " Principal Consultant, Associate Director, Practice Principal, Associate Partner."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": " Senior Managers are experienced consultants responsible for managing consulting teams and client relationships. They play a pivotal role in project delivery, client engagement, and business development.",
                    "related_job_titles": " Senior Consulting Manager, Engagement Manager, Senior Manager, Project Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": " Managers in consulting lead specific consulting projects or teams. They are responsible for project execution, client communication, and ensuring that project objectives are met on time and within budget.",
                    "related_job_titles": " Consulting Manager, Project Manager, Engagement Manager, Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Consultant",
                    "position_descriptor": " Senior Consultants are subject matter experts with in-depth knowledge of specific industries or functional areas. They analyze complex problems, provide strategic recommendations, and often serve as mentors to junior team members.",
                    "related_job_titles": " Senior Consultant, Senior Advisor, Senior Associate"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Consultant / Associate",
                    "position_descriptor": " Consultants or Associates work on consulting projects, conducting research, analyzing data, and assisting with client solutions. They collaborate with teams to deliver results.",
                    "related_job_titles": " Consultant, Associate Consultant, Associate"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Consultant / Associate",
                    "position_descriptor": " Consultants or Associates work on consulting projects, conducting research, analyzing data, and assisting with client solutions. They collaborate with teams to deliver results.",
                    "related_job_titles": " Consultant, Associate Consultant, Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Associate Consultant/Analyst",
                    "position_descriptor": " Associate Consultants or Analysts are typically entry-level professionals in consulting. They assist with data collection, research, and analysis, gaining experience in consulting methodologies.",
                    "related_job_titles": " Associate Consultant, Business Analyst, Consulting Analyst, Junior Consultant."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Consulting Assistant",
                    "position_descriptor": " Consulting Assistants provide administrative and operational support to consulting projects. They assist in scheduling, documentation, and research tasks, helping to streamline project workflows.",
                    "related_job_titles": " Consulting Assistant, Research Assistant, Project Assistant"
                }
            ]
        },
        "Accounting and Auditing": {
            "job_family_descriptor": "In this job family professionals are responsible for ensuring the accuracy, transparency, and compliance of financial and operational processes in various organizations",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Managing Partner",
                    "position_descriptor": " The Managing Partner is the highest-ranking executive in an audit firm and provides strategic leadership, oversee audit teams, and ensure compliance with auditing standards.",
                    "related_job_titles": " Managing Partner, Chief Audit Officer, Audit Practice Leader, Head of Audit."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Partner / Audit Director",
                    "position_descriptor": " Partners or Audit Directors are senior leaders within audit firms. They manage audit engagements, lead audit teams, and provide guidance on complex audit issues.",
                    "related_job_titles": " Audit Partner, Director of Audit, Senior Audit Manager, Audit Practice Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Audit Manager",
                    "position_descriptor": " Senior Audit Managers oversee audit projects and teams. They ensure the quality of audits, interact with clients, and address technical audit matters.",
                    "related_job_titles": " Senior Audit Manager, Audit Manager, Engagement Manager, Senior Assurance Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Audit Manager",
                    "position_descriptor": " Audit Managers lead audit assignments, manage audit staff, and coordinate the audit process. They are responsible for delivering high-quality audit services.",
                    "related_job_titles": " Audit Manager, Senior Auditor, Audit Supervisor, Assurance Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Auditor",
                    "position_descriptor": " Senior Auditors perform detailed audit work, including reviewing financial statements, internal controls, and audit procedures. They often specialize in specific industries or audit areas.",
                    "related_job_titles": " Senior Auditor, Senior Assurance Specialist, Audit Senior, Senior Audit Associate."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Auditor / Associate Auditor",
                    "position_descriptor": " Auditors work on audit engagements, conduct fieldwork, and test financial data for accuracy. They report findings and assist in preparing audit reports.",
                    "related_job_titles": " Auditor, Associate Auditor, Audit Associate, Assurance Associate."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Auditor / Associate Auditor",
                    "position_descriptor": " Auditors work on audit engagements, conduct fieldwork, and test financial data for accuracy. They report findings and assist in preparing audit reports.",
                    "related_job_titles": " Auditor, Associate Auditor, Audit Associate, Assurance Associate."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Associate Auditor / Audit Analyst",
                    "position_descriptor": " Associate Auditors or Audit Analysts are entry-level professionals in audit. They assist with data collection, analysis, and documentation for audit projects.",
                    "related_job_titles": " Associate Auditor, Audit Analyst, Junior Auditor, Audit Assistant."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Audit Assistant",
                    "position_descriptor": " Audit Assistants provide administrative support for audit engagements. They assist in scheduling, document management, and basic audit-related tasks.",
                    "related_job_titles": " Audit Assistant, Audit Support Assistant, Junior Audit Assistant, Audit Intern."
                }
            ]
        },
        "Financial Consulting": {
            "job_family_descriptor": " Provides advisory services to clients on various financial matters, including investment strategies, financial planning, and risk management.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Director",
                    "position_descriptor": " Directors in consulting are seasoned professionals who typically have a deep understanding of industry trends and client needs. They provide strategic leadership, oversee multiple consulting projects, and are responsible for the growth and profitability of their practice area.",
                    "related_job_titles": " Director of Consulting, Consulting Director, Practice Leader, Managing Director."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Principal / Associate Director",
                    "position_descriptor": " Principals or Associate Directors are senior-level consultants who often lead specific practice areas or industry groups. They are responsible for client relationships, project oversight, and strategic direction.",
                    "related_job_titles": " Principal Financial Consultant, Associate Director, Associate Partner, Finance Consulting Lead"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager",
                    "position_descriptor": " Senior Managers are experienced consultants responsible for managing consulting teams and client relationships. They play a pivotal role in project delivery, client engagement, and business development.",
                    "related_job_titles": " Senior Financial Consulting Manager, Senior Financial Consultant, Lead Financial Advisor"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager",
                    "position_descriptor": " Managers in consulting lead specific consulting projects or teams. They are responsible for project execution, client communication, and ensuring that project objectives are met on time and within budget.",
                    "related_job_titles": " Financial Consulting Manager, Project Manager, Engagement Manager, Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Consultant",
                    "position_descriptor": " Senior Consultants are subject matter experts with in-depth knowledge of specific industries or functional areas. They analyze complex problems, provide strategic recommendations, and often serve as mentors to junior team members.",
                    "related_job_titles": " Senior Consultant, Senior Advisor, Senior Associate, Financial Consulting Specialist"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Consultant / Associate",
                    "position_descriptor": " Consultants or Associates work on consulting projects, conducting research, analyzing data, and assisting with client solutions. They collaborate with teams to deliver results.",
                    "related_job_titles": " Consultant, Associate Consultant, Associate, Financial Advisor"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Consultant / Associate",
                    "position_descriptor": " Consultants or Associates work on consulting projects, conducting research, analyzing data, and assisting with client solutions. They collaborate with teams to deliver results.",
                    "related_job_titles": " Consultant, Associate Consultant, Associate, Financial Advisor"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Associate Consultant/Analyst",
                    "position_descriptor": " Associate Consultants or Analysts are typically entry-level professionals in consulting. They assist with data collection, research, and analysis, gaining experience in consulting methodologies.",
                    "related_job_titles": " Associate Consultant, Business Analyst, Consulting Analyst, Junior Financial Consultant."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Consulting Assistant",
                    "position_descriptor": " Consulting Assistants provide administrative and operational support to consulting projects. They assist in scheduling, documentation, and research tasks, helping to streamline project workflows.",
                    "related_job_titles": " Consulting Assistant, Research Assistant, Project Assistant"
                }
            ]
        },
        "Geographic Information System (GIS)": {
            "job_family_descriptor": "In this job family professionals work with spatial data and technology to analyze, visualize, interpret and advise on geographical information.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief GIS Officer / GIS Director",
                    "position_descriptor": " The Chief GIS Officer or GIS Director is the top executive responsible for the overall leadership and direction of GIS initiatives within an organization. They set the strategic vision, manage GIS teams, and oversee major GIS projects.",
                    "related_job_titles": " Chief GIS Officer, GIS Director, GIS Executive, Head of GIS."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior GIS Manager / GIS Manager",
                    "position_descriptor": " Senior GIS Managers or GIS Managers are responsible for managing GIS projects, teams, and resources. They provide leadership, client management, and strategic guidance to ensure successful GIS implementation.",
                    "related_job_titles": " Senior GIS Manager, GIS Manager, GIS Program Manager, GIS Project Leader."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "GIS Specialist / Senior GIS Analyst",
                    "position_descriptor": " GIS Specialists or Senior GIS Analysts are subject matter experts in geospatial technology and data analysis. They lead complex GIS projects, conduct spatial analysis, and offer advanced solutions.",
                    "related_job_titles": " GIS Specialist, Senior GIS Analyst, Geospatial Specialist, GIS Solution Architect."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "GIS Developer / GIS Programmer",
                    "position_descriptor": " GIS Developers or GIS Programmers focus on creating and customizing GIS software, applications, and tools. They develop code and solutions to address specific project requirements.",
                    "related_job_titles": " GIS Developer, GIS Programmer, Geospatial Developer, GIS Software Engineer."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "GIS Database Administrator / GIS DBA",
                    "position_descriptor": " GIS Database Administrators or GIS DBAs manage GIS databases, ensuring data integrity, accessibility, and optimization. They handle database-related tasks and support GIS projects.",
                    "related_job_titles": " GIS Database Administrator, GIS DBA, Geospatial Database Administrator, Database Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "GIS Database Administrator / GIS DBA",
                    "position_descriptor": " GIS Database Administrators or GIS DBAs manage GIS databases, ensuring data integrity, accessibility, and optimization. They handle database-related tasks and support GIS projects.",
                    "related_job_titles": " GIS Database Administrator, GIS DBA, Geospatial Database Administrator, Database Specialist."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "GIS Analyst / GIS Technician",
                    "position_descriptor": " GIS Analysts or GIS Technicians play a pivotal role in data collection, management, and analysis. They maintain GIS databases, create maps, and support day-to-day GIS operations.",
                    "related_job_titles": " GIS Analyst, GIS Technician, Geospatial Analyst, Mapping Technician."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "GIS Assistant",
                    "position_descriptor": " Entry-Level GIS assistant gain hands-on experience by assisting GIS teams. They perform tasks such as data entry, map creation, and basic GIS support.",
                    "related_job_titles": " GIS Assistant, GIS Research Intern, Junior GIS Technician, Geospatial Intern."
                }
            ]
        }
    },
    "Research and Academia": {
        "Research and Academia": {
            "job_family_descriptor": "The Scientific job family encompasses professionals who are dedicated to the pursuit of knowledge, discovery, and innovation across various scientific disciplines. Members of this job family apply the scientific method to investigate, analyze, and solve complex problems, contributing to advancements in their respective fields. These professionals are characterized by their rigorous research, experimentation, data analysis, and the dissemination of findings to peers and the wider community. Scientific roles often require a deep understanding of specialized subject matter and a commitment to advancing the boundaries of human knowledge.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Distinguished Scientist/Distinguished Professor",
                    "position_descriptor": " Distinguished scientists or professors are at the pinnacle of their careers. They are internationally recognized experts in their field and have made significant, enduring contributions to science. They often receive prestigious awards, serve on advisory boards, and play a key role in shaping the direction of their discipline.",
                    "related_job_titles": " Distinguished Scientist, Distinguished Professor"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Distinguished Scientist/Distinguished Professor",
                    "position_descriptor": " Distinguished scientists or professors are at the pinnacle of their careers. They are internationally recognized experts in their field and have made significant, enduring contributions to science. They often receive prestigious awards, serve on advisory boards, and play a key role in shaping the direction of their discipline.",
                    "related_job_titles": " Distinguished Scientist, Distinguished Professor"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Scientist/Professor",
                    "position_descriptor": " Senior scientists and professors have extensive experience and expertise in their respective fields. They have demonstrated a consistent record of high-impact research, often with numerous publications and grant funding. They may mentor junior researchers and contribute to academic leadership.",
                    "related_job_titles": " Senior Scientist, Professor"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Associate Scientist/Associate Professor",
                    "position_descriptor": " Associates are established researchers who have typically completed several years of independent research. They have a strong publication record and often secure research grants. They may also teach and supervise graduate students.",
                    "related_job_titles": " Associate Scientist, Associate Professor"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Assistant Scientist/Assistant Professor",
                    "position_descriptor": " Assistant scientists or assistant professors are in the early stages of their independent careers. They conduct research, publish papers, and often seek external funding for their work. They may also teach undergraduate or graduate courses.",
                    "related_job_titles": " Assistant Scientist, Assistant Professor"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Postdoctoral Researcher",
                    "position_descriptor": " Postdoctoral researchers have earned their Ph.D. and are gaining specialized research experience. They work under the mentorship of established scientists, contributing to ongoing research projects and often publishing papers.",
                    "related_job_titles": " Postdoctoral Researcher"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Ph.D. Student/Graduate Researcher",
                    "position_descriptor": " Ph.D. students or graduate researchers are pursuing their doctoral degrees while actively conducting research. They are typically early in their research careers, focusing on a specific topic or area.",
                    "related_job_titles": " Ph.D. Student, Graduate Researcher"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Ph.D. Student/Graduate Researcher",
                    "position_descriptor": " Ph.D. students or graduate researchers are pursuing their doctoral degrees while actively conducting research. They are typically early in their research careers, focusing on a specific topic or area.",
                    "related_job_titles": " Ph.D. Student, Graduate Researcher"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Research Assistant/Associate",
                    "position_descriptor": " Research assistants or associates are often at the entry level of a scientific career. They support research projects by conducting experiments, collecting data, and assisting more senior researchers.",
                    "related_job_titles": " Research Assistant, Research Associate"
                }
            ]
        }
    }
}