import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useForm } from 'react-hook-form';
import CompensationStructure from './CompensationStructure';
import AnnualCompensationAdjustments from './AnnualCompensationAdjustments';
import { setCompPracticeData } from '../../../../../../redux/onboard/benefits/compensation_practices';
import { toast } from 'react-toastify';


export default function CompensationPractices(props) {

    const compensation_practices = useSelector(state => state.compensation_practices)
    const { data } = compensation_practices
    const dispatch = useDispatch()
    const { register, handleSubmit, watch, reset, setValue, control,
        formState: { errors, isDirty },
    } = useForm();

    useEffect(() => {
        reset(data)
    }, [])

    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])

    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    
    }





    const onSubmit = (form_data) => {
        // Only save when isDirty
        if (isDirty){
            dispatch(setCompPracticeData(form_data))
            props.onSaveClick("benefits", "compensation_practices", form_data)
        }
        props.changeTab(2)
    };


    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CompensationStructure control={control} watch={watch} register={register} errors={errors} reset={reset} setValue={setValue} />
            <AnnualCompensationAdjustments control={control} watch={watch} register={register} errors={errors} />

            <div className="flex justify-end mt-5 px-2 py-2">
                <button type='submit' className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}