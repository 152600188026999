import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function GeneralTooltip(props) {
    const { description } = props
    return (

        <HtmlTooltip
            title={
                <React.Fragment>
                    <div className="py-1" role="none">
                        <div
                            className="block px-4 py-2 text-sm text-gray-700"
                            role="menuitem"
                        >
                            <p>{
                                description
                            }
                            </p>
                        </div>
                    </div>
                </React.Fragment>
            }
        >
            {props.children}
        </HtmlTooltip>
    );
}