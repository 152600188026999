import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function LongTermIncentives(props) {
  if (!props.data) return null;


  const { offering } = props.data;
  const { data } = props;
  return (
    <div>
      <BlockHeading text="3. Long Term Incentives" />
      <div className="my-2">
        <BCard
          title={"Long Term Incentives Offered"}
          footer={getFooter(offering?.total)}
          note={getNote()}
        >
          <PieChart
            categories={offering.categories}
            series={offering.data}
            height="250"
          />
        </BCard>
      </div>
      <div className="my-2">
        <TableCharts data={data.data} />
      </div>
    </div>
  );
}


function TableCharts(props) {
  console.log("props", props.data)
  const offering = null
  const {
    direct_purchase_plans_eligibility, other, payout_frequency, service_based_restricted_shares_eligibility, stock_options_eligibility
  } = props.data;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">

      <BCard
        title={"Stock Options Eligibility"}
        footer={getFooter(offering?.total)}
        note={getNote()}
        total={offering?.total}
      >
        <BarChart
          percentage={true}
          stacked={true}
          categories={stock_options_eligibility?.categories}
          series={stock_options_eligibility?.data}
          height="auto"
        />
      </BCard>
      <BCard
        title={"Frequency Of Payout"}
        footer={getFooter(offering?.total)}
        note={getNote()}
        total={offering?.total}
      >
        <BarChart
          percentage={true}
          stacked={true}
          categories={payout_frequency?.categories}
          series={payout_frequency?.data}
          height="auto"
        />
      </BCard>
      <BCard
        title={"Direct Purchase Plans Eligibility"}
        footer={getFooter(offering?.total)}
        note={getNote()}
        total={offering?.total}
      >
        <BarChart
          percentage={true}
          stacked={true}
          categories={direct_purchase_plans_eligibility?.categories}
          series={direct_purchase_plans_eligibility?.data}
          height="auto"
        />
      </BCard>
      <BCard
        title={"Service-Based Restricted Shares Eligibility"}
        footer={getFooter(offering?.total)}
        note={getNote()}
        total={offering?.total}
      >
        <BarChart
          percentage={true}
          stacked={true}
          categories={service_based_restricted_shares_eligibility?.categories}
          series={service_based_restricted_shares_eligibility?.data}
          height="auto"
        />
      </BCard>
      <BCard
        title={"Other"}
        footer={getFooter(offering?.total)}
        note={getNote()}
        total={offering?.total}
      >
        <BarChart
          percentage={true}
          stacked={true}
          categories={other?.categories}
          series={other?.data}
          height="auto"
        />
      </BCard>
    </div>
  )

}