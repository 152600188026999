import { Card } from '@mui/material';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMarketFilters } from '../../../../redux/market_data';

function SelectFilter(props) {
    const { title, name, options } = props
    return (
        <>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">{title}</label>
            <select id="role" name={name} 
            onChange={props.onChange}
            className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value={""}>Choose...</option>
                {/* You can list your options here */}
                {options.map((option, index) => {
                    return <option key={index} value={option}>{option}</option>
                })}
            </select>
        </>
    )
}


export default function VFilters() {
    const { uniqueRoles, uniqueGrades, uniqueIndustries, uniqueJobFamilies, uniqueLocations, uniqueSectors } = useSelector(state => state.market_data)
    const [vFilters, setVFilters] = useState({
        industry: null, role: null, grade: null
    })
    const dispatch = useDispatch()
  
    
    const onChane = (e) => {
        setVFilters({
            ...vFilters,
            [e.target.name]: e.target.value
        })
        console.log(vFilters)
    }

    const filterJobs = () => {
        dispatch(setMarketFilters(vFilters))
    }
    return (
        <Card className="py-6 px-3" sx={{ border: '1px solid #eee' }}>
            <h1 className="text-xl font-semibold mb-4">Filter</h1>

            <div className="mb-4">
                <SelectFilter name={"jobFamily"} title="Job Family" options={uniqueJobFamilies} onChange={onChane} />
            </div> 

            <div className="mb-4">
                <SelectFilter name={"role"} title="Role" options={uniqueRoles} onChange={onChane} />
            </div>

            <div className="mb-4">
                <SelectFilter name={"grade"} title="Grade" options={uniqueGrades} onChange={onChane} />
            </div>

            <div className="mb-4">
                <SelectFilter name={"industry"} title="Industry" options={uniqueIndustries} onChange={onChane} />
            </div>
            <div className="mb-4">
                <SelectFilter name={"location"} title="Location" options={uniqueLocations} onChange={onChane} />
            </div>

            <div className="mb-4">
                <SelectFilter name={"sector"} title="Sector" options={uniqueSectors} onChange={onChane} />
            </div>
            {/* ... replicate for other filters ... */}
            <div className='flex justify-center'>

            <button
            onClick={() => filterJobs() }
            className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700">
                Filter Jobs
            </button>
            </div>
        </Card>
    );
}
