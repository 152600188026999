import React from "react";
import DashboardLayout from "../../components/layouts/Dashboard";

import Box from '@mui/material/Box';
import FaqAccordins from "./FaqAccordins";



export default function FAQ(props) {



  return (
    <DashboardLayout>

      <Box sx={{ width: '100%' }} className="px-4 mt-2">
        <h3 className="text-2xl text-bold text-center py-5">Frequently Asked Questions (FAQs)</h3>

        <Box sx={{ mt: 2 }}>
          <FaqAccordins />
        </Box>
      </Box>


    </DashboardLayout>
  );
}

