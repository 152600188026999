import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { YES_NO } from "../../../../../../configs/data/onboarding"
import { num_generator } from "../../utils/generators"
import { CheckboxInput } from "../../../../../../components/forms/CheckboxInput"
import { WR_COLORS } from "../../../../../../configs/colors"


const MEDICAL_INSURANCE_PAYER = [
    { label: "100% funded by employer", value: "100% funded by employer" },
    { label: "81%-90% funded by employer", value: "81%-90% funded by employer" },
    { label: "70%-80% funded by employer", value: "70%-80% funded by employer" },
    { label: "50%-69% funded by employer", value: "50%-69% funded by employer" },
    { label: "26-49% funded by employer", value: "26-49% funded by employer" },
    { label: "<25% funded by employer", value: "<25% funded by employer" },
]

const MEDICAL_SCHEME_ADMINISTERED = [
    { label: "External Insurance Cover", value: "External Insurance Cover" },
    { label: "Self Administered by Employer", value: "Self Administered by Employer" },
]

const REGION_OF_COVER = [
    { label: "All Africa coverage", value: "All Africa coverage" },
    { label: "Current country and India", value: "Current country and India" },
    { label: "Current country and Kenya", value: "Current country and Kenya" },
    { label: "Current country and South Africa", value: "Current country and South Africa" },
    { label: "Current country, India, South Africa", value: "Current country, India, South Africa" },
    { label: "Current country only", value: "Current country only" },
    { label: "East Africa coverage", value: "East Africa coverage" },
    { label: "Mozambique, India and Portugal", value: "Mozambique and India, Portugal" },
    { label: "Worldwide excluding USA", value: "Worldwide excluding USA" },
    { label: "Other", value: "Other" },
];


export default function MedicalCover(props) {
    const id = "medical_cover"
    const { watch, errors, control } = props


    const allEmployeesValues = watch(`${id}.eligibility.All Employees`);

    // This would watch the values for the other categories
    const topManagementValues = watch(`${id}.eligibility.Top Management/Management`);
    const belowManagementValues = watch(`${id}.eligibility.All employees below management`);

    const isAnyAllEmployeesCheckboxChecked = Object.values(allEmployeesValues || {}).some(val => val);
    const isAnyOtherCheckboxChecked = Object.values(topManagementValues || {}).concat(Object.values(belowManagementValues || {})).some(val => val);

    return (
        <div>
            <BlockHeading text="1. MEDICAL COVER" />
            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your company offer medical insurance/medical cover?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {watch(`${id}.offering`) === "Yes" &&
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                        <div>
                            <Label className="mb-2" htmlFor="medical_cover-insurance_payer"
                                text="How is your medical cover administered?" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.insurance_payer`}
                                errors={errors} watch={watch}
                                options={MEDICAL_SCHEME_ADMINISTERED} control={control} />
                        </div>
                        <div>
                            <Label className="mb-2" htmlFor="medical_cover-insurance_payer"
                                text="How is the medical cover funded?" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.funded`}
                                errors={errors} watch={watch}
                                options={MEDICAL_INSURANCE_PAYER} control={control} />
                        </div>
                    </div>
                    <div className="my-2">
                        {/* <Label className="mb-2"
                            text="Who is eligible?" /> */}

                        <table className="w-full border">
                            <thead>
                                <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                    <td className="px-2 border-b border-r">Eligible Levels</td>
                                    <td className="px-2 py-2 border-b border-r">Employee Only</td>
                                    <td className="px-2 border-b border-r">Spouse</td>
                                    <td className="px-2 border-b border-r">Children</td>
                                    <td className="px-2 border-b border-r">Employee Parents</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ["All Employees", "Top Management/Management", "All employees below management"].map((item, index) => {
                                        if (isAnyAllEmployeesCheckboxChecked && item !== "All Employees" || isAnyOtherCheckboxChecked && item === "All Employees") {
                                            return <tr key={`medical_cover-eligibility-${index}`}><td className="px-2 py-2 border-b border-r">{item}</td>
                                                <td className="px-2 py-2 border-b border-r"></td>
                                                <td className="px-2 py-2 border-b border-r"></td>
                                                <td className="px-2 py-2 border-b border-r"></td>
                                                <td className="px-2 py-2 border-b border-r"></td>


                                            </tr>;
                                        }
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 border-b border-r">{item}</td>
                                                {["Employee only", "Spouse", "Children", "Employee Parents"].map((check_item, check_index) => {
                                                    return (
                                                        check_item === "Children" ?
                                                            <td key={`tcell-${index}-${check_index}`} className="border-b border-r">
                                                                <SelectAutocompleteSingle
                                                                    required
                                                                    border={false}
                                                                    name={`${id}.eligibility.${item}.${check_item}`}
                                                                    errors={errors} watch={watch}
                                                                    options={[
                                                                        ...num_generator(7, true),
                                                                        { label: "Uncapped", value: "Uncapped" }
                                                                    ]} control={control} />
                                                            </td>
                                                            :
                                                            <td key={`tcell-${index}-${check_index}`} className="px-2 border-b border-r">
                                                                <CheckboxInput control={control} errors={errors} name={`${id}.eligibility.${item}.${check_item}`} />
                                                            </td>
                                                    )
                                                })}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                        <div>
                            <Label className="mb-2" htmlFor="medical_cover-region_cover"
                                text="What is the region of cover?" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.region`}
                                errors={errors} watch={watch}
                                options={REGION_OF_COVER} control={control} />
                        </div>
                        <div>
                            <Label className="mb-2" htmlFor="medical_cover-insurance_emergency_evacuation"
                                text="Does your medical insurance/medical cover include emergency evacuation?" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.insurance_emergency_evacuation`}
                                errors={errors} watch={watch}
                                options={YES_NO} control={control} />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}