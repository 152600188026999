import React from "react";
import DashboardLayout from "../../../components/layouts/Dashboard";

import Box from '@mui/material/Box';

export default function WR_LEVELS(props) {
    const PDF_URL = "https://storage.googleapis.com/cdn-theworkrate-com/main/assets/TheWorkRate%20Levels%20Descriptors.pdf#toolbar=0"


  return (
    <DashboardLayout>

      <Box sx={{ width: '100%' }} className="px-4 mt-2">
        <h3 className="text-2xl text-bold text-center py-5">WR Levels</h3>

        <Box sx={{ mt: 2 }}>
            {/* Embed Pdf */}
            <object
            aria-label="pdf"
            id="fgh" data={PDF_URL} style={{width: "100%", height:"80vh"}} ></object>
        </Box>
      </Box>


    </DashboardLayout>
  );
}

