export const TOURISM_HOSPITALITY = {
    "Food and Beverages": {
        "Hospitality and Catering Management": {
            "job_family_descriptor": "Job family encompasses roles responsible for planning, organizing, and overseeing hospitality services, events, and catering operations. Professionals in this job family play a crucial role in managing the various aspects of hospitality businesses, including hotels, restaurants, catering companies, and event venues",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Executive Officer (CEO)",
                    "position_descriptor": "The CEO oversees the entire hospitality and catering operation, providing strategic leadership, making high-level decisions, and ensuring the overall success of the business.",
                    "related_job_titles": "Chief Executive Officer (CEO)"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Operating Officer (COO)",
                    "position_descriptor": "The COO is responsible for day-to-day operations, implementing policies, and ensuring efficient business processes. They work closely with the CEO to execute the organization's strategy.",
                    "related_job_titles": "Chief Operating Officer (COO) "
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Catering and Events",
                    "position_descriptor": "This role involves overseeing the planning and execution of catering services and events. Responsibilities include coordinating staff, managing budgets, and ensuring client satisfaction.",
                    "related_job_titles": "Director of Catering Services, Events Planning Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "General Manager - Hospitality and Catering",
                    "position_descriptor": "General Managers are responsible for the overall performance of the catering and hospitality establishment. They oversee daily operations, manage staff, and ensure customer satisfaction.",
                    "related_job_titles": "General Manager, Catering and Hospitality, Hospitality General Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Catering / Food and Beverage/Restaurant Manager",
                    "position_descriptor": "Restaurant Managers are responsible for the day-to-day operations of a restaurant. They oversee staff, manage budgets, ensure customer satisfaction, and contribute to strategic planning. Catering Managers focus on planning and executing catering services. They coordinate with clients, manage budgets, supervise staff, and ensure successful events. F&B Managers oversee the food and beverage operations, including menu planning, purchasing, and ensuring high-quality service. They collaborate with chefs and staff to optimize offerings.",
                    "related_job_titles": "Catering Services Manager, Event Catering Manager, Restaurant General Manager, Dining Establishment Manager,F&B Operations Manager, Food Services Manager,"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Banquet Manager / Assistant Managers",
                    "position_descriptor": " Banquet Managers specialize in coordinating banquet events, ensuring smooth operations, managing staff, and meeting client expectations for events and gatherings.",
                    "related_job_titles": " Banquet Events Manager, Special Events Manager, Catering Operations Assistant Manager, Restaurant Deputy Manager,"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Supervisors",
                    "position_descriptor": "Supervisors support the Manager in planning and executing catering services. They may oversee specific aspects of events and assist with administrative tasks.",
                    "related_job_titles": "Supervisor Catering Services, F&B Operations Supervisor"
                }
            ]
        },
        "Culinary Arts and Kitchen Management": {
            "job_family_descriptor": "Job family encompasses a range of roles within the food and beverage sector, with professionals specializing in culinary arts, kitchen operations, and management. Individuals in this job family contribute to the creation, preparation, and presentation of food in culinary establishments, ensuring the highest standards of quality, taste, and service.",
            "levels": [
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Executive Chef",
                    "position_descriptor": "Responsible for the overall culinary vision, menu development, and kitchen management.",
                    "related_job_titles": "Executive Chef"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Sous Chef",
                    "position_descriptor": "Assists the executive chef, oversees kitchen operations, and ensures food quality.",
                    "related_job_titles": "Sous Chef"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Head Chef",
                    "position_descriptor": "Leads a specific kitchen section, responsible for menu items and kitchen staff.",
                    "related_job_titles": "Head Chef"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Chef de Partie (Station Chef)/Pastry Chef",
                    "position_descriptor": "Chef de Partie (Station Chef) manages a specific station or area in the kitchen, specializing in certain dishes or techniques. Pastry Chef Specializes in creating pastries, desserts, and baked goods.",
                    "related_job_titles": "Chef de Partie, Station Chef,Pastry Chef"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Commis Chef",
                    "position_descriptor": "Entry-level position, assists chefs in various tasks and gains hands-on kitchen experience.",
                    "related_job_titles": "Commis Chef"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Kitchen Supervisor",
                    "position_descriptor": "Supervises kitchen staff, ensuring smooth operations during shifts.",
                    "related_job_titles": "Kitchen Supervisor"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Line Cook",
                    "position_descriptor": "Prepares and cooks food items according to established recipes.",
                    "related_job_titles": "Line Cook"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Prep Cook",
                    "position_descriptor": "Assists with food preparation tasks, such as chopping, slicing, and ingredient assembly.",
                    "related_job_titles": "Prep Cook"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Dishwasher / Kitchen Porter",
                    "position_descriptor": "Responsible for cleaning dishes, utensils, and maintaining kitchen cleanliness.",
                    "related_job_titles": "Dishwasher / Kitchen Porter"
                }
            ]
        },
        "Food and Beverage Service": {
            "job_family_descriptor": "Professionals in this job family focus on ensuring a positive dining experience for guests by providing excellent customer service, taking orders, serving meals and beverages, and maintaining a welcoming and hospitable atmosphere. The primary goal is to meet customer expectations, uphold quality standards, and contribute to the overall success of the establishment",
            "levels": [
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Restaurant Manager",
                    "position_descriptor": " Manages the operations of a specific restaurant or dining establishment, overseeing staff, ensuring customer satisfaction, and implementing policies to enhance efficiency and profitability.",
                    "related_job_titles": " General Manager, Dining Manager, Restaurant Operations Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Assistant Restaurant Manager",
                    "position_descriptor": " Supports the restaurant manager in daily operations, including staff supervision, customer service, and ensuring adherence to standards and policies.",
                    "related_job_titles": " Assistant Manager, Assistant Dining Manager, Assistant F&B Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Front of House Supervisor",
                    "position_descriptor": " Oversees the service staff, ensuring smooth operations during service hours. Manages reservations, addresses customer concerns, and maintains a positive dining experience.",
                    "related_job_titles": "Front-of-House Manager, Guest Services Supervisor, Dining Room Supervisor"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Head Waiter / Head Waitress",
                    "position_descriptor": " Coordinates and supervises the waitstaff, takes orders, and ensures a high level of customer service. May also assist in staff training and scheduling.",
                    "related_job_titles": " Head Server, Lead Waiter, Dining Room Lead"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Host/Hostess",
                    "position_descriptor": "Hosts or hostesses are responsible for welcoming guests, managing reservations, and coordinating seating arrangements in restaurants or hospitality establishments. They are often the first point of contact for patrons",
                    "related_job_titles": "Host, Hostess, Greeter, Seating Coordinator, Reservationist"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Waiter / Waitress",
                    "position_descriptor": " Waiters provides table service to customers, takes orders, and ensures a pleasant dining experience. Responsible for menu knowledge, order accuracy, and customer satisfaction. Includes Bartender prepares and serves beverages, often specializing in cocktails. Interacts with customers, takes orders, and maintains a clean and organized bar area.",
                    "related_job_titles": "  Server, Waitstaff, Waiter, Waitress, Barman, Mixologist, Cocktail Server"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Busser",
                    "position_descriptor": "Bussers play a crucial role in maintaining a clean and organized dining environment. They support the waitstaff by clearing and resetting tables, assisting in maintaining a clean dining environment, and providing additional support. They are considered entry-level positions in the front-of-house staff",
                    "related_job_titles": "Busboy, Busgirl,  Dining Room Assistant"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Dining Room Attendant",
                    "position_descriptor": "Dining Room Attendants are typically responsible for maintaining cleanliness in the dining area, assisting with table setup, and supporting overall dining operations. This role is often an entry-level position",
                    "related_job_titles": "Floor Attendant, Service Assistant, Table Maintenance Staff"
                }
            ]
        },
        "Beverage Production and Management": {
            "job_family_descriptor": "Job family involves overseeing the creation, production, and management of beverages in various hospitality settings. Professionals in this family focus on beverage quality, selection, and service, ensuring an exceptional and diverse drink experience for customers.",
            "levels": [
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Director of Beverage Operations",
                    "position_descriptor": "Responsibilities include overseeing the entire beverage department, developing beverage strategies, and managing the implementation of beverage programs across the organization.",
                    "related_job_titles": "Director of Beverage Operations "
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Beverage Manager",
                    "position_descriptor": "Responsibilities involve managing the day-to-day operations of the beverage department, including staff supervision, inventory management, and ensuring the quality of beverage service.",
                    "related_job_titles": "Beverage Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Sommelier / Head Bartender",
                    "position_descriptor": "Responsibilities include expert knowledge of wines, spirits, or cocktails, advising customers on beverage choices, and overseeing the training of beverage staff.",
                    "related_job_titles": "Sommelier / Head Bartender"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Bar Manager",
                    "position_descriptor": "Responsibilities involve managing the operations of bars within an establishment, including staff supervision, inventory control, and ensuring a high standard of customer service.",
                    "related_job_titles": "Bar Manager"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Beverage Supervisor",
                    "position_descriptor": "Responsibilities include supervising beverage service staff, ensuring adherence to standards, and assisting in day-to-day operations.",
                    "related_job_titles": "Beverage Supervisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Bartender / Mixologist",
                    "position_descriptor": "Responsibilities involve preparing and serving beverages, creating cocktails, and providing excellent customer service.",
                    "related_job_titles": " Barman, Mixologist, Cocktail Server"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Barista",
                    "position_descriptor": "Responsibilities include preparing and serving coffee-based beverages, providing customer service, and maintaining cleanliness in coffee service areas.",
                    "related_job_titles": " Coffee Server, Espresso Specialist, Coffee Bar Attendant"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Beverage Server",
                    "position_descriptor": "Responsibilities include serving beverages to customers, taking orders, and ensuring customer satisfaction.",
                    "related_job_titles": "Beverage Server"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Barback / Beverage Runner",
                    "position_descriptor": "Entry-level position responsible for supporting the beverage service staff, restocking supplies, and assisting in maintaining a clean and organized bar area.",
                    "related_job_titles": "Barback / Beverage Runner"
                }
            ]
        }
    },
    "Travel and Tourism": {
        "Travel Agencies and Tour Operations": {
            "job_family_descriptor": "Job family encompasses roles dedicated to facilitating travel experiences for individuals or groups. Professionals in this family work within travel agencies, tour companies, and related businesses to assist clients in planning, booking, and organizing various aspects of their travel itineraries.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Executive Officer (CEO) - Travel Operations",
                    "position_descriptor": " Overall leadership, strategic planning, and decision-making for the entire travel agency or tour operation.",
                    "related_job_titles": "Chief Executive Officer (CEO) - Travel Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Operations Officer (COO) - Travel Operations",
                    "position_descriptor": " Overseeing day-to-day operations, implementing business strategies, and ensuring efficient workflow.",
                    "related_job_titles": "Chief Operations Officer (COO) - Travel Operations"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Operations - Travel Services",
                    "position_descriptor": " Managing and directing operational activities, optimizing processes, and ensuring quality service delivery.",
                    "related_job_titles": "Director of Operations - Travel Services"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Regional Manager - Travel Services",
                    "position_descriptor": " Supervising multiple branches or regions, ensuring consistency in service standards, and implementing regional strategies.",
                    "related_job_titles": "Regional Manager - Travel Services"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Branch Manager - Travel Agency",
                    "position_descriptor": " Leading a specific branch, overseeing sales and operations, and ensuring customer satisfaction.",
                    "related_job_titles": "Branch Manager - Travel Agency"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Tour Operations Manager",
                    "position_descriptor": " Managing the planning and execution of tour packages, coordinating with vendors, and ensuring a seamless travel experience.",
                    "related_job_titles": "Tour Operations Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Sales Manager - Travel Services",
                    "position_descriptor": " Leading the sales team, developing sales strategies, and achieving revenue targets.",
                    "related_job_titles": "Sales Manager - Travel Services"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Travel Consultant / Advisor",
                    "position_descriptor": " Providing travel advice, booking arrangements, and handling customer inquiries.",
                    "related_job_titles": "Travel Consultant, Travel Advisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Customer Service Representative - Travel",
                    "position_descriptor": " Assisting customers with inquiries, resolving issues, and providing excellent customer service.",
                    "related_job_titles": "Customer Service Representative - Travel"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Travel Coordinator / Assistant",
                    "position_descriptor": " Assisting in travel planning, coordinating bookings, and supporting operational tasks.",
                    "related_job_titles": "Travel Coordinator, Travel Assistant"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Travel Agent Trainee",
                    "position_descriptor": " Entry-level role focused on learning the basics of travel agency operations and customer service.",
                    "related_job_titles": "Travel Agent Trainee"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Tour Guide / Local Guide",
                    "position_descriptor": "Responsible for leading and guiding tourists during specific tours or activities. ",
                    "related_job_titles": "Tour Guide, Local Guide"
                }
            ]
        }
    },
    "Lodging and Accomodation": {
        "Hotel Management": {
            "job_family_descriptor": "Job family encompasses roles that oversee the overall operations of hotels and lodging establishments. Professionals in this family are responsible for managing staff, ensuring guest satisfaction, overseeing financial performance, and maintaining the overall quality and reputation of the establishment",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Hotel Director",
                    "position_descriptor": " The General Manager or Hotel Director holds the highest-ranking position in Accommodation Management. They are responsible for overseeing all aspects of the hotel's operations, setting strategic goals, managing the executive team, ensuring financial success, and maintaining overall guest satisfaction. They report to ownership or upper management.",
                    "related_job_titles": " Hotel General Manager, General Director, Hotel Director, Property Manager"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Regional Director of Operations",
                    "position_descriptor": " The Regional Director of Operations oversees the performance of multiple hotels within a specific region. They work closely with General Managers, provide guidance on operational standards, and ensure consistency in brand representation. Regional Directors of Operations report to corporate leadership.",
                    "related_job_titles": " Regional Director of Operations, Area Operations Manager, Regional Manager"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Rooms / Front Office",
                    "position_descriptor": " The Director of Rooms or Front Office oversees the Front Desk, Housekeeping, and other guest services departments. They ensure smooth operations, handle guest relations, and contribute to creating a positive guest experience.",
                    "related_job_titles": " Director of Rooms, Front Office Director, Guest Services Director, Front Desk Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Manager",
                    "position_descriptor": "Senior Managers oversee specific operational areas within the hotel. They collaborate with upper management to implement strategies, manage departmental budgets, and ensure the seamless delivery of services.",
                    "related_job_titles": "Senior Accommodation Manager, Senior Operations Manager, Senior Guest Services Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Manager",
                    "position_descriptor": "Responsible for the day-to-day operations of their specific departments, such as Housekeeping, Front Office, or other guest services. They supervise staff, implement policies and procedures, and work to achieve departmental goals.",
                    "related_job_titles": "Accommodation Manager, Operations Manager, Guest Services Manager, Front Office Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Assistant Manager",
                    "position_descriptor": "Assistant Managers support various aspects of hotel operations. They work closely with department heads, handle administrative tasks, and may oversee specific projects or areas of responsibility.",
                    "related_job_titles": "Assistant Manager- Accomodation, Deputy Manager Front Office"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Department Supervisors",
                    "position_descriptor": " Department Supervisors oversee specific operational areas, such as Housekeeping or Front Office. They ensure efficient day-to-day operations within their departments, supervise staff, and contribute to overall hotel success.",
                    "related_job_titles": " Housekeeping Supervisor, Front Office Supervisor, Department Supervisor"
                }
            ]
        },
        "Housekeeping and Cleaning": {
            "job_family_descriptor": "Job family involves roles dedicated to maintaining cleanliness and orderliness in hotels and accommodations. They ensure that guest rooms, common areas, and facilities meet high standards of cleanliness and hygiene, contributing to a positive guest experience",
            "levels": [
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Executive Housekeeper",
                    "position_descriptor": " The Executive Housekeeper holds the highest-ranking position within the Housekeeping and Cleaning job family. They are responsible for overseeing and managing all aspects of the housekeeping department, ensuring cleanliness standards are met, and contributing to a positive guest experience. Executive Housekeepers often collaborate with other departments to maintain high levels of cleanliness throughout the hotel.",
                    "related_job_titles": " Executive Housekeeper, Director of Housekeeping, Housekeeping Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Director of Housekeeping",
                    "position_descriptor": " The Director of Housekeeping is a senior leadership role responsible for managing and coordinating the housekeeping department's operations. They set standards for cleanliness, oversee staff, and ensure the efficient and effective cleaning of guest rooms and public areas. Directors of Housekeeping collaborate with other hotel departments to maintain a well-functioning and guest-friendly environment.",
                    "related_job_titles": "Director of Housekeeping"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Housekeeping Manager",
                    "position_descriptor": " Housekeeping Managers are mid-level managers who oversee the day-to-day operations of the housekeeping department. They manage housekeeping staff, allocate resources, and ensure that cleanliness standards are consistently met. Housekeeping Managers report to higher-level executives within the hotel management structure.",
                    "related_job_titles": "Housekeeping Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Assistant Housekeeping Manager",
                    "position_descriptor": " Assistant Housekeeping Managers provide support to the Housekeeping Manager in managing daily operations. They may supervise staff, assist in resource allocation, and contribute to ensuring that guest rooms and public areas meet established cleanliness standards. Assistant Housekeeping Managers play a vital role in maintaining a well-organized and efficient housekeeping department.",
                    "related_job_titles": "Assistant Housekeeping Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Laundry Supervisor",
                    "position_descriptor": "Responsible for overseeing the operations of the hotel's laundry facility and managing the cleaning and maintenance of linens, uniforms, and other textiles used within the hotel.",
                    "related_job_titles": "Laundry Supervisor"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Housekeeping Supervisor",
                    "position_descriptor": " Housekeeping Supervisors lead and supervise a team of housekeeping staff. They ensure that assigned tasks are completed, quality standards are met, and schedules are adhered to. Housekeeping Supervisors report to higher-level managers within the housekeeping department.",
                    "related_job_titles": "Housekeeping Supervisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Senior Room Attendant",
                    "position_descriptor": " Senior Room Attendants are experienced and skilled individuals responsible for cleaning and preparing guest rooms. They may have additional responsibilities, such as training new room attendants, inspecting rooms for cleanliness, and assisting with inventory management.",
                    "related_job_titles": "Senior Room Attendant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Room Attendant",
                    "position_descriptor": " Room Attendants, also known as Housekeepers, are responsible for cleaning and maintaining guest rooms. They perform tasks such as changing linens, replenishing amenities, and ensuring a high standard of cleanliness in assigned areas.",
                    "related_job_titles": "Room Attendant"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Laundry Attendant",
                    "position_descriptor": "Laundry Attendants operate laundry equipment and ensure the availability of clean textiles.",
                    "related_job_titles": "Laundry Attendant"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Housekeeping Aide / Houseman",
                    "position_descriptor": " Housekeeping Aides or Housemen provide essential support to the housekeeping department. They assist with tasks such as delivering linens, cleaning public areas, and providing additional support as needed.",
                    "related_job_titles": "Housekeeping Aide / Houseman"
                }
            ]
        },
        "Guest Relations and Concierge": {
            "job_family_descriptor": "Job family focuses on providing exceptional guest experiences. Concierge professionals assist guests with special requests, arrange services, and provide information about local attractions. Guest Relations Managers oversee the overall guest experience, addressing concerns, and ensuring guest satisfaction to enhance the reputation of the establishment.",
            "levels": [
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Director of Guest Services",
                    "position_descriptor": " The Director of Guest Services is a senior leadership role responsible for overseeing all aspects of guest relations and concierge services. They develop and implement strategies to enhance the overall guest experience.",
                    "related_job_titles": "Director of Guest Services"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Guest Relations Manager",
                    "position_descriptor": " Guest Relations Managers manage the daily operations of the guest relations team. They ensure that guests receive exceptional service, handle special requests, and address concerns.",
                    "related_job_titles": "Guest Relations Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Assistant Manager",
                    "position_descriptor": "Assistant Managers support various aspects of hotel operations. They work closely with department heads, handle administrative tasks, and may oversee specific projects or areas of responsibility.",
                    "related_job_titles": "Assistant Guest Relations Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Concierge Supervisor",
                    "position_descriptor": " Concierge Supervisors lead the concierge team, coordinating services, managing staff schedules, and providing assistance to guests with various needs.",
                    "related_job_titles": "Concierge Supervisor"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Senior Concierge",
                    "position_descriptor": " Senior Concierges have extensive experience and take on additional responsibilities, such as training new concierge staff, handling VIP guests, and overseeing special events.",
                    "related_job_titles": "Senior Concierge"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Guest Relations Officer",
                    "position_descriptor": " Guest Relations Coordinators support the guest relations team by managing guest feedback, coordinating special arrangements, and ensuring a seamless experience for guests.",
                    "related_job_titles": "Guest Relations Coordinator"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Concierge",
                    "position_descriptor": " Concierges provide personalized services to guests, including making reservations, providing information about local attractions, and fulfilling special requests.",
                    "related_job_titles": "Concierge"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Guest Services Representative",
                    "position_descriptor": " Guest Services Representatives are frontline staff who assist guests with check-in, check-out, and general inquiries. They play a key role in creating a positive first impression. Include  Front Desk Agents handle guest registrations, check-ins, and check-outs. They also provide information about hotel services and local attractions.",
                    "related_job_titles": "Guest Services Representative, Front Desk Agent"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Guest Services Associate",
                    "position_descriptor": " Guest Services Associates provide basic  support to the guest services team, assisting with various tasks to ensure a smooth and pleasant experience for guests.",
                    "related_job_titles": "Guest Services Associate"
                }
            ]
        }
    },
    "Meetings,Conferences and Exhibitions": {
        "Event Planning and Management": {
            "job_family_descriptor": "Job family involves roles responsible for the strategic planning and seamless execution of events, including meetings, conferences, and exhibitions. Professional here work closely with clients to understand their objectives, coordinate logistics, manage budgets, and ensure that all aspects of an event run smoothly.",
            "levels": [
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Director of Events and Catering",
                    "position_descriptor": " The Director of Events is a senior leadership role responsible for overseeing the entire events and catering department. They develop strategies, manage budgets, and ensure the successful execution of events.",
                    "related_job_titles": "Director of Events and Catering"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Events Manager",
                    "position_descriptor": " Senior Events Managers have extensive experience and are responsible for planning and executing high-profile events. They lead a team of events professionals and collaborate with clients.",
                    "related_job_titles": "Senior Events Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Events Manager",
                    "position_descriptor": " Events Managers are responsible for planning and coordinating events, including logistics, vendor management, and client communication. They ensure events meet client expectations.",
                    "related_job_titles": "Events Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Event Coordinator",
                    "position_descriptor": " Event Coordinators support Events Managers in planning and executing events. They handle logistics, liaise with vendors, and assist in the coordination of event details.",
                    "related_job_titles": "Event Coordinator"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Event Planner",
                    "position_descriptor": " Event Planners work closely with clients to understand their event needs, propose ideas, and coordinate all aspects of the planning process to ensure successful events.",
                    "related_job_titles": "Event Planner"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Event Specialist",
                    "position_descriptor": " Event Specialists focus on specific aspects of event planning, such as logistics, marketing, or design. They contribute specialized skills to enhance the overall event experience.",
                    "related_job_titles": "Event Specialist"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Event Assistant",
                    "position_descriptor": " Event Assistants provide administrative support to the events team, handling tasks such as scheduling, communications, and basic logistics.",
                    "related_job_titles": "Event Assistant"
                }
            ]
        },
        "Venue Management": {
            "job_family_descriptor": "Job family encompasses roles dedicated to overseeing and ensuring the effective operation of event venues, such as conference centers, exhibition halls, and meeting spaces.",
            "levels": [
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Director of Exhibition/Event Venue",
                    "position_descriptor": " The Director of Exhibition/Event Venue Management is a senior leadership role overseeing the entire venue operations. They are responsible for strategic planning, budgeting, and ensuring the successful execution of events.",
                    "related_job_titles": "Event Venue Director, Venue Operations Director, Director of Events and Venue Services"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Venue Manager",
                    "position_descriptor": " Senior Venue Managers have extensive experience and are responsible for managing the day-to-day operations of the venue. They oversee staff, coordinate events, and ensure a seamless guest experience.",
                    "related_job_titles": "Senior Event Venue Manager, Senior Venue Operations Manager, Venue Operations Senior Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Venue Manager",
                    "position_descriptor": " Venue Managers are responsible for the overall management of the venue, including scheduling events, coordinating with clients, and overseeing staff to ensure events run smoothly.",
                    "related_job_titles": "Event Venue Manager, Venue Operations Manager, Venue Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Event Operations Manager",
                    "position_descriptor": " Event Operations Managers focus on the operational aspects of events, including logistics, safety, and coordination with various teams to ensure successful event execution.",
                    "related_job_titles": "Event Operations Supervisor, Operations and Logistics supervisor, Venue Event Operations Deputy Manager"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Venue Coordinator",
                    "position_descriptor": " Venue Coordinators work closely with clients to understand their event needs, coordinate event logistics, and ensure that all aspects of the venue meet client expectations.",
                    "related_job_titles": "Venue Coordinator, Event Venue Coordinator, Venue Operations Coordinator"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Event Services Coordinator",
                    "position_descriptor": " Event Services Coordinators support venue operations by coordinating services such as catering, security, and technical support for events. They ensure services align with client requirements.",
                    "related_job_titles": "Event Services Coordinator, Venue Services Coordinator, Coordinator of Event Operations"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Venue Specialist",
                    "position_descriptor": " Venue Specialists focus on specialized aspects of venue management, such as technical operations, safety protocols, or guest services, contributing to the overall success of events.",
                    "related_job_titles": "Venue Specialist, Event Venue Specialist"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Assistant Venue Coordinator",
                    "position_descriptor": " Assistant Venue Coordinators provide support to Venue Coordinators in tasks such as logistics, client communication, and administrative duties related to venue management.",
                    "related_job_titles": "Assistant Venue Coordinator, Assistant Event Venue Coordinator, Venue Operations Assistant Coordinator"
                }
            ]
        }
    }
}