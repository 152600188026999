import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import BarChart from "../../Components/BarChart";
import { getFooter, getNote } from "../utils";

export default function SportsClub(props) {
  const { data } = props;
  if (!data) return null;

  const { offering } = data;
  return (
    <div>
      <BlockHeading text="Sports Club and/or Gym Membership" />
      <div className="my-4">
        <BCard
          title="Loans"
          subtitle="Does your organisation offer sports club and/or gym membership"
          footer={getFooter(offering?.total)}
          note={getNote()}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="200px"
          />
        </BCard>
      </div>
      <div>
        <TableCharts data={data.data} />
      </div>
    </div>
  );
}


function TableCharts(props) {
  const { data } = props;
  if (!data) return null;

  const { gym_annual_subscription, ports_annual_subscription_amount, sports_registration_fee, no_sports_clubs } = data;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title="No. of Clubs"
          note={getNote()}
          footer={getFooter(no_sports_clubs?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={no_sports_clubs?.categories}
            series={no_sports_clubs?.data}
            height="auto"
          />
        </BCard>
        <BCard
          title="Gym Annual subscription"
          note={getNote()}
          footer={getFooter(gym_annual_subscription?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={gym_annual_subscription?.categories}
            series={gym_annual_subscription?.data}
            height="auto"
          />
        </BCard>
        <BCard
          title="Sports Club membership - Annual Subscription amount"
          note={getNote()}
          footer={getFooter(ports_annual_subscription_amount?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={ports_annual_subscription_amount?.categories}
            series={ports_annual_subscription_amount?.data}
            height="auto"
          />
        </BCard>
        <BCard
          title="Sports Club membership - Registration fee"
          note={getNote()}
          footer={getFooter(sports_registration_fee?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={sports_registration_fee?.categories}
            series={sports_registration_fee?.data}
            height="auto"
          />
        </BCard>
      </div>
  )
}
