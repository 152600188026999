import Tooltip from "../../../../../../components/Tooltip"
import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"

import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { WR_COLORS } from "../../../../../../configs/colors"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import generator from "../../utils/generators"


export default function ProfitSharing(props) {
    const id = 'profit_sharing'
    const { watch, errors, control, register } = props

    return (
        <div>
            <BlockHeading text="4. PROFIT SHARING" />
            <div>
                <div className="flex pt-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer profit sharing plans?" />
                
                <Tooltip text="Profit sharing plan is incentive program that businesses use to offer employees a share of their profits." faq={true} />
                </div>
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                <td className="px-2 py-2 border-b border-r">Eligible levels</td>
                                <td className="px-2 border-b border-r">Profit sharing amount as % of basic salary </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PEOPLE.map((item, index) => {
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>


                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false}
                                                required
                                                name={`${id}.data.${item}`}
                                                register={register}
                                                errors={errors} watch={watch}
                                                options={[
                                                    { label: "Not eligible", value: "Not eligible" },
                                                    ...generator(100),
                                                    { label: "100%+", value: "100%+" },
                                                ]} control={control} /></td>


                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )
}