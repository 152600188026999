import React from "react";
import DashboardLayout from "../../../components/layouts/Dashboard";
import Cards from "./components/Cards";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dashboard from "./Tabs/Dashboard";
import QuickAccess from "./Tabs/QuickAccess";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

// export default function MainDashboard() {
//     return (
//         <DashboardLayout>
//             <Cards />
//         </DashboardLayout>
//     )
// }

export default function MainDashboard(props) {
  const [value, setValue] = React.useState('dashboard');

  const handleChange = (event, newValue) => {
      setValue(newValue)
  };


  const tabs_data = [
    { id: 'dashboard', label: 'Dashboard' },
    // { id: 'quick-access', label: 'Quick Access' }
  ]

  return (
    <DashboardLayout background="#FAFCFF">
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs_data.map((item, index) => (
            <Tab sx={{ fontSize: '14px', textTransform: 'capitalize' }} key={`tb-${index}`} label={item.label} value={item.id} />
          ))
          }
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={`dashboard`}>
        <Dashboard />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={'quick-access'}>
        <QuickAccess />
      </CustomTabPanel>
    </Box>
    </DashboardLayout>
  );
}
