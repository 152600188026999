import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card } from "@mui/material";
import AllowanceTooltip from "./AllowanceTooltip";

import { styled } from '@mui/material';
import GeneralTooltip from '../../../../components/GeneralTooltip';


const StyledDataGrid = styled(DataGrid)({
    // set width to 100%
    '& .MuiDataGrid-root': {
      width: '100%',
    },
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: '#F9FAFB', // Set table heading row background to #eee
    },
  });

function DataTable(props) {
    const marketDescriptions = {
        "High": "Your company's value is above the 75th percentile of the market, indicating a strong performance relative to peers.",
        "Low": "Your company's value is below the 25th percentile of the market, suggesting room for improvement compared to peers.",
        "Low-Average": "Your company's value is between the 25th and 50th percentiles of the market, placing it slightly below the median.",
        "Average-High": "Your company's value is between the 50th and 75th percentiles, indicating a performance above the median.",
        "At Market": "Your company's value is within the 25th to 75th percentile range, aligning closely with the market average."
      };
    const columns = [
        { field: 'employee_id', headerName: 'Employee ID', width: 100 },
        { field: 'role', headerName: 'Role', width: 350},
        { field: 'grade', headerName: 'Grade', width: 100, flex: 1 },
        { field: 'location', headerName: 'Location', width: 120, flex: 1 },
        { 
            field: 'annual_basic_salary', 
            headerName: 'Base Salary', 
            width: 150,
            valueGetter: (params) => params.row.annual_basic_salary?.toLocaleString(), flex: 1
        },
        { 
            field: 'total_allowance', 
            headerName: 'Total Allowances', 
            width: 180,
            renderCell: (params) => (
                <AllowanceTooltip 
                    data={params.row.total_allowance.num?.toLocaleString()}
                    item={params.row.total_allowance}
                />
            ), flex: 1
        },
        { 
            field: 'consolidated_salary', 
            headerName: 'Total Guaranteed Cash', 
            width: 180,
            valueGetter: (params) => params.row.consolidated_salary?.toLocaleString(), flex: 1
        },
        { field: 'market', headerName: 'Market', width: 150, flex: 1, renderCell: (params) => (
            // Assuming params.row.market_status holds the value from get_market function
            params.row.market ?
            <GeneralTooltip description={
                marketDescriptions[params.row.market]
            }>
            <div style={{
                color: params.row.market === "High" ? "green" :
                    params.row.market === "Average-High" ? "lightgreen" :
                        params.row.market === "At Market" ? "orange" :
                            params.row.market === "Low-Average" ? "#CEB10B" :
                                "red" // For "Low"
            }}>
                {params.row.market}
            </div>
            </GeneralTooltip>
            :
            <div>-</div>
        )
        }
    ];

    return (
        <Card>
            <div style={{ height: 700, width: '100%' }}>
                <StyledDataGrid
                    rows={props.data ?? []}
                    loading={props.loading}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    density="compact"
                />
            </div>
        </Card>
    );
}

export default DataTable;
