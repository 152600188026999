import AuthLayout from "../../../components/layouts/Auth";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../configs/endpoints";
import { LoaderSVG } from "../../../components/icons/LoaderSvg";
import { setAccessToken, setRefreshToken } from "../../../auth/utils";
import { useDispatch } from "react-redux";
import { handleLogin } from "../../../redux/authentication";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PasswordInput from "../forms/PasswordInput";

export default function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [alertOpen, setAlertOpen] = useState(false)
    const [searchParams] = useSearchParams();
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })

    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {
        if (searchParams.get("registration") === "success") {
            setAlertOpen(true)
        }
    }, [])


    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null })
        axios.post(`${API_URL}/api/v1/login`, data).then(res => {

            setApiReq({ loading: false, error: null })
            var data = res.data
            setAccessToken(data.access_token)
            setRefreshToken(data.refresh_token)

            delete data.access_token
            delete data.refresh_token
            console.log(data)
            dispatch(handleLogin(data))

            // navigate("/")
            window.location.href = "/"
        }).catch(err => {
            if (err?.response?.status) {
                setApiReq({ loading: false, error: err.response.data.message })
            } else {
                setApiReq({ loading: false, error: 'Error processing request' })
            }
        })
    }



    return (
        <AuthLayout title="Login">

            <div className="text-center mb-8">
                <h2 className="text-2xl font-semibold mt-4">Log In</h2>
                <p className="text-gray-600 mt-2">Welcome back! Please enter your details.</p>
            </div>

            {alertOpen &&
                <div className="bg-green-100 border border-green-400 text-green-700 px-3 py-3 rounded relative mb-4" role="alert">
                    <span className="block sm:inline">Registration success! Check your email for verification.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3 close-alert-button" onClick={() => setAlertOpen(false)}>
                        <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697L8.181 10 5.651 7.848a1.2 1.2 0 1 1 1.697-1.697L10 8.181l2.651-2.03a1.2 1.2 0 1 1 1.697 1.697L11.819 10l2.529 2.848a1.2 1.2 0 0 1 0 1.697z"></path></svg>
                    </span>
                </div>
            }

            <form className="flex-grow" onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700">Email</label>
                    <input type="email" id="email" name="email" placeholder="Enter your email" {...register('email', { required: true })} className="w-full px-4 py-2 border rounded-sm" />
                    {errors.email && <span className="text-red-600 text-sm">This field is required</span>}
                </div>
                {/* <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-700">Password</label>
                    <input type="password" id="password" name="password" {...register('password', {
                        required: 'Password is required',
                        minLength: {
                            value: 8,
                            message: 'Password must be at least 8 characters long',
                        },
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message:
                                'Password must contain at least one lowercase letter, one uppercase letter, one special character, and a number',
                        },
                    })} className="w-full px-4 py-2 border rounded-sm" />
                    {errors.password && <span className="text-red-600 text-sm">{errors.password.message}</span>}
                </div> */}
                <PasswordInput register={register} errors={errors} />
                <div className="flex justify-between">
                    <div className="mb-4 flex items-center">
                        <input type="checkbox" id="rememberLogin" name="rememberLogin" className="mr-2" />
                        <label htmlFor="rememberLogin" className="text-gray-600">Remember Me</label>
                    </div>
                    <div className="mb-4">
                        <a href="/forgot-password" className="text-blue-600 hover:underline">Forgot Password?</a>
                    </div>
                </div>

                <div className="text-red-600 text-md">{apiReq.error && apiReq.error}</div>

                <button type="submit" style={{ backgroundColor: '#8025EE' }} className="w-full flex justify-center bg-blue-600 text-white py-2 my-5 rounded-lg hover:bg-blue-700">
                    {apiReq.loading ?
                        <LoaderSVG />
                        :
                        `Log In`
                    }
                </button>

                <div className="text-center mt-5">Don't have an account? <a href="/register" className="font-medium" style={{ color: '#8025EE' }}>Sign Up</a></div>
            </form>
        </AuthLayout>
    )
}
