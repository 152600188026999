import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import BarChart from "../../Components/BarChart";
import { getFooter, getNote } from "../utils";

export default function CompanyCar(props) {
  const { data } = props;
  if (!data) return null;

  const { offering } = data;
  return (
    <div>
      <BlockHeading text="Company Car" />
      <div className="my-4">
        <BCard
          title="Company Car offering"
          subtitle="Does your organization offer company car to any employees?"
          footer={getFooter(offering?.total)}
          total={offering?.total}
          note={getNote()}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="200px"
          />
        </BCard>
      </div>
      <div>
        <TableCharts data={data.data} />
      </div>
    </div>
  );
}


function TableCharts(props) {
  const { data } = props;
  if (!data) return null;

  const { car_fuel_maintenance_cover, personal_driver, car_utilise } = data;

  return (
    <div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title="No. of Clubs"
          note={getNote()}
          total={car_utilise?.total}
          footer={getFooter(car_utilise?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={car_utilise?.categories}
            series={car_utilise?.data}
            height="auto"
          />
        </BCard>
        <BCard
          title="GYM Annual subscription"
          note={getNote()}
          total={personal_driver?.total}
          footer={getFooter(personal_driver?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={personal_driver?.categories}
            series={personal_driver?.data}
            height="auto"
          />
        </BCard>
      </div>
        <BCard
          title="Sports Club membership - Annual Subscription amount"
          note={getNote()}
          total={car_fuel_maintenance_cover?.total}
          footer={getFooter(car_fuel_maintenance_cover?.total)}
        >
          <BarChart
            percentage={true}
            stacked={true}
            categories={car_fuel_maintenance_cover?.categories}
            series={car_fuel_maintenance_cover?.data}
            height="auto"
          />
        </BCard>
      </div>
  )
}
