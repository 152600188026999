import { useEffect, useState } from "react";
import instance from "../../auth/useJwt";
import DashboardLayout from "../../components/layouts/Dashboard";
import TabMain from "./TabMain";
import PageLoader from "../../components/Loaders/PageLoader";
import FiltersTab from "./Filters";

export default function Benefits(props) {
    const [filters, setFilters] = useState({
        country: ""
    })

    const [filterOptions, setFilterOptions] = useState({
        country: [],
        industry: [],
    })

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const get_benefits_data = () => {
        setLoading(true)
        instance.post('/benefits', filters).then(res => {
            setData(res.data)
            setFilterOptions(res.data.metadata)
            setLoading(false)
        }
        ).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    // useEffect(() => {
    //     get_benefits_data()
    // }, [])

    useEffect(() => {
        get_benefits_data()
    }, [filters])


    return (
        <DashboardLayout title="Benefits">
            
            <FiltersTab filter={filters} setFilters={setFilters} filterOptions={filterOptions} />
            {loading ? <PageLoader /> :
                <div>
                    {data &&
                        <TabMain data={data} />
                    }
                </div>
            }


        </DashboardLayout>
    )
}