import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/layouts/Dashboard";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";

export default function Profile(props) {
    const user = useSelector(state => state.authentication)

  return (
    <DashboardLayout>
      
      <Box sx={{ width: '100%' }} className="px-4 mt-2">
            <Typography variant="h6" gutterBottom component="div">
                Profile
            </Typography>

            <Typography variant="h5" sx={{ maxWidth: '500px', margin: '0 auto', mt: 5 }} gutterBottom component="div">
                <p className="mb-2"><b className="font-bold">Name:</b> {user.name}</p>
                <p><b className="font-bold">Email:</b> {user.email}</p>
            </Typography>
      </Box>

      
    </DashboardLayout>
  );
}

