import { createSlice } from '@reduxjs/toolkit'


export const compensationInfoSlice = createSlice({
    name: 'compensation_info',
    initialState: {
        data: {},
        rows: [],
        xlsx_download_url: null
    },
    reducers: {
        setCompensationInfoData: (state, action) => {
            state.data = action.payload.data
            state.rows = action.payload.rows
        },
        resetCompensationInfoData: (state, action) => {
            state.data = action.payload.data
            state.rows = action.payload.rows
            state.xlsx_download_url = action.payload.xlsx_download_url
        },
        setCompensationInfoDataWKey: (state, action) => {
            state[action.payload.key] = action.payload.value
        }
    }
})

export const { setCompensationInfoData, resetCompensationInfoData, setCompensationInfoDataWKey } = compensationInfoSlice.actions

export default compensationInfoSlice.reducer