import AuthLayout from "../../../components/layouts/Auth";
import { useForm } from 'react-hook-form';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../configs/endpoints";
import { LoaderSVG } from "../../../components/icons/LoaderSvg";


export default function ForgotPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [apiReq, setApiReq] = useState({
        loading: false, error: null, message: null
    })

    const navigate = useNavigate()


    const onSubmit = (data) => {
        setApiReq({ loading: true, error: null, message: null })
        axios.post(`${API_URL}/api/v1/password-reset-request`, data).then(res => {

            setApiReq({ loading: false, error: null, message: 'Success: Please check your email for reset URL' })
            
        }).catch(err => {
            if (err?.response?.status) {
                setApiReq({ loading: false, error: err.response.data.message, message: null })
            } else {
                setApiReq({ loading: false, error: 'Error processing request', message: null })
            }
        })
    }

    return (
        <AuthLayout title="Forgot Password">

            <div className="text-center mb-8">
                <h2 className="text-2xl font-semibold mt-4">Reset Password</h2>
                <p className="text-gray-600 mt-2">Enter your email below and we will send you instructions to reset it.</p>
            </div>
            {apiReq.message &&
                <div className="bg-green-100 border border-green-400 text-green-700 px-3 py-3 rounded relative mb-4" role="alert">
                    <span className="block sm:inline">{apiReq.message}</span>
                </div>
            }
            <form className="flex-grow" onSubmit={handleSubmit((data) => onSubmit(data))}>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700">Email</label>
                    <input type="email" id="email" name="email" placeholder="Enter your email" {...register('email', { required: true })} className="w-full px-4 py-2 border rounded-sm" />
                    {errors.email && <span className="text-red-600 text-sm">This field is required</span>}
                </div>



                <div className="text-red-600 text-md">{apiReq.error && apiReq.error}</div>

                <button type="submit" style={{ backgroundColor: '#8025EE' }} className="w-full flex justify-center bg-blue-600 text-white py-2 my-5 rounded-lg hover:bg-blue-700">
                    {
                        apiReq.loading ?
                            <LoaderSVG />
                            :
                            `Submit`
                    }
                </button>

                <div className="text-center mt-5">Don't have an account? <a href="/register" className="font-medium" style={{ color: '#8025EE' }}>Sign Up</a></div>
            </form>
        </AuthLayout>
    )
}
