import { Card, CardHeader, Grid } from "@mui/material"

function SelectFilter(props) {
    const { title, name, options, value } = props
    return (
            <select id="role" name={name} value={value}
            onChange={props.onChange}
            className="mt-1 block w-full p-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                {/* You can list your options here */}
                {options.map((option, index) => {
                    return <option key={index} value={option.value}>{option.label}</option>
                })}
            </select>
    )
}

export default function FiltersTab(props) {
    const { filter, setFilters } = props
    const filtersMaps = props.filterOptions
    const filtersList = [
         "industry"
    ]
    // const filtersMaps = {
    //     "country": [
    //         { label: "All", value: "" },
    //         { label: "Mauritius", value: "mauritius" },
    //         { label: "Mozambique", value: "mozambique" },
    //         { label: "Kenya", value: "kenya" },
    //         { label: "Ethiopia", value: "ethiopia" },
    //         { label: "Uganda", value: "uganda" },
    //         { label: "Rwanda", value: "rwanda" },
    //         { label: "Tanzania", value: "tanzania" },
    //     ]
    // }


    return (
        <Card sx={{ mx: 2, border: '1px solid #eee', py: 1, px: 2 }}>
            
        <Grid container spacing={2}>
            {filtersList.map((item, index) => {
                return (
                    <Grid item md={3} key={index}>
                        <div className="form-group">
                            <label style={{
                                textTransform: 'capitalize',
                                fontSize: '13px',
                            }}>{item}</label>
                            <SelectFilter
                                title={item}
                                name={item}
                                value={filter[item]}
                                options={[
                                    { label: "All", value: "" },
                                    ...filtersMaps[item]
                                ]}
                                onChange={(e) => setFilters({...filter, [item]: e.target.value})}
                            />
                        </div>
                    </Grid>
                )
            })}
        </Grid>
        </Card>
    )

}