import BCard from "../../Components/BCard";
import BarChart from "../../Components/BarChart";
import BlockHeading from "../../../../components/forms/BlockHeading";
import PieChart from "../../Components/PieChart";
import { getFooter } from "../utils";

export default function BenefitOverview(props) {
  const { data } = props;
  const { organization_info, demography, pay_information } = data;
  const { country } = organization_info;
  const {
    primary_industry,
    company_type,
    organization_size,
    incorporation_year,
  } = demography;
  const { currency, salary_period } = pay_information;
  return (
    <div>
      {/* <BlockHeading text="1. Organisation Information" /> */}
      {/* <div className="my-2">
        <BCard
          title="Countries"
          subtitle={`As shown below, ${country.data[0]}% of the organisations are from "${country.categories[0]}".`}
          footer={getFooter(country?.total)}
          note="The total may not equal 100% due to rounding."
        >
          <BarChart
            percentage={true}
            categories={country.categories}
            series={[{ name: "Primary Industry", data: country.data }]}
            height="auto"
          />
        </BCard>
      </div> */}

      <BlockHeading text="1. Demography" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <BCard
          title="Company Type"
          subtitle={`As shown in the chart below, ${company_type.data[0]}% of survey participants are ${primary_industry.categories[0]}`}
          footer={getFooter(company_type?.total)}
          note="The total may not equal 100% due to rounding."
        >
          <PieChart
            categories={company_type.categories}
            series={company_type.data}
            height="200"
          />
        </BCard>
        <BCard
          title="Industry"
          subtitle={`As shown below, ${primary_industry.data[0]}% of the organisations are from ${primary_industry.categories[0]} super sector.`}
          footer={getFooter(primary_industry?.total)}
          note="The total may not equal 100% due to rounding."
        >
          <BarChart
            percentage={true}
            categories={primary_industry.categories}
            series={[{ name: "Primary Industry", data: primary_industry.data }]}
            height="auto"
          />
        </BCard>

        {/* <BCard
          title="Organisation Size"
          subtitle={"Organisation size is based on the number of employees"}
          footer={getFooter(organization_size?.total)}
          note="The total may not equal 100% due to rounding."
        >
          <BarChart
            percentage={true}
            categories={organization_size.categories}
            series={[
              { name: "Organisation Size", data: organization_size.data },
            ]}
            height="auto"
          />
        </BCard> */}
        <BCard
          title={"Incorporation Year"}
          subtitle={
            "Incorporation year is based on the year the organisation was founded"
          }
          footer={getFooter(incorporation_year?.total)}
          note="The total may not equal 100% due to rounding."
        >
          <BarChart
            percentage={true}
            categories={incorporation_year.categories}
            series={[
              { name: "Incorporation Year", data: incorporation_year.data },
            ]}
            height="auto"
          />
        </BCard>
      </div>
      <BlockHeading text="2. Pay Information" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-2">
        <div>
          <BCard
            title="Currency"
            subtitle={`As shown below, ${currency.data[0]}% of the organisations are from ${currency.categories[0]} super sector.`}
            footer={getFooter(currency?.total)}
            note="The total may not equal 100% due to rounding."
          >
            <BarChart
              percentage={true}
              categories={currency.categories}
              series={[{ name: "Currency", data: currency.data }]}
              height="auto"
            />
          </BCard>
        </div>
        <BCard
          title="Salary Period"
          subtitle={`As shown in the chart below, ${salary_period.data[0]}% of survey participants are ${salary_period.categories[0]}`}
          footer={getFooter(salary_period?.total)}
          note="The total may not equal 100% due to rounding."
        >
          <PieChart
            categories={salary_period.categories}
            series={salary_period.data}
            height="200"
          />
        </BCard>
      </div>
    </div>
  );
}
