import EmployeeDemography from "./EmployeeDemography";
import AttractionAndRetentionPractices from "./AttractionAndRetentionPractices";
import Overtime from "./Overtime";
import WorkEnvironment from "./WorkEnvironment";
import Leave from "./Leave";
import TrainingAndDevelopment from "./TrainingAndDevelopment";

export default function BenefitHRPolicies(props) {
  console.log("data in hrpolicies", props.data);
  const { data } = props;
  const {
    attraction_retention_practices,
    employee_population,
    leave,
    overtime,
    training_development,
    work_environment,
  } = data;

  return (
    <div>
      <EmployeeDemography data={employee_population} />
      <AttractionAndRetentionPractices data={attraction_retention_practices} />
      <TrainingAndDevelopment data={training_development} />
      <Overtime data={overtime} />
      <WorkEnvironment data={work_environment} />
      <Leave data={leave} />
    </div>
  );
}
