import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { CURRENCY, YES_NO } from "../../../../../../configs/data/onboarding"
import ExpTable from "./ExpTable"
import { CheckboxInput } from "../../../../../../components/forms/CheckboxInput"

const EXP_PACKAGES_LIST = [
    { label: "Company car", value: "Company car" },
    { label: "Cost of living allowance", value: "Cost of living allowance" },
    { label: "Cultural training", value: "Cultural training" },
    { label: "Driver", value: "Driver" },
    { label: "Driver with defence driving training", value: "Driver with defence driving training" },
    { label: "devices (mobile phone, laptop, etc)", value: "Devices" },
    { label: "Furniture allowance", value: "Furniture allowance" },
    { label: "Global health insurance cover", value: "Global health insurance cover" },
    { label: "Hardship allowance", value: "Hardship allowance" },
    { label: "Health & fitness memberships", value: "Health & fitness memberships" },
    { label: "Housing allowance", value: "Housing allowance" },
    { label: "Immigration support", value: "Immigration support" },
    { label: "Language classes", value: "Language classes" },
    { label: "Life insurance", value: "Life insurance" },
    { label: "Pet relocation/travel cover", value: "Pet relocation/travel cover" },
    { label: "Relocation allowance", value: "Relocation allowance" },
    { label: "Rest and Recreation trips", value: "Rest and Recreation trips" },
    { label: "Schooling for dependants", value: "Schooling for dependants" },
    { label: "Spouse allowance", value: "Spouse allowance" },
    { label: "Tax support", value: "Tax support" },
    { label: "Yearly return flights to home country (business)", value: "Yearly return flights to home country (business)" },
    { label: "Yearly return flights to home country (economy)", value: "Yearly return flights to home country (economy)" }
]
const EXP_COMPENSATION_MANAGE = [
    { label: "Overseas at headquarters", value: "Overseas at headquarters" },
    { label: "Locally", value: "Locally" },
    { label: "Regional hub", value: "Regional hub" },
]




export default function ExpPackages(props) {
    const { watch, errors, control, register } = props
    const id = "data"
    return (
        <div>
            <div className="mt-2">
                <Label className="mb-2" htmlFor="pay_currency"
                    text="Does your organisation hire expatriates?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {watch(`${id}.offering`) === "Yes" &&
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                        <div>
                            <Label className="mb-2" htmlFor="pay_currency"
                                text="Currency used to pay expertiates" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.expertiates_pay_currency`}
                                errors={errors} watch={watch}
                                options={[
                                    ...CURRENCY, { label: "Multi-Currency - Home Country Currency", value: "Multi-Currency - Home Country Currency" }
                                ]} control={control} />
                        </div>

                        <div>
                            <Label className="mb-2" htmlFor="compensation_management"
                                text="Where is expatriate compensation managed?" />
                            <SelectAutocompleteSingle
                                required
                                name={`${id}.compensation_management`}
                                errors={errors} watch={watch}
                                options={EXP_COMPENSATION_MANAGE} control={control} />
                        </div>
                    </div>

                    <ExpTable control={control} watch={watch} register={register} errors={errors} />
                   
                    <div className="my-2">
                        <Label className="mb-2 mt-4" htmlFor="children_benefit-question"
                            text="What forms part of exptriate packages? (Select all that apply)" />
                        <table className="w-full border">
                            <tbody>
                                {
                                    EXP_PACKAGES_LIST.map((item, index) => {
                                        return (<tr key={`medical_cover-eligibility-${index}`}>
                                            <td className="px-2 border-b border-r text-sm">{item.label}</td>
                                            <td className="px-2 border-b border-r" >
                                                <CheckboxInput control={control} errors={errors} name={`${id}.training_needs.${item.value}`} />
                                            </td>



                                        </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}