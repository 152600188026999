import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"

import { CheckboxInput } from "../../../../../../components/forms/CheckboxInput"
import { WR_COLORS } from "../../../../../../configs/colors"
import HeadCell from "../../../../../../components/forms/general"

const ORGANIZATION_PP = [
    'All employees eligible for the same benefit',
    // ...ORGANIZATION_PEOPLE
    "Only some employee levels are eligible"
]

const AWARD_OFFERING = [
    { label: "Yes - for 5, 10, 15, 20, 25, 30, 35, 40 years", value: "Yes - for 5, 10, 15, 20, 25, 30, 35, 40 years" },
    { label: "Yes – at management discretion", value: "Yes – at management discretion" },
    { label: "No", value: "No" }
]

export default function Awards(props) {
    const id = props.id
    const { watch, errors, control } = props
    const allEmployeesValues = watch(`${id}.data.All employees eligible for the same benefit`);
    const isAllEmployeesRowFilled = Object.values(allEmployeesValues || {}).some(val => Boolean(val));

    const otherRowsValues = ORGANIZATION_PP.filter(item => item !== "All employees eligible for the same benefit")
        .map(item => watch(`${id}.data.${item}`));
    const isAnyOtherRowFilled = otherRowsValues.some(row => Object.values(row || {}).some(val => Boolean(val)));

    return (
        <div>

            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer long service awards or work anniversary recognition?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={AWARD_OFFERING} control={control} />
            </div>

            { !["No", undefined, null].includes(watch(`${id}.offering`)) && 
                <div className="my-2">
                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                {["", 'Cash', "Voucher", "Medal/Trophy/plaque", "Gift", "Party/Reception",
                                    "Extra vacation days", "Other"].map((smm, index) => (
                                        <HeadCell
                                        style={{ width: smm === "" ? "auto" : "100px" }}
                                        key={`awards-heading-${index}`}>{smm}</HeadCell>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PP.map((item, index) => {
                                    const isCurrentRowAllEmployees = item === "All employees eligible for the same benefit";

                                    if (isAnyOtherRowFilled && isCurrentRowAllEmployees || isAllEmployeesRowFilled && !isCurrentRowAllEmployees) {
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 py-2 border-b border-r">{item}</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                            </tr>
                                        );
                                    }
                                    return (<tr key={`awards-row-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        {['Cash', "Voucher", "Medal/Trophy/plaque", "Gift", "Party/Reception",
                                            "Extra vacation days", "Other"].map(smm => (
                                                <td key={`awards-row${index}-cell-${smm}`} className="px-2 border-b border-r">
                                                    <CheckboxInput control={control} errors={errors} name={`${id}.data.${item}.${smm}`} />
                                                </td>
                                            ))
                                        }


                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )
}