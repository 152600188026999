import HeadCell from "../../../components/forms/general";
import { WR_COLORS } from "../../../configs/colors";
import NoData from "./NoData";

const TableView = (props) => {
  const { columns, rows, height } = props;

  if (!rows) {
    return <NoData />
  }

  return (
    <div
    // className="border-t border-gray-200"
    >
      <table className="w-full border">
        <thead>
          <tr style={{ backgroundColor: WR_COLORS.t_head }}>
            {columns.map((smm, index) => (
              <HeadCell
                style={{ width: smm === "" ? "auto" : "100px" }}
                key={`tth-${index}`}
              >
                {smm}
              </HeadCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr key={`rr-${index}`}>
              {item.map((col, col_index) => (
                <td
                  key={`cl-${index}-${col_index}`}
                  className="px-2 border-b border-r text-sm py-1"
                >
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
{
  /* <div className="overflow-hidden shadow-md sm:rounded-sm" style={{ border: '1px solid rgb(235, 235, 235)' }}>
<div className="px-2 py-2 sm:px-3">
    <h3 className="text-sm leading-6 font-medium">
        {title}
    </h3>
</div>
<div className="border-t border-gray-200">
    <table className="w-full border">
        <thead>
            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                {columns.map((smm, index) => (
                    <HeadCell
                        style={{ width: smm === "" ? "auto" : "100px" }}
                        key={`tth-${index}`}>{smm}</HeadCell>
                ))
                }
            </tr>
        </thead>
        <tbody>
            {
                rows.map((item, index) => (
                    <tr key={`rr-${index}`}>
                        {item.map((col, col_index) => (
                            <td key={`cl-${index}-${col_index}`} className="px-2 border-b border-r text-sm py-1">
                                {col}
                            </td>
                        ))}

                    </tr>

                ))

            }
        </tbody>
    </table>
</div>
<div className="px-2 py-3 sm:px-3 text-sm">
    {footer && footer}
    <br />
    {note &&
        <span>
            Note: {note}
        </span>
    }
</div>
</div> */
}
