import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from '../../../redux/app_data';
const steps = [
  'Company Info',
  'Benefits',
  'Compensation Data',
  'Submission',
  'Finish'
];

export default function OnBoardStepper(props) {
  const { val, handleStep } = props;
  
  const isReadOnly = useSelector(onBoardingReadOnly);

  // This function will decide if the step can be navigated to
  const canGoToStep = (index) => {
    // Logic to allow navigation to 'Compensation Data' if on 'Benefits'
    if (val === 1 && index === 2) {
      return true; // Can go from 'Benefits' to 'Compensation Data'
    }
    // Add more conditions as needed to control other step navigations

    // Allow navigation to previous steps
    if (isReadOnly) {
      return true;
    }
    return index <= val;
  };

  const onStepClick = (index) => {
    if (canGoToStep(index)) {
      handleStep(index);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={val} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#9651FA', // circle color (COMPLETED)
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
              color: 'grey.500', // Just text label (COMPLETED)
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#9651FA', // circle color (ACTIVE)
            },
            // Add styles for disabled step, if needed
            '& .Mui-disabled': {
              pointerEvents: 'none', // Disables click events on disabled steps
            },
          }}>
            <StepButton onClick={() => onStepClick(index)} disabled={!canGoToStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
