import BlankRowCell from "../../../../../../components/forms/BlankRowCell"
import BlockHeading from "../../../../../../components/forms/BlockHeading"
import Label from "../../../../../../components/forms/InputLabel"
import { RadioTableInput } from "../../../../../../components/forms/RadioInput"
import SelectAutocomplete from "../../../../../../components/forms/SelectAutocomplete"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { WR_COLORS } from "../../../../../../configs/colors"
import { CURRENCY, ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"

const JOB_EVALUATION = [
    { label: "Yes, in the last two years", value: "Yes, in the last two years" },
    { label: "Yes, between 2-5 years ago", value: "Yes, between 2-5 years ago" },
    { label: "Yes, more than 5 years ago", value: "Yes, more than 5 years ago" },
    { label: "No", value: "No" },
    { label: "I am not sure", value: "I am not sure" },
]



const SALARY_STRUCTURE = [
    { label: "One for all the organisation", value: "One for all the organisation" },
    { label: "Two (One for Top management and one for the rest of the organisation)", value: "Two (One for Top management and one for the rest of the organisation)" },
    { label: "Two (One for Top management/Managers and one for the rest of the organisation)", value: "Two (One for Top management/Managers and one for the rest of the organisation)" },
    { label: "Three or more", value: "Three or more" }
]

const BENCHMARK_COMPENSATION = [
    { label: "Annual base salary", value: "Annual base salary" },
    { label: "Annual guaranteed cash (annual base salary plus guaranteed cash allowances)", value: "Annual guaranteed cash (annual base salary plus guaranteed cash allowances)" },
    { label: "Annual total cash (guaranteed cash plus target variable incentive)", value: "Annual total cash (guaranteed cash plus target variable incentive)" },
    { label: "Total cost of employment", value: "Total cost of employment" },
    { label: "Other", value: "Other" },
]

const ORGANIZATION_PP = [
    'All organisation uses the same target market pay', ...ORGANIZATION_PEOPLE
]



export default function CompensationStructure(props) {
    const id = 'compensation_structure'
    const { watch, errors, control, setValue } = props

    const ALL_EMPLOYEES_CURRNCY_SALARY = "Only one currency used to pay all employees"
    const TARGET_MARKET_PAY_ALL = "All organisation uses the same target market pay"

    const disable_salary_currency = (item) => {
        const ccid = ALL_EMPLOYEES_CURRNCY_SALARY
        if (![undefined, null].includes(watch(`${id}.pay_currency.${ccid}`)) &&
            `${id}.pay_currency.${ccid}` !== `${id}.pay_currency.${item}`) {
            return true
        } else if (
            ![undefined, null].includes(watch(`${id}.pay_currency.Head of Organisation`)) &&
            `${id}.pay_currency.${ccid}` === `${id}.pay_currency.${item}`
        ) {
            return true
        }
    }

    const disable_target_market_pay_currency = (item) => {
        const ccid = TARGET_MARKET_PAY_ALL
        if (![undefined, null].includes(watch(`${id}.organization_target_market_pay.${ccid}`)) &&
            `${id}.organization_target_market_pay.${ccid}` !== `${id}.organization_target_market_pay.${item}`) {
            return true
        } else if (
            ![undefined, null].includes(watch(`${id}.organization_target_market_pay.Head of Organisation`)) &&
            `${id}.organization_target_market_pay.${ccid}` === `${id}.organization_target_market_pay.${item}`
        ) {
            return true
        }
    }

    const clearTable = () => {
        // clear `${id}.organization_target_market_pay` using react-hook-form
        //setValue(`${id}.organization_target_market_pay`, {});
        { ORGANIZATION_PP.map((item) => {
            setValue(`${id}.organization_target_market_pay.${item}`, null);
        }) }
    }
        


    return (
        <div>
            <BlockHeading text="1. Compensation Structure" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                <div>
                    <Label className="mb-2" htmlFor="job_evaluation"
                        text="Has your organisation conducted a job evaluation to have a clear job architecture?" />
                    <SelectAutocompleteSingle
                        required
                        name={`${id}.job_evaluation`}
                        errors={errors} watch={watch}
                        options={JOB_EVALUATION} control={control} />
                </div>
                <div>
                    <Label className="mb-2"
                        htmlFor="salary_structure"
                        text="Does your organisation have a salary structure/salary ranges? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                    <SelectAutocompleteSingle
                        required
                        name={`${id}.salary_structure`}
                        errors={errors} watch={watch}
                        options={YES_NO} control={control} />
                </div>
            </div>
            {watch(`${id}.salary_structure`) == "Yes" &&
                <div className="mt-2">
                    <div>
                        <Label className="mb-2"
                            htmlFor="num_salary_structure"
                            text="If yes, how many salary structures does your organisation use?" />
                        <SelectAutocompleteSingle
                            required
                            name={`${id}.num_salary_structure`}
                            errors={errors} watch={watch}
                            options={SALARY_STRUCTURE} control={control} />
                    </div>
                </div>
            }
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2 mt-2">
                <div>
                    <Label className="mb-2" htmlFor="use_speradsheet"
                        text="Does your organisation use spreadsheets to design/create compensation structures?" />
                    <SelectAutocompleteSingle
                        required
                        name={`${id}.use_speradsheet`}
                        errors={errors} watch={watch}
                        options={YES_NO} control={control} />
                </div>
                <div>
                    <Label className="mb-2"
                        htmlFor="salary_adjustments"
                        text="Does your organisation budget for off cycle salary adjustments? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                    <SelectAutocompleteSingle
                        required
                        name={`${id}.salary_adjustments`}
                        errors={errors} watch={watch}
                        options={YES_NO} control={control} />
                </div>
            </div>

            <div className="mt-2">
                {/* <Label text="What is your organisation's target market pay?" /> */}
                <table className="w-full border">
                    <thead>
                        <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                            <td className="p-2 border-b border-r">Select your organisation's target market pay
                            <span className="text-blue-500 ml-2 underline cursor-pointer"
                            onClick={clearTable}
                            >clear</span>
                            </td>
                            <td className="p-2 border-b border-r">25th</td>
                            <td className="p-2 border-b border-r">50th (Median)</td>
                            <td className="p-2 border-b border-r">60th</td>
                            <td className="p-2 border-b border-r">75th</td>
                            <td className="p-2 border-b border-r">90th</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ORGANIZATION_PP.map((item, index) => {
                                return (<tr key={`organization_target_market_pay-${index}`}>
                                    <td className="px-2 py-1 border-b border-r">{item}</td>
                                    {[
                                        "25th", "50th", "60th", "75th", "90th"
                                    ].map(item_sb => {

                                        return (
                                            !disable_target_market_pay_currency(item) ?

                                                <td style={{
                                                    width: '10%'
                                                }} key={`org_tg_pay-${index}-${item_sb}`} className="px-2 border-b border-r">
                                                    <RadioTableInput
                                                        name={`${id}.organization_target_market_pay.${item}`}
                                                        value={item_sb} control={control} errors={errors} />
                                                </td>
                                                :
                                                <BlankRowCell key={`org_tg_pay-${index}-${item_sb}`} />
                                        )
                                    })
                                    }
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="mt-2">
                <div>
                    <Label className="mb-2"
                        htmlFor="compensation_benchmark"
                        text="Which elements are used to benchmark compensation?" />
                    <SelectAutocomplete
                        required
                        name={`${id}.compensation_benchmark`}
                        errors={errors} watch={watch}
                        options={BENCHMARK_COMPENSATION} control={control} />
                </div>
            </div>
            <div className="mt-2">
                {/* <Label text="Currency used to pay?" /> */}
                <table className="w-full border">
                    <thead>
                        <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                            <td className="p-2 border-b border-r">Currency use to pay employees</td>
                            <td className="p-2 border-b border-r">Select from dropdown</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ["Only one currency used to pay all employees", ...ORGANIZATION_PEOPLE].map((item, index) => {
                                return (<tr key={`organization_target_market_pay-${index}`}>
                                    <td className="px-2 py-1 border-b border-r">{item}</td>
                                    <td className="border-b border-r">
                                        {!disable_salary_currency(item) ?
                                            <SelectAutocompleteSingle
                                                required
                                                styles={{ border: 'none' }}
                                                name={`${id}.pay_currency.${item}`}
                                                errors={errors} watch={watch}
                                                options={
                                                    item === "Only one currency used to pay all employees" ?
                                                        // Skip last 3 items
                                                        [...CURRENCY].slice(0, -3)
                                                        :
                                                        CURRENCY
                                                } control={control}
                                                border={false}
                                            />
                                            :
                                            <div style={{ height: '40px', backgroundColor: "rgb(235, 235, 235)" }}>

                                            </div>
                                        }
                                    </td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}