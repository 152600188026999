import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import SearchBox from "./SearchBox";
import HomeIcon from '../../../../assets/icons/home.svg';
import PostSurevyResponseIcon from '../../../../assets/icons/post_survey_responses.svg';
import MarketDataIcon from '../../../../assets/icons/market_data.svg';
import TalentIcon from '../../../../assets/icons/users.svg';
import ToolsIcon from '../../../../assets/icons/designtools.svg';
import LogoutIcon from '../../../../assets/icons/logout.svg';
import UsersIcon from '../../../../assets/icons/users.svg';
import FAQIcon from '../../../../assets/icons/faq.svg';


import { useTranslation } from 'react-i18next';
import { handleLogout } from "../../../../redux/authentication";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import WorkRateLogo from '../../../../assets/main/workrate_white_bluedot.png';

const Sidebar = () => {
    const { t } = useTranslation();
    const user = useSelector(state => state.authentication)
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation(); // Get the current location

    const menuItems = user.role !== "admin" ? [
        { title: t("home"), icon: HomeIcon, slug: '/' },
        { title: t("post_survey_responses"), icon: PostSurevyResponseIcon, slug: '/past-survey-responses' },
        { title: t("market_data"), icon: MarketDataIcon, slug: '/market-data' },
        { title: t("talent"), icon: TalentIcon, slug: '/talent' },
        { title: t("tools"), icon: ToolsIcon, slug: '/#tools', coming_soon: true },
        { title: t("benefits"), icon: MarketDataIcon, slug: '/benefits' }
    ] : [
        { title: t("home"), icon: HomeIcon, slug: '/admin' },
        { title: "Users", icon: UsersIcon, slug: '/admin/users' },
        { title: "Companies", icon: MarketDataIcon, slug: '/admin/companies' }
    ];

    const onLogoutClick = () => {
        dispatch(handleLogout());
        navigate("/login");
    };

    return (
        <aside style={{ width: '100%', height: '100vh', backgroundColor: '#8025EE' }}>
            <div className="h-full flex flex-col justify-between py-8 px-5">
                <div>
                    {/* Logo */}
                    <div className="text-white text-2xl mb-8 text-center">
                        <img src={WorkRateLogo} alt="Logo" />
                    </div>

                    <div className="w-full">
                        <SearchBox />
                    </div>

                    <ul className="mt-5 w-full">
                        {menuItems.map((item, index) => (
                            <li
                                key={`menu-item-${index}`}
                                className={`menu-item py-2 px-2 rounded-md text-gray-300 flex justify-between ${
                                    location.pathname === item.slug ? 'bg-blue-500 text-white' : 'hover:bg-red'
                                }`}
                            >
                                <Link to={item.slug} className="flex">
                                    <img src={item.icon} alt="Home" /> <span className="ml-2">{item.title}</span>
                                </Link>
                                {item.coming_soon && <span className="text-yellow-600 text-sm">Coming soon</span>}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="">
                    <div className="text-gray-300 hover:bg-blue-500 cursor-pointer px-2 py-2 rounded flex" onClick={onLogoutClick}>
                        <img src={LogoutIcon} alt="Logout icon" style={{ height: '16px', marginTop: '5px' }} />
                        <div className="ml-2">{t("logout")}</div>
                    </div>
                    <div style={{ borderTop: '1px solid #999' }} className="my-2"></div>

                    <Link to={'/faq'} className="text-gray-300 hover:bg-blue-500 cursor-pointer px-2 py-2 rounded flex" onClick={onLogoutClick}>
                        <img src={FAQIcon} alt="" style={{ height: '16px', marginTop: '5px' }} />
                        <div className="ml-2">{'FAQ'}</div>
                    </Link>
                    <Link to={'/data/wr-levels'} className="text-gray-300 hover:bg-blue-500 cursor-pointer px-2 py-2 rounded flex" onClick={onLogoutClick}>
                        <img src={FAQIcon} alt="Logout icon" style={{ height: '16px', marginTop: '5px' }} />
                        <div className="ml-2">{'WR Levels'}</div>
                    </Link>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
