import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function TBodyCell({ data, classes, style }) {
    const defaultClasses = 'px-3 py-3 whitespace-no-wrap text-sm font-normal leading-5 text-gray-500 text-center';

    return (
        <td className={`${classes ? `${defaultClasses} ${classes}` : defaultClasses}`} style={style}>
            {data}
        </td>
    )
}
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f9f9f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function AllowanceTooltip({ children, ...props }) {
    const { item } = props

    const get_allowances = (item) => {
        // render allowances as table from array of object where object key is allowance name and value is allowance amount

        const allowances = item.list
        return (
            <table className="min-w-full divide-y divide-gray-200">
                <tbody>
                    {
                        allowances.map((sbitem, index) => {
                            const key = Object.keys(sbitem)[0]
                            const value = sbitem[key]
                            return (
                                <tr key={`allowance-${index}`} className={`border-b border-gray-200 ${index === allowances.length - 1 ? 'border-b-0' : ''}`}>
                                    <TBodyCell data={key.replaceAll("_", " ")} style={{
                                        textAlign: 'left',
                                        textTransform: 'capitalize'
                                    }} />
                                    <TBodyCell 
                                    data={`${value?.toLocaleString()}`}
                                    style={{
                                        textAlign: 'right',
                                    }} />
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }

    return (

        <HtmlTooltip
            title={
                <React.Fragment>
                    <div className="py-1" role="none">
                        <div
                            className="block px-4 py-2 text-sm text-gray-700"
                            role="menuitem"
                        >
                            <div style={{
                                borderBottom: '1px solid #ddd',
                                paddingBottom: '10px',
                            }}>
                            {item.list.length} Allowances
                            </div>
                            {get_allowances(item)}
                        </div>
                    </div>
                </React.Fragment>
            }
        >
            <span style={{
                cursor: 'pointer',
            }}>
                {props.data}
            </span>
        </HtmlTooltip>
    );
}