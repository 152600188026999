import { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/Dashboard";
import instance from "../../../auth/useJwt";
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid } from "@mui/material";
import Stats from "./components/Stats";
import BarChart from "./components/IndustriesStats";
import CountriesStats from "./components/CountriesStats";

export default function AdminHome() {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    const getData = () => {
        instance.get("/admin/dashboard").then(res => {
            setData(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        }
        )
    }

    useEffect(() => {
        getData()
    }, [])



    return (
        <DashboardLayout title="Admin">
            <div className="px-4">

                {

                    loading ?
                        <Box sx={{ height: '60vh', flexDirection: 'column', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                            <CircularProgress />

                        </Box> :
                        <Box>
                            <Stats data={data} />
                            <Box sx={{ mt: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Card sx={{ border: '1px solid #eee' }}>
                                            <Box sx={{
                                                backgroundColor: '#F9FAFB',
                                                borderBottom: '1px solid #ddd',
                                                fontSize: '1.3rem', px: 2, py: 1.1

                                            }}>Industries</Box>
                                            <CardContent>
                                                <BarChart series={data.industries.series} categories={data.industries.categories} />
                                            </CardContent>
                                        </Card>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Card sx={{ border: '1px solid #eee' }}>
                                        <Box sx={{
                                                backgroundColor: '#F9FAFB',
                                                borderBottom: '1px solid #ddd',
                                                fontSize: '1.3rem', px: 2, py: 1.1

                                            }}>Countries</Box>
                                            
                                            <CardContent>
                                                <CountriesStats series={data.countries.series[0].data} categories={data.countries.categories} />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                }

            </div>

        </DashboardLayout>
    )
}