import BlockHeading from "../../../../../../components/forms/BlockHeading";
import Label from "../../../../../../components/forms/InputLabel";
import SelectAutocomplete from "../../../../../../components/forms/SelectAutocomplete";
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle";


const TRAINING_DEV_POLICIES = [
    { label: "Yes, For all levels of the organisation", value: "Yes, For all levels of the organisation" },
    { label: "Yes, For some levels of the organisation", value: "Yes, For some levels of the organisation" },
    { label: "Yes, but only on a case by case basis", value: "Yes, but only on a case by case basis" },
    { label: "No formal training and development program", value: "No formal training and development program" }
]

const TRAINING_NEEDS = [
    { label: "Observing employees ", value: "Observing employees" },
    { label: "Assessments/surveys", value: "Assessments/surveys" },
    { label: "Consultation with subject experts", value: "Consultation with subject experts" },
    { label: "Focus groups", value: "Focus groups" },
    { label: "Monitoring external trends", value: "Monitoring external trends" },
    { label: "Conducting formal assessments", value: "Conducting formal assessments" },
    { label: "Examining work", value: "Examining work" }
]


const TRAINING_KINDS = [
    { label: "Educational Videos", value: "Educational Videos" },
    { label: "Work Shadowing", value: "Work Shadowing" },
    { label: "Attending conferences/seminars", value: "Attending conferences/seminars" },
    { label: "Training and instructional guides", value: "Training and instructional guides" },
    { label: "Case studies", value: "Case studies" },
    { label: "Group discussions", value: "Group discussions" },
    { label: "Mentoring and coaching", value: "Mentoring and coaching" },
    { label: "Simulation methods", value: "Simulation methods" },
    { label: "Benchmarking", value: "Benchmarking" },
    { label: "Questionnaires/Analytical approach", value: "Questionnaires/Analytical approach" },
    { label: "Online learning", value: "Online learning" },
    { label: "Professional certifications/degrees", value: "Professional certifications/degrees" },
]

const TRAINING_BUDGET = [
    { label: "Up to USD25,000", value: "Up to USD25,000" },
    { label: "USD25,000 to USD50,000", value: "USD25,000 to USD50,000" },
    { label: "USD50,000 to USD75,000", value: "USD50000 to USD75,000" },
    { label: "USD75,000 to USD100,000", value: "USD75,000 to USD100,000" },
    { label: "USD100,000 to USD250,000", value: "USD100,000 to USD250,000" },
    { label: "USD250,000 to USD500,000", value: "USD250,000 to USD500,000" },
    { label: "USD500,000 to USD 1 Million", value: "USD500,000 to USD 1 Million" },
    { label: "USD 1Million to USD 2.5 Million", value: "USD 1Million to USD 2.5 Million" },
    { label: "> USD 2.5 Million", value: "> USD 2.5 Million" },
]

export default function TrainingDevelopmentPolicies(props) {
    const id = 'training_development_policies'
    const { watch, errors, control, register } = props
    

    return (
        <div>
            <BlockHeading text="2. Training and development policies" />

            <div className="my-2">
                <Label className="mb-2" text="a) Does your organisation have a formal training and development program in place for all staff?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.formal_training`}
                    errors={errors} watch={watch}
                    options={TRAINING_DEV_POLICIES} control={control} />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-2">
                    <div>
                        <Label className="mb-2" htmlFor="training_needs_identification"
                            text="How are training needs identified? (multiple selection)" />
                        <SelectAutocomplete control={control} register={register} watch={watch}
                            options={TRAINING_NEEDS} name={`${id}.training_needs_identification`} />
                    </div>
                    <div>
                        <Label className="mb-2" htmlFor="training_kinds"
                            text=" If so, what kind of training is offered? (multiple selection)" />
                        <SelectAutocomplete control={control} register={register} watch={watch}
                            options={TRAINING_KINDS} name={`${id}.training_kinds`} />
                    </div>
                </div>
                <div className="mt-2">
                    <Label text="c) What is your annual training budget?" className="mb-2" />
                    <SelectAutocompleteSingle errors={errors} control={control} register={register} watch={watch}
                        required={false}
                        options={TRAINING_BUDGET} name={`${id}.training_budget`} />
                </div>
            </div>

        </div>
    )
}