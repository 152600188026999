import { useDispatch, useSelector } from "react-redux"
import Intro from "./Intro"
import { useEffect, useState } from "react"
import CompanyDemographic from "./CompanyDemographic"
import CompensationData from "./CompensationData"
import OnBoardStepper from "./Stepper"
import FileSubmission from "./FileSubmission"
import BenefitsData from "./Benefits"
import FinishSubmission from "./Finish"
import instance from "../../../auth/useJwt"
import { setCompanyInfoData } from "../../../redux/onboard/company_info"
import { setHrPoliciesData } from "../../../redux/onboard/benefits/hr_policies"
import { setCompPracticeData } from "../../../redux/onboard/benefits/compensation_practices"
import { setIncentivesData } from "../../../redux/onboard/benefits/incentives"
import { setInsuranceData } from "../../../redux/onboard/benefits/insurance"
import { setOtherBenefitsData } from "../../../redux/onboard/benefits/other_benefits"
import { setWellBeingData } from "../../../redux/onboard/benefits/well_being"
import { setExpatriateCompensationData } from "../../../redux/onboard/benefits/expatriate_compensation"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetCompensationInfoData } from "../../../redux/onboard/compensation_info"
import { setCompensationSubmissionData } from "../../../redux/onboard/compensation_submission"
import { useNavigate } from "react-router-dom"
import PageLoader from "../../../components/Loaders/PageLoader"
import { onBoardingReadOnly, setOnBoardingReadOnly } from "../../../redux/app_data";
import OnBoardDone from "../../../components/messages/OnBoardDone"


export default function OnBoarding() {
    const [step, setStep] = useState("intro")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.authentication)
    console.log(user)
    const [onBoardDone, setOnBoardDone] = useState(false)
    const [apiReq, setApiReq] = useState({
        loading: true, error: null
    })
    const stepper_vals = {
        company_info: 0, benefits: 1, compensation_data: 2, submission: 3, finish: 4
    }
    const benefits_tabs = {
        hr_policies: 0,
        compensation_practices: 1,
        incentives: 2,
        insurance: 3,
        other_benefits: 4,
        well_being: 5,
        expatriate_compensation: 6
    }
    const [benefitsTab, setBenefitsTab] = useState(null)

    // if route is /past-survey-responses, set isReadOnly to true
    useEffect(() => {
        if (window.location.pathname === "/past-survey-responses" || window.location.pathname === "/admin/companies/view") {
            dispatch(setOnBoardingReadOnly(true));
        } else {
            dispatch(setOnBoardingReadOnly(false));
        }
    }, [window.location.pathname])

    //compensation_practices, incentives, insurance, other_benefits, well_being, expatriate_compensation
    const company_info = useSelector(state => state.company_info).data
    const benefits = {
        hr_policies: useSelector(state => state.hr_policies).data,
        compensation_practices: useSelector(state => state.compensation_practices).data,
        incentives: useSelector(state => state.incentives).data,
        insurance: useSelector(state => state.insurance).data,
        other_benefits: useSelector(state => state.other_benefits).data,
        well_being: useSelector(state => state.well_being).data,
        expatriate_compensation: useSelector(state => state.expatriate_compensation).data,
    }
    const compensation_data = useSelector(state => state.compensation_info)
    const compensation_submission = useSelector(state => state.compensation_submission)


    const isReadOnly = useSelector(onBoardingReadOnly);
    const urlParams = new URLSearchParams(window.location.search);
    const company_id = urlParams.get('id');


    const fetchPreviousData = () => {
        console.log("fetching data")
        setApiReq({ loading: true, error: null })
        instance.get("/on-boarding?id=" + company_id).then(res => {
            if (res.data) {
                const dt = res.data

                const benfits_m = dt.benefits

                dispatch(setCompanyInfoData(res.data.company_info))

                // Set benefits data
                dispatch(setHrPoliciesData(benfits_m.hr_policies))
                dispatch(setCompPracticeData(benfits_m.compensation_practices))
                dispatch(setIncentivesData(benfits_m.incentives))
                dispatch(setInsuranceData(benfits_m.insurance))
                dispatch(setOtherBenefitsData(benfits_m.other_benefits))
                dispatch(setWellBeingData(benfits_m.well_being))

                dispatch(setExpatriateCompensationData(benfits_m.expatriate_compensation))

                if (res.data.compensation_data) {
                    dispatch(resetCompensationInfoData(res.data.compensation_data))
                }


                if (res.data.sub_tab) {
                    if (window.location.pathname !== "/past-survey-responses" || window.location.pathname !== "/admin/companies/view") {
                        setBenefitsTab(benefits_tabs[res.data.sub_tab])
                    }
                }

                if (res?.data?.compensation_xlsx_data) {
                    dispatch(setCompensationSubmissionData(res?.data?.compensation_xlsx_data))
                }

                if (res?.data?.finish) {
                    setOnBoardDone(true)

                    if (window.location.pathname === "/") {
                        if (res?.data?.approved) {
                            navigate("/dashboard")
                        }else{
                            //navigate("/past-survey-responses")
                        }

                    }
                }
                if (window.location.pathname === "/") {
                    setStep(res.data.tab)
                } else {
                    setStep("company_info")
                }

                setApiReq({ loading: false, error: null })
            }
        }).catch(err => {
            console.log("error", err)
            setApiReq({ loading: false, error: "Error fetching data" })
        })
    }

    useEffect(() => {
        fetchPreviousData()
        //console.log(company_id)
        console.log(window.location.pathname)
    }, [window.location.pathname])


    const onNext = (tb) => {
        setStep(tb)
    }

    const onSaveClick = (main_key, sub_key, data) => {
        // if (onBoardDone) {
        //     return
        // }
        if (isReadOnly) {
            return
        }

        const sv_data = {
            company_info: company_info,
            benefits: benefits,
            compensation_data: compensation_data,
            compensation_xlsx_data: compensation_submission,
            tab: main_key, sub_tab: sub_key
        }
        if (main_key === "benefits") {
            sv_data.benefits[sub_key] = data
        } else {
            sv_data[main_key] = data
        }

        console.log(sv_data)
        //return

        //setOnBoardData(sv_data)


        //setApiReq({ loading: true, error: null })
        instance.patch("/on-boarding", sv_data).then(res => {
            console.log(res)

            //setApiReq({ loading: false, error: null })
            if (main_key === "finish") {
                setOnBoardDone(true)
            }

            toast.success('Data updated successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }).catch(err => {
            console.log(err)

            //setApiReq({ loading: false, error: "Error submitting data" })
        })
    }



    const comps = {
        intro: <Intro onNext={onNext} />,
        company_info: <CompanyDemographic onNext={onNext} onSaveClick={onSaveClick} />,
        benefits: <BenefitsData onNext={onNext} onSaveClick={onSaveClick} tab={benefitsTab} />,
        compensation_data: <CompensationData onNext={onNext} onSaveClick={onSaveClick} />,
        submission: <FileSubmission onNext={onNext} />,
        finish: <FinishSubmission onSaveClick={onSaveClick} apiReq={apiReq} finishData={onBoardDone} />
    }


    const handleStep = (index) => {
        for (let [key, value] of Object.entries(stepper_vals)) {
            if (value === index) {
                setStep(key)
            }
        }
    }


    if (onBoardDone && window.location.pathname === "/") {
        if (user?.access?.approved){
            return navigate("/dashboard")
        }
        return <OnBoardDone />
    }


    return (
        <div className="pt-5">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            {user.onboarding_approved || user.role === "admin" ?
                <div>

                    {apiReq.loading ? <PageLoader /> :
                        <div>
                            {step !== "intro" &&
                                <OnBoardStepper val={stepper_vals[step]} handleStep={handleStep} />
                            }
                            <div className="p-4">

                                <div className="p-6 w-full max-w-8xl mx-auto">
                                    {comps[step]}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :
                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-3xl font-semibold text-gray-800 mt-4">Your account is not approved yet for onboarding.</h1>
                    <p className="text-gray-600 mt-4">Please contact <a className="text-blue-500" href="mailto:clients@theworkrate.com">clients@theworkrate.com</a></p>
                </div>
            }
        </div>
    )
}