import React from 'react';
import { Box } from '@mui/material';
import Chart from 'react-apexcharts'
import { styled } from "@mui/material";
import { WR_PIE_GRAPH_COLORS } from '../../../../configs/colors';

const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)",
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))


export default function DepartmentCompensation(props) {
    const { data } = props
    if (!data) return null
    const state = {
          
        series: data.compensations,
        options: {
            chart: {
                type: 'bar',
                height: 1000,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false, // This disables the controls
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '60%',
                    //borderRadius: 10
                },
            },
            tooltip: {
                // Only show in integer format
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    
                    const dt = series[0][dataPointIndex]
                    const dt2 = series[1][dataPointIndex]
                    return (
                        `<div class="custom-tooltip">
                        <div class="custom-tooltip-header">${props.data.categories[dataPointIndex]}</div>
                        <div class="custom-tooltip-value" style="display: flex"><div style="min-width: 125px">Annual Base Salary:</div> ${dt.toLocaleString()}</div>
                        <div class="custom-tooltip-value" style="display: flex"><div style="min-width: 125px">Allowances:</div> ${dt2.toLocaleString()}</div>
                        </div>`
                    );
                }
            },
            colors: WR_PIE_GRAPH_COLORS.slice(1, 3).reverse(),
            grid: {
                show: false, // This disables the grid
            },
            xaxis: {
                categories: props.data.categories,
                labels: {
                    show: false, // Ensure they are set to show
                    rotate: -45, // Optionally rotate them for better visibility
                    style: {
                        colors: '#333', // Change color to something more visible
                        fontSize: '12px' // Adjust font size if needed
                    }
                },
                axisBorder: {
                    show: false,  // This hides the x-axis line
                }
              },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            dataLabels: {
                enabled: false, // This hides data labels on the bars
            },
            yaxis: {
                labels: {
                    show: true
                },
                axisBorder: {
                    show: false,  // This hides the y-axis line
                }
            },
            
            fill: {
                opacity: 1
            },
            legend: {
                show: true, // This disables the legends
            }
        }
        
    }

    return (

            <SingleCard>
                <h2 className='text-xl font-semibold text-gray-600'>Compensation by Job Families</h2>
                {/* <p className='text-sm mt-2 text-gray-500'>Base salary valuable benifits</p> */}
                <Box sx={{ pr: 1 }}>
                <Chart series={state.series} options={state.options} type="bar" height={data.compensations[0].data?.length > 6 ? parseInt(data.compensations[0].data?.length * 25) : parseInt(data.compensations[0].data?.length * 35) } />
                </Box>
            </SingleCard>
    );
}



