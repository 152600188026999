import { Grid } from '@mui/material';
import React from 'react';
import DataTable from './components/DataTable';
import HFilters from './components/HFilters';

export default function OrgLevel(props) {

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={12}>
            <div className='pb-5'>
            <HFilters />
            </div>
            <DataTable data={props.data} loading={props.loading} />
        </Grid>
    </Grid>
  );
}
