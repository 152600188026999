export const IndustrialProducts = {
    "Construction": {
        "Engineering": {
            "job_family_descriptor": "This job family is a multidisciplinary field that involves the application of scientific principles and mathematical concepts to design, develop, and innovate solutions for real-world challenges. This family encompasses a wide range of disciplines, each with its unique focus, including civil, mechanical, electrical, chemical, and aerospace engineering, among many others",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Engineering Officer / Chief Engineer",
                    "position_descriptor": " The Chief Engineering Officer or Chief Engineer holds the highest position in the engineering hierarchy, overseeing the engineering department's strategic direction and management.",
                    "related_job_titles": "Chief Engineering Officer, Chief Engineer, Chief Technical Officer, Vice President of Engineering."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Engineering Director",
                    "position_descriptor": " The Engineering Director is responsible for managing the engineering division, setting departmental goals, and ensuring project success.",
                    "related_job_titles": "Engineering Director, Director of Engineering, Director of Research and Development, Director of Technical Services."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Engineering Manager",
                    "position_descriptor": " Senior Engineering Managers lead major engineering projects, supervise teams, and coordinate complex initiatives.",
                    "related_job_titles": "Senior Engineering Manager, Senior Engineering Program Manager, Engineering Team Lead, Senior Project Manager."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Engineering Manager",
                    "position_descriptor": " Engineering Managers oversee engineering projects, including planning, budgeting, and coordination, to ensure they meet quality and schedule standards.",
                    "related_job_titles": "Engineering Manager, Project Engineering Manager, Engineering Department Manager, Engineering Operations Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Project Engineer",
                    "position_descriptor": " Project Engineers manage specific engineering projects, collaborating with project teams to ensure successful execution.",
                    "related_job_titles": "Project Engineer, Project Manager Engineer, Project Coordinator, Design Engineer."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Senior Engineer",
                    "position_descriptor": " Senior Engineers possess advanced expertise and play a pivotal role in engineering design, analysis, and project leadership.",
                    "related_job_titles": "Senior Engineer, Senior Design Engineer, Senior Structural Engineer, or Senior Process Engineer."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Engineer",
                    "position_descriptor": " Engineers are responsible for designing, analyzing, and implementing engineering solutions and often work under the guidance of senior engineers.",
                    "related_job_titles": "Engineer, Mechanical Engineer, Civil Engineer, Electrical Engineer, Environmental Engineer"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Engineering Specialist",
                    "position_descriptor": " Engineering Specialists focus on a specific engineering discipline or niche area, providing specialized expertise within the organization.",
                    "related_job_titles": "Engineering Specialists, Aerospace Specialist, Materials Engineering Specialist, Water Resources Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Engineering Technician",
                    "position_descriptor": " Engineering Technicians provide technical support, perform tests, and assist engineers in various engineering projects.",
                    "related_job_titles": "Engineering Technician, CAD Technician, Test Technician, Quality Control Technician"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Engineering Associate",
                    "position_descriptor": " Engineering Associates support engineering teams with data analysis, documentation, and project coordination.",
                    "related_job_titles": "Engineering Associates, Design Associate, Technical Associate, Project Support Associate."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Engineering Assistant",
                    "position_descriptor": " Engineering Assistants perform entry-level tasks, assisting engineers and technicians in their daily activities.",
                    "related_job_titles": "Engineering Assistant, Assistant Engineer, Junior Engineer, or Entry-Level Engineering Assistant."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Engineering Trainee / Apprentice",
                    "position_descriptor": " Engineering Trainees or Apprentices are individuals who are in the early stages of their engineering careers, learning the fundamentals and gaining hands-on experience.",
                    "related_job_titles": " Engineering Trainees and Apprentices may have titles like Engineering Intern, Apprentice Engineer, Trainee Technician."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Engineering Aide / Junior Engineering Assistant",
                    "position_descriptor": " Engineering Aides or Junior Engineering Assistants are entry-level personnel who provide general administrative and operational support to engineering teams.",
                    "related_job_titles": "Engineering Aide, Junior Engineering Assistant, Junior Technician, Engineering Support Assistant, or Entry-Level Aide."
                }
            ]
        },
        "Architecture": {
            "job_family_descriptor": "This job family encompasses professionals who design and plan structures, providing creative solutions that balance aesthetics, functionality, safety, and sustainability. Architects collaborate with clients, engineers, and construction teams to bring their designs to life.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Principal Architect / Chief Architect",
                    "position_descriptor": " The Principal Architect or Chief Architect is the top leadership position in an architecture firm, responsible for setting the firm's design direction and overseeing its operations.",
                    "related_job_titles": "Principal Architect, Chief Architect, Senior Design Partner, Design Principal, or Design Director."
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Director of Architecture",
                    "position_descriptor": " The Director of Architecture manages the architectural department or division, guiding design strategies and ensuring project excellence.",
                    "related_job_titles": "Director of Architecture, Director of Design, Director of Projects, Architecture Division Head."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Design Architect",
                    "position_descriptor": " Senior Design Architects lead complex architectural projects, providing creative design solutions and direction.",
                    "related_job_titles": "Senior Design Architect, Lead Design Architects, Senior Designers, or Principal Designers."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Project Architect / Senior Architect",
                    "position_descriptor": " Project Architects or Senior Architects manage individual architectural projects, coordinating design teams and overseeing project execution.",
                    "related_job_titles": "Project Architect, Senior Architect, Senior Project Managers, Project Leaders, Project Coordinators."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Architect",
                    "position_descriptor": " Architects are responsible for designing buildings and structures, working on specific aspects of projects, and collaborating with clients.",
                    "related_job_titles": "Architect, Design Architect, Project Architect, Licensed Architect"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Design Associate / Junior Architect",
                    "position_descriptor": " Design Associates or Junior Architects are developing professionals who assist senior architects with design work and project coordination.",
                    "related_job_titles": "Design Associate, Junior Architect, Junior Designers, Associate Architects, or Assistant Architects."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Architectural Designer",
                    "position_descriptor": " Architectural Designers focus on the aesthetic and functional aspects of design, creating innovative solutions for projects.",
                    "related_job_titles": "Architectural Designer, Design Specialists, Concept Designers, Design Consultants."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Architectural Drafter / CAD Technician",
                    "position_descriptor": " Architectural Drafters or CAD Technicians create technical drawings and plans based on architects' designs.",
                    "related_job_titles": "Architectural Drafter, CAD Technician, Drafting Technicians, CAD Designers, or Technical Drafters"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Architectural Technician / Junior Drafter",
                    "position_descriptor": " Architectural Technicians or Junior Drafters assist in creating architectural drawings, models, and technical documents.",
                    "related_job_titles": "Architectural Technician, Junior Drafter, Junior CAD Technicians, Drafting Assistants, Junior Technical Drafters"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Architectural Assistant",
                    "position_descriptor": " Architectural Assistants provide support to architects and drafters, performing various administrative and entry-level tasks.",
                    "related_job_titles": "Architectural Assistant, Assistant Architect, Junior Architecture Assistant, Design Assistant."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Architectural Trainee / Intern",
                    "position_descriptor": " Architectural Trainees or Interns are individuals in the early stages of their architectural careers, learning the fundamentals and gaining hands-on experience.",
                    "related_job_titles": "Architectural Trainee, Architecture Intern, Trainee Architect, Intern Designer."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Architecture Aide / Junior Architectural Assistant",
                    "position_descriptor": " Architecture Aides or Junior Architectural Assistants assist architectural teams by providing administrative and operational support.",
                    "related_job_titles": "Architecture Aide, Junior Architectural Assistant, Junior Design Assistant, Architectural Support Assistant, Entry-Level Aide."
                }
            ]
        },
        "Building and Construction Services": {
            "job_family_descriptor": "This job family encompasses professionals involved in the construction, renovation, and maintenance of buildings and structures",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Construction Officer",
                    "position_descriptor": " The Chief Construction Officer holds the highest leadership position in a construction company, overseeing all operations and strategic direction.",
                    "related_job_titles": "Chief Construction Officer (CCO), CEO of Construction, Chief Executive, Construction Industry Leader."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Construction",
                    "position_descriptor": " The Director of Construction manages the construction division or department, setting goals, strategy, and ensuring project success.",
                    "related_job_titles": "Director of Construction, Director of Operations, Director of Projects, Construction Division Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Project Manager / Project Director",
                    "position_descriptor": " Senior Project Managers or Project Directors lead major construction projects, managing teams and coordinating complex initiatives.",
                    "related_job_titles": "Senior Project Manager, Project Director, Senior Construction Project Manager, or Senior Project Leader."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Project Manager",
                    "position_descriptor": " Project Managers are responsible for planning, executing, and closing construction projects, ensuring quality, budget, and schedule goals are met.",
                    "related_job_titles": "Project Manager, Construction Project Manager, Project Coordinator, or Project Team Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Construction Manager",
                    "position_descriptor": " Construction Managers oversee on-site construction activities, ensuring safety, quality, and efficiency.",
                    "related_job_titles": "Construction Manager, Site Manager, Field Operations Manager, Construction Site Supervisor."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Construction Engineer",
                    "position_descriptor": " Construction Engineers focus on design, quality control, and project management within the construction field.",
                    "related_job_titles": "Construction Engineer, Design Engineer, Project Engineer, or Construction Engineering Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Construction Supervisor",
                    "position_descriptor": " Construction Supervisors lead teams of construction workers, ensuring adherence to safety guidelines and efficient task completion.",
                    "related_job_titles": "Construction Supervisor, Site Supervisor, Foreman, or On-Site Construction Supervisor."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Construction Foreman",
                    "position_descriptor": " Construction Foremen supervise small groups of construction workers, directing their work and ensuring tasks are completed as planned.",
                    "related_job_titles": "Construction Foreman, Crew Leader, Foreman Supervisor, or Site Foreman"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Construction Technicians",
                    "position_descriptor": " Construction Technicians provide technical support, conduct tests, and assist engineers and managers with various tasks.",
                    "related_job_titles": "Construction Technicians, Quality Control Technician, Materials Testing Technician, or Field Technician."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Construction Assistants",
                    "position_descriptor": " Construction Assistants support project teams by performing administrative and operational tasks.",
                    "related_job_titles": "Construction Assistants, Assistant Project Manager, Construction Support Assistant, or Project Coordinator Assistant."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Skilled Trades Workers",
                    "position_descriptor": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within construction.",
                    "related_job_titles": "Skilled Trades Workers, Electrician, Plumber, Carpenter, Mason, HVAC Technician, Welder, Painter, Pipefitter, Roofing Specialist, Concrete Finisher, Ironworker, Glazier, Tile Setter, Drywall Installer, Millwright"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Construction Laborers / General Laborers",
                    "position_descriptor": " Construction Laborers or General Laborers provide manual labor support on construction sites, assisting skilled trades workers and following supervisors' instructions.",
                    "related_job_titles": "Construction Laborers, General Laborers, Construction Worker, Laborer, Site Helper, Mate"
                }
            ]
        }
    },
    "Metals and Mining": {
        "Mining": {
            "job_family_descriptor": "This job family includes professionals engaged in the exploration, extraction, and processing of minerals, metals, and natural resources",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Mining Officer",
                    "position_descriptor": " The Chief Mining Officer or CEO leads the mining company, setting strategic direction, overseeing operations, and ensuring the company's success.",
                    "related_job_titles": "Chief Mining Officer, CEO of Mining, Mining Industry Leader."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Mining",
                    "position_descriptor": " Directors of Mining manage the mining department, setting goals, strategy, and ensuring effective mining operations.",
                    "related_job_titles": "Director of Mining, Director of Operations, Director of Mining Projects, Mining Division Director."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Mining Project Director",
                    "position_descriptor": " Mining Project Directors oversee large mining projects, providing leadership, planning, and management to ensure successful outcomes.",
                    "related_job_titles": "Mining Project Director, Project Director, Senior Mining Project Manager, Project Leader."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Mining Engineer",
                    "position_descriptor": " Senior Mining Engineers lead the design, planning, and execution of mining projects, ensuring safety, efficiency, and environmental compliance.",
                    "related_job_titles": "Senior Mining Engineer, Lead Mining Engineer, Senior Geotechnical Engineer, Senior Mining Engineering Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Mining Engineer",
                    "position_descriptor": " Mining Engineers plan and coordinate mining operations, design and implement mining systems, and manage production processes.",
                    "related_job_titles": "Mining Engineer, Mining Operations Engineer, Project Mining Engineer, Mining Project Manager, Mining Engineering Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Mining Supervisor",
                    "position_descriptor": " Mining Supervisors oversee on-site mining activities, ensuring safety, compliance, and efficient mineral extraction.",
                    "related_job_titles": "Mining Supervisor, Mining Site Supervisor, Mining Operations Supervisor, On-Site Mining Supervisor."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Mining Foreman",
                    "position_descriptor": " Mining Foremen supervise teams of miners, directing their work, and ensuring tasks are completed safely and efficiently.",
                    "related_job_titles": "Mining Foreman, Crew Leader, Mining Site Foreman, Foreman Supervisor."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Geologist",
                    "position_descriptor": " Geologists study the Earth's structure to locate mineral resources, assess deposit quality, and plan exploration and mining activities.",
                    "related_job_titles": "Geologist, Exploration Geologist, Mining Geologist, Geological Consultant."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Mining Technician",
                    "position_descriptor": " Mining Technicians provide technical support, conduct tests, and assist engineers and geologists with various tasks.",
                    "related_job_titles": "Mining Technician, Mining Technical Specialist, Testing Technician, Mining Support Technician."
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Driller / Blaster",
                    "position_descriptor": " Drillers and Blasters operate drilling equipment, explosives, and machinery to extract minerals and ores from the earth.",
                    "related_job_titles": "Driller, Blaster, Mining Driller, Blasting Technician, or Drilling Specialist."
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Miner",
                    "position_descriptor": " Miners perform the physical work of extracting minerals and ores from underground or open-pit mines, operating heavy equipment and machinery.",
                    "related_job_titles": "Miner, Underground Miner, Open-Pit Miner, or Miner Operator."
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Mining Laborer / Entry-Level Miner",
                    "position_descriptor": " Mining Laborers or Entry-Level Miners perform manual labor and entry-level tasks on mining sites, following instructions from supervisors.",
                    "related_job_titles": "Mining Laborer, Entry-Level Miner, Mining Site Assistant."
                }
            ]
        }
    },
    "Manufacturing": {
        "Production Management": {
            "job_family_descriptor": "This job family focuses on overseeing and managing the production processes within a manufacturing or production facility.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Manufacturing Officer",
                    "position_descriptor": " The Chief Manufacturing Officer is the highest-ranking executive responsible for leading the manufacturing operations, setting the strategic direction, and ensuring overall success.",
                    "related_job_titles": "Chief Manufacturing Officer, Deputy CEO-Manufacturing, Manufacturing Industry Leader, Chief Operations Officer (COO), Manufacturing Executive Director"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Manufacturing",
                    "position_descriptor": " Directors of Manufacturing oversee the manufacturing department, setting goals, strategy, and ensuring the efficient operation of the manufacturing division.",
                    "related_job_titles": "Director of Manufacturing, Director of Manufacturing Operations, Director of Manufacturing Projects, Director of Production, Director of Manufacturing Strategy, Manufacturing Division Director, Director of Plant Operations"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Manufacturing Project Director",
                    "position_descriptor": " Manufacturing Project Directors lead large-scale manufacturing projects, providing leadership, project management, and direction for successful project outcomes.",
                    "related_job_titles": "Manufacturing Project Director, Project Director, Senior Project Manager, Manufacturing Project Leader, Project Director \u2013 Operations, Director of Project Management, Program Director - Manufacturing"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Production Manager",
                    "position_descriptor": " Senior Production Managers are responsible for planning, organizing, and supervising production processes, ensuring quality, efficiency, and on-time delivery.",
                    "related_job_titles": "Senior Production Manager, Production Operations Manager, Senior Manager, Manufacturing, Manufacturing Operations Director, Production Manager II"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Production Manager",
                    "position_descriptor": " Production Managers oversee day-to-day manufacturing operations, coordinating resources, monitoring production targets, and ensuring quality control.",
                    "related_job_titles": "Production Manager, Manufacturing Manager, Production Operations Supervisor, Manufacturing Operations Manager, Production Lead Coordinator, Production Team Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Production Supervisor",
                    "position_descriptor": " Production Supervisors lead teams of workers on the manufacturing floor, ensuring adherence to safety guidelines, quality standards, and efficiency in production processes.",
                    "related_job_titles": "Production Supervisor, Manufacturing Supervisor, Production Line Manager, Shift Supervisor, Operations Supervisor, Shop Floor Supervisor"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Quality Control Analysts",
                    "position_descriptor": " Quality Control analysts manage and oversee quality control processes, ensuring that products meet established quality standards and regulatory requirements.",
                    "related_job_titles": "Quality Control Manager, Quality Assurance Manager, Quality Manager, Quality Control Supervisor, Quality Assurance Supervisor, Quality Inspector"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Production Technician",
                    "position_descriptor": " Production Technicians provide technical support in manufacturing processes, operate machinery, and assist in product assembly and quality checks.",
                    "related_job_titles": "Production Technician, Production Technician, Manufacturing Technician, Production Technical Specialist, Production Support Technician, Manufacturing Specialist, Assembly Technician"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Manufacturing Technician",
                    "position_descriptor": " Manufacturing Technicians assist engineers and technicians in manufacturing processes, conduct tests, and handle equipment maintenance. Can guide teams  as shift leads",
                    "related_job_titles": "Manufacturing Technician, Production Technician, Assembly Technician, Manufacturing Technical Assistant, Quality Technician, Manufacturing Support Technician, Shift Supervisor"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Machine Operator",
                    "position_descriptor": " Machine Operators operate and maintain production machinery, ensuring it runs efficiently and produces quality products.",
                    "related_job_titles": "Machine Operator, Manufacturing Machine Operator, Machine Technician, CNC Operator, Production Machine Operator, Manufacturing Equipment Operator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Assembly Line Worker",
                    "position_descriptor": " Assembly Line Workers work on production lines, assembling and inspecting products, and following manufacturing procedures.",
                    "related_job_titles": "Assembly Line Worker, Manufacturing Assembler, Production Line Assembler, Manufacturing Line Worker, Factory Worker, Production Operator"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Manufacturing Assistant / Entry-Level Worker",
                    "position_descriptor": " Manufacturing Assistants or Entry-Level Workers perform basic tasks in the manufacturing process, such as material handling and support duties.",
                    "related_job_titles": "Manufacturing Assistant, Production Assistant, Manufacturing Laborer, General Laborer"
                }
            ]
        },
        "Packaging and Containers": {
            "job_family_descriptor": "This job family involves all aspects of packaging design, creating and optimizing packaging solutions to protect, store, and transport goods and plays a crucial role in product safety, branding, and sustainability",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Packaging Officer",
                    "position_descriptor": " The Chief Packaging Officer is responsible for leading the packaging and container operations, setting the strategic direction, and ensuring overall success.",
                    "related_job_titles": "Chief Packaging Officer, Chief Operations Officer (COO) \u2013 Packaging, Director of Packaging Strategy, Packaging and Containers Division Chief."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Packaging and Containers",
                    "position_descriptor": " Directors of Packaging and Containers oversee the packaging and container department, setting goals, strategy, and ensuring the efficient operation of the packaging and container division.",
                    "related_job_titles": "Director of Packaging Operations, Director of Packaging Projects, Director of Packaging Strategy, Director of Container Design, Packaging Division Director, Packaging and Containers Program Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Packaging and Containers Project Director",
                    "position_descriptor": " Packaging and Containers Project Directors lead large-scale projects related to packaging and container design, production, and innovation, providing leadership and direction for successful project outcomes.",
                    "related_job_titles": "Packaging and Containers Project Director, Project Director \u2013 Packaging, Senior Packaging Project Manager, Packaging Project Leader, Project Director \u2013 Containers, Director of Packaging Innovation, Program Director - Packaging"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Packaging Manager",
                    "position_descriptor": " Senior Packaging Managers are responsible for planning, organizing, and supervising packaging and container production processes, ensuring quality, efficiency, and on-time delivery.",
                    "related_job_titles": "Senior Packaging Manager, Packaging Operations Manager, Senior Manager, Packaging, Senior Container Production Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Packaging Manager",
                    "position_descriptor": " Packaging Managers oversee day-to-day packaging and container operations, coordinating resources, monitoring production targets, and ensuring quality control.",
                    "related_job_titles": "Packaging Manager, Packaging Operations Manager, Production Packaging Manager, Lead Packaging Coordinator, Container Production Manager, Packaging Team Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Packaging Supervisor",
                    "position_descriptor": " Packaging Supervisors lead teams of workers in packaging and container facilities, ensuring adherence to safety guidelines, quality standards, and efficiency in production processes.",
                    "related_job_titles": "Packaging Supervisor, Packaging Line Supervisor, Production Line Manager, Packaging Shift Supervisor, Operations Supervisor \u2013 Packaging, Shop Floor Supervisor - Containers"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Quality Control / QA Officer",
                    "position_descriptor": " Quality Control Analysts or Quality Assurance Officers manage and oversee quality control processes, ensuring that packaging and containers meet established quality standards and regulatory requirements.",
                    "related_job_titles": "Quality Control Officer \u2013 Packaging, Quality Assurance Officer\u2013 Containers, Packaging Quality Control Analyst, Quality Control Supervisor \u2013 Packaging, Quality Assurance Supervisor \u2013 Containers, Packaging Quality Inspector"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Packaging Technician",
                    "position_descriptor": " Packaging Technicians provide technical support in packaging and container processes, operate packaging machinery, and assist in quality checks and maintenance.",
                    "related_job_titles": "Packaging Technician, Packaging Technical Specialist, Packaging Production Technician, Packaging Equipment Technician, Container Packaging Specialist, Packaging Assembly Technician"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Machine Operator",
                    "position_descriptor": " Machine Operators in Packaging and Containers operate and maintain packaging machinery, ensuring it runs efficiently and produces quality packaging and containers.",
                    "related_job_titles": "Machine Operator \u2013 Packaging, Packaging Machine Operator, Machine Technician \u2013 Containers, CNC Operator \u2013 Packaging, Production Machine Operator \u2013 Containers, Packaging Equipment Operator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Packaging Line Worker",
                    "position_descriptor": " Packaging Line Workers work on production lines, assembling and inspecting packaging and containers, and following packaging procedures.",
                    "related_job_titles": "Packaging Line Worker, Packaging Assembler, Production Line Assembler, Packaging Line Operator, Container Line Worker, Packaging Operator"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Packaging Technician Assistant / Entry-Level Worker",
                    "position_descriptor": " Packaging Technician Assistants or Entry-Level Workers perform basic tasks in the packaging and container process, such as material handling and support duties.",
                    "related_job_titles": "Packaging Technician Assistant, Entry-Level Packaging Worker, Packaging Laborer, General Laborer, Packaging Support Operator"
                }
            ]
        },
        "Quality Assurance": {
            "job_family_descriptor": "This job family is responsible for maintaining and improving the quality of products and processes in manufacturing to establish and enforce quality standards, conduct inspections and audits, and implement quality control measures to ensure that products meet customer expectations and regulatory requirements",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Quality Officer",
                    "position_descriptor": " The Chief Quality Officer is responsible for overseeing the overall quality management and performance of the organization.",
                    "related_job_titles": "Chief Quality Officer, Director of Quality Assurance:"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Quality Assurance",
                    "position_descriptor": " Directors of Quality Assurance are responsible for leading the quality assurance department, establishing quality standards, and ensuring compliance with industry regulations and company quality policies.",
                    "related_job_titles": "Director of Quality Control, Quality Assurance Director, Head of Quality Management"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Quality Assurance Program Manager",
                    "position_descriptor": " Quality Assurance Program Managers oversee and lead large-scale quality assurance programs, ensuring that quality standards are consistently met throughout the manufacturing process.",
                    "related_job_titles": "Quality Assurance Program Manager, Quality Program Manager, Quality Improvement Manager, Program Director, Quality Assurance"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Quality Manager",
                    "position_descriptor": " Senior Quality Managers manage quality control teams, ensuring product quality, and play a key role in developing and implementing quality improvement initiatives.",
                    "related_job_titles": "Senior Quality Manager, Senior Quality Control Manager, Manager of Quality Assurance, Senior Quality Assurance Specialist"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Quality Manager",
                    "position_descriptor": " Quality Managers oversee daily quality control operations, including inspections, audits, and process improvements, to maintain high product quality standards.",
                    "related_job_titles": "Quality Manager, Quality Control Manager, Quality Assurance Manager, Manager of Quality Programs"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Quality Supervisor",
                    "position_descriptor": " Quality Supervisors lead quality control teams, ensuring compliance with quality procedures and industry regulations, while also supervising quality control technicians.",
                    "related_job_titles": "Quality Supervisor, Quality Control Supervisor, Quality Assurance Supervisor, Supervisor of Quality Operations"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Quality Control Analyst / QA Officer",
                    "position_descriptor": " Quality Control Analyast is an experienced QA Officer who conduct inspections, audits, and tests to ensure that products meet established quality standards, and they are responsible for documenting and reporting any deviations.",
                    "related_job_titles": "Quality Control Technician, Quality Assurance Technician, Quality Analyst, Inspection Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Quality Assurance Specialist",
                    "position_descriptor": " Quality Assurance Specialists focus on specific areas of quality control, such as product testing, process validation, or supplier quality management, ensuring high-quality outcomes.",
                    "related_job_titles": "Quality Assurance Specialist, Quality Specialist, Quality Assurance Analyst, Product Quality Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Quality Assurance Coordinator / Assistant",
                    "position_descriptor": " Quality Assurance Coordinators or Assistants support quality control operations, assisting in document control, data analysis, and maintaining quality records.",
                    "related_job_titles": "Quality Assurance Coordinator, Quality Coordinator, Assistant Quality Assurance Specialist, Quality Support Technician"
                }
            ]
        },
        "Research & Development": {
            "job_family_descriptor": "This job family encompasses professionals dedicated to innovation, technological advancement, and the creation of new products, services, or processes within manufacturing",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Research & Development Officer",
                    "position_descriptor": "The Chief Research & Development Officer is the highest-ranking executive responsible for setting the strategic vision and direction for the organization's research and development initiatives.",
                    "related_job_titles": "Chief Innovation Officer, VP of Research & Development, Chief Technology Officer."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Research & Development",
                    "position_descriptor": "Directors of Research & Development lead the R&D department, setting strategic goals and managing research projects.",
                    "related_job_titles": "Directors of Research & Development, Director of Innovation, Director of Product Development, "
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior R&D Manager",
                    "position_descriptor": "Senior R&D Managers oversee R&D teams and projects, ensuring the successful development of new products, technologies, and innovations.",
                    "related_job_titles": "Senior R&D Managers, Senior R&D Program Manager, Senior Product Development Manager, Senior Engineering Manager."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "R&D Manager",
                    "position_descriptor": "R&D Managers manage day-to-day research and development activities, including project planning, resource allocation, and collaboration with scientists and engineers.",
                    "related_job_titles": "R&D Managers, R&D Project Managers, Innovation Managers, Technology Development Managers."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "R&D Project Leader",
                    "position_descriptor": "R&D Project Leaders lead specific R&D projects, coordinating team efforts and ensuring project goals are met within established timelines and budgets.",
                    "related_job_titles": "R&D Project Leaders, Project Manager, R&D Team Lead, Lead Research Project Coordinator."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "R&D Scientist / Engineer",
                    "position_descriptor": "R&D Scientists and Engineers conduct research, experiments, and tests to contribute to the development of new products and technologies.",
                    "related_job_titles": "Research Scientist, R&D Engineer, Product Development Engineer, Research Chemist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "R&D Specialist",
                    "position_descriptor": "R&D Specialists provide specialized expertise in areas like materials, processes, or technologies, contributing to R&D team projects.",
                    "related_job_titles": "R&D Specialists, Technology Specialist, Materials Scientist, Process Development Specialist."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "R&D Coordinator / Associate",
                    "position_descriptor": "R&D Coordinators or Associates assist R&D efforts by coordinating projects, collecting data, and providing support to R&D teams.",
                    "related_job_titles": "R&D Coordinators, R&D Associates, R&D Coordinator, Research Associate, R&D officer, Research Officer."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "R&D Assistant",
                    "position_descriptor": "R&D Assistants offer administrative support to R&D teams, assisting with data entry, record-keeping, and research-related tasks.",
                    "related_job_titles": "R&D Assistants, Research Assistant, Laboratory Technician, Technical Assistant."
                }
            ]
        },
        "Facilities & Plant Management": {
            "job_family_descriptor": "This job family encompasses professionals responsible for the efficient operation, maintenance, and optimization of physical assets, facilities, and plants within various industries.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Facilities Officer",
                    "position_descriptor": "The Chief Facilities Officer is a high-ranking executive responsible for setting the strategic vision and direction for the organization's facilities and plant management. They oversee the development and execution of facility management strategies and policies.",
                    "related_job_titles": "Chief Facilities Officers, Chief Plant Officer, Head of Plant Operations"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director of Facilities & Plant Management",
                    "position_descriptor": "Directors of Facilities & Plant Management lead and manage the entire facilities department, setting the department's goals, strategies, and overseeing the operation, maintenance, and development of facilities.",
                    "related_job_titles": "Directors of Facilities & Plant Management, Director of Plant Management, Director of Facilities Operations, Director of Real Estate."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Facilities Manager",
                    "position_descriptor": "Senior Facilities Managers manage and lead the facilities management team, ensuring the efficient operation of facilities, maintenance, and compliance with safety and environmental regulations.",
                    "related_job_titles": "Senior Facilities Managers, Senior Facilities Operations Managers."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Facilities Manager",
                    "position_descriptor": "Facilities Managers are responsible for the day-to-day operations of facilities, including maintenance, space planning, and safety measures.",
                    "related_job_titles": "Facilities Managers, Property Manager, Building Services Manager, Facilities Operations Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Facility Supervisor / Plant Supervisor",
                    "position_descriptor": "Facility Supervisors oversee and supervise the activities of the facilities or plant management teams, ensuring that maintenance and operations run smoothly.",
                    "related_job_titles": "Facility Supervisors, Plant Supervisors, Facilities Operations Lead."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Facility Technician / Plant Technician",
                    "position_descriptor": "Facility Technicians are skilled professionals responsible for the maintenance and repair of facilities and plant equipment.",
                    "related_job_titles": "Facility Technicians, Plant Technicians, Maintenance Technician, Facility Engineer, Plant Maintenance Specialist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Facility Coordinator / Plant Coordinator",
                    "position_descriptor": "Facility Coordinators provide coordination and support for facility operations, assisting in maintenance, space allocation, and scheduling.",
                    "related_job_titles": "Facility Coordinators, Plant Coordinators, Facilities Coordinators, Space Coordinators, or Operations Coordinators."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Facility Associate / Plant Associate",
                    "position_descriptor": "Facility Associates support day-to-day facility operations, providing assistance in maintenance, safety, and coordination tasks.",
                    "related_job_titles": "Facility Associates, Plant Associates, Facilities Associate, Maintenance Associate, Operations Officer/Associate."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Facility Assistant / Plant Assistant",
                    "position_descriptor": "Facility Assistants assist with routine facility maintenance tasks, ensuring the cleanliness and functionality of facilities.",
                    "related_job_titles": "Facility Assistants, Plant Assistants, Facilities Assistants, Maintenance Assistants, Operations Assistants."
                }
            ]
        },
        "Printing": {
            "job_family_descriptor": "This job family encompasses roles related to the planning, operation, and management of printing processes overseeing printing equipment, maintaining quality standards, and ensuring efficient production of printed materials.",
            "levels": [
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Chief Printing Officer",
                    "position_descriptor": "The Chief Printing Officer is a top-level executive responsible for leading the entire printing production operation. They set the strategic direction, manage budgets, and ensure the highest quality standards are met. CPOs oversee production processes, large teams, and technological advancements in the field.",
                    "related_job_titles": "Chief Printing Officer"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Printing Operations",
                    "position_descriptor": "Directors of Printing Operations oversee the entire printing production department within an organization. They set departmental goals, develop and manage budgets, and ensure production processes run efficiently.",
                    "related_job_titles": "Director of Printing Operations, Printing Operations Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Production Manager",
                    "position_descriptor": "Senior Production Managers are responsible for the daily operation and management of the production team. They oversee print jobs, allocate resources, and maintain quality control.",
                    "related_job_titles": "Senior Production Manager, Senior Printing Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Production Manager",
                    "position_descriptor": "Production Managers are responsible for planning and coordinating all aspects of print production. They manage staff, monitor schedules, and ensure efficient use of resources.",
                    "related_job_titles": "Production Manager, Printing Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Print Supervisor",
                    "position_descriptor": "Print Supervisors oversee day-to-day production processes. They lead production teams, monitor output quality, and address any issues that may arise.",
                    "related_job_titles": "Print Supervisor, Printing Supervisor"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Print Technician",
                    "position_descriptor": "Print Technicians operate and maintain printing equipment. They ensure that printing jobs are executed according to specifications and perform routine maintenance.",
                    "related_job_titles": "Print Technician, Printing Technician"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Print Operator",
                    "position_descriptor": "Print Operators run printing presses and equipment, following job orders and maintaining quality standards. They may perform minor maintenance tasks on the equipment.",
                    "related_job_titles": "Print Operator, Print Machine Operator, Printing Operator"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Print Assistant",
                    "position_descriptor": "Print Assistants support production by performing tasks like loading materials, assisting with equipment setup, and helping to maintain a clean work area.",
                    "related_job_titles": "Print Assistant, Printing Assistant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Print Trainee / Apprentice",
                    "position_descriptor": "Print Trainees are individuals who are learning the basics of print production. They assist experienced operators and technicians while acquiring new skills.",
                    "related_job_titles": "Print Trainee, Printing Trainee, Print Apprentice, Printing Apprentice"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Print Laborer",
                    "position_descriptor": "Print Laborers handle general labor tasks within the printing production environment. They assist with various aspects of the production process, including material handling and cleanup.",
                    "related_job_titles": "Print Laborer, Printing Laborer, General worker"
                }
            ]
        }
    },
    "Safety Health and Environmental Services": {
        "Safety Health and Environmental Services": {
            "job_family_descriptor": "This Job family is responsible for developing, implementing, and managing safety, health, and environmental programs within an organization",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Safety Officer / Chief Environmental Officer",
                    "position_descriptor": "The Chief Safety Officer or Chief Environmental Officer oversees all safety, health, and environmental initiatives and compliance as a top executive.",
                    "related_job_titles": "Chief Safety Officer, Chief Environmental Officer, Vice President of Safety and Environmental Affairs"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Director of Safety, Health, and Environmental Services",
                    "position_descriptor": "Directors of Safety, Health, and Environmental Services lead and manage the organizations safety and environmental programs as top-level executives.",
                    "related_job_titles": "Director of Safety, Health, and Environmental Services, Director of Safety, Director of Health and Safety, Director of Environmental Services, Health, Safety, and Environmental (HSE) Director"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Safety, Health, and Environmental Services Program Manager",
                    "position_descriptor": "Safety, Health, and Environmental Services Program Managers oversee and manage large-scale programs to maintain high safety, health, and environmental standards.",
                    "related_job_titles": "Safety, Health, and Environmental Services Program Manager, Program Manager - Safety and Environmental Services, HSE Program Manager, Lead Safety Program Coordinator, Environmental Program Manager"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Safety Manager",
                    "position_descriptor": "Senior Safety Managers provide leadership in safety programs, conduct risk assessments, and ensure regulatory compliance.",
                    "related_job_titles": "Senior Safety Manager, Senior Safety Specialist, Senior Safety Coordinator, Lead Safety Manager, Safety Compliance Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Safety Manager",
                    "position_descriptor": "Safety Managers manage daily safety operations, develop policies, and ensure employee compliance.",
                    "related_job_titles": "Safety Manager, Safety Supervisor, Safety Administrator, Safety Consultant, Safety and Health Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Environmental Health and Safety (EHS) Manager",
                    "position_descriptor": "EHS Managers focus on health and environmental aspects, managing compliance and sustainability initiatives.",
                    "related_job_titles": "Environmental Health and Safety (EHS) Manager, EHS Coordinator, EHS Specialist, Health and Safety Manager, Environmental Compliance Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Safety Officer",
                    "position_descriptor": "Safety Officers are experienced professionals responsible for implementing  safety programs and ensuring compliance with regulations.",
                    "related_job_titles": "Safety Officer, Safety Specialist, Safety Coordinator, Safety Compliance Officer, SHE Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Safety Coordinator / Specialist",
                    "position_descriptor": "Safety Coordinators or Specialists support safety initiatives, conduct inspections, and assist in policy development.",
                    "related_job_titles": "Safety Coordinator, Safety Specialist, Safety Analyst, Safety Inspector, Safety Compliance Specialist, Environmental Health Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Safety Technician",
                    "position_descriptor": "Safety Technicians assist with safety inspections, audit processes, and contribute to safety training and data documentation.",
                    "related_job_titles": "Safety Technician, Safety Analyst, Safety Inspector, Safety Compliance Specialist, Environmental Health Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Safety Assistant",
                    "position_descriptor": "Safety Assistants provide administrative support to safety teams, assisting with record-keeping and safety-related tasks.",
                    "related_job_titles": "Safety Assistant, Safety Clerk, Safety Support Specialist, Safety Administrative Assistant, Environmental Health Assistant"
                }
            ]
        }
    }
}