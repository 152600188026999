import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HRPolicies from './Tabs/HRPolicies';
import CompensationPractices from './Tabs/CompensationPractices';
import Insurance from './Tabs/Insurance';
import OtherBenefits from './Tabs/OtherBenefits';
import WellBeingDEI from './Tabs/WellBeingDEI';
import ExpatriateCompensation from './Tabs/ExpatriateCompensation';
import Incentives from './Tabs/Incentives';
import { toast } from 'react-toastify';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BenefitsTabs(props) {
  const [value, setValue] = React.useState(0);
  const [dirty, setDirty] = React.useState({
    0 : false,
    1 : false,
    2 : false,
    3 : false,
    4 : false,
    5 : false,
    6 : false,
  })
  const handleChange = (event, newValue) => {
    if (dirty[value]) {
      //alert("Please save the data first by clicking on next button, otherwise your data will be lost")
      toast.error("Please save the data first by clicking on next button, otherwise your data will be lost", {
        position: toast.POSITION.TOP_CENTER
      });
      
      return
    }
    if (newValue < value) {
      setValue(newValue)
    }else {
      if (!dirty[value]) {
        setValue(newValue)
      }
    }
    //setValue(newValue);
  };

  const changeTab = (index) => {
    
    //props.onSaveClick("save-next")
    if (index ===7 ) {
      props.onNext("compensation_data")
    }else{
      setValue(index)
    }
  }

  React.useEffect(() => {
      if(props.tab){
        setValue(props.tab)
      }
  }, [props.tab])

  const tabs_data = [
    { id: 0, label: 'HR Policies and Practices' },
    { id: 1, label: 'Compensation Practices' },
    { id: 2, label: 'Incentives' },
    { id: 3, label: 'Insurance' },
    { id: 4, label: 'Other Benefits' },
    { id: 5, label: 'Well-being & DEI' },
    { id: 6, label: 'Expatriates' }
  ]



  const onChangeIsDirty = (is_dirty, index) => {
    //props.onChangeIsDirty(is_dirty, index)
    setDirty({...dirty, [index]: is_dirty})
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs_data.map((item, index) => (
            <Tab sx={{ fontSize: '14px', textTransform: 'capitalize' }} key={`tb-${index}`} label={item.label} {...a11yProps(item.id)} />
          ))
          }
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <HRPolicies changeTab={changeTab} onSaveClick={props.onSaveClick} id={0} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CompensationPractices changeTab={changeTab} onSaveClick={props.onSaveClick} id={1} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Incentives changeTab={changeTab} onSaveClick={props.onSaveClick} id={2} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Insurance changeTab={changeTab} onSaveClick={props.onSaveClick} id={3} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <OtherBenefits changeTab={changeTab} onSaveClick={props.onSaveClick} id={4} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <WellBeingDEI changeTab={changeTab} onSaveClick={props.onSaveClick} id={5} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <ExpatriateCompensation changeTab={changeTab} onSaveClick={props.onSaveClick} id={6} onChangeIsDirty={onChangeIsDirty} />
      </CustomTabPanel>
    </Box>
  );
}
