import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { YES_NO } from "../../../../../../configs/data/onboarding"
import { CheckboxInput } from '../../../../../../components/forms/CheckboxInput';



const WELL_BEING_INITITIATES_DATA = [
    {
        label: "Flexible Work Arrangements: Providing options for remote work, flexible hours, or compressed workweeks to accommodate employees' needs.",
        value: "Flexible Work Arrangements"
    },
    {
        label: "Health and Wellness Programs: Offering fitness classes, nutrition workshops, and wellness challenges to promote physical health.",
        value: "Health and Wellness Programs"
    },
    {
        label: "Mental Health Support: Providing access to counseling services, mental health resources, and stress management workshops.",
        value: "Mental Health Support"
    },
    {
        label: "Employee Assistance Programs (EAPs): Offering confidential counseling and support services for various personal and professional challenges.",
        value: "Employee Assistance Programs (EAPs)"
    },
    {
        label: "Work-Life Balance Support: Encouraging employees to maintain a healthy balance between work and personal life.",
        value: "Work-Life Balance Support"
    },
    {
        label: "Well-being Paid Time Off (PTO): Additional personal days for employees to rest and recharge.",
        value: "Well-being Paid Time Off (PTO)"
    },
    {
        label: "Remote Work Support: Offering technology and resources to support remote work, including ergonomic equipment and connectivity solutions.",
        value: "Remote Work Support"
    },
    {
        label: "Financial Wellness Programs: Providing financial education, planning, and resources to help employees manage their finances effectively.",
        value: "Financial Wellness Programs"
    },
    {
        label: "Stress Reduction Initiatives: Introducing mindfulness meditation sessions, relaxation spaces, and stress-relief activities.",
        value: "Stress Reduction Initiatives"
    },
    {
        label: "Health Screenings: Organizing on-site health screenings for conditions like blood pressure, cholesterol, and diabetes.",
        value: "Health Screenings"
    },
    {
        label: "Fitness Subsidies: Offering reimbursements or subsidies for gym memberships, fitness equipment, or wellness-related expenses.",
        value: "Fitness Subsidies"
    },
    {
        label: "Healthy Snack Options: Stocking the workplace with nutritious snacks and beverages to support healthy eating habits.",
        value: "Healthy Snack Options"
    },
    {
        label: "Social Connection Activities: Organizing team-building events, social gatherings, and volunteer opportunities to foster a sense of community.",
        value: "Social Connection Activities"
    },
    {
        label: "Professional Development Opportunities: Providing access to skill-building workshops, training, and career advancement programs.",
        value: "Professional Development Opportunities"
    },
    {
        label: "Diversity and Inclusion Initiatives: Promoting a diverse and inclusive workplace that values all employees' perspectives and backgrounds.",
        value: "Diversity and Inclusion Initiatives"
    },
    {
        label: "On-Site childcare resources",
        value: "On-Site childcare resources"
    },
    {
        label: "Lactation room for mothers",
        value: "Lactation room for mothers"
    },
    {
        label: "On-Site Wellness Facilities: Providing on-site fitness centers, meditation rooms, or relaxation areas.",
        value: "On-Site Wellness Facilities"
    },
    {
        label: "Employee Resource Groups: Voluntary, employee-led group that fosters inclusivity and builds community",
        value: "Employee Resource Groups"
    },
]

export default function WBInititiates(props) {
    const id = 'initiatives'
    const { watch, errors, control, register } = props
    return (
        <div>



            <div className='my-2'>
                <Label className="mb-2" htmlFor="wellbeing_inititiates"
                    text="Does your organisation offer well-being initiatives?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>
            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    <table className="w-full border">
                        <tbody>
                            {
                                WELL_BEING_INITITIATES_DATA.map((item, index) => {
                                    return (<tr key={`medical_cover-eligibility-${index}`}>
                                        <td className="px-2 border-b border-r">{item.label}</td>
                                        <td className="px-2 border-b border-r" >
                                            <CheckboxInput control={control} errors={errors} name={`${id}.data.${item.value}`} />
                                        </td>



                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }


        </div>
    )
}