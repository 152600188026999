import CompensationManagement from "./CompensationManagement";
import ExpariatesPackages from "./ExpariatesPackages";
import ExpatriatesContract from "./ExpatriatesContract";
import ExpatriatesCurrency from "./ExpatriatesCurrency";
import HiringExpatriates from "./HiringExpatriates";

export default function BenefitExpatriates(props) {
  console.log(props.data);
  const {
    compensation_management,
    expatriates_pay_currency,
    offering,
    training_needs,
    contract
  } = props.data;
  console.log(expatriates_pay_currency)
  return (
    <div>
      <HiringExpatriates data={offering} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <ExpatriatesCurrency data={expatriates_pay_currency} />
        <CompensationManagement data={compensation_management} />
      </div>
      <ExpariatesPackages data={training_needs} />
      <ExpatriatesContract data={contract} />
    </div>
  );
}
