import React from "react";
import Chart from "react-apexcharts";
import { WR_GRAPH_COLORS } from "../../../configs/colors";
import NoData from "./NoData";

const get_height = (height, data) => {
  if (height === "auto") {
    if (data?.length == 1) {
      return 100
    }else if (data?.length == 2) {
      return 130
    }else if (data?.length == 3) {
      return 130;
    }
    return data?.length * 35;
    // return data.length * 70;
  } else {
    return height;
  }
};

const BarChart = (props) => {
  if (!props) {
    return <NoData />
  }
  if (!props.categories) {
    return <NoData />
  }
  if (!props.series) {
    return <NoData />
  }


  const options = {
    chart: {
      type: "bar",
      stacked: props.stacked || false,
      height: get_height(props.height, props.categories),
      toolbar: {
        show: false,
      },
      // sparkline: {
      //   enabled: true,
      // },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top", // position the labels at the top (end of the bar)
        },
      },
    },
    dataLabels: {
      enabled: true, // enable data labels
      offsetX: props.stacked ? -10 : 30, // adjust this value as needed
      style: {
        fontSize: "12px",
        colors: ["#111111"],
      },
      formatter: function (val) {
        return props.percentage ? val + "%" : val; // Append a percent sign to each label
      },
    },
    xaxis: {
      categories: props.categories || [
        "Retail & Wholesale",
        "Insurance/Reinsurance",
        "Other Non-Manufacturing",
        "Banking/Financial Services",
        "Logistics",
        "High Tech",
        "Chemicals",
        "Services (Non-Financial)",
        "Other Manufacturing",
        "Life Sciences",
        "Energy",
        "Consumer Goods",
      ],
      labels: {
        show: false,
      },
      axisBorder: {
        show: false, // hide x-axis line
      },
      axisTicks: {
        show: false, // hide x-axis ticks
      },
      show: false,
    },
    yaxis: {
      show: true, // hide the y-axis
      axisTicks: {
        show: true, // hide x-axis ticks
      },
    },

    colors: WR_GRAPH_COLORS,
    grid: {
      show: false, // already set to hide grid
    },
  };

  const series = props.series || [
    {
      name: "Percentage",
      data: [0.8, 1.6, 3.2, 3.2, 7.1, 7.1, 7.1, 9.5, 11.1, 11.1, 15.1, 23.0],
    },
  ];

  // Render the chart component
  return (
    <div
      id="chart"
      // className=" bg-green-400"
    >
      <Chart
        options={options}
        series={series}
        type="bar"
        height={get_height(props.height, props.categories) || 350}
        // height={"15px"}
      />
    </div>
  );
};

export default BarChart;
