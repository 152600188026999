import React, { useState, useEffect } from "react";
import DashboardLayout from "../../components/layouts/Dashboard";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BenefitOverview from "./Tabs/Overview";
import BenefitHRPolicies from "./Tabs/HRPolicies";
import BenefitCompensationPractices from "./Tabs/CompensationPractices";
import BenefitIncentives from "./Tabs/Incentives";
import BenefitInsurance from "./Tabs/Insurance";
import BenefitOtherBenefits from "./Tabs/OtherBenefits";
import BenefitWellBeingDEI from "./Tabs/WellBeingDEI";
import BenefitExpatriates from "./Tabs/Expatriates";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function TabMain(props) {
  const [value, setValue] = useState("overview");
  const { data } = props;
  //   console.log("data in tab main = ", data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs_data = [
    { id: "overview", label: "Overview" },
    { id: "hr_policies", label: "HR Policies and Practices" },
    { id: "compensation_practices", label: "Compensation Practices" },
    { id: "incentives", label: "Incentives" },
    { id: "insurance", label: "Insurance" },
    { id: "other_benefits", label: "Other Benefits" },
    { id: "well_being_dei", label: "Well-being & DEI" },
    { id: "expatriates", label: "Expatriates" },
  ];

  return (
    <Box sx={{ width: "100%" }} className="px-4 mt-2">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="benefits tab output"
        >
          {tabs_data.map((item, index) => (
            <Tab
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
              key={`tb-${index}`}
              label={item.label}
              value={item.id}
            />
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={`overview`}>
        <BenefitOverview data={data.company_overview} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"hr_policies"}>
        <BenefitHRPolicies data={data.hr_policies} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"compensation_practices"}>
        <BenefitCompensationPractices data={data.compensation_practices} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"incentives"}>
        <BenefitIncentives data={data.incentives} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"insurance"}>
        <BenefitInsurance data={data.insurance} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"other_benefits"}>
        <BenefitOtherBenefits data={data.other_benefits} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"well_being_dei"}>
        <BenefitWellBeingDEI data={data.well_being} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={"expatriates"}>
        <BenefitExpatriates data={data.expatriates} />
      </CustomTabPanel>
    </Box>
  );
}
