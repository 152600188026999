import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Checkbox } from '@mui/material';
import instance from '../../../../auth/useJwt';
import Backdrop from '@mui/material';

function OnBoardStatusChange({ row, onSuccess }) {
    const [open, setOpen] = React.useState(false);
    const [apiReq, setApiReq] = React.useState({
        loading: false,
        error: null,
        success: false,
    });
    const [checkboxState, setCheckboxState] = React.useState(row.onboarding_approved); // State to manage checkbox

    const onChangeOnboardApproval = (id, value) => {
        setApiReq({
            loading: true,
            error: null,
            success: false,
        });
        instance.post('/admin/users/onboard-approval', { id, value }).then(res => {
            onSuccess();
            setOpen(false)
            setApiReq({
                loading: false,
                error: null,
                success: true,
            });
        }).catch(err => {
            setOpen(false)
            setApiReq({
                loading: false,
                error: err,
                success: false,
            });
            console.log(err);
        });
    };

    const title = 'Onboard Status Change';
    const message = 'Are you sure you want to change the onboard status of this user?';

    const handleOnboardedChange = () => {
        setOpen(true);
    };

    const handleClose = (answer) => {
        if (answer === 'yes') {
            onChangeOnboardApproval(row.id, !row.onboarding_approved);
            setCheckboxState(!row.onboarding_approved); // Update checkbox state on confirm
        } else {
            setOpen(false)
            setCheckboxState(row.onboarding_approved); // Reset checkbox state on cancel
        }
    };

    return (
        <>
            {/* <Checkbox checked={checkboxState} onChange={handleOnboardedChange} /> */}
            <input type="checkbox" checked={checkboxState} onChange={handleOnboardedChange} />
            <Dialog
                open={open}
                //onClose={() => handleClose('no')}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={apiReq.loading}
                        onClick={() => handleClose('no')} color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={apiReq.loading}
                        onClick={() => handleClose('yes')} color="primary" autoFocus>
                        {apiReq.loading ? 'Processing...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default OnBoardStatusChange;