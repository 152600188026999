import CommissionSchemes from "./CommissionSchemes";
import LongTermIncentives from "./LongTermIncentives";
import ProfitSharing from "./ProfitSharing";
import ShortTermIncentives from "./ShortTermIncentives";

export default function BenefitIncentives(props) {
  console.log(props.data);
  const {
    short_term_incentives,
    long_term_incentives,
    commission_schemes,
    profit_sharing,
  } = props.data;
  return (
    <div>
      <ShortTermIncentives data={short_term_incentives} />
      <CommissionSchemes data={commission_schemes} />
      <LongTermIncentives data={long_term_incentives} />
      <ProfitSharing data={profit_sharing} />
    </div>
  );
}
