import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CompensationFieldsData from "../../../../configs/data/CompensationFieldsData"
import { toast } from 'react-toastify';
import { setAppData } from "../../../../redux/app_data";
import { setCompensationInfoData } from "../../../../redux/onboard/compensation_info";
import InputTableC from "./InputTableC";


import { onBoardingReadOnly } from "../../../../redux/app_data";

export default function CompensationData(props) {
    const { demography } = useSelector(state => state.company_info).data
    const compensation_data_redux = useSelector(state => state.compensation_info)
    const [availableJF, setAvailableJF] = useState([])

    const sector = demography.sector
    const dispatch = useDispatch()

    const [fields, setFields] = useState([

    ])

    
    const isReadOnly = useSelector(onBoardingReadOnly);
    


    const sector_data = CompensationFieldsData[demography.industry]
    
    const industry_data = sector_data[demography.sector]
    
    const get_df = () => {
        var data_f = []
        const REPORTING_STRUCTURE = {label: "National Recruited Staff - NRS", value: "National Recruited Staff - NRS"}
        Object.entries(CompensationFieldsData.COMMON_JF.All).map((value, key) => {
            data_f.push({
                sb_index: 0,
                job_family: value[0], sector: "All", points: value[1], survey_position: null, wr_job_level: null, position_overview: null,
                match_job_title: null, reporting_structure: null, contracting: REPORTING_STRUCTURE, num_staff: null
            })
        })
        Object.entries(industry_data).map((value, key) => {
            data_f.push({
                sb_index: 0,
                job_family: value[0], sector: demography.industry, points: value[1], survey_position: null, wr_job_level: null, position_overview: null,
                match_job_title: null, reporting_structure: null, contracting: REPORTING_STRUCTURE, num_staff: null
            })
        })
        return data_f
    }

    const get_dfAll = () => {
        var data_f = []
        const REPORTING_STRUCTURE = {label: "National Recruited Staff - NRS", value: "National Recruited Staff - NRS"}
        console.log(CompensationFieldsData.COMMON_JF_ALL)
        Object.entries(CompensationFieldsData.COMMON_JF_ALL).map((value, key) => {
            data_f.push({
                sb_index: 0,
                job_family: value[0], sector: "All", points: value[1], survey_position: null, wr_job_level: null, position_overview: null,
                match_job_title: null, reporting_structure: null, contracting: REPORTING_STRUCTURE, num_staff: null
            })
        })
        Object.entries(industry_data).map((value, key) => {
            data_f.push({
                sb_index: 0,
                job_family: value[0], sector: demography.industry, points: value[1], survey_position: null, wr_job_level: null, position_overview: null,
                match_job_title: null, reporting_structure: null, contracting: REPORTING_STRUCTURE, num_staff: null
            })
        })
        return data_f
    }

    useEffect(() => {
        if (compensation_data_redux?.data?.jobRows?.length > 0) {
            // iterate on each and assign job level as "wr_job_level"




            setFields(compensation_data_redux.data.jobRows)
        }else{
        
        var data_f = get_df()
        setFields(data_f)
       
    }
    setAvailableJF(get_dfAll())
    }, [industry_data])


    useEffect(() => {
        dispatch(setAppData({
            key: 'sidebar_open',
            value: false
        }))
    }, [])

    const onSubmit = () => {

        if (isReadOnly) {
            props.onNext("submission")
            return
        }

        // For all rows check if any of these fields are empty
        const validation_fields = ["survey_position", "related_job_title", "contracting", "reporting_structure", "staff_number"]

        var error = false
        fields.map((value, key) => {
            validation_fields.map((v, k) => {
                if (!value[v]) {
                    error = true
                }
            })
        })

        if (error) {
            toast.success('Please fill all fields carefully', {
                position: "top-center",
                type: 'error',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            return
        }


        const form_data = {
            jobRows: fields
        }
       dispatch(setCompensationInfoData({
              data: form_data, rows: fields
       }))
        props.onSaveClick("compensation_data", null, {
            data: form_data, rows: fields
       })
       
       props.onNext("submission")
    }

    if (!sector) {
        return <div></div>
    }

    return (
        <div>
            {/* <InputTestTable /> */}
           
                {/* <InputTable fields={fields} setFields={setFields} register={register} watch={watch} reset={reset} control={control} errors={errors} /> */}


                <InputTableC fieldsR={fields} availableJF={availableJF} setFieldsR={setFields}  />
                

                <div className="flex justify-end mt-5 px-2 py-2">
                <button
                onClick={onSubmit}
                className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
            
        </div>
    )
}