// ** React Imports
import { Navigate, Outlet } from "react-router-dom"
import { isUserLoggedIn } from "../auth/utils"
import { useSelector } from "react-redux"

const AdminRoute = () => {

  const user = useSelector(state => state.authentication)
const userLoggedIn = isUserLoggedIn()
  return (
    // userLoggedIn ? <Outlet /> : <Navigate to='/login' />
    user.role === "admin" ? <Outlet /> : <Navigate to='/' />
  )
}
export default AdminRoute