export const COMMON_JF = {
    "All": {
        "General Management ": {
            "job_family_descriptor": "The General Management job family may consist of multiple roles within the organization, taking a generalized approach to management that may include leadership of two or more areas. Job holders provide oversight and strategies for business development and may manage and develop the implementation of general operating policies.",
            "levels": [
                {
                    "wr_job_level": "WR 14",
                    "survey_position": "Executive I",
                    "position_descriptor": " The highest-ranking executive, responsible for setting the organization's strategic direction and ensuring overall company success.",
                    "related_job_titles": "Chief Executive Officer, President, Managing Director, CEO & President, Chief Executive"
                },
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Executive II",
                    "position_descriptor": " Top-level executives with significant authority, overseeing major divisions or functions and contributing to strategic decision-making.",
                    "related_job_titles": "CEO, MD, Executive Vice President, Deputy CEO, Senior Vice President "
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Executive III",
                    "position_descriptor": "Position hold high-level executive positions, managing key areas of the business, and contributing to organizational strategy.",
                    "related_job_titles": "Chief Operating Officer (COO), Chief Strategy Officer (CSO), Senior Vice President, Vice President, General Manager"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director I",
                    "position_descriptor": "Leads divisions/directorates which have specific departments, setting divisional goals and strategies, and managing teams within their areas of responsibility.",
                    "related_job_titles": "Senior Director,Vice President,  VP of Operations/manufacturing"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director II",
                    "position_descriptor": " Directors manage directorates or functional areas, developing and executing strategies while often overseeing budget management.",
                    "related_job_titles": "Director, Director of, Division Manager, Director of Finance/HR/Sales"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Manager",
                    "position_descriptor": " Senior Managers oversee teams or areas within departments, ensuring alignment with departmental goals and contributing to performance improvement.",
                    "related_job_titles": "Senior Manager, Senior Department Manager, Senior Project Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Manager",
                    "position_descriptor": " Managers are at the mid level management and lead teams or departments with operational responsibilities, setting goals and driving performance.",
                    "related_job_titles": "Manager, Department Manager, Project Manager, Operations Manager, Sales Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Section Heads",
                    "position_descriptor": " Supervisors oversee small teams, ensuring efficient task completion and providing guidance and support to team members.",
                    "related_job_titles": "Section lead, Supervisor, Department Supervisor, Team Supervisor, Frontline Supervisor, Shift Supervisor (Profesionals)"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Team Leader / Unit Heads",
                    "position_descriptor": " Team Leaders coordinate and lead teams, assign tasks, and ensure goal achievement within workgroups.",
                    "related_job_titles": "Unit Lead, Team Leader, Group Leader, Lead Supervisor, Team Coordinator"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Operational / Process Supervisor",
                    "position_descriptor": "Supervisors responsible for overseeing the day-to-day activities within their operational areas, ensuring that processes are running efficiently and that teams are meeting their performance goals. ",
                    "related_job_titles": "Team Leader, Shift Supervisor, Production Supervisor, Manufacturing Supervisor, Logistics Supervisor, Quality Control Supervisor, Warehouse Supervisor, Facilities Supervisor"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Management Trainee ",
                    "position_descriptor": " These are Entry-level management trainees or assistants provide support to teams and managers, gaining experience and learning management processes.",
                    "related_job_titles": "Management Trainee, Management Assistant"
                }
            ]
        },
        "Human Resource": {
            "job_family_descriptor": "The Human Resources job family is responsible for the administration and management of all aspects of the human resources function within an organization. This includes employee recruitment and selection, employee benefits and compensation, employee relations and communications, and employee training and development.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                    "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                    "related_job_titles": " HR Leadership and Management Executive, HR Strategy Leadership, Chief HR Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director / Vice President of Human Resources",
                    "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                    "related_job_titles": " VP of HR Leadership, Vice President of HR Management, HR Leadership VP"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Human Resources",
                    "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                    "related_job_titles": " Director of HR Leadership, HR Management Director, HR Management Lead"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior HR Manager",
                    "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                    "related_job_titles": " Senior HR Manager, Senior HR Leadership Manager, Lead HR Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "HR Manager",
                    "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                    "related_job_titles": " HR Manager, HR Leadership Manager, HR Management Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "HR Business Partner / Specialist",
                    "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                    "related_job_titles": " HR Leadership Specialist, HR Management Specialist, HR Leadership Business Partner"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "HR Coordinator / Generalist",
                    "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                    "related_job_titles": " HR Leadership Coordinator, HR Management Coordinator, HR Leadership Generalist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "HR Associate / Officer",
                    "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                    "related_job_titles": " HR Leadership Associate, HR Management Associate, HR Leadership Support Associate"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "HR Assistant ",
                    "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                    "related_job_titles": " HR Leadership Assistant, HR Management Assistant,"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "HR Admin Clerks",
                    "position_descriptor": "Not full qualified HR Assistants who provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                    "related_job_titles": "HR Clerk, Interns"
                }
            ]
        },
        "Finance ": {
            "job_family_descriptor": "The Finance job family manages the financial activities of an organization or business via financial planning, accounting, collections/credits, financial analyses, audits, and tax information. This general financial operation family encompasses roles related to the day-to-day financial activities of an organization, such as accounts payable, accounts receivable, payroll, and financial reporting.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Financial Officer (CFO)",
                    "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                    "related_job_titles": " Financial Operations Executive, Strategic Operations Officer, Chief Financial Operations Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director / Vice President of Finance",
                    "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                    "related_job_titles": " VP of Financial Operations, Vice President of Operations, Financial Operations VP"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Finance",
                    "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                    "related_job_titles": " Director of Financial Operations, Operations Director, Finance Operations Lead"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Finance Manager",
                    "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                    "related_job_titles": " Senior Operations Manager, Senior Financial Operations Analyst, Lead Financial Operations Analyst"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Finance Manager",
                    "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                    "related_job_titles": " Operations Manager, Financial Operations Analyst, Financial Operations Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Finance Business Partner / Specialist",
                    "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                    "related_job_titles": " Financial Operations Specialist, Operations Business Partner, Strategic Operations Analyst"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Finance Analyst / Coordinator",
                    "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                    "related_job_titles": " Financial Operations Analyst, Operations Coordinator, Budget Operations Analyst"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Financial Officer/ Associate",
                    "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                    "related_job_titles": " Financial Operations Associate, Operations Analyst Associate, Junior Operations Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Finance Assistant",
                    "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                    "related_job_titles": " Financial Operations Intern, Operations Analyst Assistant, Junior Financial Operations Assistant"
                }
            ]
        },
        "Customer Service and Support (General)": {
            "job_family_descriptor": "The Customer Service job family is responsible for providing customer service to internal and external customers. This includes responding to customer inquiries, resolving customer complaints, and providing information about products and services.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Customer Experience Officer",
                    "position_descriptor": " At the highest level, the Chief Customer Experience Officer is responsible for developing and executing the organization's customer service and support strategy. They lead and oversee all customer service functions, ensuring exceptional customer experiences, satisfaction, and loyalty. The CCXO collaborates with senior leadership to align customer service initiatives with business objectives. They also analyze customer feedback, identify areas for improvement, and drive the organization's customer-centric culture.",
                    "related_job_titles": " Chief Customer Satisfaction Officer, Customer Experience Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director / Vice President of Customer Service",
                    "position_descriptor": " Vice Presidents of Customer Service lead a substantial portion of the customer service and support function. They design and implement customer service strategies, policies, and standards. This role involves managing multiple customer service teams, monitoring customer satisfaction metrics, and overseeing the resolution of complex customer issues. The Vice President of Customer Service collaborates with other departments to enhance the overall customer experience.",
                    "related_job_titles": " VP of Client Services, Vice President of Customer Support, Senior Director Customer Care, Vice President of Support Services"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Customer Support",
                    "position_descriptor": " Directors of Customer Support manage specific areas within the customer service function, such as technical support, helpdesk, or customer care. They lead teams responsible for providing timely and effective solutions to customer inquiries and issues. Directors of Customer Support collaborate with cross-functional teams to ensure alignment with business goals, implement customer service best practices, and drive continuous improvement.",
                    "related_job_titles": "Customer Support Director, Director of Client Services, Client Services Director, Director of Customer Support"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Customer Service Manager",
                    "position_descriptor": " Senior Customer Service Managers oversee critical customer service activities and teams. They monitor customer satisfaction metrics, analyze customer feedback, and identify areas for improvement. Senior Customer Service Managers develop and implement customer service strategies, manage customer service representatives, and ensure consistent delivery of high-quality customer support.",
                    "related_job_titles": " Senior Client Services Manager, Senior Customer Support Manager, Senior Manager of Customer Support, Senior Manager of Client Services"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Customer Service Manager",
                    "position_descriptor": " Customer Service Managers manage day-to-day customer service operations. They lead customer service teams, handle escalations, and monitor performance metrics. Customer Service Managers ensure that customer inquiries are resolved promptly and effectively, collaborate with other departments, and contribute to the development of customer service policies and procedures.",
                    "related_job_titles": " Client Services Manager, Customer Support Manager, Manager of Customer Support, Manager of Client Services"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Customer Service Team Lead / Supervisor",
                    "position_descriptor": " Customer Service Team Leads or Supervisors provide direct leadership to customer service representatives. They assist in resolving complex customer inquiries, provide coaching and training, and monitor team performance. Customer Service Team Leads/Supervisors play a key role in ensuring a positive customer experience and facilitating communication between their team and management.",
                    "related_job_titles": " Client Services Team Lead, Customer Support Supervisor, Supervisor of Client Services, Customer Service Team Supervisor"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Customer Service Representative / Specialist",
                    "position_descriptor": " Customer Service Representatives or Specialists directly interact with customers to address inquiries, resolve issues, and provide support. They maintain accurate records of customer interactions, offer product or service information, and contribute to maintaining a high level of customer satisfaction. Customer Service Representatives/Specialists handle a variety of customer-related tasks.",
                    "related_job_titles": " Client Services Representative, Customer Support Specialist, Specialist in Customer Support, Customer Experience Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Customer Service Coordinator / Associate",
                    "position_descriptor": " Customer Service Coordinators or Associates provide administrative and operational support to customer service teams. They assist in organizing customer inquiries, maintain customer-related records, and ensure smooth communication between customers and representatives. Customer Service Coordinators/Associates play a role in optimizing customer service processes.",
                    "related_job_titles": " Client Services Coordinator, Customer Support Associate, Associate in Customer Support, Customer Experience Associate"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Customer Service Assistant I",
                    "position_descriptor": "At this level, the incumbent is experienced at this role and tasks involves handling complex customer inquiries, resolving escalated issues, and providing guidance to lower-level assistants.",
                    "related_job_titles": "Customer Support Assistant, Customer Care Assistant, Customer Experience Assistant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Customer Service Assistant II",
                    "position_descriptor": "At this Level,  this is an intermediate-level position with responsibilities such as addressing moderately complex customer queries, processing orders, and providing product/service information.",
                    "related_job_titles": "Customer Support Assistant, Assistant in Customer Support, Customer Care Repressentative"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Customer Service Assistant III",
                    "position_descriptor": "An entry-level position focused on basic customer service tasks often working under close supervision",
                    "related_job_titles": " Client Services Intern, Customer Support Assistant"
                }
            ]
        },
        "Public Relations": {
            "job_family_descriptor": "",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Communications Officer / Head of Public Relations",
                    "position_descriptor": " As the top executive in the Public Relations field, the CCO or Head of PR leads the organization's entire communication strategy and oversee all communication activities, manage communication teams, and play a crucial role in shaping the company's public image and reputation.",
                    "related_job_titles": "Chief Communications Officer, Head of Public Relations, Chief PR Strategist, Chief Brand Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Communications Director / PR Director",
                    "position_descriptor": " Directors are senior leaders responsible for developing and implementing communication strategies and drive PR campaigns, supervise teams, and serve as the primary spokesperson for the organization.",
                    "related_job_titles": "Director of Corporate Communications, Communications Director, PR Director, Director of Public Affairs, Director of Media Relations, Corporate Communications Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Public Relations Manager / Communications Manager",
                    "position_descriptor": " Senior PR Managers or Communications Managers oversee the planning and execution of PR campaigns and manage communication staff, develop media relations, and handle crisis communication.",
                    "related_job_titles": "Senior Public Relations Manager, Communications Manager, PR and Media Relations Manager, Public Relations Program Manager, Senior Corporate Communications Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Public Relations Manager / Communications Specialist",
                    "position_descriptor": " PR Managers or Communications Specialists manage day-to-day PR activities and develop and maintain media relationships, create PR materials, and assist in campaign execution.",
                    "related_job_titles": "Public Relations Manager, Communications Specialist, Media Relations Manager, Corporate Communications Specialist, PR Campaign Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "PR Coordinator / Communications Coordinator",
                    "position_descriptor": " Coordinators supervise PR efforts and coordinate communication activities and perform media monitoring, event planning, and content creation.",
                    "related_job_titles": "PR Coordinator, Communications Coordinator, Media Coordinator, Public Affairs Coordinator, Corporate Communications Coordinator"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Public Relations Specialist / Communications Associate",
                    "position_descriptor": " These are mid-level professionals specializing in various aspects of public relations, such as media relations, social media management, or internal communication, and they take on more independent responsibilities within the PR team.",
                    "related_job_titles": "Public Relations Specialist, Communications Associate, Media Relations Specialist, Corporate Communications Associate, PR Campaign Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Public Relations Officer / Communications Officer",
                    "position_descriptor": " Officers focus on specific PR functions and specialize in areas like media outreach, social media, or internal communications.",
                    "related_job_titles": "Public Relations Officer, Media Relations Officer, PR Campaign Officer, Communication Officer, Media Officer, Corporate Communications Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "PR Assistant / Communications Assistant",
                    "position_descriptor": " PR Assistants or Communications Assistants provide entry-level support in PR and communication efforts and handle administrative tasks, track media coverage, and assist with research.",
                    "related_job_titles": "PR Assistant, Communications Assistant, Media Assistant, Public Affairs Assistant, Corporate Communications Assistant"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Public Relations Intern / Communications Intern",
                    "position_descriptor": " PR Interns or Communications Interns are typically in entry-level, short-term roles to gain practical experience by assisting PR professionals, performing research, and contributing to PR projects.",
                    "related_job_titles": "Public Relations Intern, Communications Intern, Media Intern, PR and Communications Intern, Corporate Communications Intern"
                }
            ]
        },
        "Legal and Compliance (General)": {
            "job_family_descriptor": "Legal and compliance job family roles focus on ensuring that the organisation adheres to laws, regulations, and industry standards. Legal professionals handle contracts, regulatory compliance, and legal matters.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Legal and Compliance Officer",
                    "position_descriptor": " At the highest level, the Chief Legal and Compliance Officer (CLCO) holds ultimate responsibility for the organization's legal and compliance functions. They provide strategic leadership, overseeing legal affairs and ensuring compliance with laws, regulations, and industry standards. The CLCO advises senior leadership on legal matters, manages legal teams, and develops risk management strategies. They also monitor changes in laws and regulations to ensure the organization's practices remain compliant.",
                    "related_job_titles": " Legal Compliance Executive, Chief Compliance Strategist, Head of Legal and Compliance"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Seniore Director / Vice President of Legal Affairs",
                    "position_descriptor": " Vice Presidents of Legal Affairs lead a substantial portion of the legal department. They provide legal counsel to the organization, manage legal teams, and oversee contract negotiations. This role involves addressing complex legal issues, conducting risk assessments, and ensuring compliance with laws and regulations. The Vice President of Legal Affairs collaborates with senior leaders to align legal strategies with business goals.",
                    "related_job_titles": " VP of Legal Services, Vice President of Legal Advisory, Legal Affairs VP, VP of Legal Compliance, Vice President of Legal Operations, Compliance VP."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Legal Services",
                    "position_descriptor": " Directors of Legal Services manage specific areas within the legal department, such as contracts, litigation, or intellectual property. They lead legal teams, provide legal guidance, and manage legal projects. Directors of Legal Services collaborate with other departments to identify legal risks and provide proactive legal solutions. They also ensure the organization's practices align with legal and regulatory requirements.",
                    "related_job_titles": " Legal Services Director, Director of Legal Affairs, Legal Advisory Director, Director of Legal Compliance, Legal Compliance Lead, Legal Operations Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Legal Counsel",
                    "position_descriptor": " Senior Legal Counsels handle critical legal matters and provide expert legal advice. They conduct legal research, draft contracts and agreements, and represent the organization in legal negotiations. Senior Legal Counsels analyze legal risks, provide recommendations, and help develop legal strategies. They may also assist in managing external legal resources and supervise junior legal staff.",
                    "related_job_titles": " Senior Counsel, Lead Legal Advisor, Senior Legal Consultant, Principal Legal Counsel, Senior Legal Advisor, Lead Regulatory Counsel."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Departmental Manager, Legal & Compliance",
                    "position_descriptor": " Legal Counsels handle a variety of legal tasks, including contract drafting and reviewing, legal research, and providing legal opinions. They work on legal agreements, assist in negotiations, and help ensure legal compliance. Legal Counsels may also advise on legal issues related to specific projects or initiatives and collaborate with other teams to address legal concerns.  Compliance Managers oversee the organization's compliance with laws, regulations, and industry standards. They develop compliance programs, conduct audits, and implement policies and procedures. Compliance Managers monitor regulatory changes, assess potential risks, and train employees on compliance matters. They work to ensure that the organization operates ethically and within legal boundaries.",
                    "related_job_titles": "Departmental  Manager,  In-House Counsel, Corporate Legal Advisor, Legal Consultant, Business Legal Advisor, Legal Analyst, Corporate Compliance Counsel. Regulatory Compliance Manager, Compliance Officer, Compliance Strategy Manager, Regulatory Affairs Manager, Regulatory Compliance Strategist."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Section Manager, Legal & Compliance",
                    "position_descriptor": " Compliance Managers oversee the organization's compliance with laws, regulations, and industry standards. They develop compliance programs, conduct audits, and implement policies and procedures. Compliance Managers monitor regulatory changes, assess potential risks, and train employees on compliance matters. They work to ensure that the organization operates ethically and within legal boundaries.",
                    "related_job_titles": "Section Head, Section Manager, In-House Counsel, Corporate Legal Advisor, Legal Consultant, Business Legal Advisor, Legal Analyst, Corporate Compliance Counsel. Regulatory Compliance Manager, Compliance Officer, Compliance Strategy Manager, Regulatory Affairs Manager, Regulatory Compliance Strategist."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Compliance Specialist / Analyst",
                    "position_descriptor": " Compliance Specialists or Analysts support the compliance efforts of the organization. They assist in developing compliance programs, conducting risk assessments, and monitoring compliance with regulations. Compliance Specialists/Analysts help maintain compliance documentation, analyze compliance data, and contribute to the development of compliance training materials.",
                    "related_job_titles": " Regulatory Compliance Specialist, Compliance Analyst, Compliance Solutions Specialist, Regulatory Analyst, Compliance Reporting Analyst."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Compliance Coordinator / Associate",
                    "position_descriptor": " Compliance Coordinators or Associates provide administrative and operational support to the compliance function. They assist in organizing compliance activities, maintaining compliance records, and ensuring proper documentation. Compliance Coordinators/Associates contribute to compliance reporting and help ensure that compliance processes are followed.",
                    "related_job_titles": " Regulatory Compliance Coordinator, Compliance Associate, Compliance Support Coordinator, Regulatory Associate, Regulatory Compliance Associate."
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Legal and Compliance Assistant",
                    "position_descriptor": " Entry-level positions within the legal and compliance function are typically filled by interns or assistants. These individuals support various legal and compliance tasks, including research, documentation, and administrative duties. Entry-Level Legal and Compliance Interns/Assistants gain exposure to legal and compliance practices while working under the guidance of experienced professionals.",
                    "related_job_titles": " Legal Intern, Compliance Assistant, Legal Support Intern, Compliance Assistant, Legal Compliance Intern, Assistant Compliance Analyst."
                }
            ]
        },
        "Supply Chain": {
            "job_family_descriptor": "The Supply Chain Management job family consists of positions that coordinate and manage all activities related to the procurement of goods and services, the distribution of finished products, and the disposal of finished products. In addition, job holders are responsible for the management of the relationships between the various stakeholders involved in the supply chain, including suppliers, manufacturers, distributors, retailers, and customers.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Supply Chain Officer ",
                    "position_descriptor": "The Chief  holds the ultimate responsibility for the organization's end-to-end supply chain operations. They provide strategic leadership, overseeing procurement, logistics, inventory management, and distribution. The CSCO collaborates with executive leadership to align supply chain strategies with business goals, optimize efficiency, manage risk, and ensure timely delivery of products and services.",
                    "related_job_titles": "Supply Chain Strategy Executive, Chief Logistics and Procurement Officer, CSCO."
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director / Vice President of Supply Chain",
                    "position_descriptor": " Vice Presidents of Supply Chain lead a substantial portion of the supply chain operations. They manage procurement, logistics, and inventory teams, ensuring efficient sourcing, transportation, and inventory management. This role involves strategic planning, supplier relationship management, and cost optimization. Vice Presidents of Supply Chain also develop and implement supply chain strategies to meet business objectives.",
                    "related_job_titles": "VP of Logistics and Procurement, Vice President of Supply Chain Operations, Supply Chain VP."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Supply Chain",
                    "position_descriptor": " Directors of Supply Chain oversee specific segments of the supply chain, such as logistics, procurement, or inventory. They lead teams, manage supplier relationships, and implement supply chain improvement initiatives. Directors of Supply Chain collaborate with cross-functional teams to ensure product availability, reduce lead times, and enhance operational efficiency.",
                    "related_job_titles": "Supply Chain Director, Director of Logistics, Supply Chain Strategy Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Supply Chain Manager",
                    "position_descriptor": " Senior Supply Chain Managers handle critical supply chain functions and manage teams responsible for procurement, logistics, or inventory management. They analyze supply chain processes, identify opportunities for improvement, and drive efficiency initiatives. Senior Supply Chain Managers also collaborate with suppliers, negotiate contracts, and implement cost-saving strategies.",
                    "related_job_titles": "Senior Manager of Logistics and Procurement, Lead Supply Chain Strategist, Senior Procurement Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Supply Chain Manager",
                    "position_descriptor": " Supply Chain Managers manage day-to-day supply chain operations, overseeing procurement, logistics, and inventory management activities. They develop and implement supply chain strategies, monitor performance metrics, and optimize processes to meet demand while controlling costs. Supply Chain Managers ensure timely delivery of products and materials to support business operations.",
                    "related_job_titles": "Logistics and Procurement Manager, Supply Chain Operations Manager, Procurement Strategy Manager."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Supply Chain Team Lead / Supervisor",
                    "position_descriptor": " Supply Chain Team Leads or Supervisors oversee smaller teams within specific supply chain functions. They coordinate daily activities, manage inventory levels, and ensure orders are fulfilled on time. Supply Chain Team Leads/Supervisors may also troubleshoot operational issues and collaborate with cross-functional teams to resolve supply chain challenges.",
                    "related_job_titles": "Logistics and Procurement Team Supervisor, Supply Chain Supervisor, Procurement Team Lead."
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Supply Chain Coordinator",
                    "position_descriptor": " Supply Chain Coordinators or Analysts support supply chain activities by managing data, analyzing trends, and coordinating orders. They track inventory levels, monitor demand forecasts, and assist with procurement processes. Supply Chain Coordinators/Analysts contribute to maintaining accurate supply chain records and ensuring smooth operations.",
                    "related_job_titles": "Logistics and Procurement Coordinator, Supply Chain Analyst, Procurement Coordinator."
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Supply Chain Officer / Associate",
                    "position_descriptor": "Officers responsible for the day-to-day operations of the supply chain, working under the guidance of higher-level supply chain professionals.",
                    "related_job_titles": "Logistics and Procurement Officer, Supply Chain Associate, Procurement Officer"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry-Level Supply Chain Assistant",
                    "position_descriptor": " Entry-level positions within the supply chain are typically filled by interns or assistants. These individuals provide administrative support, assist with data entry, and help coordinate supply chain processes. Entry-Level Supply Chain Interns/Assistants gain exposure to supply chain operations and processes under the guidance of experienced professionals.",
                    "related_job_titles": "Supply Chain Intern, Logistics and Procurement Intern, Assistant Operations Coordinator."
                }
            ]
        },
        "Operations and Administration | Admin Support": {
            "job_family_descriptor": "The jobs in this family involves providing assistance and support to various departments within the organization, managing administrative tasks, and ensuring smooth operations.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Administrative Officer",
                    "position_descriptor": " At this level, the Chief Administrative Officer oversees all administrative support functions across the organization, ensuring smooth operations and effective coordination of administrative processes.",
                    "related_job_titles": " Chief Admin Officer, Administration Strategy Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director, Administrative Support",
                    "position_descriptor": "Lead and manage administrative teams, ensuring efficient execution of administrative tasks, process improvements, and adherence to organizational policies.",
                    "related_job_titles": " VP of Admin Services, Vice President of Office Support, Admin Support VP."
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Administrative Support",
                    "position_descriptor": " Directors of Administrative Support oversee specific areas of administrative functions, implementing best practices, managing teams, and collaborating with other departments to ensure seamless administrative operations.",
                    "related_job_titles": " Admin Support Director, Director of Office Services, Support Services Director."
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Administrative Manager",
                    "position_descriptor": " Senior Administrative Managers manage critical administrative functions, overseeing tasks such as document management, travel coordination, and office supplies procurement to support daily business operations.",
                    "related_job_titles": " Senior Admin Manager, Lead Office Coordinator, Senior Support Manager."
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Administrative Manager",
                    "position_descriptor": " Administrative Managers handle day-to-day administrative tasks, including managing calendars, coordinating meetings, and ensuring timely communication with internal and external stakeholders.",
                    "related_job_titles": " Admin Manager, Office Services Manager, Support Services Manager."
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Administrative Specialist / Analyst",
                    "position_descriptor": " Administrative Specialists or Analysts provide specialized support, analyzing administrative processes, managing databases, and assisting in project coordination.",
                    "related_job_titles": " Admin Specialist, Office Analyst, Support Analyst, Senior Admin Officer"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Administrative Coordinator / Associate",
                    "position_descriptor": " Administrative Coordinators or Associates support administrative activities by coordinating schedules, assisting in documentation, and facilitating effective communication within the organization.",
                    "related_job_titles": " Admin Coordinator, Office Associate, Admin Support Associate"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Administrative Officer",
                    "position_descriptor": "Experienced Administrator, performs basic administrative tasks such as data entry, filing, answering phones, scheduling appointments, and providing general office support. They often assist other staff members and may handle routine correspondence",
                    "related_job_titles": "Admin Officer, Office Administrator"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Administrative Assistant",
                    "position_descriptor": " Entry-level positions within administrative support involve interns or assistants who support various administrative tasks, contributing to office organization and operational efficiency.",
                    "related_job_titles": "Admin Assistant, Assistant Administrator"
                }
            ]
        },
        "General Marketing": {
            "job_family_descriptor": "The General Marketing job family is responsible for planning, developing, and executing marketing programs that promote the products, services, or brand of an organization. Job holders work to ensure that marketing campaigns are effective and align with the overall goals of the company. ",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Marketing Officer ",
                    "position_descriptor": "The Chief Marketing Officer is the highest-ranking marketing executive responsible for leading and shaping the entire marketing department. They define the organization's overarching marketing strategy, set objectives, and oversee the execution of marketing initiatives. The CMO plays a pivotal role in brand development, customer acquisition, and revenue growth.",
                    "related_job_titles": "Chief Marketing Officer, Chief Brand Officer, Chief Growth Officer, Senior Vice President of Marketing"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director / Vice President of Marketing",
                    "position_descriptor": "Senior Directors or Vice Presidents of Marketing are senior leaders who manage and guide the marketing department. They are instrumental in developing and implementing marketing strategies, overseeing major campaigns, and ensuring alignment with business objectives. They often report directly to the CMO or top executives.",
                    "related_job_titles": "Vice President of Marketing, Senior Director of Marketing, Marketing Vice President, Head of Marketing, Senior Marketing Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Marketing",
                    "position_descriptor": "Directors of Marketing oversee specific areas or teams within the marketing department, such as digital marketing, product marketing, or brand management. They are responsible for strategy implementation, team leadership, and achieving marketing objectives.",
                    "related_job_titles": "Director of Marketing, Marketing Director, Head of Marketing Strategy"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Marketing Manager",
                    "position_descriptor": "Senior Marketing Managers lead marketing teams and initiatives, taking responsibility for the successful execution of marketing plans. They play a key role in campaign management, data analysis, and ensuring marketing efforts drive business growth.",
                    "related_job_titles": "Senior Marketing Manager, Senior Marketing Strategist, Marketing Program Manager, Senior Campaign Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Marketing Manager",
                    "position_descriptor": "Marketing Managers manage marketing campaigns, projects, or teams. They develop and execute marketing plans, analyze campaign performance, and ensure that marketing initiatives align with the overall marketing strategy.",
                    "related_job_titles": "Marketing Manager, Marketing Campaign Manager, Marketing Project Manager, Senior Marketing Specialist"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Team Lead / Supervisor - Marketing",
                    "position_descriptor": "Team Leads or Supervisors in Marketing oversee a group of marketing professionals. They provide guidance, assign tasks, and ensure that team members work effectively to achieve marketing goals.",
                    "related_job_titles": "Marketing Team Lead, Marketing Supervisor, Marketing Operations Supervisor, Marketing Project Lead, Marketing Team Supervisor, Assistant Marketing Manager"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Marketing Specialist/ Senior Officer",
                    "position_descriptor": "Marketing Specialists are subject matter experts in specific marketing areas, such as social media marketing, content marketing, or product marketing. They work on specialized projects, manage key accounts, and contribute to marketing strategy development.",
                    "related_job_titles": "Marketing Specialist, Marketing Coordinator, Marketing Analyst, Marketing Strategist, Product Marketing Specialist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Marketing Coordinator / Associate / Officer",
                    "position_descriptor": "Marketing Coordinators or Associates provide essential support to marketing teams. They assist in organizing events, managing schedules, and handling various marketing tasks to ensure projects run smoothly.",
                    "related_job_titles": "Marketing Coordinator, Marketing Associate, Marketing Officer, Marketing Support Specialist, Marketing Administrator"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry-Level Marketing Assistant",
                    "position_descriptor": "Entry-Level Marketing Assistants are new to the field and support marketing activities by performing tasks such as data entry, research, and assisting with marketing campaigns. They gain foundational experience in marketing processes and may work under the guidance of more experienced team members.",
                    "related_job_titles": "Marketing Assistant, Marketing Intern, Marketing Trainee, Junior Marketer, Marketing Apprentice"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Marketing Admin Assistant",
                    "position_descriptor": "Offers administrative support to the department, manage office tasks, coordinate meetings, and provide assistance to ensure the smooth functioning of operations.",
                    "related_job_titles": "Marketing Admin Assistant, Marketing clerk"
                }
            ]
        },
        "General Sales": {
            "job_family_descriptor": "The General Sales job family encompasses positions that are responsible for generating revenue for the company through the sale of products and/or services. job holders may work in a variety of industries and sell a wide range of products and services. They are typically responsible for identifying potential customers, developing relationships with them, and closing sales.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Sales Officer ",
                    "position_descriptor": "Chief Sales Officers are top-level executives responsible for leading the entire sales department, shaping the organization's sales strategy, and ensuring that sales efforts drive revenue growth. They play a crucial role in setting sales targets and overseeing major sales initiatives.",
                    "related_job_titles": "Chief Sales Officer, Chief Revenue Officer, Senior Vice President of Sales, Head of Sales"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director / Vice President of Sales",
                    "position_descriptor": "Senior Directors or Vice Presidents of Sales are senior leaders who manage and guide the sales team, often reporting directly to the CSO or top executives. They play a pivotal role in developing and implementing sales strategies to achieve business objectives.",
                    "related_job_titles": "Vice President of Sales, Senior Director of Sales, Sales Vice President, Senior Sales Director"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Sales",
                    "position_descriptor": "Directors of Sales oversee specific areas or teams within the sales department, such as regional sales or key accounts. They are responsible for strategy implementation, team leadership, and achieving sales goals.",
                    "related_job_titles": "Director of Sales, Sales Director, Regional Sales Director, Director of Sales and Marketing"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Sales Manager",
                    "position_descriptor": "Senior Sales Managers lead sales teams and initiatives, ensuring the successful execution of sales plans and campaigns. They play a key role in managing sales performance and contributing to revenue growth.",
                    "related_job_titles": "Senior Sales Manager, Senior Sales Strategist, Senior Sales Program Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Sales Manager",
                    "position_descriptor": "Sales Managers are responsible for managing sales teams, setting targets, and developing sales strategies. They play a crucial role in coaching and motivating sales representatives to meet their goals.",
                    "related_job_titles": "Sales Manager, Sales Team Manager, Sales Operations Manager, Sales Coordinator Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Team Lead / Supervisor - Sales",
                    "position_descriptor": "Sales Team Leads or Supervisors oversee groups of sales professionals, providing guidance and ensuring effective team collaboration. They may focus on specific sales channels or regions.",
                    "related_job_titles": "Assistant Manager-Sales, Sales Team Lead, Sales Supervisor, Sales Operations Supervisor, Sales Project Lead, Sales Team Supervisor"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Sales Specialist/ Senior Officer",
                    "position_descriptor": "Sales Specialists are subject matter experts in specific product lines or market segments. They contribute to specialized sales projects, manage key accounts, and support sales strategy development.",
                    "related_job_titles": "Sales Specialist, Sales Coordinator, Sales Analyst, Sales Strategist, Product Sales Specialist, Senior Sales Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Sales Coordinator / Associate/Officer",
                    "position_descriptor": "Sales Coordinators or Associates provide administrative and operational support to sales teams, assisting with tasks such as order processing, customer service, and sales coordination.",
                    "related_job_titles": "Sales Coordinator, Sales Associate, Sales Officer, Sales Support Specialist, Sales Administrator"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Sales Assistant",
                    "position_descriptor": "Entry-Level Sales Assistants are new to the sales field and provide support by performing tasks like data entry and assisting with sales campaigns. They gain foundational experience in sales processes and may work under the guidance of more experienced team members.",
                    "related_job_titles": "Sales Assistant, Sales Trainee, Junior Sales Assistant, Sales Apprentice"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Sales Admin Assistant",
                    "position_descriptor": "Offers administrative support to the department, manage office tasks, coordinate meetings, and provide assistance to ensure the smooth functioning of operations.",
                    "related_job_titles": "Sales Administration Assistant, Sales Clerk"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Field Sales Representatives ",
                    "position_descriptor": "Sales Representatives promote and sell products at an assigned station. They build relationships with clients, understand customer needs, and contribute to achieving sales targets.",
                    "related_job_titles": "Field Sales Staff, Sales Reps, Sales Representatives"
                }
            ]
        },
        "Safety and Security": {
            "job_family_descriptor": "This job family focuses on the planning, implementation, and management of safety and security measures to prevent and respond to threats, incidents, and emergencies",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Security Officer / Chief Safety Officer",
                    "position_descriptor": " As the highest-ranking executive in security and safety, the position sets and leads the organization's safety and security strategies. They manage all aspects of security, crisis response, safety policies, and risk assessment, ensuring a safe environment for all stakeholders.",
                    "related_job_titles": "Chief Security Officer (CSO), Chief Safety Officer (CSaO), Head of Security"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director of Safety and Security",
                    "position_descriptor": " Senior Directors are top-level executives responsible for overseeing all safety and security functions. They contribute to organizational strategy, manage budgets, and lead security and safety teams.",
                    "related_job_titles": "Senior Security Director, Senior VP of Safety and Security, Safety and Security Executive Director,"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director of Safety and Security",
                    "position_descriptor": " Director leads and manages safety and security functions within an organization. They develop strategies, policies, and programs while ensuring compliance with relevant regulations.",
                    "related_job_titles": "Director of Safety and Security, Safety and Security Director"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Manager of Safety and Security",
                    "position_descriptor": " Senior Manager oversees safety and security departments, defining and executing strategies. They manage safety programs, security measures, and crisis response procedures.",
                    "related_job_titles": "Senior Manager Safety and Security, Senior Safety Program Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Safety and Security Manager / Analyst",
                    "position_descriptor": " Managers/Senior Analysts lead safety and security teams, enforcing policies and ensuring safety compliance. They analyse safety data, manage safety programs, and supervise security measures. ",
                    "related_job_titles": "Safety and Security Manager, Senior Safety Analyst, Safety Compliance Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Safety and Security Specialist",
                    "position_descriptor": " Coordinators/Safety Specialists support safety and security functions, ensuring adherence to policies and standards. They conduct safety training, inspections, and incident investigations.",
                    "related_job_titles": "Safety Coordinator, Safety Specialist, Security Compliance Specialist"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Safety and Security Officer / Inspector",
                    "position_descriptor": " Officers/Inspectors are responsible for implementing security and safety measures. They monitor premises, enforce safety regulations, and respond to incidents.",
                    "related_job_titles": "Safety Officer, Security Inspector, Safety and Security Patrol Officer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Safety and Security Technicians",
                    "position_descriptor": " Technicians provide support in safety and security operations. They assist with equipment maintenance, access control, and safety drills.",
                    "related_job_titles": "Safety Technician, Security Supervisor, Access Control Technician"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Safety and Security Assistant",
                    "position_descriptor": " These are entry-level assistants who gain experience in safety and security. They support senior personnel in tasks such as security monitoring, incident reporting, and safety audits.",
                    "related_job_titles": "Safety and Security Intern, Assistant Security Officer, Safety Assistant Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Senior Security Guard",
                    "position_descriptor": " Senior Security guards take on more responsibilities which reflects their experience and potentially additional duties within the organization. They are experienced Guards responsible for monitoring and safeguarding premises in settings where on-site security personnel are needed.",
                    "related_job_titles": "Senior Security Guard"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Security Guard I",
                    "position_descriptor": "Guards responsible for monitoring and safeguarding premises in settings where on-site security personnel are needed",
                    "related_job_titles": "Security Guard"
                },
                {
                    "wr_job_level": "WR 01",
                    "survey_position": "Security Guard II",
                    "position_descriptor": "Entry level Guards responsible for monitoring and safeguarding premises in settings where on-site security personnel are needed",
                    "related_job_titles": "Security Guard"
                }
            ]
        },
        "ICT Operations": {
            "job_family_descriptor": "This job family covers roles related to managing and maintaining technology infrastructure, networks, and IT systems. It includes positions that ensure the smooth operation of IT services, hardware, and software within an organization.",
            "levels": [
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Chief Technology Officer (CTO)",
                    "position_descriptor": "The Chief is responsible for shaping and executing the technology strategy of the organization. They oversee the design, implementation, and management of the entire IT infrastructure, ensuring it aligns with business goals. The CTO provides visionary leadership to optimize technology systems, enhance operational efficiency, and drive innovation.",
                    "related_job_titles": "Chief Information Officer, IT Strategy Executive, Head of IT, Technology Operations Officer"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Senior Director - ICT ",
                    "position_descriptor": "The Senior Director plays a pivotal role in leading technology operations. They provide strategic direction to IT teams, manage critical technology projects, and ensure the reliability and scalability of infrastructure. The Senior Director collaborates with cross-functional stakeholders to optimize technology solutions and drive continuous improvement.",
                    "related_job_titles": "ICT Strategy Director, Senior IT Operations Director, Director of Infrastructure, Technology Leadership Executive"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Director - ICT ",
                    "position_descriptor": "The Director is accountable for the effective management of technology systems and infrastructure. They oversee the deployment of technology initiatives, ensure seamless operation of IT services, and lead teams in implementing and maintaining IT solutions that support business operations.",
                    "related_job_titles": "IT Operations Director, Infrastructure Solutions Director, Chief IT Officer"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Senior Manager - ICT",
                    "position_descriptor": "The Senior Manager leads IT operations to meet organizational objectives. They manage technology teams, provide technical leadership, and ensure the stability, security, and performance of IT infrastructure. The Senior Manager contributes to strategic planning and manages technology projects.",
                    "related_job_titles": "Senior IT Operations Manager, Infrastructure Solutions Manager, Senior IT Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Manager - ICT ",
                    "position_descriptor": "The Manager is responsible for overseeing the day-to-day management of technology systems and services. They lead teams in deploying, configuring, and maintaining IT assets, and collaborate with various departments to ensure technology meets business needs. The Manager monitors IT performance, resolves issues, and drives operational efficiency.",
                    "related_job_titles": "IT Operations Manager, Infrastructure Implementation Manager, IT Infrastructure Manager"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Team Leader - ICT ",
                    "position_descriptor": "The Team Leader supervises a group of IT professionals. They guide technical activities, mentor team members, and collaborate with other teams to ensure seamless technology operations. The Team Leader contributes to the development and implementation of technology solutions.",
                    "related_job_titles": "IT Team Lead, Infrastructure Team Supervisor, Technical Infrastructure Lead"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Specialist Professional - ICT",
                    "position_descriptor": "The IT Specialist is an expert in specific technology domains. They analyze technology trends, design and implement infrastructure solutions, and optimize technology performance. The Specialist contributes to technology planning, technical documentation, and process improvement.",
                    "related_job_titles": "IT Expert, Infrastructure Specialist, Technical Infrastructure Guru"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Senior Officer - ICT",
                    "position_descriptor": "The Senior Professional manages technology systems and services. They lead technology deployment, contribute to technical projects, and provide expertise to support IT initiatives. The Senior Professional ensures the reliability and availability of technology resources.",
                    "related_job_titles": "Senior IT Specialist, Lead Infrastructure Engineer, Principal Technologist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "ICT Officer",
                    "position_descriptor": "The IT Professional assists in managing technology systems. They participate in technology deployment, contribute to technical documentation, and provide technical assistance to users. The Professional supports IT projects and operational tasks.",
                    "related_job_titles": "ICT Officer, IT Support Specialist, Infrastructure Engineer, Technical Operations Analyst"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - ICT",
                    "position_descriptor": "The Support Associate in IT provides administrative and technical assistance to IT teams. They manage documentation, coordinate technology activities, and support IT operations. The Support Associate contributes to maintaining an efficient and organized IT environment.",
                    "related_job_titles": "Junior IT Associate, Trainee IT Technician, IT Intern, IT Operations Associate, Technical Support Assistant, IT Helpdesk Coordinator"
                }
            ]
        }
    }
}