import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { YES_NO } from "../../../../../../configs/data/onboarding"

import { CheckboxInput } from "../../../../../../components/forms/CheckboxInput"
import { WR_COLORS } from "../../../../../../configs/colors"
import HeadCell from "../../../../../../components/forms/general"

const ORGANIZATION_PP = [
    'All employees',
    "Some Employees"
]

export default function OtherBenefits(props) {

    const COLS = [
        "Christmas hamper/gifts", "End of year party", "Relocation support",
        "Birthday celebrations/cake", 
        //"Shift allowance", 
        "Clothes/Dressing allowance",
        "Subsidised Meals", "Other"
    ]
    const id = props.id
    const { watch, errors, control, register } = props

    const allEmployeesValues = watch(`${id}.data.All employees`);
    const isAllEmployeesRowFilled = Object.values(allEmployeesValues || {}).some(val => Boolean(val));

    const otherRowsValues = ORGANIZATION_PP.filter(item => item !== "All employees")
        .map(item => watch(`${id}.data.${item}`));
    const isAnyOtherRowFilled = otherRowsValues.some(row => Object.values(row || {}).some(val => Boolean(val)));


    return (
        <div>

            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer any other benefits?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                <HeadCell></HeadCell>
                                {COLS.map((smm, index) => (
                                    <HeadCell style={{ width: smm === "" ? "auto" : "130px" }} key={`others-benefits-${index}`}>{smm}</HeadCell>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PP.map((item, index) => {
                                    const isCurrentRowAllEmployees = item === "All employees";
                                    if (isAnyOtherRowFilled && isCurrentRowAllEmployees || isAllEmployeesRowFilled && !isCurrentRowAllEmployees) {
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 py-2 border-b border-r">{item}</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                            </tr>
                                        );
                                    }
                                    return (<tr key={`other-benefits-row-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        {COLS.map(smm => (
                                            <td key={`other-benefirs-${index}-col-${smm}`} className="px-2 border-b border-r">
                                                <CheckboxInput control={control} errors={errors} name={`${id}.data.${item}.${smm}`} />
                                            </td>
                                        ))
                                        }


                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )
}