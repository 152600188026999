import React from 'react';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts'
import { styled } from "@mui/material";
import { WR_GRAPH_COLORS } from '../../../../configs/colors';

const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)",
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))


export default function EmploymentType(props) {
  console.log(props.data)
    const state = {
          
        series: [{
            data: props.data
        }],
        options: {
          chart: {
            type: 'rangeBar',
            height: 350
          },
          colors: WR_GRAPH_COLORS,
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          dataLabels: {
            enabled: true
          },
          dataLabels: {
            enabled: false,
            formatter: function(val, opts) {
              console.log(val, opts)
              return 'Min: ' + val.toLocaleString()
              
            }
          },
          tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const dt = props.data[dataPointIndex]
                return (
                    `<div class="custom-tooltip">
                        <div class="custom-tooltip-header">Data - ${dt.x}</div>
                        <div class="custom-tooltip-value">Min: ${dt.y.length === 2 && dt.y[0]?.toLocaleString()}</div>
                        <div class="custom-tooltip-value">Max: ${dt.y.length === 2 && dt.y[1]?.toLocaleString()}</div>
                    </div>`
                );
            },
            // ... other tooltip settings ...
        },
          xaxis: {
            title: {
              text: "Job Grade"
            },
            labels: {
              show: true
            },
            axisBorder: { show: true }
          },
          yaxis: {
            title: {
                text: "Salaries"
            },
            labels: {
                show: true,
                formatter: function (value) {
                    // Convert the value to a string with commas
                    return value.toLocaleString();
                },
                axisBorder: { show: true }
            },
            axisBorder: { show: true }
        },
        fill: { opacity: 1 },
        legend: { show: true },
        },
      
      
      }

    return (

            <SingleCard>
                <h2 className='text-xl font-semibold text-gray-600'>Salary Distribution</h2>

                <Chart series={state.series} options={state.options} type="rangeBar" height={350} />
            </SingleCard>
    );
}



