import React from "react";
import BCard from "../../Components/BCard";
import TableView from "../../Components/TableView";
import { getFooter } from "../utils";

export default function Initiative(props) {
  const { columns, rows, total } = props?.data;
  return (
    <div className="my-4">
      <BCard
        title="Well-Being Initiatives"
        footer={getFooter(total)}
        note={`The total does not equal 100% due to rounding.`}
      >
        <TableView columns={columns} rows={rows} />
        {/* <TableWithBarChart columns={columns} rows={rows} /> */}
      </BCard>
    </div>
  );
}
