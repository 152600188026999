import React from 'react';
import { Grid } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { styled } from "@mui/material";
import PaymentsIcon from '@mui/icons-material/Payments';
import DonutChart from '../../../Benefits/Components/DonutChart';
const SingleCard = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)", display: 'flex', justifyContent: 'space-between',
    padding: '20px', width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))

const SingleCardNP = styled("div")(({ theme }) => ({
    boxShadow: "0px 1px 6px 0px rgb(220, 220, 220)", display: 'flex', justifyContent: 'space-between',
    width: '100%',
    border: '1px solid rgb(252, 252, 252)', borderRadius: '7px'
}))

const CardIconContainer = styled("div")(({ theme }) => ({
    color: "#fff", width: '58px', height: '58px', textAlign: 'center', borderRadius: '50%',
    display: 'flex', justifyContent: 'center', flexDirection: 'column',
    alignContent: 'center'
}))

export default function Cards(props) {
    const { data } = props
    console.log(data)
    const iconSize = 32
    const data_rows = [
        {
            title: "Total Compensation (Organisation)", value: `${data?.currency ? data?.currency : ''} ${data?.compensation?.toLocaleString()}`, color:"#8124EF", background: '#F3EAFE', icon: <PaymentsIcon className='m-auto' sx={{ fontSize: iconSize, color: "#8124EF" }} />
        },
        {
            title: "Total Employee", value: data?.total_employees?.toLocaleString(), color: "#00BBFF", background:"#E6F9FF", icon: <PeopleIcon className='m-auto' sx={{ fontSize: iconSize, color: "#00BBFF" }} />
        },
        // {
        //     title: "Gender Pay Gap", value: "41%", color: "#5076FF", background:"#eef2ff", icon: <AccountBalanceWalletIcon className='m-auto' sx={{ fontSize: iconSize, color: "#5076FF" }} />
        // }
    ]


    return (
            <Grid container spacing={2}>
                { data_rows.map((item) => (
                    <Grid item md={4} key={item.title}>
                    <SingleCard>
                        <div>
                            <p className="text-md text-gray-600">{ item.title }</p>
                            <h2 className={`mt-1 text-2xl font-bold`} style={{ color: item.color }}>{item.value}</h2>
                        </div>
                        <CardIconContainer style={{ backgroundColor: item.background }}>
                            {item.icon}
                        </CardIconContainer>
                    </SingleCard>
                </Grid>
                ))}
                <Grid item md={4} key={"Gender Split"}>
                    <SingleCardNP>
                        <div style={{ padding: '20px' }}>
                            <p className="text-md text-gray-600">{ "Gender Split" }</p>
                            
                            {/* <h2 className={`mt-1 text-xl font-bold`} style={{ color: "#5076FF" }}>
                                Male: <span className='text-gray-700' style={{ fontSize: 20 }}>{data?.gender_pay_gap?.male.toFixed(2)}</span> - 
                                Female:  <span className='text-gray-700' style={{ fontSize: 20 }}>{data?.gender_pay_gap?.female.toFixed(2)}</span> -
                                Non Binary:  <span className='text-gray-700' style={{ fontSize: 20 }}>{data?.gender_pay_gap?.non_binary?.toFixed(2)}</span>
                            </h2> */}
                        </div>
                        <div className='custom-donut-chart'>
                          
                        <DonutChart categories={["Male", "Female", "Non-Binary"]} series={[
                            data?.gender_pay_gap?.male, 
                            data?.gender_pay_gap?.female,
                            data?.gender_pay_gap?.non_binary
                            ]} height={'96px'} />
                        </div>
                    </SingleCardNP>   
                </Grid> 

            </Grid>
    );
}



