import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card } from "@mui/material";

import { styled } from '@mui/material';
import GeneralTooltip from '../../../../components/GeneralTooltip';


const StyledDataGrid = styled(DataGrid)({
    // set width to 100%
    '& .MuiDataGrid-root': {
        width: '100%',
    },
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#F9FAFB', // Set table heading row background to #eee
    },
});

function DataTable(props) {
    const marketDescriptions = {
        "High": "Your company's value is above the 75th percentile of the market, indicating a strong performance relative to peers.",
        "Low": "Your company's value is below the 25th percentile of the market, suggesting room for improvement compared to peers.",
        "Low-Average": "Your company's value is between the 25th and 50th percentiles of the market, placing it slightly below the median.",
        "Average-High": "Your company's value is between the 50th and 75th percentiles, indicating a performance above the median.",
        "At Market": "Your company's value is within the 25th to 75th percentile range, aligning closely with the market average."
      };
    const columns = [
        { field: 'role', headerName: 'Role', width: 300 },
        { field: 'grade', headerName: 'Grade', width: 75 },
        { field: 'industry', headerName: 'Industry', width: 160 },
        { field: 'companies', headerName: 'Companies', width: 90 },
        { field: 'employees', headerName: 'Employees', width: 90 },
        {
            field: 'min',
            headerName: 'Min',
            width: 100,
            //valueGetter: (params) => params.row.min?.toLocaleString()
            renderCell: (params) => (
                // Only show if companies and employees are greater than 2 else '-'
                params.row.companies > 3 && params.row.employees > 3 ?
                    params.row.min?.toLocaleString()
                    :
                    '-'
            )
        },
        {
            field: 'market_25th',
            headerName: '25th Percentile',
            width: 110,
            //valueGetter: (params) => params.row.market_25th?.toLocaleString()
            // Only show if companies and employees are greater than 4 else '-'
            renderCell: (params) => (
                params.row.companies > 4 && params.row.employees > 4 ? 
                    params.row.market_25th?.toLocaleString()
                    :
                    '-'
            )
        },
        {
            field: 'market_50th',
            headerName: '50th Percentile',
            width: 110,
            //valueGetter: (params) => params.row.market_50th?.toLocaleString()
            // Only show if companies and employees are greater than 2 else '-'
            renderCell: (params) => (
                params.row.companies > 2 && params.row.employees > 2 ?
                    params.row.market_50th?.toLocaleString()
                    :
                    '-'
            )
        },
        {
            field: 'market_75th',
            headerName: '75th Percentile',
            width: 110,
            //valueGetter: (params) => params.row.market_75th?.toLocaleString()
            // Only show if companies and employees are greater than 4 else '-'
            renderCell: (params) => (
                params.row.companies > 4 && params.row.employees > 4 ?
                    params.row.market_75th?.toLocaleString()
                    :
                    '-'
            )
        },

        {
            field: 'max',
            headerName: 'Max',
            width: 100,
            //valueGetter: (params) => params.row.max?.toLocaleString()
            // Only show if companies and employees are greater than 2 else '-'
            renderCell: (params) => (
                params.row.companies > 3 && params.row.employees > 3 ?
                    params.row.max?.toLocaleString()
                    :
                    '-'
            )
        },

        {
            field: 'annual_basic_salary',
            headerName: 'My Company',
            width: 110,
            valueGetter: (params) => params.row.company?.avg?.toLocaleString()
        },
        {
            field: 'market', headerName: 'Market Position', width: 150,
            renderCell: (params) => (
                // Assuming params.row.market_status holds the value from get_market function
                params.row.companies > 4 && params.row.employees > 4 ?
                <div>
                    {
                params.row.company?.avg ?
                <GeneralTooltip description={
                    marketDescriptions[params.row.market]
                }>
                <div style={{
                    color: params.row.market === "High" ? "green" :
                        params.row.market === "Average-High" ? "lightgreen" :
                            params.row.market === "At Market" ? "orange" :
                                params.row.market === "Low-Average" ? "#CEB10B" :
                                    "red" // For "Low"
                }}>
                    {params.row.market}
                </div>
                </GeneralTooltip>
        
        : '-'}
        </div>
                :
                '-'
            )

        }
    ];

    return (
        <Card>
            <div style={{ height: 700, width: '100%' }}>
                <StyledDataGrid
                    rows={props.data ?? []}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    density="compact"
                />
            </div>
        </Card>
    );
}

export default DataTable;
