import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";
import NoData from "../../Components/NoData";

export default function EducationBenefit(props) {
  const { data } = props
  if (!data) return null

  const {offering } = data
  const charts = data.data
  const { max_age, max_amount_per_child_yearly, max_num_children, self_education_yearly_amount } = charts
  
  


  return (
    <div>
      <BlockHeading text="Education" />
      <div className="my-4">
        <BCard
          title="Child Education And Personal Education"
          subtitle="Does your organization offer child education benefit or personal education benefit?"
          footer={getFooter(offering?.total)}
          total={offering?.total}
          note={getNote()}
        >
          <PieChart
            categories={offering?.categories}
            series={offering?.data}
            height="200px"
          />
        </BCard>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title="Max amount per child yearly"
          subtitle={'The maximum amount per child per year that the organization offers for education benefit.'}
          note={getNote()}
          total={max_amount_per_child_yearly?.total}
          footer={getFooter(max_amount_per_child_yearly?.total)}
        >
          { max_amount_per_child_yearly ?
          <BarChart
            percentage={true}
            stacked={true}
            categories={max_amount_per_child_yearly.categories}
            series={max_amount_per_child_yearly.data}
            height="auto"
          />
          : <NoData /> }
        </BCard>
        <BCard
          title="Max no. of children"
          subtitle={'Maximum number of children for which the organization offers education benefit.'}
          note={getNote()}
          total={max_num_children?.total}
          footer={getFooter(max_num_children?.total)}
        >
          { max_num_children ?
          <BarChart
            percentage={true}
            stacked={true}
            categories={max_num_children.categories}
            series={max_num_children.data}
            height="auto"
          />
          : <NoData /> }
        </BCard>
        <BCard
          title="Max age (Years)"
          subtitle={'Maximum age of the child for which the organization offers education benefit.'}
          note={getNote()}
          total={max_age?.total}
          footer={getFooter(max_age?.total)}
        >
          { max_age ?
          <BarChart
            percentage={true}
            stacked={true}
            categories={max_age.categories}
            series={max_age.data}
            height="auto"
          /> : <NoData /> }
        </BCard>
        <BCard
          title="Education for self yearly (amount)"
          subtitle={'The amount that the organization offers for self education benefit.'}
          note={getNote()}
          total={self_education_yearly_amount?.total}
          footer={getFooter(self_education_yearly_amount?.total)}
        >
          { self_education_yearly_amount ?
          <BarChart
            percentage={true}
            stacked={true}
            categories={self_education_yearly_amount.categories}
            series={self_education_yearly_amount.data}
            height="auto"
          />
          : <NoData /> }
        </BCard>
      </div>
    </div>
  );
}
