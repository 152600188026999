import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function TooltipCustom(props) {
    const { text, faq } = props
    return (

        <HtmlTooltip
            title={
                <React.Fragment>
                    <div className="py-1" role="none">
                        <div
                            className="block px-4 py-2 text-sm text-gray-700"
                            role="menuitem"
                        >
                            <p>{
                                text
                            }
                            </p>
                            <a href='/faq' className='text-blue-500' target='_blank'>See FAQs for further clarification</a>
                        </div>
                    </div>
                </React.Fragment>
            }
        >
            <button type="button">
                <span className="text-md" style={{ color: "#9651fa" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>


                </span>
            </button>
        </HtmlTooltip>
    );
}