import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function Leave(props) {
  console.log(props.data);
  const {
    annual,
    compassionate,
    paternity,
    maternity,
    sell_annual,
    sabbatical,
    sick_full_pay,
    sick_half_pay,
    sick_unpaid,
    unpaid,
    study,
    carried_forward,
  } = props.data;
  return (
    <div>
      <BlockHeading text="6. Leave" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title={`Annual Leave`}
          footer={getFooter(annual?.total)}
          note={getNote()}
        >
          <BarChart
            categories={annual?.categories}
            series={[{ name: "Annual Leave", data: annual?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Study Leave`}
          footer={getFooter(study?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={study?.categories}
            series={[
              {
                name: "Study Leave",
                data: study?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
        <BCard
          title={`Compassionate Leave`}
          footer={getFooter(compassionate?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={compassionate?.categories}
            series={[
              {
                name: "Compassionate Leave",
                data: compassionate?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
        <BCard
          title={`Sick Full Pay Leave`}
          footer={getFooter(sick_full_pay?.total)}
          note={getNote()}
        >
          <BarChart
            categories={sick_full_pay?.categories}
            series={[{ name: "Sick Full Pay Leave", data: sick_full_pay?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Sick Half Pay Leave`}
          footer={getFooter(sick_half_pay?.total)}
          note={getNote()}
        >
          <BarChart
            categories={sick_half_pay?.categories}
            series={[{ name: "Sick Half Pay Leave", data: sick_half_pay?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Sick Unpaid Leave`}
          footer={getFooter(sick_unpaid?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={sick_unpaid.categories}
            series={[
              {
                name: "Sick Unpaid Leave",
                data: sick_unpaid?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
        <BCard
          title={`Maternity Leave`}
          footer={getFooter(maternity?.total)}
          note={getNote()}
        >
          <BarChart
            categories={maternity?.categories}
            series={[{ name: "Maternity Leave", data: maternity?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Paternity Leave`}
          footer={getFooter(paternity?.total)}
          note={getNote()}
        >
          <BarChart
            categories={paternity?.categories}
            series={[{ name: "Paternity Leave", data: paternity?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Sabbatical Leave`}
          footer={getFooter(sabbatical?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={sabbatical.categories}
            series={[
              {
                name: "Sabbatical Leave",
                data: sabbatical?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
        <BCard
          title={`Unpaid Leave`}
          footer={getFooter(unpaid?.total)}
          note={getNote()}
        >
          <BarChart
            categories={unpaid?.categories}
            series={[{ name: "Unpaid Leave", data: unpaid?.data }]}
            height="auto"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Sell Annual Leave`}
          footer={getFooter(sell_annual?.total)}
          note={getNote()}
        >
          <PieChart
            categories={sell_annual?.categories}
            series={sell_annual?.data}
            height="200px"
            percentage={true}
          />
        </BCard>
        <BCard
          title={`Carried Forward leave`}
          footer={getFooter(carried_forward?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={carried_forward.categories}
            series={[
              {
                name: "Carried Forward Leave",
                data: carried_forward?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
      </div>
    </div>
  );
}
