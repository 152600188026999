import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function WorkEnvironment(props) {
  console.log(props.data);
  const { work_regime, work_hours, work_days, work_hours_week } = props.data;
  return (
    <div>
      <BlockHeading text="5. Work Environment" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
        <BCard
          title="Work Regime"
          // subtitle={`Work regime for majority of employees`}
          footer={getFooter(work_regime?.total)}
          note={getNote()}
        >
          <PieChart
            categories={work_regime?.categories}
            series={work_regime?.data}
            height="200px"
          />
        </BCard>

        <BCard
          title={`Work Days`}
          footer={getFooter(work_days?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={work_days?.categories}
            series={[
              {
                name: "Work Days",
                data: work_days?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
        <BCard
          title={`Work Hours`}
          footer={getFooter(work_hours?.total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={work_hours?.categories}
            series={[
              {
                name: "Work Hours",
                data: work_hours?.data,
              },
            ]}
            height="auto"
          />
        </BCard>
        <BCard
          title="Work Hours Week"
          footer={getFooter(work_hours_week?.total)}
          note={getNote()}
        >
          <PieChart
            categories={work_hours_week?.categories}
            series={work_hours_week?.data}
            height="200px"
          />
        </BCard>
      </div>
    </div>
  );
}
