
import Label from "../../../../../../components/forms/InputLabel"
import SelectAutocompleteSingle from "../../../../../../components/forms/SelectAutocompleteSingle"
import { ORGANIZATION_PEOPLE, YES_NO } from "../../../../../../configs/data/onboarding"
import { WR_COLORS } from "../../../../../../configs/colors"
import HeadCell from "../../../../../../components/forms/general"
const ORGANIZATION_PP = [
    'All employees eligible for the same benefit',
    ...ORGANIZATION_PEOPLE
]

const CAR_USAGE = [
    { label: "For work only", value: "For work only" },
    { label: "Work and private use", value: "Work and private use" }
]

export default function CompanyCar(props) {
    const id = props.id
    const { watch, errors, control, register } = props
    const allEmployeesValues = watch(`${id}.data.All employees eligible for the same benefit`);
const isAllEmployeesRowFilled = Object.values(allEmployeesValues || {}).some(val => Boolean(val));

const otherRowsValues = ORGANIZATION_PP.filter(item => item !== "All employees eligible for the same benefit")
                                       .map(item => watch(`${id}.data.${item}`));
const isAnyOtherRowFilled = otherRowsValues.some(row => Object.values(row || {}).some(val => Boolean(val)));

    return (
        <div>

            <div className="my-2">
                <Label className="mb-2" htmlFor="offering"
                    text="Does your organisation offer company cars as a benefit?" />
                <SelectAutocompleteSingle
                    required
                    name={`${id}.offering`}
                    errors={errors} watch={watch}
                    options={YES_NO} control={control} />
            </div>

            {watch(`${id}.offering`) === "Yes" &&
                <div className="my-2">
                    <table className="w-full border">
                        <thead>
                            <tr style={{ backgroundColor: WR_COLORS.t_head_main }}>
                                <HeadCell></HeadCell>
                                <HeadCell colSpan={2}>Company car</HeadCell>
                                <HeadCell></HeadCell>
                            </tr>
                            <tr style={{ backgroundColor: WR_COLORS.t_head }}>
                                {["", 'How is car utilised?', "Does the company cover fuel and maintenance?", "Are personal drivers provided by the company?"].map(smm => (
                                    <HeadCell key={`company-car-sub-${smm}`}>{smm}</HeadCell>
                                ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ORGANIZATION_PP.map((item, index) => {
                                    const isCurrentRowAllEmployees = item === "All employees eligible for the same benefit";

                                    if (isAnyOtherRowFilled && isCurrentRowAllEmployees || isAllEmployeesRowFilled && !isCurrentRowAllEmployees) {
                                        return (
                                            <tr key={`medical_cover-eligibility-${index}`}>
                                                <td className="px-2 py-2 border-b border-r">{item}</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                                <td className="border-b border-r">&nbsp;</td>
                                            </tr>
                                        );
                                    }
                                    return (<tr key={`company-car-row-${index}`}>
                                        <td className="px-2 border-b border-r">{item}</td>
                                        <td className="border-b border-r" style={{ minWidth: '250px' }}>
                                            <SelectAutocompleteSingle border={false} id={`cc#${item}#car_utilise`} name={`${id}.data.${item}.car_utilise`} control={control} options={CAR_USAGE} errors={errors} />
                                        </td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false} id={`data.${item}.car_fuel_maintenance_cover`} name={`${id}.data.${item}.car_fuel_maintenance_cover`} control={control} options={YES_NO} errors={errors} />
                                        </td>
                                        <td className="border-b border-r">
                                            <SelectAutocompleteSingle border={false} id={`data.${item}.personal_driver`} name={`${id}.data.${item}.personal_driver`} control={control} options={YES_NO} errors={errors} />
                                        </td>


                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }

        </div>
    )
}