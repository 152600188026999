import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../components/layouts/Dashboard";
import instance from "../../../auth/useJwt";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Card, CardContent, CardHeader, Checkbox, LinearProgress } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { styled } from '@mui/material';
import { Link } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)({
    // set width to 100%
    '& .MuiDataGrid-root': {
        width: '100%',
    },
    '& .MuiDataGrid-cell': {
        borderRight: '1px solid #ddd', // Add vertical borders to cells
    },
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#F9FAFB', // Set table heading row background to #eee
    },
});
export default function AdminCompanies() {

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)

    const get_users = () => {
        setLoading(true)
        instance.get('/admin/companies').then(res => {

            // add field "index" to users
            const temp_users = res.data.map((user, index) => {
                return {
                    ...user,
                    index: index + 1
                }
            })
            setUsers(temp_users)
            setLoading(false)
        }
        ).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }


    const columns = [
        { field: 'index', headerName: '', width: 50 },
        { field: 'contact_name', headerName: 'Name', flex: 1 },
        { field: 'contact_email', headerName: 'Email', flex: 1 },
        { field: 'name', headerName: 'Company Name', width: 200 },
        { field: 'country', headerName: 'Country', width: 150 },
        { field: 'industry', headerName: 'Industy', flex: 1 },
        { field: 'sector', headerName: 'Sector', flex: 1 },
        { field: 'percentage_done', headerName: 'Percentage Done', flex: 1, renderCell(params) {    
            return (
                <div style={{ width: `${params.value}%`, backgroundColor: '#9951f0', py:0.5, display: 'flex', alignItems: 'center' }}>
                    <LinearProgress variant="determinate" value={params.value} />
                    <span style={{ marginLeft: 10 }}>{params.value}%</span>
                </div>
            )
        } },
        { field: 'done', headerName: 'Done', width: 50, renderCell: (params) => (
            // If done is true, show checkmark
            params.value ? <span className="text-center">&#10003;</span> : null
        ) },
        { field: "approved", headerName: "Approved", width: 80, renderCell: (params) => (
            params.value ? <span className="text-center">&#10003;</span> : null
        ) },
        {
            field: 'onboarding_approved', headerName: '', width: 50,
            renderCell: (params) => (
                // MUI checkbox
                // <Link to={`/admin/companies/view?id=${params.row.id}`}>
                <Link to={`/admin/companies/${params.row.id}`}>
                    <RemoveRedEyeIcon sx={{
                        color: '#06f',
                        fontSize: '1.5rem',
                        cursor: 'pointer'
                    }} />
                </Link>
            )
        },
    ]

    useEffect(() => {
        get_users()
    }, [])

    return (
        <DashboardLayout title="Companies - Admin">
            <div className="px-4 py-1">
                <Card sx={{ border: '1px solid #eee' }}>
                    <CardHeader title={`Companies (${users.length})`} />
                    <CardContent sx={{ borderTop: '1px solid #eee', px: 0 }}>
                        <StyledDataGrid
                            rows={users}
                            columns={columns}
                            loading={loading}
                            disableRowSelectionOnClick={true}
                            autoHeight
                            sx={{ height: 500, width: '100%', border: 'none' }}
                            slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
                            density="compact"
                            pagination
                            pageSize={15}
                            pageSizeOptions={[15, 25]}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 15 } },
                            }}
                            
                        />
                    </CardContent>
                </Card>
            </div>
        </DashboardLayout>
    )
}