import React, { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";
import Label from "../../../../components/forms/InputLabel";
import TextInput from "../../../../components/forms/TextInput";
import BlockHeading from "../../../../components/forms/BlockHeading";
import SelectInput from "../../../../components/forms/SelectInput";
import PhoneInput from 'react-phone-input-2'
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css'
import { COUNTRY_CODE_MAP } from '../../../../configs/data/codes';
import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from '../../../../redux/app_data';


export default function ContactInformation(props) {
    const { watch, errors, control, register, setValue } = props
    const id = "contact_information"
    const [countryCode, setCountryCode] = useState('US');

    // If there is already phone number in watch(`${id}.phone`), extract the country code from it
    const phoneNumber = watch(`${id}.phone`);
    useEffect(() => {
        if (phoneNumber) {
          console.log(phoneNumber);
            const parsedPhoneNumber = parsePhoneNumberFromString(`+${phoneNumber}`);
            console.log(parsedPhoneNumber);
            if (parsedPhoneNumber) {
              console.log(parsedPhoneNumber.countryCallingCode);
              //setCountryCode(COUNTRY_CODE_MAP[parsedPhoneNumber.countryCallingCode.toString()]);
               setCountryCode(parsedPhoneNumber.countryCallingCode);
            }
        }
    }, []);

    const isReadOnly = useSelector(onBoardingReadOnly);

    return (
        <div>

            <BlockHeading text="2. Contact Information" />
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-2">
                <div>
                    <Label htmlFor={`${id}.name`} text="Name" />
                    <TextInput watch={watch} type="text" id="contact_name" name={`${id}.name`} register={register} errors={errors} />
                </div>
                <div>
                    <Label htmlFor={`${id}.title`} text="Title" />
                    <SelectInput id={`${id}.title`} name={`${id}.title`} register={register} options={[
                        { label: 'Select a value', value: '' },
                        { label: 'Mr.', value: 'Mr.' },
                        { label: 'Mrs.', value: 'Mrs.' },
                        { label: 'Ms.', value: 'Ms.' },
                        { label: 'Dr.', value: 'Dr.' },
                    ]} errors={errors} />
                </div>
                <div>
                    <Label htmlFor={`${id}.phone`} text="Phone" />
                    {/* <TextInput watch={watch} type="text" id="contact_phone" name={`${id}.phone`} register={register} errors={errors} /> */}
                    <div className="pt-1">
                    <Controller
      name={`${id}.phone`}
      control={control}
      rules={{
        required: 'Phone is required',
        validate: (value) => {
          console.log(value);
          const phoneNumber = isValidPhoneNumber(value, COUNTRY_CODE_MAP[countryCode.toString()]);
          console.log(phoneNumber);
          if (!phoneNumber) {
            return 'Invalid phone number';
          }
          return true;
        },
      }}
      defaultValue={watch(`${id}.phone`)}
      render={({ field }) => (
        <div>
          <PhoneInput
            country={countryCode}
            value={field.value}
            disabled={isReadOnly}
            onChange={(value, country) => {
              field.onChange(value);
              setCountryCode(country.dialCode); // Update the country code dynamically
            }}
            // On country select, set country code
            onSelect={(country) => {
              setCountryCode(country.dialCode);
            }}
            inputStyle={{
              paddingTop: '18px',
              paddingBottom: '18px',
              width: '100%',
              borderColor: errors?.contact_information?.phone ? '#f00' : '#ddd',
            }}
          />
        </div>
      )}
    />

                    </div>
                </div>
                <div>
                    <Label htmlFor={`${id}.email`} text="Email" />
                    <TextInput watch={watch} type="text" id="contact_email" name={`${id}.email`} register={register} errors={errors} rules={
                        {
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address format"
                            }
                        }
                    } />
                </div>
            </div>
        </div>
    );
}
