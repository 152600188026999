export default function Intro(props) {

    return (
        <div className="flex justify-center">
            <div className="px-20 py-8">

                <p className="mb-3 font-bold">Welcome to TheWorkrate!</p>
                <p className="mb-3">We're excited to have you on board as part of our valued community.</p>
                <p className="mb-3">Our mission at TheWorkrate is to make total rewards accessible and uncomplicated. We understand that your time is precious,  so we’ve designed an intuitive platform to support you.</p>
                <p className="mb-3">To get started, we kindly request your assistance in providing some essential data. We believe in the reciprocity principle – you share data to gain insights.</p>
                <p className="mb-3">Don't worry; we've made this as smooth as possible.</p>
                <p className="font-bold">Your onboarding journey will consist of three simple steps:</p>
                <ol className="list-decimal pl-4 mb-3">
                    <li><b>Company Data:</b> Let's begin by setting up your company's profile.</li>
                    <li><b>Benefits and Practices:</b> Share your benefits and HR practices information with us. This data is vital for comprehensive total rewards analysis.</li>
                    <li><b>Compensation:</b> Finally, provide compensation data. This step includes job matching for effective benchmarking </li>
                </ol>
                <p className="mb-3">Allow approximately <b>45 minutes</b> for this process, although you can progress at your own pace. We understand the importance of flexibility, so you can save your progress and return whenever it suits you.</p>
                <p className="mb-3">Ready to embark on this journey together? Let's get started by clicking the button below to dive into the first step: 'Company Data.'</p>


                <div className="my-3">
                    <button onClick={() => props.onNext("company_info")} type="submit" style={{ backgroundColor: '#8025EE' }} className="flex justify-center bg-blue-600 text-white py-1.5 my-5 px-5 rounded-lg hover:bg-blue-700">
                        Get Started
                    </button>
                </div>
                <p className="mb-3">We're here to support you every step of the way. If you have any questions or need assistance, please don't hesitate to reach out on <a href="mailto:clients@theworkrate.com" className="text-blue-600 underline">clients@theworkrate.com</a>
                </p>
                <p className="mb-3">Thank you for choosing WorkRate – where total rewards management is simplified!</p>

                <p>Best Regards,<br />

                TheWorkrate</p>
            </div>
        </div>
    )
}