import React from 'react';

function Avatar({ name, size = 'w-10 h-10' }) {
    // Extract initials from name
    const getInitials = name => {
        if ([null, undefined].includes(name)){
            return ""
        }
        const parts = name.split(' ');
        let initials = '';
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && !!(parts[i].trim())) {
                initials += parts[i][0].toUpperCase();
            }
        }
        return initials.substring(0, 2); // Get at most 2 initials
    };

    const initials = getInitials(name);

    return (
        <div
            className={`${size} bg-blue-500 rounded-full text-white flex items-center justify-center font-semibold`}
        >
            {initials}
        </div>
    );
}

export default Avatar;
