import React from "react";
import BCard from "../../Components/BCard";
import PieChart from "../../Components/PieChart";
import { getFooter, getNote } from "../utils";

export default function EvpProgram(props) {
  const { categories, total, data } = props?.data;
  return (
    <div className="my-4">
      <BCard
        title="Formal Employee Value Proposition Program Presence"
        footer={getFooter(total)}
        note={getNote()}
      >
        <PieChart categories={categories} series={data} height="200px" />
      </BCard>
    </div>
  );
}
