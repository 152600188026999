export const TECHNOLOGY_MEDIA = {
    "Technology": {
        "Software Development": {
            "job_family_descriptor": "This job family includes roles related to designing, developing, and maintaining software applications and solutions in a Tech organisation. It encompasses a wide range of technical roles involved in coding, programming, and software architecture.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Technology Officer (CTO)",
                    "position_descriptor": "The Chief leads the software development in a technology organisation. They are responsible for shaping the organization's technology strategy, overseeing software development initiatives, and ensuring alignment with business goals and technology trends.",
                    "related_job_titles": "Chief Technology Officer (CTO): Chief Technical Officer, Chief Software Officer, Head of Development, Technology Strategy Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Technology",
                    "position_descriptor": "The Senior Director guides software development efforts. They lead development teams, manage software projects, and collaborate with stakeholders to deliver innovative and high-quality software solutions.",
                    "related_job_titles": "Senior Director - Technology: Development Strategy Director, Senior Software Director, Director of Engineering, Technical Leadership Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Technology",
                    "position_descriptor": "The Director manages software development projects and teams. They oversee software architecture, development methodologies, and ensure successful project delivery within established timelines.",
                    "related_job_titles": "Director - Technology: Development Operations Director, Software Solutions Director, Chief Development Officer (CDO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager- Technology",
                    "position_descriptor": "The Senior Manager coordinates software development activities. They lead development teams, provide technical guidance, and contribute to the design and implementation of software solutions.",
                    "related_job_titles": "Senior Manager - Technology: Senior Development Manager, Software Solutions Manager, Senior DevOps Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager -Technology",
                    "position_descriptor": "The Manager supports software development initiatives. They manage development projects, collaborate with cross-functional teams, and ensure the successful delivery of software products.",
                    "related_job_titles": "Manager - Technology: Development Operations Manager, Software Implementation Manager, Application Development Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Technology",
                    "position_descriptor": "The Team Leader supervises software development professionals. They guide development activities, provide technical mentorship, and contribute to software architecture decisions.",
                    "related_job_titles": "Team Leader (Professionals) - Technology: Development Team Lead, Software Team Supervisor, Technical Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Technology",
                    "position_descriptor": "The Specialist provides expertise in specific software domains. They design and develop complex software solutions, collaborate. ",
                    "related_job_titles": "Specialist Professional - Technology: Software Expert, Development Specialist, Technical Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Technology",
                    "position_descriptor": "The Senior Professional supports software development efforts. They design and develop software components, participate in code reviews, and contribute to technical design decisions.",
                    "related_job_titles": "Senior Professional - Technology: Senior Developer, Lead Software Engineer, Principal Technologist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Experienced Professional - Technology",
                    "position_descriptor": "The Experienced Professional contributes to software development projects. They develop software features, participate in testing, and collaborate with teams to ensure software quality.",
                    "related_job_titles": "Experienced Professional - Technology: Development Solutions Specialist, Technical Project Coordinator, Senior Software Programmer"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Technology",
                    "position_descriptor": "The Professional assists in software development activities. They develop and maintain software code, contribute to software testing, and support the implementation of software solutions.",
                    "related_job_titles": "Professional - Technology: Software Developer, Application Engineer, Technical Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Technology",
                    "position_descriptor": "The Entry-level Professional gains exposure to software development processes. They assist in software coding, participate in testing activities, and support software documentation efforts.",
                    "related_job_titles": "Entry Professional - Technology: Junior Developer, Trainee Programmer, Software Development Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Technology",
                    "position_descriptor": "The Support Associate provides administrative assistance to development teams. They manage documentation, assist in coordinating development activities, and support operational tasks.",
                    "related_job_titles": "Support Associate - Technology: Development Operations Associate, Technical Support Assistant, Software Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Technology",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the software development division. They manage office tasks, coordinate meetings, and assist in maintaining efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Technology: Development Office Assistant, Technical Admin Support"
                }
            ]
        },
        "Data Science and Analytics": {
            "job_family_descriptor": "This job family focuses on roles related to data analysis, data interpretation, and deriving insights from data in a tech organisation. It includes positions that involve data modeling, statistical analysis, machine learning, and data visualization.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Data Officer (CDO)",
                    "position_descriptor": "The Chief leads the data science and analytics division in a Technology Organisation. They define the organization's data strategy, oversee data initiatives, and drive data-driven decision-making to achieve business goals.",
                    "related_job_titles": "Chief Data Officer (CDO): Chief Analytics Officer, Chief Data Strategist, Data Science Executive"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - Data Science and Analytics",
                    "position_descriptor": "The Senior Director guides data science efforts. They lead data teams, manage data projects, and collaborate with stakeholders to deliver actionable insights and data-driven solutions.",
                    "related_job_titles": "Senior Director - Technology: Data Strategy Director, Senior Analytics Director, Director of Data Science, Analytics Leadership Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - Data Science and Analytics",
                    "position_descriptor": "The Director manages data analysis projects and teams. They oversee data modeling, analysis methodologies, and ensure the successful delivery of data-driven insights.",
                    "related_job_titles": "Director - Technology: Data Operations Director, Analytics Solutions Director, Chief Data Scientist"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - Data Science and Analytics",
                    "position_descriptor": "The Senior Manager in coordinates data analysis activities. They lead data teams, provide analytical guidance, and contribute to the development of data-driven solutions.",
                    "related_job_titles": "Senior Manager - Technology: Senior Data Science Manager, Analytics Solutions Manager, Senior Data Analyst Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - Data Science and Analytics",
                    "position_descriptor": "The Manager supports data analysis initiatives. They manage data projects, collaborate with cross-functional teams, and ensure the successful delivery of actionable insights.",
                    "related_job_titles": "Manager - Technology: Data Operations Manager, Analytics Implementation Manager, Data Insights Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - Data Science and Analytics",
                    "position_descriptor": "The Team Leader supervises data professionals. They guide data analysis activities, provide analytical mentorship, and contribute to data modeling decisions.",
                    "related_job_titles": "Team Leader (Professionals) - Technology: Data Science Team Lead, Analytics Team Supervisor, Data Insights Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - Data Science and Analytics",
                    "position_descriptor": "The Data Science and Analytics Specialist provides expertise in specific data domains. They conduct advanced data analysis, develop predictive models, and contribute to data-driven innovation.",
                    "related_job_titles": "Specialist Professional - Technology: Data Science Expert, Analytics Specialist, Data Analytics Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - Data Science and Analytics",
                    "position_descriptor": "The Senior Professional supports data analysis efforts. They analyze data, develop statistical models, and provide insights to support data-driven decision-making.",
                    "related_job_titles": "Senior Professional - Technology: Senior Data Scientist, Lead Data Analyst, Principal Data Technologist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - Data Science and Analytics",
                    "position_descriptor": "The Professional assists in data analysis activities. They conduct data exploration, contribute to data modeling efforts, and support the generation of data-driven insights.",
                    "related_job_titles": "Professional - Technology: Data Analyst, Analytics Engineer, Technical Data Specialist"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - Data Science and Analytics",
                    "position_descriptor": "The Entry-level Professional gains exposure to data analysis processes. They assist in data analysis tasks, participate in data exploration, and support data documentation efforts.",
                    "related_job_titles": "Entry Professional - Technology: Junior Data Scientist, Trainee Data Analyst, Data Science Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - Data Science and Analytics",
                    "position_descriptor": "The Support Associate provides administrative assistance to data teams. They manage documentation, assist in coordinating data analysis activities, and support operational tasks.",
                    "related_job_titles": "Support Associate - Technology: Data Operations Associate, Technical Analytics Assistant, Data Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - Data Science and Analytics",
                    "position_descriptor": "The Administrative Assistant offers administrative support to the data science and analytics division. They manage office tasks, coordinate meetings, and assist in maintaining efficient team operations.",
                    "related_job_titles": "Administrative Assistant - Technology: Data Office Assistant, Technical Admin Support"
                }
            ]
        },
        "IT Infrastructure and Operations": {
            "job_family_descriptor": "This job family covers roles related to managing and maintaining technology infrastructure, networks, and IT systems of a tech organisation. It includes positions that ensure the smooth operation of IT services, hardware, and software within an organization.",
            "levels": [
                {
                    "wr_job_level": "WR 13",
                    "survey_position": "Chief Technology Officer (CTO)",
                    "position_descriptor": "The Chief is responsible for shaping and executing the technology strategy of the Technology organization. They oversee the design, implementation, and management of the entire IT infrastructure, ensuring it aligns with business goals. The CTO provides visionary leadership to optimize technology systems, enhance operational efficiency, and drive innovation.",
                    "related_job_titles": "Chief Information Officer (CIO): Chief Information Officer, IT Strategy Executive,Technology Operations Officer"
                },
                {
                    "wr_job_level": "WR 12",
                    "survey_position": "Senior Director - IT Infrastructure and Operations",
                    "position_descriptor": "The Senior Director plays a pivotal role in leading technology operations. They provide strategic direction to IT teams, manage critical technology projects, and ensure the reliability and scalability of infrastructure. The Senior Director collaborates with cross-functional stakeholders to optimize technology solutions and drive continuous improvement.",
                    "related_job_titles": "Senior Director - Technology: IT Strategy Director, Senior IT Operations Director, Director of Infrastructure, Technology Leadership Executive"
                },
                {
                    "wr_job_level": "WR 11",
                    "survey_position": "Director - IT Infrastructure and Operations",
                    "position_descriptor": "The Director is accountable for the effective management of technology systems and infrastructure. They oversee the deployment of technology initiatives, ensure seamless operation of IT services, and lead teams in implementing and maintaining IT solutions that support business operations.",
                    "related_job_titles": "Director - Technology: IT Operations Director, Infrastructure Solutions Director, Chief IT Officer (CITO)"
                },
                {
                    "wr_job_level": "WR 10",
                    "survey_position": "Senior Manager - IT Infrastructure and Operations",
                    "position_descriptor": "The Senior Manager leads IT operations to meet organizational objectives. They manage technology teams, provide technical leadership, and ensure the stability, security, and performance of IT infrastructure. The Senior Manager contributes to strategic planning and manages technology projects.",
                    "related_job_titles": "Senior Manager - Technology: Senior IT Operations Manager, Infrastructure Solutions Manager, Senior IT Manager"
                },
                {
                    "wr_job_level": "WR 09",
                    "survey_position": "Manager - IT Infrastructure and Operations",
                    "position_descriptor": "The Manager is responsible for overseeing the day-to-day management of technology systems and services. They lead teams in deploying, configuring, and maintaining IT assets, and collaborate with various departments to ensure technology meets business needs. The Manager monitors IT performance, resolves issues, and drives operational efficiency.",
                    "related_job_titles": "Manager - Technology: IT Operations Manager, Infrastructure Implementation Manager, IT Infrastructure Manager"
                },
                {
                    "wr_job_level": "WR 08",
                    "survey_position": "Team Leader (Professionals) - IT Infrastructure and Operations",
                    "position_descriptor": "The Team Leader supervises a group of IT professionals. They guide technical activities, mentor team members, and collaborate with other teams to ensure seamless technology operations. The Team Leader contributes to the development and implementation of technology solutions.",
                    "related_job_titles": "Team Leader (Professionals) - Technology: IT Team Lead, Infrastructure Team Supervisor, Technical Infrastructure Lead"
                },
                {
                    "wr_job_level": "WR 07",
                    "survey_position": "Specialist Professional - IT Infrastructure and Operations",
                    "position_descriptor": "The IT Specialist is an expert in specific technology domains. They analyze technology trends, design and implement infrastructure solutions, and optimize technology performance. The Specialist contributes to technology planning, technical documentation, and process improvement.",
                    "related_job_titles": "Specialist Professional - Technology: IT Expert, Infrastructure Specialist, Technical Infrastructure Guru"
                },
                {
                    "wr_job_level": "WR 06",
                    "survey_position": "Senior Professional - IT Infrastructure and Operations",
                    "position_descriptor": "The Senior Professional manages technology systems and services. They lead technology deployment, contribute to technical projects, and provide expertise to support IT initiatives. The Senior Professional ensures the reliability and availability of technology resources.",
                    "related_job_titles": "Senior Professional - Technology: Senior IT Specialist, Lead Infrastructure Engineer, Principal Technologist"
                },
                {
                    "wr_job_level": "WR 05",
                    "survey_position": "Professional - IT Infrastructure and Operations",
                    "position_descriptor": "The IT Professional assists in managing technology systems. They participate in technology deployment, contribute to technical documentation, and provide technical assistance to users. The Professional supports IT projects and operational tasks.",
                    "related_job_titles": "Professional - Technology: IT Support Specialist, Infrastructure Engineer, Technical Operations Analyst"
                },
                {
                    "wr_job_level": "WR 04",
                    "survey_position": "Entry Professional - IT Infrastructure and Operations",
                    "position_descriptor": "The Entry-level Professional in IT gains exposure to technology processes. They assist in technology deployment, provide technical support, and contribute to technical documentation. The Entry Professional supports IT teams in various operational tasks.",
                    "related_job_titles": "Entry Professional - Technology: Junior IT Associate, Trainee IT Technician, IT Intern"
                },
                {
                    "wr_job_level": "WR 03",
                    "survey_position": "Support Associate - IT Infrastructure and Operations",
                    "position_descriptor": "The Support Associate in IT provides administrative and technical assistance to IT teams. They manage documentation, coordinate technology activities, and support IT operations. The Support Associate contributes to maintaining an efficient and organized IT environment.",
                    "related_job_titles": "Support Associate - Technology: IT Operations Associate, Technical Support Assistant, IT Helpdesk Coordinator"
                },
                {
                    "wr_job_level": "WR 02",
                    "survey_position": "Administrative Assistant - IT Infrastructure and Operations",
                    "position_descriptor": "The Administrative Assistant in IT offers administrative support to the technology division. They manage office tasks, coordinate meetings, and provide assistance to ensure the smooth functioning of IT operations.",
                    "related_job_titles": "Administrative Assistant - Technology: IT Office Assistant, Technical Admin Support"
                }
            ]
        }
    }
}