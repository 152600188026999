import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from 'react-hook-form';
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { getNestedError } from "./utils";

import { useSelector } from 'react-redux';
import { onBoardingReadOnly } from "../../redux/app_data";


export default function SelectAutocompleteSingle(props) {
    const { register, control, options, errors, required, watch, fontSize, ...restProps } = props;

    const isError = !!getNestedError(errors, restProps.name);

    // Determine the variant and border style
    const variant = props.variant ? props.variant : 'outlined';
    var borderNone = {};
    if (props.border === false || props.border === "false") {
        borderNone = isError ? {} : {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    border: "none",
                    padding: 0,
                },
            }
        };
    }

    // Determine the background color when disabled
    const backgroundColorWhenDisabled = props.disabled ? { backgroundColor: '#eee' } : {};
    const stt = fontSize ? { fontSize: fontSize ? '12px' : undefined, padding: 0 } : {}
    
    const isReadOnly = useSelector(onBoardingReadOnly);
    return (
        <Controller
            name={restProps.name}
            control={control}
            defaultValue={null}
            // Update the rules based on the disabled prop
            rules={props.disabled ? {} : (required ? { required: 'Value is required!' } : {})}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    isOptionEqualToValue={(option, value) => option.value === value}
                    options={options || []}
                    disabled={props.disabled || isReadOnly}
                    value={value}
                    onChange={(_, newValue) => onChange(newValue ? newValue.value : null)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant={variant}
                            fullWidth
                            error={required && !props.disabled ? isError : false}
                            multiline={props.isMultiline}
                            sx={{ ...borderNone, ...backgroundColorWhenDisabled }}
                            inputProps={{
                                ...params.inputProps,
                                style: stt,
                            }}
                        />
                    )}
                />
            )}
        />
    );
}
