import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import MedicalCover from './MedicalCover';
import PensionProvidentFund from './PensionProvidentFund';
import LifeInsurance from './LifeInsurance';
import { useSelector, useDispatch } from 'react-redux';
import { setInsuranceData } from '../../../../../../redux/onboard/benefits/insurance';
import { toast } from 'react-toastify';


export default function Insurance(props){
    const insurance = useSelector(state => state.insurance)
    const { data } = insurance
    const dispatch = useDispatch()

    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors, isDirty },
    } = useForm({
        
    });

    useEffect(() => {
        reset(data)
    }, [])
    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])

    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    
    }


    const onSubmit = (form_data) => {
        if (isDirty){
            dispatch(setInsuranceData(form_data))
            props.onSaveClick("benefits", "insurance", form_data)
        }
        
        props.changeTab(4)
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <MedicalCover control={control} watch={watch} register={register} errors={errors} />
            <PensionProvidentFund control={control} watch={watch} register={register} errors={errors} />
            <LifeInsurance control={control} watch={watch} register={register} errors={errors} />

            <div className="flex justify-end mt-5 px-2 py-2">
                <button type='submit' className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}