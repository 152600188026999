export const COMMON_JFAll = {
    "General Management ": {
        "job_family_descriptor": "The General Management job family may consist of multiple roles within the organization, taking a generalized approach to management that may include leadership of two or more areas. Job holders provide oversight and strategies for business development and may manage and develop the implementation of general operating policies.",
        "levels": [
            {
                "wr_job_level": "WR 14",
                "survey_position": "Executive I",
                "position_descriptor": " The highest-ranking executive, responsible for setting the organization's strategic direction and ensuring overall company success.",
                "related_job_titles": "Chief Executive Officer, President, Managing Director, CEO & President, Chief Executive"
            },
            {
                "wr_job_level": "WR 13",
                "survey_position": "Executive II",
                "position_descriptor": " Top-level executives with significant authority, overseeing major divisions or functions and contributing to strategic decision-making.",
                "related_job_titles": "CEO, MD, Executive Vice President, Deputy CEO, Senior Vice President "
            },
            {
                "wr_job_level": "WR 12",
                "survey_position": "Executive III",
                "position_descriptor": "Position hold high-level executive positions, managing key areas of the business, and contributing to organizational strategy.",
                "related_job_titles": "Chief Operating Officer (COO), Chief Strategy Officer (CSO), Senior Vice President, Vice President, General Manager"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Director I",
                "position_descriptor": "Leads divisions/directorates which have specific departments, setting divisional goals and strategies, and managing teams within their areas of responsibility.",
                "related_job_titles": "Senior Director,Vice President,  VP of Operations/manufacturing"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director II",
                "position_descriptor": " Directors manage directorates or functional areas, developing and executing strategies while often overseeing budget management.",
                "related_job_titles": "Director, Director of, Division Manager, Director of Finance/HR/Sales"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Manager",
                "position_descriptor": " Senior Managers oversee teams or areas within departments, ensuring alignment with departmental goals and contributing to performance improvement.",
                "related_job_titles": "Senior Manager, Senior Department Manager, Senior Project Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Manager",
                "position_descriptor": " Managers are at the mid level management and lead teams or departments with operational responsibilities, setting goals and driving performance.",
                "related_job_titles": "Manager, Department Manager, Project Manager, Operations Manager, Sales Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Section Heads",
                "position_descriptor": " Supervisors oversee small teams, ensuring efficient task completion and providing guidance and support to team members.",
                "related_job_titles": "Section lead, Supervisor, Department Supervisor, Team Supervisor, Frontline Supervisor, Shift Supervisor (Profesionals)"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Team Leader / Unit Heads",
                "position_descriptor": " Team Leaders coordinate and lead teams, assign tasks, and ensure goal achievement within workgroups.",
                "related_job_titles": "Unit Lead, Team Leader, Group Leader, Lead Supervisor, Team Coordinator"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Operational / Process Supervisor",
                "position_descriptor": "Supervisors responsible for overseeing the day-to-day activities within their operational areas, ensuring that processes are running efficiently and that teams are meeting their performance goals. ",
                "related_job_titles": "Team Leader, Shift Supervisor, Production Supervisor, Manufacturing Supervisor, Logistics Supervisor, Quality Control Supervisor, Warehouse Supervisor, Facilities Supervisor"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Management Trainee ",
                "position_descriptor": " These are Entry-level management trainees or assistants provide support to teams and managers, gaining experience and learning management processes.",
                "related_job_titles": "Management Trainee, Management Assistant"
            }
        ]
    },
    "Human Resource": {
        "job_family_descriptor": "The Human Resources job family is responsible for the administration and management of all aspects of the human resources function within an organization. This includes employee recruitment and selection, employee benefits and compensation, employee relations and communications, and employee training and development.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " HR Leadership and Management Executive, HR Strategy Leadership, Chief HR Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of HR Leadership, Vice President of HR Management, HR Leadership VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of HR Leadership, HR Management Director, HR Management Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior HR Manager, Senior HR Leadership Manager, Lead HR Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " HR Manager, HR Leadership Manager, HR Management Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " HR Leadership Specialist, HR Management Specialist, HR Leadership Business Partner"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " HR Leadership Coordinator, HR Management Coordinator, HR Leadership Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " HR Leadership Associate, HR Management Associate, HR Leadership Support Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " HR Leadership Assistant, HR Management Assistant,"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "HR Admin Clerks",
                "position_descriptor": "Not full qualified HR Assistants who provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": "HR Clerk, Interns"
            }
        ]
    },
    "Human Resource | Recruitment & Talent Acquisition": {
        "job_family_descriptor": " This job family is responsible for identifying, attracting, and hiring qualified individuals to fill vacant positions within the organization. Roles within this family include recruiters, talent acquisition specialists, and sourcers.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Recruitment Strategy Executive, Talent Acquisition Leadership, Head of Recruitment and Talent Acquisition"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Talent Acquisition, Vice President of Recruitment, Talent Acquisition Vice President"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Talent Acquisition, Recruitment Director, HR Recruitment Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Talent Acquisition Manager, Senior Recruitment Manager, Lead Talent Acquisition Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Talent Acquisition Manager, Recruitment Manager, HR Recruitment Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Talent Acquisition Business Partner, Recruitment Specialist, HR Talent Sourcing Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Recruitment Coordinator, Talent Acquisition Coordinator, HR Recruitment Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Talent Acquisition Associate, Recruitment Associate, HR Sourcing Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Recruitment Assistant, Talent Acquisition Assistant, HR Recruitment Intern"
            }
        ]
    },
    "Human Resource | Compensation & Benefits": {
        "job_family_descriptor": " Jobs within this family deal with designing and administering compensation packages, including salaries, bonuses, and benefits such as healthcare and retirement plans. Compensation analysts, benefits administrators, and total rewards specialists fall under this category.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Compensation and Benefits Executive, Total Rewards Leadership, Chief Compensation Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Compensation and Benefits, Vice President of Total Rewards, Compensation and Benefits VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Compensation, Benefits Director, Total Rewards Director"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Compensation Manager, Senior Benefits Manager, Total Rewards Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Compensation Manager, Benefits Manager, Total Rewards Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Compensation Specialist, Benefits Specialist, Total Rewards Business Partner"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Compensation Coordinator, Benefits Coordinator, Total Rewards Coordinator"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Compensation Associate, Benefits Associate, Total Rewards Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Compensation Assistant, Benefits Assistant, Total Rewards Intern"
            }
        ]
    },
    "Human Resource | Employee Relations & Engagement": {
        "job_family_descriptor": " This job family focuses on fostering positive relationships between employees and the organization. Employee relations specialists handle conflict resolution, employee engagement initiatives, and maintain a healthy work environment.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Employee Experience Executive, Workplace Relations Leadership, Chief Employee Engagement Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Employee Relations, Vice President of Employee Engagement, Employee Experience VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Employee Relations, Employee Engagement Director, Workplace Relations Director"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Employee Relations Manager, Senior Employee Engagement Manager, Employee Experience Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Employee Relations Manager, Employee Engagement Manager, HR Engagement Specialist"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Employee Relations Specialist, Employee Engagement Specialist, Workplace Relations Business Partner"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Employee Relations Coordinator, Employee Engagement Coordinator, Workplace Relations Coordinator"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Employee Relations Associate, Employee Engagement Associate, Workplace Relations Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Employee Relations Assistant, Employee Engagement Assistant, Workplace Relations Intern"
            }
        ]
    },
    "Human Resource | Training & Development": {
        "job_family_descriptor": " Jobs in this family involve designing and delivering training programs to enhance employees' skills and knowledge. Training managers, instructional designers, and learning and development specialists are part of this category.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Learning and Development Executive, Training Strategy Leadership, Chief Learning Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Training and Development, Vice President of Learning, Training and Development VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Training and Development, Learning Director, HR Training Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Learning and Development Manager, Senior Training Manager, Lead Training Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Learning and Development Manager, Training Manager, HR Training Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Learning and Development Specialist, Training Business Partner, HR Learning Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Training Coordinator, Learning and Development Coordinator, HR Training Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Learning and Development Associate, Training Associate, HR Learning Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Training Assistant, Learning and Development Assistant, HR Training Intern"
            }
        ]
    },
    "Human Resource | Performance Management": {
        "job_family_descriptor": " Roles within this family revolve around evaluating and improving employee performance. Performance managers, performance analysts, and performance consultants help set goals, provide feedback, and manage performance appraisal processes.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Performance Management Executive, Strategic Performance Leadership, Chief Performance Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Performance Management, Vice President of Performance, Performance Management VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Performance Management, Performance Director, HR Performance Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Performance Management Manager, Senior Performance Manager, Lead Performance Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Performance Management Manager, Performance Manager, HR Performance Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Performance Management Specialist, Performance Business Partner, HR Performance Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Performance Management Coordinator, Performance Coordinator, HR Performance Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Performance Management Associate, Performance Associate, HR Performance Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Performance Management Assistant, Performance Assistant, HR Performance Intern"
            }
        ]
    },
    "Human Resource | Organizational Development": {
        "job_family_descriptor": " This job family is concerned with enhancing the overall effectiveness and health of the organization. Organizational development specialists and change management experts focus on implementing strategic changes, fostering cultural alignment, and improving organizational processes.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Organizational Development Executive, Strategic Development Leadership, Chief Development Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Organizational Development, Vice President of Development, Organizational Development VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Organizational Development, Development Director, HR Development Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Organizational Development Manager, Senior Development Manager, Lead Development Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Organizational Development Manager, Development Manager, HR Development Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Organizational Development Specialist, Development Business Partner, HR Development Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Organizational Development Coordinator, Development Coordinator, HR Development Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Organizational Development Associate, Development Associate, HR Development Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Organizational Development Assistant, Development Assistant, HR Development Intern"
            }
        ]
    },
    "Human Resource | HR Analytics & Data Management": {
        "job_family_descriptor": " In this family, professionals utilize data and analytics to make informed HR decisions. HR data analysts and workforce planners collect, analyze, and interpret HR data to provide insights and support decision-making.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " HR Analytics Executive, Data Management Leadership, Chief HR Analytics Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of HR Analytics, Vice President of Data Management, HR Analytics VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of HR Analytics, Data Management Director, HR Data Analytics Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior HR Analytics Manager, Senior Data Management Manager, Lead HR Analytics Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " HR Analytics Manager, Data Management Manager, HR Data Analytics Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " HR Analytics Specialist, Data Management Business Partner, HR Data Analytics Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " HR Data Coordinator, Analytics Coordinator, HR Data Management Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " HR Analytics Associate, Data Management Associate, HR Data Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " HR Data Assistant, Analytics Assistant, HR Data Intern"
            }
        ]
    },
    "Human Resource | HR Information Systems (HRIS)": {
        "job_family_descriptor": " Jobs within this family involve managing and maintaining HRIS platforms and technology solutions that support various HR functions. HRIS analysts, system administrators, and HR technology specialists are part of this category.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " HRIS Executive, HRIS Leadership, Chief HRIS Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of HR Information Systems, Vice President of HRIS, HRIS VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of HRIS, HRIS Director, HR HRIS Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior HRIS Manager, Senior HR Systems Manager, Lead HRIS Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " HRIS Manager, HR Systems Manager, HR HRIS Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " HRIS Specialist, HR Systems Business Partner, HR HRIS Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " HRIS Coordinator, HR Systems Coordinator, HR HRIS Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " HRIS Associate, HR Systems Associate, HR HRIS Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " HRIS Assistant, HR Systems Assistant, HR HRIS Intern"
            }
        ]
    },
    "Human Resource | Diversity & Inclusion": {
        "job_family_descriptor": " This job family concentrates on promoting diversity, equity, and inclusion within the workplace. Diversity officers, inclusion managers, and equity coordinators work to create inclusive environments and policies.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Diversity and Inclusion Executive, Inclusion Leadership, Chief Diversity Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Diversity and Inclusion, Vice President of Inclusion, Diversity and Inclusion VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Diversity and Inclusion, Inclusion Director, HR Diversity Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Diversity and Inclusion Manager, Senior Inclusion Manager, Lead Diversity Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Diversity and Inclusion Manager, Inclusion Manager, HR Diversity Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Diversity Specialist, Inclusion Business Partner, HR Diversity Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Diversity Coordinator, Inclusion Coordinator, HR Diversity Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Diversity Associate, Inclusion Associate, HR Diversity Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Diversity Assistant, Inclusion Assistant, HR Diversity Intern"
            }
        ]
    },
    "Human Resource | Labor Relations": {
        "job_family_descriptor": " Roles in this family deal with managing relationships between the organization and labor unions. Labor relations specialists, negotiators, and union representatives work to ensure compliance with labor laws and maintain positive labor relations.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " Labor Relations Executive, Industrial Relations Leadership, Chief Labor Relations Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of Labor Relations, Vice President of Industrial Relations, Labor Relations VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of Labor Relations, Industrial Relations Director, HR Labor Relations Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior Labor Relations Manager, Senior Industrial Relations Manager, Lead Labor Relations Specialist"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " Labor Relations Manager, Industrial Relations Manager, HR Labor Relations Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " Labor Relations Specialist, Industrial Relations Business Partner, HR Labor Relations Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " Labor Relations Coordinator, Industrial Relations Coordinator, HR Labor Relations Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " Labor Relations Associate, Industrial Relations Associate, HR Labor Relations Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " Labor Relations Assistant, Industrial Relations Assistant, HR Labor Relations Intern"
            }
        ]
    },
    "Human Resource | HR Business Partnering": {
        "job_family_descriptor": " HR business partners act as liaisons between HR and other departments within the organization. They provide HR expertise, support business goals, and help align HR strategies with overall organizational strategies.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Human Resources Officer (CHRO) / Chief People Officer",
                "position_descriptor": "This top-level executive is responsible for developing and executing the organization's HR strategies and initiatives. They collaborate closely with senior leadership to align HR practices with overall business goals. The CHRO oversees all aspects of HR, including talent management, compensation, benefits, employee engagement, and organizational development. They provide leadership in shaping the organizational culture and ensuring that HR practices support the company's long-term success.",
                "related_job_titles": " HR Business Partnering Executive, Strategic HR Partnership Leadership, Chief HR Business Partner"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Human Resources",
                "position_descriptor": "The Vice President of Human Resources is a strategic leader who directs the overall HR function. They work closely with executive management to define HR strategies that drive organizational success. This role involves overseeing HR departments, guiding HR managers, and ensuring that HR policies and programs align with business objectives.",
                "related_job_titles": " VP of HR Business Partnering, Vice President of HR Partnership, HR Business Partnering VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Human Resources",
                "position_descriptor": "Directors of Human Resources manage and lead HR teams to implement HR policies, programs, and initiatives. They play a key role in driving employee engagement, managing talent acquisition, and fostering positive workplace relations. Directors collaborate with senior management to develop and execute HR strategies that contribute to the organization's growth and effectiveness.",
                "related_job_titles": " Director of HR Business Partnering, HR Partnership Director, HR Partnership Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior HR Manager",
                "position_descriptor": "Senior HR Managers are responsible for supervising HR functions and teams within specific areas such as talent management, employee relations, or compensation. They provide strategic guidance, drive HR projects, and ensure the effective execution of HR policies to enhance overall organizational performance.",
                "related_job_titles": " Senior HR Business Partner, Senior Partnership Manager, Lead HR Business Partner"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "HR Manager",
                "position_descriptor": "HR Managers oversee the daily operations of HR departments. They manage employee relations, recruitment, performance management, and compliance with labor laws. HR Managers work closely with senior leadership to align HR efforts with organizational objectives.",
                "related_job_titles": " HR Business Partner, Partnership Manager, HR Partnership Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "HR Business Partner / Specialist",
                "position_descriptor": "HR Business Partners/Specialists collaborate with business units to provide HR expertise and support. They facilitate effective communication between HR and operational teams, implement HR programs, and address employee needs. These roles contribute to strategic decision-making and help ensure the organization's HR practices are aligned with business goals.",
                "related_job_titles": " HR Partnership Specialist, Strategic HR Business Partner, HR Partnership Business Partner"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "HR Coordinator / Generalist",
                "position_descriptor": "HR Coordinators/Generalists handle various HR tasks, including recruitment coordination, onboarding, data maintenance, and policy administration. They provide essential support to HR teams, contributing to the smooth functioning of HR operations and ensuring compliance with HR procedures.",
                "related_job_titles": " HR Partnership Coordinator, HR Business Partner Coordinator, HR Business Partnering Generalist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "HR Associate / Officer",
                "position_descriptor": "HR Associates perform specific HR tasks, such as benefits administration, data entry, and HRIS management. They work closely with HR professionals to maintain accurate records and support HR functions within their designated areas.",
                "related_job_titles": " HR Business Partnering Associate, HR Partnership Associate, HR Partnership Support Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "HR Assistant ",
                "position_descriptor": "HR Assistants/Entry-Level roles provide administrative support to the HR team. They assist with tasks such as scheduling interviews, managing documentation, responding to inquiries, and supporting HR projects. These roles contribute to the efficient functioning of the HR department.",
                "related_job_titles": " HR Business Partnering Assistant, HR Partnership Assistant, HR Business Partnering Intern"
            }
        ]
    },
    "Finance ": {
        "job_family_descriptor": "The Finance job family manages the financial activities of an organization or business via financial planning, accounting, collections/credits, financial analyses, audits, and tax information. This general financial operation family encompasses roles related to the day-to-day financial activities of an organization, such as accounts payable, accounts receivable, payroll, and financial reporting.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Financial Operations Executive, Strategic Operations Officer, Chief Financial Operations Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Financial Operations, Vice President of Operations, Financial Operations VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Financial Operations, Operations Director, Finance Operations Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Operations Manager, Senior Financial Operations Analyst, Lead Financial Operations Analyst"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Operations Manager, Financial Operations Analyst, Financial Operations Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Financial Operations Specialist, Operations Business Partner, Strategic Operations Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Financial Operations Analyst, Operations Coordinator, Budget Operations Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Financial Operations Associate, Operations Analyst Associate, Junior Operations Analyst"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Financial Operations Intern, Operations Analyst Assistant, Junior Financial Operations Assistant"
            }
        ]
    },
    "Finance | Accounting": {
        "job_family_descriptor": " Involves recording, summarizing, and analyzing financial transactions of an organization, and preparing financial statements and reports for both internal and external stakeholders.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Accounting Executive, Strategic Accountant, Chief Accounting Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Accounting, Vice President of Financial Accounting, Accounting VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Accounting, Financial Accounting Director, Finance Accounting Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Accounting Manager, Senior Accountant, Lead Financial Accountant"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Accounting Manager, Financial Accountant, Financial Accounting Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Accounting Specialist, Financial Accounting Business Partner, Strategic Account Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Financial Accountant, Financial Accounting Coordinator, Budget Accounting Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Accounting Associate, Financial Accountant Associate, Junior Financial Accountant"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Accounting Intern, Financial Accounting Assistant, Junior Accounting Assistant"
            }
        ]
    },
    "Finance | Financial Analysis": {
        "job_family_descriptor": " Involves analyzing financial data and performance to provide insights and recommendations for decision-making. This can include roles like financial analyst, business analyst, or data analyst.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Financial Analysis Executive, Strategic Financial Analyst, Chief Financial Analysis Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Financial Analysis, Vice President of Financial Planning, Financial Analysis VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Financial Analysis, Financial Analysis Director, Finance Analysis Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Financial Analysis Manager, Senior Financial Analyst, Lead Financial Analyst"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Financial Analysis Manager, Finance Analyst Manager, Financial Analytics Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Financial Analysis Specialist, Finance Analysis Business Partner, Strategic Finance Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Financial Analyst, Finance Analysis Coordinator, Budget Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Financial Analysis Associate, Finance Analyst Associate, Financial Data Assistant"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Finance Analysis Intern, Financial Analyst Assistant, Junior Financial Analyst"
            }
        ]
    },
    "Finance | Risk Management": {
        "job_family_descriptor": " Entails assessing and mitigating financial risks, including credit risk, market risk, operational risk, and compliance risk.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Risk Management Executive, Strategic Risk Officer, Chief Risk Management Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Risk Management, Vice President of Risk Analysis, Risk Management VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Risk Management, Risk Management Director, Finance Risk Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Risk Manager, Senior Risk Analyst, Lead Risk Analyst"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Risk Manager, Risk Analysis Manager, Risk Management Specialist"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Risk Management Specialist, Risk Analysis Business Partner, Strategic Risk Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Risk Analyst, Risk Analysis Coordinator, Risk Assessment Coordinator"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Risk Associate, Risk Analysis Associate, Junior Risk Analyst"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Risk Management Intern, Risk Analysis Assistant, Junior Risk Assistant"
            }
        ]
    },
    "Finance | Treasury Management": {
        "job_family_descriptor": " Involves managing an organization's cash flow, liquidity, and financial assets to ensure efficient financial operations.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Treasury Management Executive, Strategic Treasury Officer, Chief Treasury Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Treasury Management, Vice President of Treasury, Treasury Management VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Treasury Management, Treasury Management Director, Finance Treasury Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Treasury Manager, Senior Treasury Analyst, Lead Treasury Analyst"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Treasury Manager, Treasury Analysis Manager, Treasury Management Specialist"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Treasury Management Specialist, Treasury Analysis Business Partner, Strategic Treasury Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Treasury Analyst, Treasury Analysis Coordinator, Cash Management Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Treasury Associate, Treasury Analysis Associate, Junior Treasury Analyst"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Treasury Management Intern, Treasury Analysis Assistant, Junior Treasury Assistant"
            }
        ]
    },
    "Finance | Financial Planning and Analysis": {
        "job_family_descriptor": " Focuses on forecasting, budgeting, and analyzing financial performance to aid in strategic planning and decision-making.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Financial Planning Executive, Strategic Financial Planner, Chief Financial Planning Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Financial Planning, Vice President of Financial Strategy, Financial Planning VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Financial Planning, Financial Analysis and Planning Director, Finance Planning Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Financial Planning Manager, Senior Financial Analyst and Planner, Lead Financial Planner"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Financial Planning Manager, Financial Analysis and Planning Manager, Financial Strategy Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Financial Planning Specialist, Financial Strategy Business Partner, Strategic Financial Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Financial Analyst and Planner, Financial Analysis Coordinator, Budget Planning Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Financial Planning Associate, Financial Analysis and Planning Associate, Junior Financial Planner"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Financial Planning Intern, Financial Analysis and Planning Assistant, Junior Financial Analyst"
            }
        ]
    },
    "Finance | Tax Accounting": {
        "job_family_descriptor": " Encompasses managing an organization's tax obligations, ensuring compliance with tax laws, and optimizing tax strategies.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Taxation Executive, Strategic Tax Officer, Chief Taxation Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Taxation, Vice President of Tax Strategy, Taxation VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Taxation, Tax Strategy Director, Finance Taxation Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Tax Manager, Senior Tax Analyst, Lead Tax Analyst"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Tax Manager, Tax Analyst, Tax Strategy Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Taxation Specialist, Tax Strategy Business Partner, Strategic Tax Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Tax Analyst, Tax Strategy Coordinator, Budget Tax Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Assistants or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Assistants/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Taxation Associate, Tax Analyst Associate, Junior Tax Analyst"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Taxation Intern, Tax Analyst Assistant, Junior Tax Assistant"
            }
        ]
    },
    "Finance | General Internal Audit": {
        "job_family_descriptor": " Involves evaluating and ensuring the effectiveness of an organization's internal controls, risk management processes, and compliance with regulations.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Internal Audit Executive, Strategic Auditor, Chief Audit Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Internal Audit, Vice President of Auditing, Internal Audit VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Internal Audit, Audit Director, Finance Audit Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior Audit Manager, Senior Auditor, Lead Internal Auditor"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " Audit Manager, Internal Auditor, Internal Audit Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Internal Audit Specialist, Auditing Business Partner, Strategic Auditor"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Internal Auditor, Auditing Coordinator, Budget Auditor"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officers or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Officers/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Internal Audit Associate, Auditor Associate, Junior Internal Auditor"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Internal Audit Intern, Auditor Assistant, Junior Audit Assistant"
            }
        ]
    },
    "Finance | Financial Technology (FinTech)": {
        "job_family_descriptor": " Involves developing and implementing innovative technologies to enhance financial services, including mobile payments, digital banking, and blockchain solutions.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Financial Officer (CFO)",
                "position_descriptor": " At this level, the Chief Financial Officer (CFO) holds the highest position within the finance department and is responsible for overseeing the organization's financial strategy, financial operations, and overall financial health. They collaborate with the executive team to make strategic decisions, manage financial risks, drive financial growth, and ensure regulatory compliance. The CFO provides leadership to finance teams, presents financial performance to the board of directors, and plays a key role in shaping the company's financial future.",
                "related_job_titles": " Financial Technology Executive, Strategic FinTech Officer, Chief Financial Technology Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Finance",
                "position_descriptor": " The Vice President of Finance leads a significant segment of the finance department, overseeing multiple financial functions such as accounting, budgeting, financial analysis, and treasury. They contribute to financial planning, strategy development, and decision-making. This role involves managing financial reporting, providing insights to optimize performance, and collaborating with senior leadership to achieve financial goals. The Vice President of Finance may represent the CFO in certain contexts and drive financial initiatives.",
                "related_job_titles": " VP of Financial Technology, Vice President of FinTech, FinTech VP"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Finance",
                "position_descriptor": " Directors of Finance lead specific areas within the finance department, such as financial planning and analysis, treasury management, or internal audit. They manage teams, analyze financial data, and provide recommendations to improve financial performance and efficiency. Directors of Finance work closely with cross-functional teams to align financial strategies with business objectives, implement financial policies, and contribute to long-term financial planning.",
                "related_job_titles": " Director of Financial Technology, FinTech Director, Finance FinTech Lead"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Finance Manager",
                "position_descriptor": " Senior Finance Managers oversee critical finance functions, such as budgeting, forecasting, or financial reporting. They lead teams responsible for analyzing financial data, identifying trends, and providing actionable insights to senior management. Senior Finance Managers collaborate with business leaders to align financial objectives, evaluate investment opportunities, manage financial risks, and ensure accurate financial reporting.",
                "related_job_titles": " Senior FinTech Manager, Senior Financial Technology Analyst, Lead FinTech Analyst"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Finance Manager",
                "position_descriptor": " Finance Managers manage day-to-day financial operations, including budgeting, financial analysis, and reporting. They supervise finance staff, oversee financial transactions, monitor financial performance against targets, and recommend strategies to enhance financial efficiency. Finance Managers also collaborate with departments across the organization to support financial decision-making and maintain financial controls.",
                "related_job_titles": " FinTech Manager, Financial Technology Analyst, FinTech Lead"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Finance Business Partner / Specialist",
                "position_descriptor": " Finance Business Partners or Specialists work closely with specific business units, providing financial insights, analysis, and support to drive decision-making. They assist in budgeting, forecasting, and financial modeling for their assigned areas. Finance Business Partners/Specialists collaborate with operational teams, identifying cost-saving opportunities, analyzing financial performance, and ensuring alignment between financial goals and business objectives.",
                "related_job_titles": " Financial Technology Specialist, FinTech Business Partner, Strategic FinTech Analyst"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Finance Analyst / Coordinator",
                "position_descriptor": " Finance Analysts or Coordinators support financial planning, analysis, and reporting activities. They gather and analyze financial data, create reports, and assist in budget preparation. Finance Analysts/Coordinators help identify financial trends, variances, and performance metrics. They play a crucial role in maintaining accurate financial records, performing reconciliations, and contributing to financial projects.",
                "related_job_titles": " Financial Technology Analyst, FinTech Coordinator, Budget FinTech Analyst"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Financial Officer/ Associate",
                "position_descriptor": " Financial Officer or Associates provide administrative support to the finance department, performing tasks such as data entry, invoice processing, and basic financial calculations. They help maintain financial records, assist with expense tracking, and support routine financial transactions. Financial Assistants/Associates contribute to the overall efficiency of the finance team by handling essential administrative responsibilities.",
                "related_job_titles": " Financial Technology Associate, FinTech Analyst Associate, Junior FinTech Analyst"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Finance Assistant",
                "position_descriptor": " Entry-level positions within the finance department are typically filled by interns or assistants. These individuals support various finance functions, including data entry, basic analysis, and clerical tasks. They gain exposure to finance processes, assist in maintaining financial records, and contribute to the day-to-day operations of the finance team.",
                "related_job_titles": " Financial Technology Intern, FinTech Analyst Assistant, Junior Financial Technology Assistant"
            }
        ]
    },
    "Customer Service and Support (General)": {
        "job_family_descriptor": "The Customer Service job family is responsible for providing customer service to internal and external customers. This includes responding to customer inquiries, resolving customer complaints, and providing information about products and services.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Customer Experience Officer",
                "position_descriptor": " At the highest level, the Chief Customer Experience Officer is responsible for developing and executing the organization's customer service and support strategy. They lead and oversee all customer service functions, ensuring exceptional customer experiences, satisfaction, and loyalty. The CCXO collaborates with senior leadership to align customer service initiatives with business objectives. They also analyze customer feedback, identify areas for improvement, and drive the organization's customer-centric culture.",
                "related_job_titles": " Chief Customer Satisfaction Officer, Customer Experience Executive"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Customer Service",
                "position_descriptor": " Vice Presidents of Customer Service lead a substantial portion of the customer service and support function. They design and implement customer service strategies, policies, and standards. This role involves managing multiple customer service teams, monitoring customer satisfaction metrics, and overseeing the resolution of complex customer issues. The Vice President of Customer Service collaborates with other departments to enhance the overall customer experience.",
                "related_job_titles": " VP of Client Services, Vice President of Customer Support, Senior Director Customer Care, Vice President of Support Services"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Customer Support",
                "position_descriptor": " Directors of Customer Support manage specific areas within the customer service function, such as technical support, helpdesk, or customer care. They lead teams responsible for providing timely and effective solutions to customer inquiries and issues. Directors of Customer Support collaborate with cross-functional teams to ensure alignment with business goals, implement customer service best practices, and drive continuous improvement.",
                "related_job_titles": "Customer Support Director, Director of Client Services, Client Services Director, Director of Customer Support"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Customer Service Manager",
                "position_descriptor": " Senior Customer Service Managers oversee critical customer service activities and teams. They monitor customer satisfaction metrics, analyze customer feedback, and identify areas for improvement. Senior Customer Service Managers develop and implement customer service strategies, manage customer service representatives, and ensure consistent delivery of high-quality customer support.",
                "related_job_titles": " Senior Client Services Manager, Senior Customer Support Manager, Senior Manager of Customer Support, Senior Manager of Client Services"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Customer Service Manager",
                "position_descriptor": " Customer Service Managers manage day-to-day customer service operations. They lead customer service teams, handle escalations, and monitor performance metrics. Customer Service Managers ensure that customer inquiries are resolved promptly and effectively, collaborate with other departments, and contribute to the development of customer service policies and procedures.",
                "related_job_titles": " Client Services Manager, Customer Support Manager, Manager of Customer Support, Manager of Client Services"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Customer Service Team Lead / Supervisor",
                "position_descriptor": " Customer Service Team Leads or Supervisors provide direct leadership to customer service representatives. They assist in resolving complex customer inquiries, provide coaching and training, and monitor team performance. Customer Service Team Leads/Supervisors play a key role in ensuring a positive customer experience and facilitating communication between their team and management.",
                "related_job_titles": " Client Services Team Lead, Customer Support Supervisor, Supervisor of Client Services, Customer Service Team Supervisor"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Customer Service Representative / Specialist",
                "position_descriptor": " Customer Service Representatives or Specialists directly interact with customers to address inquiries, resolve issues, and provide support. They maintain accurate records of customer interactions, offer product or service information, and contribute to maintaining a high level of customer satisfaction. Customer Service Representatives/Specialists handle a variety of customer-related tasks.",
                "related_job_titles": " Client Services Representative, Customer Support Specialist, Specialist in Customer Support, Customer Experience Specialist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Customer Service Coordinator / Associate",
                "position_descriptor": " Customer Service Coordinators or Associates provide administrative and operational support to customer service teams. They assist in organizing customer inquiries, maintain customer-related records, and ensure smooth communication between customers and representatives. Customer Service Coordinators/Associates play a role in optimizing customer service processes.",
                "related_job_titles": " Client Services Coordinator, Customer Support Associate, Associate in Customer Support, Customer Experience Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Customer Service Assistant I",
                "position_descriptor": "At this level, the incumbent is experienced at this role and tasks involves handling complex customer inquiries, resolving escalated issues, and providing guidance to lower-level assistants.",
                "related_job_titles": "Customer Support Assistant, Customer Care Assistant, Customer Experience Assistant"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Customer Service Assistant II",
                "position_descriptor": "At this Level,  this is an intermediate-level position with responsibilities such as addressing moderately complex customer queries, processing orders, and providing product/service information.",
                "related_job_titles": "Customer Support Assistant, Assistant in Customer Support, Customer Care Repressentative"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Customer Service Assistant III",
                "position_descriptor": "An entry-level position focused on basic customer service tasks often working under close supervision",
                "related_job_titles": " Client Services Intern, Customer Support Assistant"
            }
        ]
    },
    "Customer Service | Call Center Operations": {
        "job_family_descriptor": "The Call Center job family provides customer service and solutions via phone, text or online chat. They respond to questions, queries, and inbound support issues related to a product or service. They may also call or text customers to update them on additional services and products.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Customer Experience Officer",
                "position_descriptor": " At the highest level, the Chief Customer Experience Officer is responsible for developing and executing the organization's customer service and support strategy. They lead and oversee all customer service functions, ensuring exceptional customer experiences, satisfaction, and loyalty. The CCXO collaborates with senior leadership to align customer service initiatives with business objectives. They also analyze customer feedback, identify areas for improvement, and drive the organization's customer-centric culture.",
                "related_job_titles": " Chief Call Center Officer, Call Center Experience Executive"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Customer Service",
                "position_descriptor": " Vice Presidents of Customer Service lead a substantial portion of the customer service and support function. They design and implement customer service strategies, policies, and standards. This role involves managing multiple customer service teams, monitoring customer satisfaction metrics, and overseeing the resolution of complex customer issues. The Vice President of Customer Service collaborates with other departments to enhance the overall customer experience.",
                "related_job_titles": " VP of Call Center Services, Vice President of Call Center Management, VP of Call Center Support, Vice President of Call Center Strategy"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Customer Support",
                "position_descriptor": " Directors of Customer Support manage specific areas within the customer service function, such as technical support, helpdesk, or customer care. They lead teams responsible for providing timely and effective solutions to customer inquiries and issues. Directors of Customer Support collaborate with cross-functional teams to ensure alignment with business goals, implement customer service best practices, and drive continuous improvement.",
                "related_job_titles": " Call Center Support Director, Director of Call Center Services, Call Center Services Director, Director of Call Center Support"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Customer Service Manager",
                "position_descriptor": " Senior Customer Service Managers oversee critical customer service activities and teams. They monitor customer satisfaction metrics, analyze customer feedback, and identify areas for improvement. Senior Customer Service Managers develop and implement customer service strategies, manage customer service representatives, and ensure consistent delivery of high-quality customer support.",
                "related_job_titles": " Senior Manager of Call Center Services, Senior Call Center Operations Manager, Senior Support Manager, Senior Manager of Call Center Support"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Customer Service Manager",
                "position_descriptor": " Customer Service Managers manage day-to-day customer service operations. They lead customer service teams, handle escalations, and monitor performance metrics. Customer Service Managers ensure that customer inquiries are resolved promptly and effectively, collaborate with other departments, and contribute to the development of customer service policies and procedures.",
                "related_job_titles": " Manager of Call Center Services, Call Center Operations Manager, Support Manager, Manager of Call Center Support"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Customer Service Team Lead / Supervisor",
                "position_descriptor": " Customer Service Team Leads or Supervisors provide direct leadership to customer service representatives. They assist in resolving complex customer inquiries, provide coaching and training, and monitor team performance. Customer Service Team Leads/Supervisors play a key role in ensuring a positive customer experience and facilitating communication between their team and management.",
                "related_job_titles": " Call Center Team Supervisor, Call Center Operations Team Lead,  Supervisor of Call Center Support, Call Center Team Supervisor"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Customer Service Representative / Specialist",
                "position_descriptor": " Customer Service Representatives or Specialists directly interact with customers to address inquiries, resolve issues, and provide support. They maintain accurate records of customer interactions, offer product or service information, and contribute to maintaining a high level of customer satisfaction. Customer Service Representatives/Specialists handle a variety of customer-related tasks.",
                "related_job_titles": " Call Center Specialist, Call Center Agent, Specialist in Call Center Services, Call Center Support Specialist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Customer Service Coordinator / Associate",
                "position_descriptor": " Customer Service Coordinators or Associates provide administrative and operational support to customer service teams. They assist in organizing customer inquiries, maintain customer-related records, and ensure smooth communication between customers and representatives. Customer Service Coordinators/Associates play a role in optimizing customer service processes.",
                "related_job_titles": " Call Center Associate, Call Center Support Coordinator, Associate in Call Center Services, Support Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Customer Service Assistant",
                "position_descriptor": "Experienced assistants who support various customer service tasks, including data entry, basic issue resolution, and administrative duties to gain exposure to customer service practices and contribute to delivering positive customer experiences.",
                "related_job_titles": " Call Center Assistant, Call Center Operations Intern, Assistant in Call Center Services, Support Intern"
            }
        ]
    },
    "Customer Service | Technical Customer Support": {
        "job_family_descriptor": "The Technical Customer Service job family is responsible for providing customer support for technical products and services. This includes troubleshooting technical problems, providing technical assistance, and responding to customer inquiries. Technical Customer Service Representatives are expected to have a high level of technical knowledge and be able to effectively communicate with customers.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Customer Experience Officer",
                "position_descriptor": " At the highest level, the Chief Customer Experience Officer is responsible for developing and executing the organization's customer service and support strategy. They lead and oversee all customer service functions, ensuring exceptional customer experiences, satisfaction, and loyalty. The CCXO collaborates with senior leadership to align customer service initiatives with business objectives. They also analyze customer feedback, identify areas for improvement, and drive the organization's customer-centric culture.",
                "related_job_titles": " Chief Technical Support Officer, Technical Support Executive"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Customer Service",
                "position_descriptor": " Vice Presidents of Customer Service lead a substantial portion of the customer service and support function. They design and implement customer service strategies, policies, and standards. This role involves managing multiple customer service teams, monitoring customer satisfaction metrics, and overseeing the resolution of complex customer issues. The Vice President of Customer Service collaborates with other departments to enhance the overall customer experience.",
                "related_job_titles": " VP of Technical Support, Vice President of Support Services, VP of Customer Assistance, Vice President of Technical Assistance"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Customer Support",
                "position_descriptor": " Directors of Customer Support manage specific areas within the customer service function, such as technical support, helpdesk, or customer care. They lead teams responsible for providing timely and effective solutions to customer inquiries and issues. Directors of Customer Support collaborate with cross-functional teams to ensure alignment with business goals, implement customer service best practices, and drive continuous improvement.",
                "related_job_titles": " Technical Support Director, Director of Support Services, Support Services Director, Director of Technical Assistance"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Customer Service Manager",
                "position_descriptor": " Senior Customer Service Managers oversee critical customer service activities and teams. They monitor customer satisfaction metrics, analyze customer feedback, and identify areas for improvement. Senior Customer Service Managers develop and implement customer service strategies, manage customer service representatives, and ensure consistent delivery of high-quality customer support.",
                "related_job_titles": " Senior Manager of Technical Support, Senior Manager of Support Services,  Senior Technical Support Operations Manager, Senior Manager of Technical Assistance"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Customer Service Manager",
                "position_descriptor": " Customer Service Managers manage day-to-day customer service operations. They lead customer service teams, handle escalations, and monitor performance metrics. Customer Service Managers ensure that customer inquiries are resolved promptly and effectively, collaborate with other departments, and contribute to the development of customer service policies and procedures.",
                "related_job_titles": " Manager of Technical Support, Manager of Support Services, Manager of Technical Assistance, Technical Support Operations Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Customer Service Team Lead / Supervisor",
                "position_descriptor": " Customer Service Team Leads or Supervisors provide direct leadership to customer service representatives. They assist in resolving complex customer inquiries, provide coaching and training, and monitor team performance. Customer Service Team Leads/Supervisors play a key role in ensuring a positive customer experience and facilitating communication between their team and management.",
                "related_job_titles": " Technical Support Team Supervisor, Support Team Lead, Supervisor of Support Services, Technical Assistance Team Supervisor"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Customer Service Representative / Specialist",
                "position_descriptor": " Customer Service Representatives or Specialists directly interact with customers to address inquiries, resolve issues, and provide support. They maintain accurate records of customer interactions, offer product or service information, and contribute to maintaining a high level of customer satisfaction. Customer Service Representatives/Specialists handle a variety of customer-related tasks.",
                "related_job_titles": " Technical Support Specialist, Support Specialist, Specialist in Technical Support, Support Representative"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Customer Service Coordinator / Associate",
                "position_descriptor": " Customer Service Coordinators or Associates provide administrative and operational support to customer service teams. They assist in organizing customer inquiries, maintain customer-related records, and ensure smooth communication between customers and representatives. Customer Service Coordinators/Associates play a role in optimizing customer service processes.",
                "related_job_titles": " Technical Support Associate, Support Associate, Associate in Technical Support, Support Coordinator"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Customer Service Assistant",
                "position_descriptor": "Experienced assistants who support various customer service tasks, including data entry, basic issue resolution, and administrative duties to gain exposure to customer service practices and contribute to delivering positive customer experiences.",
                "related_job_titles": " Technical Support Assistant, Support Intern, Assistant in Technical Support, Support Intern"
            }
        ]
    },
    "Customer Service | Field Services Customer Support": {
        "job_family_descriptor": "The Field Service job family provides on-site customer support during (scheduled) field visits or dispatches.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Customer Experience Officer",
                "position_descriptor": " At the highest level, the Chief Customer Experience Officer is responsible for developing and executing the organization's customer service and support strategy. They lead and oversee all customer service functions, ensuring exceptional customer experiences, satisfaction, and loyalty. The CCXO collaborates with senior leadership to align customer service initiatives with business objectives. They also analyze customer feedback, identify areas for improvement, and drive the organization's customer-centric culture.",
                "related_job_titles": " Chief Field Support Officer, Field Services Experience Executive"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Customer Service",
                "position_descriptor": " Vice Presidents of Customer Service lead a substantial portion of the customer service and support function. They design and implement customer service strategies, policies, and standards. This role involves managing multiple customer service teams, monitoring customer satisfaction metrics, and overseeing the resolution of complex customer issues. The Vice President of Customer Service collaborates with other departments to enhance the overall customer experience.",
                "related_job_titles": " VP of Field Support, Vice President of Support Services, VP of Customer Assistance, Vice President of Field Assistance"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Customer Support",
                "position_descriptor": " Directors of Customer Support manage specific areas within the customer service function, such as technical support, helpdesk, or customer care. They lead teams responsible for providing timely and effective solutions to customer inquiries and issues. Directors of Customer Support collaborate with cross-functional teams to ensure alignment with business goals, implement customer service best practices, and drive continuous improvement.",
                "related_job_titles": " Field Support Director, Director of Support Services, Support Services Director, Director of Field Assistance"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Customer Service Manager",
                "position_descriptor": " Senior Customer Service Managers oversee critical customer service activities and teams. They monitor customer satisfaction metrics, analyze customer feedback, and identify areas for improvement. Senior Customer Service Managers develop and implement customer service strategies, manage customer service representatives, and ensure consistent delivery of high-quality customer support.",
                "related_job_titles": " Senior Manager of Field Support, Senior Manager of Support Services, Senior Field Support Operations Manager, Senior Manager of Field Assistance"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Customer Service Manager",
                "position_descriptor": " Customer Service Managers manage day-to-day customer service operations. They lead customer service teams, handle escalations, and monitor performance metrics. Customer Service Managers ensure that customer inquiries are resolved promptly and effectively, collaborate with other departments, and contribute to the development of customer service policies and procedures.",
                "related_job_titles": " Manager of Field Support, Manager of Support Services, Manager of Field Assistance, Field Support Operations Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Customer Service Team Lead / Supervisor",
                "position_descriptor": " Customer Service Team Leads or Supervisors provide direct leadership to customer service representatives. They assist in resolving complex customer inquiries, provide coaching and training, and monitor team performance. Customer Service Team Leads/Supervisors play a key role in ensuring a positive customer experience and facilitating communication between their team and management.",
                "related_job_titles": " Field Support Team Supervisor, Support Team Lead, Supervisor of Support Services, Field Assistance Team Supervisor"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Customer Service Representative / Specialist",
                "position_descriptor": " Customer Service Representatives or Specialists directly interact with customers to address inquiries, resolve issues, and provide support. They maintain accurate records of customer interactions, offer product or service information, and contribute to maintaining a high level of customer satisfaction. Customer Service Representatives/Specialists handle a variety of customer-related tasks.",
                "related_job_titles": " Field Support Specialist, Support Specialist, Specialist in Field Support, Support Representative"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Customer Service Coordinator / Associate",
                "position_descriptor": " Customer Service Coordinators or Associates provide administrative and operational support to customer service teams. They assist in organizing customer inquiries, maintain customer-related records, and ensure smooth communication between customers and representatives. Customer Service Coordinators/Associates play a role in optimizing customer service processes.",
                "related_job_titles": " Field Support Associate, Support Associate, Associate in Field Support, Support Coordinator"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Customer Service Assistant",
                "position_descriptor": "Experienced assistants who support various customer service tasks, including data entry, basic issue resolution, and administrative duties to gain exposure to customer service practices and contribute to delivering positive customer experiences.",
                "related_job_titles": " Field Support Assistant, Support Intern, Assistant in Field Support, Support Intern"
            }
        ]
    },
    "Public Relations": {
        "job_family_descriptor": "",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Communications Officer / Head of Public Relations",
                "position_descriptor": " As the top executive in the Public Relations field, the CCO or Head of PR leads the organization's entire communication strategy and oversee all communication activities, manage communication teams, and play a crucial role in shaping the company's public image and reputation.",
                "related_job_titles": "Chief Communications Officer, Head of Public Relations, Chief PR Strategist, Chief Brand Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Communications Director / PR Director",
                "position_descriptor": " Directors are senior leaders responsible for developing and implementing communication strategies and drive PR campaigns, supervise teams, and serve as the primary spokesperson for the organization.",
                "related_job_titles": "Director of Corporate Communications, Communications Director, PR Director, Director of Public Affairs, Director of Media Relations, Corporate Communications Director"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Public Relations Manager / Communications Manager",
                "position_descriptor": " Senior PR Managers or Communications Managers oversee the planning and execution of PR campaigns and manage communication staff, develop media relations, and handle crisis communication.",
                "related_job_titles": "Senior Public Relations Manager, Communications Manager, PR and Media Relations Manager, Public Relations Program Manager, Senior Corporate Communications Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Public Relations Manager / Communications Specialist",
                "position_descriptor": " PR Managers or Communications Specialists manage day-to-day PR activities and develop and maintain media relationships, create PR materials, and assist in campaign execution.",
                "related_job_titles": "Public Relations Manager, Communications Specialist, Media Relations Manager, Corporate Communications Specialist, PR Campaign Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "PR Coordinator / Communications Coordinator",
                "position_descriptor": " Coordinators supervise PR efforts and coordinate communication activities and perform media monitoring, event planning, and content creation.",
                "related_job_titles": "PR Coordinator, Communications Coordinator, Media Coordinator, Public Affairs Coordinator, Corporate Communications Coordinator"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Public Relations Specialist / Communications Associate",
                "position_descriptor": " These are mid-level professionals specializing in various aspects of public relations, such as media relations, social media management, or internal communication, and they take on more independent responsibilities within the PR team.",
                "related_job_titles": "Public Relations Specialist, Communications Associate, Media Relations Specialist, Corporate Communications Associate, PR Campaign Specialist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Public Relations Officer / Communications Officer",
                "position_descriptor": " Officers focus on specific PR functions and specialize in areas like media outreach, social media, or internal communications.",
                "related_job_titles": "Public Relations Officer, Media Relations Officer, PR Campaign Officer, Communication Officer, Media Officer, Corporate Communications Officer"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "PR Assistant / Communications Assistant",
                "position_descriptor": " PR Assistants or Communications Assistants provide entry-level support in PR and communication efforts and handle administrative tasks, track media coverage, and assist with research.",
                "related_job_titles": "PR Assistant, Communications Assistant, Media Assistant, Public Affairs Assistant, Corporate Communications Assistant"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Public Relations Intern / Communications Intern",
                "position_descriptor": " PR Interns or Communications Interns are typically in entry-level, short-term roles to gain practical experience by assisting PR professionals, performing research, and contributing to PR projects.",
                "related_job_titles": "Public Relations Intern, Communications Intern, Media Intern, PR and Communications Intern, Corporate Communications Intern"
            }
        ]
    },
    "Legal and Compliance (General)": {
        "job_family_descriptor": "Legal and compliance job family roles focus on ensuring that the organisation adheres to laws, regulations, and industry standards. Legal professionals handle contracts, regulatory compliance, and legal matters.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Legal and Compliance Officer",
                "position_descriptor": " At the highest level, the Chief Legal and Compliance Officer (CLCO) holds ultimate responsibility for the organization's legal and compliance functions. They provide strategic leadership, overseeing legal affairs and ensuring compliance with laws, regulations, and industry standards. The CLCO advises senior leadership on legal matters, manages legal teams, and develops risk management strategies. They also monitor changes in laws and regulations to ensure the organization's practices remain compliant.",
                "related_job_titles": " Legal Compliance Executive, Chief Compliance Strategist, Head of Legal and Compliance"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Seniore Director / Vice President of Legal Affairs",
                "position_descriptor": " Vice Presidents of Legal Affairs lead a substantial portion of the legal department. They provide legal counsel to the organization, manage legal teams, and oversee contract negotiations. This role involves addressing complex legal issues, conducting risk assessments, and ensuring compliance with laws and regulations. The Vice President of Legal Affairs collaborates with senior leaders to align legal strategies with business goals.",
                "related_job_titles": " VP of Legal Services, Vice President of Legal Advisory, Legal Affairs VP, VP of Legal Compliance, Vice President of Legal Operations, Compliance VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Legal Services",
                "position_descriptor": " Directors of Legal Services manage specific areas within the legal department, such as contracts, litigation, or intellectual property. They lead legal teams, provide legal guidance, and manage legal projects. Directors of Legal Services collaborate with other departments to identify legal risks and provide proactive legal solutions. They also ensure the organization's practices align with legal and regulatory requirements.",
                "related_job_titles": " Legal Services Director, Director of Legal Affairs, Legal Advisory Director, Director of Legal Compliance, Legal Compliance Lead, Legal Operations Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Legal Counsel",
                "position_descriptor": " Senior Legal Counsels handle critical legal matters and provide expert legal advice. They conduct legal research, draft contracts and agreements, and represent the organization in legal negotiations. Senior Legal Counsels analyze legal risks, provide recommendations, and help develop legal strategies. They may also assist in managing external legal resources and supervise junior legal staff.",
                "related_job_titles": " Senior Counsel, Lead Legal Advisor, Senior Legal Consultant, Principal Legal Counsel, Senior Legal Advisor, Lead Regulatory Counsel."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Departmental Manager, Legal & Compliance",
                "position_descriptor": " Legal Counsels handle a variety of legal tasks, including contract drafting and reviewing, legal research, and providing legal opinions. They work on legal agreements, assist in negotiations, and help ensure legal compliance. Legal Counsels may also advise on legal issues related to specific projects or initiatives and collaborate with other teams to address legal concerns.  Compliance Managers oversee the organization's compliance with laws, regulations, and industry standards. They develop compliance programs, conduct audits, and implement policies and procedures. Compliance Managers monitor regulatory changes, assess potential risks, and train employees on compliance matters. They work to ensure that the organization operates ethically and within legal boundaries.",
                "related_job_titles": "Departmental  Manager,  In-House Counsel, Corporate Legal Advisor, Legal Consultant, Business Legal Advisor, Legal Analyst, Corporate Compliance Counsel. Regulatory Compliance Manager, Compliance Officer, Compliance Strategy Manager, Regulatory Affairs Manager, Regulatory Compliance Strategist."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Section Manager, Legal & Compliance",
                "position_descriptor": " Compliance Managers oversee the organization's compliance with laws, regulations, and industry standards. They develop compliance programs, conduct audits, and implement policies and procedures. Compliance Managers monitor regulatory changes, assess potential risks, and train employees on compliance matters. They work to ensure that the organization operates ethically and within legal boundaries.",
                "related_job_titles": "Section Head, Section Manager, In-House Counsel, Corporate Legal Advisor, Legal Consultant, Business Legal Advisor, Legal Analyst, Corporate Compliance Counsel. Regulatory Compliance Manager, Compliance Officer, Compliance Strategy Manager, Regulatory Affairs Manager, Regulatory Compliance Strategist."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Compliance Specialist / Analyst",
                "position_descriptor": " Compliance Specialists or Analysts support the compliance efforts of the organization. They assist in developing compliance programs, conducting risk assessments, and monitoring compliance with regulations. Compliance Specialists/Analysts help maintain compliance documentation, analyze compliance data, and contribute to the development of compliance training materials.",
                "related_job_titles": " Regulatory Compliance Specialist, Compliance Analyst, Compliance Solutions Specialist, Regulatory Analyst, Compliance Reporting Analyst."
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Compliance Coordinator / Associate",
                "position_descriptor": " Compliance Coordinators or Associates provide administrative and operational support to the compliance function. They assist in organizing compliance activities, maintaining compliance records, and ensuring proper documentation. Compliance Coordinators/Associates contribute to compliance reporting and help ensure that compliance processes are followed.",
                "related_job_titles": " Regulatory Compliance Coordinator, Compliance Associate, Compliance Support Coordinator, Regulatory Associate, Regulatory Compliance Associate."
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Legal and Compliance Assistant",
                "position_descriptor": " Entry-level positions within the legal and compliance function are typically filled by interns or assistants. These individuals support various legal and compliance tasks, including research, documentation, and administrative duties. Entry-Level Legal and Compliance Interns/Assistants gain exposure to legal and compliance practices while working under the guidance of experienced professionals.",
                "related_job_titles": " Legal Intern, Compliance Assistant, Legal Support Intern, Compliance Assistant, Legal Compliance Intern, Assistant Compliance Analyst."
            }
        ]
    },
    "Legal and Compliance | Legal": {
        "job_family_descriptor": "The Legal job family consists of positions that provide professional legal services.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Legal and Compliance Officer",
                "position_descriptor": " At the highest level, the Chief Legal and Compliance Officer (CLCO) holds ultimate responsibility for the organization's legal and compliance functions. They provide strategic leadership, overseeing legal affairs and ensuring compliance with laws, regulations, and industry standards. The CLCO advises senior leadership on legal matters, manages legal teams, and develops risk management strategies. They also monitor changes in laws and regulations to ensure the organization's practices remain compliant.",
                "related_job_titles": " Legal Strategy Executive, Chief Legal Strategist, CLO."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Legal Affairs",
                "position_descriptor": " Vice Presidents of Legal Affairs lead a substantial portion of the legal department. They provide legal counsel to the organization, manage legal teams, and oversee contract negotiations. This role involves addressing complex legal issues, conducting risk assessments, and ensuring compliance with laws and regulations. The Vice President of Legal Affairs collaborates with senior leaders to align legal strategies with business goals.",
                "related_job_titles": " VP of Legal Services, Vice President of Legal Advisory, Legal Affairs VP, VP of Legal Operations, Vice President of Legal Compliance, Legal Operations VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Legal Services",
                "position_descriptor": " Directors of Legal Services manage specific areas within the legal department, such as contracts, litigation, or intellectual property. They lead legal teams, provide legal guidance, and manage legal projects. Directors of Legal Services collaborate with other departments to identify legal risks and provide proactive legal solutions. They also ensure the organization's practices align with legal and regulatory requirements.",
                "related_job_titles": " Legal Services Director, Director of Legal Affairs, Legal Advisory Director, Director of Legal Compliance, Legal Compliance Lead, Legal Operations Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Legal Counsel",
                "position_descriptor": " Senior Legal Counsels handle critical legal matters and provide expert legal advice. They conduct legal research, draft contracts and agreements, and represent the organization in legal negotiations. Senior Legal Counsels analyze legal risks, provide recommendations, and help develop legal strategies. They may also assist in managing external legal resources and supervise junior legal staff.",
                "related_job_titles": " Senior Counsel, Lead Legal Advisor, Senior Legal Consultant, Principal Legal Counsel, Senior Legal Advisor, Lead Regulatory Counsel."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Legal Counsel I",
                "position_descriptor": " Legal Counsels at departmental manager level who handle a variety of legal tasks, including contract drafting and reviewing, legal research, and providing legal opinions. They work on legal agreements, assist in negotiations, and help ensure legal compliance. Legal Counsels may also advise on legal issues related to specific projects or initiatives and collaborate with other teams to address legal concerns.",
                "related_job_titles": " In-House Counsel, Corporate Legal Advisor, Legal Consultant, Business Legal Advisor, Legal Analyst, Corporate Compliance Counsel."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Legal Counsel II",
                "position_descriptor": " Legal Counsel at section manager level, who handle a variety of legal tasks, including contract drafting and reviewing, legal research, and providing legal opinions. They work on legal agreements, assist in negotiations, and help ensure legal compliance. Legal Counsels may also advise on legal issues related to specific projects or initiatives and collaborate with other teams to address legal concerns.",
                "related_job_titles": " In-House Counsel, Corporate Legal Advisor, Legal Consultant, Business Legal Advisor, Legal Analyst, Corporate Compliance Counsel."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Compliance Specialist / Analyst",
                "position_descriptor": " Compliance Specialists or Analysts support the compliance efforts of the organization. They assist in developing compliance programs, conducting risk assessments, and monitoring compliance with regulations. Compliance Specialists/Analysts help maintain compliance documentation, analyze compliance data, and contribute to the development of compliance training materials.",
                "related_job_titles": " Legal Analyst, Legal Solutions Specialist, Legal Affairs Specialist, Regulatory Analyst, Compliance Reporting Analyst."
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Compliance Coordinator / Associate",
                "position_descriptor": " Compliance Coordinators or Associates provide administrative and operational support to the compliance function. They assist in organizing compliance activities, maintaining compliance records, and ensuring proper documentation. Compliance Coordinators/Associates contribute to compliance reporting and help ensure that compliance processes are followed.",
                "related_job_titles": " Legal Associate, Legal Support Coordinator, Regulatory Associate, Regulatory Compliance Associate."
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Legal and Compliance Assistant",
                "position_descriptor": " Entry-level positions within the legal and compliance function are typically filled by interns or assistants. These individuals support various legal and compliance tasks, including research, documentation, and administrative duties. Entry-Level Legal and Compliance Interns/Assistants gain exposure to legal and compliance practices while working under the guidance of experienced professionals.",
                "related_job_titles": " Legal Assistant, Legal Support Intern, Compliance Assistant, Legal Compliance Intern, Assistant Legal Analyst."
            }
        ]
    },
    "Legal and Compliance | Compliance": {
        "job_family_descriptor": "Compliance job family encompasses roles and responsibilities within an organization that are focused on ensuring adherence to relevant laws, regulations, policies, and industry standards",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Legal and Compliance Officer",
                "position_descriptor": " At the highest level, the Chief Legal and Compliance Officer (CLCO) holds ultimate responsibility for the organization's legal and compliance functions. They provide strategic leadership, overseeing legal affairs and ensuring compliance with laws, regulations, and industry standards. The CLCO advises senior leadership on legal matters, manages legal teams, and develops risk management strategies. They also monitor changes in laws and regulations to ensure the organization's practices remain compliant.",
                "related_job_titles": " Compliance Strategy Executive, Chief Regulatory Strategist, CCO."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Seniore Director / Vice President of Legal Affairs",
                "position_descriptor": " Vice Presidents of Legal Affairs lead a substantial portion of the legal department. They provide legal counsel to the organization, manage legal teams, and oversee contract negotiations. This role involves addressing complex legal issues, conducting risk assessments, and ensuring compliance with laws and regulations. The Vice President of Legal Affairs collaborates with senior leaders to align legal strategies with business goals.",
                "related_job_titles": " VP of Regulatory Affairs, Vice President of Regulatory Compliance, Compliance VP, VP of Compliance Operations."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Legal Services",
                "position_descriptor": " Directors of Legal Services manage specific areas within the legal department, such as contracts, litigation, or intellectual property. They lead legal teams, provide legal guidance, and manage legal projects. Directors of Legal Services collaborate with other departments to identify legal risks and provide proactive legal solutions. They also ensure the organization's practices align with legal and regulatory requirements.",
                "related_job_titles": " Compliance Director, Director of Regulatory Affairs, Regulatory Compliance Director, Director of Compliance Strategy."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Legal Counsel",
                "position_descriptor": " Senior Legal Counsels handle critical legal matters and provide expert legal advice. They conduct legal research, draft contracts and agreements, and represent the organization in legal negotiations. Senior Legal Counsels analyze legal risks, provide recommendations, and help develop legal strategies. They may also assist in managing external legal resources and supervise junior legal staff.",
                "related_job_titles": " Senior Manager of Regulatory Compliance, Lead Compliance Strategist, Senior Regulatory Affairs Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Compliance Manager I",
                "position_descriptor": "At Departmental Manager level who oversee the organization's compliance with laws, regulations, and industry standards. They develop compliance programs, conduct audits, and implement policies and procedures. Compliance Managers monitor regulatory changes, assess potential risks, and train employees on compliance matters. They work to ensure that the organization operates ethically and within legal boundaries.",
                "related_job_titles": " Regulatory Compliance Manager, Compliance Officer, Compliance Strategy Manager, Regulatory Affairs Manager, Department Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Compliance Manager II",
                "position_descriptor": "At Section Manager Level who oversee the organization's compliance with laws, regulations, and industry standards. They develop compliance programs, conduct audits, and implement policies and procedures. Compliance Managers monitor regulatory changes, assess potential risks, and train employees on compliance matters. They work to ensure that the organization operates ethically and within legal boundaries.",
                "related_job_titles": " Regulatory Compliance Manager, Compliance Officer, Compliance Strategy Manager, Regulatory Affairs Manager, Section Manager"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Compliance Specialist / Analyst",
                "position_descriptor": " Compliance Specialists or Analysts support the compliance efforts of the organization. They assist in developing compliance programs, conducting risk assessments, and monitoring compliance with regulations. Compliance Specialists/Analysts help maintain compliance documentation, analyze compliance data, and contribute to the development of compliance training materials.",
                "related_job_titles": " Regulatory Compliance Specialist, Compliance Analyst, Compliance Solutions Specialist, Regulatory Analyst, Compliance Reporting Analyst."
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Compliance Coordinator / Associate",
                "position_descriptor": " Compliance Coordinators or Associates provide administrative and operational support to the compliance function. They assist in organizing compliance activities, maintaining compliance records, and ensuring proper documentation. Compliance Coordinators/Associates contribute to compliance reporting and help ensure that compliance processes are followed.",
                "related_job_titles": " Compliance Associate, Compliance Support Coordinator, Regulatory Associate, Regulatory Compliance Associate."
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Legal and Compliance Assistant",
                "position_descriptor": " Entry-level positions within the legal and compliance function are typically filled by interns or assistants. These individuals support various legal and compliance tasks, including research, documentation, and administrative duties. Entry-Level Legal and Compliance Interns/Assistants gain exposure to legal and compliance practices while working under the guidance of experienced professionals.",
                "related_job_titles": " Compliance Assistant, Compliance Support Intern, Regulatory Compliance Intern, Assistant Compliance Analyst."
            }
        ]
    },
    "Supply Chain": {
        "job_family_descriptor": "The Supply Chain Management job family consists of positions that coordinate and manage all activities related to the procurement of goods and services, the distribution of finished products, and the disposal of finished products. In addition, job holders are responsible for the management of the relationships between the various stakeholders involved in the supply chain, including suppliers, manufacturers, distributors, retailers, and customers.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Supply Chain Officer ",
                "position_descriptor": "The Chief  holds the ultimate responsibility for the organization's end-to-end supply chain operations. They provide strategic leadership, overseeing procurement, logistics, inventory management, and distribution. The CSCO collaborates with executive leadership to align supply chain strategies with business goals, optimize efficiency, manage risk, and ensure timely delivery of products and services.",
                "related_job_titles": "Supply Chain Strategy Executive, Chief Logistics and Procurement Officer, CSCO."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Supply Chain",
                "position_descriptor": " Vice Presidents of Supply Chain lead a substantial portion of the supply chain operations. They manage procurement, logistics, and inventory teams, ensuring efficient sourcing, transportation, and inventory management. This role involves strategic planning, supplier relationship management, and cost optimization. Vice Presidents of Supply Chain also develop and implement supply chain strategies to meet business objectives.",
                "related_job_titles": "VP of Logistics and Procurement, Vice President of Supply Chain Operations, Supply Chain VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Supply Chain",
                "position_descriptor": " Directors of Supply Chain oversee specific segments of the supply chain, such as logistics, procurement, or inventory. They lead teams, manage supplier relationships, and implement supply chain improvement initiatives. Directors of Supply Chain collaborate with cross-functional teams to ensure product availability, reduce lead times, and enhance operational efficiency.",
                "related_job_titles": "Supply Chain Director, Director of Logistics, Supply Chain Strategy Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Supply Chain Manager",
                "position_descriptor": " Senior Supply Chain Managers handle critical supply chain functions and manage teams responsible for procurement, logistics, or inventory management. They analyze supply chain processes, identify opportunities for improvement, and drive efficiency initiatives. Senior Supply Chain Managers also collaborate with suppliers, negotiate contracts, and implement cost-saving strategies.",
                "related_job_titles": "Senior Manager of Logistics and Procurement, Lead Supply Chain Strategist, Senior Procurement Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Supply Chain Manager",
                "position_descriptor": " Supply Chain Managers manage day-to-day supply chain operations, overseeing procurement, logistics, and inventory management activities. They develop and implement supply chain strategies, monitor performance metrics, and optimize processes to meet demand while controlling costs. Supply Chain Managers ensure timely delivery of products and materials to support business operations.",
                "related_job_titles": "Logistics and Procurement Manager, Supply Chain Operations Manager, Procurement Strategy Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Supply Chain Team Lead / Supervisor",
                "position_descriptor": " Supply Chain Team Leads or Supervisors oversee smaller teams within specific supply chain functions. They coordinate daily activities, manage inventory levels, and ensure orders are fulfilled on time. Supply Chain Team Leads/Supervisors may also troubleshoot operational issues and collaborate with cross-functional teams to resolve supply chain challenges.",
                "related_job_titles": "Logistics and Procurement Team Supervisor, Supply Chain Supervisor, Procurement Team Lead."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Supply Chain Coordinator",
                "position_descriptor": " Supply Chain Coordinators or Analysts support supply chain activities by managing data, analyzing trends, and coordinating orders. They track inventory levels, monitor demand forecasts, and assist with procurement processes. Supply Chain Coordinators/Analysts contribute to maintaining accurate supply chain records and ensuring smooth operations.",
                "related_job_titles": "Logistics and Procurement Coordinator, Supply Chain Analyst, Procurement Coordinator."
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Supply Chain Officer / Associate",
                "position_descriptor": "Officers responsible for the day-to-day operations of the supply chain, working under the guidance of higher-level supply chain professionals.",
                "related_job_titles": "Logistics and Procurement Officer, Supply Chain Associate, Procurement Officer"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Entry-Level Supply Chain Assistant",
                "position_descriptor": " Entry-level positions within the supply chain are typically filled by interns or assistants. These individuals provide administrative support, assist with data entry, and help coordinate supply chain processes. Entry-Level Supply Chain Interns/Assistants gain exposure to supply chain operations and processes under the guidance of experienced professionals.",
                "related_job_titles": "Supply Chain Intern, Logistics and Procurement Intern, Assistant Operations Coordinator."
            }
        ]
    },
    "Supply Chain | Procurement and Purchasing": {
        "job_family_descriptor": "The Procurement and Purchasing job family encompasses the process of acquiring goods, services, and materials needed by an organization. Professionals in this family are responsible for sourcing suppliers, negotiating contracts, evaluating vendor performance, and ensuring timely and cost-effective procurement of goods and services. They play a crucial role in optimizing costs, quality, and supplier relationships.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Procurement Officer (CPO)",
                "position_descriptor": "The Chief Procurement Officer  is responsible for leading the entire procurement function. They develop procurement strategies, manage vendor relationships, and ensure cost-effective purchasing. The CPO collaborates with senior leadership to align procurement with business goals, drive efficiency, and mitigate supply chain risks.",
                "related_job_titles": "Procurement Strategy Executive, Chief Sourcing Officer, CPO, Head of Procurement"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director/ Vice President of Procurement",
                "position_descriptor": " Vice Presidents of Procurement oversee a substantial portion of the procurement function. They manage procurement teams, lead vendor negotiations, and implement strategic sourcing strategies. This role involves supplier selection, contract management, and cost optimization. Vice Presidents of Procurement align procurement strategies with the organization's objectives and foster cross-functional collaboration.",
                "related_job_titles": "VP of Strategic Sourcing, Vice President of Procurement Operations, Senior Director Procurement"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Procurement",
                "position_descriptor": " Directors of Procurement manage specific areas within procurement, such as strategic sourcing, category management, or supplier diversity. They lead procurement teams, develop sourcing strategies, and negotiate contracts. Directors of Procurement collaborate with other departments to ensure procurement aligns with business needs and cost targets.",
                "related_job_titles": "Procurement Director, Director of Strategic Sourcing, Category Management Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Procurement Manager",
                "position_descriptor": " Senior Procurement Managers handle critical procurement functions and lead teams responsible for sourcing, vendor management, or purchasing. They analyze market trends, identify cost-saving opportunities, and drive supplier negotiations. Senior Procurement Managers also assess supplier performance and contribute to procurement process improvements.",
                "related_job_titles": "Senior Manager of Sourcing and Procurement, Lead Procurement Strategist, Senior Category Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Procurement Manager",
                "position_descriptor": " Procurement Managers oversee day-to-day procurement operations, manage vendor relationships, and handle purchasing activities. They collaborate with internal stakeholders to understand procurement needs, evaluate suppliers, and ensure timely delivery of goods and services. Procurement Managers contribute to cost containment and procurement process enhancements.",
                "related_job_titles": "Sourcing and Procurement Manager, Procurement Operations Manager, Category Procurement Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Procurement Specialist / Analyst",
                "position_descriptor": " Procurement Specialists or Analysts support procurement activities by analyzing supplier performance, monitoring purchasing trends, and assisting in negotiations. They contribute to market research, evaluate supplier capabilities, and maintain procurement records. Procurement Specialists/Analysts help optimize procurement processes and supplier relationships.",
                "related_job_titles": "Procurement Specialist, Procurement Analyst, Strategic Sourcing Analyst, Senior Procurement Officer"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Procurement Coordinator ",
                "position_descriptor": " Procurement Coordinators or Associates provide administrative support to the procurement function. They assist in vendor communications, manage procurement documentation, and help track purchasing orders. Procurement Coordinators/Associates contribute to efficient procurement operations and accurate record keeping.",
                "related_job_titles": "Procurement Coordinator, Procurement Associate, Sourcing Coordinator, Procurement Officer"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Procurement Officer / Associate",
                "position_descriptor": "Officers responsible for the day-to-day operations of the procurement and purchasing department, working under the guidance of higher-level procurement professionals.",
                "related_job_titles": "Procurement Associate, Procurement Officer, Purchasing Officer"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Entry-Level Procurement Assistant",
                "position_descriptor": " Entry-level positions within procurement are typically filled by interns or assistants. These individuals support procurement tasks, assist with data entry, and contribute to procurement projects. Entry-Level Procurement Interns/Assistants gain exposure to procurement processes while working alongside experienced professionals.",
                "related_job_titles": "Procurement Intern, Procurement Assistant, Sourcing Intern."
            }
        ]
    },
    "Supply Chain | Materials Management": {
        "job_family_descriptor": "The Materials Management job family involves planning, coordinating, and controlling the flow of materials within an organization's supply chain. Professionals in this family are responsible for inventory management, demand forecasting, materials planning, and ensuring the availability of materials needed for production and operations. They strive to minimize excess inventory while meeting production and customer demands.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Material Management Officer ",
                "position_descriptor": " At the highest level, the Chief Material Management Officer (CMO) is responsible for overseeing all aspects of material procurement, inventory control, and distribution. They develop strategic material management plans, collaborate with suppliers, and optimize inventory levels to support operational efficiency and cost-effectiveness.",
                "related_job_titles": "Material Management Strategy Executive, Chief Procurement and Inventory Officer, CMO."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Vice President of Material Management",
                "position_descriptor": " Vice Presidents of Material Management lead the material management function. They manage teams responsible for procurement, inventory management, and distribution. This role involves developing procurement strategies, vendor negotiations, and ensuring timely availability of materials to meet operational demands.",
                "related_job_titles": "VP of Procurement and Inventory Management, Vice President of Material Operations, Senior Director Material Management"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Material Management",
                "position_descriptor": " Directors of Material Management oversee specific material management areas, such as procurement, inventory, or distribution. They lead teams, implement efficient material handling processes, and optimize inventory levels to prevent shortages and excesses. Directors also collaborate with cross-functional teams to align material management with business goals.",
                "related_job_titles": "Material Management Director, Director of Procurement and Inventory, Distribution Strategy Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Material Manager",
                "position_descriptor": " Senior Material Managers handle critical material management functions. They analyze material usage trends, negotiate supplier contracts, and manage inventory levels to meet production requirements. Senior Material Managers also lead process improvement initiatives and collaborate with vendors to ensure timely delivery.",
                "related_job_titles": "Senior Manager of Material Procurement, Lead Material Procurement Strategist, Senior Inventory Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Material Manager",
                "position_descriptor": " Material Managers manage day-to-day material procurement, inventory control, and distribution operations. They collaborate with suppliers, monitor stock levels, and ensure materials are available when needed. Material Managers contribute to cost savings, process efficiency, and minimizing supply chain disruptions.",
                "related_job_titles": "Material Procurement Manager, Material Operations Manager, Inventory Control Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Material Specialist / Analyst",
                "position_descriptor": " Material Specialists or Analysts analyze material consumption patterns, monitor inventory data, and assist in supplier evaluations. They provide insights for demand forecasting, inventory optimization, and support procurement decisions. Material Specialists/Analysts contribute to maintaining efficient material management processes.",
                "related_job_titles": "Material Analyst, Inventory Analyst, Material Usage Analyst, Procurement Data Analyst Senior Materials Management Officer, Senior Inventory Officer, Materials Planner"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Material Coordinator",
                "position_descriptor": " Material Coordinators or Associates assist in coordinating material orders, managing inventory records, and facilitating material movement within the organization. They ensure timely availability of materials and support smooth material flow through the supply chain.",
                "related_job_titles": "Material Coordinator, Material Associate, Inventory Coordinator, Procurement Assistant, Materials Management Officer, Inventory Officer"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Material Officer/  / Associate",
                "position_descriptor": "Officers responsible for the day-to-day operations of the material management, working under the guidance of higher-level professionals.",
                "related_job_titles": "Materials Management Officer,  Associate"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Material Management Assistant",
                "position_descriptor": " Entry-level positions within material management are typically filled by interns or assistants. These individuals support material management activities, assist with data entry, and contribute to material handling tasks. Entry-Level Material Management Interns/Assistants gain exposure to material management operations and processes",
                "related_job_titles": "Material Management Intern, Material Assistant, Procurement Support Intern"
            }
        ]
    },
    "Supply Chain | Logistics": {
        "job_family_descriptor": "The Logistics job family focuses on the efficient and effective movement of goods, information, and resources across the supply chain. Professionals in this family manage transportation, distribution, order fulfilment, and supply chain networks to ensure products are delivered to customers on time while optimizing transportation costs and service levels.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Logistics Officer",
                "position_descriptor": " At the highest level, the Chief Logistics Officer is responsible for overseeing the entire logistics function, including supply chain, transportation, and distribution strategies, to ensure efficient and cost-effective movement of goods.",
                "related_job_titles": "Logistics Strategy Executive, Chief Supply Chain and Distribution Officer, CLO."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Seniore Director / Vice President of Logistics",
                "position_descriptor": " Vice Presidents of Logistics lead strategic planning and execution of logistics operations, managing transportation, distribution, and supply chain functions to support business objectives.",
                "related_job_titles": "VP of Supply Chain and Distribution, Vice President of Transportation and Logistics, Logistics VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Logistics",
                "position_descriptor": " Directors of Logistics oversee logistics operations, coordinating transportation, warehousing, and inventory management, and ensuring timely and efficient delivery of products.",
                "related_job_titles": "Logistics Director, Director of Distribution Operations, Supply Chain Strategy Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Logistics Manager",
                "position_descriptor": " Senior Logistics Managers manage critical logistics functions, optimizing transportation routes, coordinating distribution networks, and implementing strategies to enhance supply chain efficiency.",
                "related_job_titles": "Senior Manager of Distribution, Lead Logistics Strategist, Senior Supply Chain Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Logistics Manager",
                "position_descriptor": " Logistics Managers handle day-to-day logistics operations, overseeing transportation schedules, managing warehouse activities, and coordinating with suppliers and customers.",
                "related_job_titles": "Distribution Manager, Transportation Manager, Logistics Operations Manager, Supply Chain Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Logistics Specialist / Analyst",
                "position_descriptor": " Logistics Specialists or Analysts analyze logistics data, monitor shipment performance, and assist in optimizing transportation and distribution processes.",
                "related_job_titles": "Logistics Specialist, Transportation Analyst, Distribution Analyst, Supply Chain Analyst."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Logistics Coordinator ",
                "position_descriptor": " Logistics Coordinators or Associates support logistics operations by coordinating shipments, managing inventory, and ensuring smooth movement of goods through the supply chain.",
                "related_job_titles": "Logistics Coordinator, Transportation Associate, Distribution Coordinator, Supply Chain Associate."
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Logistics Officer / Associate",
                "position_descriptor": "Officers responsible for the day-to-day operations of the logistics department, working under the guidance of higher-level  professionals.",
                "related_job_titles": "Logistics Associate, Logistics Officer, Transport Officer"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Logistics Assistant",
                "position_descriptor": " Entry-level positions within logistics are typically filled by interns or assistants who assist in logistics tasks, support data entry, and contribute to logistics projects.",
                "related_job_titles": "Logistics Intern, Transportation Assistant, Distribution Intern, Supply Chain Assistant, Driver"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Senior Driver",
                "position_descriptor": "The Senior Driver typically assumes responsibility for coordinating transportation logistics, ensuring timely and safe delivery of goods or passengers, overseeing vehicle maintenance, and mentoring/training junior drivers. They might also liaise with clients or stakeholders, manage schedules, and ensure compliance with transportation regulations.",
                "related_job_titles": "Senior Driver, Driver Supervisor, Fleet Assistant"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Driver",
                "position_descriptor": "Drivers are primarily responsible for operating vehicles safely, transporting goods or individuals, maintaining vehicle cleanliness, and following designated routes or schedules.",
                "related_job_titles": "Driver, Delivery Driver, Transport Operator, Shuttle Driver"
            },
            {
                "wr_job_level": "WR 01",
                "survey_position": "Driver Mate",
                "position_descriptor": "A Driver Mate typically supports the driver by assisting with loading/unloading, ensuring the security of transported items, and navigating routes.",
                "related_job_titles": " Driver Assistant, Parker, Assistant Driver, Transport Assistant, Delivery Assistant."
            }
        ]
    },
    "Supply Chain | Warehousing": {
        "job_family_descriptor": "The Warehousing job family involves the management of physical storage facilities where goods are stored, managed, and distributed within the supply chain. Professionals in this family oversee inventory storage, pick and pack processes, warehouse layout optimization, and the use of technology to improve warehouse efficiency. They ensure accurate inventory tracking and timely order fulfilment.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Warehousing Officer",
                "position_descriptor": "The Chief Warehousing Officer is responsible for overseeing the entire warehousing function, including storage, inventory management, and distribution strategies, to ensure efficient and organized handling of goods.",
                "related_job_titles": "Chief Warehousing Officer, Warehousing Strategy Executive, Chief Inventory Management Officer, Head of Warehousing."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Vice President of Warehousing",
                "position_descriptor": " Vice Presidents of Warehousing lead strategic planning and execution of warehousing operations, managing storage facilities, inventory control, and optimizing distribution networks.",
                "related_job_titles": "Senior Director Warehousing, VP of Inventory Management, Vice President of Warehousing Operations, Warehousing VP. VP of Storage and Distribution: VP of Storage Solutions, Vice President of Distribution, Storage and Distribution VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Warehousing",
                "position_descriptor": " Directors of Warehousing oversee warehousing operations, coordinating storage processes, inventory management, and ensuring timely and accurate order fulfillment.",
                "related_job_titles": "Director of Warehousing, Director of Inventory Control, Storage and Distribution Director, Director of Warehouse Operations, Director of Storage Operations, Director of Distribution Strategy."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Warehouse Manager",
                "position_descriptor": " Senior Warehouse Managers manage critical warehousing functions, optimizing storage layouts, implementing inventory control strategies, and leading teams to enhance overall efficiency.",
                "related_job_titles": "Senior Warehouse Manager, Senior Manager of Storage and Inventory, Lead Warehouse Strategist, Senior Inventory Control Manager, Senior Manager of Inventory Operations, Lead Inventory Strategist."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Warehouse Manager",
                "position_descriptor": " Warehouse Managers handle day-to-day warehouse operations, overseeing storage activities, managing inventory levels, and ensuring accurate and efficient goods movement.",
                "related_job_titles": "Warehouse Manager, Storage and Inventory Manager, Warehouse Operations Manager, Inventory Control Manager, Inventory Manager, Storage Manager, Distribution Operations Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Warehouse Specialist / Analyst",
                "position_descriptor": " Warehouse Specialists or Analysts analyze warehouse data, monitor inventory levels, and assist in optimizing storage and distribution processes.",
                "related_job_titles": "Stores Officer, Inventory Specialist, Warehouse Operations Specialist, Storage and Inventory Coordinator: Distribution Coordinator, Store Operations Coordinator."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Warehouse Coordinator ",
                "position_descriptor": " Warehouse Coordinators or Associates support warehouse operations by coordinating storage, managing inventory records, and facilitating accurate movement of goods.",
                "related_job_titles": "Warehouse Officer, Storage Coordinator, Distribution Coordinator, Inventory Coordinator, Inventory Operations Coordinator, Store Operations Coordinator, Storage Specialist, Storekeeper"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Warehouse Officer / Associate",
                "position_descriptor": "Officers responsible for the day-to-day operations of thewarehouse, working under the guidance of higher-level supply chain professionals.",
                "related_job_titles": "Warehouse Officer, Warehouse Associate, Stores Officer"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": " Warehouse Assistant",
                "position_descriptor": " Entry-level positions within warehousing are typically filled by interns or assistants who assist in warehouse tasks, support data entry, and contribute to overall warehouse operations.",
                "related_job_titles": "Warehouse Intern, Inventory Assistant, Storage Intern, Distribution and Storage Assistant."
            }
        ]
    },
    "Operations and Administration | Admin Support": {
        "job_family_descriptor": "The jobs in this family involves providing assistance and support to various departments within the organization, managing administrative tasks, and ensuring smooth operations.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Administrative Officer",
                "position_descriptor": " At this level, the Chief Administrative Officer oversees all administrative support functions across the organization, ensuring smooth operations and effective coordination of administrative processes.",
                "related_job_titles": " Chief Admin Officer, Administration Strategy Executive"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director, Administrative Support",
                "position_descriptor": "Lead and manage administrative teams, ensuring efficient execution of administrative tasks, process improvements, and adherence to organizational policies.",
                "related_job_titles": " VP of Admin Services, Vice President of Office Support, Admin Support VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Administrative Support",
                "position_descriptor": " Directors of Administrative Support oversee specific areas of administrative functions, implementing best practices, managing teams, and collaborating with other departments to ensure seamless administrative operations.",
                "related_job_titles": " Admin Support Director, Director of Office Services, Support Services Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Administrative Manager",
                "position_descriptor": " Senior Administrative Managers manage critical administrative functions, overseeing tasks such as document management, travel coordination, and office supplies procurement to support daily business operations.",
                "related_job_titles": " Senior Admin Manager, Lead Office Coordinator, Senior Support Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Administrative Manager",
                "position_descriptor": " Administrative Managers handle day-to-day administrative tasks, including managing calendars, coordinating meetings, and ensuring timely communication with internal and external stakeholders.",
                "related_job_titles": " Admin Manager, Office Services Manager, Support Services Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Administrative Specialist / Analyst",
                "position_descriptor": " Administrative Specialists or Analysts provide specialized support, analyzing administrative processes, managing databases, and assisting in project coordination.",
                "related_job_titles": " Admin Specialist, Office Analyst, Support Analyst, Senior Admin Officer"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Administrative Coordinator / Associate",
                "position_descriptor": " Administrative Coordinators or Associates support administrative activities by coordinating schedules, assisting in documentation, and facilitating effective communication within the organization.",
                "related_job_titles": " Admin Coordinator, Office Associate, Admin Support Associate"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Administrative Officer",
                "position_descriptor": "Experienced Administrator, performs basic administrative tasks such as data entry, filing, answering phones, scheduling appointments, and providing general office support. They often assist other staff members and may handle routine correspondence",
                "related_job_titles": "Admin Officer, Office Administrator"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Administrative Assistant",
                "position_descriptor": " Entry-level positions within administrative support involve interns or assistants who support various administrative tasks, contributing to office organization and operational efficiency.",
                "related_job_titles": "Admin Assistant, Assistant Administrator"
            }
        ]
    },
    "Operations and Administration | Office Management": {
        "job_family_descriptor": "Office management covers the overall coordination and oversight of office operations, including facilities management, supplies procurement, and staff coordination.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Administrative Officer",
                "position_descriptor": " The Chief Office Management Officer oversees the strategic planning and execution of office management functions, ensuring a productive and well-organized workplace environment.",
                "related_job_titles": " Chief Office Manager, Workspace Strategy Executive, Office Head"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director, Administrative Support",
                "position_descriptor": "Lead office management teams, responsible for maintaining efficient office operations, space utilization, and employee well-being.",
                "related_job_titles": " VP of Office Operations, Vice President of Workspace Management, Office Management VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Office Management",
                "position_descriptor": " Directors of Office Management manage office facilities, space planning, and employee services to create a conducive work environment that supports productivity and employee satisfaction.",
                "related_job_titles": " Office Management Director, Director of Workspace Operations, Workspace Services Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Office Manager",
                "position_descriptor": " Senior Office Managers oversee daily office operations, manage facilities and vendor relationships, and implement policies to enhance office efficiency and employee experience.",
                "related_job_titles": " Senior Office Manager, Lead Office Operations Manager, Senior Workspace Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Office Manager",
                "position_descriptor": " Office Managers coordinate office logistics, manage office supplies, handle facility maintenance requests, and ensure smooth office operations on a day-to-day basis.",
                "related_job_titles": " Office Manager, Workspace Operations Manager, Workspace Services Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Office Specialist / Analyst",
                "position_descriptor": " Office Specialists or Analysts analyze office processes, track utilization of office resources, and assist in implementing office-related initiatives to enhance efficiency.",
                "related_job_titles": " Office Specialist, Workspace Analyst, Office Operations Analyst."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Office Coordinator / Associate",
                "position_descriptor": " Office Coordinators or Associates assist in coordinating office activities, managing facility requests, and supporting employees in their workspace needs.",
                "related_job_titles": " Office Coordinator, Workspace Associate, Office Operations Associate, Officer"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Administrative Officer",
                "position_descriptor": "Experienced Administrator, performs basic administrative tasks such as data entry, filing, answering phones, scheduling appointments, and providing general office support. They often assist other staff members and may handle routine correspondence",
                "related_job_titles": "Admin Officer, Office Administrator"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Office Management Assistant",
                "position_descriptor": "The office management assistants support office-related tasks, contributing to the overall organization and functionality of the workplace.",
                "related_job_titles": "Office / Workspace Assistant, Office Operations Assistant, Admin Assistant"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Office Support Staff I",
                "position_descriptor": " Office Support Staff III often involves more specialized or supervisory responsibilities within the office support function. ",
                "related_job_titles": " Senior Office Support Staff, Office Support Supervisor"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Office Support Staff II",
                "position_descriptor": "At this level, individuals take on more responsibilities and might handle more complex tasks than in the entry-level role.",
                "related_job_titles": " Office Support Staff, Office Support Clerk, Admin Clerk"
            },
            {
                "wr_job_level": "WR 01",
                "survey_position": "Office Support Staff III",
                "position_descriptor": "This entry-level role typically involves handling basic office manual tasks such as cleaning, serving refreshments, filing documents, sorting mail, and providing general support to other office staff.",
                "related_job_titles": "Cleaner, Cook,Messenger"
            }
        ]
    },
    "Operations and Administration | Front Desk and Reception": {
        "job_family_descriptor": "Front desk and reception focus on creating a positive first impression by managing front desk operations, greeting visitors, and handling incoming calls",
        "levels": [
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Front Desk Operations",
                "position_descriptor": " The Director oversees front desk operations, ensuring a welcoming and efficient experience for visitors, clients, and employees.",
                "related_job_titles": " Chief Reception Officer, Guest Services Executive, Director"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Reception Manager",
                "position_descriptor": "Senior Managers in Front Desk Operations lead front desk teams, managing reception services, visitor coordination, and maintaining a positive first impression of the organization.",
                "related_job_titles": " Senior Reception Manager, Lead Guest Services Manager, Senior Front Desk Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Reception Manager",
                "position_descriptor": "Managers oversee daily reception activities, manage reception staff, and implement protocols for handling visitors and maintaining security measures.",
                "related_job_titles": " Reception Manager, Guest Services Manager, Front Desk Manager."
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Senior Reception Specialist",
                "position_descriptor": "Specialist coordinate reception services, handle guest inquiries, and manage reception staff to ensure a professional and hospitable environment.",
                "related_job_titles": "Lead Guest Services Officer, Senior Front Desk Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Reception Coordinator/ supervisor",
                "position_descriptor": "Supervises front desk activities, greet visitors, manage phone calls, and provide administrative support to ensure smooth reception operations.",
                "related_job_titles": " Reception Team Lead, Guest Services Supervisor, Front Desk Supervisor"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Front Desk Specialist / Coordinator",
                "position_descriptor": " Front Desk Specialists or Coordinators manage visitor sign-ins, assist in managing phone inquiries, and provide support for maintaining a welcoming and organized reception area.",
                "related_job_titles": " Front Desk Specialist, Guest Services Coordinator, Reception Coordinator."
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Front Desk Associate I",
                "position_descriptor": " Front Desk Associates experienced at this level, manages reception area, managing guest inquiries, and ensuring proper reception area maintenance.",
                "related_job_titles": " Front Desk Associate, Guest Services Associate, Reception Associate, Receptionist"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Front Desk Associate II",
                "position_descriptor": " Front Desk Associates assist in greeting visitors, managing guest inquiries, and ensuring proper reception area maintenance.",
                "related_job_titles": " Front Desk Associate, Guest Services Associate, Reception Associate, Receptionist"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Entry-Level Front Desk Assistant",
                "position_descriptor": " Entry-level positions within front desk and reception involve interns or assistants who provide support in handling guest interactions and maintaining reception areas.",
                "related_job_titles": " Reception Intern, Guest Services Assistant, Front Desk Intern, Concierge"
            }
        ]
    },
    "Operations and Administration | Data Entry and Documentation": {
        "job_family_descriptor": "Roles in Data entry and documentation involve accurately entering and processing data, managing records, and maintaining organized information",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Data Management Officer",
                "position_descriptor": " The Chief Data Management Officer oversees the organization's data management strategy, ensuring accurate and secure data entry and documentation practices.",
                "related_job_titles": " Chief Data Officer, Data Strategy Executive, Head of Documentation"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director, Data Management",
                "position_descriptor": " Vice Presidents of Data Management lead data entry and documentation teams, ensuring data accuracy, integrity, and compliance with data management policies.",
                "related_job_titles": " VP of Data Entry, Vice President of Data Operations, Data Management VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Data Entry and Documentation",
                "position_descriptor": " Directors of Data Entry and Documentation oversee data capture processes, manage documentation standards, and implement data quality controls.",
                "related_job_titles": " Data Entry Director, Director of Data Operations, Data Operations Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Data Entry and Documentation Manager",
                "position_descriptor": " Senior Managers in this role manage data entry and documentation processes, supervise data entry teams, and ensure the proper organization of records.",
                "related_job_titles": " Senior Data Entry Manager, Lead Data Operations Manager, Senior Data Operations Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Data Entry and Documentation Manager",
                "position_descriptor": " Managers oversee data entry operations, implement document management systems, and ensure accurate recording and retrieval of data and documents.",
                "related_job_titles": " Data Entry Manager, Data Operations Manager, Documentation Manager."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Data Entry Specialist / Analyst",
                "position_descriptor": " Data Entry Specialists or Analysts handle accurate data entry, analyze data trends, and assist in maintaining data integrity and documentation standards.",
                "related_job_titles": " Data Entry Specialist, Data Analyst, Documentation Analyst, Senior Data Officer, Senior Librarian"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Data Entry Coordinator / Associate",
                "position_descriptor": " Data Entry Coordinators or Associates assist in data entry tasks, support documentation processes, and ensure data accuracy and completeness.",
                "related_job_titles": " Data Entry Coordinator, Data Associate, Documentation Coordinator, Data Officer, Librarian"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Data Entry Assistant ",
                "position_descriptor": " Entry-level positions within data entry and documentation involve interns or assistants who assist in data entry, document management, and ensuring data quality.",
                "related_job_titles": " Data Entry Intern, Data Assistant, Documentation Clerk, Data Clerk"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Data Entry Clerk I",
                "position_descriptor": "This is senior-level position within the data entry department, typically reserved for experienced professionals with advanced data entry skills. May oversee the work of lower-level clerks, ensuring that data entry tasks are completed according to established procedures and deadlines.",
                "related_job_titles": "Data Entry Clerk I"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Data Entry Clerk II",
                "position_descriptor": "This is a mid-level position that requires a higher level of proficiency and experience in data entry tasks. They are responsible for inputting and verifying data accurately and efficiently, often handling more complex data sets or projects. ",
                "related_job_titles": "Data Entry Clerk II"
            },
            {
                "wr_job_level": "WR 01",
                "survey_position": "Data Entry Clerk III",
                "position_descriptor": "This is an entry-level position responsible for accurately inputting and updating data into computer systems or databases. They typically handle routine data entry tasks with supervision, ensuring data accuracy and completeness.",
                "related_job_titles": "Data Entry Clerk III"
            }
        ]
    },
    "Operations and Administration | Executive Assistance": {
        "job_family_descriptor": "This job family provides high-level administrative support to executives and managers, including managing schedules, handling correspondence, and coordinating activities.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Executive Assistant ",
                "position_descriptor": " The Chief Executive Assistant provides high-level administrative support to top executives, managing their schedules, communications, and ensuring smooth operations of their offices.",
                "related_job_titles": " Chief EA, Senior Executive Support Specialist, Chief Executive Support."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director of Executive Assistance",
                "position_descriptor": " Vice Presidents of Executive Assistance lead a team of executive assistants, ensuring efficient coordination of executive schedules, travel arrangements, and communication.",
                "related_job_titles": " VP of Executive Support, Vice President of Executive Services, Executive Assistance VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Executive Assistance",
                "position_descriptor": " Directors of Executive Assistance oversee executive support functions, managing administrative tasks, maintaining confidentiality, and ensuring effective communication with leadership.",
                "related_job_titles": "  Director of Executive Services, Senior Executive Assistance Manager."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Manager, Executive Assistance",
                "position_descriptor": " Senior Executive Assistants provide direct support to executives, manage calendars, prepare reports, and handle confidential information in a professional manner.",
                "related_job_titles": " Senior Executive Assistant, Lead Executive Support Specialist, Senior Personal Assistant"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Executive Assistant I",
                "position_descriptor": "Executive Assistant I is fairly senior provide administrative support to executives, schedule meetings, handle travel arrangements, and facilitate effective communication within the organization to the C level positions.",
                "related_job_titles": " Executive Assistant, Executive Support Specialist, Personal Assistant"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Executive Assistant II",
                "position_descriptor": "Executive Assistant II provides support to Departmental Heads, support analyze data, coordinate projects, and assist in preparing reports for executive decision-making.",
                "related_job_titles": " Administrative Analyst, Executive Analyst, Administrative Support Specialist."
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Executive Assistant III",
                "position_descriptor": "Executive Assistant III assist in scheduling and coordinating meetings, managing documents, and providing logistical support to departmental Managers. May be working in a small organisation providing level support to the leaders.",
                "related_job_titles": " Administrative Associate, Executive Coordinator, Support Coordinator, Secretary, Executive Driver"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Administrative Assistant",
                "position_descriptor": " Entry-level positions in executive support involve interns or assistants who provide administrative assistance to executives, contributing to their efficiency.",
                "related_job_titles": " Administrative Clerk, Trainee Executive Assistant, Executive Support Intern."
            }
        ]
    },
    "Operations and Administration | Facilities and Space Management": {
        "job_family_descriptor": "Facilities and space management job family encompasses maintaining the physical workspace, optimizing space utilization, and ensuring a comfortable and functional environment.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Facilities Officer ",
                "position_descriptor": " The Chief Facilities Officer oversees strategic planning and execution of facilities management, ensuring optimal utilization of space, maintenance, and a safe work environment.",
                "related_job_titles": " Chief Facilities Officer, Space Management Executive, CFO."
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director of Facilities Management",
                "position_descriptor": "Lead facilities teams, managing building maintenance, space planning, and ensuring compliance with safety regulations.",
                "related_job_titles": " VP of Facilities Operations, Vice President of Space Management, Facilities Management VP."
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Facilities Management",
                "position_descriptor": " Directors of Facilities Management oversee day-to-day facilities operations, manage facility maintenance teams, and coordinate space allocation to enhance workplace efficiency.",
                "related_job_titles": " Facilities Management Director, Director of Space Operations, Space Operations Director."
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Facilities Manager",
                "position_descriptor": " Senior Facilities Managers manage facilities operations, maintenance schedules, and space optimization strategies to provide a conducive and well-maintained workplace.",
                "related_job_titles": " Senior Facilities Manager, Lead Space Manager, Senior Facilities Operations Manager."
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Facilities Manager",
                "position_descriptor": " Facilities Managers handle daily facilities operations, coordinate maintenance activities, manage vendor relationships, and ensure a safe and comfortable work environment.",
                "related_job_titles": " Facilities Manager, Space Manager, Facilities Operations Manager."
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Facilities Specialist / Analyst",
                "position_descriptor": " Facilities Specialists or Analysts analyze facilities data, monitor maintenance schedules, and assist in implementing strategies for space optimization and workplace safety.",
                "related_job_titles": " Facilities Specialist, Space Analyst, Facilities Operations Analyst, Senior Facilities Officer"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Facilities Coordinator / Associate",
                "position_descriptor": " Facilities Coordinators or Associates support facilities operations, coordinate maintenance requests, and ensure efficient space utilization within the organization.",
                "related_job_titles": " Facilities Coordinator, Space Associate, Facilities Operations Associate, Facilities Officer"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Facilities Assistant",
                "position_descriptor": " Entry-level positions in facilities management involve interns or assistants who provide support in facilities-related tasks, assisting in maintenance coordination and space management.",
                "related_job_titles": " Facilities Intern, Space Assistant, Facilities Operations Clerk, Office Cleaner"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Maintainence Caretaker",
                "position_descriptor": "Assumes a broader range of responsibilities like complex cleaning tasks, manage inventory for cleaning supplies, oversee the maintenance of office equipment, and address minor technical issues within the office environment and could supervise junior staff and ensure adherence to safety and cleanliness standards",
                "related_job_titles": "Caretaker, Office Support Technician,Office Maintenance Supervisor"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Skilled Trades Workers",
                "position_descriptor": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within construction.",
                "related_job_titles": " Skilled Trades Workers include professionals such as electricians, plumbers, carpenters, and masons, each specializing in a specific trade within construction."
            },
            {
                "wr_job_level": "WR 01",
                "survey_position": "Construction Laborers / General Laborers",
                "position_descriptor": " Construction Laborers or General Laborers provide manual labor support on construction sites, assisting skilled trades workers and following supervisors' instructions.",
                "related_job_titles": " Construction Laborers or General Laborers provide manual labor support on construction sites, assisting skilled trades workers and following supervisors' instructions."
            }
        ]
    },
    "General Marketing": {
        "job_family_descriptor": "The General Marketing job family is responsible for planning, developing, and executing marketing programs that promote the products, services, or brand of an organization. Job holders work to ensure that marketing campaigns are effective and align with the overall goals of the company. ",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Marketing Officer ",
                "position_descriptor": "The Chief Marketing Officer is the highest-ranking marketing executive responsible for leading and shaping the entire marketing department. They define the organization's overarching marketing strategy, set objectives, and oversee the execution of marketing initiatives. The CMO plays a pivotal role in brand development, customer acquisition, and revenue growth.",
                "related_job_titles": "Chief Marketing Officer, Chief Brand Officer, Chief Growth Officer, Senior Vice President of Marketing"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Marketing",
                "position_descriptor": "Senior Directors or Vice Presidents of Marketing are senior leaders who manage and guide the marketing department. They are instrumental in developing and implementing marketing strategies, overseeing major campaigns, and ensuring alignment with business objectives. They often report directly to the CMO or top executives.",
                "related_job_titles": "Vice President of Marketing, Senior Director of Marketing, Marketing Vice President, Head of Marketing, Senior Marketing Director"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Marketing",
                "position_descriptor": "Directors of Marketing oversee specific areas or teams within the marketing department, such as digital marketing, product marketing, or brand management. They are responsible for strategy implementation, team leadership, and achieving marketing objectives.",
                "related_job_titles": "Director of Marketing, Marketing Director, Head of Marketing Strategy"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Marketing Manager",
                "position_descriptor": "Senior Marketing Managers lead marketing teams and initiatives, taking responsibility for the successful execution of marketing plans. They play a key role in campaign management, data analysis, and ensuring marketing efforts drive business growth.",
                "related_job_titles": "Senior Marketing Manager, Senior Marketing Strategist, Marketing Program Manager, Senior Campaign Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Marketing Manager",
                "position_descriptor": "Marketing Managers manage marketing campaigns, projects, or teams. They develop and execute marketing plans, analyze campaign performance, and ensure that marketing initiatives align with the overall marketing strategy.",
                "related_job_titles": "Marketing Manager, Marketing Campaign Manager, Marketing Project Manager, Senior Marketing Specialist"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Team Lead / Supervisor - Marketing",
                "position_descriptor": "Team Leads or Supervisors in Marketing oversee a group of marketing professionals. They provide guidance, assign tasks, and ensure that team members work effectively to achieve marketing goals.",
                "related_job_titles": "Marketing Team Lead, Marketing Supervisor, Marketing Operations Supervisor, Marketing Project Lead, Marketing Team Supervisor, Assistant Marketing Manager"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Marketing Specialist/ Senior Officer",
                "position_descriptor": "Marketing Specialists are subject matter experts in specific marketing areas, such as social media marketing, content marketing, or product marketing. They work on specialized projects, manage key accounts, and contribute to marketing strategy development.",
                "related_job_titles": "Marketing Specialist, Marketing Coordinator, Marketing Analyst, Marketing Strategist, Product Marketing Specialist"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Marketing Coordinator / Associate / Officer",
                "position_descriptor": "Marketing Coordinators or Associates provide essential support to marketing teams. They assist in organizing events, managing schedules, and handling various marketing tasks to ensure projects run smoothly.",
                "related_job_titles": "Marketing Coordinator, Marketing Associate, Marketing Officer, Marketing Support Specialist, Marketing Administrator"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Entry-Level Marketing Assistant",
                "position_descriptor": "Entry-Level Marketing Assistants are new to the field and support marketing activities by performing tasks such as data entry, research, and assisting with marketing campaigns. They gain foundational experience in marketing processes and may work under the guidance of more experienced team members.",
                "related_job_titles": "Marketing Assistant, Marketing Intern, Marketing Trainee, Junior Marketer, Marketing Apprentice"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Marketing Admin Assistant",
                "position_descriptor": "Offers administrative support to the department, manage office tasks, coordinate meetings, and provide assistance to ensure the smooth functioning of operations.",
                "related_job_titles": "Marketing Admin Assistant, Marketing clerk"
            }
        ]
    },
    "General Sales": {
        "job_family_descriptor": "The General Sales job family encompasses positions that are responsible for generating revenue for the company through the sale of products and/or services. job holders may work in a variety of industries and sell a wide range of products and services. They are typically responsible for identifying potential customers, developing relationships with them, and closing sales.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Sales Officer ",
                "position_descriptor": "Chief Sales Officers are top-level executives responsible for leading the entire sales department, shaping the organization's sales strategy, and ensuring that sales efforts drive revenue growth. They play a crucial role in setting sales targets and overseeing major sales initiatives.",
                "related_job_titles": "Chief Sales Officer, Chief Revenue Officer, Senior Vice President of Sales, Head of Sales"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director / Vice President of Sales",
                "position_descriptor": "Senior Directors or Vice Presidents of Sales are senior leaders who manage and guide the sales team, often reporting directly to the CSO or top executives. They play a pivotal role in developing and implementing sales strategies to achieve business objectives.",
                "related_job_titles": "Vice President of Sales, Senior Director of Sales, Sales Vice President, Senior Sales Director"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Sales",
                "position_descriptor": "Directors of Sales oversee specific areas or teams within the sales department, such as regional sales or key accounts. They are responsible for strategy implementation, team leadership, and achieving sales goals.",
                "related_job_titles": "Director of Sales, Sales Director, Regional Sales Director, Director of Sales and Marketing"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Sales Manager",
                "position_descriptor": "Senior Sales Managers lead sales teams and initiatives, ensuring the successful execution of sales plans and campaigns. They play a key role in managing sales performance and contributing to revenue growth.",
                "related_job_titles": "Senior Sales Manager, Senior Sales Strategist, Senior Sales Program Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Sales Manager",
                "position_descriptor": "Sales Managers are responsible for managing sales teams, setting targets, and developing sales strategies. They play a crucial role in coaching and motivating sales representatives to meet their goals.",
                "related_job_titles": "Sales Manager, Sales Team Manager, Sales Operations Manager, Sales Coordinator Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Team Lead / Supervisor - Sales",
                "position_descriptor": "Sales Team Leads or Supervisors oversee groups of sales professionals, providing guidance and ensuring effective team collaboration. They may focus on specific sales channels or regions.",
                "related_job_titles": "Assistant Manager-Sales, Sales Team Lead, Sales Supervisor, Sales Operations Supervisor, Sales Project Lead, Sales Team Supervisor"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Sales Specialist/ Senior Officer",
                "position_descriptor": "Sales Specialists are subject matter experts in specific product lines or market segments. They contribute to specialized sales projects, manage key accounts, and support sales strategy development.",
                "related_job_titles": "Sales Specialist, Sales Coordinator, Sales Analyst, Sales Strategist, Product Sales Specialist, Senior Sales Officer"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Sales Coordinator / Associate/Officer",
                "position_descriptor": "Sales Coordinators or Associates provide administrative and operational support to sales teams, assisting with tasks such as order processing, customer service, and sales coordination.",
                "related_job_titles": "Sales Coordinator, Sales Associate, Sales Officer, Sales Support Specialist, Sales Administrator"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Sales Assistant",
                "position_descriptor": "Entry-Level Sales Assistants are new to the sales field and provide support by performing tasks like data entry and assisting with sales campaigns. They gain foundational experience in sales processes and may work under the guidance of more experienced team members.",
                "related_job_titles": "Sales Assistant, Sales Trainee, Junior Sales Assistant, Sales Apprentice"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Sales Admin Assistant",
                "position_descriptor": "Offers administrative support to the department, manage office tasks, coordinate meetings, and provide assistance to ensure the smooth functioning of operations.",
                "related_job_titles": "Sales Administration Assistant, Sales Clerk"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Field Sales Representatives ",
                "position_descriptor": "Sales Representatives promote and sell products at an assigned station. They build relationships with clients, understand customer needs, and contribute to achieving sales targets.",
                "related_job_titles": "Field Sales Staff, Sales Reps, Sales Representatives"
            }
        ]
    },
    "Safety and Security": {
        "job_family_descriptor": "This job family focuses on the planning, implementation, and management of safety and security measures to prevent and respond to threats, incidents, and emergencies",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Security Officer / Chief Safety Officer",
                "position_descriptor": " As the highest-ranking executive in security and safety, the position sets and leads the organization's safety and security strategies. They manage all aspects of security, crisis response, safety policies, and risk assessment, ensuring a safe environment for all stakeholders.",
                "related_job_titles": "Chief Security Officer (CSO), Chief Safety Officer (CSaO), Head of Security"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director of Safety and Security",
                "position_descriptor": " Senior Directors are top-level executives responsible for overseeing all safety and security functions. They contribute to organizational strategy, manage budgets, and lead security and safety teams.",
                "related_job_titles": "Senior Security Director, Senior VP of Safety and Security, Safety and Security Executive Director,"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director of Safety and Security",
                "position_descriptor": " Director leads and manages safety and security functions within an organization. They develop strategies, policies, and programs while ensuring compliance with relevant regulations.",
                "related_job_titles": "Director of Safety and Security, Safety and Security Director"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Manager of Safety and Security",
                "position_descriptor": " Senior Manager oversees safety and security departments, defining and executing strategies. They manage safety programs, security measures, and crisis response procedures.",
                "related_job_titles": "Senior Manager Safety and Security, Senior Safety Program Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Safety and Security Manager / Analyst",
                "position_descriptor": " Managers/Senior Analysts lead safety and security teams, enforcing policies and ensuring safety compliance. They analyse safety data, manage safety programs, and supervise security measures. ",
                "related_job_titles": "Safety and Security Manager, Senior Safety Analyst, Safety Compliance Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Safety and Security Specialist",
                "position_descriptor": " Coordinators/Safety Specialists support safety and security functions, ensuring adherence to policies and standards. They conduct safety training, inspections, and incident investigations.",
                "related_job_titles": "Safety Coordinator, Safety Specialist, Security Compliance Specialist"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Safety and Security Officer / Inspector",
                "position_descriptor": " Officers/Inspectors are responsible for implementing security and safety measures. They monitor premises, enforce safety regulations, and respond to incidents.",
                "related_job_titles": "Safety Officer, Security Inspector, Safety and Security Patrol Officer"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Safety and Security Technicians",
                "position_descriptor": " Technicians provide support in safety and security operations. They assist with equipment maintenance, access control, and safety drills.",
                "related_job_titles": "Safety Technician, Security Supervisor, Access Control Technician"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "Safety and Security Assistant",
                "position_descriptor": " These are entry-level assistants who gain experience in safety and security. They support senior personnel in tasks such as security monitoring, incident reporting, and safety audits.",
                "related_job_titles": "Safety and Security Intern, Assistant Security Officer, Safety Assistant Intern"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Senior Security Guard",
                "position_descriptor": " Senior Security guards take on more responsibilities which reflects their experience and potentially additional duties within the organization. They are experienced Guards responsible for monitoring and safeguarding premises in settings where on-site security personnel are needed.",
                "related_job_titles": "Senior Security Guard"
            },
            {
                "wr_job_level": "WR 02",
                "survey_position": "Security Guard I",
                "position_descriptor": "Guards responsible for monitoring and safeguarding premises in settings where on-site security personnel are needed",
                "related_job_titles": "Security Guard"
            },
            {
                "wr_job_level": "WR 01",
                "survey_position": "Security Guard II",
                "position_descriptor": "Entry level Guards responsible for monitoring and safeguarding premises in settings where on-site security personnel are needed",
                "related_job_titles": "Security Guard"
            }
        ]
    },
    "ICT Operations": {
        "job_family_descriptor": "This job family covers roles related to managing and maintaining technology infrastructure, networks, and IT systems. It includes positions that ensure the smooth operation of IT services, hardware, and software within an organization.",
        "levels": [
            {
                "wr_job_level": "WR 12",
                "survey_position": "Chief Technology Officer (CTO)",
                "position_descriptor": "The Chief is responsible for shaping and executing the technology strategy of the organization. They oversee the design, implementation, and management of the entire IT infrastructure, ensuring it aligns with business goals. The CTO provides visionary leadership to optimize technology systems, enhance operational efficiency, and drive innovation.",
                "related_job_titles": "Chief Information Officer, IT Strategy Executive, Head of IT, Technology Operations Officer"
            },
            {
                "wr_job_level": "WR 11",
                "survey_position": "Senior Director - ICT ",
                "position_descriptor": "The Senior Director plays a pivotal role in leading technology operations. They provide strategic direction to IT teams, manage critical technology projects, and ensure the reliability and scalability of infrastructure. The Senior Director collaborates with cross-functional stakeholders to optimize technology solutions and drive continuous improvement.",
                "related_job_titles": "ICT Strategy Director, Senior IT Operations Director, Director of Infrastructure, Technology Leadership Executive"
            },
            {
                "wr_job_level": "WR 10",
                "survey_position": "Director - ICT ",
                "position_descriptor": "The Director is accountable for the effective management of technology systems and infrastructure. They oversee the deployment of technology initiatives, ensure seamless operation of IT services, and lead teams in implementing and maintaining IT solutions that support business operations.",
                "related_job_titles": "IT Operations Director, Infrastructure Solutions Director, Chief IT Officer"
            },
            {
                "wr_job_level": "WR 09",
                "survey_position": "Senior Manager - ICT",
                "position_descriptor": "The Senior Manager leads IT operations to meet organizational objectives. They manage technology teams, provide technical leadership, and ensure the stability, security, and performance of IT infrastructure. The Senior Manager contributes to strategic planning and manages technology projects.",
                "related_job_titles": "Senior IT Operations Manager, Infrastructure Solutions Manager, Senior IT Manager"
            },
            {
                "wr_job_level": "WR 08",
                "survey_position": "Manager - ICT ",
                "position_descriptor": "The Manager is responsible for overseeing the day-to-day management of technology systems and services. They lead teams in deploying, configuring, and maintaining IT assets, and collaborate with various departments to ensure technology meets business needs. The Manager monitors IT performance, resolves issues, and drives operational efficiency.",
                "related_job_titles": "IT Operations Manager, Infrastructure Implementation Manager, IT Infrastructure Manager"
            },
            {
                "wr_job_level": "WR 07",
                "survey_position": "Team Leader - ICT ",
                "position_descriptor": "The Team Leader supervises a group of IT professionals. They guide technical activities, mentor team members, and collaborate with other teams to ensure seamless technology operations. The Team Leader contributes to the development and implementation of technology solutions.",
                "related_job_titles": "IT Team Lead, Infrastructure Team Supervisor, Technical Infrastructure Lead"
            },
            {
                "wr_job_level": "WR 06",
                "survey_position": "Specialist Professional - ICT",
                "position_descriptor": "The IT Specialist is an expert in specific technology domains. They analyze technology trends, design and implement infrastructure solutions, and optimize technology performance. The Specialist contributes to technology planning, technical documentation, and process improvement.",
                "related_job_titles": "IT Expert, Infrastructure Specialist, Technical Infrastructure Guru"
            },
            {
                "wr_job_level": "WR 05",
                "survey_position": "Senior Officer - ICT",
                "position_descriptor": "The Senior Professional manages technology systems and services. They lead technology deployment, contribute to technical projects, and provide expertise to support IT initiatives. The Senior Professional ensures the reliability and availability of technology resources.",
                "related_job_titles": "Senior IT Specialist, Lead Infrastructure Engineer, Principal Technologist"
            },
            {
                "wr_job_level": "WR 04",
                "survey_position": "ICT Officer",
                "position_descriptor": "The IT Professional assists in managing technology systems. They participate in technology deployment, contribute to technical documentation, and provide technical assistance to users. The Professional supports IT projects and operational tasks.",
                "related_job_titles": "ICT Officer, IT Support Specialist, Infrastructure Engineer, Technical Operations Analyst"
            },
            {
                "wr_job_level": "WR 03",
                "survey_position": "Support Associate - ICT",
                "position_descriptor": "The Support Associate in IT provides administrative and technical assistance to IT teams. They manage documentation, coordinate technology activities, and support IT operations. The Support Associate contributes to maintaining an efficient and organized IT environment.",
                "related_job_titles": "Junior IT Associate, Trainee IT Technician, IT Intern, IT Operations Associate, Technical Support Assistant, IT Helpdesk Coordinator"
            }
        ]
    }
}