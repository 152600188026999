export const CONTRACTING_OPTIONS = [
    {label: "National Recruited Staff - NRS", value: "National Recruited Staff - NRS"},
    {label: "Internationally Recruited Staff - IRS", value: "Internationally Recruited Staff - IRS"},
]

export const COLS = [
    //"Sector", 
    "Job Family", "Sector Survey Position", "Job Level", "Position Overview", "Your Organisation Job Title",
    "Contracting",
    "Reporting Structure", "Number of Staff In Position"
]

export const COLS_MP = {
    "Job Family": "Select the Job Family of the role",
    "Sector Survey Position": "Position in the system",
    "Job Level": "Select the appropriate job level or grade",
    "Position Overview": "The position summary, job level description and common job titles used in the market.",
    "Your Organisation Job Title": "State the corresponding job title in your organisation",
    "Contracting": "National Recruited Staff - NRS or Internationally Recruited Staff-IRS",
    "Reporting Structure": "Where the position reports to within your organisation",
    "Number of Staff In Position": "State the Approved Establishment whether the position is filled or not"
}


// export const COLS_MP = {
//     "Job Family": "(Select the Job Family of the role)",
//     "Sector Survey Position": "(Position in the system)",
//     "Job Level": "(Select the appropriate job level or grade)",
//     "Position Overview": "(picks the descriptor and alternative titles)",
//     "Your Organisation Job Title": "(State the corresponding job title in your organisation)",
//     "Contracting": "(National Recruited Staff - NRS or Internationally Recruited Staff-IRS)",
//     "Reporting Structure": "(Where the position reports to within your organisation)",
//     "Number of Staff In Position": "(State the Approved Establishment whether the position is filled or not)"
// }
