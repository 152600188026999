import React from "react";
import BlockHeading from "../../../../components/forms/BlockHeading";
import BarChart from "../../Components/BarChart";
import BCard from "../../Components/BCard";
import { getFooter, getNote } from "../utils";

export default function EmployeeDemography(props) {
  console.log(props.data);
  const { total, categories, data } = props.data;
  return (
    <div>
      <BlockHeading text="1. Employee Demography" />
      <div className="my-4">
        <BCard
          title="Employee Demography"
          subtitle={"Employee population split"}
          footer={getFooter(total)}
          note={getNote()}
        >
          <BarChart
            percentage={true}
            categories={categories}
            series={[
              {
                name: "Employee Demography",
                data: data,
              },
            ]}
            height="auto"
          />
        </BCard>
      </div>
    </div>
  );
}
