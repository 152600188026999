import { Box, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell as TCell, TableContainer, TableRow as TRow } from "@mui/material"
import { styled } from '@mui/material';

const TableRow = styled(TRow)({
    // Decrease padding
    py: 0.1,
    '& .MuiTableCell-root': {
        py: 0.5,
    }
})

const TableCell = styled(TCell)({
    // Decrease padding
    py: 0.1
})



export default function CompanyDetailsCard(props) {
    const { data, approved, can_edit } = props 
    if (!data) {
        return null
    }

    const { company_info, tab, finish } = data

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <Box sx={{
                            backgroundColor: '#F9FAFB',
                            borderBottom: '1px solid #ddd',
                            fontSize: '1.3rem', px: 2, py: 1.1
                        }}>Contact Information</Box>
                        <CardContent sx={{ py: 0.5 }}>

                            <TableContainer sx={{ width: '100%' }}>
                                <Table sx={{ width: '100%' }} size="small">
                                    <TableBody>

                                        <TableRow>
                                            <TableCell>Contact Person Name</TableCell>
                                            <TableCell>{company_info.contact_information.title} {company_info.contact_information.name}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Contact Person Email</TableCell>
                                            <TableCell>{company_info.contact_information.email}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Contact Person Phone</TableCell>
                                            <TableCell>{company_info.contact_information.phone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Country</TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize' }}>{company_info.organization_information.country}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <Box sx={{
                            backgroundColor: '#F9FAFB',
                            borderBottom: '1px solid #ddd',
                            fontSize: '1.3rem', px: 2, py: 1.1
                        }}>Company Information</Box>
                        <CardContent sx={{ py: 0.5 }}>
                            <TableContainer sx={{ width: '100%' }}>
                                <Table sx={{ width: '100%' }} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Company Type</TableCell>
                                            <TableCell>{company_info.demography.company}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Industry</TableCell>
                                            <TableCell>{company_info.demography.industry}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Sector</TableCell>
                                            <TableCell>{company_info.demography.sector}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Is Startup</TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize' }}>{company_info.demography.is_startup}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}