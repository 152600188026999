export default function generator(limit) {
    const resultList = [];

    for (let i = 1; i <= limit; i++) {
        resultList.push({
            label: `${i}%`,
            value: `${i}%`
        });
    }

    return resultList;
}

export function num_generator(limit, additional) {
    const resultList = [];

    for (let i = 0; i <= limit; i++) {
        resultList.push({
            label: `${i}`,
            value: `${i}`
        });
    }
    if (additional) {
        resultList.push({
            label: `${limit}+`,
            value: `${limit}+`
        });
    }

    return resultList;
}

