import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import ExpPackages from './ExpPackages';
import { useEffect } from 'react';
import { setExpatriateCompensationData } from '../../../../../../redux/onboard/benefits/expatriate_compensation';
import { toast } from 'react-toastify';



export default function ExpatriateCompensation(props) {
    const expatriate_compensation = useSelector(state => state.expatriate_compensation)
    const { data } = expatriate_compensation
    const dispatch = useDispatch()


    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors, isDirty },
    } = useForm({

    });

    useEffect(() => {
        reset(data)
    }, [])
    useEffect(() => {
        props.onChangeIsDirty(isDirty, props.id)
    }, [isDirty])


    const onError = (errors) => {
        toast.success('Please fill all fields carefully', {
            position: "top-center",
            type: 'error',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }




    const onSubmit = (form_data) => {
        if (isDirty) {
            dispatch(setExpatriateCompensationData(form_data))
            props.onSaveClick("benefits", "expatriate_compensation", form_data)
        }
        props.changeTab(7)
    };



    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <ExpPackages control={control} watch={watch} register={register} errors={errors} />

            <div className="flex justify-end mt-5 px-2 py-2">
                <button type='submit' className="flex justify-center bg-blue-600 text-white py-1.5 px-5 rounded-lg hover:bg-blue-700">Next</button>
            </div>
        </form>
    )
}